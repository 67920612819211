import { useEffect, useState } from "react";
import { ServiceAccount, ServiceAccountType } from "../types";
import { Button, Dialog, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { subscribeToServiceLines, getServiceLineSubscription } from "../slice";
import moment from "moment-timezone";
import { getDataFromLocalStorageParsed } from "../../../utils/util";
import { getAllServiceLines } from "../../StarlinkReports/slice";
import _ from "lodash";
import { YYYYMMDD_HHmmss } from "../../../utils/constants";


interface ServiceAccountDialogProps {
    authReducer: any;
    errorReducer: any;
    starlinkAdmin: any;
    open: boolean;
    serviceAccount: ServiceAccount | null;
    onClose: () => void;
    onSave: (serviceAccount: ServiceAccount) => void;
    allServiceLines:any,
    allServiceLinesSubscription:any,
    dpIds:any,
    getAllServiceLines:any,
    subscribeToServiceLines:any,
    getServiceLineSubscription: any
}

function ServiceAccountDialog(props: ServiceAccountDialogProps) {

    const { authReducer, errorReducer, starlinkAdmin, open, serviceAccount, onClose, onSave, allServiceLines, dpIds,subscribeToServiceLines, getAllServiceLines, getServiceLineSubscription, allServiceLinesSubscription } = props;

    const [serviceAccountType, setServiceAccountType] = useState<ServiceAccountType>(serviceAccount?.type || 'service_account_client');
    const [userName, setUserName] = useState<string>(serviceAccount?.userName || '');
    const [password, setPassword] = useState<string>('');
    const [clientId, setClientId] = useState<string>(serviceAccount?.clientId || '');
    const [clientSecret, setClientSecret] = useState<string>('');
    const [valid, setValid] = useState<boolean>(false);
    const [disableActions, setDisableActions] = useState<boolean>(false);
    const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
    const [isSubscribedToAll, setIsSubscribedToAll] = useState<boolean>(false);

    const handleClose = () => {
        onClose();
    }

    const handleSave = () => {
        onSave({
            id: serviceAccount?.id || '',
            dpId: serviceAccount?.dpId || authReducer.selectedOu.id,
            type: serviceAccountType,
            userName,
            clientId,
            clientSecret,
            password,
        });
    }

    const handleChangeServiceAccountType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceAccountType((event.target as HTMLInputElement).value as ServiceAccountType);
    };

    const handleChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(event.target.value);
    };

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleChangeClientId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientId(event.target.value);
    };

    const handleChangeClientSecret = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientSecret(event.target.value);
    };

    useEffect(() => {
        if (open) {
            setServiceAccountType(serviceAccount?.type || 'service_account_client');
            setUserName(serviceAccount?.userName || '');
            setPassword('');
            setClientId(serviceAccount?.clientId || '');
            setClientSecret('');
        }
    }, [open, serviceAccount]);

    useEffect(()=>{
        if(allServiceLinesSubscription && allServiceLinesSubscription.length > 0){
            setIsSubscribedToAll(allServiceLinesSubscription[0].allServiceLineSubscription)
        }
    
    },[allServiceLinesSubscription])

    useEffect(() => {
        setValid(serviceAccountType === 'service_account_client' ? !!clientId && !!clientSecret : !!userName && !!password);
    }, [serviceAccountType, userName, password, clientId, clientSecret]);

    useEffect(() => {
        setDisableActions(
            starlinkAdmin.creatingServiceAccount ||
            starlinkAdmin.updatingServiceAccount ||
            starlinkAdmin.deletingServiceAccount
        );
    }, [starlinkAdmin.creatingServiceAccount, starlinkAdmin.updatingServiceAccount, starlinkAdmin.deletingServiceAccount]);

    useEffect(() => {
        if(!_.isEmpty(dpIds)) {
            getAllServiceLines({dpIds: dpIds});
            getServiceLineSubscription({dpId:dpIds})
        }
    }, [dpIds]);

    const handleChangeSubscribeToAll = async (e, sl) =>{
        const action = e?.target?.checked ? "subscribeToAll" : "unsubscribeToAll";
        const date = moment(new Date()).format(YYYYMMDD_HHmmss);
        const requestData: { serviceLineNumber: any; dpId: string[];  user: any; action: string; startDate?: string | undefined; endDate?: string | undefined; }  =
         {
            serviceLineNumber: ["All"],
            dpId:dpIds,
            user: loggedInUser?.data?.user?.userName,
            action: action,
        };

        if (e?.target?.checked) {
            requestData.startDate = date;
        } else {
            requestData.endDate = date;
        }
        await subscribeToServiceLines(requestData);
      
      getServiceLineSubscription({dpId:dpIds})
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="service_account--dialog" maxWidth="md" fullWidth>
            <Grid classes={{ root: 'starlink--base--padding_1x' }}>
                <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                    <Typography component="div" className="starlink--base--font_1x">
                        Service Account
                    </Typography>
                </Grid>
                <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                    <FormControl disabled={disableActions}>
                        <FormLabel id="starlink--service_account_type--radio_group">Account Type</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="starlink--service_account_type--radio_group"
                            name="controlled-radio-buttons-group"
                            value={serviceAccountType}
                            onChange={handleChangeServiceAccountType}
                        >
                            <FormControlLabel value="service_account_client" control={<Radio />} label="Service Account Client" />
                            <FormControlLabel value="credential" control={<Radio />} label="Web Portal" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid>
                    {
                        serviceAccountType === 'service_account_client' ?
                            <Grid container spacing={1} classes={{ root: 'starlink--base--mtb_07x' }}>
                                <Grid item md={6}>
                                    <TextField
                                    disabled={disableActions}
                                        fullWidth
                                        size="small"
                                        id="starlink--service_account--client_id"
                                        label="Client ID"
                                        value={`****${clientId?.slice(-4)}`}
                                        onChange={handleChangeClientId}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                    disabled={disableActions}
                                        fullWidth
                                        type="password"
                                        size="small"
                                        id="starlink--service_account--client_secret"
                                        label="Client Secret"
                                        value={clientSecret}
                                        onChange={handleChangeClientSecret}
                                    />
                                </Grid>
                            </Grid>
                            :
                            <Grid container spacing={1} classes={{ root: 'starlink--base--mtb_07x' }}>
                                <Grid item md={6}>
                                    <TextField
                                    disabled={disableActions}
                                        fullWidth
                                        size="small"
                                        id="starlink--service_account--user_name"
                                        label="Email"
                                        value={userName}
                                        onChange={handleChangeUserName}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                    disabled={disableActions}
                                        fullWidth
                                        type="password"
                                        size="small"
                                        id="starlink--service_account--password"
                                        label="Password"
                                        value={password}
                                        onChange={handleChangePassword}
                                    />
                                </Grid>
                            </Grid>
                    }
                </Grid>
                    <Grid container>
                    <Grid style={{marginTop:"10px"}}>
                            <Typography>Subscribe All Service Lines</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                            <FormControl>
                            <Tooltip title="Subscribe all service lines">
                                        <Switch
                                            checked={ isSubscribedToAll}
                                            onChange={ (e) => { handleChangeSubscribeToAll(e,allServiceLines) }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </Tooltip>
                            </FormControl>
                        </Grid>
                    </Grid>
                <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end' }}>
                    <Button variant="outlined" className='starlink--button--cancel' onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" className='starlink--button_contained--primary' onClick={handleSave} disabled={!valid || disableActions}>Save</Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}

const mapStateToProps = (state: any) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    starlinkAdmin: state.starlinkAdmin,
    dpIds: state.starlinkCloud.dpIds,
    allServiceLines: state.starlinkReports.allServiceLines, 
    allServiceLinesSubscription: state.starlinkAdmin.allServiceLinesSubscription
    
});

export default withRouter(
    connect(mapStateToProps, {
        subscribeToServiceLines,
        getAllServiceLines,
        getServiceLineSubscription
    })(ServiceAccountDialog)
);
