import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Button, Switch } from "@mui/material";
import { getDateForChart, getDecodeURI, getEncodedURI, getMinutes, getSingleRecordChartLable, getTimezoneCity, readableBytesAsGB, readableBytesAsMB } from "../../../utils/util";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { getCaptiveDashPlansPercentageUsageCountBar, getCaptiveDashPlansUsageLineCharts } from "../../../actions/Users/authenticateCaptiveDahshboard";
import _ from "lodash";
import moment from "moment";
import TopPlansPie from './PieChart';

const PlansCharts = (props) => {
    const { authReducer, newSummaryStartDate, newSummaryEndDate, location, history, vessels, getCaptiveDashPlansPercentageUsageCountBar, getCDPlansPercentageUsageCountBar, getCaptiveDashPlansUsageLineCharts, getCDPlansUsageLineChart, values,setValues, applyFilterClick } = props;
    const _q = getDecodeURI(location?.search);
    const [lineChartData, setLineChartData] = useState<any>([]);
    const [barChartData, setBarChartData] = useState<any>([]);
    const [resetDate, setResetDate] = useState<any>([]);
    const [recLimit, setRecLimit] = useState(10);
    const timeZone = getTimezoneCity(authReducer?.userTimezone);
    const [ chartView, setChartView] = useState(_q.hasOwnProperty('planChartView') ? _q.planChartView : 'column');
    const [barChartDataUnit, setBarChartDataUnit] = useState('GB');
    const getInterval = (minutes) => {
        if (minutes <= 720) {
            return "5 minutes";
        } else if (minutes > 720 && minutes <= 1440) {
            return "15 minutes";
        } else if (minutes > 1440 && minutes <= 10080) {
            return "1 hour";
        } else {
            return "12 hour";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    const { selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal } = values;

    useEffect(() => {
        const _interval = getInterval(
            Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)
        );
        setSelectedBin(_interval);
        if (vessels?.length > 0) {
            chartView == 'column' ? getCaptiveDashPlansPercentageUsageCountBar(vessels, newSummaryStartDate, newSummaryEndDate, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal) :
            getCaptiveDashPlansUsageLineCharts(vessels, newSummaryStartDate, newSummaryEndDate, _interval, recLimit, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal);
        } else {
            setLineChartData([]);
            setBarChartData([]);
        }
    }, [vessels, newSummaryStartDate, newSummaryEndDate]);

    const colors = ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
        '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
        '#1b4dac', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
        '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
        '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
    ]

    useEffect(() => {
        if (!_.isEmpty(getCDPlansPercentageUsageCountBar)) {
            const data = getCDPlansPercentageUsageCountBar.hasOwnProperty('data') ? getCDPlansPercentageUsageCountBar.data : {};
            const dataArr = data?.map((el) => {
                return {x: el.quotaBucket, y: el.numberOfPlans}
            })
            setBarChartData(dataArr);
        } else {
            setBarChartData([])
        }
        if (!_.isEmpty(getCDPlansUsageLineChart)) {
            const data = getCDPlansUsageLineChart.hasOwnProperty('data') ? getCDPlansUsageLineChart.data : {};
            if (data) {
                const series = data?.quotaUsed?.series || {};
                const seriesKeys = Object.keys(series);
                const gbValue = Math.pow(1000, 3);
                const isGBCrossed = seriesKeys.some(key => {
                    const seriesValue = series[key];
                    return (_.isArray(seriesValue) && seriesValue.some(item => item.y > gbValue))
                })
                const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
                const lineChartData: any = data?.quotaUsed?.series && Object.keys(data?.quotaUsed?.series)?.map((key, i) => {
                    data?.quotaUsed?.series[key]?.forEach((el, i) => {
                        series[key][i].y = Number(convertBytes(el.y, false))
                    })
                    return { name: key, data: data?.quotaUsed?.series[key], marker: { symbol: 'circle' }, visible: true, color: colors[i] }
                })
                setLineChartData(lineChartData);
                setBarChartDataUnit(isGBCrossed ? 'GB' : 'MB');
            }
        } else {
            setLineChartData([])
        }
    }, [getCDPlansPercentageUsageCountBar, getCDPlansUsageLineChart])

    const pinchAndResetChart = (start, end, interval) => {
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = interval;
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const chartAreaSelection = () => {
        return (event: any) => {
            let start: any = moment(new Date(Math.ceil(event.xAxis[0].min)));
            let end = moment(new Date(Math.floor(event.xAxis[0].max)));
            pinchAndResetChart(start, end, 'customDates');
            let arr: any = resetDate;
            arr.push({ startDate: start, endDate: end });
            setResetDate(arr);
            return false;
        }
    }

    const handleResetZoom = () => {
        if (resetDate?.length == 0) {
            return;
        }
        let arr: any = resetDate;
        arr.pop();
        setResetDate(arr);
        if (arr?.length == 0) {
            const start = moment(Date.now()).subtract(1, 'hour');
            const end = moment(Date.now());
            pinchAndResetChart(start, end, '1h');
        }
        else {
            let obj = arr[arr?.length - 1];
            let start = obj.startDate;
            let end = obj.endDate;
            pinchAndResetChart(start, end, 'customDates');
        }
    }

    const handleDefaultResetZoom = () => {
        setResetDate([]);
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        pinchAndResetChart(start, end, '1h');
    }

    const barOptions = {
        time: {
            timezone: timeZone,
        },
        chart: {
            type: chartView,
            height: '260px',
            plotBorderWidth: 1,
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500',
            },
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false,
                },
            },
        },
        tooltip: {
            headerFormat: '',
            pointFormat: chartView == 'column' ? `<b style="color: #7e8089">Plans: {point.y}</b>` : `<span style="color: #7e8089">{point.x}</span> <br/> <b style="color: #fff">{point.z}</b><br/>`,
            valueDecimals: 0,
            borderWidth: 1,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
            formatter: function (this: any) {
                if (chartView === 'spline') {
                    const options: Intl.DateTimeFormatOptions = {
                        weekday: 'long',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false,
                    };
        
                    const timeZoneFormattedDate = new Intl.DateTimeFormat('en-US', {
                        ...options,
                        timeZone: timeZone,
                    }).format(new Date(this.x));
        
                    return `
                        <b style="color: #7e8089">${timeZoneFormattedDate}</b><br/>
                        <b style="color: #7e8089">${this.series.name}</b>: 
                        <b style="color: #fff">${this.point.y} ${barChartDataUnit}</b>
                    `;
                } else {
                    return `<b style="color: #7e8089">Plans: ${this.point.y}</b>`;
                }
            }
        },
        xAxis: {
            title: {
                text: '',
            },
            categories: null,
            type: chartView == 'column' ? 'linear' : 'datetime',
            gridLineWidth: 0.5,
            labels: {
                format: chartView === 'column' ? '{value} %' : getDateForChart(lineChartData) && getSingleRecordChartLable(lineChartData, timeZone),
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontWeight: '500',
                },
            },
        },
        yAxis: {
            title: {
                text: chartView == 'spline' ? `Usage (${barChartDataUnit})` : '',
            },
            gridLineWidth: 1,
            tickInterval: 1,
            labels: {
                format: '{value}',
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontWeight: '500',
                },
            },
        },
        series: chartView == 'column' ? [{ data: barChartData, showInLegend: false, color: "#86c6df" }] : lineChartData
    };
    
    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBin(value);
        if (vessels?.length > 0) {
            getCaptiveDashPlansUsageLineCharts(vessels, newSummaryStartDate, newSummaryEndDate, value, recLimit, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal)
        }
    };

    const handleChangeTopAppLimit = (e) => {
        const limit = e.target.value;
        setRecLimit(limit);
        if (vessels?.length > 0) {
            getCaptiveDashPlansUsageLineCharts(vessels, newSummaryStartDate, newSummaryEndDate, selectedBin, limit, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal)
        }
    };

    const handleChangeToggle = (e) => {
        let params: any = getDecodeURI(location?.search);
        params.planChartView = e.target.checked ? 'spline' : 'column';
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
        if(e.target.checked) { 
            setChartView('spline')
            getCaptiveDashPlansUsageLineCharts(vessels, newSummaryStartDate, newSummaryEndDate, selectedBin, recLimit, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal)
        }
        else { 
            setChartView('column')
            getCaptiveDashPlansPercentageUsageCountBar(vessels, newSummaryStartDate, newSummaryEndDate, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal);
        }
    }

    const handleLegendClick = (app, color, index) => {
        let all_true = true;
        let updatedData: any = [...lineChartData];
        let all_off_turn_on = 0;
        let click = 0;

        updatedData?.forEach((ele, i) => {
            if (!ele.visible) {
                all_true = false;
                all_off_turn_on += 1;
            }
            if (ele.visible) {
                click = i;
            }
        })

        if (click == index && all_off_turn_on == updatedData.length - 1) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: true }
            })
            setLineChartData([...updatedData]);
        }
        else if (all_true) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: false }
            })
            updatedData[index].visible = true;
            setLineChartData([...updatedData]);
        }
        else {
            updatedData.forEach((ele, i) => {
                updatedData[i] = ele;
            })
            updatedData[index].visible = !updatedData[index].visible;
            setLineChartData([...updatedData]);
        }
    }

    return (
        <div className="new-summary-chart-container charts-container-captive-dashboard" style={{ marginTop: '15px' }}>
            <Grid container spacing={2} className='new-summary-chart-component'>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={0} className="usage-per-site-pie">
                        <Grid item xs={3} sm={3} md={3} lg={3} className="pie-chart-summary">
                            <TopPlansPie vessels={vessels} values={values} setValues={setValues} applyFilterClick={applyFilterClick} />
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9} className="site-line-chart-title-div">
                            <Grid className="legends-pie-new-container site-line-chart-title-text">
                                <Grid>
                                    <span className="title-text">Histogram</span>
                                    <Switch
                                        checked={chartView == 'spline' ? true : false}
                                        onChange={handleChangeToggle}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        size="small"
                                    />
                                    <span className="title-text usage-trend">Usage Trend</span>
                                </Grid>
                                <Grid className="legends-view-images legends-view-images-sites">
                                    {chartView == 'column' && <Grid className="summary-pie-bar-legends">
                                        <div className="summary-pie-usage-dot" style={{ background: '#86c6df' }}></div>
                                        <span className="barChart-legends">Usage</span>
                                    </Grid>}
                                    {chartView == 'spline' && <>
                                        <Grid className="pieChat-selectTag">
                                            <FormControl variant="standard" className='selectEntry-pie'>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={selectedBin}
                                                    onChange={handleBinChange}
                                                >
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 5 && <MenuItem value={'5 minutes'}>5m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10 && <MenuItem value={'10 minutes'}>10m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 15 && <MenuItem value={'15 minutes'}>15m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 30 && <MenuItem value={'30 minutes'}>30m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 60 && <MenuItem value={'1 hour'}>1h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 120 && <MenuItem value={'2 hour'}>2h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 180 && <MenuItem value={'3 hour'}>3h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 360 && <MenuItem value={'6 hour'}>6h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 720 && <MenuItem value={'12 hour'}>12h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1440 && <MenuItem value={'1 day'}>1d</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10080 && <MenuItem value={'7 day'}>7d</MenuItem>}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <div className="siteCharts__filters-reset-zoom resetZoom-usage">
                                            <Grid className="entryLimit-lineChart">
                                                <FormControl variant="standard" className='selectEntry-pie'>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={recLimit}
                                                        onChange={handleChangeTopAppLimit}
                                                    >
                                                        <MenuItem value="all">
                                                            <em>All</em>
                                                        </MenuItem>
                                                        <MenuItem value={5}>5</MenuItem>
                                                        <MenuItem value={10}>10</MenuItem>
                                                        <MenuItem value={20}>20</MenuItem>
                                                        <MenuItem value={30}>30</MenuItem>
                                                        <MenuItem value={50}>50</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </div>
                                        {resetDate.length > 0 &&
                                            <div>
                                                <FormControlLabel
                                                    value="Zoom Back"
                                                    control={<IconButton onClick={handleResetZoom}><ZoomOutIcon /></IconButton>}
                                                    label=""
                                                    labelPlacement="start"
                                                />
                                                <FormControlLabel
                                                    value="Zoom Reset"
                                                    control={<IconButton onClick={handleDefaultResetZoom}><RestartAltIcon /></IconButton>}
                                                    label=""
                                                    labelPlacement="start"
                                                />
                                            </div>
                                        }
                                    </>}
                                </Grid>
                            </Grid>
                            <HighchartsReact
                                immutable={true}
                                highcharts={Highcharts}
                                options={barOptions}
                            />
                            {chartView == 'spline' && <Grid className="topApplicationNames cf-legends">
                                {lineChartData?.map((ele, i) => (
                                    <Grid key={i} className="name-color line-cf-chart">
                                        <div className="totalUsageColor" style={{ background: ele['color'] }}></div>
                                        <Grid className={lineChartData[i]?.visible ? "lineChart-legends" : 'disable-lineChart-legends'}
                                            onClick={() => handleLegendClick(ele, ele['color'], i)}>{ele['name']}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state?.authReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    getCDPlansPercentageUsageCountBar: state?.authReducer?.getCDPlansPercentageUsageCountBar,
    getCDPlansUsageLineChart: state?.authReducer?.getCDPlansUsageLineChart,
});

export default withRouter(
    connect(mapStateToProps, { getCaptiveDashPlansPercentageUsageCountBar, getCaptiveDashPlansUsageLineCharts })(PlansCharts)
);