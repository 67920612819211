import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { ServiceAccount, ServiceAccountType } from "../types";
import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Box, Button, Dialog, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, LinearProgress, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import HelpIcon from '@mui/icons-material/Help';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import { ServiceLine } from "../types";
import _ from "lodash";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import "./index.css";
import { bulkUpdateServiceLineOrganization, createServiceAccount, extractThroughCredential, getAllServiceLinesByCredential, getServiceLineByCredentialParameters, createSubOrg } from "../slice";
import OrgSelection from "../../OrgSelection";

interface ServiceAccountWorkflowDialogProps {
    authReducer: any;
    errorReducer: any;

    starlinkCloud: any;
    starlinkAdmin: any;
    serviceAccount: ServiceAccount | null;
    serviceLines: ServiceLine[];

    open: boolean;

    onClose: (refresh: boolean) => void;

    getAllServiceLinesByCredential: (parameters: getServiceLineByCredentialParameters, abortSignal: AbortSignal | undefined) => Promise<any>;
    createServiceAccount: (serviceAccount: ServiceAccount, abortSignal: AbortSignal | undefined) => Promise<any>;
    extractThroughCredential: (parameters: any, abortSignal: AbortSignal | undefined) => Promise<any>;
    bulkUpdateServiceLineOrganization: (parameters: any) => Promise<any>;
    createSubOrg: (parameters: any) => Promise<any>;
}

function ServiceAccountWorkflowDialog(props: ServiceAccountWorkflowDialogProps) {

    const { authReducer, errorReducer, starlinkAdmin, open, onClose, serviceLines,
        getAllServiceLinesByCredential, starlinkCloud, createServiceAccount, extractThroughCredential, bulkUpdateServiceLineOrganization, createSubOrg } = props;

    const [serviceAccountType, setServiceAccountType] = useState<ServiceAccountType>('service_account_client');
    const [userName, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [clientId, setClientId] = useState<string>('');
    const [clientSecret, setClientSecret] = useState<string>('');
    const [valid, setValid] = useState<boolean>(false);
    const [disableActions, setDisableActions] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedServiceLines, setSelectedServiceLine] = useState<ServiceLine[]>([])
    const [selectedDp, setSelectedDp] = useState<any>(null);
    const [requestAbortController, setRequestAbortController] = useState<AbortController | null>(null);
    const [credentialError, setCredentialError] = useState<string>('');
    const [validating, setValidating] = useState<boolean>(false);
    const [extractError, setExtractError] = useState<string>('');
    const [extracting, setExtracting] = useState<boolean>(false);
    const [newSubOrgNameValidationError, setNewSubOrgNameValidationError] = useState<string>('');

    const [makeNewSubOrg, setMakeNewSubOrg] = useState<boolean>(false);
    const [newSubOrgName, setNewSubOrgName] = useState<string>('');


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleClose = (refresh: boolean = false) => (event: {}, reason: "backdropClick" | "escapeKeyDown"): void => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        requestAbortController?.abort();
        onClose(refresh);
    }

    const handleServiceAccountLink = async () => {
        setCredentialError('');
        let _serviceAccount = {
            id: '',
            dpId: authReducer.selectedOu.id,
            type: serviceAccountType,
            userName,
            clientId,
            clientSecret,
            password,
            validate: true,
        }
        setValidating(true);
        let _abortController = new AbortController();
        setRequestAbortController(_abortController);
        let res = await createServiceAccount(_serviceAccount, _abortController?.signal);
        if (res?.success) {
            setActiveStep(1);
            handleSubOrgStepInitialization();
        } else if (res?.response?.data?.message) {
            setCredentialError(res?.response?.data?.message);
        }
        setValidating(false);
    }

    const handleSubOrgStepInitialization = async () => {
        let _abortController = new AbortController();
        setRequestAbortController(_abortController);
        setDisableActions(true);
        setExtractError('');

        setExtracting(true);
        let res = await extractThroughCredential({ username: userName, clientId, skipUsage: true }, _abortController?.signal);
        setExtracting(false);

        if (res?.success) {
            // get service lines
        } else if (res?.response?.data?.message) {
            setExtractError(res?.response?.data?.message);
        } else {
            setExtractError('Failed to fetch service lines');
        }

        if (!_.isEmpty(starlinkCloud.dpIds)) {
            await getAllServiceLinesByCredential({ username: userName, clientId }, _abortController?.signal);
        }
    }

    const handleChangeServiceAccountType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceAccountType((event.target as HTMLInputElement).value as ServiceAccountType);
        setCredentialError('');
        setUserName('');
        setPassword('');
        setClientId('');
        setClientSecret('');
    };

    const handleChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(event.target.value);
        setCredentialError('');
    };

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setCredentialError('');
    };

    const handleChangeClientId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientId(event.target.value);
        setCredentialError('');
    };

    const handleChangeClientSecret = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientSecret(event.target.value);
        setCredentialError('');
    };

    const handleChangeSelectedServiceLines = (event: SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {

        if (reason === "selectOption" || reason === "removeOption") {
            if ((value as ServiceLine[]).find(option => option.serviceLineNumber === "all")) {
                return setSelectedServiceLine(serviceLines);
            } else {
                return setSelectedServiceLine(value as ServiceLine[]);
            }
        } else if (reason === "clear") {
            setSelectedServiceLine([]);
        }
    }

    const handleChangeDp = (ou: any) => {
        setSelectedDp(ou)
    }

    const handleNewSubOrgInit = () => {
        setMakeNewSubOrg(true);
    }

    const handleNewSubOrgCreationCancel = () => {
        setMakeNewSubOrg(false);
        setNewSubOrgNameValidationError('');
    }

    const handleChangeNewSubOrgName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewSubOrgName(event.target.value);
        if(event.target.value.match(/[^A-Za-z0-9\s]/)){
            setNewSubOrgNameValidationError('Only alphanumeric characters are allowed');
        } else {
            setNewSubOrgNameValidationError('');
        }
    }

    const handleNewSubOrgSave = async () => {
        setDisableActions(true);
        setExtractError('');

        if (!_.isEmpty(selectedDp)) {
            let res = await createSubOrg({
                DPName: newSubOrgName,
                DPFullName: newSubOrgName,
                parentName: selectedDp.name
            })
            let _level = selectedDp.level + 1;
            if (res?.status === 1) {
                setSelectedDp({
                    id: res?.data?.data?.dpId,
                    name: newSubOrgName,
                    level: _level,
                    hasChildren: false,
                })
            } else if (res?.response?.data?.message) {
                setExtractError(res?.response?.data?.message);
            } else {
                setExtractError('Failed to create Sub-Organization');
            }
        }
        setDisableActions(false);
        setMakeNewSubOrg(false);
    }

    const handleAssignServiceLine = async () => {
        setDisableActions(true);
        setExtractError('');
        let res = await bulkUpdateServiceLineOrganization({
            dpId: selectedDp?.id,
            serviceLineNumbers: selectedServiceLines.map(serviceLine => serviceLine.serviceLineNumber),
        });

        if (res?.success) {
            onClose(true);
        } else if (res?.response?.data?.message) {
            setExtractError(res?.response?.data?.message);
        } else {
            setExtractError('Failed to assign Service Lines to Sub-Organization');
        }
        setRequestAbortController(null);
        setDisableActions(false);
    }


    useEffect(() => {
        if (open) {
            setRequestAbortController(new AbortController());
            setActiveStep(0);
            setDisableActions(false);
            setServiceAccountType('service_account_client');
            setUserName('');
            setPassword('');
            setClientId('');
            setClientSecret('');
            setNewSubOrgName('');
            setMakeNewSubOrg(false);
            setSelectedDp(null);
            setSelectedServiceLine([]);
            setValidating(false);
            setCredentialError('');
            setExtracting(false);
            setExtractError('');
        }
    }, [open]);

    useEffect(() => {
        setValid(
            activeStep === 0 ?
                (serviceAccountType === 'service_account_client' ? !!clientId && !!clientSecret : !!userName && !!password) :
                (selectedServiceLines.length > 0 && !!selectedDp)
        );
    }, [serviceAccountType, userName, password, clientId, clientSecret, activeStep, selectedServiceLines, selectedDp]);

    useEffect(() => {
        setDisableActions(
            starlinkAdmin.creatingServiceAccount ||
            starlinkAdmin.updatingServiceAccount ||
            starlinkAdmin.deletingServiceAccount
        );
    }, [starlinkAdmin.creatingServiceAccount, starlinkAdmin.updatingServiceAccount, starlinkAdmin.deletingServiceAccount]);

    return (
        <Dialog open={open} onClose={handleClose(true)} aria-labelledby="service_account--dialog" maxWidth="md" fullWidth>
            <Paper elevation={1} classes={{ root: 'starlink--base--padding_1x' }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key={'Link Service Account'}>
                        <StepLabel>Link Service Account</StepLabel>
                        <StepContent>
                            <Grid classes={{ root: 'starlink--base--padding_1x' }}>
                                <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                                    <FormControl disabled={disableActions}>
                                        <FormLabel id="starlink--service_account_type--radio_group">Account Type</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="starlink--service_account_type--radio_group"
                                            name="controlled-radio-buttons-group"
                                            value={serviceAccountType}
                                            onChange={handleChangeServiceAccountType}
                                        >
                                            <FormControlLabel value="service_account_client" control={<Radio />} label={
                                                <Grid classes={{ root: 'starlink--base--flex--gap--05x starlink--base--flex--align--center' }}>
                                                    Service Account Client
                                                    <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={<Grid>
                                                        <Typography component="div" className="starlink--base--font_08x starlink--base--padding_05x">
                                                            <p>To set up API access, log into your Starlink account home page.</p>
                                                            <p>In the section named "Your Service Accounts" select the "+" in the top right corner to create a new service account.</p>
                                                            <p>If you do not see the section "Your Service Accounts" reach out to your account manager to inquire about access.</p>
                                                        </Typography>
                                                    </Grid>}>
                                                        <HelpIcon fontSize="small" />
                                                    </Tooltip>
                                                </Grid>
                                            } />

                                            <FormControlLabel value="credential" control={<Radio />} label="Web Portal" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid>
                                    {
                                        serviceAccountType === 'service_account_client' ?
                                            <Grid container spacing={1} classes={{ root: 'starlink--base--mtb_07x' }}>
                                                <Grid item md={6}>
                                                    <TextField
                                                        disabled={disableActions}
                                                        fullWidth
                                                        size="small"
                                                        id="starlink--service_account--client_id"
                                                        label="Client ID"
                                                        value={clientId}
                                                        onChange={handleChangeClientId}
                                                        error={credentialError?.length > 0}
                                                         autoComplete="new-client-id"
                                                    />
                                                </Grid>
                                                <Grid item md={6}>
                                                    <TextField
                                                        disabled={disableActions}
                                                        fullWidth
                                                        type="password"
                                                        size="small"
                                                        id="starlink--service_account--client_secret"
                                                        label="Client Secret"
                                                        value={clientSecret}
                                                        onChange={handleChangeClientSecret}
                                                        error={credentialError?.length > 0}
                                                        autoComplete="new-password" 
                                                    />
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container spacing={1} classes={{ root: 'starlink--base--mtb_07x' }}>
                                                <Grid item md={6}>
                                                    <TextField
                                                        disabled={disableActions}
                                                        fullWidth
                                                        size="small"
                                                        id="starlink--service_account--user_name"
                                                        label="Email"
                                                        value={userName}
                                                        onChange={handleChangeUserName}
                                                        error={credentialError?.length > 0}
                                                    />
                                                </Grid>
                                                <Grid item md={6}>
                                                    <TextField
                                                        disabled={disableActions}
                                                        fullWidth
                                                        type="password"
                                                        size="small"
                                                        id="starlink--service_account--password"
                                                        label="Password"
                                                        value={password}
                                                        onChange={handleChangePassword}
                                                        error={credentialError?.length > 0}
                                                    />
                                                </Grid>
                                            </Grid>
                                    }
                                    <Grid>
                                        <FormHelperText error={credentialError?.length > 0}>
                                            <Typography component="div" className="starlink--base--text_center starlink--base--font_08x">
                                                {credentialError}
                                            </Typography>
                                        </FormHelperText>

                                    </Grid>
                                    {
                                        validating ? <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                                            <Typography component="div" className="starlink--base--font_07x">
                                                Validating...
                                            </Typography>
                                            <LinearProgress />
                                        </Grid> : null
                                    }
                                </Grid>
                                <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end' }}>
                                    <Button variant="outlined" className='starlink--button--cancel' onClick={() => { onClose(false) }}>Cancel</Button>
                                    <Button variant="contained" className='starlink--button_contained--primary' onClick={handleServiceAccountLink} disabled={!valid || disableActions}>Add</Button>
                                </Grid>
                            </Grid>
                        </StepContent>
                    </Step>
                    <Step key={'Associate Service Line to Sub-Organization'}>
                        <StepLabel>Associate Service Line to Sub-Organization</StepLabel>
                        <StepContent>
                            {
                                extracting ? <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                                    <Typography component="div" className="starlink--base--font_07x">
                                        Fetching Service Lines...
                                    </Typography>
                                    <LinearProgress />
                                </Grid> : null
                            }
                            <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                                <Typography component="div" className="starlink--base--font_08x">Assign Service Lines to the Sub-Organizations that will be using them. This will allow you to control which Sub-Organizations have access to which Service Lines. You can assign multiple Service Lines to a single Sub-Organization.</Typography>
                            </Grid>
                            <Grid container classes={{ root: 'starlink--base--mtb_07x' }}>
                                <Grid item xs={5}>
                                    <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                                        <OrgSelection label={makeNewSubOrg ? "Parent Organization" : "Sub-Organization"} value={selectedDp} onChange={handleChangeDp} disabled={disableActions} />
                                    </Grid>
                                    <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                                        {
                                            makeNewSubOrg ? <TextField
                                                disabled={disableActions}
                                                fullWidth
                                                size="small"
                                                id="starlink--service_account--sub_org_name"
                                                label="Sub-Organization Name"
                                                value={newSubOrgName}
                                                onChange={handleChangeNewSubOrgName}
                                                error={newSubOrgNameValidationError.length > 0}
                                            /> : null
                                        }
                                        <FormHelperText error={newSubOrgNameValidationError?.length > 0}>
                                            <Typography component="div" className="starlink--base--text_center starlink--base--font_08x">
                                                {newSubOrgNameValidationError}
                                            </Typography>
                                        </FormHelperText>
                                    </Grid>
                                    <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--start' }}>
                                        {
                                            makeNewSubOrg ? <Fragment>
                                                <Button size="small" variant="outlined" className='starlink--button--cancel' onClick={handleNewSubOrgCreationCancel} disabled={disableActions}>Cancel</Button>
                                                <Button size="small" variant="contained" className='starlink--button_contained--primary' onClick={handleNewSubOrgSave} disabled={disableActions || newSubOrgNameValidationError.length > 0 || newSubOrgName.length === 0 || selectedDp === null}>Create</Button>
                                            </Fragment> : <Button size="small" variant="contained" className='starlink--button_contained--primary' onClick={handleNewSubOrgInit} disabled={disableActions}>New Sub-Organization</Button>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} classes={{ root: 'starlink--base--flex--justify--center' }}>
                                    <Grid classes={{ root: 'starlink--base--flex--align--center' }}>
                                        <KeyboardBackspaceIcon />
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'} height={'100%'}>
                                        <Autocomplete
                                            id="service_line"
                                            multiple
                                            disableCloseOnSelect
                                            fullWidth                                            
                                            disabled={disableActions}
                                            options={serviceLines.length > 0 ? [{ serviceLineNumber: 'all', serviceLineName: 'Select All'}, ...serviceLines] as ServiceLine[] : []}
                                            value={selectedServiceLines}
                                            onChange={handleChangeSelectedServiceLines}
                                            size="small"
                                            getOptionLabel={(option: ServiceLine) => option.serviceLineName}
                                            renderInput={(params) => <TextField {...params } label="Service Line" variant="outlined" />}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid>
                                <FormHelperText error={extractError?.length > 0}>
                                    <Typography component="div" className="starlink--base--text_center starlink--base--font_08x">
                                        {extractError}
                                    </Typography>
                                </FormHelperText>
                            </Grid>
                            <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end' }}>
                                <Button variant="outlined" className='starlink--button--cancel' onClick={() => { onClose(true) }}>Skip</Button>
                                <Button variant="contained" className='starlink--button_contained--primary' onClick={handleAssignServiceLine} disabled={makeNewSubOrg || !valid || disableActions}>Assign</Button>
                            </Grid>
                        </StepContent>
                    </Step>
                </Stepper>
            </Paper>
        </Dialog>
    )
}

const mapStateToProps = (state: any) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    starlinkCloud: state.starlinkCloud,
    starlinkAdmin: state.starlinkAdmin,
    serviceLines: state.starlinkAdmin.allServiceLines,
});

export default withRouter(
    connect(mapStateToProps, {
        getAllServiceLinesByCredential,
        createServiceAccount,
        extractThroughCredential,
        bulkUpdateServiceLineOrganization,
        createSubOrg
    })(ServiceAccountWorkflowDialog)
);
