import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { getCaptiveDashUserSessionsTable } from "../../../actions/Users/authenticateCaptiveDahshboard";
import _ from "lodash";
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, Tooltip, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../../../components/Pagination";
import { getDecodeURI, getEncodedURI, convertDateTimeIntoTimezone } from "../../../utils/util";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../../../components/DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import ImageWithFallback from "../../../components/ImageWithFallback";
import CircleIcon from '@mui/icons-material/Circle';
import { DEFAULT_DATE_FORMAT, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import { JsonDataTooltip } from "../../../components/JSONTooltip/JsonTooltip";

const SessionsTable = (props) => {
    const { userTimezone, getCaptiveDashUserSessionsTable, newSummaryStartDate, newSummaryEndDate, getCDUserSessionsTable, setCaptiveDashUserSessionsTableLoading, csvDataDownloded, csvDownloadId, downloadAsCSV, location, history, selectedRow } = props;

    const _q = getDecodeURI(location?.search);
    const DEFAULT_PAGE_SIZE = 10;
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const ENTER_KEY_CODE = "Enter";
    const [tableData, setTableData] = useState<any>([]);
    const [count, setCount] = useState(0);
    const PLAN_NAME = 'planName';
    const PLAN_USAGE = 'planUsage';
    const SESSION_START = 'logInDate';
    const LOGGED_IN = 'loggedIn';
    const LAST_ACTIVITY = 'lastActivityAt';
    const TOP_APPS = 'top3Apps';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : LAST_ACTIVITY);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const dispatch = useDispatch();
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const userPlanId = _q.hasOwnProperty('userPlanId') ? _q.userPlanId : '';

    useEffect(() => {
        getCaptiveDashUserSessionsTable(newSummaryStartDate, newSummaryEndDate, 1, limit, sort, sortOrder, searchValue, userPlanId)
    }, [newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        if(!_.isEmpty(getCDUserSessionsTable)) {
            const data = getCDUserSessionsTable.hasOwnProperty('data') ? getCDUserSessionsTable.data : [];
            setCount(data?.[0]?.['totalCount']);
            setTableData(data);
        }
    }, [getCDUserSessionsTable])

    const convertToCsv = (data: any[][]) => {
        const [_columns, ...rows] = data;
        const csvRows: any[] = [];
        rows.forEach(row => {
            const osDetails = row[12] && JSON.parse(row[12]);
            const osColumn = Object.entries(osDetails)
            .map(([key, value]) => `${key}: '${value}'`)
            .join('\n');
            const formattedOsColumn = `"${osColumn.replace(/"/g, '""')}"`;
            csvRows.push([row[4], row[7], `${convertDateTimeIntoTimezone(row[9], userTimezone, DEFAULT_DATE_FORMAT)}`, formattedOsColumn, (row[5] && row[6] ? `${row[5]} / ${row[6]}` : row[5]), row[10] == 1 ? "Yes" : "No", `${convertDateTimeIntoTimezone(row[11], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}`, row[13]?.replaceAll(',', ' | ')])
        })
        return [['Plan Name', 'Session Usage (GB)', 'Session Start', 'OS', 'IP / MAC', 'Logged In', `Last Activity (${userTimezone})`, 'Top Apps'], ...csvRows]
    }

    useEffect(() => {
        if (csvDataDownloded && csvDataDownloded.length > 0) {
            if (csvDownloadId === 'captive-dashboard-subscribers-sessions-table') {
                const [columns, _countRow, ...rows] = csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows]), {
                    formatters : {
                        0: 'ESC-COMMA',
                        6: 'ESC-COMMA',
                    }
                });
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [csvDataDownloded])

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        const Page = 1;
        setPage(1);
        doNavigate(params);
        getCaptiveDashUserSessionsTable(newSummaryStartDate, newSummaryEndDate, Page, limit, sort, sortOrder, value, userPlanId)
    };

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 1;
        setPage(1);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        getCaptiveDashUserSessionsTable(newSummaryStartDate, newSummaryEndDate, Page, limit, field, order, searchValue, userPlanId)
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const handleChangePage = (e, value) => {
        setPage(value);
        let params: any = getDecodeURI(location?.search);
        value == 1 ? delete params.page : params.page = value;
        doNavigate(params);
        getCaptiveDashUserSessionsTable(newSummaryStartDate, newSummaryEndDate, value, limit, sort, sortOrder, searchValue, userPlanId)
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        Limit == 10 ? delete params.limit : params.limit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        getCaptiveDashUserSessionsTable(newSummaryStartDate, newSummaryEndDate, Page, Limit, sort, sortOrder, searchValue, userPlanId)
    }

    const handleDownloadReport = () => {
        let isClickHouse = true;
        const startDate = newSummaryStartDate;
        const endDate = newSummaryEndDate;
        const params: CsvParameters = {
            type: isClickHouse? 'QUERY_CH' : "QUERY",
            id : "captive-dashboard-subscribers-sessions-table",
            queryName: 'CAPTIVE_DASHBOARD_SUBSCRIBER_SESSIONS_TABLE',
            payload: {
                "startDate": startDate,
                "endDate": endDate,
                "pageOffset": 0,
                "pageSize": count,
                "sortColumn": sort,
                "sortOrder": sortOrder,
                "search": searchValue,
                "userPlanId": `'${userPlanId}'`
              }
        }
        downloadAsCSV(params);
    }

    const rowsOption = [10, 15, 25, 50, 100]

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    function GetTopAppsImage(props) {
        const { app } = props;
        if (app) {
            let appName = app.includes(' ') ? app.split(' ')[0] : app.includes('-') ? app.split('-')[0] : "";
            return <ImageWithFallback src={`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${app.trim()}`} fallbackSrcs={[`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${appName.trim()}`, "https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png"]} app={app} />
        } else {
            return <img className="logo-activity paddingLogo" src={`https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png`} />
        }
    }

    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container topbarMarginPaddingReset alignTopbarItemsCenter">
                    <Grid item xs={5} sm={5} md={5} lg={5} className="site-details-header usage-site-details-header">{`Subscriber: ${selectedRow?.subscriber}`}</Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7} className="usage-table-search-download-div">
                        <Grid className="usage-search-border-color">
                            <Box className="searchbarBox">
                                {<TextField
                                    id="outlined-basic"                                
                                    variant="outlined"
                                    placeholder="Type something"
                                    autoComplete="off"
                                    size="small"
                                    value={searchValue}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel1" }}
                                    InputProps={{
                                        className: "searchbarInputs",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchValue && <SearchIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchValue && <CloseIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        {count > 0 && <Grid>
                            <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner tablePaddingReset">
                    <Paper className="pagination-position config-pagination new-usage remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={count ? Number(count) : 0}
                            rowsPerPage={limit ? limit : 0}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer captiveSessions-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left text-align-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, PLAN_NAME)}>Plan Name</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === PLAN_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, PLAN_NAME, ASC_ORDER)} />
                                            <img src={sort === PLAN_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, PLAN_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left text-align-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, PLAN_USAGE)}>Session Usage(GB)</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === PLAN_USAGE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, PLAN_USAGE, ASC_ORDER)} />
                                            <img src={sort === PLAN_USAGE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, PLAN_USAGE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left text-align-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SESSION_START)}>Session Start</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SESSION_START && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SESSION_START, ASC_ORDER)} />
                                            <img src={sort === SESSION_START && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, SESSION_START, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                        <span style={{ whiteSpace: "nowrap" }}>
                                            <span className="font-wt-900 topAppsFonts" >OS</span>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                        <span>
                                            <span className="font-wt-900 topAppsFonts" >IP / MAC</span>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left text-align-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, LOGGED_IN)}>Logged In</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === LOGGED_IN && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, LOGGED_IN, ASC_ORDER)} />
                                            <img src={sort === LOGGED_IN && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, LOGGED_IN, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable login-left-align login-tableCell new-summary-left width-sessions-last-activity">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, LAST_ACTIVITY)}>Last Activity</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === LAST_ACTIVITY && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, LAST_ACTIVITY, ASC_ORDER)} />
                                            <img src={sort === LAST_ACTIVITY && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, LAST_ACTIVITY, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left text-align-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, TOP_APPS)}>Top Apps</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === TOP_APPS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, TOP_APPS, ASC_ORDER)} />
                                            <img src={sort === TOP_APPS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, TOP_APPS, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {count > 0 && tableData && 1 != tableData?.length && !setCaptiveDashUserSessionsTableLoading ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">
                                    {
                                        tableData && tableData?.map((item, i) => (
                                            i > 0 && <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity elipseThreat'>{item?.planName}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.planUsage}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{convertDateTimeIntoTimezone(item?.logInDate, userTimezone, DEFAULT_DATE_FORMAT)}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                                    {(() => {
                                                        const deviceInformation = item?.['deviceInformation'] ? JSON.parse(item['deviceInformation']) : {};
                                                        return (
                                                            <div className="device-type-metadata">
                                                                <span className="inter-font device-type-padding-left-0">{deviceInformation.os}</span>
                                                                {deviceInformation?.os && <JsonDataTooltip data={deviceInformation} />}
                                                            </div>
                                                        );
                                                    })()}
                                                </TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.ipAddress && item?.macAddress ? `${item.ipAddress} / ${item.macAddress}` : item?.ipAddress && !item?.macAddress ? `${item.ipAddress}` : ''}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                                    <div className="hub-row-internetStatus">
                                                        <CircleIcon id={item.loggedIn == 1 ? "greenDotSymbol" : item.loggedIn == 0 ? "redDotSymbol" : ''} />
                                                        <span>{item?.loggedIn == 1 ? 'Yes' : 'No'}</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell sx={{textAlign: 'left'}} className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{convertDateTimeIntoTimezone(item?.lastActivityAt, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>
                                                    <Grid className="tableSites-topIcons activity-top-sites">
                                                        { tableData[i]?.top3Apps ? tableData[i]?.top3Apps?.split(',')?.map((app) => (
                                                            <Tooltip title={app}>
                                                                <GetTopAppsImage app={app} />
                                                            </Tooltip>
                                                        )) : null}
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
  userTimezone: state.authReducer.userTimezone,
  newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
  newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
  getCDUserSessionsTable: state?.authReducer?.getCDUserSessionsTable,
  setCaptiveDashUserSessionsTableLoading: state?.authReducer?.setCaptiveDashUserSessionsTableLoading,
  csvDataDownloded: state?.authReducer?.csvDataDownloded,
  csvDownloadId: state?.authReducer?.csvDownloadId
});

export default withRouter(
  connect(mapStateToProps, { 
    getCaptiveDashUserSessionsTable, downloadAsCSV
 })(SessionsTable)
);