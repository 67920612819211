// Table of Summary vessels
import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails, Tooltip, TableHead, Stack, Box, Paper, Divider, Chip, ToggleButtonGroup, ToggleButton, ListItemButton, List } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from "lodash";
import "./TableSummary.css";
import { readableBytes, readableBits, readableBytesAsGB, convertDateTimeIntoTimezone, redirectToTopolgyTabs } from '../../utils/util';
import { getLinkStatusForDashBoard, getWanProfilesPerDevice, getDashboardAdvancedBonding, getDashboardLANStatus, getDashboardConnectedHub, getVesselEdgeTopologyProducts } from '../../actions/Users/authenticateDashboard';
import { connect } from "react-redux";
import syncImg from "../../asset/image/sync.svg";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircleIcon from '@mui/icons-material/Circle';
import { YYYYMMDD_HHmmss } from "../../utils/constants";
import { Link, useLocation } from "react-router-dom";
var Loader = require("react-loader");

interface DeviceListProps {
  data: any;
  interval: any;
  getLinkStatusForDashBoard?: any;
  k4Id: any;
  linkStatusPerDevice: any;
  getWanProfilesPerDevice: any;
  wanProfilesPerDevice: any;
  getDashboardAdvancedBonding: any;
  getAdvancedBonding: any;
  getDashboardLANStatus: any;
  getLANStatus: any;
  getDashboardConnectedHub: any;
  getConnectedHub: any;
  getVesselEdgeTopologyProducts: any;
  vesselEdgeTopologyProducts: any;
  selectedouId: any;
  loading: boolean;
  linkStatusPerDeviceLoading: boolean;
  setLANStatusLoading: boolean;
  setWanProfilePerDeviceLoading: boolean;
  setAdvancedBondingLoading: boolean;
  setConnectedHubLoading: boolean;
  userTimezone: any;
}

export interface IdentfierType {
  deviceName: string;
  deviceId: string;
  isParent: boolean;
}

type TabType = 'interface' | 'profile';

export const GenericAccordionComp = connect(() => ({}), { getLinkStatusForDashBoard, getWanProfilesPerDevice, getDashboardAdvancedBonding, getDashboardLANStatus, getDashboardConnectedHub, getVesselEdgeTopologyProducts })((props: DeviceListProps) => {


  const { k4Id, getLinkStatusForDashBoard, linkStatusPerDevice, interval, data, getWanProfilesPerDevice, wanProfilesPerDevice, getDashboardAdvancedBonding, getAdvancedBonding, getDashboardLANStatus, getLANStatus, getDashboardConnectedHub, getConnectedHub, getVesselEdgeTopologyProducts, vesselEdgeTopologyProducts, selectedouId, loading, linkStatusPerDeviceLoading, setLANStatusLoading, setWanProfilePerDeviceLoading, setAdvancedBondingLoading, setConnectedHubLoading, userTimezone } = props;
  const [selectedIdentifier, setSelectedIdentifier] = useState<IdentfierType | null>(null);
  const [selectedTab, setSelectedTab] = useState<TabType>('interface');
  const deviceIdentifiers = React.useMemo<IdentfierType[]>(() => _.isString(data?.product_device) && data.product_device.split('|').map((identifier => {
    const [deviceName = '', deviceId = ''] = identifier.split('=');
    const isParent = data.parent === deviceName
    return {
      deviceId,
      deviceName,
      isParent
    }
  })), [data]);
  const [wanProfilesList, setWanProfilesList] = useState([]);
  const [isAdvBondingEnabled, setIsAdvBondingEnabled] = useState(false);
  const [LANSTatus, setLANStatus] = useState(0);
  const [connectedHub, setConnectedHub] = useState({name: '', ip_port_list: ''})

  useEffect(() => {
    if (deviceIdentifiers && deviceIdentifiers.length === 0) {
      setSelectedIdentifier(null);
      return;
    }
    setSelectedIdentifier(deviceIdentifiers[0]);
  }, [deviceIdentifiers]);

  useEffect(() => {
    if (selectedIdentifier?.deviceId) {
      if (selectedTab == 'profile') {
        getWanProfilesPerDevice(k4Id, selectedIdentifier?.deviceId);
        getDashboardAdvancedBonding(k4Id, selectedIdentifier?.deviceId);
        getDashboardConnectedHub(k4Id, selectedIdentifier?.deviceId);
      }
    }
  }, [selectedIdentifier?.deviceId, selectedTab])

  const handleRefresh = (e: React.MouseEvent, deviceID: string) => {
    e.stopPropagation();
    if (selectedTab == 'profile') {
      getWanProfilesPerDevice(k4Id, deviceID);
      getDashboardAdvancedBonding(k4Id, deviceID);
      getDashboardConnectedHub(k4Id, deviceID);
    } else {
      getLinkStatusForDashBoard(deviceID, interval?.label, data.k4Id);
      getDashboardLANStatus(k4Id, deviceID);
    }
    getVesselEdgeTopologyProducts(selectedouId, k4Id)
  }

  const handleOnSelectItem = (identifier: IdentfierType) => {
    setSelectedIdentifier(identifier);
  }

  const handleOnTabChange = (
    _: React.MouseEvent<HTMLElement>,
    value: TabType,
  ) => {
    setSelectedTab(value);
  };

  const groupByPriority = (items) => {
    return items.reduce((grouped, item) => {
      // Check if priority group exists, if not, create an empty array
      if (!grouped[item.priority]) {
        grouped[item.priority] = [];
      }
      // Push item to its respective priority group
      grouped[item.priority].push(item);
      return grouped;
    }, {});
  }

  useEffect(() => {
    if(!_.isEmpty(wanProfilesPerDevice)) {
      const wanProfiles = wanProfilesPerDevice.hasOwnProperty('data') ? wanProfilesPerDevice.data : {};
      if (Object.keys(wanProfiles)?.length > 0) {
        let resultArr: any = Object.keys(wanProfiles)?.map((el) => {
          let row: any = {};
          row.name = el;
          let priorities: any = [];
          let networks: any = [];
          wanProfiles[el]['rows']?.forEach((item) => {
            let priority: any = {};
            priority[wanProfiles[el]['columns'][3]] = item[3]
            priority[wanProfiles[el]['columns'][4]] = item[4]
            priority[wanProfiles[el]['columns'][5]] = item[5]
            priorities.push(priority)
          })
          let prioritylist = groupByPriority(priorities)
          row.profiles = prioritylist;

          wanProfiles[el]['rows'][0][1] && wanProfiles[el]['rows'][0][1]?.split(",")?.length > 0 && wanProfiles[el]['rows'][0][1]?.split(",")?.forEach((data) => {
            networks.push(data)
          })
          row.networks = networks;
          return row
        })
        setWanProfilesList(resultArr);
      }
    }
    if(!_.isEmpty(getAdvancedBonding)) {
      const advancedBonding = getAdvancedBonding.hasOwnProperty('data') ? getAdvancedBonding.data : {};
      setIsAdvBondingEnabled(advancedBonding?.rows[0]?.[0])
    }
    if(!_.isEmpty(getLANStatus)) {
      const LANStatus = getLANStatus.hasOwnProperty('data') ? getLANStatus.data : {};
      setLANStatus(LANStatus?.rows[0]?.[0])
    }
    if(!_.isEmpty(getConnectedHub)) {
      const ConnectedHub = getConnectedHub.hasOwnProperty('data') ? getConnectedHub.data : {};
      setConnectedHub({name: ConnectedHub?.rows[0]?.[0], ip_port_list: ConnectedHub?.rows[0]?.[1]})
    }
 }, [wanProfilesPerDevice, getAdvancedBonding, getLANStatus, getConnectedHub])

  useEffect(() => {
    getVesselEdgeTopologyProducts(selectedouId, k4Id)
  }, [])

  const getKonnectStatus = (selectedDevice) => {
    const data = vesselEdgeTopologyProducts;
    let arr: any = [];
    data?.manage_location?.[0]?.products?.forEach((product) => {
      return product?.devices?.forEach((device) => {
        if(device?.id == selectedDevice?.deviceId) {
          device.konnectStatus == 'STATUS_CONNECTED' ? arr.push('green') : device.konnectStatus == 'STATUS_NOT_CONNECTED' ? arr.push('red') : arr.push('grey')
        }
      })
    })
    if(arr?.length > 0) return arr.join(",");
    else return 'grey';
  }

  return <Grid container spacing={1}>
  <Grid xs={3} className="menuScroll device-border-right" item>
    <Grid className="device-data-body">
      <List>
        {deviceIdentifiers && deviceIdentifiers.map((identifier, index) => {
          return <ListItemButton className='status-lists' style={identifier === selectedIdentifier ? {background:'#F4F5FA'} : {background:'#fff'}} key={`device-${index}`} selected={identifier === selectedIdentifier} onClick={() => handleOnSelectItem(identifier)}>
            <CircleIcon id={`${getKonnectStatus(identifier)}DotSymbol`} />
            <Tooltip title={identifier.isParent ? 'Parent' : null}>
              <Typography className="font-status-pop-up" style={identifier === selectedIdentifier ? {fontWeight:'Bold'} : {fontWeight:'400'}}>{identifier.deviceName}</Typography>
            </Tooltip>
            {<div style={{ marginLeft: 'auto' }} onClick={(e) => handleRefresh(e, identifier.deviceId)}>
              <img src={syncImg} alt='refresh' />
            </div>}
          </ListItemButton>
        })}
      </List>
    </Grid>
  </Grid>
  <Grid item xs={9} className="menuScroll">
    <Grid className="device-data-body">
      <Stack className="sub-tab-parent" direction="column" spacing={1}>
        <ToggleButtonGroup value={selectedTab} exclusive onChange={handleOnTabChange} className="tabs-toggle-btns">
          <ToggleButton className="wan-tab-btns sub-tab-width" id={selectedTab === 'interface' ? 'interface_select' : 'interface_unselect'} value="interface" aria-label="centered">
            Interfaces
          </ToggleButton>
          <ToggleButton className="wan-tab-btns sub-tab-width" id={selectedTab === 'profile' ? 'interface_select' : 'interface_unselect'} value="profile" aria-label="centered">
            Profiles
          </ToggleButton>
        </ToggleButtonGroup>
          {loading || linkStatusPerDeviceLoading || setLANStatusLoading || setWanProfilePerDeviceLoading || setAdvancedBondingLoading || setConnectedHubLoading ? (
            <div className="commonLoader_NewSummary wan-profile-interfaces-loader">
            <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} />
          </div>
          ) : null}
        {selectedTab === 'interface' && <DeviceItemDetails identifier={selectedIdentifier} interval={interval} k4Id={data.k4Id} siteName={data.sitename} LANSTatus={LANSTatus} userTimezone={userTimezone} />}
        {selectedTab === 'profile' && <WanProfileDetails wanProfilesList={wanProfilesList} isAdvBondingEnabled={isAdvBondingEnabled} connectedHub={connectedHub} />}
      </Stack>
    </Grid>
  </Grid>
</Grid>
})

const mapStateToProps = (state) => ({
  linkStatusPerDevice: state.authReducer?.linkStatusPerDevice,
  wanProfilesPerDevice: state.authReducer?.wanProfilesPerDevice,
  getAdvancedBonding: state.authReducer?.getAdvancedBonding,
  getLANStatus: state.authReducer?.getLANStatus,
  getConnectedHub: state.authReducer?.getConnectedHub,
  selectedouId: state.authReducer?.selectedOu?.id,
  vesselEdgeTopologyProducts: state.authReducer?.vesselEdgeTopologyProducts?.data,
  loading: state.authReducer?.loading,
  linkStatusPerDeviceLoading: state.authReducer?.linkStatusPerDeviceLoading,
  setLANStatusLoading: state.authReducer?.setLANStatusLoading,
  setWanProfilePerDeviceLoading: state.authReducer?.setWanProfilePerDeviceLoading,
  setAdvancedBondingLoading: state.authReducer?.setAdvancedBondingLoading,
  setConnectedHubLoading: state.authReducer?.setConnectedHubLoading,
  userTimezone: state.authReducer.userTimezone
});

export const DeviceItemDetails = connect(mapStateToProps, { getLinkStatusForDashBoard, getDashboardLANStatus })((props: any) => {
  const { identifier, interval, linkStatusPerDevice, k4Id, siteName, getLinkStatusForDashBoard, getDashboardLANStatus, LANSTatus, userTimezone } = props;
  const [devices, setDevices] = useState<any[]>([]);
  const location = useLocation();
  useEffect(() => {
    if (linkStatusPerDevice && linkStatusPerDevice[identifier?.deviceId]) {
      let perDeviceDetails = linkStatusPerDevice[identifier?.deviceId]?.data?.rows.map((item) => {
        return linkStatusPerDevice[identifier?.deviceId]?.data?.columns.reduce((acc, columItem, i) => {

          acc[columItem] = item[i];
          return acc;
        }, {})
      })
      setDevices(perDeviceDetails);
    }

  }, [linkStatusPerDevice, identifier])


  useEffect(() => {
    if (identifier?.deviceId && interval?.label && k4Id) {
      getLinkStatusForDashBoard(identifier?.deviceId, interval?.label, k4Id);
      getDashboardLANStatus(k4Id, identifier?.deviceId);
    }
  }, [identifier, interval, k4Id])

  const statusData = {
    bad: { color: "#ffe7e7", label: "Bad" },
    poor: { color: "#ffbf01", label: "Poor" },
    average: { color: "rgb(251 251 125)", label: "Average" },
    good: { color: "#01b0f1", label: "Good" },
    great: { color: "#00af51", label: "Great" },
    "": { color: "#f0f1f3", label: "Link Down" },
  };

  return <div>
    <div className="LAN-Status-div">
      <CircleIcon id={`${LANSTatus == 1 ? 'greenDotSymbol' : 'redDotSymbol'}`} />
      <span>LAN Status</span>
    </div>
    <div className="table-parent">
      <TableContainer>
        <TableHead className="tableHead" style={{backgroundColor: "#EFEFEF"}}>
          <TableRow className="tableHeaderFleetVesselData onlinePopupPadding">
            <TableCell align="center" className="fleetDataTable accordianWidth">
              <span className="justfiyCenter">STATUS</span>
            </TableCell>
            <TableCell align="center" className="fleetDataTable accordianWidth">
              <span className="justfiyCenter">QOE</span>
            </TableCell>
            <TableCell className="fleetDataTable accordianWidth" align="center">
              <span className="justfiyCenter">UPTIME </span>
            </TableCell>
            <TableCell className="fleetDataTable accordianWidth" align="center">
              <span className="justfiyCenter">PROBE PASS</span>
            </TableCell>
            <TableCell className="fleetDataTable accordianWidth" align="center">
              <span className="justfiyCenter">THROUGHPUT</span>
            </TableCell>
            <TableCell className="fleetDataTable accordianWidth" align="center">
              <span className="justfiyCenter">SPEEDTEST RESULT</span>
            </TableCell>
            <TableCell className="fleetDataTable accordianWidth" align="center">
              <span className="justfiyCenter">SPEEDTEST SETTING</span>
            </TableCell>
            <TableCell className="fleetDataTable accordianWidth" align="center">
              <span className="justfiyCenter">LATENCY</span>
            </TableCell>
            <TableCell className="fleetDataTable accWidUsage" align="right">
              <span className="justfiyCenter padRight35">USAGE</span>
            </TableCell>
          </TableRow>
        </TableHead>
          <TableBody>
            {devices && Array.isArray(devices) && devices.length > 0 ? devices.map((device) => (
              <TableRow
                className="fleetRowRank onlinePopupPadding" >
                <TableCell align="center" className="accordianWidth">
                  <div className="vesselNameBox">
                    {
                      device.hasOwnProperty("color") && device.color === "RED" ? (<Tooltip title='Link Status: Down' placement="right"><CircleIcon id="redDotSymbol" /></Tooltip>)
                        : device.hasOwnProperty("color") && device.color === "GREEN" ? (<Tooltip title='Link Status: Up' placement="right"><CircleIcon id="greenDotSymbol" /></Tooltip>)
                          : device.hasOwnProperty("color") && device.color === "BULLSEYE" ? (<Tooltip title='Link Status : Up (Active)' placement="right"><RadioButtonCheckedIcon className="bullseye-color" /></Tooltip>)
                            // : device?.uptime > 0  ? (<span className="dot tooltipNewAcc" style={{ background: "green" }}><div className="tooltiptextAcc">Link Status: Up</div></span>)
                            : (<Tooltip title='Link Status: No Data' placement="right"><CircleIcon id="greyDotSymbol" /></Tooltip>)
                    }
                    <Tooltip title={device.displayName}>
                      <div className="identifier-font nowrapDisplayName online-status-wan-ellipsis">{device.displayName}</div>
                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell align="center" className="accordianWidth">
                  <Typography className="identifier-font qoe-display-flex">
                    {device.hasOwnProperty("QoEStreaming") && device.QoEStreaming ? (<>
                      <Tooltip title={`Score: ${device.QoEStreamingPoints} (${statusData[`${device.QoEStreaming}`]?.label})`} arrow placement="top">
                        <CircleIcon id="qoe-bacis-css" style={{ color: `${statusData[`${device.QoEStreaming}`]?.color || "#000"}`, boxShadow: `0px 0px 6px 0px ${statusData[`${device.QoEStreaming}`]?.color || "#000"}` }} />
                      </Tooltip>
                      <Link to={identifier?.deviceId && device?.displayName && redirectToTopolgyTabs(location, "/fleet-dashboard/sites/SDWAN", k4Id, siteName, { device_id: identifier?.deviceId, wan_name: device.displayName, isStreaming: true }, true, false)} className='hub-redirectToOtherTabs'>
                        Streaming
                      </Link>
                      </>) : null}
                  </Typography>
                  <Typography className="identifier-font qoe-display-flex">
                    {device.hasOwnProperty("QoEGaming") && device.QoEGaming ? (<>
                      <Tooltip title={`Score: ${device.QoEGamingPoints} (${statusData[`${device.QoEGaming}`]?.label})`} arrow placement="top">
                        <CircleIcon id="qoe-bacis-css" style={{ color: `${statusData[`${device.QoEGaming}`]?.color || "#000"}`, boxShadow: `0px 0px 6px 0px ${statusData[`${device.QoEGaming}`]?.color || "#000"}` }} />
                      </Tooltip>
                      <Link to={identifier?.deviceId && device?.displayName && redirectToTopolgyTabs(location, "/fleet-dashboard/sites/SDWAN", k4Id, siteName, { device_id: identifier?.deviceId, wan_name: device.displayName, isRTC: true }, true, false)} className='hub-redirectToOtherTabs'>
                        RTC
                      </Link>
                      </>) : null}
                  </Typography>
                </TableCell>
                <TableCell align="center" className="accordianWidth">
                  <Typography className="identifier-font">
                    {device.hasOwnProperty("uptime") && device.uptime ? `${(device.uptime).toFixed(2)} %` : device?.uptime === 0 ? 0 : "-"}
                  </Typography>
                </TableCell>
                <TableCell align="center" className="accordianWidth">
                  <Typography className="identifier-font">
                    {device.hasOwnProperty("sucess_probe") && device.sucess_probe ? `${(device.sucess_probe).toFixed(2)} %` : "-"}
                  </Typography>
                </TableCell>
                <TableCell className="accordianWidth">
                  <Typography align="center" className="identifier-font">
                    {device.hasOwnProperty("speed") && device.speed ? `${readableBits(device.speed)}` : "-"}
                  </Typography>
                </TableCell>
                <TableCell className="accordianWidth">
                  <Typography align="center" className="identifier-font">
                    {device.hasOwnProperty("speedtest_result") && device.hasOwnProperty("speedtest_time") && device.speedtest_result && device.speedtest_time ? (
                      <>
                        <span>{`${Math.round(device.speedtest_result)} Mbps`}</span>
                        <br />
                        <span>({convertDateTimeIntoTimezone(device.speedtest_time, userTimezone, YYYYMMDD_HHmmss)})</span>
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </TableCell>
                <TableCell className="accordianWidth">
                  <Typography align="center" className="identifier-font">
                    {device.hasOwnProperty("speedtest_required") && device.hasOwnProperty("speedtest_interval") && device.speedtest_required && device.speedtest_interval ? `${device.speedtest_interval}h` : "-"}
                  </Typography>
                </TableCell>
                <TableCell className="accordianWidth">
                  <Typography align="center" className="identifier-font">
                    {device.hasOwnProperty("latency") ? `${Math.round(device.latency)} ms` : "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="identifier-font accWidUsage" align="center">
                    <Tooltip title="Download / Upload">
                      <span>
                        {device.downBytes || device.upBytes ? `${readableBytesAsGB(device.downBytes)} / ${readableBytesAsGB(device.upBytes)}` : "-"}
                      </span>
                    </Tooltip>
                  </Typography>
                </TableCell>
              </TableRow>
            )
            ) : <TableRow>
              <TableCell colSpan={6} align='center' className="noDataAvailableCss">
                No Data Available
              </TableCell>
            </TableRow>}
          </TableBody>
      </TableContainer>
    </div>
  </div>
})

type StatusType = 'online' | 'offline' | 'unknown';

type ProfileDetail = {
  name: string;
  profiles: ProfileDetailItem[];
  networks: AssociatedNetwork[]
}

type AssociatedNetwork = {
  name: string;
  count: number;
}

type ProfileDetailItem = {
  priority: number;
  wan: string;
  status: StatusType
}

const WanProfileDetails = ({wanProfilesList, isAdvBondingEnabled, connectedHub}) => {
  return wanProfilesList?.length > 0 ? <Stack sx={{ width: '100%', bgcolor: 'background.paper' }} spacing={1} className="profile-parent-stack">
    <div className="wan-rofile-top-chips">
      <div className="LAN-Status-div">
        <span className="advanced_bonding">{isAdvBondingEnabled == 'true' ? 'Advanced Bonding - Enabled' : 'Advanced Bonding - Disabled'}</span>
      </div>
      <div className="LAN-Status-div">
        {connectedHub?.name && <span>{`Hub IP - ${connectedHub.name}: ${connectedHub.ip_port_list}`}</span>}
      </div>
    </div>
    {wanProfilesList?.map((item, index) => <AssociatedNeworkDetail key={`detail-${index}`} item={item} />)}
  </Stack> : <div className="wan-profile-no-data-text">No Data</div>
}

interface ProfileProps {
  item: ProfileDetail;
}

const AssociatedNeworkDetail: React.FC<ProfileProps> = ({ item }) => {

  return <Box className="profile-parent" component={Paper} sx={{ p: 2 }}>
    <Typography className="profile-heads" gutterBottom variant="body1" component="div">
      {(item.name)?.substring(1, (item.name)?.length)}
    </Typography>
    <Divider />
    <Box sx={{ p: 1 }}>
      <Grid className="profile-body" container spacing={1}>
        <Grid className="profile-sub-head-parent" item xs={6}>
          <Typography className="profile-sub-head" gutterBottom variant="h4">
            Associated Networks
          </Typography>
          <Stack className="chip-parent" direction="row" spacing={1} flexWrap='wrap'>
            {item.networks.map(network => <Chip className="chip-color" label={`${network}`} sx={{ ml: 0 }} />)}
          </Stack>
        </Grid>
        <Grid item xs={6} className="profile-sub-head-table">
          <TableContainer component={Paper} className="alertsTable-FleetDashboard wan-profile-table-container">
            <Table aria-label="simple table">
              <TableHead className="tableHead alertsTable-tableHead">
                <TableRow className="alertReports-tableRow wan-profile-table-head-row">
                  <TableCell className="alertsTable-fleetDataTable font-wt-900 priority-column">Priority</TableCell>
                  <TableCell className="alertsTable-fleetDataTable font-wt-900">WAN</TableCell>
                  <TableCell className="alertsTable-fleetDataTable font-wt-900 status-column">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody alertsTable-tableBody">
                {Object.keys(item.profiles)?.map((row, index) => (
                  <TableRow key={`network-${index}`} className="alertsData-tabelRow wan-profile-small-table">
                    <TableCell className="alertsTable-tableCell">{row}</TableCell>
                    <TableCell>
                      <ul>
                        {Array.isArray(item.profiles[row]) && item.profiles[row]?.length > 0 && item.profiles[row]?.map((priority, i) => (
                          <li className={i !=0 ? "profile-link-name" : ''}>{priority.link_name}</li>
                        ))}
                      </ul>
                    </TableCell>
                    <TableCell>
                      <ul>
                        {Array.isArray(item.profiles[row]) && item.profiles[row].length > 0 && item.profiles[row]?.map((priority, i) => (
                          <div className="vesselNameBox">
                            {priority.color != 'BULLSEYE' ? <CircleIcon fontSize='small' className="profileDotMargin" id={priority.color=="GREEN"? "greenDotSymbol" : priority.color=="RED" ? "redDotSymbol" : "greyDotSymbol"} /> : <RadioButtonCheckedIcon className="bullseye-color remove-margin-bullseye" />}
                          </div>
                        ))}
                      </ul>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  </Box>
}


// export default TableSummary;
export default connect(mapStateToProps, {
  getLinkStatusForDashBoard,
  getWanProfilesPerDevice,
  getDashboardAdvancedBonding,
  getDashboardLANStatus,
  getDashboardConnectedHub
  //All functions defined in action will come here

})(GenericAccordionComp);