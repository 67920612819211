import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    Grid, MenuItem, Menu, ListItemText, Button,
    TextField, InputAdornment
  } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/CheckBoxRounded';
import SearchIcon from "@mui/icons-material/Search";
import { getOrganisationUnitList } from "../../actions/Users/authenticate";
import "../../components/InventoryWarehouseTable/inventoryWarehouse.css"
import _ from "lodash";
import { checkSelectedOuIsInvitedOu, getInvitedOuList, getOu, getUserOu, populateForParent } from "../../utils/util";
var Loader = require("react-loader");

const CreateChildDP = (props) => {
  const {  authReducer, getOrganisationUnitList, setOu } = props;

  const [organisations, setOrganisations] = useState<any[]>([]);
  const [selectedOu, setSelectedOu] = useState(authReducer.selectedOu);
  const [loading, setLoading] = useState<Boolean>(false);
  const [showOuList, setShowOuList] = useState(true);
  const [ouSearchValue, setOuSearchValue] = useState("");
  const [isOuClicked, setIsOuClicked] = useState<Boolean>(false);
 
  useEffect(() => {
    setOu(selectedOu)
  }, [selectedOu])

useEffect(() => {
  if (authReducer.selectedOu && authReducer.selectedOu?.name) {
    setOrganisations([authReducer?.selectedOu]);
  }
  getOrganisationUnitList();
}, []);


const handleOuClose = (popupState: any = null) => {
  if (popupState) {
    popupState.close();
  }
}

const handleOuSearch = async (value) => {
    setOuSearchValue(value);
    setLoading(true);
    getOrganisationUnitList(value);
  }


const searchOu = (tree, ouName) => {
  if (tree && ouName) {
    for (let i = 0; i < tree.length; i++) {
      if (tree[i].name === ouName) {
        return tree[i];
      }
      if (tree[i].parent_of) {
        let ou = searchOu(tree[i].parent_of, ouName);
        if (ou) return ou;
      }
    }
  }
  return null;
}

useEffect(() => {
    if (loading) {
        setLoading(false);
    }
    if (!_.isEmpty(authReducer.getOrganisationUnitList) && authReducer.getOrganisationUnitList.dps) {
        let _organisations: any[] = [];
        if (authReducer.getOrganisationUnitList.dps && authReducer.getOrganisationUnitList.dps.length > 0) {
          authReducer.getOrganisationUnitList.dps.map((item) => {
            _organisations = populateForParent(item, null, _organisations);
          })
        }
        setOrganisations(_organisations);
        setSelectedOu(getOu(_organisations,selectedOu,authReducer.getOrganisationUnitList))
        authReducer.getOrganisationUnitList = {};
    }
}, [authReducer.getOrganisationUnitList]);

const handleOuClick = (event, ou, popupState) => { 
    if (popupState) {
      popupState.close();
    }
    setIsOuClicked(true);
    setSelectedOu(ou)
    if(ouSearchValue){
      setOuSearchValue("");
      getOrganisationUnitList();
    }
  };

const getOuTree = (popupState: any, ous: any[], prefix: string = "", isInvitedOusValidationRequired =true): any[] => {
    let list: any[] = [];
    if (ous && Array.isArray(ous)) {
      let userOu = getUserOu()
      for (let i = 0; i < ous.length; i++) {
        const item = ous[i];
        // if(isInvitedOusValidationRequired && !_.isEmpty(userOu) && !_.isEmpty(item) && userOu.hasOwnProperty("id") && item.hasOwnProperty("id")  && userOu["id"] !==item.id){
        //   continue;
        // }
        const _childern = getOuTree(popupState, item._childern, "ou_" + i,false);
        list.push(
          <div key={prefix + i + 'div'} className='dp-dropdown-dp-create'>
              <MenuItem key={prefix + i} className="OuMenuChild" 
              onClick={(e) => handleOuClick(e, item, popupState)}
              >
                {
                  0 == _childern.length ?
                    <ListItemText primary={"- " + item.name} className="DPchildHierarch textWrapInner" />
                  : <ListItemText primary={"+ " + item.name} className="DPchildHierarch textWrapInner" />
                }
                {!_.isEmpty(selectedOu) && selectedOu.hasOwnProperty("name") && selectedOu["name"] == item.name ? <CheckIcon className="createDp-checkIcon" /> : ''}
              </MenuItem>
            <div key={prefix + i + 'childern'} className="childern" >
              {_childern}
            </div>
          </div>
        );
      }
    }
    return list
  }

return (
    <div className="Form">
        <Grid>
        {
            showOuList ?
            <Grid className="DPvesselDropdown create-DPvesselDropdown">
                <PopupState variant="popover" popupId="ou-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                    <Button className="DPVesselName create-DPVesselName" variant="contained" {...bindTrigger(popupState)}>
                        <span className="DPtextWrap">{!_.isEmpty(selectedOu) && selectedOu.hasOwnProperty("name")? selectedOu["name"] :""}</span>
                        {
                        popupState.isOpen ?
                            <KeyboardArrowDownIcon key="ouArrow" className="DPdropdownArrow" style={{ cursor: "pointer", transform: "rotate(-180deg)" }} />
                            :
                            <KeyboardArrowDownIcon key="ouArrow" className="DPdropdownArrow" />
                        }
                    </Button>
                    <Menu 
                    {...bindMenu(popupState)}
                        id="DPmenu-ou"
                        aria-labelledby="menu-ou"
                        onClose={() => handleOuClose(popupState)}
                        key="menu-ou"
                        className="childHierarchy"
                    >
                      <MenuItem key={'ou_text'} className="vessel_search_item vessel_search_item" onKeyDown={(e) => e.stopPropagation()}>
                        <TextField
                          id="ousearchtext"
                          variant="outlined"
                          placeholder="Type something"
                          autoComplete="off"
                          size="small"
                          value={ouSearchValue}
                          onChange={(event) => {
                            handleOuSearch(event.target.value);
                          }}
                          InputLabelProps={{ className: "serachLabel" }}
                          InputProps={{
                            className: "serachBar cretesubDP",
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon className="cursorPointer" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MenuItem>
                        <span>
                        {getOuTree(popupState, organisations)}</span>
                    </Menu>

                    </React.Fragment>
                )}
                </PopupState>
            </Grid>
            : <></>
        }
        </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});
export default withRouter(
    connect(mapStateToProps, {
     getOrganisationUnitList
    })(CreateChildDP)
);