import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getSoftwareVersionsDeviceCount, getDeviceDetails } from "../../actions/Users/authenticateSoftware";
import _ from "lodash";
import arrowAlert from "../../asset/image/arrowAlert.svg";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import Box from '@mui/material/Box';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { getDecodeURI, getEncodedURI } from "../../utils/util";
import { PER_PAGE_ITEMS } from "../../constants/Constants";
import Autocomplete from "@mui/material/Autocomplete";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import Paper from "@material-ui/core/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ascSorting from "../../asset/image/ascSorting.svg";
import descSorting from "../../asset/image/descSorting.svg";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import "./softwareVersionsTable.css"

import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";

const SoftwareVersionsTable = (props) => {
    const { authReducer, getSoftwareVersionsDeviceCount, getDeviceDetails, location, history, permissionChanges } = props;
    let [softwareVersionsData, setSoftwareVersionsData] = useState<any[]>([]);
    let [selectedOu, setSelectedOu ] = useState<any>({});

    const VESSEL_NAME = "locationName";
    const DEVICE_ID = "deviceId";
    const DEVICE_TYPE = "deviceType";
    const EOS_VERSION = "softwareVersion";
    const DEVICE_COUNT = "count";
    const DEVICE_SOFTWARE_VERSION = "deviceSoftwareVersion";
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";

    let _q = getDecodeURI(location?.search);
    let [searchValue, setSearchValue] = useState<any>(_q.hasOwnProperty("search") ? _q.search : "");
    const [isSearchApplied, setISearchApplied] = useState<Boolean>(searchValue ? true : false);
    const ENTER_KEY_CODE = "Enter";
    let [devicesData, setDevicesData] = useState<any[]>([]);
    const [isPagination, setPagination] = useState(true);
    const [fromItem, setFromItem] = useState<number>(0);
    const [toItem, setToItem] = useState<number>(0);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = useState<any>(_q.hasOwnProperty("page") ? _q.page : 0)
    const [pages, setPages] = React.useState(0);
    const [limit, setLimit] = useState<any>(_q.hasOwnProperty("limit") ? _q.limit : DEFAULT_PAGE_SIZE);
    const [currentPageItemType, setCurrentPageItemType] = React.useState("Devices");
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : VESSEL_NAME);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : ASC_ORDER);
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const [sortSoftwareVersion, setSortSoftwareVersion] = useState<string>(_q.hasOwnProperty("sortTwo")?_q.sortTwo: EOS_VERSION);
    const [sortSoftwareVersionOrder, setSoftwareVersionOrder] = useState<string>(_q.hasOwnProperty("sortOrderTwo")?_q.sortOrderTwo: DESC_ORDER);

    const clearSoftwareVersionsDataInfo = () => {
        setSoftwareVersionsData([]);
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        if(value) {
            params.search = value;
            params.page = 0;
            params.limit = limit;
            params.sort = sort;
            params.sortOrder = sortOrder;
            setPage(0);

            getDeviceDetails(selectedOu.name, selectedOu.id, searchValue, page + 1, limit, sort, sortOrder);
            setISearchApplied(true);
            clearSoftwareVersionsDataInfo();
            doNavigate(location.url, params);
        }else {
            delete params.search;
            delete params.page;
            doNavigate(location.url, params);
            fetchDeviceCountData();
            setISearchApplied(false);
        }
    };

    const doNavigate = (pathname, params = {}) => {
        history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
    }

    useEffect(() => {
        if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id
            && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name) {
            setSelectedOu(authReducer.selectedOu);
        }
    }, [authReducer.selectedOu]);

    useEffect(() => {
        if(selectedOu && selectedOu.hasOwnProperty("id") && selectedOu.id && selectedOu.hasOwnProperty("name") && selectedOu.name){
            if (searchValue) {
                getDeviceDetails(selectedOu.name, selectedOu.id, searchValue, page + 1, limit, sort, sortOrder);
            } else {
                fetchDeviceCountData();
            }
        }
    }, [selectedOu]);

    const fetchDeviceCountData = () => {
        getSoftwareVersionsDeviceCount(selectedOu.name, selectedOu.id);
    }

    useEffect(() => {
        if (!_.isEmpty(authReducer.getSoftwareVersionsDeviceCount)) {
            let data = authReducer.getSoftwareVersionsDeviceCount.hasOwnProperty("data") && Array.isArray(authReducer.getSoftwareVersionsDeviceCount.data) ? authReducer.getSoftwareVersionsDeviceCount.data : [];
            setSoftwareVersionsData(data);
            authReducer.getSoftwareVersionsDeviceCount = {};
        }
    }, [authReducer.getSoftwareVersionsDeviceCount]);

    useEffect(() => {
        if (!_.isEmpty(authReducer.getSoftVersionDeviceDetails)) {
            let data = authReducer.getSoftVersionDeviceDetails.hasOwnProperty("data") && Array.isArray(authReducer.getSoftVersionDeviceDetails.data) ? authReducer.getSoftVersionDeviceDetails.data : [];
            setDevicesData(data);
            const totalCount: number = authReducer.getSoftVersionDeviceDetails.hasOwnProperty("total")? parseInt(authReducer.getSoftVersionDeviceDetails.total) : 0;
            const pages = Math.ceil(totalCount / limit);
            updatePageInfo(page, limit, data.length, pages, totalCount);
            authReducer.getSoftVersionDeviceDetails = {};
        }
    }, [authReducer.getSoftVersionDeviceDetails]);

    const handlePerPageChange = (e: any) => {
        let _perPage = parseInt(e.target.textContent);
        if (Number.isInteger(_perPage)) {
            let params: any = getDecodeURI(location?.search);
            params.page = 0;
            params.limit = _perPage;
            setPage(0);
            setLimit(_perPage);
            doNavigate(location.url, params);
            getDeviceDetails(selectedOu.name, selectedOu.id, searchValue, 1, _perPage, sort, sortOrder);
        }
    };

    const handlePagination = (e, value) => {
        let params: any = getDecodeURI(location?.search);
        params.page = value - 1;
        setPage(value - 1)
        doNavigate(location.url, params);
        getDeviceDetails(selectedOu.name, selectedOu.id, searchValue, value, limit, sort, sortOrder);
    };

    const updatePageInfo = (page: number, limit: number, itemCount: number, pages: number, total: number) => {
        setPage(page);
        setFromItem(itemCount > 0 ? (page * limit) + 1 : 0);
        setToItem((page * limit) + itemCount);
        setTotal(total);
        setPages(pages);
    };

    const switchSorting = (event: any, field: any) => {
        let order = DESC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        let params: any = getDecodeURI(location?.search);
        params.page = 0;
        params.sort = field;
        params.sortOrder = order;

        setPage(0);
        setSort(field);
        setSortOrder(order);

        doNavigate(location.url, params);
        getDeviceDetails(selectedOu.name, selectedOu.id, searchValue, 1, limit, field, order);
    };

    const switchSoftwareVersionSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sortSoftwareVersion === field) {
            order = ASC_ORDER === sortSoftwareVersionOrder ? DESC_ORDER : ASC_ORDER;
        }
        let params: any = getDecodeURI(location?.search);
        params.sortTwo = field;
        params.sortOrderTwo = order;

    
        setSortSoftwareVersion(field);
        setSoftwareVersionOrder(order);

        doNavigate(location.url, params);
        getSoftwareVersionsDeviceCount(selectedOu.name, selectedOu.id, field, order);
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            let params: any = getDecodeURI(location?.search);
            params.page = 0;
            params.sort = field;
            params.sortOrder = order;

            setPage(0);
            setSort(field);
            setSortOrder(order);

            doNavigate(location.url, params);
            getDeviceDetails(selectedOu.name, selectedOu.id, searchValue, 1, limit, field, order);
        }
    };

    const setSoftwareVersionSorting = (event: any, field: any, order: any) => {
        if (event && event.currentTarget) {
            let params: any = getDecodeURI(location?.search);
            params.sortTwo = field;
            params.sortOrderTwo = order;
            
            setSortSoftwareVersion(field);
            setSoftwareVersionOrder(order);

            doNavigate(location.url, params);
            getSoftwareVersionsDeviceCount(selectedOu.name, selectedOu.id, field, order);
        }
    }

    const navigateToDeviceDetails = (url) => {
        //check if required
        permissionChanges(false)
        let params: any = getDecodeURI(location?.search);
        doNavigate(url, params);
    };

    const handleSearch = (event) => {
        if(!event.target.value) {
            let params: any = getDecodeURI(location?.search);
            delete params.search;
            delete params.page;
            doNavigate(location.url, params);
            fetchDeviceCountData();
        }
        _q.hasOwnProperty('search') ? setISearchApplied(true) : setISearchApplied(false);
        setSearchValue(event.target.value);
    }

    
    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }
  
    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    return (
        <div className="Form">
            <Grid className="am-table">
                <Grid className="soft-scroll-container">
                    <Grid className="alignTopbarItemsCenter">
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Box className="searchbarBox">
                                {<TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Type something"
                                    autoComplete="off"
                                    size="small"
                                    value={searchValue}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel" }}
                                    InputProps={{
                                        className: "searchbarInputs",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchValue && <SearchIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchValue && <CloseIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        {
                            isPagination && isSearchApplied && searchValue
                                ?
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <Grid style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        paddingTop: "10px"
                                    }}
                                    >
                                        <Grid>
                                            <Grid className="vesselPageDetails">Per Page</Grid>
                                            <Grid className="vesselPagination"> {`${fromItem} to ${toItem} out of ${total} ${currentPageItemType}`}</Grid>
                                        </Grid>
                                        <Grid style={{ marginLeft: "10px" }}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={PER_PAGE_ITEMS}
                                                className="props.classes.paper"
                                                sx={{ width: 65 }}
                                                disableClearable
                                                onChange={(e: any) => {
                                                    handlePerPageChange(e);
                                                }}
                                                value={{ label: `${limit}` }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        InputLabelProps={{ className: "autoCompleteBox" }}
                                                    />
                                                )}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ fontSize: "12px" }}>{children}</Paper>
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            key="pagination-list"
                                            style={{
                                                marginLeft: "5px",
                                                alignItems: "center",
                                                width: "auto",
                                            }}
                                        >
                                            <Stack>
                                                <Pagination
                                                    className="paginationContainer"
                                                    count={pages}
                                                    page={page + 1}
                                                    size="small"
                                                    onChange={handlePagination}
                                                    variant="outlined"
                                                    shape="rounded"
                                                    siblingCount={0}
                                                    defaultPage={1}
                                                    boundaryCount={0}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                : null
                        }
                    </Grid>

                    {
                        isSearchApplied && searchValue ?
                            <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                            <Table aria-label="simple sticky table" stickyHeader>
                                <TableHead className="usageReports-tableHead">
                                    <TableRow className="UsageReports-tableRow login-tableRow">
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                            <span style={{ whiteSpace: "nowrap" }}>
                                                <span className="login-history-dp-name paddingRight font-wt-900">SITE</span>
                                                    <Grid className="sort-icon-margin">
                                                        <img src={sort === VESSEL_NAME && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                            className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, VESSEL_NAME, "asc")} />
                                                        <img src={sort === VESSEL_NAME && sortOrder === "desc" ? descSortingSelected : DescSort}
                                                            className="sortingImages" onClick={(event) => setSorting(event, VESSEL_NAME, "desc")} />
                                                    </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                            <span style={{ whiteSpace: "nowrap" }}>
                                                <span className="login-history-dp-name paddingRight font-wt-900">DID</span>
                                                <Grid className="sort-icon-margin">
                                                         <img src={sort === DEVICE_ID && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                            className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DEVICE_ID, ASC_ORDER)} />
                                                        <img src={sort === DEVICE_ID && sortOrder ===  "desc" ? descSortingSelected : DescSort}
                                                            className="sortingImages" onClick={(event) => setSorting(event, DEVICE_ID, DESC_ORDER)} />
                                                    </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                            <span style={{ whiteSpace: "nowrap" }}>
                                                <span className="login-history-dp-name paddingRight font-wt-900">SOFTWARE VERSION</span>
                                                    <Grid className="sort-icon-margin">
                                                        <img src={sort === DEVICE_SOFTWARE_VERSION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                            className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DEVICE_SOFTWARE_VERSION, ASC_ORDER)} />
                                                        <img src={sort === DEVICE_SOFTWARE_VERSION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                            className="sortingImages" onClick={(event) => setSorting(event, DEVICE_SOFTWARE_VERSION, DESC_ORDER)} />
                                                    </Grid>
                                                </span>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {devicesData && 0 != devicesData.length ? (
                                    <TableBody className="tableBody usage-tableBody">
                                        {
                                            devicesData && devicesData.map((row, i) =>
                                                <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr">
                                                    <TableCell className="login-left-align inter-font">
                                                    {row[VESSEL_NAME]}
                                                    </TableCell>
                                                    <TableCell className="login-left-align inter-font">
                                                    {row[DEVICE_ID]}
                                                    </TableCell>
                                                    <TableCell className="login-left-align inter-font" onClick={() => navigateToDeviceDetails(`/softwareList/${row.softwareVersion}/devices`)}>
                                                        <span style={{ textTransform: "lowercase" }} title={row.hasOwnProperty(DEVICE_SOFTWARE_VERSION) && null !== row[DEVICE_SOFTWARE_VERSION] ? row[DEVICE_SOFTWARE_VERSION] : null}>
                                                            {row.hasOwnProperty(DEVICE_SOFTWARE_VERSION) && null !== row[DEVICE_SOFTWARE_VERSION] ? (row[DEVICE_SOFTWARE_VERSION].indexOf("~") > -1 ? row[DEVICE_SOFTWARE_VERSION].substring(0, row[DEVICE_SOFTWARE_VERSION].indexOf("~")) : row[DEVICE_SOFTWARE_VERSION]) : "-"}
                                                        </span>
                                                    </TableCell>
                                                </TableRow>

                                            )
                                        }
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                            </TableContainer>
                            :
                            <div className="menuScroll">
                                <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                                <Table aria-label="simple sticky table" stickyHeader>
                                    <TableHead className="usageReports-tableHead">
                                        <TableRow className="UsageReports-tableRow login-tableRow">
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    <span className="login-history-dp-name paddingRight font-wt-900" onClick={(event) => switchSoftwareVersionSorting(event, EOS_VERSION)}>EOS Version</span>
                                                    <Grid className="sort-icon-margin">
                                                        <img src={sortSoftwareVersion === EOS_VERSION && sortSoftwareVersionOrder === ASC_ORDER ? ascSortingSelected : AscSort} alt=""
                                                            className="ascendingOrderIcon sortingImages" onClick={(event) => setSoftwareVersionSorting(event, EOS_VERSION, ASC_ORDER)} />
                                                        <img src={sortSoftwareVersion === EOS_VERSION && sortSoftwareVersionOrder === DESC_ORDER ? descSortingSelected : DescSort} alt=""
                                                            className="sortingImages" onClick={(event) => setSoftwareVersionSorting(event, EOS_VERSION, DESC_ORDER)} />
                                                    </Grid>
                                                </span>
                                            </TableCell>
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                        <span className="login-history-dp-name paddingRight font-wt-900" onClick={(event) => switchSoftwareVersionSorting(event, DEVICE_COUNT)}>Device Count</span>
                                                        <Grid className="sort-icon-margin">
                                                            <img src={sortSoftwareVersion === DEVICE_COUNT && sortSoftwareVersionOrder === ASC_ORDER ? ascSortingSelected : AscSort} alt=""
                                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSoftwareVersionSorting(event, DEVICE_COUNT, ASC_ORDER)} />
                                                            <img src={sortSoftwareVersion === DEVICE_COUNT && sortSoftwareVersionOrder === DESC_ORDER ? descSortingSelected : DescSort} alt=""
                                                                className="sortingImages" onClick={(event) => setSoftwareVersionSorting(event, DEVICE_COUNT, DESC_ORDER)} />
                                                        </Grid>
                                                </span>
                                            </TableCell>
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {softwareVersionsData && 0 != softwareVersionsData.length ? (
                                        <TableBody className="tableBody usage-tableBody">
                                            {
                                                softwareVersionsData && softwareVersionsData.map((row, i) =>
                                                    <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr">
                                                        <TableCell className="login-left-align inter-font">
                                                            <span style={{ textTransform: "lowercase" }} title={row.hasOwnProperty("softwareVersion") && null !== row.softwareVersion ? row.softwareVersion : null}>
                                                                {row.hasOwnProperty("softwareVersion") && "null" !== row.softwareVersion ? (row.softwareVersion.indexOf("~") > -1 ? row.softwareVersion.substring(0, row.softwareVersion.indexOf("~")) : row.softwareVersion) : "-"}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell className="login-left-align inter-font">
                                                            {row.deviceCount}
                                                        </TableCell>
                                                        <TableCell className="login-left-align inter-font" onClick={()=>navigateToDeviceDetails(`/softwareList/${row.softwareVersion}/devices`)}>
                                                            {/* <Link to={{
                                                                pathname: `/softwareList/${row.softwareVersion}/devices`,

                                                            }} style={{ textDecoration: "none" }}> */}
                                                                <img
                                                                    src={arrowAlert}
                                                                    style={{
                                                                        width: "24px",
                                                                        height: "24px",
                                                                        cursor: "pointer"
                                                                    }}
                                                                    title={"View Device details"}>
                                                                </img>
                                                            {/* </Link> */}


                                                        </TableCell>
                                                    </TableRow>

                                                )
                                            }
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                            </div>
                    }

                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, { getSoftwareVersionsDeviceCount, getDeviceDetails })(SoftwareVersionsTable)
);