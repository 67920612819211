// SubHeader for the summary page
import React, { useEffect, useState , useMemo} from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Grid, MenuItem, Menu, ListItemText, Button,
  TextField, InputAdornment, Typography
} from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { getVesselsListing } from "../../actions/Users/authenticateService";
import { HUB_RESOURCE_FIELD_VESSELNAME, MMDDYYYYHMM_DATE_FORMAT_24_HRS, RESOURCE_FIELD_VESSELNAME } from "../../utils/constants";
import { getOrganisationList, getOrganisationUnitList, updateIsReadOnlyUser, updateSelectedOu, updateSelectedVessel, updateSelectedVessels, updateUserOuHasSubOus, updatFilteredVessels } from "../../actions/Users/authenticate";
import { getServiceAccounts } from "../../actions/Users/authenticate";
import { CONST_ALL_VESSELS, CONST_SELECT_VESSEL, NEWINTERVALS_WITH_PLANS, USER_OU, USER_PREFERENCES } from '../../constants/Constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/CheckBoxRounded';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SearchIcon from "@mui/icons-material/Search";
import CheckLineIcon from '@mui/icons-material/Check';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import _ from "lodash";
import './SubHeaderSummary.css';
import moment from "moment-timezone";
import { displayToastError } from "../../server/request";
import {  getDataFromLocalStorage, getDecodeURI, getEncodedURI, getTimezoneCity, setDataToLocalStorage, strToK4IdList, strToK4List } from "../../utils/util";
import Tooltip, {  } from '@mui/material/Tooltip';
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { NEWINTERVALS, INTERVAL_LAST_THREE_MONTHS, WAN_ARRAY, THREAT_SEVERITY_ARRAY} from "../../constants/Constants";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {  LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useDispatch } from "react-redux";
import { UPDATE_NEW_SUMMARY_START_DATE, UPDATE_NEW_SUMMARY_END_DATE, UPDATE_NEW_SUMMARY_WAN_TYPE, GET_DRAWER_OPEN, USER_TIMEZONE, UPDATE_NEW_SUMMARY_SERVICE_LINE, UPDATE_NEW_SEVERITY_TYPE, UPDATE_NEW_SUMMARY_SERVICE_ACCOUNTS, IS_INVENTORY_PREVIOUS_PAGE } from "../../actions/types";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CommonFilterDownIcon from "../../asset/image/CommonFilterDownIcon.svg";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { fetchServiceLines } from '../../actions/Users/authenticate';
import { FixedSizeList } from "react-window";
import { getAllServiceLineSubscriptionAPI } from "../StarlinkDashboard/slice";

var Loader = require("react-loader");

const SubHeaderSummary = (props) => {
  const { authReducer, subHeaderSelectedDropdown,getServiceAccounts, getOrganisationUnitList, getVesselsListing, updateSelectedOu, updateSelectedVessel, updateSelectedVessels, updatFilteredVessels, errorReducer, location, history, updateUserOuHasSubOus, newSummary, newSummaryStartDate, newSummaryEndDate, newSummaryWanType, newSummarySeverityType,
     newSummaryServiceAccount, selectedServiceAccount, dpIds, newSummaryServiceLine, showWantType = true, showSummaryPeriod = true, setTimePeriod, periodOptions, disableOu, disableSite, showDateOnly, updateIsReadOnlyUser, isCustomPeriodSelectionRequired, showSLDropDown, fetchServiceLines,
    hubVesselNames, hubSelectedVesselNames, hubSelectedVesselsDisplayName, siteSearchValue, setSiteSearchValue, handleChangeVesselHub, showSeverity = false, showAccounts = false, showLastThreeMonths = false, showPlans = false, showAllServiceLines=false, getAllServiceLineSubscriptionAPI, getAllServiceLinesSubscription } = props;
  const [vesselNames, setVesselNames] = useState<any[]>([]);
  const [accessibleServiceLines, setAccessibleServiceLines]  = useState<any[]>([]);
  const [selectedServiceLineNames, setSelectedServiceLineNames] = useState<string[]>([]);
  const [selectedServiceLines, setSelectedServiceLines] = useState<string[]>([]);
  const [singleVesselNames, setSingleVesselNames] = useState<any[]>([]);
  const [selectedVessels, setSelectedVessels] = useState<any[]>([]);
  const [selectedSingleVessel, setSelectedSingleVessel] = useState<any>({ id: "", "vessel-name": CONST_SELECT_VESSEL });
  const [selectedVesselNames, setSelectedVesselNames] = useState<string[]>([]);
  const [selectedVesselsDisplayName, setSelectedVesselsDisplayName] = useState<any>(CONST_ALL_VESSELS);
  const [selectedAccountsDisplayName, setSelectedAccountsDisplayName] = useState<any>("All Accounts");
  const [selectedVesselsSLName, setSelectedSLDisplayName] = useState<any>("All Service Lines");
  const [selectedAccountPrevious, setSelectedAccountPrevious] = useState([])
  const path = window.location.pathname; 
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [organisations, setOrganisations] = useState<any[]>([]);
  const [newOrganisationsList, setNewOrganisationsList] = useState<any>([]);
  const [ouName, setOuName] = useState(authReducer?.selectedOu?.name ? authReducer.selectedOu.name : '');
  const [isSingleVessel, setIsSingleVessel] = useState(false);
  const [isMultiVessel, setIsMultiVessel] = useState(true);
  const [isHubSites, setIsHubSites] = useState(false);
  const [showOuList, setShowOuList] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [searchServiceLines, setSearchServiceLines] = useState("");
  const [allFetchedServiceLines,setAllFetchedServiceLines] = useState<any[]>([]);
  const [ouSearchValue, setOuSearchValue] = useState("");
  const [permission, setPermission] = useState<any>(null);
  const [filterK4Ids, setFilterK4Ids] = useState<any>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [isOuClicked, setIsOuClicked] = useState<Boolean>(false);
  const [vesselList, setVesselList] = useState<any>([]);
  const [isUnselectNeeded, setIsUnselectNeeded] = useState<Boolean>();
  const [selectedPeriod, setSelectedPeriod] = useState(NEWINTERVALS && NEWINTERVALS[1]?.label)
  const [customStartDate, setCustomStartDate] = useState<moment.Moment | null>(newSummaryStartDate)
  const [customEndDate, setCustomEndDate] = useState<moment.Moment | null>(newSummaryEndDate)

  let _q: any = getDecodeURI(location.search);

  const dispatch = useDispatch();

  const [timezone, setTimezone] = useState<string>("")

  useEffect(() => {
    if(_q.timezone) {
      const timezone = _q.timezone;
      setTimezone(timezone);
      dispatch({ type: USER_TIMEZONE, payload: timezone});
    } else if (_.isEmpty(authReducer.userTimezone)) {
      // ex: Asia/Colombo - IST
      let timezone = moment.tz.guess(true) + " - " + moment.tz(moment.tz.guess(true)).format('z')
      try {
        let userPreferences = getDataFromLocalStorage(USER_PREFERENCES)
        if (userPreferences) {
          let preferences = JSON.parse(userPreferences)
          if (preferences) {
            if (preferences?.timezone) {
              timezone = preferences.timezone
            }
          }
        }
      } catch (error) {

      }
      setTimezone(getTimezoneCity(timezone))
      dispatch({ type: USER_TIMEZONE, payload: timezone})
    } else {
      setTimezone(getTimezoneCity(authReducer.userTimezone))
    }
  }, [authReducer.userTimezone])

  useEffect(() => {
    accessibleServiceLines && accessibleServiceLines.length > 0 && 
     updateSLDisplayNames(accessibleServiceLines.filter(serviceLine => newSummaryServiceLine.includes(serviceLine.id)).map(record => record.name) || "")

  },[accessibleServiceLines, newSummaryServiceLine])

  useEffect(() => {
    let _q = getDecodeURI(location?.search);
    if (_q.hasOwnProperty("k4Ids") && '' != _q.k4Ids.trim()) {
      let vessels = strToK4IdList(_q.k4Ids);
      if(vessels && vessels.length > 0 )  {
        vessels = vessels.filter(item => item !== '');
      }
      let vesselObjs = strToK4List(_q.k4Ids);
      if(vessels.length != filterK4Ids.length ||
         vessels.join() !== filterK4Ids.join())
      updatFilteredVessels(vesselObjs);
      setFilterK4Ids(vessels);
      if (vesselObjs.length > 0) {
        setSelectedSingleVessel(vesselObjs[0]);
        updateSelectedVessel(vesselObjs[0]);
        updateSelectedVessels({ isAll: isAllSelected, vessels: vesselObjs });
      }
    } else {
      if (filterK4Ids.length > 0) {
        setFilterK4Ids([]);
        updatFilteredVessels([]);
      }
      if ("" !== selectedSingleVessel.id) {
        setSelectedSingleVessel({ id: "", "vessel-name": CONST_SELECT_VESSEL });
      }
      updateSelectedVessel({});
      updateSelectedVessels({ isAll: isAllSelected, vessels: []})
    }
    if(!_q.hasOwnProperty('k4Ids')) {
      updatFilteredVessels([]);
    }

    let ou = authReducer?.userOu;
    if (_q.ouName && '' != _q.ouName && _q.ouId && '' != _q.ouId) {
      ou = { name: _q.ouName, id: _q.ouId };
    }


    if (ou?.name !== ouName) {
      updateSelectedOu(ou);
      setIsOuClicked(false);
    }
    // READ ONLY USER 
    const loginObj = JSON.parse(localStorage.getItem('loggedInUser') || '[]')
    updateIsReadOnlyUser(loginObj?.data?.user?.sportReadOnlyUser);
    if(loginObj?.data?.user?.readOnlyUser) {
      localStorage.setItem("readOnlyAceess", "true");
    } else {
      localStorage.setItem("readOnlyAceess", "false");
    }
    if(loginObj?.data?.user?.isK4User) {
      localStorage.setItem("isBetaUser", "true");
    } else {
      localStorage.setItem("isBetaUser", "false");
    }


    if(_q?.interval){
      let selectedInterval
     
      NEWINTERVALS?.map(interval => {
        if (interval.value === _q?.interval) {
          selectedInterval = interval.label;
        }
      });
      if(!showLastThreeMonths && !showPlans){
        setSelectedPeriod(selectedInterval?.split(" ")[2] ? selectedInterval?.split(" ")[1] + " " + selectedInterval?.split(" ")[2]: selectedInterval)
      } 
    }

    if(_q?.serviceLineAccess) {
      if(_q?.serviceLineAccess?.split(",").includes("All")){
      }else{
        dispatch({ type: UPDATE_NEW_SUMMARY_SERVICE_LINE, payload: _q?.serviceLineAccess?.split(",") })
      }
    }

    if(_q?.startDate && moment(new Date(parseInt(_q.startDate))).utc().format("DD/MM/YYYY hh:mm:ss") !== moment(authReducer?.newSummaryStartDate).format("DD/MM/YYYY hh:mm:ss") ){
      dispatch({ type: UPDATE_NEW_SUMMARY_START_DATE, payload:moment(new Date(parseInt(_q.startDate))).utc()})
    }
    if( _q?.endDate && moment(new Date(parseInt(_q.endDate))).utc().format("DD/MM/YYYY hh:mm:ss") !== moment(authReducer?.newSummaryEndDate).format("DD/MM/YYYY hh:mm:ss")){
      dispatch({ type: UPDATE_NEW_SUMMARY_END_DATE, payload:moment(new Date(parseInt(_q.endDate))).utc()})
    }

    if( _q?.wanType){
      if( _q?.wanType !== newSummaryWanType?.label){
        let selectedWan
        WAN_ARRAY?.map(wan => {
          if (wan.label === _q?.wanType) {
            selectedWan = wan;
          }
        });
        dispatch({ type: UPDATE_NEW_SUMMARY_WAN_TYPE, payload: selectedWan })
      }
    }else{
      if(newSummaryWanType?.label !== "All"){
        dispatch({ type: UPDATE_NEW_SUMMARY_WAN_TYPE, payload: WAN_ARRAY[0] })
      }
    }
    if( _q?.severityType){
      if( _q?.severityType !== newSummarySeverityType?.label){
        let selectedSeverity
        THREAT_SEVERITY_ARRAY?.map(severity => {
          if (severity?.label === _q?.severityType) {
            selectedSeverity = severity;
          }
        });
        dispatch({ type: UPDATE_NEW_SEVERITY_TYPE, payload: selectedSeverity })
      }
    }else{
      if(newSummarySeverityType?.label !== "All"){
        dispatch({ type: UPDATE_NEW_SEVERITY_TYPE, payload: THREAT_SEVERITY_ARRAY[0] })
      }
    }

    if(_q?.selectedAccount && newSummaryServiceAccount.length > 0) {
      if(_q?.selectedAccount?.split(",").includes("All")){
        
        dispatch({ type: UPDATE_NEW_SUMMARY_SERVICE_ACCOUNTS, payload: newSummaryServiceAccount?.map((account)=>{
          return account?.accountNumber
        }) })
      }else{
       
        dispatch({ type: UPDATE_NEW_SUMMARY_SERVICE_ACCOUNTS, payload: _q?.selectedAccount?.split(",") })
      
      }
    }

    if (_q.sideNav) {
      dispatch({ type: GET_DRAWER_OPEN, payload: _q.sideNav == 'true' ? true : false });
    }
    if(_.isEmpty(authReducer?.getVesselsListing) && authReducer?.selectedOu?.name  !== undefined && authReducer?.selectedOu?.name !== _q.ouName && _q?.ouName !== undefined) {
      getVesselsListing(_q?.ouName, "", true, true);
    }
  }, [location.search]);

  useEffect(() => {
    if (ouName && null != ouName && permission?.service && permission?.serviceFunction && ouName !== undefined) {
      if(permission.serviceFunction === "InventoryRO" && !_.isEmpty(authReducer?.getVesselsListing)) {
        console.log("skip global location call")
      } else {
        if (!window.location.pathname?.includes('/inventory')) {
          getVesselsListing(ouName, "", permission.service, permission.serviceFunction);
        }
      }
    }
  }, [ouName, showOuList]);

  useEffect(()=>{
    if(authReducer.fetchServiceLines && authReducer.fetchServiceLines.serviceLines && getAllServiceLinesSubscription){
  
    let serviceLines = authReducer.fetchServiceLines.serviceLines
    let filteredServiceLines = serviceLines
    if (!showAllServiceLines) {
    filteredServiceLines = serviceLines.filter(
    (line) =>
      getAllServiceLinesSubscription.some(
        (sub) => sub.service_line_number === line.id && sub.active
      )
  );}

  if (!filteredServiceLines.some(serviceLine => serviceLine.name === "All Service Lines")) {
    filteredServiceLines.unshift({ name: "All Service Lines", id: "All Service Lines" });
}
     
    setAccessibleServiceLines(filteredServiceLines)
    setSelectedServiceLines([])
    setSelectedServiceLineNames([])
    }
  },[authReducer.fetchServiceLines, getAllServiceLinesSubscription, showAllServiceLines])

  useEffect(()=>{

    if(authReducer.fetchServiceLines && authReducer.fetchServiceLines.serviceLines && getAllServiceLinesSubscription){
      let serviceLines = authReducer.fetchServiceLines.serviceLines
      let filteredServiceLines = serviceLines
      if (!showAllServiceLines) {
      filteredServiceLines= serviceLines.filter(
        (line) =>
          getAllServiceLinesSubscription.some(
            (sub) => sub.service_line_number === line.id && sub.active
          )
      );}

      if (!filteredServiceLines.some(serviceLine => serviceLine.name === "All Service Lines")) {
        filteredServiceLines.unshift({ name: "All Service Lines", id: "All Service Lines" });
    }
     
      setAllFetchedServiceLines(filteredServiceLines)
      if (_q?.serviceLineAccess && _q?.serviceLineAccess == "All"){
        setSelectedServiceLines(filteredServiceLines.map((sl)=>{return sl.id}));
        setSelectedServiceLineNames(filteredServiceLines.map((sl)=>{return sl.name}));
        dispatch({ type: UPDATE_NEW_SUMMARY_SERVICE_LINE, payload: filteredServiceLines.filter((sl) => sl.id !== 'All Service Lines').map((sl)=>{return sl.id}) });
      }
      else if(_q?.serviceLineAccess){
        const selectedNames = filteredServiceLines.filter(line => _q?.serviceLineAccess?.split(",")?.includes(line.id)).map(line => line.name);
        setSelectedServiceLines(_q?.serviceLineAccess?.split(",").map((sl)=>{return sl}));
        setSelectedServiceLineNames(selectedNames);
      }

    }
  
  },[authReducer.fetchServiceLines, _q?.serviceLineAccess, getAllServiceLinesSubscription, showAllServiceLines])

  const findAccountNames = (selectedAccountNumbers) => {
    const accountNumbersArray = selectedAccountNumbers?.split(',')

    const matchedAccountNames = accountNumbersArray?.map((accNumber) => {
        const account = newSummaryServiceAccount?.find((acc) => acc?.accountNumber === accNumber?.trim())
        return account ? account?.accountName : null
      })
      .filter((accountName) => accountName)

      return matchedAccountNames
  };


  useEffect(() => {
    if (_q?.selectedAccount?.split(",").includes("All")) {
      dispatch({
        type: UPDATE_NEW_SUMMARY_SERVICE_ACCOUNTS, payload: newSummaryServiceAccount?.map((account) => {
          return account?.accountNumber
        })
      })
    } else {
      dispatch({ type: UPDATE_NEW_SUMMARY_SERVICE_ACCOUNTS, payload: _q?.selectedAccount?.split(",") })
    }

    const selectedAccountsNames = _q?.selectedAccount && findAccountNames(_q?.selectedAccount)

    const selectionCount = selectedAccountsNames?.length;
    if (selectedAccountsNames && selectedAccountsNames.length > 0) {
      setSelectedAccountsDisplayName(
        selectionCount === 1
          ? selectedAccountsNames[0]
          : `${selectedAccountsNames[0]}${selectionCount > 1 ? ` + ${selectionCount - 1}` : ''}`
      );
    }
  }, [newSummaryServiceAccount])

  const handleChangeServiceLine = (e, sl) => {
  
    const isChecked = selectedServiceLineNames.includes(sl.name);
    let selectedSlNames = [...selectedServiceLineNames];
    let selectedSls = [...selectedServiceLines];
    if (isChecked) {
      if (sl.name === 'All Service Lines') {
        selectedSlNames = [];
        selectedSls = [];
        _q.serviceLineAccess =  selectedSls.join(',').toString();
      }
      else if(selectedServiceLineNames.includes('All Service Lines')){
        selectedSlNames = selectedSlNames.filter(name => name == sl.name);
        selectedSlNames = selectedSlNames.filter(name => name !== 'All Service Lines');
        selectedSls = selectedSls.filter(id => id == sl.id);
        selectedSls = selectedSls.filter(id => id !== '');
        _q.serviceLineAccess =  selectedSls.join(',').toString();
      }else if(selectedSlNames.length === 1){
           accessibleServiceLines.forEach(sls => {
          if (!selectedSlNames.includes(sls.name)) {
            selectedSlNames.push(sls.name);
            selectedSls.push(sls.id);
          }
        });
        _q.serviceLineAccess =  ['All'].join(',').toString();
      }
      else {
        selectedSlNames = selectedSlNames.filter(name => name !== sl.name);
        selectedSlNames = selectedSlNames.filter(name => name !== 'All Service Lines');
        selectedSls = selectedSls.filter(id => id !== sl.id);
        selectedSls = selectedSls.filter(id => id !== '');
        _q.serviceLineAccess =  selectedSls.join(',').toString();
      }
    } else {
      if (sl.name === 'All Service Lines') {
        setAccessibleServiceLines(allFetchedServiceLines)
        accessibleServiceLines.forEach(sls => {
          if (!selectedSlNames.includes(sls.name)) {
            selectedSlNames.push(sls.name);
            selectedSls.push(sls.id);
          }
        });
        _q.serviceLineAccess =  ['All'].join(',').toString();
      } else {
        if (!selectedSlNames.includes(sl.name)) {
          selectedSlNames.push(sl.name);
          selectedSls.push(sl.id);
          _q.serviceLineAccess =  selectedSls.join(',').toString();
        }
      }
    }
    updateSLDisplayNames(selectedSlNames)
    setSelectedServiceLines(selectedSls);
    setSelectedServiceLineNames(selectedSlNames);
  

    delete _q.page;
  
    history.push({ pathname: location.url, search: `?${getEncodedURI(_q)}` });
  };

  const updateSLDisplayNames = (selectedSls) => {
    if (0 === selectedSls.length) {
      setSelectedSLDisplayName("");
    }else if (accessibleServiceLines.length-1 === selectedSls.length) {
      setSelectedSLDisplayName("All Service Lines")
    }  else if (1 === selectedSls.length) {
      setSelectedSLDisplayName(selectedSls[0])
    } else {
      setSelectedSLDisplayName(`${selectedSls[0]} +${selectedSls.length - 1}`);
    }
  }

  useEffect(() => {
    if(window.location.pathname.includes('/starlink') && _q.ouName) {
     getVesselsListing(_q.ouName, "");
    }
 }, [])

  useEffect(() => {
    if (authReducer?.isInventoryPreviousPage && ouName && !window.location.pathname?.includes('/inventory')) {
      getVesselsListing(ouName, "");
      dispatch({type: IS_INVENTORY_PREVIOUS_PAGE, payload: false})
    }
  }, [location.search, authReducer?.isInventoryPreviousPage, ouName])


  useEffect(() => {
    if (authReducer?.permissionObserver) {
      let info = authReducer?.permissionObserver;
      if(info.hasOwnProperty("isUnselectNeeded")){
        setIsUnselectNeeded(info?.isUnselectNeeded);
      } else {
        setIsUnselectNeeded(true);
      }
      setIsMultiVessel(info.isMultiVessel);
      setIsSingleVessel(info.isSingleVessel);
      setIsHubSites(info.hubSites);
      if(info.showOu !== undefined) {
        setShowOuList(info.showOu);
      }
      setPermission(info.permission);
    }
  }, [authReducer.permissionObserver]);


  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorGetOrganisation)) {
      displayToastError(errorReducer.errorGetOrganisation.description)
      errorReducer.errorGetOrganisation = {};
    }
  }, [errorReducer.errorGetOrganisation]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorGetOrganisationUnits)) {
      displayToastError(errorReducer.errorGetOrganisationUnits.description)
      errorReducer.errorGetOrganisationUnits = {};
    }
  }, [errorReducer.errorGetOrganisationUnits]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorGetVesselsListing)) {
      displayToastError(errorReducer.errorGetVesselsListing.description)
      errorReducer.errorGetVesselsListing = {};
    }
  }, [errorReducer.errorGetVesselsListing]);

  useEffect(() => {
    if (authReducer.selectedOu && authReducer.selectedOu?.name) {
      setOrganisations([authReducer?.selectedOu]);
    }
    !_q.hasOwnProperty('starlinkTab') && getOrganisationUnitList();
  }, []);

  useEffect(() => {
    let selVessels: any[] = vesselNames;
    let isAll = isAllSelected;
    if (vesselNames.length > 0) {
      if (filterK4Ids && filterK4Ids.length > 0) {
        setIsAllSelected(false);
        isAll = false;
        selVessels = vesselNames.filter(item => filterK4Ids.indexOf(item.id) >= 0);
        let selVesselNames: any[] = selVessels.map(item => item[RESOURCE_FIELD_VESSELNAME] ? item[RESOURCE_FIELD_VESSELNAME] : "");
        setSelectedVessels(selVessels);
        setSelectedVesselNames(selVesselNames);
        updateDisplayName(selVesselNames);
      } else {
        isAll = true;
        setSelectedVesselsDisplayName(CONST_ALL_VESSELS);
        checkAllCheckBox(vesselNames);
      }
    } else {
      isAll = true;
      setSelectedVesselsDisplayName("");
    }
  }, [vesselNames, filterK4Ids]);

  useEffect(() => {
    if (authReducer?.selectedOu?.name && authReducer?.selectedOu?.id && ouName !== authReducer?.selectedOu?.name) {
      setOuName(authReducer?.selectedOu?.name);
      if(authReducer && authReducer?.sideMenu && authReducer?.sideMenu?.menuItem === "starlink" || authReducer?.sideMenu?.menuItem === "map"){     
        getAllServiceLineSubscriptionAPI() 
        fetchServiceLines(authReducer?.selectedOu?.id, selectedServiceAccount) 
      }
      setSelectedAccountsDisplayName("All Accounts")

      let _q = getDecodeURI(location?.search);
      _q.selectedAccount = ['All']
      history.push({ pathname: location.url, search: `?${getEncodedURI(_q)}` });
    } 
    // setDPChange(true)
  }, [authReducer.selectedOu]);

  useEffect(()=>{
    if(!_.isEmpty(selectedServiceAccount) && authReducer?.selectedOu?.name && authReducer?.selectedOu?.id && JSON.stringify(selectedServiceAccount) !== JSON.stringify(selectedAccountPrevious)){
      setSelectedAccountPrevious(selectedServiceAccount)
      if(authReducer && authReducer?.sideMenu && authReducer?.sideMenu?.menuItem === "starlink" || authReducer?.sideMenu?.menuItem === "map"){   
        getAllServiceLineSubscriptionAPI() 
        fetchServiceLines(authReducer?.selectedOu?.id, selectedServiceAccount)
      }
    }
  },[selectedServiceAccount])


  useEffect(()=>{
    getServiceAccounts({
      dpIds: dpIds,
      searchText: "",
     sortBy:"client_id",
     sortOrder:"desc",
     limit:10,
     page: 1
  });
  },[dpIds])

  useEffect(() => {
    if(customStartDate !== newSummaryStartDate) {
      setCustomStartDate(newSummaryStartDate);
    }
    if(customEndDate !== newSummaryEndDate) {
      setCustomEndDate(newSummaryEndDate);
    }
  },[newSummaryStartDate, newSummaryEndDate]);

  const updateDisplayName = (selVesselNames) => {
    if (0 === selVesselNames.length) {
      setSelectedVesselsDisplayName("");
    } else if (1 === selVesselNames.length) {
      setSelectedVesselsDisplayName(selVesselNames[0])
    } else {
      setSelectedVesselsDisplayName(`${selVesselNames[0]} +${selVesselNames.length - 1}`);
    }
  }

  const handleClose = (popupState: any = null) => {
   
  };

  const handleOuClose = (popupState: any = null) => {
    if (popupState) {
      popupState.close();
    }
    if (_.isEmpty(organisations)) {
      getOrganisationUnitList();
    }

    if (!isOuClicked && ouSearchValue) {
      setOuSearchValue("");
      getOrganisationUnitList();
    }
  }

  const handleChangeVessel = (e, vessel) => {
    let name = vessel[RESOURCE_FIELD_VESSELNAME];
    let isChecked = !selectedVesselNames.includes(name);
    let selectedVessel:any = [];
    let selectedVesselName:any = [];
    let isAllSelect = isAllSelected
    if (isChecked) {
      if (name == CONST_ALL_VESSELS) {
        checkAllCheckBox(vesselNames);
      } else {
        if (isAllSelected) {
          selectedVesselName = [name]
          selectedVessel = [vessel]
          isAllSelect = false
          setIsAllSelected(isAllSelect);
          setSelectedVesselNames(selectedVesselName);
          setSelectedVessels(selectedVessel);
        } else {
          selectedVesselName = [...selectedVesselNames, name]
          selectedVessel = [...selectedVessels, vessel]
          setSelectedVesselNames(selectedVesselName);
          setSelectedVessels(selectedVessel);
        }
      }
    } else {
      if (name === CONST_ALL_VESSELS) {
        selectedVesselName = []
        selectedVessel = []
        setSelectedVesselNames(selectedVesselName);
        setSelectedVessels(selectedVessel);
      } else {
        if (isAllSelected) {
          isAllSelect = false
          setIsAllSelected(isAllSelect);
          selectedVesselName = [name]
          selectedVessel = [vessel]
          setSelectedVesselNames(selectedVesselName);
          setSelectedVessels(selectedVessel);
        } else {
          selectedVesselName = selectedVesselNames.filter(item => item !== name)
          selectedVessel = selectedVessels.filter(item => item[RESOURCE_FIELD_VESSELNAME] !== name)
          setSelectedVesselNames(selectedVesselName);
          setSelectedVessels(selectedVessel);
        }
      }
    }

    let params = getDecodeURI(location?.search);
    if (isAllSelect) {
      setSelectedVesselsDisplayName(CONST_ALL_VESSELS);
    } else {
      updateDisplayName(selectedVesselName.filter(item => item !== name));
    }
    _setSelectedVessels(selectedVessel);

    let filterK4Ids: any[] = [];
    if (!isAllSelect) {
      filterK4Ids = selectedVessel.map(item => `${item.id}:${item['vessel-name']}`);
    }
    let navigate = true;
    if (filterK4Ids.length > 0) {
      params.k4Ids = filterK4Ids.toString();
    } else if (params.hasOwnProperty("k4Ids")) {
      delete params.k4Ids;
    } else {
      navigate = false;
    }
    delete params.page;
    // dashboard redirection without internet status filter, when some sites are selected
    if (params.hasOwnProperty("k4Ids")) {
      delete params.filterApplied;
      delete params.internetStatus;
    } else {
      // dashboard redirection with internet status filter, when all sites are selected
      params.filterApplied = "true";
      params.internetStatus = "Online,Offline";
    }

    if (isMultiVessel) {
      updateSelectedVessels({ isAll: isAllSelect, vessels: selectedVessels });
    }
    if (navigate) {
      history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });
    }

  }

  const handleChangeSingleVessel = (e, vessel, popupState: any = null) => {
    if (vessel) {
      let params = getDecodeURI(location?.search);
      if ('' == vessel?.id) {
        delete params.k4Ids;
      } else {
        params.k4Ids = `${vessel.id}:${vessel['vessel-name']}`;
      }
      updateSelectedVessel({ id: vessel.id, "vessel-name": vessel['vessel-name'] })
      history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });
    }

    if (popupState) {
      popupState.close();
    }
  }

  const checkAllCheckBox = (_vessels) => {
    setIsAllSelected(true);
    setSelectedVesselNames(_vessels.map(item => item[RESOURCE_FIELD_VESSELNAME] ? item[RESOURCE_FIELD_VESSELNAME] : ""));
    setSelectedVessels(_vessels.filter(item => item[RESOURCE_FIELD_VESSELNAME] && item[RESOURCE_FIELD_VESSELNAME] !== CONST_ALL_VESSELS));
  }

  const _setSelectedVessels = (vessels: any[]) => {
    subHeaderSelectedDropdown(vessels);
    // setSearchValue("");
    // updateSelectedVessels({ isAll: isAllSelected, vessels: vessels });
  }

  const handleSearch = (event) => {
    setSearchValue(event);
  }

  const handleServiceLinesSearch = (event) => {
    setSearchServiceLines(event);
  }

  const handleOuSearch = async (value) => {
    setOuSearchValue(value);
    setLoading(true);
  }
  // old logic
  // useEffect(() => {
  //   if (!_.isEmpty(authReducer.getVesselsListing) && !_.isEmpty(organisations)) {
  //     let _vessels = [];
  //     // let _single_vessels = [];
  //     let anotherVessels: any = authReducer.getVesselsListing?.locations;
  //     if (anotherVessels) {
  //       anotherVessels = anotherVessels.map((item) => ({
  //         id: item.id,
  //         dpId: item.dpId,
  //         'vessel-name': item.name,
  //       }))
  //       _vessels = anotherVessels;
  //       // _single_vessels = anotherVessels;
  //     }
  //     // sort vessels by name
  //     _vessels = _.sortBy(_vessels, ['vessel-name']);
  //     // _single_vessels = _.sortBy(_single_vessels, ['vessel-name']);
  //     // remove OUs from vessel list
  //     const ous = !_.isEmpty(organisations) && organisations.length > 0 ? organisations.map(org => {
  //       return org?._childern?.length > 0 ? org._childern.map(dp => {
  //         return {
  //           'vessel-name': dp.name,
  //         }
  //       }) : []
  //     }).flat() : [];
  //     _vessels = _.differenceBy(_vessels, ous, 'vessel-name')
  //     setVesselList(_vessels);
  //     // _single_vessels = _.differenceBy(_single_vessels, ous, 'vessel-name')
  //     // setVesselNames([{ "vessel-name": CONST_ALL_VESSELS, id: '' }, ..._vessels]);
  //     // setSingleVesselNames([{ "vessel-name": "unselect", id: '' }, ..._single_vessels]);
  //   }
  // }, [authReducer.getVesselsListing, organisations])

  useEffect(() => {
    if (authReducer.getVesselsListing?.locations?.length && organisations?.length) {
      const vessels = authReducer.getVesselsListing.locations.map((item) => ({
        id: item.id,
        dpId: item.dpId,
        'vessel-name': item.name,
      }));
  
      // Sort vessels by name
      const sortedVessels = _.sortBy(vessels, ['vessel-name']);
  
      // Extract OUs (Organizational Units)
      const ous = organisations.flatMap((org) =>
        org._childern?.map((child) => ({
          'vessel-name': child.name,
        })) || []
      );
  
      // Filter out OUs from the vessel list
      const filteredVessels = _.differenceBy(sortedVessels, ous, 'vessel-name');
  
      // Update the state with filtered vessels
      setVesselList(filteredVessels);
    } else if (authReducer?.getVesselsListing?.status === "ok" && !authReducer?.getVesselsListing?.locations?.length && organisations?.length) {
      setVesselList([])
    }
  }, [authReducer.getVesselsListing, organisations]);

  useEffect(() => {
    setVesselNames([{ "vessel-name": CONST_ALL_VESSELS, id: '' }, ...vesselList]);
    setSingleVesselNames([{ "vessel-name": "clear selection", id: '' }, ...vesselList]);
  }, [vesselList])
  
  const memoizedVesselList = useMemo(() => {
    // Perform any transformations or computations on vesselList, if needed.
    return vesselList || []; // Return the vessel list directly if no transformations are required.
  }, [vesselList]);
  
  useEffect(() => {
    const debouncedSearch = _.debounce(() => {
      if (searchValue !== "" && !_.isEmpty(memoizedVesselList)) {
        const filteredVessels = memoizedVesselList.filter(
          (vessel) =>
            vessel["vessel-name"] &&
            vessel["vessel-name"].toLowerCase().includes(searchValue.toLowerCase())
        );
  
        setVesselNames([
          { "vessel-name": CONST_ALL_VESSELS, id: "" },
          ...filteredVessels,
        ]);
  
        setSingleVesselNames([
          { "vessel-name": "clear selection", id: "" },
          ...filteredVessels,
        ]);
      } else {
        setVesselNames([
          { "vessel-name": CONST_ALL_VESSELS, id: "" },
          ...memoizedVesselList,
        ]);
        setSingleVesselNames([
          { "vessel-name": "clear selection", id: "" },
          ...memoizedVesselList,
        ]);
      }
    });
  
    debouncedSearch();
  
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchValue, memoizedVesselList]);
  

  useEffect(() => {
    if ( !_.isEmpty(allFetchedServiceLines)) {
      if (searchServiceLines !== "") {
        const filteredServiceLines = allFetchedServiceLines.filter(line => line.name.toLowerCase().includes(searchServiceLines.toLowerCase()));
        setAccessibleServiceLines(filteredServiceLines);
      } else {
        setAccessibleServiceLines(allFetchedServiceLines);
      }
    }

  }, [searchServiceLines])

  function populateForParent(data, parentid, org) {
    let _newitem: any = {
      name: data.name,
      id: data.id,
      _childern: []
    };
    if (data && data.parent_of) {
      for (let child of data.parent_of) {
        let newitem: any = {
          name: child.name,
          id: child.id
        }
        _newitem._childern.push(newitem)
        if (child.parent_of) {
          newitem["_childern"] = [];
          for(let nextChild of child.parent_of){
          populateForParent(nextChild, newitem.id, newitem._childern);
          }
        }
      }
    }
    org.push(_newitem)
  }

  const searchOu = (tree, ouName) => {
    if (tree && ouName) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].name === ouName) {
          return tree[i];
        }
        if (tree[i].parent_of) {
          let ou = searchOu(tree[i].parent_of, ouName);
          if (ou) return ou;
        }
      }
    }
    return null;
  }

  const searchOuList = (data, searchValue) => {
    const recursiveSearch = (arr) => {
      let result: any = [];
      for (let item of arr) {
        let children: any = [];
        if (item.parent_of && item.parent_of.length > 0) {
          children = recursiveSearch(item.parent_of);
        }
        if (item.name.toLowerCase().includes(searchValue.toLowerCase()) || children.length > 0) {
          result.push({
            name: item.name,
            id: item.id,
            _childern: children
          });
        }
      }
      return result;
    }
    const ouList = {
      name: data.name,
      id: data.id,
      _childern: recursiveSearch(data.parent_of)
    };

    return ouList;
  }

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
    if(!_.isEmpty(authReducer.getOrganisationUnitList) && authReducer.getOrganisationUnitList.dps && authReducer.getOrganisationUnitList.dps.length > 0 && ouSearchValue) {
      const ouList = authReducer.getOrganisationUnitList.dps[0];
      const result = searchOuList(ouList, ouSearchValue);
      setNewOrganisationsList([result]);
    }
    else if (!_.isEmpty(authReducer.getOrganisationUnitList) && authReducer.getOrganisationUnitList.dps && authReducer.getOrganisationUnitList.dps.length > 0) {
      let _organisations: any[] = [];
      if (!_.isEmpty(authReducer.getOrganisationUnitList.dps)) {

        authReducer.getOrganisationUnitList.dps?.map((item:any, i)  => {
            populateForParent(item, null, _organisations);
            if(item?.name === authReducer.getOrganisationUnitList.root_ou) {
              if(item.hasOwnProperty("id") && item.hasOwnProperty("name")){
                let parentOu = {
                  "id": item.id,
                  "name" : item.name
                }
                  setDataToLocalStorage(USER_OU, JSON.stringify(parentOu));
              }
            }
        }) 
      }
      _organisations = !_.isEmpty(_organisations) ? _.sortBy(_organisations.map(org => {
        return !_.isEmpty(org?._childern) ? {
          ...org,
          _childern: _.sortBy(org._childern, 'name')
        } : org
      }), 'name') : _organisations

      setOrganisations(_organisations);
      if (_organisations.length > 0) {
        let _ou = _organisations[0];
        if (ouName && '' != ouName.trim()) {
          if (searchOu(_organisations, ouName)) {
            _ou = null;
          }
        }
        let _q = getDecodeURI(location?.search);
        if ((!_q.ouName || '' === _q.ouName || !_q.ouId || '' === _q.ouId) && _ou) {
          updateSelectedOu(_ou);
        }
      }


      // authReducer.getOrganisationUnitList = {};
    }
  }, [authReducer.getOrganisationUnitList, ouSearchValue]);


  const handleOuClick = (event, name, popupState, id, ignoreou = false) => {
    let params = getDecodeURI(location?.search);
    if (ignoreou) {
      delete params.ouName;
      delete params.ouId;
      delete params.page;
    }else {
      params.ouName = name;
      params.ouId = id;
      delete params.page;
      if(window.location.pathname == '/fleet-dashboard/sites') {
        params.filterApplied = "true";
        params.internetStatus = "Online,Offline";
      }
    }
    params.serviceLineAccess = "All";
    delete params.k4Ids;
    delete params.hubK4Ids;
    history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });

    if (popupState) {
      popupState.close();
    }
    setIsOuClicked(true);
    updateSelectedVessels({ isAll: true, vessels: [] });
    if(ouSearchValue){
      setOuSearchValue("");
      // getOrganisationUnitList();
    }
  };
  interface OuItem {
    id: string;
    name: string;
    _childern: OuItem[];
  }
  
  interface PopupState {
    // Define the structure of popupState if needed
  }

  const [visibleItems, setVisibleItems] = useState<{ [key: string]: boolean }>({});

  const handleToggleVisibility = (key: string) => {
    setVisibleItems(prevState => ({ ...prevState, [key]: !prevState[key] }));
  };

  const getOuTree = (popupState: PopupState, ous: OuItem[], prefix: string = ""): React.ReactNode[] => {
    let list: React.ReactNode[] = [];
    if (ous && Array.isArray(ous)) {
      for (let i = 0; i < ous.length; i++) {
        const item = ous[i];
        const key = prefix + i;
        // const isVisible = visibleItems[key] || false;
        let isVisible = prefix === "" ? true : visibleItems[key] || false;
        if(ouSearchValue) {
          isVisible = true
        }
        const _children = getOuTree(popupState, item._childern || [], key + "_");
  
        list.push(
          <div key={key + 'div'} style={{ background: "#ffffff" }}>
            <Tooltip placement="left" title={<span className="span_NewSummary">{item.name}</span>} arrow>
              <MenuItem
                key={key}
                className="OuMenuChild"
               
              >
                <span className="plusMinusIc" onClick={(e) => { e.stopPropagation(); handleToggleVisibility(key); }}>
                  {item._childern && item._childern.length > 0 ? (isVisible ? "-" : "+") : "-"}
                </span>
                <ListItemText primary={item.name} className="childHierarch textWrapInner"  onClick={(e) => handleOuClick(e, item.name, popupState, item.id)} />
                {ouName === item.name && <CheckIcon style={{ background: "#474444", fontSize: "12px" }} />}
              </MenuItem>
            </Tooltip>
            {isVisible && (
              <div key={key + 'childern'} className="childern">
                {_children}
              </div>
            )}
          </div>
        );
      }
    }
    return list;
  };

  // const doNavigate = (pathname, params = {}) => {
  //   history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
  // }

  const handleChangePeriod = (e, period, popupState) => {
    if (popupState) {
      popupState.close();
    }
  
    let _now = Date.now();
    const end = moment(_now).utc(); 
    let start, customEnd;
  
    if (period?.value === "MTD") {
      start = moment(_now).utc().startOf('month'); 
      customEnd = end; 
    } 
    else if (period?.value === "plans") { 
      start = moment(_now).utc().startOf('month'); 
      customEnd = end; 

      newSummaryServiceAccount.forEach((account) => {
        if (account?.isPooledAccount) {
            start = moment(account?.startDate);
            customEnd = moment(account?.endDate);
        }
    });

     } 
    else if (period?.value.match(/^\d{4}-\d{2}$/)) { 
      start = moment(period.value).startOf('month'); 
      customEnd = moment(period.value).endOf('month').set({ hour: 23, minute: 59, second: 59 });
    } 
    else if (period?.duration && period?.timeVal) {
      start = moment(_now).utc().subtract(period?.duration, period?.timeVal); 
      customEnd = end; 
    }
  
    setTimePeriod?.(period);
    dispatch({ type: UPDATE_NEW_SUMMARY_END_DATE, payload: customEnd });
    dispatch({ type: UPDATE_NEW_SUMMARY_START_DATE, payload: start });
    setSelectedPeriod(period?.label);
  
    let params = getDecodeURI(location?.search);
    params.interval = period?.value;
    params.startDate = start.valueOf(); 
    params.endDate = customEnd.valueOf();
    delete params.page; 
  
    history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });
  };
  

  // const handleChangePeriod = (e, period, popupState) =>{

  //   if(popupState){
  //     popupState.close()
  //   }

  //   let _now = Date.now();
  //   const end = moment(_now).utc();
  //   let start;

  //   if (period?.value === "MTD") {
  //     start = moment(_now).utc().startOf('month');
  //   } else {
  //     start = moment(_now).utc().subtract(period?.duration, period?.timeVal);
  //   }

  //   setTimePeriod?.(period);
  //   dispatch({ type: UPDATE_NEW_SUMMARY_END_DATE, payload: end})
  //   dispatch({ type: UPDATE_NEW_SUMMARY_START_DATE, payload: start})
  //   setSelectedPeriod(period?.label?.split(" ")[2] ? period?.label?.split(" ")[1] + " " + period?.label?.split(" ")[2] : period?.label)   
 
  //   let params: any = getDecodeURI(location?.search);
  //   params.interval = period?.value;
  //   params.startDate = start.valueOf();
  //   params.endDate = end.valueOf();
  //   delete params.page;
  //   // doNavigate(location.url, params);
  //   history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });

  // }
  

  useEffect(() => {
    if (!_.isEmpty(periodOptions)) {
      let params: any = getDecodeURI(location?.search);
      if (!periodOptions?.includes?.(params.interval)) {
        if (periodOptions?.[0]) {
          let _option = NEWINTERVALS?.find(interval => interval.value === periodOptions?.[0]);
          handleChangePeriod(null, _option, null)
        }
      }
    }
  }, [periodOptions])
 

  const handleChangeWan = (e, wan, popupState) =>{
    if(popupState){
      popupState.close()
    }
    dispatch({ type: UPDATE_NEW_SUMMARY_WAN_TYPE, payload: wan})
    let params: any = getDecodeURI(location?.search);
    params.wanType = wan?.label;
    delete params.page;
    // doNavigate(location.url, params);
    history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });

  }

  const handleChangeSeverity = (e, severity, popupState) =>{
    if(popupState){
      popupState.close()
    }

    dispatch({ type: UPDATE_NEW_SEVERITY_TYPE, payload: severity})
    let params: any = getDecodeURI(location?.search);
    params.severityType = severity?.label;
    delete params.page;
    // doNavigate(location.url, params);
    history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });

  }

  const handleChangeServiceAccount = (e, account, popupState) => {
    let updatedSelection = selectedServiceAccount && selectedServiceAccount.length > 0 ? [...selectedServiceAccount] : [];
  
    if (account?.accountNumber === 'All') {
      if (selectedServiceAccount?.includes('All')) {
        setSelectedAccountsDisplayName("");
        updatedSelection = [];
      } else {
        setSelectedAccountsDisplayName("All Accounts");
        updatedSelection = newSummaryServiceAccount?.map(acc => acc.accountNumber);
      }
    } else {
      if (selectedServiceAccount?.includes('All')) {
        updatedSelection = [account?.accountNumber];
      } else {
        if (updatedSelection.includes(account?.accountNumber)) {
          updatedSelection = updatedSelection?.filter(acc => acc !== account.accountNumber); 
        } else {
          updatedSelection?.push(account.accountNumber);
        }
        setSelectedAccountsDisplayName("");
      }
  

      const selectedAccountsNames = newSummaryServiceAccount
        ?.filter(acc => updatedSelection?.includes(acc.accountNumber))
        ?.map(acc => acc.accountName);
  

      const selectionCount = selectedAccountsNames?.length;
      setSelectedAccountsDisplayName(
        selectionCount === 0 ? "" :
        selectionCount === 1 
          ? selectedAccountsNames[0] 
          : `${selectedAccountsNames[0]}${selectionCount > 1 ? ` + ${selectionCount - 1}` : ''}` 
      );
    }
      
    if (popupState) {
      popupState.close();
    }
  
    dispatch({ type: UPDATE_NEW_SUMMARY_SERVICE_ACCOUNTS, payload: updatedSelection });
    let params = getDecodeURI(location?.search);
    params.selectedAccount = updatedSelection.join(',');
    delete params.page;
    history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });
  };
  
  const handleT0Visible = () => {
    if (window.location.pathname?.includes('/inventory')) {
      return true
    }
    return false
  }
  
  
 const handleCustomDate = (popupState) =>{
  if(popupState){
    popupState.close()
  }
  setSelectedPeriod("customDates")

  let params: any = getDecodeURI(location?.search);
  params.interval = "customDates";
  if (customStartDate && customEndDate) {
    params.startDate = customStartDate.valueOf();
    params.endDate = customEndDate.valueOf();
  }
  delete params.page;
  // doNavigate(location.url, params);
  history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });

  }

  const handleClickAway = () => {
    setCustomStartDate(newSummaryStartDate);
    setCustomEndDate(newSummaryEndDate);
  }

    // Function to render each row in the dropdown using react-window
    const renderRow = ({ index, style }) => {
      const vessel = vesselNames[index];
      const isSelected = selectedVesselNames.includes(vessel[RESOURCE_FIELD_VESSELNAME]);
  
      return (
        <Tooltip
          placement="left"
          title={<span className="span_NewSummary">{vessel[RESOURCE_FIELD_VESSELNAME]}</span>}
          arrow
        >
          <div style={style}>
            <MenuItem
              key={vessel.id || index}
              className="vessel_name_item"
              onClick={(e) => handleChangeVessel(e, vessel)}
            >
              <ListItemText
                className="vessel_name"
                primary={<span className="textWrapInner">{vessel[RESOURCE_FIELD_VESSELNAME]}</span>}
              />
              {isSelected ? (
                <CheckIcon className="checkedIcon_NewSummary" />
              ) : (
                <CheckBoxOutlineBlankIcon className="unCheckedIcon_NewSummary" />
              )}
            </MenuItem>
          </div>
        </Tooltip>
      );
    };

  return (
    <Grid className="layout_NewSummary background-gray">
      {
        showOuList ?
        <>
        <span className="ns_common_filters_typo">Organization</span>
          <Grid className="vesselDropdown mainGrid_NewSummary " >
            
            <PopupState variant="popover" popupId="ou-popup-menu">
              {(popupState) => (
                <React.Fragment >
                  <Tooltip title={<span className="span_NewSummary">{ouName}</span>}>
                  <Button style={{height:newSummary ? "32px" : "26px" }} id={!disableOu ? 'enable-cursor' : 'disable-cursor'} className={popupState.isOpen ? "vesselDropdownInput vesselDropdown-button on_dropdown_open" : "vesselDropdownInput vesselDropdown-button"} variant="contained" {...!disableOu && {...bindTrigger(popupState)}}>
                   <span className="textWrap">{ouName}</span>  
                    {
                      popupState.isOpen ?
                        <img alt="dropdown" className="dropdownArrow keyDownIcon_NewSummary margin-left-auto padding-r-15" src={CommonFilterDownIcon} />
                        :
                        <img alt="dropdown" className="dropdownArrow margin-left-auto padding-r-15" src={CommonFilterDownIcon} />
                    }
                  </Button>
                  </Tooltip>
                  <Menu {...bindMenu(popupState)}
                    id="menu-ou"
                    aria-labelledby="menu-ou"
                    onClose={() => handleOuClose(popupState)}
                    key="menu-ou"
                    className="childHierarchy menuScroll"
                  >
                    <MenuItem key={'ou_text'} className="vessel_search_item vessel_search_item" onKeyDown={(e) => e.stopPropagation()}>
                      <TextField
                        id="ousearchtext"
                        variant="outlined"
                        placeholder="Type something"
                        autoComplete="off"
                        size="small"
                        value={ouSearchValue}
                        onChange={(event) => {
                          handleOuSearch(event.target.value);
                        }}
                        InputLabelProps={{ className: "serachLabel" }}
                        InputProps={{
                          className: "serachBar",
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon   className="cursorPointer" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MenuItem>
                    <span>
                    {getOuTree(popupState, ouSearchValue ? newOrganisationsList : organisations)}</span>
                  </Menu>

                </React.Fragment>
              )}
            </PopupState>
          </Grid>
          </>
          : <></>
      }
      {
        isMultiVessel ?
        <>
        <span className="ns_common_filters_typo">Sites</span>
          <Grid className="vesselDropdown globalFilterMarginRight" sx={{ marginRight: "15px !important" }}>
            <PopupState variant="popover" popupId="vessels-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Tooltip title={<span  className="span_NewSummary">{selectedVesselsDisplayName}</span>}>
                  <Button style={{height:newSummary ? "32px" : "26px" }} id={!disableSite ? 'enable-cursor' : 'disable-cursor'} className={popupState.isOpen ? "vesselDropdownInput vesselDropdown-button on_dropdown_open" : "vesselDropdownInput vesselDropdown-button"} variant="contained" {...!disableSite && {...bindTrigger(popupState)}}>
                  <span className="textWrap">{selectedVesselsDisplayName}</span>  
                      {
                      popupState.isOpen ?
                        <img alt="dropdown" className="dropdownArrow keyDownIcon_NewSummary margin-left-auto padding-r-15" src={CommonFilterDownIcon} />
                        :
                        <img alt="dropdown" className="dropdownArrow margin-left-auto padding-r-15" src={CommonFilterDownIcon} />
                    }
                  </Button>
                  </Tooltip>
                  <Menu {...bindMenu(popupState)}
                    id="menu-vessels"
                    aria-labelledby="menu-vessels"
                    onClose={() => {  if (popupState) {
                      setSearchValue("");
                      popupState.close();
                    }}}
                    key="menu-vessels"
                    className="menuScroll"
                    >
                    <MenuItem key={'vessel_text'} className="vessel_search_item" onKeyDown={(e) => e.stopPropagation()} sx={{p: 0.8}}>
                      <TextField
                        id="searchtext"
                        variant="outlined"
                        placeholder="Type something"
                        size="small"
                        autoComplete="off"
                        value={searchValue}
                        onChange={(event) => {
                          handleSearch(event.target.value);
                        }}
                        sx={{width: '100%'}}
                        // onClick={handleSearch}
                        InputLabelProps={{ className: "serachLabel" }}
                        InputProps={{
                          className: "serachBar",
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon  className="cursorPointer searchIcon_NewSummary" />
                              <CheckLineIcon onClick={() => {  if (popupState) {
                                      popupState.close();
                                    }}}  className="cursorPointer checkLine_NewSummary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MenuItem>
                    {/* {vesselNames.map((vessel, i) => (
                      <Tooltip placement="left" title={<span  className="span_NewSummary">{vessel[RESOURCE_FIELD_VESSELNAME]}</span>} arrow>
                        <div>
                          <MenuItem key={'vessel_' + i} className="vessel_name_item" onClick={(e) => handleChangeVessel(e, vessel)}>
                            <ListItemText className="vessel_name" primary={<span className="textWrapInner">{vessel[RESOURCE_FIELD_VESSELNAME]}</span>} />
                            {
                              selectedVesselNames.includes(vessel[RESOURCE_FIELD_VESSELNAME]) ?
                                <CheckIcon  className="checkedIcon_NewSummary" />
                                :
                                <CheckBoxOutlineBlankIcon  className="unCheckedIcon_NewSummary" />
                            }
                          </MenuItem>
                        </div>
                      </Tooltip>
                    ))} */}

                  <FixedSizeList
                  height={225} // Adjust dropdown height
                  itemCount={vesselNames.length}
                  itemSize={25} // Adjust row height
                  width="100%"
                >
                  {renderRow}
                </FixedSizeList>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Grid>
          </>
          : null
      }
      {
        isHubSites && window.location.pathname?.includes('/hub') ?
        <>
        <span className="ns_common_filters_typo">Hub</span>
          <Grid className="vesselDropdown globalFilterMarginRight" sx={{ marginRight: "15px !important" }}>
            <PopupState variant="popover" popupId="vessels-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Tooltip title={<span  className="span_NewSummary">{hubSelectedVesselsDisplayName}</span>}>
                  <Button style={{height:newSummary ? "32px" : "26px" }} id={!disableSite ? 'enable-cursor' : 'disable-cursor'} className={popupState.isOpen ? "vesselDropdownInput vesselDropdown-button on_dropdown_open" : "vesselDropdownInput vesselDropdown-button"} variant="contained" {...!disableSite && {...bindTrigger(popupState)}}>
                  <span className="textWrap">{hubSelectedVesselsDisplayName}</span>  
                      {
                      popupState.isOpen ?
                        <img alt="dropdown" className="dropdownArrow keyDownIcon_NewSummary margin-left-auto padding-r-15" src={CommonFilterDownIcon} />
                        :
                        <img alt="dropdown" className="dropdownArrow margin-left-auto padding-r-15" src={CommonFilterDownIcon} />
                    }
                  </Button>
                  </Tooltip>
                  <Menu {...bindMenu(popupState)}
                    id="menu-vessels"
                    aria-labelledby="menu-vessels"
                    onClose={() => {  if (popupState) {
                      setSiteSearchValue("");
                      popupState.close();
                    }}}
                    key="menu-vessels"
                    className="menuScroll"
                    >
                    <MenuItem key={'vessel_text'} className="vessel_search_item" onKeyDown={(e) => e.stopPropagation()} sx={{p: 0.8}}>
                      <TextField
                        id="searchtext"
                        variant="outlined"
                        placeholder="Type something"
                        autoComplete="off"
                        size="small"
                        value={siteSearchValue}
                        onChange={(event) => {
                          setSiteSearchValue(event.target.value);
                        }}
                        sx={{width: '100%'}}
                        InputLabelProps={{ className: "serachLabel" }}
                        InputProps={{
                          className: "serachBar",
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon  className="cursorPointer searchIcon_NewSummary" />
                              <CheckLineIcon onClick={() => {  if (popupState) {
                                      popupState.close();
                                    }}}  className="cursorPointer checkLine_NewSummary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MenuItem>
                    {hubVesselNames?.map((vessel, i) => (
                      <Tooltip placement="left" title={<span  className="span_NewSummary">{vessel[HUB_RESOURCE_FIELD_VESSELNAME]}</span>} arrow>
                        <div>
                          <MenuItem key={'vessel_' + i} className="vessel_name_item" onClick={(e) => handleChangeVesselHub(e, vessel)}>
                            <ListItemText className="vessel_name" primary={<span className="textWrapInner">{vessel[HUB_RESOURCE_FIELD_VESSELNAME]}</span>} />
                            {
                              hubSelectedVesselNames.includes(vessel[HUB_RESOURCE_FIELD_VESSELNAME]) ?
                                <CheckIcon  className="checkedIcon_NewSummary" />
                                :
                                <CheckBoxOutlineBlankIcon  className="unCheckedIcon_NewSummary" />
                            }
                          </MenuItem>
                        </div>
                      </Tooltip>
                    ))}
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Grid>
          </>
          : null
      }
       {
        isSingleVessel ?
          <Grid className="vesselDropdown" sx={{ marginRight: "15px !important" }}>
            <PopupState variant="popover" popupId="vessel-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Tooltip title={<span  className="span_NewSummary">{selectedSingleVessel ? selectedSingleVessel[RESOURCE_FIELD_VESSELNAME] : CONST_SELECT_VESSEL}</span>}>
                  <Button style={{height:newSummary ? "32px" : "26px" }} className={popupState.isOpen ? "vesselDropdownInput vesselDropdown-button on_dropdown_open button-shadow" : "vesselDropdownInput vesselDropdown-button button-shadow"} variant="contained" {...bindTrigger(popupState)}>
                    <span className="textWrap">{selectedSingleVessel ? selectedSingleVessel[RESOURCE_FIELD_VESSELNAME] : CONST_SELECT_VESSEL}</span>
                    <img alt="dropdown-disabled" className="dropdownArrow margin-left-auto padding-right-15" src={CommonFilterDownIcon} />
                  </Button>
                  </Tooltip>
                  <Menu {...bindMenu(popupState)}
                    id="menu-vessels"
                    aria-labelledby="menu-vessels"
                    key="menu-vessels">
                    <MenuItem key={'vessel_text'} className="vessel_search_item vessel_search_item" onKeyDown={(e) => e.stopPropagation()}>
                      <TextField
                        id="searchtext"
                        variant="outlined"
                        placeholder="Type something"
                        autoComplete="off"
                        size="small"
                        value={searchValue}
                        onChange={(event) => {
                          handleSearch(event.target.value);
                        }}
                        InputLabelProps={{ className: "serachLabel" }}
                        InputProps={{
                          className: "serachBar",
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon   className="cursorPointer searchIcon_NewSummary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MenuItem>
                    {singleVesselNames.map((vessel, i) => (
                      !isUnselectNeeded && vessel[RESOURCE_FIELD_VESSELNAME] === "unselect" ? "" :
                      <MenuItem key={'single_vessel_' + i} className="vessel_name_item" onClick={(e) => handleChangeSingleVessel(e, vessel, popupState)}>
                        <ListItemText className="vessel_name" primary={vessel[RESOURCE_FIELD_VESSELNAME]} />
                        {
                          (selectedSingleVessel[RESOURCE_FIELD_VESSELNAME] == vessel[RESOURCE_FIELD_VESSELNAME]) ?
                            <RadioButtonCheckedIcon  className="checkedSingleVessel" />
                            :
                            <RadioButtonUncheckedIcon  className="uncheckedSingleVessel" />
                        }
                      </MenuItem>
                    ))}
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Grid>
          : null
      }

{
        newSummary && (showAccounts)?
        <>
        <span className="ns_common_filters_typo">Accounts</span>
          <Grid className="vesselDropdown globalFilterMarginRight" sx={{ marginRight: "15px !important" }}>
            <PopupState variant="popover" popupId="vessels-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Tooltip title={<span  className="span_NewSummary">{selectedAccountsDisplayName}</span>}>
                  <Button style={{height:newSummary ? "32px" : "26px" }} 
                  id={!disableSite ? 'enable-cursor' : 'disable-cursor'} 
                  className={popupState.isOpen ? "vesselDropdownInput vesselDropdown-button on_dropdown_open" : "vesselDropdownInput vesselDropdown-button"} 
                  variant="contained" {...!disableSite && {...bindTrigger(popupState)}}>
                  <span className="textWrap">{selectedAccountsDisplayName}</span>  
                      {
                      popupState.isOpen ?
                        <img alt="dropdown" className="dropdownArrow keyDownIcon_NewSummary margin-left-auto padding-r-15" src={CommonFilterDownIcon} />
                        :
                        <img alt="dropdown" className="dropdownArrow margin-left-auto padding-r-15" src={CommonFilterDownIcon} />
                    }
                  </Button>
                  </Tooltip>
                  <Menu {...bindMenu(popupState)}
                    id="menu-vessels"
                    aria-labelledby="menu-vessels"
                    onClose={() => {  if (popupState) {
                      setSearchValue("");
                      popupState.close();
                    }}}
                    key="menu-vessels"
                    className="menuScroll"
                    >
                    <MenuItem key={'vessel_text'} className="vessel_search_item" onKeyDown={(e) => e.stopPropagation()} sx={{p: 0.8}}>
                      <TextField
                        id="searchtext"
                        variant="outlined"
                        placeholder="Type something"
                        autoComplete="off"
                        size="small"
                        value={searchValue}
                        onChange={(event) => {
                          handleSearch(event.target.value);
                        }}
                        sx={{width: '100%'}}
                        // onClick={handleSearch}
                        InputLabelProps={{ className: "serachLabel" }}
                        InputProps={{
                          className: "serachBar",
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon  className="cursorPointer searchIcon_NewSummary" />
                              <CheckLineIcon onClick={() => {  if (popupState) {
                                      popupState.close();
                                    }}}  className="cursorPointer checkLine_NewSummary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MenuItem>
                    {newSummaryServiceAccount?.map((account, i) => (
                      <Tooltip placement="left" title={<span  className="span_NewSummary">{account?.accountNumber}</span>} arrow>
                        <div>
                          <MenuItem key={'vessel_' + i} className="vessel_name_item" onClick={(e) => handleChangeServiceAccount(e, account, popupState)}>
                            <ListItemText className="vessel_name" primary={<span className="textWrapInner">{account?.accountName}</span>} />
                            {
                              selectedServiceAccount?.includes(account?.accountNumber) ?
                                <CheckIcon  className="checkedIcon_NewSummary" />
                                :
                                <CheckBoxOutlineBlankIcon  className="unCheckedIcon_NewSummary" />
                            }
                          </MenuItem>
                        </div>
                      </Tooltip>
                    ))}
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Grid>
          </>
          : null
      }
         {/* {
        newSummary && (showSLDropDown) ?
        <>
        <span className="ns_common_filters_typo">Service Line</span>
          <Grid className="vesselDropdown globalFilterMarginRight" sx={{ marginRight: "15px !important" }}>
            <PopupState variant="popover" popupId="vessels-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Tooltip title={<span  className="span_NewSummary">{selectedVesselsSLName}</span>}>
                  <Button style={{height:newSummary ? "32px" : "26px" }} 
                  id={!disableSite ? 'enable-cursor' : 'disable-cursor'} 
                  className={popupState.isOpen ? "vesselDropdownInput vesselDropdown-button on_dropdown_open" : "vesselDropdownInput vesselDropdown-button"} 
                  variant="contained" {...!disableSite && {...bindTrigger(popupState)}}>
                  <span className="textWrap">{selectedVesselsSLName}</span>  
                      {
                      popupState.isOpen ?
                        <img alt="dropdown" className="dropdownArrow keyDownIcon_NewSummary margin-left-auto padding-r-15" src={CommonFilterDownIcon} />
                        :
                        <img alt="dropdown" className="dropdownArrow margin-left-auto padding-r-15" src={CommonFilterDownIcon} />
                    }
                  </Button>
                  </Tooltip>
                    <Menu {...bindMenu(popupState)}
                      id="menu-vessels"
                      aria-labelledby="menu-vessels"
                      onClose={() => {
                        if (popupState) {
                          setSearchServiceLines("");
                          popupState.close();
                          let asl = [
                            ...accessibleServiceLines.filter((sl, index, self) => sl.name === 'All Service Lines' && self.findIndex(s => s.name === 'All Service Lines') === index),
                            ...accessibleServiceLines.filter(sl => selectedServiceLineNames.includes(sl.name)),
                            ...accessibleServiceLines.filter(sl => sl.name !== 'All Service Lines' && !selectedServiceLineNames.includes(sl.name))
                          ]
                          let seenAllServiceLines = false;
                          let result = asl.filter(item => {
                            if (item.name === "All Service Lines") {
                                if (seenAllServiceLines) {
                                    return false;
                                }
                                seenAllServiceLines = true;
                            }
                            return true;
                        })
                          setAccessibleServiceLines(result)
                        }
                      }}
                      key="menu-vessels"
                      className="menuScroll"
                    >
                    <MenuItem key={'vessel_text'} className="vessel_search_item" onKeyDown={(e) => e.stopPropagation()} sx={{p: 0.8}}>
                      <TextField
                        id="searchtext"
                        variant="outlined"
                        placeholder="Type something"
                        size="small"
                        value={searchServiceLines}
                        onChange={(event) => {
                          handleServiceLinesSearch(event.target.value);
                        }}
                        sx={{width: '100%'}}
                        // onClick={handleSearch}
                        InputLabelProps={{ className: "serachLabel" }}
                        InputProps={{
                          className: "serachBar",
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon  className="cursorPointer searchIcon_NewSummary" />
                              <CheckLineIcon onClick={() => {  if (popupState) {
                                  let asl = [
                                    ...accessibleServiceLines.filter((sl, index, self) => sl.name === 'All Service Lines' && self.findIndex(s => s.name === 'All Service Lines') === index),
                                    ...accessibleServiceLines.filter(sl => selectedServiceLineNames.includes(sl.name)),
                                    ...accessibleServiceLines.filter(sl => sl.name !== 'All Service Lines' && !selectedServiceLineNames.includes(sl.name))
                                  ]
                                  let seenAllServiceLines = false;
                                  let result = asl.filter(item => {
                                    if (item.name === "All Service Lines") {
                                        if (seenAllServiceLines) {
                                            return false;
                                        }
                                        seenAllServiceLines = true;
                                    }
                                    return true;
                                })
                                  setAccessibleServiceLines(result)
                                setSearchServiceLines("")
                                      popupState.close();
                                    }}}  className="cursorPointer checkLine_NewSummary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MenuItem>
                    {accessibleServiceLines.map((sl, i) => (
                      <Tooltip placement="left" title={<span  className="span_NewSummary">{sl.name}</span>} arrow>
                        <div>
                          <MenuItem key={'vessel_' + i} className="vessel_name_item" onClick={(e) => handleChangeServiceLine(e, sl)}>
                            <ListItemText className="vessel_name" primary={<span className="textWrapInnerSL">{sl.name}</span>} />
                            {
                              selectedServiceLineNames.includes(sl.name) ?
                                <CheckIcon  className="checkedIcon_NewSummary" />
                                :
                                <CheckBoxOutlineBlankIcon  className="unCheckedIcon_NewSummary" />
                            }
                          </MenuItem>
                        </div>
                      </Tooltip>
                    ))}
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Grid>
          </>
          : null
      } */}

      {
         newSummary && showSLDropDown ? (
          <>
            <span className="ns_common_filters_typo">Service Line</span>
            <Grid className="vesselDropdown globalFilterMarginRight" sx={{ marginRight: "15px !important" }}>
              <PopupState variant="popover" popupId="vessels-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Tooltip title={<span className="span_NewSummary">{selectedVesselsSLName}</span>}>
                      <Button
                        style={{ height: newSummary ? "32px" : "26px" }}
                        id={!disableSite ? 'enable-cursor' : 'disable-cursor'}
                        className={
                          popupState.isOpen
                            ? "vesselDropdownInput vesselDropdown-button on_dropdown_open"
                            : "vesselDropdownInput vesselDropdown-button"
                        }
                        variant="contained"
                        {...!disableSite && { ...bindTrigger(popupState) }}
                      >
                        <span className="textWrap">{selectedVesselsSLName}</span>
                        <img
                          alt="dropdown"
                          className={`dropdownArrow margin-left-auto padding-r-15 ${
                            popupState.isOpen ? 'keyDownIcon_NewSummary' : ''
                          }`}
                          src={CommonFilterDownIcon}
                        />
                      </Button>
                    </Tooltip>
                    <Menu
                      {...bindMenu(popupState)}
                      id="menu-vessels"
                      aria-labelledby="menu-vessels"
                      onClose={() => {
                        setSearchServiceLines("");
                        popupState.close();
                      }}
                      key="menu-vessels"
                      className="menuScroll"
                    >
                      {/* Search Field */}
                      <MenuItem key={'vessel_text'} className="vessel_search_item" sx={{ p: 0.8 }}>
                        <TextField
                          id="searchtext"
                          variant="outlined"
                          placeholder="Type something"
                          autoComplete="off"
                          size="small"
                          value={searchServiceLines}
                          onChange={(event) => handleServiceLinesSearch(event.target.value)}
                          // sx={{ width: '100%' }}
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '&::placeholder': {
                                fontSize: '12px'
                              },
                              backgroundColor: 'white', // Override background on focus
                              '&:hover': {
                                backgroundColor: 'white',
                              },
                              '&.Mui-focused': {
                                backgroundColor: 'white',
                                boxShadow: 'none', // Remove default box shadow if any
                              },
                              '& .MuiInputBase-input::placeholder': {
                                fontSize: '12px', // Adjust the placeholder size
                              },
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon className="cursorPointer searchIcon_NewSummary" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MenuItem>
    
                      {/* Virtualized List */}
                      <FixedSizeList
                        height={225} // Adjust based on dropdown height
                        itemCount={accessibleServiceLines.length}
                        itemSize={25} // Adjust based on item height
                        width="100%"
                      >
                        {({ index, style }) => {
                          const sl = accessibleServiceLines[index];
                          return (
                            <div style={style} key={sl.name}>
                              <Tooltip placement="left" title={<span className="span_NewSummary">{sl.name}</span>} arrow>
                                <MenuItem
                                  className="vessel_name_item"
                                  onClick={(e) => handleChangeServiceLine(e, sl)}
                                >
                                  <ListItemText
                                    className="vessel_name"
                                    primary={<span className="textWrapInnerSL">{sl.name}</span>}
                                  />
                                  {selectedServiceLineNames.includes(sl.name) ? (
                                    <CheckIcon className="checkedIcon_NewSummary" />
                                  ) : (
                                    <CheckBoxOutlineBlankIcon className="unCheckedIcon_NewSummary" />
                                  )}
                                </MenuItem>
                              </Tooltip>
                            </div>
                          );
                        }}
                      </FixedSizeList>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </Grid>
          </>
        ) : null
      }
       {
        newSummary && (showWantType) ?
        <>
        <span className="ns_common_filters_typo">WAN</span>
          <Grid className="new-summary-vesselDropdown globalFilterMarginRight" sx={{ marginRight: "15px !important" }}>
            <PopupState variant="popover" popupId="vessels-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Tooltip title={<span  className="span_NewSummary">{newSummaryWanType?.label}</span>}>
                  <Button className={popupState.isOpen ? "vesselDropdownInput vesselDropdown-button on_dropdown_open" : "vesselDropdownInput vesselDropdown-button"} variant="contained" {...bindTrigger(popupState)}>
                  <span className="textWrap">{newSummaryWanType?.label}</span> 
                      {
                      popupState.isOpen ?
                       <img alt="dropdown" className="dropdownArrow keyDownIcon_NewSummary margin-left-auto wan-keyDownIcon_NewSummary" src={CommonFilterDownIcon} />
                       :
                       <img alt="dropdown" className="dropdownArrow margin-left-auto" src={CommonFilterDownIcon} />
                    }
                  </Button>
                  </Tooltip>
                  <Menu {...bindMenu(popupState)}
                    id="menu-vessels-new-summary"
                    aria-labelledby="menu-vessels-new-summary"
                    // onClose={() => handleClose(popupState)}
                    key="menu-vessels-new-summary"
                    className="menuScroll"
                    >
                 
                      {WAN_ARRAY.filter(
                        (wan) => wan.label !== "BOND" || window.location.pathname.includes("activity")
                      ).map((wan, i) => (
                      <Tooltip placement="left" title={<span  className="span_NewSummary">{wan?.label}</span>} arrow>
                        <div>
                          <MenuItem key={'wanUsage' + i}  style={{backgroundColor: newSummaryWanType?.label === wan?.label ? "#F8F8FF" : "#ffffff"}} className="vessel_name_item" onClick={(e) => handleChangeWan(e, wan, popupState)}>
                            <ListItemText className="vessel_name" primary={<span className="textWrapInner">{wan?.label}</span>} />
                          
                          </MenuItem>
                        </div>
                      </Tooltip>
                    ))}
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Grid>
          </>
          : null
      }
       {showSeverity ?
        <>
        <span className="ns_common_filters_typo">SEVERITY</span>
          <Grid className="new-summary-vesselDropdown" sx={{ marginRight: "15px !important" }}>
            <PopupState variant="popover" popupId="vessels-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Tooltip title={<span  className="span_NewSummary">{newSummarySeverityType?.label}</span>}>
                  <Button className={popupState.isOpen ? "vesselDropdownInput vesselDropdown-button on_dropdown_open" : "vesselDropdownInput vesselDropdown-button"} variant="contained" {...bindTrigger(popupState)}>
                  <span className="textWrap">{newSummarySeverityType?.label}</span> 
                      {
                      popupState.isOpen ?
                       <img alt="dropdown" className="dropdownArrow keyDownIcon_NewSummary margin-left-auto wan-keyDownIcon_NewSummary" src={CommonFilterDownIcon} />
                       :
                       <img alt="dropdown" className="dropdownArrow margin-left-auto" src={CommonFilterDownIcon} />
                    }
                  </Button>
                  </Tooltip>
                  <Menu {...bindMenu(popupState)}
                    id="menu-vessels-new-summary"
                    aria-labelledby="menu-vessels-new-summary"
                    // onClose={() => handleClose(popupState)}
                    key="menu-vessels-new-summary"
                    className="menuScroll"
                    >
                 
                    {THREAT_SEVERITY_ARRAY.map((severity, i) => (
                      <Tooltip placement="left" title={<span  className="span_NewSummary">{severity?.label}</span>} arrow>
                        <div>
                          <MenuItem key={'wanUsage' + i}  style={{backgroundColor: newSummarySeverityType?.label === severity?.label ? "#F8F8FF" : "#ffffff"}} className="vessel_name_item" onClick={(e) => handleChangeSeverity(e, severity, popupState)}>
                            <ListItemText className="vessel_name" primary={<span className="textWrapInner">{severity?.label}</span>} />
                          
                          </MenuItem>
                        </div>
                      </Tooltip>
                    ))}
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Grid>
          </>
          : null
      }
       {
        newSummary && showSummaryPeriod  ?
        <div className="periodSelection">
        {/* <span className="ns_common_filters_typo periodInnerContainer">Period</span> */}
          <Grid className="new-summary-vesselDropdown globalFilterMarginRight newPeriod-globalFilterMarginRight">
            <PopupState variant="popover" popupId="vessels-popup-menu">
              {(popupState) => (
                <React.Fragment>
                    <div className="new-period-div-container">
                      <span>
                        <Typography className="newSummaryTimePeriod">{!_.isEmpty(newSummaryStartDate) && !_.isEmpty(timezone) ? moment(newSummaryStartDate).tz(getTimezoneCity(authReducer?.userTimezone))?.format(MMDDYYYYHMM_DATE_FORMAT_24_HRS) : null}</Typography>
                        <Typography className="newSummaryTimePeriod">{!_.isEmpty(newSummaryEndDate) && !_.isEmpty(timezone) ? moment(newSummaryEndDate).tz(getTimezoneCity(authReducer?.userTimezone))?.format(MMDDYYYYHMM_DATE_FORMAT_24_HRS) : null}</Typography>
                      </span>
                      <Button className={popupState.isOpen ? "vesselDropdownInput vesselDropdown-button on_dropdown_open" : "vesselDropdownInput vesselDropdown-button"} variant="contained" {...bindTrigger(popupState)}>
                        <span className="textWrap textWrap-new-period">{selectedPeriod && selectedPeriod != 'customDates' ? selectedPeriod : "Custom"}
                          {
                            popupState.isOpen ?
                              <img alt="dropdown" className="dropdownArrow keyDownIcon_NewSummary margin-left-auto period-keyDownIcon_NewSummary" src={CommonFilterDownIcon} />
                              :
                              <img alt="dropdown" className="dropdownArrow margin-left-auto" src={CommonFilterDownIcon} />
                          }
                        </span>
                      </Button>
                    </div>
               <ClickAwayListener onClickAway={handleClickAway}>
                  <Menu {...bindMenu(popupState)}
                    id="menu-vessels-new-summary-period"
                    // aria-labelledby="menu-vessels-new-summary"
                    // onClose={() => handleClose(popupState)}
                    key="mmenu-vessels-new-summary-period"
                    className={isCustomPeriodSelectionRequired == false ?"menuScroll min-width":"menuScroll"}
                    >
                   
                    {(periodOptions && periodOptions.length > 0 ? showLastThreeMonths ? INTERVAL_LAST_THREE_MONTHS.filter(item => periodOptions.includes(item.value)) : showPlans ?  NEWINTERVALS_WITH_PLANS.filter(item => periodOptions.includes(item.value)) :  NEWINTERVALS.filter(item => periodOptions.includes(item.value)) : NEWINTERVALS)?.map((period, i) => (
                      <Tooltip placement="left" title={<span  className="span_NewSummary">{period?.label?.split(" ")[2] ? period?.label?.split(" ")[1] + " " + period?.label?.split(" ")[2] : period?.label}</span>} arrow>
                        <div>
                          <MenuItem key={'periodSelection' + i} className="vessel_name_item" style={{backgroundColor: "Last "+selectedPeriod === period?.label ? "#F8F8FF" : "#ffffff"}} onClick={(e) => handleChangePeriod(e, period, popupState)}>
                            <ListItemText className="vessel_name" primary={<span className="textWrapInner">{period?.label}</span>} />
                        
                          </MenuItem>
                        </div>
                      </Tooltip>
                    ))}
                    {
                      isCustomPeriodSelectionRequired == false
                        ? null
                        : [
                            <div className="custom-date-selection-div">
                              <MenuItem key={'periodSelectionCustom'} className="vessel_name_item" style={_q.hasOwnProperty('interval') && _q.interval == 'customDates' ? {backgroundColor: "#F8F8FF", display: "flex", flexDirection: "column", alignItems: "flex-start"} : { backgroundColor: "#FFF", display: "flex", flexDirection: "column", alignItems: "flex-start" }} >
                                <ListItemText className="vessel_name" primary={<span className="textWrapInner">{"From"}</span>} />
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DateTimePicker
                                    // label="Select Date & Time"
                                    ampm={false}
                                    timezone={timezone}
                                    value={customStartDate}
                                    onChange={(newValue) => {
                                      // dispatch({ type: UPDATE_NEW_SUMMARY_START_DATE, payload:newValue})
                                      if (newValue !== null) {
                                        setCustomStartDate(newValue)
                                      }
                                    }}
                                  viewRenderers={showDateOnly ? undefined :{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                  }}
                                  maxDateTime={customEndDate}
                                  className="audit-filter-inputs"
                                  views={showDateOnly ? ["year", "month", "day"] : ["year", "month", "day", "hours", "minutes"]}
                                />
                          </LocalizationProvider>
                          </MenuItem>
                        </div>,
                        <div className="custom-date-selection-div">
                        <MenuItem key={'periodSelectionCustom'} className="vessel_name_item" style={_q.hasOwnProperty('interval') && _q.interval == 'customDates' ? {backgroundColor: "#F8F8FF", display: "flex", flexDirection: "column", alignItems: "flex-start"} : { backgroundColor: "#FFF", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <ListItemText className="vessel_name" primary={<span className="textWrapInner">{"To"}</span>} />
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                             <DateTimePicker
                                  ampm={false}
                                  timezone={timezone}
                                  value={customEndDate}
                                  onChange={(newValue)=>{    
                                      // dispatch({ type: UPDATE_NEW_SUMMARY_END_DATE, payload:newValue})
                                      if (newValue !== null) {
                                        setCustomEndDate(newValue)
                                      }
                                    }}
                                  viewRenderers={showDateOnly ? undefined :{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                  }}
                                  minDateTime={customStartDate}
                                  maxDateTime={moment().utc()}
                                  className="audit-filter-inputs"
                                  views={showDateOnly ? ["year", "month", "day"] : ["year", "month", "day", "hours", "minutes"]}
                                />
                          </LocalizationProvider>
                          </MenuItem>
                        </div>,
                        <div className="applyButtonWrapper_ns"> 
                          <Button className="applyButtonCustomDate_ns" onClick={()=>{handleCustomDate(popupState)}}>Apply</Button>
                          <Button className="cancelButtonCustomDate_ns" onClick={()=>{if(popupState){   
                             popupState.close()
                             setCustomStartDate(newSummaryStartDate);
                             setCustomEndDate(newSummaryEndDate);
                             }}}>Cancel</Button>
                        </div>
                        ]
                    }
                  </Menu>
                  </ClickAwayListener>
                </React.Fragment>
              )}
            </PopupState>
          </Grid>
          </div>
          : null
      }
     
      {
        loading ? (
          <div className="loader_NewSummary">
            <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} />
          </div>
        ) : <></>
      }
    </Grid >
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
  newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
  newSummaryWanType:state?.authReducer?.newSummaryWanType,
  newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
  newSummarySeverityType: state?.authReducer?.newSummarySeverityType,
  newSummaryServiceAccount: state?.authReducer?.newSummaryServiceAccount,
  selectedServiceAccount: state?.authReducer?.selectedServiceAccount,
  dpIds: state.starlinkCloud.dpIds,
  getAllServiceLinesSubscription: state.starlinkDashboard.getAllServiceLinesSubscription
});

export default withRouter(
  connect(mapStateToProps, {
    getOrganisationList,
    getOrganisationUnitList,
    getVesselsListing,
    updateSelectedOu,
    updateSelectedVessel,
    updateSelectedVessels,
    updatFilteredVessels,
    updateUserOuHasSubOus,
    updateIsReadOnlyUser,
    fetchServiceLines,
    getAllServiceLineSubscriptionAPI,
    getServiceAccounts
  })(SubHeaderSummary)
);
