// Dialog Box for creating / updating Note -  Pop-up
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    Grid, MenuItem, Menu, ListItemText, Button,
    TextField, InputAdornment
  } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Tooltip from '@mui/material/Tooltip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/CheckBoxRounded';
import SearchIcon from "@mui/icons-material/Search";
import { getDPList } from "../../actions/Users/authenticateInventory";
import "./inventoryWarehouse.css"
import _ from "lodash";
import { getOu, getOuforReports, getUserOu, populateForParent } from "../../utils/util";
var Loader = require("react-loader");


const DP_Dropdown = (props) => {
  const {  authReducer, getDPList, isSmall=false, setOu, className, deviceOu, isReports } = props;

  const [organisations, setOrganisations] = useState<any[]>([]);
  const [selectedOu, setSelectedOu] = useState(_.isEmpty(deviceOu)?authReducer.selectedOu:deviceOu);
  const [loading, setLoading] = useState<Boolean>(false);
  const [showOuList, setShowOuList] = useState(true);
  const [ouSearchValue, setOuSearchValue] = useState("");
  const [isOuClicked, setIsOuClicked] = useState<Boolean>(false);
 
  useEffect(() => {
    setOu(selectedOu)
  }, [selectedOu])

useEffect(() => {
  if (authReducer.selectedOu && authReducer.selectedOu?.name) {
    setOrganisations([authReducer?.selectedOu]);
  }
  getDPList();
}, []);


const handleOuClose = (popupState: any = null) => {
  if (popupState) {
    popupState.close();
  }
}

const handleOuSearch = async (value) => {
    setOuSearchValue(value);
    setLoading(true);
    getDPList(value);
  }


const searchOu = (tree, ouName) => {
  if (tree && ouName) {
    for (let i = 0; i < tree.length; i++) {
      if (tree[i].name === ouName) {
        return tree[i];
      }
      if (tree[i].parent_of) {
        let ou = searchOu(tree[i].parent_of, ouName);
        if (ou) return ou;
      }
    }
  }
  return null;
}

useEffect(() => {
    if (loading) {
        setLoading(false);
    }
    if (authReducer.getDPList && authReducer.getDPList.dps) {
        let _organisations: any[] = [];
        if (authReducer.getDPList.dps && authReducer.getDPList.dps.length > 0 ) {
          authReducer.getDPList.dps.map((item) => {
            _organisations = populateForParent(item, null, _organisations);
          })
        }
        setOrganisations(_organisations);
        setSelectedOu(isReports ? getOuforReports(_organisations,selectedOu,authReducer.getDPList) :getOu(_organisations,selectedOu,authReducer.getDPList))
        authReducer.getDPList = {};
    }
}, [authReducer.getDPList]);

const handleOuClick = (event, ou, popupState) => { 
    if (popupState) {
      popupState.close();
    }
    setIsOuClicked(true);
    setSelectedOu(ou)
    if(ouSearchValue){
      setOuSearchValue("");
      getDPList();
    }
  };

const getOuTree = (popupState: any, ous: any[], prefix: string = "", isInvitedOusValidationRequired =true): any[] => {
    let list: any[] = [];
    if (ous && Array.isArray(ous)) {
      let userOu = getUserOu()
      for (let i = 0; i < ous.length; i++) {
        const item = ous[i];
        // if(isInvitedOusValidationRequired && !_.isEmpty(userOu) && !_.isEmpty(item) && userOu.hasOwnProperty("id") && item.hasOwnProperty("id")  && userOu["id"] !==item.id){
        //   continue;
        // }
        const _childern = getOuTree(popupState, item._childern, "ou_" + i,false);
        list.push(
          <div key={prefix + i + 'div'} style={{fontSize:'14px'}}>
              <MenuItem key={prefix + i} className="OuMenuChild" 
              onClick={(e) => handleOuClick(e, item, popupState)}
              >
                {
                  0 == _childern.length ?
                    <Tooltip title={item.name}>
                      <ListItemText primary={"- " + item.name} className="DPchildHierarch textWrapInner" />
                    </Tooltip>
                  : <Tooltip title={item.name}>
                    <ListItemText primary={"+ " + item.name} className="DPchildHierarch textWrapInner" /> 
                  </Tooltip>
                }
                {!_.isEmpty(selectedOu) && selectedOu.hasOwnProperty("name") && selectedOu["name"]  == item.name ? <CheckIcon style={{ color: "#ffffff", background: "#2860B4", fontSize: "12px" }} /> : ''}
              </MenuItem>
            <div key={prefix + i + 'childern'} className="childern" >
              {_childern}
            </div>
          </div>
        );
      }
    }
    return list
  }

return (
    <div className="Form">
        <Grid>
        {
            showOuList ?
            <Grid className="DPvesselDropdown" sx={{ marginRight: "15px !important" }}>
                <PopupState variant="popover" popupId="ou-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                    <Button className={`DPVesselName ${className}`} variant="contained" {...bindTrigger(popupState)}   disabled={isReports ? true : false}>
                        <span className="DPtextWrap" title={!_.isEmpty(selectedOu) && selectedOu.hasOwnProperty("name")? selectedOu["name"] :""}>{!_.isEmpty(selectedOu) && selectedOu.hasOwnProperty("name")? selectedOu["name"] :""}</span>
                {!isReports && <>{
                        popupState.isOpen ?
                            <ArrowDropDownIcon key="ouArrow" className="DPdropdownArrow margin-left-auto" style={{ cursor: "pointer", transform: "rotate(-180deg)" }} />
                            :
                            <ArrowDropDownIcon key="ouArrow" className="DPdropdownArrow margin-left-auto" />
                        }</>}
                    </Button>
                    <Menu 
                    {...bindMenu(popupState)}
                        id={isSmall ? "DPmenuSmall-ou" : className ? "dpDropdownMenu-ou" : "DPmenu-ou"}
                        aria-labelledby="menu-ou"
                        onClose={() => handleOuClose(popupState)}
                        key="menu-ou"
                        className="childHierarchy"
                    >
                      <MenuItem key={'ou_text'} className="vessel_search_item vessel_search_item" onKeyDown={(e) => e.stopPropagation()}>
                        <TextField
                          id="ousearchtext"
                          variant="outlined"
                          placeholder="Type something"
                          autoComplete="off"
                          size="small"
                          value={ouSearchValue}
                          onChange={(event) => {
                            handleOuSearch(event.target.value);
                          }}
                          InputLabelProps={{ className: "serachLabel" }}
                          InputProps={{
                            className: "serachBar",
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon style={{ position: "absolute", right: "12px", fontSize: "12px" }} className="cursorPointer" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MenuItem>
                        <span>
                        {getOuTree(popupState, organisations)}</span>
                    </Menu>

                    </React.Fragment>
                )}
                </PopupState>
            </Grid>
            : <></>
        }
        {/* {
        loading ? (
          <div style={{ position: "absolute", top: "63px", right: "36px" }}>
            <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} />
          </div>
          ) : <></>
        } */}
        </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});
export default withRouter(
    connect(mapStateToProps, {
     getDPList
    })(DP_Dropdown)
);