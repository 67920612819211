// TicketTable for Ticket Page
import React, { useEffect, useRef, useState } from "react";
import {
  Grid, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  MenuItem, Menu, OutlinedInput, InputAdornment, Autocomplete, Paper, TextField, Stack, Tooltip, Box
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { getTickets, getTicketsByFilter, updateTicket, getTicketAllAgents, addAttachments } from "../../actions/Users/authenticateTicket";
import { autoCompleteRenderTags, convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI, strToK4List } from "../../utils/util";
import { DEFAULT_TIMEZONE, MMDDYYYYHMMSS_DATE_FORMAT, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS, NO_TIMEFRAME } from "../../utils/constants";
import { CONFIRM_DIALOG_TITLE, KAPTUREV2_URL, MARK_AS_CLOSED_CONFIRM_DIALOG_SUBTITLE } from '../../config';
// import { selectedVesselsObserver } from "../../redux/K4Observer";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { getServiceFunction } from "../../utils/util";
import { PER_PAGE_ITEMS, DEFAULT_PAGE_SIZE, NEW_PER_PAGE_ITEMS } from "../../constants/Constants";
import filter from "../../asset/image/filter.svg";
import FilterTicketDialog from "../FilterTicketDialog";
import TicketHotVessel from "../HotVessels"
import CreateNewTicketDialog from "../CreateNewTicketDialog";
import TicketDetailsDialog from "../TicketDetailsDialog";
import DisardIcon from "../../asset/image/DiscrdFilter.svg";
import TicketView from "../TicketView"
import moment from "moment-timezone";
import _ from "lodash";
import ConfirmDialog from "../../components/ConfirmDialog";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import uncheckedbox from "../../asset/image/uncheckedbox.svg";
import checkedbox from "../../asset/image/checkedbox.svg";
import ascSorting from "../../asset/image/ascSorting.svg";
import descSorting from "../../asset/image/descSorting.svg";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";
import './TicketTable.css';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom"
import CustomLoader from "../Loader/CustomLoader";
import { VIEW_LAST_WEEK, VIEW_LAST_MONTH, CUSTOM_DATES } from "../../utils/constants";
import { Pagination } from "../Pagination";
import TuneIcon from '@mui/icons-material/Tune';
import Download from '../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../actions/types";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";

const convertToCsv = (data: any[], userTimezone: string) => {
  const csvData = data.map(item => {
    return [item.ticketId, item.vessel, item.ticketSubject, convertDateTimeIntoTimezone(item.createdOn, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), item.fullName, item.assignee, item.priority, convertDateTimeIntoTimezone(item.updatedOn, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), item.ticketStatus]
  })
  let createdOn = 'Created On (' + userTimezone + ')'
  let updatedOn = 'Updated On (' + userTimezone + ')'
  const headers: string[] = ['Ticket ID', 'Site', 'Subject', createdOn, 'Creator', 'Assignee', 'Priority', updatedOn, 'Status'];
  return [headers, ...csvData];
}

interface FilterSelection {
  isTicketOpen: boolean,
  isTicketClosed: boolean,
  isTicketOnHold: boolean,
  isTicketReopened: boolean,
  isTicketEscalated: boolean,
  isTicketResolved: boolean,
  isUnassigned: boolean,
  isRaised24h: boolean,
  isHotVessel: boolean,
  isCritical: boolean,
  isStandUp: boolean,
  isMyTickets: boolean,
  isTicketRMA: boolean,
  assignee: any[],
  creater: any[],
  selectedTimeframe: string,
  dateRangeValue: string
}

interface TicketUnhandledFilter {
  _ouName?: string;
  _ouId?: string;
  _k4idList?: string[];
  _page?: number;
}

const decodeArrayValue = (value: any) => {
  if(typeof value === 'string') {
    return value.trim().split(',');
  }
  return value instanceof Array? value : [];
}

const getMoreFilter = (_q: any) => {
  let isTicketOpen = false;
  const selectedAssignees = (_q.assignedTo && '' !== _q.assignedTo.trim()) ? JSON.parse(_q.assignedTo) : [];
  const selectedCreators = (_q.createdBy && '' !== _q.createdBy.trim()) ? JSON.parse(_q.createdBy) : [];
  const status = decodeArrayValue(_q.status);
  if (status.length > 0 && status.includes("Open")) {
    isTicketOpen = true;
  }
  let selectedTimeframe = "";
  let startDate : any = "";
  let endDate = "";
  let dateRangeValue = "";
  if (!_.isEmpty(_q.selectedTimeframe)) {
    selectedTimeframe = _q.selectedTimeframe;

    if (selectedTimeframe === VIEW_LAST_WEEK) {
      startDate = Date.now() - 7 * 24 * 3600 * 1000;
    } else if (selectedTimeframe === VIEW_LAST_MONTH) {
      startDate = Date.now() - 30 * 24 * 3600 * 1000;
    } else if (selectedTimeframe === CUSTOM_DATES) {
      if (!_.isEmpty(_q.dateRangeValue) && _q.dateRangeValue.includes("-")) {
        dateRangeValue = _q.dateRangeValue;
        startDate = _q.dateRangeValue.split("-")[0].trim();
        endDate = _q.dateRangeValue.split("-")[1].trim();
      }
    }

    // CUSTOM_DATES
    if (selectedTimeframe === CUSTOM_DATES && dateRangeValue.includes("-")) {
      startDate = moment.utc(startDate).startOf('day').toISOString();
      endDate = moment.utc(endDate).endOf("day").toISOString();
    } else if (selectedTimeframe !== NO_TIMEFRAME && selectedTimeframe !== CUSTOM_DATES) {
      // for other timeframes except CUSTOM_DATES & NO_TIMEFRAME
      startDate = moment(startDate).utc().startOf('day').toISOString();
      endDate = moment(Date.now()).utc().endOf("day").toISOString();
    }
  }

  let filters = {
    isTicketOpen: isTicketOpen,
    isTicketClosed: false,
    isTicketOnHold: false,
    isTicketReopened: false,
    isTicketEscalated: false,
    isTicketResolved: false,
    isUnassigned: false,
    isRaised24h: false,
    isHotVessel: false,
    isCritical: false,
    isStandUp: false,
    isMyTickets: false,
    isTicketRMA: false,
    assignee: selectedAssignees,
    creater: selectedCreators,
    selectedTimeframe: selectedTimeframe,
    eventStartDate: startDate,
    eventEndDate: endDate,
    dateRangeValue: dateRangeValue
  }

  if (status.length > 0) {
    let urlStatus = status;

    if (urlStatus.includes("Open")) {
      filters.isTicketOpen = true
    }
    if (urlStatus.includes("Closed")) {
      filters.isTicketClosed = true
    }
    if (urlStatus.includes("onHold")) {
      filters.isTicketOnHold = true
    }
    if (urlStatus.includes("Reopened")) {
      filters.isTicketReopened = true
    }
    if (urlStatus.includes("Escalated")) {
      filters.isTicketEscalated = true
    }
    if (urlStatus.includes("Resolved")) {
      filters.isTicketResolved = true
    }

  }

  if (_q.filter && '' != _q.filter.trim()) {
    let urlFilter = _q.filter.split(',')

    if (urlFilter.includes("Unassigned")) {
      filters.isUnassigned = true
    }
    if (urlFilter.includes("Raised24h")) {
      filters.isRaised24h = true
    }
    if (urlFilter.includes("HotVessel")) {
      filters.isHotVessel = true
    }
    if (urlFilter.includes("Critical")) {
      filters.isCritical = true
    }
    if (urlFilter.includes("StandUp")) {
      filters.isStandUp = true
    }
    if (urlFilter.includes("MyTickets")) {
      filters.isMyTickets = true
    }
    if (urlFilter.includes("RMA")) {
      filters.isTicketRMA = true
    }
  }
  return filters;
}

const TicketTable: any = (props) => {
  let history = useHistory();
  const loadingTextOnUI = "Fetching data please wait...";
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: loadingTextOnUI });
  const { filteredVessels, getTicketsByFilter, getTicketAllAgents, updateTicket, addAttachments, authReducer, location, downloadAsCSV, newSummaryStartDate, newSummaryEndDate } = props;

  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '', onConfirm: {} });

  function createData(k4Id, ticketId, vessel, ticketSubject, description, createdOn, eventDate, creator, assignee, status, updatedOn, priority, parentDP, issueType, systemType, classification, component, channel, customerReported, provider, assignedTo, fullName, ticketDesc) {
    return { k4Id, ticketId, vessel, ticketSubject, description, createdOn, eventDate, creator, assignee, status, updatedOn, priority, parentDP, issueType, systemType, classification, component, channel, customerReported, provider, assignedTo, fullName, ticketDesc };
  }

  let _q = getDecodeURI(location?.search);
  let [selectedVesselK4Id, setSelectedVesselK4Id] = useState<string>();
  let [k4idList,setK4idList]: any = useState(() => _q.hasOwnProperty("k4Ids") && _q.k4Ids.trim() != "" ? strToK4List(_q.k4Ids).map(vessel => vessel.id): []);

  let [searchBoxValue, setSearchBoxValue]: any = useState(_q.search ? _q.search : '');
  let [searchText, setSearchText]: any = useState(_q.search ? _q.search : '');
  let [moreFiltersSelection, setMoreFiltersSelection] = useState<Partial<FilterSelection>>(() => getMoreFilter(_q));
  const MORE_FILTERS_TEXT = "Filters";
  const MORE_FILTERS_TEXT_1 = "Filters";
  let [moreFiltersButtonText, setMoreFiltersButtonText] = useState<string>(MORE_FILTERS_TEXT);
  let [isMoreFiltersActive, setIsMoreFiltersActive] = useState<boolean>();

  let [loggedInUserDetails, setLoggedInUserDetails] = useState<{}>();
  let [unAssignedUserDetails, setUnAssignedUserDetails] = useState<{}>();
  let [allAgentList, setAllAgentList]: any = useState([]);

  let [creatorMenuAnchorEl, setCreatorMenuAnchorEl]: any = React.useState(null);
  let openCreatorMenu = Boolean(creatorMenuAnchorEl);
  let [creatorLoggedInUserSelected, setCreatorLoggedInUserSelected] = useState<boolean>(false);

  let [assigneeMenuAnchorEl, setAssigneeMenuAnchorEl]: any = React.useState(null);
  let openAssigneeMenu = Boolean(assigneeMenuAnchorEl);
  let [assigneeSelectedUsers, setAssigneeSelectedUsers]: any = useState(() => (_q.assignedTo && '' !== _q.assignedTo.trim()) ? JSON.parse(_q.assignedTo) : []);
  let [creatorSelectedUsers, setCreatorSelectedUsers]: any = useState(() => (_q.createdBy && '' !== _q.createdBy.trim()) ? JSON.parse(_q.createdBy) : []);

  let [assigneeLoggedInUserSelected, setAssigneeLoggedInUserSelected] = useState<boolean>(false);
  let [assigneeUnAssignedSelected, setAssigneeUnAssignedSelected] = useState<boolean>(false);
  let [hotVesselDialog, setHotVesselDialog] = useState<boolean>(false);
  const unAssigned = "unassigned";
  const notAssigned = "Not assigned";

  let [moreFiltersShowPopUP, setMoreFiltersShowPopUP]: any = useState(false);
  let [createNewAccountShowPopUP, setCreateNewAccountShowPopUP]: any = useState(false);
  let [ticketDetailsShowPopUP, setTicketDetailsShowPopUP]: any = useState(false);
  let [ticketHistoryShowPopUP, setTicketHistoryShowPopUP]: any = useState(_q.hasOwnProperty('ticketId') ? true : false);

  let [data, setData]: any = useState([]);
  // table header count
  const NO_DATA_AVAILABLE_COL_SPAN = 11;
  // let [showingTickets, setShowingTickets]: any = useState("0");
  // let [ticketCount, setTicketCount]: any = useState(0);
  let [page, setPage]: any = React.useState(() => (_q.page && '' !== _q.page.trim()) ? parseInt(_q.page) : 0);
  // let [rowsPerPage, setRowsPerPage]: any = React.useState(10);
  // let [lastPage, setLastPage]: any = useState(0);
  // if text has more characters than textMinLength, then show showMoreText
  const textMinLength: any = 45;
  let [detailsPageClickedTicketInfo, setDetailsPageClickedTicketInfo]: any = useState();
  let [historyPageClickedTicketInfo, setHistoryPageClickedTickedInfo]: any = useState();
  let [isSelectedVesselK4IdAvailable, setIsSelectedVesselK4IdAvailable]: any = useState(false);
  let [newTicketAttachmentsFile, setNewTicketAttachmentsFile]: any = useState([]);
  let [total, setTotal] = useState<number>(0);
  let [pages, setPages] = useState<number>(0);
  let [limit, setLimit] = useState<number>(() => (_q.limit && '' !== _q.limit.trim()) ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
  let [fromItem, setFromItem] = useState<number>(0);
  let [toItem, setToItem] = useState<number>(0);

  // prev or next page icon click - identitify the active filter i.e vessel-list OR other_filters
  let [activeFilter, setActiveFilter] = useState<String>();
  let [selectedOu, setSelectedOu] = useState(() => _q.ouId && '' != _q.ouId.trim() ? _q.ouId : 'DP-0001');
  const VESSEL_FILTER = "vessel_filter";
  const OTHER_FILTERS = "other_filters"

  // API payload/response JSON keys
  const K4ID_KEY = "k4Id";
  const VESSEL_NAME = "vessel"
  const PARENT_ORG = 'parentDP';
  const TICKET_ID_KEY = "ticketId";
  const TICKET_SUBJECT_KEY = "ticketSubject";
  const TICKET_DESCRIPTION_KEY = "ticketDesc";
  const EVENT_DATE_KEY = "eventDate";
  const FULL_NAME_KEY = "fullName";
  const ASSIGNEE_KEY = "assignee";
  const CREATOR_KEY = "creator"
  const UPDATEDON_KEY = "updatedOn";
  const PRIORITY_KEY = "priority";
  const TICKET_STATUS_KEY = "ticketStatus";
  const PAGE_SIZE_KEY = "pageSize";
  const PAGE_NUMBER_KEY = "pageNumber";
  const OUNAME_KEY = "ouName";
  const DPID_KEY = "dpId";
  const SEARCH_KEY = "searchKey";
  const EVENT_START_DATE_KEY = "eventStartDate";
  const EVENT_END_DATE_KEY = "eventEndDate";
  const RAISED_BY_KEY = "raisedBy";
  const SORT_KEY = "sortKey";
  const SORT_ORDER_KEY = "sortOrder";
  const K4_EMP_ID_KEY = "k4EmpId";
  const ASSIGNED_TO_KEY = "assignedTo";
  const CREATED_BY_KEY = "createdBy";
  const CREATED_ON_KEY = "createdOn";
  const FILE_KEY = "file"

  const SEARCH_TEXT = "searchText";
  const CUSTOM_SEARCH = "customSearch";
  const TICKET_OPEN = "Open";
  const TICKET_CLOSED = "Closed";
  const TICKET_ESCALATED = "Escalated";
  const TICKET_ONHOLD = "OnHold";
  const TICKET_REOPENED = "Reopened";
  const TICKET_RESOLVED = "Resolved";
  const TICKET_RMA = "classification";
  const ASCENDING_ORDER = "ASC";
  const DESCENDING_ORDER = "DESC";

  // Autocomplete
  const AUTO_COMPLETE_NO_OPTIONS_TEXT = "No options";
  const AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS = 1;
  let [creatorAutoCompleteSelectedvalues, setCreatorAutoCompleteSelectedvalues] = useState([]);
  let [assigneeAutoCompleteSelectedvalues, setAssigneeAutoCompleteSelectedvalues] = useState([]);
  const [enableNavigationsBtns, setEnableNavigationsBtns] = useState(false);

  // sorting of tickets
  let [sortKey, setSortKey] = useState(() => _q.sortKey && '' != _q.sortKey.trim() ? _q.sortKey: TICKET_ID_KEY);
  let [sortOrder, setSortOrder] = useState(() => _q.sortOrder && '' != _q.sortOrder.trim() ? _q.sortOrder : DESCENDING_ORDER);
  let [queryOuName, setOuName] = useState("")
  // attachments
  const NAME = "name";
  const DEFAULT_IMAGE_FILE_NAME = "image.png";

  // Keyboard keycodes
  const ENTER_KEY_CODE = "Enter";

  const searchTimeoutRef = useRef<NodeJS.Timeout>();
  const dispatch = useDispatch();


  const servicePortal = SERVICE_FUNCTION_TO_FEATURE && SERVICE_FUNCTION_TO_FEATURE["create-ticket"] && SERVICE_FUNCTION_TO_FEATURE["create-ticket"]["service"]
    ? SERVICE_FUNCTION_TO_FEATURE["create-ticket"]["service"]
    : (
      SERVICE_FUNCTION_TO_FEATURE && SERVICE_FUNCTION_TO_FEATURE["view-tickets"] && SERVICE_FUNCTION_TO_FEATURE["view-tickets"]["service"]
        ? SERVICE_FUNCTION_TO_FEATURE["view-tickets"]["service"]
        : ""
    );

  const createTicket = SERVICE_FUNCTION_TO_FEATURE && SERVICE_FUNCTION_TO_FEATURE["create-ticket"] && SERVICE_FUNCTION_TO_FEATURE["create-ticket"]["function"]
    ? SERVICE_FUNCTION_TO_FEATURE["create-ticket"]["function"]
    : "";
  const viewTickets = SERVICE_FUNCTION_TO_FEATURE && SERVICE_FUNCTION_TO_FEATURE["view-tickets"] && SERVICE_FUNCTION_TO_FEATURE["view-tickets"]["function"]
    ? SERVICE_FUNCTION_TO_FEATURE["view-tickets"]["function"]
    : "";

  const k4Id = "id";
  let [vesselNames, setVesselNames]: any[] = useState([]);
  //   let [isVesselNameAvailable, setIsVesselNameAvailable]: any = useState(false);
  let [selectedVessel, setSelectedVessel]: any[] = useState([]);
  //   let [selectedVesselDisplayName, setSelectedVesselDisplayName]: any[] = useState("");
  let [createTicketVesselList, setCreateTicketVesselList]: any[] = useState([]);

  // call only once, when page loads for the 1st time
  useEffect(() => {
    setAllAgentList([]);
    getTicketAllAgents();
    getLoggedInUserDetails();
    getUnAssignedDetails();
  }, []);

  // useEffect(() => {
  //   prepareGetTicketsByFilterPayload()
  // }, [limit, page, sortKey, sortOrder,k4idList,selectedOu,searchText,assigneeSelectedUsers, creatorSelectedUsers]);



  useEffect(() => {
    getCreateTicketResources();
  }, []);


  const doNavigate = (params) => {
    history.push({ pathname: "/tickets", search: `?${getEncodedURI(params)}` });
  }

  // construct createTicketVesselNames i.e Create ticket option available for which all vessels
  const getCreateTicketResources = () => {
    let createTicketFunction = getServiceFunction(servicePortal, createTicket);
    if (
      createTicketFunction &&
      createTicketFunction.resources &&
      Array.isArray(createTicketFunction.resources) &&
      createTicketFunction.resources.length > 0
    ) {
      let resource = createTicketFunction.resources;
      resource.map((item) => {
        createTicketVesselList.push(item);
      });
    }
  };

  useEffect(() => {
    if (!_.isEmpty(authReducer.getTickets) && authReducer.getTickets.data && authReducer.getTickets.data.tickets && authReducer.getTickets.data.totalCount) {
      // clear the data
      data.length = 0;
      authReducer.getTickets.data.tickets.forEach((row) => {
        let k4Id = row[K4ID_KEY] ? row[K4ID_KEY] : "";
        let ticketId = row[TICKET_ID_KEY] ? row[TICKET_ID_KEY] : "";
        let vessel = row[VESSEL_NAME] ? row[VESSEL_NAME] : "";
        let ticketSubject = row[TICKET_SUBJECT_KEY] ? row[TICKET_SUBJECT_KEY] : "";
        let description = row[TICKET_DESCRIPTION_KEY] ? row[TICKET_DESCRIPTION_KEY] : "";
        let createdOn = moment(row[CREATED_ON_KEY]).tz(DEFAULT_TIMEZONE).format(MMDDYYYYHMMSS_DATE_FORMAT);
        let eventDate = moment(row[EVENT_DATE_KEY]).tz(DEFAULT_TIMEZONE).format(MMDDYYYYHMMSS_DATE_FORMAT);
        let creator = row[FULL_NAME_KEY] ? row[FULL_NAME_KEY] : "";
        let assignee = row[ASSIGNEE_KEY] ? row[ASSIGNEE_KEY] : "";
        let ticketStatus = row[TICKET_STATUS_KEY] ? row[TICKET_STATUS_KEY] : "";
        let issueType = row['issueType'] ? row['issueType'] : '';
        let systemType = row['systemType'] ? row['systemType'] : '';
        let classification = row['classification'] ? row['classification'] : '';
        let component = row['component'] ? row['component'] : '';
        let channel = row['channel'] ? row['channel'] : '';
        let customerReported = row['customerReported'] ? row['customerReported'] : '';
        let provider = row['provider'] ? row['provider'] : '';
        let assignedTo = row['assignedTo'] ? row['assignedTo'] : '';
        let fullName = row[FULL_NAME_KEY] ? row[FULL_NAME_KEY] : "";
        let ticketDesc = row[TICKET_DESCRIPTION_KEY] ? row[TICKET_DESCRIPTION_KEY] : "";

        let updatedOn = row[UPDATEDON_KEY] && row[UPDATEDON_KEY] != null ? moment(row[UPDATEDON_KEY]).tz(DEFAULT_TIMEZONE).format(MMDDYYYYHMMSS_DATE_FORMAT) : "";
        let priority = row[PRIORITY_KEY] ? row[PRIORITY_KEY] : "";
        let parentDP = row[PARENT_ORG] ? row[PARENT_ORG] : '';

        setData(prevState => [...prevState, createData(k4Id, ticketId, vessel, ticketSubject, description, createdOn, eventDate, creator, assignee, ticketStatus, updatedOn, priority, parentDP, issueType, systemType, classification, component, channel, customerReported, provider, assignedTo, fullName, ticketDesc)]);
      });

      let pages = parseInt("" + (authReducer.getTickets.data.totalCount / limit));
      if ((authReducer.getTickets.data.totalCount % limit) > 0) {
        pages++;
      }
      setPages(pages);
      setTotal(authReducer.getTickets.data.totalCount);
      setFromItem((page * limit) + 1);
      setToItem((page * limit) + authReducer.getTickets.data.tickets.length);

      setIsSelectedVesselK4IdAvailable(true);
      authReducer.getTickets = {};
    }
    // not null,not empty
    if(!_.isEmpty(authReducer.getTickets) && authReducer.getTickets.data && authReducer.getTickets.data.tickets.length === 0){
      setData([])
      setPages(0);
      setTotal(authReducer.getTickets.data.totalCount);
      setFromItem(0);
      setToItem((page * limit) + authReducer.getTickets.data.tickets.length);
    }
      setIsSelectedVesselK4IdAvailable(true);
    
  }, [authReducer.getTickets]);

  // TO DO:
  const getLoggedInUserDetails = () => {
    let loggedInUser = {};
    // loggedInUser[K4_EMP_ID_KEY] = "KE-000";
    // loggedInUser[FULL_NAME_KEY] = "Praveen Biradar";
    // setLoggedInUserDetails(loggedInUser);
  };

  const getUnAssignedDetails = () => {
    let unAssignedUser = {};
    unAssignedUser[K4_EMP_ID_KEY] = unAssigned;
    unAssignedUser[FULL_NAME_KEY] = notAssigned;
    setUnAssignedUserDetails(unAssignedUser);
  };

  useEffect(() => {
    if (!_.isEmpty(authReducer.getTicketAllAgents) && authReducer.getTicketAllAgents.data
      && Array.isArray(authReducer.getTicketAllAgents.data) && authReducer.getTicketAllAgents.data.length > 0) {

      setAllAgentList([]);
      setAllAgentList(authReducer.getTicketAllAgents.data);
      authReducer.getTicketAllAgents = {};
    }
  }, [authReducer.getTicketAllAgents]);

  // once ticket is created
  useEffect(() => {
    if (!_.isEmpty(authReducer.createNewTicket) ) {
      // refresh tickets UI i.e get tickets from API again
      // selectedDropdown(seletedVessels);
      // upload attachments, if any
      if (authReducer.createNewTicket.data && authReducer.createNewTicket["status"] == 1 && authReducer.createNewTicket.data["ticketId"]) {
        uploadAttachments(authReducer.createNewTicket.data["ticketId"], authReducer.createNewTicket.data[K4ID_KEY]);
      }
      authReducer.createNewTicket = {};
    }
  }, [authReducer.createNewTicket])

  // once attachment is uploaded
  useEffect(() => {
    if (!_.isEmpty(authReducer.addAttachmentsToTicket)) {
      newTicketAttachmentsFile.length > 0 && setNewTicketAttachmentsFile([]);
      authReducer.addAttachmentsToTicket = {};
    }
  }, [authReducer.addAttachmentsToTicket])



  const uploadAttachments = (ticketId, k4Id) => {
    if (newTicketAttachmentsFile.length > 0 && k4Id) {
      //  formdata object
      let formData = new FormData();
      //  append the values with key, value pair
      formData.append(K4ID_KEY, k4Id);
      formData.append(TICKET_ID_KEY, ticketId);
      for (let i = 0; i < newTicketAttachmentsFile.length; i++) {
        let lastModified = new Date().getTime();
        let fileNameWithExtension = newTicketAttachmentsFile[i][NAME] && newTicketAttachmentsFile[i][NAME].lastIndexOf(".") > 0 ? newTicketAttachmentsFile[i][NAME] : DEFAULT_IMAGE_FILE_NAME;
        let lastIndexOfDot = fileNameWithExtension.lastIndexOf(".");
        let fileExtension = fileNameWithExtension.slice(lastIndexOfDot + 1);
        let fileName = fileNameWithExtension.slice(0, lastIndexOfDot);
        let fileNameWithTimeStamp = fileName + "_" + lastModified + "." + fileExtension;

        // append each file to formdata with file name
        formData.append(
          FILE_KEY,
          newTicketAttachmentsFile[i],
          fileNameWithTimeStamp
        );
      }
      // send fordata to attachment API
      addAttachments(formData);
    }
  };

  // once ticket is updated
  useEffect(() => {
    if (!_.isEmpty(authReducer.updateTicket)) {
      // refresh tickets UI i.e get tickets from API again
      // selectedDropdown(seletedVessels);
      authReducer.updateTicket = {};
    }
  }, [authReducer.updateTicket]);

  const clearDetailsPageClickedTicketInfo = () => {
    setDetailsPageClickedTicketInfo('');
  };

  const clearHistoryPageClickedTicketInfo = () => {
    setHistoryPageClickedTickedInfo('');
  };

  const handlePerPageChange = (e: any) => {
    let _limit = parseInt(e.target.value);
    if (Number.isInteger(_limit)) {
      // Update navigation url
      let params: any = getDecodeURI(location.search);
      params.page = 0;
      params.limit = _limit;
      doNavigate(params);
      setPage(0);
      setLimit(_limit);
    }
  };

  const handlePagination = (e, value) => {
    // Update navigation url
    let params: any = getDecodeURI(location.search);
    const page = value - 1;
    params.page = page;
    setPage(page);
    doNavigate(params);
    prepareGetTicketsByFilterPayload({_page: page})
  };

  // const getTicketsByActiveFilter = () => {
  //   switch (activeFilter) {
  //     case VESSEL_FILTER:
  //       getTickets(seletedVessels, page, limit);
  //       break;
  //     case OTHER_FILTERS:
  //       prepareGetTicketsByFilterPayload();
  //       break;
  //     default:
  //   }
  // };

  const selectedDropdown = (k4Id: any) => {
    clearTicketTableData();

    // setActiveFilter(VESSEL_FILTER);
    // getTickets(k4Id, 0, limit);
    prepareGetTicketsByFilterPayload();
  };

  const clearTicketTableData = () => {
    data.length = 0;
    // @TODO setPage(0);
    // setLastPage(0);
    // setShowingTickets("0");
    // setTicketCount(0);
    setIsSelectedVesselK4IdAvailable(false);
  };

  // useEffect(() => {
  //   // once search box value is saved
  //   if (activeFilter == OTHER_FILTERS && searchText && !_.isEmpty(searchText)) {
  //     prepareGetTicketsByFilterPayload();
  //   }
  //   // once saved search box value is cleared
  //   if (activeFilter == OTHER_FILTERS && _.isEmpty(searchText) && _.isEmpty(searchBoxValue)) {
  //     prepareGetTicketsByFilterPayload();
  //   }
  // }, [searchText])

  useEffect(() => {
    // when user clears search box
    if (activeFilter == OTHER_FILTERS && searchText && !_.isEmpty(searchText) && _.isEmpty(searchBoxValue)) {
      // clear saved search box value
      setSearchText("");
    }
  }, [searchBoxValue])

  const clearSearchBoxValue = () => {
    setSearchText("");
  }

  const handleSearchClick = (value: string) => {
    if (value != searchText) {
      // clearTicketTableData();
      // setActiveFilter(OTHER_FILTERS);
      // save search box value
      // setSearchText(searchBoxValue);
      let params: any = getDecodeURI(location.search);
      params.page = 0;
      params.search = value
      doNavigate(params);
      setPage(0);
      setSearchText(value);
    }
  }

  const searchBoxKeyDown = (event: any) => {
    if (event && event.key === ENTER_KEY_CODE) {
      handleSearchClick(searchBoxValue);
    }
  }

  const setBoxValue = (value) => {
    let params: any = getDecodeURI(location.search);
    params.page = 0;
    params.search = value
    if (value.length === 0) {
      delete params.search
    }
    doNavigate(params);
    setPage(0);
    setSearchBoxValue(value);
  }

  const prepareGetTicketsByFilterPayload = (filters: TicketUnhandledFilter = {}) => {
    let {_k4idList, _ouId, _page} = filters;
    _ouId = _ouId || selectedOu
    _k4idList = _k4idList || k4idList;

    if(_.isEmpty(_ouId)){
      return;
    }

    let searchFilterSelected = false;
    let creatorFilterSelected = false;
    let assigneeFilterSelected = false;
    let moreFiltersSelected = false;
    let sortingSelected = false;
    let payload = {};
    // search filter saved search box value
    if (searchText) {
      payload[SEARCH_KEY] = CUSTOM_SEARCH;
      payload[SEARCH_TEXT] = searchText;
      searchFilterSelected = true;
      setSearchBoxValue(searchText);
    } else {
      clearSearchBoxValue();
    }
    // if vessel is selected from dropdown, pagination is active, reset to first page
    if(_q.page === undefined && _page === undefined && page !== 0) {
      _page = 0;
      setPage(0);
    }

    if (authReducer.selectedVessels.isAll || _.isEmpty(authReducer.selectedVessels.vessels)) {
      payload[DPID_KEY] = authReducer.selectedOu.id;
      payload[K4ID_KEY] = null;
    } else {
      payload[K4ID_KEY] = _k4idList && _k4idList.length > 0 ? _k4idList : null;
    }
    payload[PAGE_SIZE_KEY] = limit;
    payload[PAGE_NUMBER_KEY] = _page === undefined ? page : _page;
    payload[DPID_KEY] = _ouId === "DP-0001" ? undefined : _ouId;
    // creator filter saved selections
    // get K4_EMP_ID_KEY of all selected users
    if (moreFiltersSelection?.creater && moreFiltersSelection.creater.length > 0) {
      let k4EmpIds = moreFiltersSelection?.creater.map(a => a[K4_EMP_ID_KEY]);
      if (k4EmpIds && Array.isArray(k4EmpIds) && k4EmpIds.length > 0) {
        payload[RAISED_BY_KEY] = k4EmpIds;
        creatorFilterSelected = true;
      }
    }

    // assignee filter saved selections
    // get K4_EMP_ID_KEY of all selected users
    if (moreFiltersSelection?.assignee &&  moreFiltersSelection?.assignee.length > 0) {
      let k4EmpIds = moreFiltersSelection?.assignee.map(a => a[K4_EMP_ID_KEY]);
      if (k4EmpIds && Array.isArray(k4EmpIds) && k4EmpIds.length > 0) {
        payload[ASSIGNED_TO_KEY] = k4EmpIds;
        assigneeFilterSelected = true;
      }
    }

    // more filter saved selections
    if (moreFiltersSelection && (moreFiltersSelection.isTicketOpen || moreFiltersSelection.isTicketClosed || moreFiltersSelection.isTicketOnHold || moreFiltersSelection.isTicketEscalated || moreFiltersSelection.isTicketReopened || moreFiltersSelection.isTicketResolved ||
      moreFiltersSelection.isUnassigned || moreFiltersSelection.isRaised24h || moreFiltersSelection.isHotVessel || moreFiltersSelection.isCritical || moreFiltersSelection.isStandUp || moreFiltersSelection.isMyTickets || moreFiltersSelection.isTicketRMA || moreFiltersSelection.selectedTimeframe
      || (moreFiltersSelection.isTicketOpen && moreFiltersSelection.isTicketClosed && moreFiltersSelection.isTicketOnHold && moreFiltersSelection.isTicketEscalated && moreFiltersSelection.isTicketReopened && moreFiltersSelection.isTicketResolved && !_.isEmpty(moreFiltersSelection.isTicketRMA) && !_.isEmpty(moreFiltersSelection.selectedTimeframe)))) {
      let tkt_status: string[] = [];

      if (moreFiltersSelection.isTicketOpen) {
        tkt_status.push(TICKET_OPEN);
      }

      if (moreFiltersSelection.isTicketClosed) {
        tkt_status.push(TICKET_CLOSED);
      }

      if (moreFiltersSelection.isTicketEscalated) {
        tkt_status.push(TICKET_ESCALATED);
      }

      if (moreFiltersSelection.isTicketOnHold) {
        tkt_status.push(TICKET_ONHOLD);
      }

      if (moreFiltersSelection.isTicketReopened) {
        tkt_status.push(TICKET_REOPENED);
      }

      if (moreFiltersSelection.isTicketResolved) {
        tkt_status.push(TICKET_RESOLVED);
      }

      if (moreFiltersSelection.isUnassigned) {
        payload["unassigned"] = true
      }

      if (moreFiltersSelection.isHotVessel) {
        setHotVesselDialog(true)
        return;
      }

      if (moreFiltersSelection.isRaised24h) {
        payload["last24"] = true
      }
      if (moreFiltersSelection.isCritical) {
        payload["priority"] = "Critical"
      }
      if (moreFiltersSelection.isStandUp) {
        payload["searchKey"] = "channel"
        payload["searchText"] = "StandUp"
      }
      if (moreFiltersSelection.isMyTickets) {
        payload["searchKey"] = "assignee";
      }
      if (moreFiltersSelection.isTicketRMA) {
        payload[TICKET_RMA] = 'RMA';
      }

      if (tkt_status.length > 0) {
        payload[TICKET_STATUS_KEY] = tkt_status;
      }

      moreFiltersSelected = true;
    }

    if (!_.isEmpty(newSummaryStartDate)) {
      payload[EVENT_START_DATE_KEY] = _q.hasOwnProperty('status') && _q.status == 'Open' ? moment(Date.now()).subtract(1, 'years') : newSummaryStartDate;

    }

    if (!_.isEmpty(newSummaryEndDate)) {
      payload[EVENT_END_DATE_KEY] = _q.hasOwnProperty('status') && _q.status == 'Open' ? moment(Date.now()) : newSummaryEndDate;

    }
    // sorting saved selection
    if (sortKey && sortOrder) {
      payload[SORT_KEY] = sortKey;
      payload[SORT_ORDER_KEY] = sortOrder;
      sortingSelected = true;
    }
    // if minimum one out of search, creator, assignee, more filters, sorting is selected then getTicketsByFilter else get all tickets of selected vessel

    if (searchFilterSelected || creatorFilterSelected || assigneeFilterSelected || moreFiltersSelected || sortingSelected) {
      if (searchBoxValue && searchBoxValue.length > 0) {
        getTicketsByFilter(payload, false);
      }
      else
        getTicketsByFilter(payload);


    } else if(_k4idList || _ouId === 'DP-0001') {
      // if (seletedVessels && seletedVessels.length > 0) {
        getTicketsByFilter(payload);

      // }
      // selectedDropdown(seletedVessels);
    }
  }

  const navigateToDeviceDetails = (row, index) => {
    setTicketHistoryShowPopUP(true);
    setEnableNavigationsBtns(true);
    let params: any = getDecodeURI(location?.search);
    params.ticketIndex = index;
    params.k4Id = row.k4Id;
    params.ticketId = row.ticketId;
    // delete params.status
    history.push({pathname: location.pathname, search: `?${getEncodedURI(params)}` });
};

  useEffect(() => {
    if (moreFiltersButtonText == MORE_FILTERS_TEXT_1) {
      setIsMoreFiltersActive(true);
    } else {
      setIsMoreFiltersActive(false);
    }
  }, [moreFiltersButtonText])

  const clearMoreFiltersSelection = () => {
    setMoreFiltersSelection({
      isTicketOpen: false,
      isTicketClosed: false,
      isTicketOnHold: false,
      isTicketReopened: false,
      isTicketEscalated: false,
      isTicketResolved: false,
      isUnassigned: false,
      isRaised24h: false,
      isHotVessel: false,
      isCritical: false,
      isStandUp: false,
      isMyTickets: false,
      isTicketRMA: false,
      assignee: [],
      creater: [],
      selectedTimeframe: "",
      dateRangeValue: ""
    });
    setMoreFiltersButtonText(MORE_FILTERS_TEXT);
  }

  const handleMoreFiltersClearAllClick = () => {
    clearMoreFiltersSelection();
  }

  const hotVesselSelection = (K4Id) => {

    let payload = {}
    payload[K4ID_KEY] = K4Id;
    payload[PAGE_SIZE_KEY] = limit;
    payload[PAGE_NUMBER_KEY] = page;

    getTicketsByFilter(payload, false);
  }

  const closeHotVessel = () => {
    let params: any = getDecodeURI(location.search);
    if (params.hasOwnProperty("filter") && !_.isEmpty(params.filter) && params.filter.toLowerCase() === "hotvessel") {
      delete params.filter;
      doNavigate(params);
      const filters = getMoreFilter(params);
      setMoreFiltersSelection(filters);
    }
    setHotVesselDialog(false);
  }

  const handleMoreFiltersOkClick = (openStatus: boolean, closedStatus: boolean, reopened: boolean, onHold: boolean, escalated: boolean, resolved: boolean,
    selectedTimeframe: string, unAssigned: boolean, hotVessels: boolean, raised24h: boolean, critical: boolean, standUp: boolean, myTickets: boolean,  RMA:boolean,
    startDate: string, endDate: string, dateRangeValue: string, assigneeAutoCompleteSelectedvalues: [], creatorAutoCompleteSelectedvalues: []) => {

      // when the user clicks CLEAR ALL button OR user manually clears the all more filters selections
    // and then clicks OK button, clear the previously saved more filters selections and apply changes
    if (activeFilter == OTHER_FILTERS && (!openStatus && !closedStatus && !reopened && !onHold && !escalated && !resolved && !unAssigned && !hotVessels && !raised24h && !critical && !standUp && !myTickets && !RMA && _.isEmpty(startDate) && _.isEmpty(endDate))
      && (moreFiltersSelection && (moreFiltersSelection.isTicketOpen || moreFiltersSelection.isTicketClosed || moreFiltersSelection.isTicketOnHold || moreFiltersSelection.isTicketEscalated || moreFiltersSelection.isTicketReopened || moreFiltersSelection.isTicketResolved
        || moreFiltersSelection.isUnassigned || moreFiltersSelection.isRaised24h || moreFiltersSelection.isHotVessel || moreFiltersSelection.isCritical || moreFiltersSelection.isStandUp || moreFiltersSelection.isMyTickets || moreFiltersSelection.isTicketRMA
        || (moreFiltersSelection.isTicketOpen && moreFiltersSelection.isTicketClosed && moreFiltersSelection.isTicketOnHold && moreFiltersSelection.isTicketEscalated && moreFiltersSelection.isTicketReopened && moreFiltersSelection.isTicketResolved  &&
          moreFiltersSelection.isUnassigned && moreFiltersSelection.isRaised24h && moreFiltersSelection.isHotVessel && moreFiltersSelection.isCritical && moreFiltersSelection.isStandUp && moreFiltersSelection.isMyTickets && moreFiltersSelection.isTicketRMA)))) {
            console.log("Hereee1")
      clearMoreFiltersSelection();
    } else if ( closedStatus || onHold || reopened || escalated || resolved || unAssigned || hotVessels || raised24h || critical || standUp || myTickets || RMA || assigneeAutoCompleteSelectedvalues || creatorAutoCompleteSelectedvalues || (!openStatus && !closedStatus && !onHold && !reopened && !escalated && !resolved && !_.isEmpty(startDate) && !_.isEmpty(endDate))) {
      // if more filters selections are selected
      setActiveFilter(OTHER_FILTERS);
      setMoreFiltersButtonText(MORE_FILTERS_TEXT_1);

      // setAssigneeSelectedUsers(assigneeAutoCompleteSelectedvalues)
      // setCreatorSelectedUsers(creatorAutoCompleteSelectedvalues)

      let params: any = getDecodeURI(location.search), statusArr = "", separator = false, assigneeArray="",creatorArray=""

      if(assigneeAutoCompleteSelectedvalues && Array.isArray(assigneeAutoCompleteSelectedvalues) && assigneeAutoCompleteSelectedvalues.length > 0){
        //  let assigneeArrayList = assigneeAutoCompleteSelectedvalues.map((el)=>{return el[K4_EMP_ID_KEY]})
        //  assigneeArray = assigneeArrayList.toString()
        params.assignedTo =  JSON.stringify(assigneeAutoCompleteSelectedvalues);
      }else if(assigneeAutoCompleteSelectedvalues && Array.isArray(assigneeAutoCompleteSelectedvalues) && assigneeAutoCompleteSelectedvalues.length == 0){
       delete params.assignedTo
      }

      if(creatorAutoCompleteSelectedvalues && creatorAutoCompleteSelectedvalues.length > 0){
        // let creatorArrayList = creatorAutoCompleteSelectedvalues.map((el)=>{return el[K4_EMP_ID_KEY]})
        // creatorArray = creatorArrayList.toString()
        params.createdBy =  JSON.stringify(creatorAutoCompleteSelectedvalues);
       }
       else if(creatorAutoCompleteSelectedvalues && Array.isArray(creatorAutoCompleteSelectedvalues) && creatorAutoCompleteSelectedvalues.length == 0){
        delete params.createdBy
       }

      if (openStatus) {
        statusArr += "Open"
        separator = true
      }

      if (closedStatus) {
        statusArr += separator ? ",Closed" : "Closed"
        separator = true
      }
      if (onHold) {
        statusArr += separator ? ",onHold" : "onHold"
        separator = true
      }
      if (reopened) {
        statusArr += separator ? ",Reopened" : "Reopened"
        separator = true
      }
      if (escalated) {
        statusArr += separator ? ",Escalated" : "Escalated"
        separator = true
      }
      if (resolved) {
        statusArr += separator ? ",Resolved" : "Resolved"
        separator = true
      }


      if (openStatus || closedStatus || onHold || reopened || escalated || resolved) {
        params.status = statusArr;
      }

      let filterArr = ""

      let filterSeparator = false

      if (unAssigned) {
        filterArr += "Unassigned"
        filterSeparator = true
      }
      else if (raised24h) {
        filterArr += filterSeparator ? ",Raised24h" : "Raised24h"
        filterSeparator = true
      }
      else if (hotVessels) {
        filterArr += filterSeparator ? ",HotVessel" : "HotVessel"
        filterSeparator = true
      }
      else if (critical) {
        filterArr += filterSeparator ? ",Critical" : "Critical"
        filterSeparator = true
      }
      else if (standUp) {
        filterArr += filterSeparator ? ",StandUp" : "StandUp"
        filterSeparator = true
      }
      else if (myTickets) {
        filterArr += filterSeparator ? ",MyTickets" : "MyTickets"
        filterSeparator = true
      }
      else if (RMA) {
        filterArr += filterSeparator ? ",RMA" : "RMA"
        filterSeparator = true
      }
      else{
        delete params.filter
      }


      if (unAssigned || hotVessels || raised24h || critical || standUp || myTickets || RMA) {
        params.filter = filterArr;
      }

      if(selectedTimeframe){
        params.selectedTimeframe = selectedTimeframe;
      }
      if(selectedTimeframe === CUSTOM_DATES && dateRangeValue){
        params.dateRangeValue = dateRangeValue;  
      }
      params.page = 0;
      doNavigate(params);
      const filters = getMoreFilter(params);
      setPage(0);
      setAssigneeSelectedUsers(filters.assignee);
      setCreatorSelectedUsers(filters.creater);
      setMoreFiltersSelection(filters);
      // clearTicketTableData();
    }
  }

  useEffect(() => {
    if (_.isEmpty(authReducer?.getVesselsListing)) {
      return;
    }
    let _k4idList: string[] = [];
    let _ouId = selectedOu;
    if (_ouId !== authReducer?.selectedOu.id) {
      _ouId = authReducer?.selectedOu.id
      setSelectedOu(_ouId);
    } else {
      _k4idList = authReducer?.getVesselsListing?.locations.map(loc => loc.id);
    }
    if (_.isArray(filteredVessels) && filteredVessels.length > 0) {
      _k4idList = filteredVessels.map(vessel => vessel.id);
    }
    setK4idList(_k4idList);
    prepareGetTicketsByFilterPayload({ _k4idList, _ouId });
  }, [moreFiltersSelection, searchText, limit, sortKey, sortOrder, authReducer?.selectedOu, authReducer?.getVesselsListing, filteredVessels, newSummaryStartDate, newSummaryEndDate])

  useEffect(() => {
    if (activeFilter == VESSEL_FILTER) {
      // clear search box value
      clearSearchBoxValue();
      // clear creator filter selection
      creatorMenuItemSetDefaultSelection();
      // clear assignee filter selection
      assigneeMenuItemSetDefaultSelection();
      // clear more filter selection
      clearMoreFiltersSelection();
      // clear sorting selection
      clearSortingSelection();
    }
  }, [activeFilter])



  const discardFilter = (filter, key) => {
    let _q = getDecodeURI(location?.search), params: any = getDecodeURI(location.search), urlStatus = _q.status && _q.status.includes(",") ? _q.status.split(',') : _q.status,
    urlFilter = _q.filter && _q.filter.includes(",") ? _q.filter.split(',') : _q.filter

    if (_q.status && '' != _q.status.trim() && key === "status") {
      if (Array.isArray(urlStatus)) {
        urlStatus = urlStatus.filter(function (item) {
          return item !== filter
        })
      }
      else {
        urlStatus = ""
      }
    }

    if (_q.filter && '' != _q.filter.trim() && key === "filter") {
      if (Array.isArray(urlFilter)) {
        urlFilter = urlFilter.filter(function (item) {
          return item !== filter
        })
      } else {
        urlFilter = ""
      }

    }

    if( key === "filter"){
    if (urlFilter !== "" && _q.filter && '' != _q.filter.trim() ) {
      params.filter = urlFilter
    }
    else {
      delete params.filter
    }
  }

    if( key === "status"){
    if (urlStatus !== "" && _q.status && '' != _q.status.trim()) {
      params.status = urlStatus
    } else {
      delete params.status
    }
  }

  if( key === ASSIGNEE_KEY){
      delete params.assignedTo
  }

  if( key === CREATOR_KEY){
    delete params.createdBy
}
  if(key === "dateRange"){
    delete params.selectedTimeframe;
    delete params.dateRangeValue;
  }

  params.page = 0;
    doNavigate(params);
    const filters = getMoreFilter(params);
    setPage(0);
    setAssigneeSelectedUsers(filters.assignee);
    setCreatorSelectedUsers(filters.creater);
    setMoreFiltersSelection(filters);

  }

  const showAllUserTickets = () => {
    // TO DO
    // selectedVesselK4Id && selectedDropdown(selectedVesselK4Id);
    // creatorMenuItemSetDefaultSelection();
    // assigneeMenuItemSetDefaultSelection();
  }

  const handleCreatorMenu = (event: any) => {
    const _creatorMenu = document.getElementById("creatorMenu");
    if (_creatorMenu) {
      _creatorMenu.style.zIndex = "1300";
    }
    setCreatorMenuAnchorEl(event.currentTarget);
  }

  const handleAssigneeMenu = (event: any) => {
    const _assigneeMenu = document.getElementById("assigneeMenu");
    if (_assigneeMenu) {
      _assigneeMenu.style.zIndex = "1300";
    }
    setAssigneeMenuAnchorEl(event.currentTarget)
  }

  const markAsClosed = (k4Id: any, ticketId: any) => {
    let payload = {}
    payload[K4ID_KEY] = k4Id;
    payload[TICKET_ID_KEY] = ticketId;
    payload[TICKET_STATUS_KEY] = TICKET_CLOSED;

    updateTicket(payload);
  }

  const handleCommentIconClick = (row: any) => {
    setDetailsPageClickedTicketInfo(row);
    setTicketDetailsShowPopUP(true);
  }

  const handleArrowIconClick = (row: any) => {
    setHistoryPageClickedTickedInfo(row);
    setDetailsPageClickedTicketInfo(row);
    history.push({
      pathname: `/tickets/${row["ticketId"]}`, state: { ticketInfo: row, assigneeList: allAgentList, },
    });
    // setTicketHistoryShowPopUP(true);
  }

  const creatorMenuItemSetDefaultSelection = () => {
    creatorSelectedUsers.length > 0 && setCreatorSelectedUsers([]);

    creatorAutoCompleteSelectedvalues.length > 0 && setCreatorAutoCompleteSelectedvalues([]);
    creatorLoggedInUserSelected && setCreatorLoggedInUserSelected(false);
  }

  const creatorMenuItemHanldeClearAllClick = () => {
    creatorAutoCompleteSelectedvalues.length > 0 && setCreatorAutoCompleteSelectedvalues([]);
    creatorLoggedInUserSelected && setCreatorLoggedInUserSelected(false);
  }

  const creatorMenuItemHanldeOkClick = () => {
    // when the user clicks CLEAR button OR user manually clears the all creator filter selections
    // and then clicks OK button, clear the previously saved creator filter selections and apply changes
    if (!creatorLoggedInUserSelected && creatorAutoCompleteSelectedvalues.length == 0 && creatorSelectedUsers.length > 0) {
      creatorSelectedUsers.length = 0;
      prepareGetTicketsByFilterPayload();
    } else if (creatorLoggedInUserSelected || creatorAutoCompleteSelectedvalues.length > 0) {
      // if creator filter selections are selected

      setActiveFilter(OTHER_FILTERS);
      clearTicketTableData();

      let k4EmpIds: any = [];
      // check LoggedInUser name(me) selected in creator filter
      if (creatorLoggedInUserSelected && loggedInUserDetails && loggedInUserDetails[FULL_NAME_KEY] && loggedInUserDetails[K4_EMP_ID_KEY]) {
        k4EmpIds.push(loggedInUserDetails);
      }
      // check creator Autocomplete selection
      if (creatorAutoCompleteSelectedvalues.length > 0) {
        k4EmpIds = k4EmpIds.concat(creatorAutoCompleteSelectedvalues);
      }
      // save creator filter selections
      if (k4EmpIds.length > 0) {
        setCreatorSelectedUsers(k4EmpIds);
      }
    }
    setCreatorMenuAnchorEl(null);
  }

  useEffect(() => {
    // when user opens creator filter: show previously saved selections if any
    if (creatorMenuAnchorEl && openCreatorMenu) {
      if (creatorSelectedUsers.length > 0) {
        let creators: any = [];
        let loggedInUserSelected = false;
        let loggedInUserName = loggedInUserDetails && loggedInUserDetails[FULL_NAME_KEY];
        let loggedInUserK4EmpId = loggedInUserDetails && loggedInUserDetails[K4_EMP_ID_KEY];
        creatorSelectedUsers.map(item => {
          if (item[FULL_NAME_KEY] && loggedInUserName && item[FULL_NAME_KEY] == loggedInUserName
            && item[K4_EMP_ID_KEY] && loggedInUserK4EmpId && item[K4_EMP_ID_KEY] == loggedInUserK4EmpId) {
            loggedInUserSelected = true;
          } else {
            creators.push(item);
          }
        });
        setCreatorLoggedInUserSelected(loggedInUserSelected);
        setCreatorAutoCompleteSelectedvalues(creators);
      } else {
        creatorAutoCompleteSelectedvalues.length > 0 && setCreatorAutoCompleteSelectedvalues([]);
        creatorLoggedInUserSelected && setCreatorLoggedInUserSelected(false);
      }
    }

  }, [creatorMenuAnchorEl])

  const assigneeMenuItemSetDefaultSelection = () => {
    assigneeLoggedInUserSelected && setAssigneeLoggedInUserSelected(false);
    assigneeUnAssignedSelected && setAssigneeUnAssignedSelected(false)
    assigneeAutoCompleteSelectedvalues.length > 0 && setAssigneeAutoCompleteSelectedvalues([]);
  }


  const assigneeMenuItemHanldeClearAllClick = () => {
    assigneeLoggedInUserSelected && setAssigneeLoggedInUserSelected(false);
    assigneeUnAssignedSelected && setAssigneeUnAssignedSelected(false)
    assigneeAutoCompleteSelectedvalues.length > 0 && setAssigneeAutoCompleteSelectedvalues([]);
  }
  const assigneeMenuItemHanldeOkClick = () => {
    // when the user clicks CLEAR button OR user manually clears the all assignee filter selections
    // and then clicks OK button, clear the previously saved assignee filter selections and apply changes
    if (!assigneeLoggedInUserSelected && !assigneeUnAssignedSelected && assigneeAutoCompleteSelectedvalues.length == 0 && assigneeSelectedUsers.length > 0) {
      assigneeSelectedUsers.length = 0;
      // prepareGetTicketsByFilterPayload();
    } else if (assigneeLoggedInUserSelected || assigneeUnAssignedSelected || assigneeAutoCompleteSelectedvalues.length > 0) {
      // if assignee filter selections are selected

      setActiveFilter(OTHER_FILTERS);
      clearTicketTableData();

      let k4EmpIds: any = [];
      // check LoggedInUser name(me) selected in assignee filter
      if (assigneeLoggedInUserSelected && loggedInUserDetails && loggedInUserDetails[FULL_NAME_KEY] && loggedInUserDetails[K4_EMP_ID_KEY]) {
        k4EmpIds.push(loggedInUserDetails);
      }
      // check not assigned selection
      if (assigneeUnAssignedSelected && unAssignedUserDetails && unAssignedUserDetails[FULL_NAME_KEY] && unAssignedUserDetails[K4_EMP_ID_KEY]) {
        k4EmpIds.push(unAssignedUserDetails);
      }
      // check assignee Autocomplete selection
      if (assigneeAutoCompleteSelectedvalues.length > 0) {
        k4EmpIds = k4EmpIds.concat(assigneeAutoCompleteSelectedvalues);
      }
    
    }
    setAssigneeMenuAnchorEl(null);
  }

  useEffect(() => {
    // when user opens assignee filter: show previously saved selections if any
    if (activeFilter == OTHER_FILTERS && assigneeMenuAnchorEl && openAssigneeMenu) {
      if (assigneeSelectedUsers.length > 0) {
        let assignees: any = [];
        let loggedInUserSelected = false;
        let unAssignedSelected = false;
        let loggedInUserName = loggedInUserDetails && loggedInUserDetails[FULL_NAME_KEY];
        let loggedInUserK4EmpId = loggedInUserDetails && loggedInUserDetails[K4_EMP_ID_KEY];
        let unAssignedUserName = unAssignedUserDetails && unAssignedUserDetails[FULL_NAME_KEY];
        let unAssignedUserK4EmpId = unAssignedUserDetails && unAssignedUserDetails[K4_EMP_ID_KEY];
        assigneeSelectedUsers.map(item => {
          if (item[FULL_NAME_KEY] && loggedInUserName && item[FULL_NAME_KEY] == loggedInUserName
            && item[K4_EMP_ID_KEY] && loggedInUserK4EmpId && item[K4_EMP_ID_KEY] == loggedInUserK4EmpId) {
            loggedInUserSelected = true;
          } else if (item[FULL_NAME_KEY] && unAssignedUserName && item[FULL_NAME_KEY] == unAssignedUserName
            && item[K4_EMP_ID_KEY] && unAssignedUserK4EmpId && item[K4_EMP_ID_KEY] == unAssignedUserK4EmpId) {
            unAssignedSelected = true;
          } else {
            assignees.push(item);
          }
        });
        setAssigneeLoggedInUserSelected(loggedInUserSelected);
        setAssigneeUnAssignedSelected(unAssignedSelected);
        setAssigneeAutoCompleteSelectedvalues(assignees);
      } else {
        assigneeLoggedInUserSelected && setAssigneeLoggedInUserSelected(false);
        assigneeUnAssignedSelected && setAssigneeUnAssignedSelected(false);
        assigneeAutoCompleteSelectedvalues.length > 0 && setAssigneeAutoCompleteSelectedvalues([]);
      }
    }
  }, [assigneeMenuAnchorEl])

  const setSortingSelection = (event: any, sortKey: string, sortOrder: string) => {
    setActiveFilter(OTHER_FILTERS);

    // setPage(0);
    // setSortKey(sortKey);
    // setSortOrder(sortOrder);

    let params: any = getDecodeURI(location.search);
    params.page = 0;
    params.sortKey = sortKey;
    params.sortOrder = sortOrder;
    setPage(0);
    setSortKey(sortKey);
    setSortOrder(sortOrder);
    doNavigate(params);
  }

  const switchSorting = (event: any, sortKey: string) => {
    let _sortOrder: string = ASCENDING_ORDER;
    if (sortKey === sortKey) {
      _sortOrder = ASCENDING_ORDER === sortOrder ? DESCENDING_ORDER : ASCENDING_ORDER;
    }

    setActiveFilter(OTHER_FILTERS);
    setPage(0);
    setSortKey(sortKey);
    setSortOrder(_sortOrder);

    let params: any = getDecodeURI(location.search);
    params.page = 0;
    params.sortKey = sortKey;
    params.sortOrder = _sortOrder;
    doNavigate(params);
  }



  const clearSortingSelection = () => {
    setSortKey(null);
    setSortOrder(null);
  }

  const showMoreText = "Show more";
  const showLessText = "Show less";
  const spaceBeforeShowMoreText = ". ";
  const toggleReadMore = (event: any, text: any) => {
    if (event.currentTarget.innerText.toLowerCase().includes(showMoreText.toLowerCase())) {
      event.currentTarget.previousElementSibling.innerText = text;
      event.currentTarget.innerHTML = "<br />" + showLessText;
    } else {
      event.currentTarget.previousElementSibling.innerText = text.substring(0, textMinLength).replace(/\n/g, "  ") + spaceBeforeShowMoreText;
      event.currentTarget.innerText = showMoreText;
    }
  };


  const handleOnSearchClick = () => {
    handleSearchClick(searchBoxValue);
  }

  const handleOnSearchClear = () => {
    setBoxValue('');
    handleSearchClick('');
  }

  const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setBoxValue(e.target.value);
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(() => {
      searchTimeoutRef.current = undefined;
      handleSearchClick(e.target.value);
    }, 1000)
  }

  useEffect(() => {
    if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
      if (authReducer.csvDownloadId === 'tickets-csv') {
        DownloadCSV(convertToCsv(authReducer.csvDataDownloded, authReducer.userTimezone), {
          formatters: {
            1: 'ESC-COMMA',
            2: 'ESC-COMMA',
            3: 'ESC-COMMA',
            7: 'ESC-COMMA'
          }
        });
        return () => {
          dispatch(clearCsvDownload())
        }
      }
    }
  }, [authReducer.csvDataDownloded])

  const handleDownloadReport = () => {
    if(_.isEmpty(selectedOu)){
      return;
    }
    let payload = {};
    if (authReducer.selectedVessels.isAll || _.isEmpty(authReducer.selectedVessels.vessels)) {
      payload[DPID_KEY] = authReducer.selectedOu.id;
      payload[K4ID_KEY] = null;
    } else {
      payload[K4ID_KEY] = k4idList && k4idList.length > 0 ? k4idList : null;
    }
    payload[PAGE_SIZE_KEY] = total;
    payload[PAGE_NUMBER_KEY] = 0;
    payload[DPID_KEY] =   selectedOu  === "DP-0001" ? undefined : selectedOu;
    // search filter saved search box value
    if (searchText) {
      payload[SEARCH_KEY] = CUSTOM_SEARCH;
      payload[SEARCH_TEXT] = searchText;
    }

    // creator filter saved selections
    // get K4_EMP_ID_KEY of all selected users
    if (creatorSelectedUsers.length > 0) {
      let k4EmpIds = creatorSelectedUsers.map(a => a[K4_EMP_ID_KEY]);
      if (k4EmpIds && Array.isArray(k4EmpIds) && k4EmpIds.length > 0) {
        payload[RAISED_BY_KEY] = k4EmpIds;
      }
    }

    // assignee filter saved selections
    // get K4_EMP_ID_KEY of all selected users
    if (assigneeSelectedUsers.length > 0) {
      let k4EmpIds = assigneeSelectedUsers.map(a => a[K4_EMP_ID_KEY]);
      if (k4EmpIds && Array.isArray(k4EmpIds) && k4EmpIds.length > 0) {
        payload[ASSIGNED_TO_KEY] = k4EmpIds;
      }
    }

    // more filter saved selections
    if (moreFiltersSelection && (moreFiltersSelection.isTicketOpen || moreFiltersSelection.isTicketClosed || moreFiltersSelection.isTicketOnHold || moreFiltersSelection.isTicketEscalated || moreFiltersSelection.isTicketReopened || moreFiltersSelection.isTicketResolved ||
      moreFiltersSelection.isUnassigned || moreFiltersSelection.isRaised24h || moreFiltersSelection.isHotVessel || moreFiltersSelection.isCritical || moreFiltersSelection.isStandUp || moreFiltersSelection.isMyTickets || moreFiltersSelection.isTicketRMA || moreFiltersSelection.selectedTimeframe
      || (moreFiltersSelection.isTicketOpen && moreFiltersSelection.isTicketClosed && moreFiltersSelection.isTicketOnHold && moreFiltersSelection.isTicketEscalated && moreFiltersSelection.isTicketReopened && moreFiltersSelection.isTicketResolved && !_.isEmpty(moreFiltersSelection.isTicketRMA) && !_.isEmpty(moreFiltersSelection.selectedTimeframe) && !_.isEmpty(newSummaryStartDate) && !_.isEmpty(newSummaryEndDate)))) {
      let tkt_status: string[] = [];

      if (moreFiltersSelection.isTicketOpen) {
        tkt_status.push(TICKET_OPEN);
      }

      if (moreFiltersSelection.isTicketClosed) {
        tkt_status.push(TICKET_CLOSED);
      }

      if (moreFiltersSelection.isTicketEscalated) {
        tkt_status.push(TICKET_ESCALATED);
      }

      if (moreFiltersSelection.isTicketOnHold) {
        tkt_status.push(TICKET_ONHOLD);
      }

      if (moreFiltersSelection.isTicketReopened) {
        tkt_status.push(TICKET_REOPENED);
      }

      if (moreFiltersSelection.isTicketResolved) {
        tkt_status.push(TICKET_RESOLVED);
      }

      if (moreFiltersSelection.isUnassigned) {
        payload["unassigned"] = true
      }

      if (moreFiltersSelection.isHotVessel) {
        setHotVesselDialog(true)
        return;
      }

      if (moreFiltersSelection.isRaised24h) {
        payload["last24"] = true
      }
      if (moreFiltersSelection.isCritical) {
        payload["priority"] = "Critical"
      }
      if (moreFiltersSelection.isStandUp) {
        payload["searchKey"] = "channel"
        payload["searchText"] = "StandUp"
      }
      if (moreFiltersSelection.isMyTickets) {
        payload["searchKey"] = "assignee";
      }
      if (moreFiltersSelection.isTicketRMA) {
        payload[TICKET_RMA] = 'RMA';
      }

      if (tkt_status.length > 0) {
        payload[TICKET_STATUS_KEY] = tkt_status;
      }
    }
    if (!_.isEmpty(newSummaryStartDate)) {
      payload[EVENT_START_DATE_KEY] = _q.hasOwnProperty('status') && _q.status == 'Open' ? moment(Date.now()).subtract(1, 'years') : newSummaryStartDate;
    }
    if (!_.isEmpty(newSummaryEndDate)) {
      payload[EVENT_END_DATE_KEY] = _q.hasOwnProperty('status') && _q.status == 'Open' ? moment(Date.now()) : newSummaryEndDate;
    }
    // sorting saved selection
    if (sortKey && sortOrder) {
      payload[SORT_KEY] = sortKey;
      payload[SORT_ORDER_KEY] = sortOrder;
    }
    const params: CsvParameters = {
      type: "POST_API_EXTERNAL",
      id: "tickets-csv",
      endpoint: `${KAPTUREV2_URL}/tickets/getFilteredTickets`,
      payload
    }
    downloadAsCSV(params);
  }

  return (
    <div className="Form">
      {createNewAccountShowPopUP ?
        <CreateNewTicketDialog vesselk4Id={selectedVesselK4Id} createTicketVesselList={createTicketVesselList} assigneeList={allAgentList} autoCompleteNoOptionsText={AUTO_COMPLETE_NO_OPTIONS_TEXT} addToAttachments={setNewTicketAttachmentsFile} open={createNewAccountShowPopUP} onClose={() => setCreateNewAccountShowPopUP(false)} />
        :
        ticketHistoryShowPopUP ?
          <TicketView ticketsList={data} ticketInfo={historyPageClickedTicketInfo} clearTicketInfo={clearHistoryPageClickedTicketInfo} addToAttachments={setNewTicketAttachmentsFile} open={ticketHistoryShowPopUP} onClose={() => setTicketHistoryShowPopUP(false)} assigneeList={allAgentList} location={location} enableNavigationsBtns={enableNavigationsBtns}/>
          :
          <Grid className="tickets-page-frontView">
            <Grid container className="ticketsTopbar topbarWidth alignTopbarItemsCenter">
              <Grid item xs={12} sm={6} md={6} lg={6} className="tkt-searchbar-filter-div">
                <Grid container>
                  <Grid item xs={12} sm={5} md={5} lg={5} className="searchbarMaxWidth1">
                    <Box className="searchbarBox">
                      <TextField
                        id="search"
                        variant="outlined"
                        placeholder="Type something"
                        autoComplete="off"
                        size="small"
                        value={searchBoxValue}
                        onChange={handleOnSearchChange}
                        onKeyDown={(event) => searchBoxKeyDown(event)}
                        InputLabelProps={{ className: "serachLabel" }}
                        InputProps={{
                          className: "searchbarInputs",
                          startAdornment: (
                            <InputAdornment position="start">
                              {!searchBoxValue && <SearchIcon
                                className="searchbarIconsPosition"
                                onClick={handleOnSearchClick}
                              />}
                              {searchBoxValue && <CloseIcon
                                className="searchbarIconsPosition"
                                onClick={handleOnSearchClear}
                              />}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} lg={2} className="ticketFilterButton filterButtonMaxWidth">
                    {/* <Button className="tkt_moreFilters_button cursorPointer" style={{ border: "1px solid rgba(20, 99, 172, 0.24)", marginTop: "-4px", display: "flex", justifyContent: "center" }} onClick={() => setMoreFiltersShowPopUP(true)}>
                      <img src={filter} title="filter" style={{ width: "16px", height: "16px", padding: "5px" }} />

                      {moreFiltersButtonText}
                    </Button> */}

                    <Button variant="outlined" startIcon={<TuneIcon id="filterIcon"/>} onClick={() => setMoreFiltersShowPopUP(true)} id="filterButton">
                            Filters
                        </Button>
                  </Grid>
                </Grid>

              </Grid>



              <Grid item xs={12} sm={3} md={4} lg={4} className="createTicketButton">
                <Button title="Create a new ticket" className="ticket-create-button" onClick={() => setCreateNewAccountShowPopUP(true)}>
                  Create a new ticket
                </Button>

              </Grid>


              <Menu
                anchorEl={creatorMenuAnchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openCreatorMenu}
                onClose={() => setCreatorMenuAnchorEl(null)}
                id="creatorMenu"
                style={{ top: "30px", marginLeft: "14px", zIndex: -1 }}
              >
                <MenuItem className="creatorMenuItem">
                  <Autocomplete
                    multiple
                    limitTags={1}
                    size="small"
                    id="creatorMenuSearchAutocomplete"
                    noOptionsText={AUTO_COMPLETE_NO_OPTIONS_TEXT}
                    options={allAgentList}
                    value={creatorAutoCompleteSelectedvalues}
                    defaultValue={creatorAutoCompleteSelectedvalues}
                    getOptionLabel={(option: any) => option[FULL_NAME_KEY]}
                    PaperComponent={({ children }) => (
                      <Paper className="fontCSS fontSize14px fontWeightNormal" >{children}</Paper>
                    )}
                    onChange={(e, newValue: any) => {
                      setCreatorAutoCompleteSelectedvalues(newValue);
                    }}
                    renderTags={(value, getTagProps) => {
                      return autoCompleteRenderTags(value, AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS, getTagProps, FULL_NAME_KEY);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Search creator" />
                    )}
                  />
                </MenuItem>
                {
                  loggedInUserDetails && loggedInUserDetails[FULL_NAME_KEY] && loggedInUserDetails[K4_EMP_ID_KEY]
                    ?
                    <MenuItem className="creatorMenuItem fontCSS fontSize14px fontWeightNormal">
                      <div>
                        <span>{loggedInUserDetails[FULL_NAME_KEY]} (me)</span>
                        {
                          creatorLoggedInUserSelected
                            ? <img src={checkedbox} onClick={() => setCreatorLoggedInUserSelected(false)} />
                            : <img src={uncheckedbox} onClick={() => setCreatorLoggedInUserSelected(true)} />
                        }
                      </div>
                    </MenuItem>
                    : ""
                }
                <MenuItem className="creatorMenuItem">
                  <div className="creatorMenuItem_actions">
                    <Button className="creatorMenuItem_clearAllButton" onClick={() => creatorMenuItemHanldeClearAllClick()}>Clear</Button>
                    <Button className="creatorMenuItem_okButton" onClick={() => creatorMenuItemHanldeOkClick()}>Ok</Button>
                  </div>
                </MenuItem>
              </Menu>

              <Menu
                anchorEl={assigneeMenuAnchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openAssigneeMenu}
                onClose={() => setAssigneeMenuAnchorEl(null)}
                id="assigneeMenu"
                style={{ top: "30px", marginLeft: "14px", zIndex: -1 }}
              >

                <MenuItem className="assigneeMenuItem">
                  <Autocomplete
                    multiple
                    limitTags={1}
                    size="small"
                    id="assigneeMenuSearchAutocomplete"
                    noOptionsText={AUTO_COMPLETE_NO_OPTIONS_TEXT}
                    options={allAgentList}
                    value={assigneeAutoCompleteSelectedvalues}
                    defaultValue={assigneeAutoCompleteSelectedvalues}
                    getOptionLabel={(option: any) => option[FULL_NAME_KEY]}
                    PaperComponent={({ children }) => (
                      <Paper className="fontCSS fontSize14px fontWeightNormal" >{children}</Paper>
                    )}
                    onChange={(e, newValue: any) => {
                      setAssigneeAutoCompleteSelectedvalues(newValue);
                    }}
                    renderTags={(value, getTagProps) => {
                      return autoCompleteRenderTags(value, AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS, getTagProps, FULL_NAME_KEY);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Search assignee" />
                    )}
                  />
                </MenuItem>
                {
                  loggedInUserDetails && loggedInUserDetails[FULL_NAME_KEY] && loggedInUserDetails[K4_EMP_ID_KEY]
                    ?
                    <MenuItem className="assigneeMenuItem fontCSS fontSize14px fontWeightNormal">
                      <div>
                        <span>{loggedInUserDetails[FULL_NAME_KEY]} (me)</span>
                        {
                          assigneeLoggedInUserSelected
                            ? <img src={checkedbox} onClick={() => setAssigneeLoggedInUserSelected(false)} />
                            : <img src={uncheckedbox} onClick={() => setAssigneeLoggedInUserSelected(true)} />
                        }
                      </div>
                    </MenuItem>
                    : ""
                }
                {
                  unAssignedUserDetails && unAssignedUserDetails[FULL_NAME_KEY] && unAssignedUserDetails[K4_EMP_ID_KEY]
                    ?
                    <MenuItem className="creatorMenuItem fontCSS fontSize14px fontWeightNormal">
                      <div>
                        <span>{unAssignedUserDetails[FULL_NAME_KEY]}</span>
                        {
                          assigneeUnAssignedSelected
                            ? <img src={checkedbox} onClick={() => setAssigneeUnAssignedSelected(false)} />
                            : <img src={uncheckedbox} onClick={() => setAssigneeUnAssignedSelected(true)} />
                        }
                      </div>
                    </MenuItem>
                    : ""
                }
                {/* comment it for now */}
                {/* <MenuItem className="assigneeMenuItem assigneeMenuItem_showAllUsers" onClick={() => showAllUserTickets()}>Show all users</MenuItem> */}
                <MenuItem className="assigneeMenuItem">
                  <div className="assigneeMenuItem_actions">
                    <Button className="assigneeMenuItem_clearAllButton" onClick={() => assigneeMenuItemHanldeClearAllClick()}>Clear</Button>
                    <Button className="assigneeMenuItem_okButton" onClick={() => assigneeMenuItemHanldeOkClick()}>Ok</Button>
                  </div>
                </MenuItem>
              </Menu>
            </Grid>
            <Grid container style={{ background: "#FFFFFF", display: "flex", alignItems: "center"}}>
              <Grid item xs={12} sm={6} md={8} lg={8} style={{ display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                <Grid container style={{ margin: "8px 0px 0px 15px"}}>

                  {
                    moreFiltersSelection.isTicketOpen ?
                      <Grid item className="show_filter" style={{ minWidth: "100px"}}>
                        <div style={{ display: "flex", justifyContent: "space-between", height:"23px"}}>
                          <Typography className="typo2">
                            Status
                          </Typography>
                          <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                            onClick={(e) => discardFilter("Open", "status")}
                          />
                        </div>
                        <Typography className="typo3">
                          {moreFiltersSelection.isTicketOpen ? "Open " : ""}
                        </Typography>
                      </Grid>
                      : ""
                  }

                  {
                    moreFiltersSelection.isTicketClosed ?
                      <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="typo2">
                            Status
                          </Typography>
                          <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                            onClick={(e) => discardFilter("Closed", "status")}
                          />
                        </div>
                        <Typography className="typo3">
                          {moreFiltersSelection.isTicketClosed ? "Closed " : ""}
                        </Typography>
                      </Grid>
                      : ""
                  }

                  {
                    moreFiltersSelection.isTicketEscalated ?
                      <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="typo2">
                            Status
                          </Typography>
                          <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                            onClick={(e) => discardFilter("Escalated", "status")}
                          />
                        </div>
                        <Typography className="typo3">
                          {moreFiltersSelection.isTicketEscalated ? "Escalated " : ""}
                        </Typography>
                      </Grid>
                      : ""
                  }

                  {
                    moreFiltersSelection.isTicketOnHold ?
                      <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="typo2">
                            Status
                          </Typography>
                          <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                            onClick={(e) => discardFilter("onHold", "status")}
                          />
                        </div>
                        <Typography className="typo3">
                          {moreFiltersSelection.isTicketOnHold ? "On Hold " : ""}
                        </Typography>
                      </Grid>
                      : ""
                  }

                  {
                    moreFiltersSelection.isTicketResolved ?
                      <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="typo2">
                            Status
                          </Typography>
                          <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                            onClick={(e) => discardFilter("Resolved", "status")}
                          />
                        </div>
                        <Typography className="typo3">
                          {moreFiltersSelection.isTicketResolved ? "Resolved " : ""}
                        </Typography>
                      </Grid>
                      : ""
                  }

                  {
                    moreFiltersSelection.isTicketReopened ?
                      <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="typo2">
                            Status
                          </Typography>
                          <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                            onClick={(e) => discardFilter("Reopened", "status")}
                          />
                        </div>
                        <Typography className="typo3">
                          {moreFiltersSelection.isTicketReopened ? "Reopened " : ""}
                        </Typography>
                      </Grid>
                      : ""
                  }


                  {
                    moreFiltersSelection.isUnassigned ?
                      <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="typo2">
                            Filter In
                          </Typography>
                          <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                            onClick={(e) => discardFilter("Unassigned", "filter")}
                          />
                        </div>
                        <Typography className="typo3">
                          {moreFiltersSelection.isUnassigned ? "Unassigned " : ""}
                        </Typography>
                      </Grid>
                      : ""
                  }

                  {
                    moreFiltersSelection.isRaised24h ?
                      <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="typo2">
                            Filter In
                          </Typography>
                          <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                            onClick={(e) => discardFilter("Raised24h", "filter")}
                          />
                        </div>
                        <Typography className="typo3">
                          {moreFiltersSelection.isRaised24h ? "Raised < 24h " : ""}
                        </Typography>
                      </Grid>
                      : ""
                  }
                  {
                    moreFiltersSelection.isHotVessel ?
                      <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="typo2">
                            Filter In
                          </Typography>
                          <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                            onClick={(e) => discardFilter("HotVessel", "filter")}
                          />
                        </div>
                        <Typography className="typo3">
                          {moreFiltersSelection.isHotVessel ? "Hot Sites " : ""}
                        </Typography>
                      </Grid>
                      : ""
                  }
                  {
                    moreFiltersSelection.isCritical ?
                      <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="typo2">
                            Filter In
                          </Typography>
                          <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                            onClick={(e) => discardFilter("Critical", "filter")}
                          />
                        </div>
                        <Typography className="typo3">
                          {moreFiltersSelection.isCritical ? "Critical " : ""}
                        </Typography>
                      </Grid>
                      : ""
                  }
                  {
                    moreFiltersSelection.isStandUp ?
                      <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="typo2">
                            Filter In
                          </Typography>
                          <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                            onClick={(e) => discardFilter("StandUp", "filter")}
                          />
                        </div>
                        <Typography className="typo3">
                          {moreFiltersSelection.isStandUp ? "StandUp " : ""}
                        </Typography>
                      </Grid>
                      : ""
                  }
                  {
                    moreFiltersSelection.isMyTickets ?
                      <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="typo2">
                            Filter In
                          </Typography>
                          <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                            onClick={(e) => discardFilter("MyTickets", "filter")}
                          />
                        </div>
                        <Typography className="typo3">
                          {moreFiltersSelection.isMyTickets ? "My Tickets " : ""}
                        </Typography>
                      </Grid>
                      : ""
                  }
                  {
                    moreFiltersSelection.isTicketRMA ?
                      <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="typo2">
                            Filter In
                          </Typography>
                          <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                            onClick={(e) => discardFilter("RMA", "filter")}
                          />
                        </div>
                        <Typography className="typo3">
                          {moreFiltersSelection.isTicketRMA ? "RMA " : ""}
                        </Typography>
                      </Grid>
                      : ""
                  }

                  {assigneeSelectedUsers && assigneeSelectedUsers.length > 0 ?
                    <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography className="typo2">
                          Assignee
                        </Typography>
                        <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                          onClick={(e) => discardFilter(ASSIGNEE_KEY, ASSIGNEE_KEY)}
                        />
                      </div>
                      <span className="typo3">
                        {assigneeSelectedUsers.map((el, i) => {
                          if (i == assigneeSelectedUsers.length - 1)
                            return el[FULL_NAME_KEY] + " "

                          else
                            return el[FULL_NAME_KEY] + ", "
                        })}
                      </span>
                    </Grid> : null}
                  {creatorSelectedUsers && creatorSelectedUsers.length > 0 ?
                    <Grid item className="show_filter" style={{ minWidth: "100px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography className="typo2">
                          Creator
                        </Typography>
                        <img className="icon-cursor" title="Discard Filter" src={DisardIcon}
                          onClick={(e) => discardFilter(CREATOR_KEY, CREATOR_KEY)}
                        />
                      </div>
                      <span className="typo3">
                        {creatorSelectedUsers.map((el, i) => {
                          if (i == creatorSelectedUsers.length - 1)
                            return el[FULL_NAME_KEY] + " "

                          else
                            return el[FULL_NAME_KEY] + ", "
                        })}
                      </span>
                    </Grid> : null}

                </Grid>
              </Grid>
              {total > 0 && <div className="download-summary-btn marg-left-auto tickets-csv-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>}
            </Grid>
            <Grid style={{ background: "#ffffff", marginLeft: "15px", marginRight: "25px" }}>
            <Box className="margin-t-15">
                <Pagination
                  rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                  count={total ? total : 0}
                  rowsPerPage={limit}
                  page={page + 1}
                  onPageChange={handlePagination}
                  onRowsPerPageChange={handlePerPageChange}
                />
              </Box>
              <div>
                <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                        <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, TICKET_ID_KEY)}>TICKET ID</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sortKey === TICKET_ID_KEY && sortOrder === ASCENDING_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingSelection(event, TICKET_ID_KEY, ASCENDING_ORDER)} />
                                                <img src={sortKey === TICKET_ID_KEY && sortOrder === DESCENDING_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSortingSelection(event, TICKET_ID_KEY, DESCENDING_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                        <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, TICKET_SUBJECT_KEY)}>Site</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sortKey === VESSEL_NAME && sortOrder === ASCENDING_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingSelection(event, VESSEL_NAME, ASCENDING_ORDER)} />
                                                <img src={sortKey === VESSEL_NAME && sortOrder === DESCENDING_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSortingSelection(event, VESSEL_NAME, DESCENDING_ORDER)} />                                            
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                        <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, PARENT_ORG)}>Parent Org</span>
                                            {/* <Grid className="sort-icon-margin">
                                                <img src={sortKey === PARENT_ORG && sortOrder === ASCENDING_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingSelection(event, PARENT_ORG, ASCENDING_ORDER)} />
                                                <img src={sortKey === PARENT_ORG && sortOrder === DESCENDING_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSortingSelection(event, PARENT_ORG, DESCENDING_ORDER)} />                                            
                                            </Grid> */}
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell" onClick={(event) => switchSorting(event, TICKET_SUBJECT_KEY)}>
                                        <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon">
                                        <span className="login-history-dp-name font-wt-900">SUBJECT</span>                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                        <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, CREATED_ON_KEY)}>CREATED ON</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sortKey === CREATED_ON_KEY && sortOrder === ASCENDING_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingSelection(event, CREATED_ON_KEY, ASCENDING_ORDER)} />
                                            <img src={sortKey === CREATED_ON_KEY && sortOrder === DESCENDING_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSortingSelection(event, CREATED_ON_KEY, DESCENDING_ORDER)} />

                                          
                                          
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                        <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, CREATED_BY_KEY)}>CREATOR</span>
                                            <Grid className="sort-icon-margin">
                                            <img src={sortKey === CREATED_BY_KEY && sortOrder === ASCENDING_ORDER ? ascSortingSelected : AscSort} 
                                            className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingSelection(event, CREATED_BY_KEY, ASCENDING_ORDER)} />
                                            <img src={sortKey === CREATED_BY_KEY && sortOrder === DESCENDING_ORDER ? descSortingSelected : DescSort} className="sortingImages" onClick={(event) => setSortingSelection(event, CREATED_BY_KEY, DESCENDING_ORDER)} />
                          
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                        <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ASSIGNEE_KEY)}>ASSIGNEE</span>
                                            <Grid className="sort-icon-margin">
                                              <img src={sortKey === ASSIGNEE_KEY && sortOrder === ASCENDING_ORDER ? ascSortingSelected : AscSort} className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingSelection(event, ASSIGNEE_KEY, ASCENDING_ORDER)} />
                                              <img src={sortKey === ASSIGNEE_KEY && sortOrder === DESCENDING_ORDER ? descSortingSelected : DescSort} className="sortingImages" onClick={(event) => setSortingSelection(event, ASSIGNEE_KEY, DESCENDING_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                        <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ASSIGNEE_KEY)}>PRIORITY</span>
                                            <Grid className="sort-icon-margin">
                                              <img src={sortKey === PRIORITY_KEY && sortOrder === ASCENDING_ORDER ? ascSortingSelected : AscSort} className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingSelection(event, PRIORITY_KEY, ASCENDING_ORDER)} />
                                              <img src={sortKey === PRIORITY_KEY && sortOrder === DESCENDING_ORDER ? descSortingSelected : DescSort} className="sortingImages" onClick={(event) => setSortingSelection(event, PRIORITY_KEY, DESCENDING_ORDER)} />
                                          </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                        <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ASSIGNEE_KEY)}>UPDATED ON</span>
                                            <Grid className="sort-icon-margin">
                                              <img src={sortKey === UPDATEDON_KEY && sortOrder === ASCENDING_ORDER ? ascSortingSelected : AscSort} className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingSelection(event, UPDATEDON_KEY, ASCENDING_ORDER)} />
                                              <img src={sortKey === UPDATEDON_KEY && sortOrder === DESCENDING_ORDER ? descSortingSelected : DescSort} className="sortingImages" onClick={(event) => setSortingSelection(event, UPDATEDON_KEY, DESCENDING_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                        <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, TICKET_STATUS_KEY)}>STATUS</span>
                                            <Grid className="sort-icon-margin">
                                              <img src={sortKey === TICKET_STATUS_KEY && sortOrder === ASCENDING_ORDER ? ascSortingSelected : AscSort} className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingSelection(event, TICKET_STATUS_KEY, ASCENDING_ORDER)} />
                                              <img src={sortKey === TICKET_STATUS_KEY && sortOrder === DESCENDING_ORDER ? descSortingSelected : DescSort} className="sortingImages" onClick={(event) => setSortingSelection(event, TICKET_STATUS_KEY, DESCENDING_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                        <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon">
                                            <span className="login-history-dp-name font-wt-900">Actions</span>
                                            
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {data && 0 != data.length ? (
                                <TableBody className="tableBody usage-tableBody">

                                    {
                                        data && data.map((row, i) =>
                                            <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr height40 ticketsTableRowsPadding">
                                                <TableCell className="login-left-align inter-font ticketClick" onClick={()=>{  navigateToDeviceDetails(row, i)}}>
                                                  {/* <Link to={{
                                                    pathname: `/tickets/${row["k4Id"]}/${row["ticketId"]}`,
                                                    
                                                  }}
                                                    style={{ textDecoration: "none" }}
                                                  >
                                                    {row.ticketId}
                                                  </Link> */}
                                                   {row.ticketId}
                                                </TableCell>
                                                <TableCell className="login-left-align inter-font noWrap">{row.vessel ? row.vessel : "-"}</TableCell>
                                                <TableCell className="login-left-align inter-font noWrap">{row?.parentDP ? row?.parentDP : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font">
                                                  <Tooltip arrow title={<span className="font-12">{row.ticketSubject}</span>}>
                                                    <span className="wrappingContent">{row.ticketSubject}</span>
                                                  </Tooltip>
                                                </TableCell>
                                                <TableCell className="login-left-align inter-font noWrap">{convertDateTimeIntoTimezone(row.createdOn, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                                <TableCell className="login-left-align inter-font noWrap">{row.creator}</TableCell>
                                                <TableCell className='login-left-align inter-font text-center'>{row.assignee}</TableCell>
                                                <TableCell className="login-left-align inter-font text-center">{row.priority}</TableCell>
                                                <TableCell className="login-left-align inter-font noWrap">{convertDateTimeIntoTimezone(row.updatedOn, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                                <TableCell className='login-left-align inter-font'>{row.status.toUpperCase()}</TableCell>
                                                <TableCell className='login-left-align inter-font'>
                                                {
                                                  row.status.toLowerCase() === "open" && createTicketVesselList.some((vessel) => vessel['id'] === row.k4Id)
                                                    ?
                                                    <Button className="markAsClosed cursorPointer marg-left-20" onClick={() => {
                                                      setConfirmDialog({
                                                        isOpen: true,
                                                        title: CONFIRM_DIALOG_TITLE,
                                                        subTitle: MARK_AS_CLOSED_CONFIRM_DIALOG_SUBTITLE,
                                                        onConfirm: () => { markAsClosed(row.k4Id, row.ticketId) }
                                                      })
                                                    }
                                                    }>
                                                      Mark as closed
                                                    </Button>
                                                    : ""
                                                }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                  </div>
            </Grid>
          </Grid>
      }

      <TicketHotVessel open={hotVesselDialog} onCloseDialog={() => closeHotVessel()} onClose={() => { setHotVesselDialog(false) }} hotVesselSelection={hotVesselSelection} />
      <FilterTicketDialog isMoreFiltersActive={isMoreFiltersActive} selection={moreFiltersSelection} moreFiltersOkClick={handleMoreFiltersOkClick} assigneeList={allAgentList} moreFiltersClearAllClick={handleMoreFiltersClearAllClick} open={moreFiltersShowPopUP} onClose={() => setMoreFiltersShowPopUP(false)} />
      <TicketDetailsDialog ticketInfo={detailsPageClickedTicketInfo} clearTicketInfo={clearDetailsPageClickedTicketInfo} closeTicket={markAsClosed} createTicketVesselList={createTicketVesselList} assigneeList={allAgentList} autoCompleteNoOptionsText={AUTO_COMPLETE_NO_OPTIONS_TEXT} open={ticketDetailsShowPopUP} setConfirmDialog={setConfirmDialog} onClose={() => setTicketDetailsShowPopUP(false)} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      {/* {
        authReducer.loading ? (
          <CustomLoader
            showLoader={true}
            loadingText={
              loaders["loadingText"]
                ? loaders["loadingText"]
                : loadingTextOnUI
            }
          />
        ) : null
      } */}

    </div >
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  filteredVessels: state.authReducer?.filteredVessels,
  newSummaryStartDate: state.authReducer.newSummaryStartDate,
  newSummaryEndDate: state.authReducer.newSummaryEndDate,
  
});

export default withRouter(
  connect(mapStateToProps, {
    getTickets, getTicketsByFilter,
    updateTicket, getTicketAllAgents, addAttachments, downloadAsCSV
  })(TicketTable)
);