import { withRouter, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Collapse, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getDecodeURI, getEncodedURI, readableBytesAsGB, redirectToTopolgyTabs, getDataFromLocalStorageParsed } from "../../utils/util";
import CircleIcon from '@mui/icons-material/Circle';
import _ from "lodash";
import EdgeLinkIcon from "../../asset/image/EdgeLinkIcon.svg"
import EdgeLinkIconDisabled from "../../asset/image/linkDisabled.svg";
import { INTERVALS } from "../../constants/Constants";
import ConfirmDialog from "../../components/AcoordionPopUp";
import { clearData, getGeneratedLinks } from "../../components/RemoteConnections/remoteConnectionSlice";
import { displayToastError } from "../../server/request";
import { getHubDataClietTable, getHubDashboardClietTableData } from '../../actions/Users/authenticateHub';
import ClientsData from './ClientPopup';
import { GET_HUB_DASHBOARD_CLIENT_TABLE, GET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE } from "../../actions/types";

const Row = (props) => {
    const { row, index, location, getGeneratedLinks, getHubDataClietTable, hubDataClientTable, setClientTableLoading, userTimezone, history, getHubDashboardClietTableData, carrierHubDataClientTable } = props;

    let _q = getDecodeURI(location?.search);
    const [open, setOpen] = React.useState(false);
    const [wanProfilePopup, setWanProfilePopup] = useState(false);
    const [interval, setInterval] = useState(_q.hasOwnProperty('interval') ? {label: _q.interval == '15mins' ? '15m' : _q.interval, value: _q.interval} : INTERVALS[1]);
    const [eachRow, setEachrow] = useState();
    const [clientData, setClientData] = useState([]);
    const [clientDataPopup, setClientDataPopup] = useState(false);
    const [selectedSite, setSelectedSite] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (index == 0) {
            setOpen(!open)
        }
    }, [])

    useEffect(() => {
        if (!_.isEmpty(hubDataClientTable)) {
            const data = hubDataClientTable.hasOwnProperty('data') ? hubDataClientTable.data : {};
            setClientData(data);
        }
        if (!_.isEmpty(carrierHubDataClientTable)) {
            const data = carrierHubDataClientTable.hasOwnProperty('data') ? carrierHubDataClientTable.data : {};
            setClientData(data);
        }
    }, [hubDataClientTable, carrierHubDataClientTable])

    const handleOpenPopup = (row) => {
        setEachrow(row);
        setWanProfilePopup(true);
    }

    const handleClosePopUp = () => {
        setWanProfilePopup(false);
    }

    const EDGE_PORTAL_ADDRESS = "127.0.0.1";
    const EDGE_PORTAL_PORT = 80;

    const onConnectToEdge = async (e, deviceId: any, selectedVessel) => {
        e.preventDefault()
        let resp:any = await getGeneratedLinks(selectedVessel, deviceId, { address: [EDGE_PORTAL_ADDRESS] })
        if (!_.isEmpty(resp?.result)) {
            let [edgeLink] = resp.result?.filter(rc => {
                return rc?.address === EDGE_PORTAL_ADDRESS && rc.port === EDGE_PORTAL_PORT && rc.alias.includes("Portal")
            });
            if (edgeLink && edgeLink.url) {
              let url = edgeLink.url;
              const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
              let edgeToken = loggedInUser?.data?.edge_token;
              if (!_.isEmpty(edgeToken)) {
                let objUrl = new URL(`/login?token=${edgeToken}`, `${url}`)
                url = objUrl?.href
              }
              window.open(url)
              clearData()
            }
        } else {
            displayToastError('No remote connection link found')
        }
    }

    const handleOpenClientData = (row, hub, hubId) => {
        const _interval = _q.interval == '15mins' ? '15m' : _q.interval ? _q.interval : '1h';
        if(row.service == 'AGENT') {
            getHubDashboardClietTableData(row.server_location_id, row.client_device_id, _interval)
        } else {
            getHubDataClietTable(row.server_location_id, row.client_device_id, _interval);
        }
        setClientData([])
        setClientDataPopup(true);
        setSelectedSite({name: row.sitename, id: row.k4Id, service: row.service, siteId: row.server_location_id, agentId: row.client_device_id, hub_name: hub, hubId: hubId});
    }

    const redirectToHubTopology = (row, hub, hubId) => {
        let params = getDecodeURI(location?.search);
        params.siteId = row.server_location_id;
        params.agentId = row.client_device_id;
        params.hub_name = hub;
        params.hub_id = hub;
        delete params.pageStart;
        delete params.hubK4Ids;
        history.push({ pathname: '/fleet-dashboard/hub/topology', search: `?${getEncodedURI(params)}` });
    }

    return (
        <React.Fragment>
            <TableRow className="each-row-background">
                <TableCell className={open ? "accordian-expand no-padding" : 'accordian-expand remove-border-btm'} id={index != 0 ? 'hub-add-padding-top' : ''}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon className="accordian-arrow" /> : <KeyboardArrowRightIcon className="accordian-arrow" />}
                    </IconButton>
                </TableCell>
                <TableCell className={open ? "audit-logs-font padding-left-right" : "audit-logs-font padding-left-right remove-border-bottom-hub-cell"} id={index != 0 ? 'hub-add-padding-top' : ''} align="left">
                    <Tooltip title={row.sitename}><Link to={redirectToTopolgyTabs(location, "/fleet-dashboard/sites/topology", row?.k4Id, row?.sitename, {}, true, false)} className='hub-redirectToOtherTabs pointerCursor'
                    >{row.sitename}</Link></Tooltip>
                </TableCell>
                <TableCell className={open ? "audit-logs-font padding-left-right" : "audit-logs-font padding-left-right remove-border-bottom-hub-cell"} id={index != 0 ? 'hub-add-padding-top' : ''} align="left">
                    <Tooltip title={row.Organization}><span>{row.Organization}</span></Tooltip>
                </TableCell>
                <TableCell className={open ? "audit-logs-font padding-left-right" : "audit-logs-font padding-left-right remove-border-bottom-hub-cell"} id={index != 0 ? 'hub-add-padding-top' : ''} align="left">
                    <Tooltip title={row.location}><Link to={row?.location && redirectToTopolgyTabs(location, "/vessel-map", row?.k4Id, row?.sitename, {}, true, false)} className={row.location ? 'hub-redirectToOtherTabs' : 'remove-cursor-locationClick'}>
                        {row?.location ? row.location : "-"}
                    </Link></Tooltip>
                </TableCell>
                <TableCell className={open ? "audit-logs-font padding-left-right" : "audit-logs-font padding-left-right remove-border-bottom-hub-cell"} id={index != 0 ? 'hub-add-padding-top' : ''} align="left">
                    <Tooltip title={row.device_name}><span>{row.device_name}</span></Tooltip>
                </TableCell>
                <TableCell className={open ? "audit-logs-font padding-left-right" : "audit-logs-font padding-left-right remove-border-bottom-hub-cell"} id={index != 0 ? 'hub-add-padding-top' : ''} align="left">{readableBytesAsGB(row.totalUsage, false)}</TableCell>
                {row.service == 'CARRIERHUB' ? <TableCell className={open ? "audit-logs-font padding-left-right" : "audit-logs-font padding-left-right remove-border-bottom-hub-cell"} id={index != 0 ? 'hub-add-padding-top' : ''} align="left">-</TableCell> :
                <TableCell className={open ? "audit-logs-font padding-left-right" : "audit-logs-font padding-left-right remove-border-bottom-hub-cell"} id={index != 0 ? 'hub-add-padding-top' : ''} align="left">{Math.round(row.availibility)}</TableCell>}
                <Tooltip title={row.throughput ? `${(row.throughput).toFixed(2)}` : null}>
                    <TableCell className={open ? "audit-logs-font padding-left-right" : "audit-logs-font padding-left-right remove-border-bottom-hub-cell"} id={index != 0 ? 'hub-add-padding-top' : ''} align="left">{row.throughput ? row.throughput.toFixed(2) : '-'}</TableCell>
                </Tooltip>
                <TableCell className={open ? "audit-logs-font padding-left-right" : "audit-logs-font padding-left-right remove-border-bottom-hub-cell"} id={index != 0 ? 'hub-add-padding-top' : ''} align="left">{row.clients ? row.clients : '-'}</TableCell>
                <TableCell className={open ? "audit-logs-font padding-left-right" : "audit-logs-font padding-left-right remove-border-bottom-hub-cell"} id={index != 0 ? 'hub-add-padding-top' : ''} align="left">
                    <div className="hub-row-internetStatus hub-redirectToOtherTabs">
                        <CircleIcon id={row.Internet_status == 'Online' ? "greenDotSymbol" : row.Internet_status == 'Offline' ? "redDotSymbol" : "greyDotSymbol"} />
                        <span onClick={() => handleOpenPopup(row)}>{row.Internet_status ? row.Internet_status : 'Unknown'}</span>
                    </div>
                </TableCell>
                <TableCell className={open ? "audit-logs-font padding-left-right" : "audit-logs-font padding-left-right remove-border-bottom-hub-cell"} id={index != 0 ? 'hub-add-padding-top' : ''} align="left">{row.client_status ? row.client_status : '-'}</TableCell>
                <TableCell className={open ? "audit-logs-font padding-left-right" : "audit-logs-font padding-left-right remove-border-bottom-hub-cell"} id={index != 0 ? 'hub-add-padding-top' : ''} align="left">{row.tunnels_status ? row.tunnels_status : 0}</TableCell>
                <TableCell className={open ? "audit-logs-font padding-left-right" : "audit-logs-font padding-left-right remove-border-bottom-hub-cell"} id={index != 0 ? 'hub-add-padding-top' : ''} align="center">
                    {row.konnect == 'STATUS_CONNECTED' ?
                        <a className="vessel_table_cell--portal_link" onClick={(e) => onConnectToEdge(e, row?.parent_DevivceID, row?.k4Id)}>
                            <img src={EdgeLinkIcon} title="Portal" />
                        </a> :
                        <a>
                            <img src={EdgeLinkIconDisabled} title="Portal" />
                        </a>
                    }
                </TableCell>
            </TableRow>
            {open && <TableRow className="hub-empty-table-row">
                <TableCell className="hub-empty-table-cell" colSpan={13} />
            </TableRow>}
            {row?.eachRow?.length > 0 ? row?.eachRow?.map((eachRow, i) => (
                <TableRow className="padding-control hub-expanded-row" id={i == 0 ? 'hub-expanded-row-tr' : ''}>
                    <TableCell className={!open ? "no-padding-top-bottom" : ''} id="expand-icon-border">
                        <Collapse in={open} timeout="auto" unmountOnExit>

                        </Collapse>
                    </TableCell>
                    <TableCell className={!open ? "no-padding-top-bottom" : 'padding-left-right'}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {eachRow.service == 'AGENT' ? <Tooltip title={eachRow.device_name}>
                                <span onClick={() => redirectToHubTopology(eachRow, row.sitename, row.server_location_id)} className='hub-redirectToOtherTabs pointerCursor'
                                >{eachRow.device_name ? eachRow.device_name : '-'}</span>
                            </Tooltip> : <Tooltip title={eachRow.sitename}>
                                <Link to={redirectToTopolgyTabs(location, "/fleet-dashboard/sites/topology", eachRow?.k4Id, eachRow?.sitename, {}, true, false)} className='hub-redirectToOtherTabs'
                                >{eachRow.sitename ? eachRow.sitename : '-'}</Link>
                            </Tooltip>}
                        </Collapse>
                    </TableCell>
                    <TableCell className={!open ? "no-padding-top-bottom" : 'padding-left-right'}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Tooltip title={eachRow.Organization}><span>{eachRow.Organization ? eachRow.Organization : '-'}</span></Tooltip>
                        </Collapse>
                    </TableCell>
                    <TableCell className={!open ? "no-padding-top-bottom" : 'padding-left-right'}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Tooltip title={eachRow.location}>
                                <Link to={eachRow?.location && redirectToTopolgyTabs(location, "/vessel-map", eachRow?.k4Id, eachRow?.sitename, {}, true, false)} className={eachRow.location ? 'hub-redirectToOtherTabs' : 'remove-cursor-locationClick'}>
                                    {eachRow?.location ? eachRow.location : "-"}
                                </Link>
                            </Tooltip>
                        </Collapse>
                    </TableCell>
                    <TableCell className={!open ? "no-padding-top-bottom" : 'padding-left-right'}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Tooltip title={eachRow.device_name}>
                                {eachRow.service == 'AGENT' ? <span onClick={() => redirectToHubTopology(eachRow, row.sitename, row.server_location_id)} className='hub-redirectToOtherTabs'>{eachRow.device_name ? eachRow.device_name : '-'}</span> : <span>{eachRow.device_name ? eachRow.device_name : '-'}</span>}
                                </Tooltip>
                        </Collapse>
                    </TableCell>
                    <TableCell className={!open ? "no-padding-top-bottom" : 'padding-left-right'}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {readableBytesAsGB(eachRow.totalUsage, false)}
                        </Collapse>
                    </TableCell>
                    <TableCell className={!open ? "no-padding-top-bottom" : 'padding-left-right'}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {eachRow.service == 'AGENT' ? '-' : Math.round(eachRow.availibility)}
                        </Collapse>
                    </TableCell>
                    <TableCell className={!open ? "no-padding-top-bottom" : 'padding-left-right'}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Tooltip title={eachRow.throughput ? eachRow.throughput.toFixed(2) : null}><span>{eachRow.throughput ? eachRow.throughput.toFixed(2) : '-'}</span></Tooltip>
                        </Collapse>
                    </TableCell>
                    <TableCell className={!open ? "no-padding-top-bottom" : 'padding-left-right'}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {eachRow.clients ? eachRow.clients : '-'}
                        </Collapse>
                    </TableCell>
                    <TableCell className={!open ? "no-padding-top-bottom" : 'padding-left-right'}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <div className="each-row-content-status">
                                {eachRow.service == 'AGENT' ? <div>
                                    <CircleIcon id={eachRow.client_status == 1 ? "greenDotSymbol" : eachRow.client_status == 0 ? "redDotSymbol" : "greyDotSymbol"} />
                                    {eachRow.client_status == 1 ? 'Online' : eachRow.client_status == 0 ? 'Offline' : 'Unknown'}
                                </div> : <div className="hub-row-internetStatus hub-redirectToOtherTabs" onClick={() => handleOpenPopup(eachRow)}>
                                    <CircleIcon id={eachRow.Internet_status == 'Online' ? "greenDotSymbol" : eachRow.Internet_status == 'Offline' ? "redDotSymbol" : "greyDotSymbol"} />
                                    {eachRow.Internet_status == 'Online' ? 'Online' : eachRow.Internet_status == 'Offline' ? 'Offline' : 'Unknown'}
                                </div>}
                            </div>
                        </Collapse>
                    </TableCell>
                    <TableCell className={!open ? "no-padding-top-bottom" : 'padding-left-right'}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <div className="each-row-content-status">
                                <div className="hub-row-internetStatus hub-redirectToOtherTabs" onClick={() => handleOpenClientData(eachRow, row.sitename, row.server_location_id)}>
                                    <CircleIcon id={eachRow.client_status == 1 ? "greenDotSymbol" : eachRow.client_status == 0 ? "redDotSymbol" : "greyDotSymbol"} />
                                    {eachRow.client_status == 1 ? 'Online' : eachRow.client_status == 0 ? 'Offline' : 'Unknown'}
                                </div>
                            </div>
                        </Collapse>
                    </TableCell>
                    <TableCell className={!open ? "no-padding-top-bottom" : 'padding-left-right'}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <span>{eachRow.tunnels_status ? eachRow.tunnels_status : 0}</span>
                        </Collapse>
                    </TableCell>
                    <TableCell align="center" className={!open ? "no-padding-top-bottom" : 'padding-left-right'}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {eachRow.konnect == 'STATUS_CONNECTED' ?
                                <a className="vessel_table_cell--portal_link">
                                    <img src={EdgeLinkIcon} title="Portal" onClick={(e) => onConnectToEdge(e, eachRow?.parent_DevivceID, eachRow?.k4Id)} />
                                </a> :
                                <a>
                                    <img src={EdgeLinkIconDisabled} title="Portal" />
                                </a>
                            }
                        </Collapse>
                    </TableCell>
                </TableRow>
            )) : <TableRow>
                {open && <TableCell colSpan={12} align='center' className="audit-logs-no-data clients-no-data">
                    No Clients Connected
                </TableCell>}
            </TableRow>
            }
            {open && <TableRow className="hub-empty-table-row">
                <TableCell colSpan={12} className="hub-empty-table-cell" />
            </TableRow>}
            {wanProfilePopup && <ConfirmDialog confirmDialog={{isOpen: wanProfilePopup}} row={eachRow} handleClosePopUp={handleClosePopUp} interval={interval} />}
            {clientDataPopup && <ClientsData clientData={clientData} open={clientDataPopup} setOpen={setClientDataPopup} loading={setClientTableLoading} selectedSite={selectedSite} location={location} userTimezone={userTimezone} />}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    hubDataClientTable: state.authReducer.hubDataClientTable,
    setClientTableLoading: state.authReducer.setHubDataClientTableLoading,
    userTimezone: state.authReducer.userTimezone,
    carrierHubDataClientTable: state.authReducer.carrierHubDataClientTable,
});

export default withRouter(
    connect(mapStateToProps, { getGeneratedLinks, getHubDataClietTable, getHubDashboardClietTableData })(Row)
);