import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Grid } from "@mui/material";
import { CONFIRM_DIALOG_TITLE } from "../../config";
import _ from "lodash";
import { upgradeWarehouseDevicetoLatestEOSversion } from "../../actions/Users/authenticateInventory";

const UpgradeWarehouseDevice = (props) => {
    const { setOpen, selectedRow, selectedDeviceLatestEosVersion, setConfirmDialog, upgradeWarehouseDevicetoLatestEOSversion } = props;
    return (
        <Grid className="Form">
            <Grid container className="device-system-config">
                <Grid container>
                    <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Device</Grid>
                    <Grid xs={5} sm={5} md={5} lg={5} className="content-right" title={selectedRow?.deviceId}>{selectedRow?.deviceId}</Grid>
                </Grid>
                <Grid container>
                    <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Latest EOS Version</Grid>
                    <Grid xs={5} sm={5} md={5} lg={5} className="content-right action">
                        {
                            !_.isEmpty(selectedRow) && selectedRow?.deviceId
                            ? <span>{selectedDeviceLatestEosVersion}</span>
                            : null
                        }
                    </Grid>
                    <Grid xs={3} sm={3} md={3} lg={3} className="content-right action">
                        <Button className="action-button"
                            onClick={() => {
                                setConfirmDialog({
                                    isOpen: true,
                                    title: CONFIRM_DIALOG_TITLE,
                                    subTitle: "Are you sure to upgrade the device " + selectedRow?.deviceId + " to latest " + selectedDeviceLatestEosVersion + " ?",
                                    onConfirm: () => {
                                        upgradeWarehouseDevicetoLatestEOSversion(selectedRow?.k4DpId, selectedRow?.deviceId)
                                        setOpen(false)
                                    },
                                })
                            }}
                        >Upgrade</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser,
});

export default withRouter(
    connect(mapStateToProps,
        { upgradeWarehouseDevicetoLatestEOSversion }
    )(UpgradeWarehouseDevice)
);