import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dialog, DialogProps, DialogTitle, DialogContent, Grid, TextField, Box, Button, DialogActions, IconButton, Select, MenuItem, InputLabel, FormControl, Autocomplete, Checkbox} from "@mui/material";
import { OnSaveContentFilterParameters } from "../ContentFilterTable";
import "./CFTrafficPolicyDialog.css";
import close from "../../../asset/image/close.svg";
import {ContentFilter, Category, Domain} from "./../../../UserScreen/CustomContentFilter/types"
import _, { drop } from "lodash"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {getTrafficPolicyDataV3, getTrafficPolicyDataV4, getTrafficPolicyDataV5} from "./../../../actions/Users/authenticateInventory"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { applyTrafficPolicy, getContentFilterLogs } from "../../../UserScreen/CustomContentFilter/slice";
interface ContentFilterDialogType {
    authReducer:any,
    errorReducer:any,
    open:any,
    onClose: ()=>{},
    contentFilterToUpdate: ContentFilter,
    onSave:(OnSaveContentFilterParameters)=>{},
    contentFilterName: string,
    contentFilterId: string,
    deviceList: any,
    groupList: any,
    getTrafficPolicyDataV3: any,
    getTrafficPolicyDataV4: any,
    getTrafficPolicyDataV5: any,
    inventoryGroupsList: any;
    applyTrafficPolicy: any;
    appliedTrafficPolicyDetails: any;
    getContentFilterLogs: any;
}

const SITES = "Site"
const DEVICES = "Device"
const MANAGED_GROUPS = "Managed Group"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface TrafficPolicyData {
    index: any;
    target_type: string;
    resources: string[];
    traffic_policies: string[];
}

function CFTrafficPolicyDialog({authReducer, errorReducer, open, onClose, contentFilterName, contentFilterId, deviceList, groupList, getTrafficPolicyDataV3, getTrafficPolicyDataV4, getTrafficPolicyDataV5, inventoryGroupsList, applyTrafficPolicy, appliedTrafficPolicyDetails, getContentFilterLogs}:ContentFilterDialogType) {
    
    const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');
    const [dropdownGroups, setDropdownGroups] = useState<TrafficPolicyData[]>([]);

    useEffect(()=>{
        if(dropdownGroups.length == 0){
            let objTrafficPolicyData : TrafficPolicyData = {
                index: 1,
                target_type:"",
                resources:[],
                traffic_policies:[]
            }
            setDropdownGroups([objTrafficPolicyData])
        }
    }, [dropdownGroups])

    useEffect(() => {
        if (!_.isEmpty(appliedTrafficPolicyDetails) && appliedTrafficPolicyDetails?.[0].hasOwnProperty('resources') && appliedTrafficPolicyDetails?.[0].hasOwnProperty('traffic_policies')) {
            const updatedAppliedTrafficPolicyDetails = appliedTrafficPolicyDetails?.map((tp, i) => {
                return {
                    index: i + 1,
                    target_type: tp?.target_type,
                    resources: tp?.resources?.map(rs => rs.name)?.filter(rs => rs!=''),
                    traffic_policies: tp?.traffic_policies
                }
            })
            setDropdownGroups(updatedAppliedTrafficPolicyDetails)
        }
    }, [appliedTrafficPolicyDetails])

    const onCancel = ()=>{
        let objTrafficPolicyData : TrafficPolicyData = {
            index: 1,
            target_type:"",
            resources:[],
            traffic_policies:[]
        }
        setDropdownGroups([objTrafficPolicyData])
        onClose()
    }

    const addMoreTrafficPolicy = ()=>{
        let lastObj = dropdownGroups[dropdownGroups.length - 1]
        let index = lastObj.index
        let objTrafficPolicyData : TrafficPolicyData = {
            index: index + 1,
            target_type:"",
            resources:[],
            traffic_policies:[]
        }
        setDropdownGroups((prev) => [...prev, objTrafficPolicyData]);
    }
    
    const removeTrafficPolicyFromList = (index)=>{
        for(var i=0;i<dropdownGroups.length;i++){
            if(dropdownGroups[i].index == index){
                dropdownGroups.splice(i, 1)
                setDropdownGroups([...dropdownGroups])
            }
        }
    }

    const getDeviceIdsPerSite = (names) => {
        let siteIds:any[] = []
        for (var i = 0; i < authReducer?.getVesselsListing?.locations.length; i++) {
            let siteName = authReducer?.getVesselsListing?.locations[i]["name"]
            if (names.includes(siteName)) {
                siteIds.push({name: authReducer?.getVesselsListing?.locations[i]["name"], id: authReducer?.getVesselsListing?.locations[i]["id"]})
            }
        }
        if(!_.isEmpty(siteIds)){
            let deviceIdList:any = []
            const deviceList = authReducer.getDeviceList?.data;
            deviceList.forEach(device => {
                siteIds
                    .filter(site => device.location_id === site?.id)
                    .forEach(() => {
                        const devices = device?.devices.map(d => ({
                            device_id: d.id,
                            site_id: device.location_id,
                            site_name: device.location_name
                        }));
            
                        deviceIdList.push(...devices);
                    });
            }); 
            return deviceIdList;
        }
        return []
    }

    const getSitesIdsPerDevice = (deviceNames) => {
        let deviceIds: any[] = []
        for (var i = 0; i < deviceList.length; i++) {
            let deviceName = deviceList[i]["name"]
            if (deviceNames.includes(deviceName)) {
                deviceIds.push({name: deviceList[i]["name"], id: deviceList[i]["id"]})
            }
        }
        if(!_.isEmpty(deviceIds)){

            let deviceIdList:any = []

            const data = authReducer.getDeviceList?.data;

            deviceIds.forEach(selectedDevice => {
                data.forEach(item => {
                    const devices = item?.devices.map(device => device.id);
                    if (devices.includes(selectedDevice.id)) {
                        const deviceObj = {
                            device_name: selectedDevice.name,
                            device_id: selectedDevice.id,
                            site_id: item?.location_id,
                        };
            
                        deviceIdList.push(deviceObj);
                    }
                });
            });
            return deviceIdList;
        }
        return []
    }

    const getDeviceIdsPerGroup = (groups) => {
        let groupsList: any[] = []
        for (var i = 0; i < groupList.length; i++) {
            let deviceName = groupList[i]["groupName"]
            if (groups.includes(deviceName)) {
                groupsList.push({name: groupList[i]["groupName"], id: groupList[i]["groupId"]})
            }
        }
        if(!_.isEmpty(groupsList)){
            let deviceIdList:any = []
            let deviceList = authReducer.getDeviceList?.data;
            groupsList.map(selectedList => {
                inventoryGroupsList?.forEach(group => {
                    if(selectedList.name == group.name) {
                        const devices = group?.devices.split('+').map(d => d.split("|")[0]); 
                        
                        devices.forEach(deviceId => {
                            
                            const matchingDevice = deviceList.find(device => 
                                device.devices.some(d => d.id === deviceId)
                            );
                            
                            if (matchingDevice) {
                                const selectedDeviceObj:any = {
                                    device_id: deviceId,
                                    group_id: group?.id,
                                    group_name: group?.name,
                                    site_id: matchingDevice.location_id
                                };
                                deviceIdList.push(selectedDeviceObj);
                            }
                        });
                    }
                });
            })
            return deviceIdList
        }
        return []
    }

    const setTypeValue = (index, value)=>{
        for(var i=0;i<dropdownGroups.length;i++){
            if(dropdownGroups[i].index == index){
                dropdownGroups[i].target_type = value
                setDropdownGroups([...dropdownGroups])
            }
        }
    }

    const setTrafficPoliciesValue = (index, value)=>{
        for(var i=0;i<dropdownGroups.length;i++){
            if(dropdownGroups[i].index == index){
                dropdownGroups[i].traffic_policies = value
                setDropdownGroups([...dropdownGroups])
            }
        }
    }
    const setTrafficPolicyResourceValue = (index, value)=>{
        for(var i=0;i<dropdownGroups.length;i++){
            if(dropdownGroups[i].index == index){
                dropdownGroups[i].resources = value
                setDropdownGroups([...dropdownGroups])
            }
        }
    }
    
    const generateDeviceIdsData = (dropdownGroups, getDeviceIdsPerSite, getSitesIdsPerDevice, getDeviceIdsPerGroup) => {
        const deviceIdsData: any[] = [];
        const appliedTrafficPolicyData: any[] = [];

        for (const group of dropdownGroups) {
            const { target_type, resources, traffic_policies } = group;

            if (target_type === "Site") {
                const siteIds: string[] = [];
                for (const site of resources) {
                    const devices = getDeviceIdsPerSite(site);
                    let siteId:any = {};
                    for (const device of devices) {
                        for (const policy of traffic_policies) {
                            siteId = {name: site, id: device.site_id};
                            deviceIdsData.push({
                                dp_id: authReducer.selectedOu?.id,
                                site_id: device.site_id,
                                device_id: device.device_id,
                                traffic_policy_name: policy.name,
                            });
                        }
                    }
                    siteIds.push(siteId);
                }
                if (siteIds.length > 0) {
                    appliedTrafficPolicyData.push({
                        target_type: "Site",
                        resources: siteIds,
                        traffic_policies: traffic_policies,
                    });
                }
            } else if (target_type === "Device") {
                const deviceIds: string[] = [];
                for (const device of resources) {
                    const siteIds = getSitesIdsPerDevice(device);
                    let deviceId:any = {};
                    for (const site_id of siteIds) {
                        for (const policy of traffic_policies) {
                            deviceId = {name: device, id: site_id.device_id}
                            deviceIdsData.push({
                                dp_id: authReducer.selectedOu?.id,
                                site_id: site_id.site_id,
                                device_id: site_id.device_id,
                                traffic_policy_name: policy.name,
                            });
                        }
                    }
                    deviceIds.push(deviceId)
                }
                if (deviceIds.length > 0) {
                    appliedTrafficPolicyData.push({
                        target_type: "Device",
                        resources: deviceIds,
                        traffic_policies: traffic_policies,
                    });
                }
            } else if (target_type === "Managed Group") {
                const groupIds: string[] = [];
                for (const groupResource of resources) {
                    const groupData = getDeviceIdsPerGroup(groupResource);
                    let groupId:any = {};
                    for (const data of groupData) {
                        for (const policy of traffic_policies) {
                            groupId = {name: groupResource, id: data.group_id};
                            deviceIdsData.push({
                                dp_id: authReducer.selectedOu?.id,
                                site_id: data.site_id,
                                device_id: data.device_id,
                                traffic_policy_name: policy.name,
                            });
                        }
                    }
                    groupIds.push(groupId)
                }
                if (groupIds.length > 0) {
                    appliedTrafficPolicyData.push({
                        target_type: "Managed Group",
                        resources: groupIds,
                        traffic_policies: traffic_policies,
                    });
                }
            }
        }

        return {
            device_ids_data: deviceIdsData,
            applied_traffic_policy_data: appliedTrafficPolicyData,
        };
    }

    const refreshLogsTableOnApply = () => {
        getContentFilterLogs(1, 10, 'created_at', 'desc', '');
    };
    
    const onApply = async () => {
        const formattedData = generateDeviceIdsData(
            dropdownGroups,
            getDeviceIdsPerSite,
            getSitesIdsPerDevice,
            getDeviceIdsPerGroup
        );
        try {
            await applyTrafficPolicy(
                authReducer.selectedOu?.id,
                contentFilterId,
                JSON.stringify(formattedData, null, 2)
            );
            refreshLogsTableOnApply();
        } catch (error) {
            console.error('Failed to apply traffic policy:', error);
        } finally {
            onClose();
        }
    };



    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                scroll={scroll}
                onClose={onClose}
                maxWidth="md"
                fullWidth
                className='config-create-content-filter-popup'
            >
            <div>
                <DialogTitle className='create-default-config-title'> 
                    <span className="content-filter-title-name"> Apply Content Filter on Traffic Policy </span>
                    <img src={close} title="Close" onClick={onCancel} className="create-content-filter-close-btn" />
                </DialogTitle>
                <DialogContent dividers={scroll ==='paper'} className='apply-content-filter-dialog-content'>
                    <div>
                        <Grid container xs={12} sm={12} md={12} lg={12}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <span>Content Filter</span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="apply-cf-dropdown">
                                <TextField
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    placeholder="Content Filter"
                                    disabled={true}
                                    value={contentFilterName}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12} sm={12} md={12} lg={12} className="traffic-policy-content">
                            {
                                dropdownGroups.map((value, index) => 
                                    <AddTrafficPolicy key={index} index={value.index} removeTrafficPolicyFromList={removeTrafficPolicyFromList} 
                                        deviceList={deviceList} authReducer={authReducer} groupList={groupList} getTrafficPolicyDataV3={getTrafficPolicyDataV3} getTrafficPolicyDataV4={getTrafficPolicyDataV4} getTrafficPolicyDataV5={getTrafficPolicyDataV5}
                                        setTypeValue={setTypeValue} setTrafficPoliciesValue={setTrafficPoliciesValue} setTrafficPolicyResourceValue={setTrafficPolicyResourceValue} selectedRow={value}
                                    >    
                                    </AddTrafficPolicy>
                                )
                            }
                        </Grid>
                        <Grid container xs={12} sm={12} md={12} lg={12}>
                            <Button variant="outlined" startIcon={<AddCircleOutlineIcon/>} className="content_filter--button_outlined_filter" onClick={addMoreTrafficPolicy}>
                                Add
                            </Button>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions className="apply-traffic-policy-dialog-actions">
                    <Button variant="outlined" className="content_filter--button_outlined_filter" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" className="content_filter-footer-create-button" onClick={onApply}>
                        Apply
                    </Button>
                </DialogActions>
            </div>
            </Dialog>
        </div>
    )
}

const AddTrafficPolicy = (props)=>{
    const {index, removeTrafficPolicyFromList, deviceList, authReducer, groupList, getTrafficPolicyDataV3, getTrafficPolicyDataV4, getTrafficPolicyDataV5, setTypeValue, setTrafficPoliciesValue, setTrafficPolicyResourceValue, selectedRow} = props
    const [type, setType] = useState("")
    const [trafficPoliciesResources, setTrafficPoliciesResources] = useState<any>([])
    const [trafficPoliciesResourcesOptions, setTrafficPoliciesResourcesOptions] = useState<any>([])
    const [trafficPolicies, setTrafficPolicies] = useState<any>([])
    const [trafficPoliciesOptions, setTrafficPoliciesOptions] = useState<any>([])
    const [selectedSites, setSelectedSites] = useState<any>([])
    const [selectedDevices, setSelectedDevices] = useState<any>([])
    const [selectedGroups, setSelectedGroups] = useState<any>([])
    const [filterValue, setFilterValue] = useState<any>("")
    const [filterTrafficPolicyValue, setFilterTrafficPolicyValue] = useState<any>("")
    
    useEffect(() => {
        if(!_.isEmpty(selectedRow)) {
            setType(selectedRow?.target_type);
            setTrafficPoliciesResources(selectedRow?.['resources'])
            setTrafficPolicies(selectedRow?.['traffic_policies'])
            if (selectedRow?.target_type == 'Site') {
                if ((authReducer?.getVesselsListing?.locations != undefined || authReducer?.getVesselsListing?.locations != null) && authReducer?.getVesselsListing?.locations.length > 0) {
                    let sites = authReducer?.getVesselsListing?.locations.map((value, index) => value.name)
                    setTrafficPoliciesResourcesOptions(sites)
                } else {
                    setTrafficPoliciesResourcesOptions([])
                }
            } else if (selectedRow?.target_type == 'Device') {
                if (deviceList != null || deviceList != undefined) {
                    let deviceNames = deviceList.map((device, index) => device.name)
                    setTrafficPoliciesResourcesOptions(deviceNames)
                } else {
                    setTrafficPoliciesResourcesOptions([])
                }
            } else if (selectedRow?.target_type == 'Managed Group') {
                if ((groupList != undefined || groupList != null) && groupList.length > 0) {
                    let groupsNameList = groupList.map((value, index) => value.groupName)
                    setTrafficPoliciesResourcesOptions(groupsNameList)
                } else {
                    setTrafficPoliciesResourcesOptions([])
                }
            }
        }
    }, [selectedRow])

    useEffect(()=>{
        if(!_.isEmpty(authReducer.getTrafficPolicyV3)){
            var data = authReducer.getTrafficPolicyV3["data"]
            if(data != null || data != undefined){
                let trafficPolicyNames: any[] = []
                for(var i=0;i<data.length;i++){
                    trafficPolicyNames.push({name: data[i]["name"], id: data[i]["id"]})
                }
                setTrafficPoliciesOptions((prev) => ({
                    ...prev,
                    "Device": trafficPolicyNames,
                }));
            }
        } else {
            setTrafficPoliciesOptions((prev) => ({
                ...prev,
                "Device": [],
            }));
        }
    }, [authReducer.getTrafficPolicyV3])
    useEffect(()=>{
        if(!_.isEmpty(authReducer.getTrafficPolicyV4)){
            var data = authReducer.getTrafficPolicyV4["data"]
            if(data != null || data != undefined){
                let trafficPolicyNames: any[] = []
                for(var i=0;i<data.length;i++){
                    trafficPolicyNames.push({name: data[i]["name"], id: data[i]["id"]})
                }
                setTrafficPoliciesOptions((prev) => ({
                    ...prev,
                    "Site": trafficPolicyNames,
                }));
            }
        } else {
            setTrafficPoliciesOptions((prev) => ({
                ...prev,
                "Site": [],
            }));
        }
    }, [authReducer.getTrafficPolicyV4])
    useEffect(()=>{
        if(!_.isEmpty(authReducer.getTrafficPolicyV5)){
            var data = authReducer.getTrafficPolicyV5["data"]
            if(data != null || data != undefined){
                let trafficPolicyNames: any[] = []
                for(var i=0;i<data.length;i++){
                    trafficPolicyNames.push({name: data[i]["name"], id: data[i]["id"]})
                }
                setTrafficPoliciesOptions((prev) => ({
                    ...prev,
                    "Managed Group": trafficPolicyNames,
                }));
            }
        } else {
            setTrafficPoliciesOptions((prev) => ({
                ...prev,
                "Managed Group": [],
            }));
        }
    }, [authReducer.getTrafficPolicyV5])
    
    useEffect(()=>{
        if(_.isEmpty(trafficPoliciesResources)) {
            setTrafficPoliciesOptions((prev) => ({
                ...prev,
                [type]: [],
            }));
        }
        if(!_.isEmpty(trafficPoliciesResources)){
            setTrafficPolicyResourceValue(index, trafficPoliciesResources)
            switch(type){
                case SITES:
                    let siteIds:any[] = []
                    for(var i=0;i<authReducer?.getVesselsListing?.locations.length;i++){
                        let siteName = authReducer?.getVesselsListing?.locations[i]["name"]
                        if(trafficPoliciesResources.includes(siteName)){
                            siteIds.push(authReducer?.getVesselsListing?.locations[i]["id"])
                        }
                    }

                    setSelectedSites([...siteIds])
                    break;
                case DEVICES:
                    let deviceIds:any[] = []
                    for(var i=0;i<deviceList.length;i++){
                        let deviceName = deviceList[i]["name"]
                        if(trafficPoliciesResources.includes(deviceName)){
                            deviceIds.push(deviceList[i]["id"])
                        }
                    }

                    setSelectedDevices([...deviceIds])
                    break;
                case MANAGED_GROUPS:
                    {
                        let devicesObj :any[] = []
                        let deviceIds:any[] = []
                        for(var i=0;i<groupList.length;i++){
                            let groupName = groupList[i]["groupName"]
                            if(trafficPoliciesResources.includes(groupName)){
                                devicesObj.push(...groupList[i]["devices"])
                            }
                        }

                        for(var i=0;i<devicesObj.length;i++){
                            deviceIds.push(devicesObj[i]["id"])
                        }

                        setSelectedGroups([...deviceIds])
                        break;
                    }
            }
        }
    }, [trafficPoliciesResources])

    useEffect(()=>{
        if(!_.isEmpty(trafficPolicies)){
            setTrafficPoliciesValue(index, trafficPolicies)
        } else {
            setTrafficPoliciesValue([]);
        }
    }, [trafficPolicies])

    useEffect(()=>{
        if(!_.isEmpty(selectedDevices)){
            let payload = { 
                query: "GET_TRAFFIC_POLICIES_V3", 
                "named": true,
                "format": "json", 
                "parameters": { 
                    "device_id": selectedDevices.map(x => "'" + x + "'").toString()
                } 
            }
            getTrafficPolicyDataV3(payload)
        }
    }, [selectedDevices])

    useEffect(()=>{
        if(!_.isEmpty(selectedSites)){
            let payload = { 
                query: "GET_TRAFFIC_POLICIES_V4", 
                "named": true,
                "format": "json", 
                "parameters": { 
                    "site_id": selectedSites.map(x => "'" + x + "'").toString()
                } 
            }
            getTrafficPolicyDataV4(payload)
        }
    }, [selectedSites])

    useEffect(()=>{
        if(!_.isEmpty(selectedGroups)){
            let payload = { 
                query: "GET_TRAFFIC_POLICIES_V3", 
                "named": true,
                "format": "json", 
                "parameters": { 
                    "device_id": selectedGroups.map(x => "'" + x + "'").toString()
                } 
            }
            getTrafficPolicyDataV5(payload)
        }
    }, [selectedGroups])

    const handleChangeType = (e:any, value)=>{
        setSelectedDevices([])
        setTrafficPoliciesOptions([])
        setTrafficPolicies([])
        setType(value)
        setTypeValue(index, value)
        switch(value) {
            case SITES:
                loadSites()
                break;
            case DEVICES:
                loadDevices()
                break;
            case MANAGED_GROUPS:
                loadManagedGroups()
                break;
        }
    }

    const loadSites = ()=>{
        if((authReducer?.getVesselsListing?.locations != undefined || authReducer?.getVesselsListing?.locations != null)  && authReducer?.getVesselsListing?.locations.length > 0) {
            let sites = authReducer?.getVesselsListing?.locations.map((value, index)=> value.name)
            setTrafficPoliciesResourcesOptions(sites)
            setTrafficPoliciesResources([])
        } else {
            setTrafficPoliciesResources([])
            setTrafficPoliciesResourcesOptions([])
        }
    }
    
    const loadDevices = ()=>{
        if(deviceList != null || deviceList != undefined){
            let deviceNames = deviceList.map((device, index)=> device.name)
            setTrafficPoliciesResourcesOptions(deviceNames)
            setTrafficPoliciesResources([])
        } else {
            setTrafficPoliciesResources([])
            setTrafficPoliciesResourcesOptions([])
        }
    }
    
    const loadManagedGroups = ()=>{
        if((groupList != undefined || groupList != null) && groupList.length > 0){
            let groupsNameList = groupList.map((value, index)=> value.groupName)
            setTrafficPoliciesResources([])
            setTrafficPoliciesResourcesOptions(groupsNameList)
        } else {
            setTrafficPoliciesResources([])
            setTrafficPoliciesResourcesOptions([])
        }
    }

    const removeTrafficPolicy = ()=>{
        removeTrafficPolicyFromList(index)
    }

    return (
        <Grid container xs={12} sm={12} md={12} lg={12} className="traffic-policy-container">
            <Grid item xs={3} sm={3} md={3} lg={3} className="traffic-policy-row">
                <Autocomplete
                    options={["Site", "Device", "Managed Group"]}
                    getOptionLabel={(option) => option}
                    sx={{ width: 200 }}
                    onChange={handleChangeType}
                    value={type}
                    renderInput={(params) => <TextField {...params} placeholder="Select Target Type" variant="outlined" size="small" />}
                    isOptionEqualToValue = {(option, value) => option === value}
                />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} className="traffic-policy-row">
                <Autocomplete
                    multiple
                    id="site-select-box"
                    options={["Select All", ...trafficPoliciesResourcesOptions]}
                    value={trafficPoliciesResources}
                    limitTags={3}
                    style={{ width: 270, margin: '0px' }}
                    disableCloseOnSelect
                    inputValue={filterValue}    
                    getOptionLabel={(option) => option}
                    renderOption={(prop, option, { selected }) => {
                        const index = prop['data-option-index'];
                        return (
                            <li {...prop} key={index}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option}
                            </li>
                        )
                    }}
                    renderInput={(params) => (
                        <TextField {...params} onChange={(e) => setFilterValue(e.target.value)} placeholder={"Select "+type} variant="outlined" size="small" />
                    )}
                    onChange={(e, value) => {
                        if (value.some((option) => option === "Select All")) {
                            if (trafficPoliciesResources.length === trafficPoliciesResourcesOptions.length) {
                                setTrafficPoliciesResources([]);
                            } else {
                                setTrafficPoliciesResources(trafficPoliciesResourcesOptions);
                            }
                            setTrafficPolicies([]);
                        } else {
                            setTrafficPoliciesResources(value);
                            setTrafficPolicies([]);
                        }
                    }}
                    onClose={() => {setFilterValue("")}}
                    isOptionEqualToValue = {(option, value) => option === value}
                />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} className="traffic-policy-row">
                <Autocomplete
                    multiple
                    id="site-select-box"
                    options={[
                        { name: "Select All", value: '' },
                        ...(trafficPoliciesOptions[type] || [])
                    ]}
                    value={trafficPolicies}
                    limitTags={3}
                    style={{ width: 270, margin: '0px' }}
                    disableCloseOnSelect
                    inputValue={filterTrafficPolicyValue}    
                    getOptionLabel={(option) => option.name}
                    renderOption={(prop, option, { selected }) => {
                        const index = prop['data-option-index'];
                        return (
                            <li {...prop} key={index}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField {...params} onChange={(e) => setFilterValue(e.target.value)} placeholder={"Select Traffic Policy"} variant="outlined" size="small" />
                    )}
                    onChange={(e, value) => {
                        if (value.some((option) => option.name === "Select All")) {
                            if (trafficPolicies?.length === trafficPoliciesOptions[type]?.length) {
                                setTrafficPolicies([]);
                            } else {
                                setTrafficPolicies(trafficPoliciesOptions[type]);
                            }
                        } else {
                            setTrafficPolicies(value);
                        }
                    }}
                    onClose={() => {setFilterTrafficPolicyValue("")}}
                    isOptionEqualToValue = {(option, value) => option.name === value.name}
                />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} className="remove-traffic-policy-icon">
                <IconButton size='small' onClick={removeTrafficPolicy}>
                    <RemoveCircleIcon />
                </IconButton>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: any) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    customContentFilter: state.customContentFilter,
})

export default withRouter(
    connect(mapStateToProps, {
        getTrafficPolicyDataV3,
        getTrafficPolicyDataV4,
        getTrafficPolicyDataV5,
        applyTrafficPolicy,
        getContentFilterLogs
    })(CFTrafficPolicyDialog)
);  