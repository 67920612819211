import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Button, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import { getCarrierHubSDWANStatusChart, getCarrierHubSDWANLatencyChart, getCarrierHubSDWANDataRatesChart, getCarrierHubSDWANUsageChart, getCarrierHubSDWANHubsChart, getCarrierHubAgentDropdownList, getCarrierHubWanDropdownList, getCarrierHubsDropdownList } from '../../../actions/Users/authenticateHub';
import _ from "lodash";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI, getMinutes, getTimezoneCity, readableBytesAsGB, readableBytesAsMB } from "../../../utils/util";
import MultipleSelectChip from "./MultipleSelectChip";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import moment from "moment";
import Chart from "../../../components/Charts";
import { GET_CARRIER_HUB_SDWAN_DATA_RATES, GET_CARRIER_HUB_SDWAN_LATENCY, GET_CARRIER_HUB_SDWAN_STATUS, GET_CARRIER_HUB_SDWAN_USAGE, GET_CARRIER_HUB_SDWAN_HUBS } from "../../../actions/types";
import Download from '../../../asset/image/DownloadUsage.svg';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";

const AgentSDWAN = (props) => {
    const { newSummaryStartDate, newSummaryEndDate, getCarrierHubSDWANStatusChart, getCarrierHubSDWANLatencyChart, getCarrierHubSDWANStatus, getCarrierHubSDWANLatency, userTimezone, getCarrierHubSDWANDataRatesChart, getCarrierHubSDWANUsageChart, getCarrierHubSDWANHubsChart, getCarrierHubSDWANDataRates, getCarrierHubAgentDropdownList, getCarrierHubWanDropdownList,
        getCarrierHubsDropdownList, getCarrierHubSDWANUsage, getCarrierHubSDWANHubs, wanDropdown, hubsDropdown, location, history
    } = props;

    let _q = getDecodeURI(location?.search);
    const dispatch = useDispatch();
    const getInterval = (minutes) => {
        if (minutes <= 60) {
            return "1 minute"
        } else if (minutes <= 1440 && minutes > 60) {
            return "3 minute"
        } else if (minutes > 1440 && minutes <= 10080) {
            return "20 minute"
        } else {
            return "12 hour";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    const [chartOptions, setChartOptions] = useState<any>({});
    const [selectedAgents, setSelectedAgents] = useState(_q.hasOwnProperty('agentId') ? [_q.agentId] : []);
    const [wansList, setWansList] = useState<any>([]);
    const [selectedWans, setSelectedWans] = useState<any>(_q.hasOwnProperty('wanId') ? [_q.wanId] : []);
    const metricsList = ['Status', 'Latency', 'Throughput', 'Usage'];
    const [selectedMetrics, setSelectedMetrics] = useState<any>(['Usage']);
    const [prevSelectedMetrics, setPrevSelectedMetrics] = useState<string[]>([]);
    const [hubsList, setHubsList] = useState<any>([]);
    const [selectedHubs, setSelectedHubs] = useState([]);
    const [selectedHubIds, setSelectedHubIds] = useState<any>([]);
    const [hubsData, setHubsData] = useState<any>([]);
    const chartRef = useRef<any>(null);
    const hubsRef = useRef<any>(null);
    const [hoverStart, setHoverStart] = useState();
    const [progressBarOptions, setProgressBarOptions] = useState<any>({});
    const [digitCount, setDigitCount] = useState([2, 2, 2, 2]);
    const [hubLabelLength, setLabelLength] = useState(false);
    const [missingLabelLength, setMissingLabelLength] = useState(0);
    const [usageUnit, setUsageUnit] = useState('GB');

    useEffect(() => {
        getCarrierHubAgentDropdownList();
    }, [])

    useEffect(() => {
        if (selectedAgents?.[0]) {
            getCarrierHubWanDropdownList(selectedAgents?.[0]);
        }
    }, [selectedAgents])

    useEffect(() => {
        if (selectedAgents?.[0]) {
            getCarrierHubsDropdownList(newSummaryStartDate, newSummaryEndDate, selectedAgents?.[0]);
        }
    }, [newSummaryStartDate, newSummaryEndDate, selectedAgents])

    useEffect(() => {
        if (!_.isEmpty(wanDropdown)) {
            const data = wanDropdown.hasOwnProperty('data') ? wanDropdown.data : [];
            if (_q.hasOwnProperty('wanId') && _q.wanId) {
                setSelectedWans([_q.wanId])
            } else {
                setSelectedWans(data?.map(wan => wan['wanId']));
            }
            setWansList(data?.map(wan => wan['wanId']));
        }
    }, [wanDropdown])

    useEffect(() => {
        if (!_.isEmpty(hubsDropdown)) {
            const data = hubsDropdown.hasOwnProperty('data') ? hubsDropdown.data : [];
            setHubsList(data?.map(hub => hub['hub_name']));
            setSelectedHubs(data?.map(hub => hub['hub_name']));
            setSelectedHubIds(data?.map(hub => hub['location_id']));
        }
    }, [hubsDropdown])

    interface HubData {
        dataTypes: string[];
        columns: string[];
        rows: Array<[string, number, string, number]>;
    }

    interface HubsResponse {
        [hubName: string]: HubData;
    }

    useEffect(() => {
        if (!_.isEmpty(getCarrierHubSDWANHubs)) {
            const rawData = getCarrierHubSDWANHubs.hasOwnProperty('data') ? getCarrierHubSDWANHubs.data : {};
            if (rawData) {
                const hubsList = selectedHubs;
                const allIntervals: { start: number, end: number }[] = [];

                const processedData = Object.entries(rawData as HubsResponse).map(([hubName, hubData]) => {
                    const rowsData = hubData.rows.map((row, index, array) => {
                        const nextRow = array[index + 1];
                        if (nextRow) {
                            const interval = { start: row[1] * 1000, end: nextRow[1] * 1000 };
                            allIntervals.push(interval); // Collect all intervals
                            return {
                                start: interval.start,
                                end: interval.end,
                                hub_name: row[2],
                                status: row[3]
                            };
                        }
                        return null;
                    }).filter(Boolean);

                    return { name: hubName, data: rowsData };
                });

                const uniqueIntervals = _.uniqWith(allIntervals, (a, b) => a.start === b.start && a.end === b.end);

                hubsList.forEach(hub => {
                    const hubData = processedData.find(data => data.name === hub);
                    if (!hubData) {
                        const missingHubData = uniqueIntervals.map(interval => ({
                            start: interval.start,
                            end: interval.end,
                            hub_name: hub,
                            status: 0
                        }));

                        processedData.push({
                            name: hub,
                            data: missingHubData
                        });
                    }
                });

                setHubsData(processedData);
            }
            dispatch({ type: GET_CARRIER_HUB_SDWAN_HUBS, payload: {} });
        }
    }, [getCarrierHubSDWANHubs]);

    const fetchChartData = (metric, startDate, endDate, agent, wans, bin, selectedHubIds) => {
        switch (metric) {
            case 'Status':
                return getCarrierHubSDWANStatusChart(startDate, endDate, agent, wans, bin, selectedHubIds);
            case 'Latency':
                return getCarrierHubSDWANLatencyChart(startDate, endDate, agent, wans, bin, selectedHubIds);
            case 'Throughput':
                return getCarrierHubSDWANDataRatesChart(startDate, endDate, agent, wans, bin, selectedHubIds);
            case 'Usage':
                return getCarrierHubSDWANUsageChart(startDate, endDate, agent, wans, bin, selectedHubIds);
            default:
                console.warn(`Unknown metric: ${metric}`);
                return null;
        }
    };

    useEffect(() => {
        const _interval = getInterval(
            Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)
        );
        setSelectedBin(_interval);
        if (!_.isEmpty(selectedWans) && !_.isEmpty(selectedHubIds)) {
            getCarrierHubSDWANHubsChart(newSummaryStartDate, newSummaryEndDate, selectedAgents[0], selectedWans, _interval, selectedHubIds);
        }
        const clearChartState = () => {
            dispatch({ type: GET_CARRIER_HUB_SDWAN_STATUS, payload: {} });
            dispatch({ type: GET_CARRIER_HUB_SDWAN_LATENCY, payload: {} });
            dispatch({ type: GET_CARRIER_HUB_SDWAN_DATA_RATES, payload: {} });
            dispatch({ type: GET_CARRIER_HUB_SDWAN_USAGE, payload: {} });
        };

        if (_.isEmpty(selectedHubs)) {
            clearChartState();
            dispatch({ type: GET_CARRIER_HUB_SDWAN_HUBS, payload: {} });
            setHubsData([]);
            return;
        }
        if (_.isEmpty(selectedWans)) {
            clearChartState();
            dispatch({ type: GET_CARRIER_HUB_SDWAN_HUBS, payload: {} });
            setHubsData([]);
            return;
        }
        if (!_.isEmpty(selectedMetrics) && !_.isEmpty(selectedWans) && !_.isEmpty(selectedHubIds)) {
            selectedMetrics.forEach((metric: any) => {
                fetchChartData(metric, newSummaryStartDate, newSummaryEndDate, selectedAgents[0], selectedWans, _interval, selectedHubIds);
            });

            const deselectedMetrics = prevSelectedMetrics.filter(
                (metric) => !selectedMetrics.includes(metric)
            );

            deselectedMetrics.forEach((metric) => {
                const actionMap = {
                    Status: GET_CARRIER_HUB_SDWAN_STATUS,
                    Latency: GET_CARRIER_HUB_SDWAN_LATENCY,
                    Throughput: GET_CARRIER_HUB_SDWAN_DATA_RATES,
                    Usage: GET_CARRIER_HUB_SDWAN_USAGE,
                };

                const actionType = actionMap[metric];
                if (actionType) {
                    dispatch({ type: actionType, payload: {} });
                } else {
                    console.warn(`Unknown metric: ${metric}`);
                }
            });

            setPrevSelectedMetrics([...selectedMetrics]);
        } else {
            console.warn('No metrics selected.');
            clearChartState();
        }
    }, [newSummaryStartDate, newSummaryEndDate, selectedWans, selectedMetrics, selectedHubIds]);

    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBin(value);
        selectedMetrics.forEach((metric) => {
            fetchChartData(metric, newSummaryStartDate, newSummaryEndDate, selectedAgents?.[0], selectedWans, value, selectedHubIds);
        });
        if (!_.isEmpty(selectedWans) && !_.isEmpty(selectedHubIds)) {
            getCarrierHubSDWANHubsChart(newSummaryStartDate, newSummaryEndDate, selectedAgents[0], selectedWans, value, selectedHubIds);
        }
    };

    const assignColor = (() => {
        const colorMap = new Map();
        const colorPalette = ["#1f3ab4", "#2e9fde", "#629b3a", "#dddb48", "#921fb3", "#dd2ba4", "#a5393a", "#e29448", "#0f957d", "#57cfc8",
            "#7e86d6", "#e7bf9e", "#f5a8ff", "#0a74cf", "#28cc4a", "#04592c", "#828282", "#ffca90"
        ];
        let colorIndex = 0;

        return (key) => {
            if (!colorMap.has(key)) {
                colorMap.set(key, colorPalette[colorIndex % colorPalette.length]);
                colorIndex++;
            }
            return colorMap.get(key);
        };
    })();

    const processChartData = (data, type, markerType, valueUnit) => {
        return Object.keys(data?.series || {}).map((key) => ({
            name: `${type} - ${key}`,
            data: data?.series[key]?.map(point => {
                if (point.y === "null" || point.y === null || isNaN(point.y)) {
                    point.y = null;
                }
                return point;
            }),
            marker: { symbol: markerType },
            color: assignColor(`${type}-${key}`),
            valueUnit,
        }));
    };

    const processCombinedUsageData = (downloadData, uploadData, type, markerType) => {
        const downloadUsageSeries = Object.keys(downloadData.series)?.map((el) => downloadData.series[el])?.flat() ?? [];
        const uploadUsageSeries = Object.keys(uploadData.series)?.map((el) => uploadData.series[el])?.flat() ?? [];

        const combinedData = [...downloadUsageSeries, ...uploadUsageSeries];

        const gbThreshold = Math.pow(1000, 3);
        const isGBCrossed = combinedData?.some((item) => item.y > gbThreshold);

        const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
        setUsageUnit(isGBCrossed ? 'GB' : 'MB')

        const downloadSeriesKeys = Object.keys(downloadData.series);
        const processedDownloadData = downloadSeriesKeys.map((key) => {
            const seriesData = downloadData.series[key];
            return {
                name: `Download Usage - ${key}`,
                data: seriesData.map((item) => ({
                    ...item,
                    y: Number(convertBytes(item.y, false)),
                })),
                marker: { symbol: markerType },
                color: assignColor(`${type}-download-${key}`),
                valueUnit: isGBCrossed ? 'GB' : 'MB',
            };
        });

        const uploadSeriesKeys = Object.keys(uploadData.series);
        const processedUploadData = uploadSeriesKeys.map((key) => {
            const seriesData = uploadData.series[key];
            return {
                name: `Upload Usage - ${key}`,
                data: seriesData.map((item) => ({
                    ...item,
                    y: Number(convertBytes(item.y, false)),
                })),
                marker: { symbol: markerType },
                color: assignColor(`${type}-upload-${key}`),
                valueUnit: isGBCrossed ? 'GB' : 'MB',
            };
        });

        return [
            ...processedDownloadData,
            ...processedUploadData
        ];
    };

    const calculateYAxisTickDigits = () => {
        if (chartRef.current && chartRef.current.chart) {
            const chart = chartRef.current.chart;
            const yAxes = chart?.yAxis || [];
            const allDigitCounts:any = [];
    
            yAxes.forEach((yAxis) => {
                const tickPositions = yAxis?.tickPositions || [];
    
                if (!Array.isArray(tickPositions) || tickPositions.length === 0) {
                    allDigitCounts.push(0);
                    return;
                }
    
                let isDot = false;
                const tickDigits = tickPositions.map((tick) => {
                    const tickString = `${tick}`;
                    if (tickString.includes('.') || tickString.includes('-')) {
                        isDot = true;
                    }
                    return tickString.length;
                });
    
                const largestNumber = Math.max(...tickDigits);
    
                if (!isFinite(largestNumber) || largestNumber <= 0) {
                    allDigitCounts.push(0);
                    return;
                }
    
                let spaceCount = 0;
                if (isDot) {
                    spaceCount = ((largestNumber - 1) * 7.19) + 3.37;
                } else {
                    spaceCount = largestNumber * 7.19;
                }
    
                if (!isFinite(spaceCount)) {
                    spaceCount = 0;
                }
    
                allDigitCounts.push(spaceCount);
            });
    
            if (allDigitCounts.every((count) => typeof count === 'number' && !isNaN(count))) {
                setDigitCount(allDigitCounts);
            }
        }
    };
    useEffect(() => {
        calculateYAxisTickDigits();
    }, [chartOptions]);

    const chartAreaSelectionHandler = () => {
        return (event: any) => {
            let start: any = moment(new Date(Math.ceil(event.xAxis[0].min)));
            let end = moment(new Date(Math.floor(event.xAxis[0].max)));
            pinchAndResetChart(start, end, 'customDates');
            return false;
        }
    }

    const calculatedSpacingLeftSecondChart = () => {
        const safeDigitCount = Array.isArray(digitCount) ? digitCount : [];
        const safeMissingLabelLength = isFinite(missingLabelLength) ? Number(missingLabelLength) : 0;
    
        const baseValues = [33, 48, 63, 77];
        const defaultSpacing = 33;
    
        const metricsLength = selectedMetrics?.length || 0;
    
        const baseValue = baseValues[metricsLength - 1] || defaultSpacing;
    
        let digitSum = 0;
        for (let i = 0; i < metricsLength; i++) {
            const value = safeDigitCount[i];
            if (isFinite(value)) {
                digitSum += Number(value);
            }
        }
    
        return !_.isEmpty(hubsData)
            ? hubLabelLength
                ? baseValue + digitSum + safeMissingLabelLength
                : baseValue + digitSum
            : 120;
    };
    

    const defaultChartOptions = {
        time: {
            timezone: getTimezoneCity(userTimezone)
        },
        chart: {
            type: 'spline',
            height: '400px',
            zoomType: 'x',
            plotBorderWidth: 1,
            spacingLeft: selectedMetrics?.length == 1 ? 79 : selectedMetrics?.length == 2 ? 53 : selectedMetrics?.length == 3 ? 27 : selectedMetrics?.length == 4 ? 1 : 80,
            resetZoomButton: {
                theme: {
                    style: {
                        display: 'none'
                    }
                }
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
            events: {
                selection: chartAreaSelectionHandler()
            }
        },
        title: {
            text: `Agent : ${selectedAgents[0]}`,
            align: 'left',
            floating: true,
            x: 12,
            y: 20,
            style: {
                fontWeight: '500',
                fontSize: '16px'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false
        },
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            y: 40,
            margin: 28,
            itemStyle: {
                color: '#3F3F3F'
            }
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
                point: {
                    events: {
                        click: function (this: any) {
                            setHoverStart(this.x);
                        },
                    },
                },
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            },
            colorByPoint: true,
        },
        tooltip: {
            valueSuffix: '',
            headerFormat: '',
            pointFormat: '<span style="color: #7e8089">{series.name}</span> <br/> <b style="color: #fff">{point.hub_name} {series.valueUnit}</b><br/>',
            valueDecimals: 0,
            borderWidth: 1,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
            formatter: function (this: any) {
                let decimals = 3;
                if (this && !_.isEmpty(this.series)) {
                    decimals = this.series.name.includes("Status") ? 0 : 3;
                }
                if (this && !_.isEmpty(this.series) && this.series.name.includes("Hub")) {
                    return `
                        <b style="color: #7e8089">${Highcharts.dateFormat('%A, %b %e, %H:%M', this.x)}</b><br/>
                        <b style="color: #7e8089">${this.series.name}</b>: 
                        <b style="color: #fff">${this.point.hub_name}</b>`;
                } else if (this && !_.isEmpty(this.series)) {
                    return `
                        <b style="color: #7e8089">${Highcharts.dateFormat('%A, %b %e, %H:%M', this.x)}</b><br/>
                        <b style="color: #7e8089">${this.series.name}</b>: 
                        <b style="color: #fff">${this.y.toFixed(decimals)} ${this.series.options.valueUnit || ''}</b>`;
                }
            }
        },
        xAxis: {
            gridLineWidth: 0.5,
            reversed: false,
            type: "datetime",
            min: moment(newSummaryStartDate).utc().toDate().valueOf(),
            max: moment(newSummaryEndDate).utc().toDate().valueOf(),
            maxPadding: 0.05,
            showLastLabel: true,
            labels: {
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                }
            },
            plotBands: [{
                color: 'red',
                from: moment(hoverStart),
                to: moment(hoverStart),
            }],
        }
    }

    useEffect(() => {
        const allChartData: any = [];
        const yAxes: any = [];

        selectedMetrics.forEach((option, index) => {
            let chartData: any = [];
            let unit = '';

            if (option === "Status" && !_.isEmpty(getCarrierHubSDWANStatus)) {
                unit = "";
                chartData = processChartData(getCarrierHubSDWANStatus?.data?.status, "Status", "circle", "");
            } else if (option === "Latency" && !_.isEmpty(getCarrierHubSDWANLatency)) {
                unit = "ms";
                chartData = processChartData(getCarrierHubSDWANLatency?.data?.latency, "Latency", "circle", "ms");
            } else if (option === "Throughput" && !_.isEmpty(getCarrierHubSDWANDataRates)) {
                unit = "Mbps";
                chartData = [
                    ...processChartData(getCarrierHubSDWANDataRates?.data?.avgDownSpeed, "Download Rate", "circle", "mbps"),
                    ...processChartData(getCarrierHubSDWANDataRates?.data?.avgUpSpeed, "Upload Rate", "circle", "mbps"),
                ];
            } else if (option === "Usage" && !_.isEmpty(getCarrierHubSDWANUsage?.data?.download) && !_.isEmpty(getCarrierHubSDWANUsage?.data?.upload)) {
                const usageData = processCombinedUsageData(getCarrierHubSDWANUsage?.data?.download, getCarrierHubSDWANUsage?.data?.upload, 'Usage', 'circle');
                unit = usageData[0]?.valueUnit;
                chartData = usageData;
            }
            if (chartData.length) {
                chartData.forEach(data => data.yAxis = index);
                allChartData.push(...chartData);
            }
            yAxes.push({
                title: {
                    text: `${option}${unit ? ` (${unit})` : ""}`,
                    style: { color: '#787773' }
                },
                labels: {
                    style: {
                        color: '#787773',
                    },
                    opposite: true,
                },
                opposite: false,
            });
        });

        if (allChartData.length > 0 && yAxes.length > 0) {
            setChartOptions({
                ...defaultChartOptions,
                yAxis: yAxes,
                series: allChartData,
            });
        } else if(allChartData.length == 0) {
            setChartOptions({
                ...defaultChartOptions,
                chart: {
                    ...defaultChartOptions.chart,
                    spacingLeft: 120
                },
                yAxis: [],
                series: [],
            })
        }
    }, [getCarrierHubSDWANStatus, getCarrierHubSDWANLatency, getCarrierHubSDWANDataRates, getCarrierHubSDWANUsage, hoverStart]);

    const pinchAndResetChart = (start, end, interval) => {
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = interval;
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleResetZoom = () => {
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        pinchAndResetChart(start, end, '1h');
    }

    const handleHubSelection = (newValue) => {
        setSelectedHubs(newValue);
        const selectedIds = hubsDropdown?.data.filter(hub => newValue.includes(hub.hub_name)).map(hub => hub.location_id);
        setSelectedHubIds(selectedIds);
    }

    const handleWanSelection = (newValue) => {
        setSelectedWans(newValue);
    }

    const handleMetricSelection = (selected) => {
        setSelectedMetrics(selected)
    }

    const processCSVData = (selectedAgent: string, wanType: string, selectedMetrics: string[]) => {
        const data: any[] = [];
        const selectedWanType = `${selectedAgent} - ${wanType}`;

        const statusSeries = getCarrierHubSDWANStatus?.data?.status?.series[selectedWanType] || [];
        const latencySeries = getCarrierHubSDWANLatency?.data?.latency?.series[selectedWanType] || [];
        const avgDownSpeedSeries = getCarrierHubSDWANDataRates?.data?.avgDownSpeed?.series[selectedWanType] || [];
        const avgUpSpeedSeries = getCarrierHubSDWANDataRates?.data?.avgUpSpeed?.series[selectedWanType] || [];
        const downloadUsageSeries = getCarrierHubSDWANUsage?.data?.download?.series[selectedWanType] || [];
        const uploadUsageSeries = getCarrierHubSDWANUsage?.data?.upload?.series[selectedWanType] || [];

        const combinedData = [...downloadUsageSeries, ...uploadUsageSeries];

        const gbThreshold = 1000 * 1000 * 1000
        const isGBCrossed = combinedData?.some((item) => item.y > gbThreshold);

        const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;

        const timestamps = Array.from(
            new Set([
                ...statusSeries.map(point => point.x),
                ...latencySeries.map(point => point.x),
                ...avgDownSpeedSeries.map(point => point.x),
                ...avgUpSpeedSeries.map(point => point.x),
                ...downloadUsageSeries.map(point => point.x),
                ...uploadUsageSeries.map(point => point.x),
            ])
        ).sort((a, b) => a - b);

        timestamps.forEach(timestamp => {
            const row: any[] = [
                selectedAgent,
                // `${hub_name}`,
                wanType,
                convertDateTimeIntoTimezone(
                    new Date(timestamp).toLocaleString(),
                    userTimezone,
                    MMDDYYYYHMMSS_DATE_FORMAT_24_HRS
                ).replace(',', ' '),
            ];

            selectedMetrics.forEach(metricKey => {
                switch (metricKey) {
                    case 'Status':
                        const statusData = statusSeries.find(point => point.x === timestamp);
                        row.push(statusData && statusData.y == 1 ? 'Online' : statusData && statusData.y == 0 ? 'Offline' : '');
                        break;
                    case 'Latency':
                        const latencyData = latencySeries.find(point => point.x === timestamp);
                        row.push(latencyData ? latencyData.y : '');
                        break;
                    case 'Throughput':
                        const avgDownSpeedData = avgDownSpeedSeries.find(point => point.x === timestamp);
                        const avgUpSpeedData = avgUpSpeedSeries.find(point => point.x === timestamp);
                        row.push(avgDownSpeedData ? avgDownSpeedData.y : '');
                        row.push(avgUpSpeedData ? avgUpSpeedData.y : '');
                        break;
                    case 'Usage':
                        const downloadData = downloadUsageSeries.find(point => point.x === timestamp);
                        const uploadData = uploadUsageSeries.find(point => point.x === timestamp);
                        row.push(downloadData ? convertBytes(downloadData.y, false) : '');
                        row.push(uploadData ? convertBytes(uploadData.y, false) : '');
                        break;
                    default:
                        break;
                }
            });

            data.push(row);
        });

        return data;
    };

    const generateCSVData = () => {
        const availableMetrics: any = [
            { key: 'Status', label: 'Status' },
            { key: 'Latency', label: 'Latency (ms)' },
            {
                key: 'Throughput',
                subKeys: ['avgDownSpeed', 'avgUpSpeed'],
                subLabels: ['Download Rate (Mbps)', 'Upload Rate (Mbps)'],
            },
            {
                key: 'Usage',
                subKeys: ['downUsage', 'upUsage'],
                subLabels: [`Download Usage (${usageUnit})`, `Upload Usage (${usageUnit})`],
            },
        ];

        const headers = [
            'Agent',
            // 'Hub',
            'WAN Name',
            `Time (${userTimezone})`,
            ...selectedMetrics.flatMap(metricKey => {
                const metric = availableMetrics.find(m => m.key === metricKey);
                return metric?.subLabels || [metric?.label];
            }),
        ];

        const csvData = [headers];

        selectedWans.forEach(wanType => {
            selectedAgents.forEach(agent => {
                const processedData = processCSVData(agent, wanType, selectedMetrics);
                processedData.forEach(row => {
                    csvData.push(row);
                });
            });
        });

        return csvData;
    };

    const convertToCSV = (data) => {
        return data.map(row => row.join(',')).join('\n');
    };

    const downloadCSV = (csvData, fileName = 'agent_wan_report.csv') => {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleDownloadMetricsReport = () => {
        const processedData = generateCSVData();
        const csvData = convertToCSV(processedData);
        downloadCSV(csvData, 'metrics_report.csv');
    };

    const handleDownloadReport = () => {
        !_.isEmpty(chartOptions?.series) && handleDownloadMetricsReport();
        !_.isEmpty(hubsData) && handleDownloadHubsReport();
    };

    const processHubsData = (hubsData) => {
        const headers = ['Hub Name', `Time (${userTimezone})`, 'Status'];
    
        const data = [headers];
    
        hubsData.forEach(hub => {
            hub.data.forEach(entry => {
                data.push([
                    hub.name,
                    convertDateTimeIntoTimezone(
                        new Date(entry.start).toLocaleString(),
                        userTimezone,
                        MMDDYYYYHMMSS_DATE_FORMAT_24_HRS
                    ).replace(',', ' '),
                    entry.status === 1 ? 'Connected' : 'Disconnected'
                ]);
            });
        });
    
        return data;
    };
    
    const generateHubsCSV = () => {
        const processedData = processHubsData(hubsData);
        return convertToCSV(processedData);
    };
    
    const handleDownloadHubsReport = () => {
        const csvData = generateHubsCSV();
        downloadCSV(csvData, 'hubs_report.csv');
    };

    const colors = ['#1d1462', '#1a3dae', '#368cd6', '#59b5ea', '#88caea', '#97cafd', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
        '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
        '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
        '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
        '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
    ]

    useEffect(() => {
        if (Array.isArray(selectedHubs) && selectedHubs.length > 0) {
            const hubListTicks = selectedHubs.map((hub) => {
                const hubString = `${hub}`;
                return hubString.length;
            });
    
            const hubLengthLabel = Math.max(...hubListTicks);
    
            if (hubLengthLabel >= 0 && hubLengthLabel < 10) {
                setLabelLength(true);
                const hubLabelDiffLength = 9 - hubLengthLabel;
    
                if (hubLabelDiffLength > 0) {
                    const missingDiffLength = (hubLabelDiffLength * 7.19) + 6.74;
    
                    if (isFinite(missingDiffLength)) {
                        setMissingLabelLength(missingDiffLength);
                    } else {
                        setMissingLabelLength(0);
                    }
                }
            } else {
                setLabelLength(false);
            }
        } else {
            setLabelLength(false);
            setMissingLabelLength(0);
        }
    }, [selectedHubs])

    useEffect(() => {
        setProgressBarOptions({
            time: {
                timezone: getTimezoneCity(userTimezone)
            },
            chart: {
                type: 'xrange',
                height: '100px',
                spacingLeft: calculatedSpacingLeftSecondChart(),
                marginTop: 0,
                marginBottom: 20,

                plotBorderWidth: 1,
                style: {
                    fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
                }
            },
            title: {
                text: null,
            },
            lang: {
                noData: "No Data",
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#303030',
                },
            },
            xAxis: {
                gridLineWidth: 0.5,
                reversed: false,
                type: "datetime",
                min: moment(newSummaryStartDate).utc().toDate().valueOf(),
                max: moment(newSummaryEndDate).utc().toDate().valueOf(),
                maxPadding: 0.05,
                showLastLabel: true,
                visible: true,
                plotBands: [{
                    color: 'red',
                    from: moment(hoverStart),
                    to: moment(hoverStart),
                    zIndex: 100000000
                }],
            },
            yAxis: {
                categories: hubsData.map((hub) => hub.name),
                labels: {
                    style: {
                        width: '81.45',
                        minWidth: '81.45px',
                        maxWidth: '81.45px',
                        color: '#787773',
                        textOverflow: 'ellipsis'
                    }
                },
                title: null,
                visible: true,
            },
            series: !_.isEmpty(hubsData) ? [{
                name: 'Connection Status',
                borderColor: 'transparent',
                borderWidth: 0,
                pointPadding: 0,
                groupPadding: 0,
                pointWidth: 4,
                color: colors,
                data: hubsData.flatMap((hub, index) =>
                    hub.data.map(entry => {
                        const color = entry.status === 0 ? '#fff' : colors[index % colors.length];
                        return {
                            x: entry.start,
                            x2: entry.end,
                            y: index,
                            color: color,
                            status: entry.status
                        };
                    })
                ),
                dataLabels: {
                    enabled: false,
                },
            }] : [],
            tooltip: {
                borderRadius: 15,
                backgroundColor: '#060606',
                formatter: function (this: any) {
                    if (this.point.status === 0) {
                        return false;
                    }
                    const hubName = this.series.chart.yAxis[0].categories[this.point.y];
                    return `
                        <b style="color: #7e8089">${Highcharts.dateFormat('%A, %b %e, %H:%M', this.point.x)}</b><br/>
                        <b style="color: #7e8089">Hub: </b><b style="color: #fff">${hubName}</b><br/>
                        <b style="color: #7e8089">Status: </b><b style="color: #fff">${this.point.status == 1 ? 'Connected' : 'DIsconnected'}</b>`;
                },
                useHTML: true
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    borderRadius: 0,
                    pointWidth: 4,
                    groupPadding: 0,
                    pointPadding: 0,
                    borderWidth: 0,
                    borderColor: 'transparent',
                    point: {
                        events: {
                            click: function (this: any) {
                                setHoverStart(this.x);
                            },
                        },
                    },
                },
            },
        })
    }, [hubsData, hoverStart, digitCount, hubLabelLength])

    return (
        <div className="new-summary-chart-container charts-container-captive-dashboard" style={{ margin: '15px 25px 10px 25px' }}>
            <Grid container spacing={2} className='new-summary-chart-component'>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid className="usage-per-site-pie">
                        <div className="site-filter-container siteCharts__filters alignTopbarItemsCenter">
                            <div className="site-filter-item">
                                <div className="sdwanTopbar hub-topology-sdwan-dropdowns-div">
                                    {selectedHubs && !_.isEmpty(hubsList) && <MultipleSelectChip defaultSelection={selectedHubs} handleSelection={handleHubSelection} values={hubsList} name={'Hub'} sx={{ maxWidth: "250px", width: "250px" }} limitValue={1} />}
                                    {selectedWans && !_.isEmpty(wansList) && <MultipleSelectChip defaultSelection={selectedWans} handleSelection={handleWanSelection} values={wansList} name={'Wans'} sx={{ maxWidth: "250px", width: "250px" }} limitValue={1} />}
                                    {selectedMetrics && !_.isEmpty(metricsList) && <MultipleSelectChip defaultSelection={selectedMetrics} handleSelection={handleMetricSelection} values={metricsList} name={'Metrics'} sx={{ maxWidth: "250px", width: "250px" }} limitValue={2} />}
                                </div>
                            </div>
                            <div className="site-filter-item jc-end">
                                <Grid className="pieChat-selectTag">
                                    <FormControl variant="standard" className='selectEntry-pie sdwan-select-bin'>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={selectedBin}
                                            onChange={handleBinChange}
                                        >
                                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1 && <MenuItem value={'1 minute'}>1m</MenuItem>}
                                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 3 && <MenuItem value={'3 minute'}>3m</MenuItem>}
                                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10 && <MenuItem value={'10 minute'}>10m</MenuItem>}
                                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 20 && <MenuItem value={'20 minute'}>20m</MenuItem>}
                                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 60 && <MenuItem value={'1 hour'}>1h</MenuItem>}
                                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 360 && <MenuItem value={'6 hour'}>6h</MenuItem>}
                                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 720 && <MenuItem value={'12 hour'}>12h</MenuItem>}
                                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1440 && <MenuItem value={'1 day'}>1d</MenuItem>}
                                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10080 && <MenuItem value={'7 day'}>7d</MenuItem>}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <div className="siteCharts__filters-reset-zoom">
                                    <FormControlLabel
                                        value="Reset Zoom"
                                        control={<IconButton onClick={handleResetZoom}><RestartAltIcon /></IconButton>}
                                        label="Reset Zoom"
                                        labelPlacement="start"
                                    />
                                </div>
                                {(!_.isEmpty(chartOptions?.series) || !_.isEmpty(hubsData)) && <Grid ml={2}>
                                    <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                                </Grid>}
                            </div>
                        </div>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className="siteCharts__charts-container-freeFormTool">
                                <Chart chartOptions={chartOptions} immutable={true} updateArgs={[true, false, true]} enableEventsTable={false} progressBarOptions={progressBarOptions} chartRef={chartRef} hubsRef={hubsRef} selectedMetrics={selectedMetrics} agentSDWAN={true} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    userTimezone: state.authReducer.userTimezone,
    getCarrierHubSDWANStatus: state?.authReducer?.getCarrierHubSDWANStatus,
    getCarrierHubSDWANLatency: state?.authReducer?.getCarrierHubSDWANLatency,
    getCarrierHubSDWANDataRates: state?.authReducer?.getCarrierHubSDWANDataRates,
    getCarrierHubSDWANUsage: state?.authReducer?.getCarrierHubSDWANUsage,
    getCarrierHubSDWANHubs: state?.authReducer?.getCarrierHubSDWANHubs,
    wanDropdown: state.authReducer.getCarrierHubWanDropdown,
    hubsDropdown: state.authReducer.getCarrierHubListDropdown,
});

export default withRouter(
    connect(mapStateToProps, { getCarrierHubSDWANStatusChart, getCarrierHubSDWANLatencyChart, getCarrierHubSDWANDataRatesChart, getCarrierHubSDWANUsageChart, getCarrierHubSDWANHubsChart, getCarrierHubAgentDropdownList, getCarrierHubWanDropdownList, getCarrierHubsDropdownList })(AgentSDWAN)
);