export const CONFIG_EDGE_ADMINISTRATION_NAME = "edge-administration";

export const RESOURCE_FIELD_VESSELNAME = "vessel-name";
export const HUB_RESOURCE_FIELD_VESSELNAME = "site_name";
export const RESOURCE_FIELD_ID = "id";
export const RESOURCE_FIELD_TYPE = "type";
export const RESOURCE_TYPE_VESSEL_VALUE = "Vessel";


export const DEFAULT_TIMEZONE = "Asia/Calcutta";
export const DEFAULT_DATE_FORMAT = "MM/DD/yyyy";
// format Tue, 23 Mar 21, 19:04:59
export const DAYDDMMMYYHMMSS_DATE_FORMAT = "ddd, DD MMM YY, hh:mm:ss";
// format Tue, 23 Mar, 19:04:59
export const DAYDDMMMHMMSS_DATE_FORMAT = "ddd, DD MMM, hh:mm:ss";

export const MMDDYYYYHMMSS_DATE_FORMAT = "MM/DD/YYYY, hh:mm:ss A";

//24hrs date-time format 
export const MMDDYYYYHMMSS_DATE_FORMAT_24_HRS = "MM/DD/YYYY, HH:mm:ss";
export const MMDDYYYYHMM_DATE_FORMAT_24_HRS = "MM/DD/YYYY, HH:mm";

//offset from UTC 
export const YYYYMMDDHHMM_UTC_OFFSET = "YYYY-MM-DD hh:mm:ss.SSS +-HHmm"

export const YYYYMMDD_HHmmss = "YYYY-MM-DD HH:mm:ss"

export const SIDE_MENU_ITEM_DASHBOARD = "dashboard"
export const SIDE_MENU_ITEM_TRENDS = "trends"
export const SIDE_MENU_ITEM_SERVICE_MANAGEMENT = "servicemanagement";
export const SIDE_MENU_ITEM_SIM_REPORTS = "simreports";
export const SIDE_MENU_ITEM_ACCOUNT_MANAGEMENT = "accountmanagement";
export const SIDE_MENU_ITEM_ALERTS = "alerts";
export const SIDE_MENU_ITEM_EDGEADMIN = "edgeadmin";
export const SIDE_MENU_ITEM_MAP = "map";
export const SIDE_MENU_ITEM_REPORTS = "reports"
export const SIDE_MENU_ITEM_TICKETS = "tickets";
export const SIDE_MENU_ITEM_SOFTWARE_LISTING = "softwareListing";
export const SIDE_MENU_ITEM_INVENTORY = "inventory"
export const SIDE_MENU_ITEM_AUDIT_LOGS = "auditLogs"
export const SIDE_MENU_ITEM_QUOTA_MANAGEMENT = "quotaManagement"
export const SIDE_MENU_ITEM_STARLINK = "starlink"
export const SIDE_MENU_ITEM_CONTENT_FILTER = "contentFilter"
export const SIDE_MENU_ITEM_KNOWLEDGE_CENTER = "knowledgeCenter"
export const SIDE_MENU_ITEM_HUB = "hub"
export const SIDE_MENU_ITEM_CYBER = "threats"
export const SIDE_MENU_ITEM_CAPTIVE = "captive"
export const SIDE_MENU_ITEM_GEO_MANAGEMENT = "geo-kontrol"

export const LOCATION_ID_KEY = "locationId";
export const ID_KEY = "id";
export const NAME_KEY = "name";
export const TYPE_KEY = "type";
export const HARDWARE_VESION_KEY = "hardware_version";
export const SOFTWARE_VERSION_KEY = "software_version";
export const STATUS = "status";

export const SITE_NOTES = "siteNotes"
export const EDGE_EVENTS = "events"
export const CHARTS = "SDWAN"
export const HA = "highAvailability"
export const REMOTE_CONNECTIONS = "remote-connections"
export const QUOTA_MANAGEMENT = "geo-kontrol"
export const KNOWLEDGE_CENTER = "knowledge-center"
export const USER_DATA = "activity"
export const SIM_ANALYSIS = "cellular"
export const DATA_USAGE = "dataUsage"
export const GRAPHICAL_DATA_USAGE = "wanUsage"
export const FIREWALL_REPORT = "firewall-report"
export const ACCOUNT_MANAGEMENT = 'accounts'
export const LOGIN_HISTORY = 'account-activity'
export const GROUP_SETTINGS = 'groupSettings'
export const OPERATIONAL_REPORTS = 'operational'
export const GENERATED_REPORTS = 'generatedReports'
export const CONTENT_FILTER = 'contentFilter'
export const STARLINK_UNMANAGED = 'starlink-cloud'
export const SYSTEM_HEALTH = 'system_health'
export const HUB = 'hub'
export const THREAT_DASHBOARD= 'threatDashboard'
export const CYBER = 'threats'
export const GEO_MANAGEMENT = "geo-kontrol"
export const KONTROL_MANAGEMENT = "kontrol"

// Tickets more filter
export const NO_TIMEFRAME = "No timeframe";
export const VIEW_LAST_WEEK = "View last week";
export const VIEW_LAST_MONTH = "View last month";
export const CUSTOM_DATES = "Custom Dates";

export const alertMap = {
    "50": "ethernet_slow_10mbps",
    "51": "ethernet_slow_100mbps",
    "52": "power_supply_thermal_throttling",
    "53": "actuator_motor_stuck",
    "54": "mast_not_vertical",
    "55": "disabled_no_active_service_line",
    "56": "disabled_too_far_from_service_address",
    "57": "disabled_no_service_in_ocean",
    "58": "disabled_invalid_country",
    "59": "disabled_moving_while_not_mobile",
    "60": "disabled_moving_too_fast",
    "61": "power_disconnect_detected",
    "62": "pop_change",
    "63": "unable_to_align",
    "66": "high_time_obstruction",
    "80": "thermal_shutdown",
    "81": "thermal_throttling",
    "82": "software_update_reboot_pending",
    "83": "disabled_blocked_country",
    "84": "disabled_data_usage_exceeded_quota",
    "89": "disabled_cell_is_disabled",
    "93": "disabled_roam_restricted",
    "94": "disabled_unknown_location",
    "95": "disabled_account_disabled",
    "over_limit": "over_max_quota",
    "offline_for_15m": "Offline for more than 15 minutes",
    "offline_for_1h": "Offline for more than 1 hour",
    "offline_for_6h": "Offline for more than 6 hours",
}

    