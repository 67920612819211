import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { CONFIRM_DIALOG_TITLE } from "../../../config";
import _ from "lodash";
import ConfirmDialog from "../../ConfirmDialog";

const MobilePriority = (props) => {
    const { optInServiceLineRes, optInData, handleOptIn, setMobilePriority, mobilePriority, row } = props;

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });

    const [tempMobilePriority, setTempMobilePriority] = useState(mobilePriority);

    useEffect(()=>{
        setTempMobilePriority(mobilePriority)
    },[mobilePriority]);

    const handleToggleMobilePriority = (e, action) => {
        const value = e.target.checked;
        setTempMobilePriority(value);
        setConfirmDialog({
            isOpen: true,
            title: CONFIRM_DIALOG_TITLE,
            subTitle:
                action === 'optOut'
                    ? "Data will continue to be charged for up to 15 minutes."
                    : "Charges will be incurred until Mobile Priority is disabled. Mobile Priority will begin within 15 minutes.",
            onConfirm: () => {
                handleOptIn();
                setMobilePriority(value)
            },
        })
    };

    useEffect(() => {
        if (!_.isEmpty(optInServiceLineRes)) {
            const res = optInServiceLineRes;
            if (res.success) {
                setMobilePriority(tempMobilePriority);
            } else {
                setTempMobilePriority(mobilePriority);
            }
        }
    }, [optInServiceLineRes])

    return (
        <div className="optIn-container">
            <Grid>
                <Grid>
                    <span id='edit-toggle-btn-text'>Disabled</span>
                    <Switch className="toggle-btn-edit"
                        checked={tempMobilePriority}
                        onChange={(e) => handleToggleMobilePriority(e, optInData?.action)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size="small"
                    />
                   <span id='edit-toggle-btn-text'>Enabled</span>
                </Grid>
            </Grid>
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} setMobilePriority={setMobilePriority}    checked={mobilePriority}  isMobilePriority={true} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    optInServiceLineRes: state.starlinkAdmin.optInServiceLineRes
});

export default withRouter(
    connect(mapStateToProps, { })(MobilePriority)
);