import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { Box, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";


import { Pagination } from "../../Pagination";
import { getDecodeURI, getEncodedURI } from '../../../utils/util';
import SortArrows,  { SortOrder } from "../../SortArrows";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { getStarlinkAuditLogs, clearComponentData } from "../slice";
import moment from "moment-timezone";
import { JsonDataTooltip } from "../../JSONTooltip/JsonTooltip";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import { convertDateTimeIntoTimezone } from "../../../utils/util";

const convertToCsv = (data: any[][]) => {
    const csvRows: any[] = [];
    data.forEach((row) => {
        csvRows.push([row[5], row[3], row[8], row[10], row[9],  row[7], row[2]])
    })
    return [['Organization', 'Sub Organization', 'Starlink ID', 'Serial Number', 'KIT Number', 'Service Line', 'K4 Site'], ...csvRows]
}

export type UserTerminalSortBy = 'serviceLineName' | 'users' | 'description' | 'resource' | 'userAction' | 'IpAddress' | 'createdAt' ;

function AuditLogs(props) {

    const { authReducer, errorReducer, history, location, dpIds, userTerminals, totalAuditLogs, auditLogs, getStarlinkAuditLogs, newSummaryServiceLine, downloadAsCSV, clearComponentData } = props;

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [searchText, setSearchText] = useState<string>('');
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        sortBy: UserTerminalSortBy;
        sortOrder: SortOrder;
        search: string;
    }>({
        page: queryParams.has('page') ? parseInt(queryParams.get('page') as string) : 1,
        size: queryParams.has('size') ? parseInt(queryParams.get('size') as string) : 10,
        sortBy: queryParams.has('sortBy') ? queryParams.get('sortBy')  as UserTerminalSortBy: 'createdAt',
        sortOrder: queryParams.has('sortOrder') ? queryParams.get('sortOrder') as SortOrder : 'desc',
        search: queryParams.has('search') ? queryParams.get('search') as string : ''
    });
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const handleChangeSorting = (sortBy: UserTerminalSortBy, sortOrder: SortOrder) => {
        setTableParams({
            ...tableParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
    }

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }


    const updateQueryParams = () => {
        queryParams.set('page', tableParams.page.toString())
        queryParams.set('size', tableParams.size.toString())
        queryParams.set('sortBy', tableParams.sortBy)
        queryParams.set('sortOrder', tableParams.sortOrder)
        queryParams.set('search', tableParams.search)
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    useEffect(() => {
        updateQueryParams();
        clearComponentData()
        getStarlinkAuditLogs({
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page,
            limit: tableParams.size,
            dpIds:dpIds,
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
        });
    }, [ tableParams, newSummaryServiceLine]);
    
 

    return (
        <Paper elevation={1} classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_1x add-srcoll-starlink-subtab-pages' }}>
            {/* FILTERS */}
            <Grid classes={{ root: 'starlink--base--mtb_07x starlink--base--flex--align--center starlink--base--flex--justify--space_between' }}>
                <Grid>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x' }}>
                        AUDIT LOGS
                    </Typography>
                </Grid>
                <Grid classes={{ root: 'starlink--base--flex starlink--base--flex--align--center' }}>
                  
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box>
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Type something"
                                autoComplete="off"
                                classes={{root: "input-box-user-ter"}}
                                size="small"
                                value={searchText}
                                onChange={handleOnSearchChange}
                                onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchText && <SearchIcon
                                                className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                            />}
                                            {searchText && <CloseIcon
                                                className="cursorPointer input-search-icons"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                    {/* {totalUserTerminals > 0 && <div className="download-summary-btn" onClick={(e)=>{}}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>} */}
                </Grid>
            </Grid>

            <Pagination count={totalAuditLogs} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />

            
            <TableContainer component={Paper} className="starlink--table_container">
                <Table sx={{ minWidth: 650 }} size="small" aria-label="starlink--service_accounts">
                    <TableHead>
                        <TableRow>
                        <TableCell>
                        <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="serviceLineName" onChange={handleChangeSorting}>
                                    <span className="font-wt-900">SERVICE LINE </span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                  <span className="font-wt-900">USER</span>
                            </TableCell>
                            <TableCell>  
                                 <span className="font-wt-900">DESCRIPTION</span>
                            </TableCell>
                            <TableCell>
                                   <span className="font-wt-900">RESOURCE</span>
                             </TableCell>
                            <TableCell>
                                   <span className="font-wt-900">ACTION</span>
                            </TableCell>
                            <TableCell>
                                   <span className="font-wt-900">IP ADDRESS</span>
                             </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="createdAt" onChange={handleChangeSorting}>
                                    <span className="font-wt-900">TIME STAMP</span>
                                </SortArrows>
                                </TableCell>
                            <TableCell>
                                    <span className="font-wt-900">METADATA</span>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {auditLogs && auditLogs.length > 0 && auditLogs.map((log:any) => {
                            return <TableRow>
                                 <TableCell>
                                 <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                                <Grid>
                                                    <Table size="small">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>Account Number</TableCell>
                                                                <TableCell>{log.accountNumber ? log.accountNumber : "-"}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>Service Line Number</TableCell>
                                                                <TableCell>{log.serviceLineNumber ? log.serviceLineNumber : "-"}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            }>
                                    <span >{log.serviceLineName ? log?.serviceLineName : "-"}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <span >{log.users ? log.users : "-"}</span>
                                </TableCell>
                                <TableCell>
                                    <span >{log?.userAction ? log?.userAction : ""}</span>
                                </TableCell>
                                <TableCell>
                                    <span >{log?.resource ? log?.resource : "-"}</span>
                                </TableCell>
                                <TableCell>
                                    <span >{log?.userAction ? log?.userAction : "-"}</span>
                                </TableCell>
                                <TableCell>
                                    <span >{log?.IpAddress  ? log?.IpAddress : "-"}</span>
                                </TableCell>
                                <TableCell>
                                    <span >{log?.createdAt ? convertDateTimeIntoTimezone(log?.createdAt, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : "-"}</span>
                                </TableCell>
                                <TableCell>
                                    <span >
                                         <JsonDataTooltip className="prettyToolTip" data={log.metaData} />
                                    </span>
                                </TableCell>
                            </TableRow>
                        })

                        }
                    </TableBody>
                </Table>
            </TableContainer>

        </Paper>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    totalAuditLogs: state.starlinkAdmin.totalAuditLogs, 
    auditLogs:state.starlinkAdmin.auditLogs,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
    
});

export default withRouter(
    connect(mapStateToProps, {
        getStarlinkAuditLogs,
        clearComponentData
    })(AuditLogs)
);