import React, { useState, useEffect, createContext } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment, { Moment } from "moment-timezone";
import { getDecodeURI, getEncodedURI, readableBytesAsGB, strToK4List } from "../../utils/util";
import { 
  getUsageWidgets,
  getUsageTableData,
  getLineChartUsage,
  getAllUsernames,
  getCategoryPieChartUsage,
  getTopApplicationLineChart,
  getTopSitesTableData,
  getTopAppTopBlockedPieChart,
  getTopDownloadUploadBlockedApplication,
  getContentFiltersTable,
  getTopDownloadUploadBlockedDomain,
  getTopDownloadUploadBlockedIp,
  getTopWidgetRiskScore,
  getTopWidgetSingleSiteRiskScore,
  getPieChartAllSiteRiskScore,
  getPieChartSingleSiteRiskScore
} from "../../actions/Users/authenticateUsageReports";
import { updateSelectedVessels } from "../../actions/Users/authenticate";
import _ from "lodash";
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../actions/types";
import DownloadCSV, { clearCsvDownload } from "../../components/DownloadCSV";
import { toast } from "react-toastify";


export  const UsageContext = createContext(Object.assign({}));

const convertToCsvTopSites = (data: any[][]) => {
  const [_columns, ...rows] = data;
  const csvRows: any[] = [];
  rows.forEach(row => {
    csvRows.push([row[0], row[2], row[3], row[4], row[5], row[8], row[9], row[10], row[13], row[12], row[11], row[14]])
  })
  return [['Site Name', 'Total (GB)', 'Upload Size (GB)', 'Download Size (GB)', 'Top Apps', 'CELLULAR (GB)', 'VSAT (GB)', 'STARLINK (GB)', 'LBAND (GB)', 'ETHERNET (GB)', 'WIFI (GB)', 'BOND (GB)'], ...csvRows]
}

const convertToCsvTopUsers = (data: any[][]) => {
  const [_columns, ...rows] = data;
  const csvRows: any[] = [];
  rows.forEach(row => {
    csvRows.push([row[14] == 1 ? `${row[0]}*` : row[0], row[1], row[2], row[7], row[9], row[8], row[4], row[5], row[6], row[3],  row[12], row[16], row[17], row[18], row[21], row[20], row[19], row[22]])
  })
  return [['Device Alias', 'IP Address', 'Mac Address', 'Site', 'Device', 'VLAN', 'Total (GB)', 'Upload (GB)', 'Download (GB)', 'Top Apps', 'Top Risk', 'CELLULAR (GB)', 'VSAT (GB)', 'STARLINK (GB)', 'LBAND (GB)', 'ETHERNET (GB)', 'WIFI (GB)', 'BOND (GB)'], ...csvRows]
}

const UsageContextProvider = (props) => {
  const { 
    authReducer,
    getUsageWidgets,
    getUsageTableData,
    getLineChartUsage,
    getCategoryPieChartUsage,
    getTopApplicationLineChart,
    getTopSitesTableData,
    getTopAppTopBlockedPieChart,
    getTopDownloadUploadBlockedApplication,
    getTopDownloadUploadBlockedDomain,
    getTopDownloadUploadBlockedIp,
    getContentFiltersTable,
    updateSelectedVessels,
    isSitesLoading,
    newSummaryStartDate,
    newSummaryEndDate,
    newSummaryWanType,
    location,
    history,
    downloadAsCSV,
    getTopWidgetRiskScore,
    getTopWidgetSingleSiteRiskScore,
    getPieChartAllSiteRiskScore,
    getPieChartSingleSiteRiskScore
  } = props;
  const _q = getDecodeURI(location?.search);
  const DEFAULT_PAGE_SIZE = 10;
  const [userName, setUserName] = useState(_q.hasOwnProperty('userName') && _q.userName!=='' ? _q.userName : '');
  const [category, setCategory] = useState(_q.hasOwnProperty('category') && _q.category!=='' ? _q.category : '');
  const [appFilt, setAppFilt] = useState(_q.hasOwnProperty('appFilt') && _q.appFilt!=='' ? _q.appFilt : '');
  const [vlanFilt, setVlanFilt] = useState(_q.hasOwnProperty('vlan') && _q.vlan!=='' ? _q.vlan : '');
  const [deviceFilt, setDeviceFilt] = useState(_q.hasOwnProperty('device') && _q.device!=='' ? _q.device : '');

  const [selectedVessels, setSelectedVessels] = useState<any[]>([]);
  const [selectedOu, setSelectedOu ] = useState<any>("");
  const [vessels, setVessels] = useState<any>([]);
  const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
  const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
  const [pageStart, setPageStart] = useState(_q.hasOwnProperty('pageStart') ? parseInt(_q.pageStart) : 1);
  const [pageLimit, setPageLimit] = useState(_q.hasOwnProperty('pageLimit') ? parseInt(_q.pageLimit) : DEFAULT_PAGE_SIZE);
  const USAGE_OVER_TIME = "usage_over_time";
  const TOP_USAGE = "top_usage";
  const [activeTab, setActiveTab] = useState(USAGE_OVER_TIME);
  const [lineChartTab, setLineChartTab] = useState(USAGE_OVER_TIME);
  const [trackTab, setTrackTab] = useState(false);
  const [application, setApplication] = useState("");
  const [selectedAppColor, setSelectedAppColor] = useState('');
  
  const TOP_APPLICATION = "top_aaplication";
  const TOP_DOMAIN = "top_domain";
  const TOP_IP = "top_ip";
  const CONTENT_FILTERING = 'content_filtering';
  const [selectTab, setSelectTab] = useState(TOP_APPLICATION);
  
  let reportType;
  const [resetDate, setResetDate] = useState<any>([])

  const [bottomTableLimit, setBottomTableLimit] = useState<any>(_q.hasOwnProperty('cardLimit') ? _q.cardLimit : 10);
  const [categoryPieLimit, setCategoryPieLimit] = useState<any>(_q.hasOwnProperty('pieLimit') ? _q.pieLimit : 10);
  const [topAppLimit, setTopAppLimit] = useState(10);

  const [topApplicationUsage, setTopApplicationUsage] = useState<any>([]);
  const [lineChartData, SetLineChartData] = useState<any>([]);
  const [userNameTrack, setUserNameTrack] = useState(_q.hasOwnProperty('userName') ? true : false);
  const [categorTrack, setCategoryTrack] = useState(_q.hasOwnProperty('category') ? true : false);
  const [trackFilter, setTrackFilter] = useState(false);
  const [trackSite, setTrackSite] = useState(false);
  const [trackLength, setTrackLength] = useState(false);
  const values = {selectedOu, vessels, userName, category, appFilt, page, limit, pageStart, pageLimit, vlanFilt, deviceFilt}
  const [reportsPopup, setReportsPopup] = useState(false);
  const dispatch = useDispatch();
  const [checkUser, setCheckUser] = useState(_q.hasOwnProperty('userCheck') ? _q.userCheck : '');
  const getInterval = (minutes) => {
    if (minutes <= 1440) {
      return "15m";
    } else if (minutes > 1440 && minutes <= 10080) {
      return "1h";
    } else {
      return "4h";
    }
  }
  const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
  const TOTAL = 'total';
  const DESC_ORDER = "desc";
  const [sortSite, setSortSite] = useState<any>(_q.hasOwnProperty("sortSite") ? _q.sortSite : TOTAL);
  const [sortOrderSite, setSortOrderSite] = useState<any>(_q.hasOwnProperty("sortOrderSite") ? _q.sortOrderSite : DESC_ORDER);
  const [sortUser, setSortUser] = useState<any>(_q.hasOwnProperty("sortUser") ? _q.sortUser : TOTAL);
  const [sortOrderUser, setSortOrderUser] = useState<any>(_q.hasOwnProperty("sortOrderUser") ? _q.sortOrderUser : DESC_ORDER);

  const handleApplicationFilterClick = (app, color, index, tab) => {
    setSelectedAppColor(color);
    let all_true = true;
    let updatedData:any = tab == 'top_usage' ? [...topApplicationUsage] : [...lineChartData];
    let all_off_turn_on = 0;
    let click = 0;

    updatedData?.forEach(( ele, i)=>{
      if( !ele.visible ){
        all_true = false;
        all_off_turn_on +=1;
      }
      if(ele.visible){
        click = i;
      }
    })
    
    if(click == index && all_off_turn_on == updatedData.length-1 ){
        updatedData?.forEach((ele,i)=>{
        updatedData[i] = { ...ele, visible:true }
       })
      tab == 'top_usage' ? setTopApplicationUsage([...updatedData]) : SetLineChartData([...updatedData]);
    }
    
    else if(all_true){
      updatedData?.forEach((ele,i)=>{
       updatedData[i] = { ...ele, visible:false }
      })
      updatedData[index].visible = true;
      tab == 'top_usage' ? setTopApplicationUsage([...updatedData]) : SetLineChartData([...updatedData]);
    }
    else{
      updatedData.forEach((ele,i)=>{
        updatedData[i] = ele;
       })
      updatedData[index].visible = !updatedData[index].visible; 
      tab == 'top_usage' ? setTopApplicationUsage([...updatedData]) : SetLineChartData([...updatedData]);
    }
  }
  
  const handleChangeBottomTableLimit = (event) => {
    const value = event.target.value;
    setBottomTableLimit(value)
    selectTab == TOP_IP ? reportType = 'remote_ip' : selectTab == TOP_DOMAIN ? reportType = 'domain' : reportType = 'application';
    let params: any = getDecodeURI(location.search);
    params.cardLimit = value;
    doNavigate(params);
    if(vessels?.length > 0) {
      if(selectTab == TOP_APPLICATION) {
        getTopDownloadUploadBlockedApplication(selectedOu, vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, 'application', value, vlanFilt, deviceFilt, checkUser, newSummaryWanType);
      } else if(selectTab == TOP_DOMAIN) {
        getTopDownloadUploadBlockedDomain(selectedOu, vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, 'domain', value, vlanFilt, deviceFilt, checkUser, newSummaryWanType);
      } else {
        getTopDownloadUploadBlockedIp(selectedOu, vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, 'remote_ip', value, vlanFilt, deviceFilt, checkUser, newSummaryWanType);
      }
    }
  }

  const handleChangeCategoryPieLimit = (event) => {
    const value = event.target.value;
    setCategoryPieLimit(value);
    let params: any = getDecodeURI(location.search);
    params.pieLimit = value;
    doNavigate(params);
    if(vessels?.length > 0) {
      getCategoryPieChartUsage(selectedOu, vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, value, vlanFilt, deviceFilt, checkUser, newSummaryWanType);
      getTopAppTopBlockedPieChart(selectedOu, vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, value, vlanFilt, deviceFilt, checkUser, newSummaryWanType);
      if(vessels.length == 1) {
        getPieChartSingleSiteRiskScore(vessels, newSummaryStartDate, newSummaryEndDate, userName, value, checkUser);
      } else {
        getPieChartAllSiteRiskScore(vessels, newSummaryStartDate, newSummaryEndDate, userName, value, checkUser)
      }
    }
  }

  const handleChangeTopAppLimit = (event) => {
    const interval = getInterval(Math.floor(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
    const value = event.target.value;
    setTopAppLimit(value);
    if(vessels?.length > 0) {
      getTopApplicationLineChart(selectedOu, vessels, newSummaryStartDate, newSummaryEndDate, userName, category, value, interval, appFilt, vlanFilt, deviceFilt, checkUser, newSummaryWanType)
    }
  }

  const pinchAndResetChart = (start, end, interval) => {
      let params = getDecodeURI(location?.search);
      params.startDate = start.valueOf();
      params.endDate = end.valueOf();
      params.interval = interval;
      doNavigate(params);
  }

  const handleResetZoom = () => {
    if(resetDate?.length == 0) {
      return;
    }
    let arr:any = resetDate;
    arr.pop();
    setResetDate(arr);
    if(arr?.length == 0) {
      const start = moment(Date.now()).subtract(1, 'hour');
      const end = moment(Date.now());
      pinchAndResetChart(start, end, '1h');
    }
    else {
      let obj = arr[arr?.length-1];
      let start = obj.startDate;
      let end = obj.endDate;
      pinchAndResetChart(start, end, 'customDates');
    }
  }

  const handleDefaultResetZoom = () => {
    setResetDate([]);
    const start = moment(Date.now()).subtract(1, 'hour');
    const end = moment(Date.now());
    pinchAndResetChart(start, end, '1h');
  }

  const activityReportsApiCall = (ouName, vessels, startDate, endDate, userName, category, appFilt, page, limit, pageStart, pageLimit, vlanFilt, deviceFilt, userCheck, _selectedBin, wanType) => {
    const interval = _selectedBin ? _selectedBin : getInterval(Math.floor(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3));

    selectTab == TOP_IP ? reportType = 'remote_ip' : selectTab == TOP_DOMAIN ? reportType = 'domain' : reportType = 'application';

    if(userName == null) {
      userName = '';
    }
    if(category == null) {
      category='';
    }
    
    if(trackTab) {
      if(activeTab==USAGE_OVER_TIME) getLineChartUsage(ouName, vessels, startDate, endDate, userName, category, appFilt, interval, vlanFilt, deviceFilt, userCheck, wanType);
      else if(activeTab==TOP_USAGE) getTopApplicationLineChart(ouName, vessels, startDate, endDate, userName, category, topAppLimit, interval, appFilt, vlanFilt, deviceFilt, userCheck, wanType);
      else if(activeTab == TOP_APPLICATION || activeTab == TOP_DOMAIN || activeTab == TOP_IP) {
        if(selectTab == TOP_APPLICATION) {
          getTopDownloadUploadBlockedApplication(ouName, vessels, startDate, endDate, userName, category, appFilt, 'application', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
        } else if(selectTab == TOP_DOMAIN) {
          getTopDownloadUploadBlockedDomain(ouName, vessels, startDate, endDate, userName, category, appFilt, 'domain', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
        } else {
          getTopDownloadUploadBlockedIp(ouName, vessels, startDate, endDate, userName, category, appFilt, 'remote_ip', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
        }
      }
      else if(activeTab == CONTENT_FILTERING) {
        getContentFiltersTable(vessels, startDate, endDate, bottomTableLimit)
      }
    }
    if(activeTab == USAGE_OVER_TIME && !trackTab) {
      getUsageWidgets(ouName, vessels, startDate, endDate, userName, category, appFilt, vlanFilt, deviceFilt, userCheck, wanType);
      if(vessels.length == 1) {
        getTopWidgetSingleSiteRiskScore(vessels, startDate, endDate, userName, userCheck);
        getPieChartSingleSiteRiskScore(vessels, startDate, endDate, userName, categoryPieLimit, userCheck);
      } else {
        getTopWidgetRiskScore(vessels, startDate, endDate, userName, userCheck);
        getPieChartAllSiteRiskScore(vessels, startDate, endDate, userName, categoryPieLimit, userCheck)
      }
      getLineChartUsage(ouName, vessels, startDate, endDate, userName, category, appFilt, interval, vlanFilt, deviceFilt, userCheck, wanType);
      getCategoryPieChartUsage(ouName, vessels, startDate, endDate, userName, category, appFilt, categoryPieLimit, vlanFilt, deviceFilt, userCheck, wanType);
      getUsageTableData(vessels, startDate, endDate, userName, category, appFilt, page, limit, vlanFilt, deviceFilt, userCheck, sortUser, sortOrderUser, wanType);
      getTopSitesTableData(vessels, startDate, endDate, userName, category, appFilt, pageStart, pageLimit, vlanFilt, deviceFilt, userCheck, sortSite, sortOrderSite, wanType);
      getTopAppTopBlockedPieChart(ouName, vessels, startDate, endDate, userName, category, appFilt, categoryPieLimit, vlanFilt, deviceFilt, userCheck, wanType);
      if(selectTab == TOP_APPLICATION) {
        getTopDownloadUploadBlockedApplication(ouName, vessels, startDate, endDate, userName, category, appFilt, 'application', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
      } else if(selectTab == TOP_DOMAIN) {
        getTopDownloadUploadBlockedDomain(ouName, vessels, startDate, endDate, userName, category, appFilt, 'domain', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
      } else {
        getTopDownloadUploadBlockedIp(ouName, vessels, startDate, endDate, userName, category, appFilt, 'remote_ip', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
      }
    }
    else if(activeTab==TOP_USAGE && !trackTab){
      getTopApplicationLineChart(ouName, vessels, startDate, endDate, userName, category, topAppLimit, interval, appFilt, vlanFilt, deviceFilt, userCheck, wanType)
      getUsageWidgets(ouName, vessels, startDate, endDate, userName, category, appFilt, vlanFilt, deviceFilt, userCheck, wanType);
      if(vessels.length == 1) {
        getTopWidgetSingleSiteRiskScore(vessels, startDate, endDate, userName, userCheck)
        getPieChartSingleSiteRiskScore(vessels, startDate, endDate, userName, categoryPieLimit, userCheck);
      } else {
        getTopWidgetRiskScore(vessels, startDate, endDate, userName, userCheck);
        getPieChartAllSiteRiskScore(vessels, startDate, endDate, userName, categoryPieLimit, userCheck)
      }
      getCategoryPieChartUsage(ouName, vessels, startDate, endDate, userName, category, appFilt, categoryPieLimit, vlanFilt, deviceFilt, userCheck, wanType);
      getUsageTableData(vessels, startDate, endDate, userName, category, appFilt, page, limit, vlanFilt, deviceFilt, userCheck, sortUser, sortOrderUser, wanType);
      getTopSitesTableData(vessels, startDate, endDate, userName, category, appFilt, pageStart, pageLimit, vlanFilt, deviceFilt, userCheck, sortSite, sortOrderSite, wanType);
      getTopAppTopBlockedPieChart(ouName, vessels, startDate, endDate, userName, category, appFilt, categoryPieLimit, vlanFilt, deviceFilt, userCheck, wanType);
      if(selectTab == TOP_APPLICATION) {
        getTopDownloadUploadBlockedApplication(ouName, vessels, startDate, endDate, userName, category, appFilt, 'application', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
      } else if(selectTab == TOP_DOMAIN) {
        getTopDownloadUploadBlockedDomain(ouName, vessels, startDate, endDate, userName, category, appFilt, 'domain', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
      } else {
        getTopDownloadUploadBlockedIp(ouName, vessels, startDate, endDate, userName, category, appFilt, 'remote_ip', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
      }
    }
    else if((activeTab == TOP_APPLICATION || activeTab == TOP_DOMAIN || activeTab == TOP_IP || activeTab == CONTENT_FILTERING) && !trackTab){
      if(lineChartTab == USAGE_OVER_TIME) getLineChartUsage(ouName, vessels, startDate, endDate, userName, category, appFilt, interval, vlanFilt, deviceFilt, userCheck, wanType) 
      else getTopApplicationLineChart(ouName, vessels, startDate, endDate, userName, category, topAppLimit, interval, appFilt, vlanFilt, deviceFilt, userCheck, wanType)
      getUsageWidgets(ouName, vessels, startDate, endDate, userName, category, appFilt, vlanFilt, deviceFilt, userCheck, wanType);
      if(vessels.length == 1) {
        getTopWidgetSingleSiteRiskScore(vessels, startDate, endDate, userName, userCheck);
        getPieChartSingleSiteRiskScore(vessels, startDate, endDate, userName, categoryPieLimit, userCheck);
      } else {
        getTopWidgetRiskScore(vessels, startDate, endDate, userName, userCheck);
        getPieChartAllSiteRiskScore(vessels, startDate, endDate, userName, categoryPieLimit, userCheck);
      }
      getCategoryPieChartUsage(ouName, vessels, startDate, endDate, userName, category, appFilt, categoryPieLimit, vlanFilt, deviceFilt, userCheck, wanType);
      getUsageTableData(vessels, startDate, endDate, userName, category, appFilt, page, limit, vlanFilt, deviceFilt, userCheck, sortUser, sortOrderUser, wanType);
      getTopSitesTableData(vessels, startDate, endDate, userName, category, appFilt, pageStart, pageLimit, vlanFilt, deviceFilt, userCheck, sortSite, sortOrderSite, wanType);
      getTopAppTopBlockedPieChart(ouName, vessels, startDate, endDate, userName, category, appFilt, categoryPieLimit, vlanFilt, deviceFilt, userCheck, wanType);
      if(selectTab == TOP_APPLICATION) {
        getTopDownloadUploadBlockedApplication(ouName, vessels, startDate, endDate, userName, category, appFilt, 'application', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
      } else if(selectTab == TOP_DOMAIN) {
        getTopDownloadUploadBlockedDomain(ouName, vessels, startDate, endDate, userName, category, appFilt, 'domain', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
      } else {
        getTopDownloadUploadBlockedIp(ouName, vessels, startDate, endDate, userName, category, appFilt, 'remote_ip', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
      }
    }
    if(_q.hasOwnProperty('topUsage')) {
      getTopApplicationLineChart(ouName, vessels, startDate, endDate, userName, category, topAppLimit, interval, appFilt, vlanFilt, deviceFilt, userCheck, wanType);
      getTopDownloadUploadBlockedDomain(ouName, vessels, startDate, endDate, userName, category, appFilt, 'domain', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
      getTopDownloadUploadBlockedIp(ouName, vessels, startDate, endDate, userName, category, appFilt, 'remote_ip', bottomTableLimit, vlanFilt, deviceFilt, userCheck, wanType);
    }
  }

  const handleUsernameChange = (e, newValue) => {
    newValue?.label ? setUserName(newValue.label) : setUserName('');
    newValue?.userFilter ? setCheckUser(newValue.userFilter) : setCheckUser('');
    setUserNameTrack(false);
    setPage(1);
    setPageStart(1);
  }

  const handleCategoryChange = (e, newValue) => {
    setCategory(newValue)
    setCategoryTrack(false);
  }

  const handleAppFilterChange = (e, newValue) => {
    setAppFilt(newValue);
  }

  const handleVlanFilterChange = (e, newValue) => {
    setVlanFilt(newValue);
  }

  const handleDeviceFilterChange = (e, newValue) => {
    setDeviceFilt(newValue);
  }

  const selectFilter = (filters: any = {}) => {
    const page = 1;
    const pageStart = 1;
    if (isSitesLoading)
      return;
    const { filterUserName = userName, filterSite, filterAppFilt = appFilt , filterCategory = category, vlanFilter, deviceFilter, userCheck } = filters;
    setCheckUser(userCheck);
    let params: any = getDecodeURI(location.search);
    let _selectedVessels = vessels;
    if(_.isString(deviceFilter) && deviceFilter.length > 0) {
      params.device = deviceFilter;
    } else {
      delete params.device;
      delete params.page;
      delete params.pageStart;
    }
    if(_.isString(vlanFilter) && vlanFilter.length > 0) {
      params.vlan = vlanFilter;
    } else {
      delete params.vlan;
      delete params.page;
      delete params.pageStart;
    }
    if(_.isString(filterCategory) && filterCategory.length > 0) {
      params.category = filterCategory;
    } else {
      delete params.category;
      delete params.page;
      delete params.pageStart;
    }
    if (_.isString(filterUserName) && filterUserName.length > 0) {
      setUserName(filterUserName);
      params.userName = filterUserName;
      userCheck ? params.userCheck = userCheck : delete params.userCheck;
    } else {
      delete params.userName;
      delete params.page;
      delete params.pageStart;
      delete params.userCheck;
    }
    if (_.isString(filterAppFilt) && filterAppFilt.length > 0) {
      params.appFilt = filterAppFilt;
    } else {
      delete params.appFilt;
      delete params.page;
      delete params.pageStart;
    }
    const user = userCheck!==undefined ? userCheck : '';
    if (_.isString(filterSite)) {
      const currentSelectedVessels = authReducer?.filteredVessels ?? [];
      // do not call api if same site
      if ((currentSelectedVessels.length == 1 && currentSelectedVessels[0]['vessel-name'] === filterSite)) {
          vlanFilter ? params.vlan = vlanFilter : delete params.vlanFilt;
          doNavigate(params);
          activityReportsApiCall(selectedOu, currentSelectedVessels, newSummaryStartDate, newSummaryEndDate, filterUserName, filterCategory, filterAppFilt, page, limit, pageStart, pageLimit, vlanFilter, deviceFilter, user, selectedBin, newSummaryWanType)
        return;
      }
      delete params.k4Ids;
      delete params.page;
      delete params.pageStart;
      if (filterSite.length > 0 && _.isArray(vessels)) {
        const vessel = vessels[0]['name'] ? vessels.find(v => v['name'] === filterSite) : vessels.find(v => v['vessel-name'] === filterSite);
        if (vessel) {
          params.k4Ids = vessel['name'] ? `${vessel.id}:${vessel['name']}` : `${vessel.id}:${vessel['vessel-name']}`;
          doNavigate(params);
          return
        }
      }else {
        _selectedVessels = [];
        updateSelectedVessels({ isAll: true, vessels: [] });
      }
    } else if (vessels?.length > 0) {
        activityReportsApiCall(selectedOu, vessels, newSummaryStartDate, newSummaryEndDate, filterUserName, filterCategory, filterAppFilt, page, limit, pageStart, pageLimit, vlanFilter, deviceFilter, user, selectedBin, newSummaryWanType)
    }
    doNavigate(params);
  }

  const applyFilterClick = () => {
    setUserNameTrack(true);
    setCategoryTrack(true);
    let params: any = getDecodeURI(location.search);
    params.startDate = newSummaryStartDate.valueOf();
    params.endDate = newSummaryEndDate.valueOf();
    userName ? params.userName = userName : delete params.userName;
    checkUser ? params.userCheck = checkUser : delete params.userCheck;
    category ? params.category = category : delete params.category;

    if(_.isString(appFilt) && appFilt.length > 0) {
      params.appFilt = appFilt;
    } else {
      delete params.appFilt;
    }
    doNavigate(params);
    if((vessels.length > 0 && !trackFilter && !trackSite && !trackLength)) {
      activityReportsApiCall(selectedOu, vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, page, limit, pageStart, pageLimit, vlanFilt, deviceFilt, checkUser, selectedBin, newSummaryWanType)
    } else if((vessels.length > 0 && trackSite) || (vessels.length > 0 && trackLength)) {
      const start = moment(Date.now()).subtract(1, 'days');
      const end = moment(Date.now());
      delete params.startDate;
      delete params.endDate;
      doNavigate(params);
      activityReportsApiCall(selectedOu, vessels, start, end, userName, category, appFilt, page, limit, pageStart, pageLimit, vlanFilt, deviceFilt, checkUser, selectedBin, newSummaryWanType);
      setTrackSite(false);
      setTrackLength(false);
    }
  }

  const clearFilters = () => {
    setUserName('');
    setCategory('');
    setAppFilt('');
    setCheckUser('');
    let params: any = getDecodeURI(location.search);
    delete params.userName;
    delete params.userCheck;
    delete params.category;
    delete params.appFilt;
    doNavigate(params);
    if(vessels?.length > 0) {
      activityReportsApiCall(selectedOu, vessels, newSummaryStartDate, newSummaryEndDate, '', '', '', page, limit, pageStart, pageLimit, vlanFilt, deviceFilt, checkUser, selectedBin, newSummaryWanType);
    }
  }

  const getIntervalCheck = (minutes) => {
    if (minutes > 10080) {
      return true;
    } 
    return false;
  }

  useEffect(() => {
    if (_.isEmpty(authReducer?.getVesselsListing))
            return;
    let vessels = [];
    const filteredVessels = authReducer?.filteredVessels;
    if(authReducer?.getVesselsListing?.locations?.length > 0 && _q.hasOwnProperty('topAppClick')){
      if(filteredVessels && filteredVessels.length == 1){
       vessels = filteredVessels;
      }
    } else if (filteredVessels && filteredVessels.length > 0) {
        vessels = filteredVessels;
    } else {
        vessels = authReducer?.getVesselsListing?.locations;
    }
    setVessels(vessels);
  }, [authReducer?.getVesselsListing, authReducer?.filteredVessels]);

  useEffect(() => {
    const checkInterval = getIntervalCheck(Math.floor(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
    setTrackTab(false);
    setPage(1)
    setPageStart(1); 
    if(vessels.length > 1 && checkInterval) {
      setTrackLength(true);
    }
    if(vessels.length > 0 && vessels?.length == authReducer?.getVesselsListing?.locations?.length && !trackSite && !checkInterval) {
      // All sites
        activityReportsApiCall('', vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, 1, limit, 1, pageLimit, vlanFilt, deviceFilt, checkUser, selectedBin, newSummaryWanType);
    } else if(vessels.length == 1) {
      // site filter click
      activityReportsApiCall('', vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, 1, limit, 1, pageLimit, vlanFilt, deviceFilt, checkUser, selectedBin, newSummaryWanType);
    } else if(vessels.length > 0 && !checkInterval) {
      // multiple sites
      activityReportsApiCall('', vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, 1, limit, 1, pageLimit, vlanFilt, deviceFilt, checkUser, selectedBin, newSummaryWanType);
    } else if(vessels.length > 0 && _q.isFixedIntervalDisabled) {
      activityReportsApiCall('', vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, 1, limit, 1, pageLimit, vlanFilt, deviceFilt, checkUser, selectedBin, newSummaryWanType);
    }
  }, [vessels, activeTab, newSummaryWanType])

  useEffect(() => {
    const checkInterval = getIntervalCheck(Math.floor(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
    setTrackTab(false);
    setPage(1)
    setPageStart(1); 
    if(vessels.length > 1 && checkInterval) {
      setTrackLength(true);
    }
    const _interval = getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
    setSelectedBin(_interval);
    if(vessels.length > 0 && vessels?.length == authReducer?.getVesselsListing?.locations?.length && !trackSite && !checkInterval) {
        activityReportsApiCall('', vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, 1, limit, 1, pageLimit, vlanFilt, deviceFilt, checkUser, _interval, newSummaryWanType);
    } else if(vessels.length == 1) {
      activityReportsApiCall('', vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, 1, limit, 1, pageLimit, vlanFilt, deviceFilt, checkUser, _interval, newSummaryWanType);
    } else if(vessels.length > 0 && !checkInterval) {
      activityReportsApiCall('', vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, 1, limit, 1, pageLimit, vlanFilt, deviceFilt, checkUser, _interval, newSummaryWanType);
    } else if(vessels.length > 0 && _q.isFixedIntervalDisabled) {
      activityReportsApiCall('', vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, 1, limit, 1, pageLimit, vlanFilt, deviceFilt, checkUser, _interval, newSummaryWanType);
    }
  }, [newSummaryStartDate, newSummaryEndDate])

  useEffect(() => {
    if (!authReducer.csvDataDownloded || !authReducer.csvDataDownloded.length) return;
    if(authReducer.csvDownloadId === 'usage-top-sites') {
      const [columns, _countRow, ...rows] = authReducer.csvDataDownloded;
      DownloadCSV(convertToCsvTopSites([columns, ...rows]), {
        formatters: {
          0: 'ESC-COMMA',
          1: 'GB',
          2: 'GB',
          3: 'GB',
          4: 'ESC-COMMA',
          5: 'GB',
          6: 'GB',
          7: 'GB',
          8: 'GB',
          9: 'GB',
          10: 'GB',
          11: 'GB',
        }
      });
      return () => {
        dispatch(clearCsvDownload())
      }
    }
    else if (authReducer.csvDownloadId === 'usage-top-users') {
      const [columns, _countRow, ...rows] = authReducer.csvDataDownloded;
      DownloadCSV(convertToCsvTopUsers([columns, ...rows]), {
        formatters: {
          0: 'ESC-COMMA',
          3: 'ESC-COMMA',
          4: 'ESC-COMMA',
          6: 'GB',
          7: 'GB',
          8: 'GB',
          9: 'ESC-COMMA',
          11: 'GB',
          12: 'GB',
          13: 'GB',
          14: 'GB',
          15: 'GB',
          16: 'GB',
          17: 'GB',
        }
      });
      return () => {
        dispatch(clearCsvDownload())
      }
    }
  }, [authReducer.csvDataDownloded])

  const doNavigate = (params) => {
    history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
  }

  const handleChangePageUser = (e, value) => {
    let params: any = getDecodeURI(location?.search);
    params.page = value;
    setPage(value);
    doNavigate(params);
    if(vessels?.length > 0) {
      getUsageTableData(vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, value, limit, vlanFilt, deviceFilt, checkUser, sortUser, sortOrderUser, newSummaryWanType);
    }
  }

  const handleChangeRowsPerPageUser = (e) => {
    const Limit = parseInt(e.target.value);
    let params: any = getDecodeURI(location?.search);
    params.page = 1;
    params.limit = Limit
    setPage(1);
    setLimit(Limit);
    doNavigate(params);
    if(vessels?.length > 0) {
      getUsageTableData(vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, 1, Limit, vlanFilt, deviceFilt, checkUser, sortUser, sortOrderUser, newSummaryWanType);
    }
   }

  const handleChangePageSite = (e, value) => {
    let params: any = getDecodeURI(location?.search);
    params.pageStart = value;
    setPageStart(value);
    doNavigate(params);
    if(vessels?.length > 0) {
      getTopSitesTableData(vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, value, pageLimit, vlanFilt, deviceFilt, checkUser, sortSite, sortOrderSite, newSummaryWanType);
    }
  }

const handleChangeRowsPerPageSite = (e) => {
  const Limit = parseInt(e.target.value);
  let params: any = getDecodeURI(location?.search);
  params.pageStart = 1;
  params.pageLimit = Limit
  setPageStart(1);
  setPageLimit(Limit);
  doNavigate(params);
  if(vessels?.length > 0) {
    getTopSitesTableData(vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, 1, Limit, vlanFilt, deviceFilt, checkUser, sortSite, sortOrderSite, newSummaryWanType);
  }
 }

  const chartAreaSelectionUsage = () => {
    return (event: any) => {
      let start: any = moment(new Date(Math.ceil(event.xAxis[0].min)));
      let end = moment(new Date(Math.floor(event.xAxis[0].max)));
      setPage(1);
      setPageStart(1);
      pinchAndResetChart(start, end, 'customDates')
      let arr:any = resetDate;
      arr.push({startDate: start, endDate: end});
      setResetDate(arr);
      return false;
    }
  }

  const chartAreaSelectionTopUsage = () => {
    return (event: any) => {
      let start: any = moment(new Date(Math.ceil(event.xAxis[0].min)));
      let end = moment(new Date(Math.floor(event.xAxis[0].max)));
      setPage(1);
      setPageStart(1);
      pinchAndResetChart(start, end, 'customDates')
      let arr:any = resetDate;
      arr.push({startDate: start, endDate: end});
      setResetDate(arr);
      return false;
    }
  }

  const handleTopUsageOnCsvDownload = (count: number) => {
    const isClickHouse = true;
    if (vessels.length === 0) {
      toast.error("No data available to download", {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      return;
    }
    const vesselMap: any = vessels?.map((item: any) => item["id"])
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    const params: CsvParameters = {
      type: isClickHouse ? 'QUERY_CH' : 'QUERY',
      id: 'usage-top-users',
      queryName: 'DPI_REPORTS_TOP_USERS_IP_TABLE_SORTING',
      payload: {
        dp: selectedOu,
        k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '',
        startDate: newSummaryStartDate,
        endDate: newSummaryEndDate,
        user: userName ? `and (hostname LIKE '%${userName}%' or ipAddress LIKE '%${userName}%' or macAddress LIKE '%${userName}%')` : '',
        category: category ? `and category LIKE '%${category}%'` : '',
        pageStart: 0,
        pageLimit: count,
        appFilt: appFilt ? `and usageFrom in ('${appFilt}')` : '',
        vlanName: vlanFilt ? `and vlanName = '${vlanFilt}'` : '',
        deviceName: deviceFilt ? `where deviceAliasName like '%${deviceFilt}%'` : '',
        sortColumn: sortUser,
        sortOrder: sortOrderUser,
        wan_type: newSummaryWanType && newSummaryWanType == 'vsat' ? `and wan_type ilike '${newSummaryWanType}%'` : newSummaryWanType && newSummaryWanType != 'vsat' ? `and wan_type='${newSummaryWanType}'` : ''
      }
    }
    downloadAsCSV(params);
  }

  const handleTopSiteOnCsvDownload = (count: number) => {
    const isClickHouse = true;
    if (vessels.length === 0) {
      toast.error("No data available to download", {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      return;
    }
    const vesselMap: any = vessels?.map((item: any) => item["id"])
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    const params: CsvParameters = {
      type: isClickHouse ? 'QUERY_CH' : 'QUERY',
      id: 'usage-top-sites',
      queryName: 'DPI_REPORTS_TOP_USAGE_BY_SITES_TABLE_SORTING',
      payload: {
        dp: selectedOu,
        k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '',
        startDate: newSummaryStartDate,
        endDate: newSummaryEndDate,
        user: userName ? `and (hostname LIKE '%${userName}%' or ipAddress LIKE '%${userName}%' or macAddress LIKE '%${userName}%')` : '',
        category: category ? `and category LIKE '%${category}%'` : '',
        pageStart: 0,
        pageLimit: count,
        appFilt: appFilt ? `and usageFrom in ('${appFilt}')` : '',
        vlanName: vlanFilt ? `and vlanName = '${vlanFilt}'` : '',
        deviceName: deviceFilt ? `where deviceAliasName like '%${deviceFilt}%'` : '',
        sortColumn: sortSite,
        sortOrder: sortOrderSite,
        wan_type: newSummaryWanType && newSummaryWanType == 'vsat' ? `and wan_type ilike '${newSummaryWanType}%'` : newSummaryWanType && newSummaryWanType != 'vsat' ? `and wan_type='${newSummaryWanType}'` : ''
      },
    }
    downloadAsCSV(params);
  }

  const handleBinChange = (e) => {
    const value = e.target.value;
    setSelectedBin(value);
    if(vessels?.length > 0) {
      if(activeTab == USAGE_OVER_TIME) getLineChartUsage(selectedOu, vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, value, vlanFilt, deviceFilt, checkUser, newSummaryWanType);
      else if(activeTab == TOP_USAGE) getTopApplicationLineChart(selectedOu, vessels, newSummaryStartDate, newSummaryEndDate, userName, category, topAppLimit, value, appFilt, vlanFilt, deviceFilt, checkUser, newSummaryWanType);
    }
  };

  const AllFunctions = {
    handleUsernameChange,
    handleCategoryChange,
    handleAppFilterChange,
    handleVlanFilterChange,
    handleDeviceFilterChange,
    newSummaryStartDate,
    newSummaryEndDate,
    userName,
    category,
    appFilt,
    applyFilterClick,
    selectFilter,
    clearFilters,
    handleChangePageSite,
    handleChangeRowsPerPageSite,
    handleChangePageUser,
    handleChangeRowsPerPageUser,
    page,
    limit,
    pageStart,
    pageLimit,
    vessels,
    setActiveTab,
    activeTab,
    setTrackTab,
    selectTab,
    setSelectTab,
    lineChartTab,
    setLineChartTab,
    handleResetZoom,
    handleDefaultResetZoom,
    resetDate,
    bottomTableLimit,
    handleChangeBottomTableLimit,
    categoryPieLimit,
    handleChangeCategoryPieLimit,
    topAppLimit,
    handleChangeTopAppLimit,
    handleApplicationFilterClick,
    application,
    selectedAppColor,
    setPageStart,
    setPage,
    topApplicationUsage,
    setTopApplicationUsage,
    lineChartData,
    SetLineChartData,
    selectedOu,
    selectedVessels,
    userNameTrack,
    setUserNameTrack,
    categorTrack,
    setCategoryTrack,
    vlanFilt, setVlanFilt,
    deviceFilt, 
    setDeviceFilt,
    setTrackFilter,
    setTrackSite, trackSite, values, activityReportsApiCall, trackLength, setTrackLength,
    chartAreaSelectionUsage, chartAreaSelectionTopUsage, reportsPopup, setReportsPopup,
    handleTopSiteOnCsvDownload,
    handleTopUsageOnCsvDownload,
    checkUser, selectedBin, handleBinChange,
    sortSite, sortOrderSite, setSortSite, setSortOrderSite, sortUser, sortOrderUser, setSortUser, setSortOrderUser
  }
    
  return(
    <UsageContext.Provider value={AllFunctions}>
        {props.children}
    </UsageContext.Provider>
  )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    isSitesLoading: state.authReducer.setVesselListingLoading,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    newSummaryWanType: state.authReducer.newSummaryWanType?.value
});

export default withRouter(
    connect(mapStateToProps, {
      getAllUsernames,
      getUsageWidgets,
      getUsageTableData,
      getLineChartUsage,
      getCategoryPieChartUsage,
      getTopApplicationLineChart,
      getTopSitesTableData,
      getTopAppTopBlockedPieChart,
      getTopDownloadUploadBlockedApplication,
      updateSelectedVessels,
      getContentFiltersTable,
      downloadAsCSV,
      getTopDownloadUploadBlockedDomain,
      getTopDownloadUploadBlockedIp,
      getTopWidgetRiskScore,
      getTopWidgetSingleSiteRiskScore,
      getPieChartAllSiteRiskScore,
      getPieChartSingleSiteRiskScore
    })(UsageContextProvider)
);