import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItemButton, Stack, Tooltip, Typography, Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import close from "../../asset/image/close.svg";
import _ from "lodash";
import ManageConfig, { Delete_Site, Firewall, Hub_IP, Hub_IP_Configure, Organization_Configuration, Quota, System_Configuration, Traffic_Policy, WAN_Profile} from "./ManageConfig";
import SystemConfig from "./SystemConfig";
import OrganizationConfig from "./OrganizationConfig";
import "./EditActionsPopup.css";
import { CONFIRM_DIALOG_TITLE } from "../../config";
import { deleteSite } from "../../actions/Users/authenticateInventory";
import { moveDeviceToWarehouse, getDeviceLatestEOSversion } from "../../actions/Users/authenticateInventory";
import { DEVICE_LATEST_EOS_VERSION, INVENTORY_ASSIGN_DEVICE_TO_WAREHOUSE } from "../../actions/types";
import { compare } from 'compare-versions';

const EditActionsPopup = (props) => {
    const { authReducer, setInventoryAssignDeviceToWarehouse, getDrawerOpen, inventoryAssignDeviceToWarehouse, moveDeviceToWarehouse, open, setOpen, row, setRow, confirmDialog, setConfirmDialog, inventoryChangeGroup, setUpgradeLicensePayload, isAuthorizedToBeta, deleteSite,
        deRegistrationReasons, handleReason, selectedReason, selectedReasonInput, setSelectedReason, setSelectedReasonInput, setDeRegistrationResons, specificErrodCode, setSpecificErrorCode, handleValidateTier, getDeviceLatestEOSversion
     } = props;

    const [isValidTier, setIsValidTier] = useState<boolean>(true)
    const deviceActionsList = [
        isValidTier && row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && Traffic_Policy,
        isValidTier && row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && Firewall,
        isValidTier && row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && WAN_Profile,
        isValidTier && row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && Hub_IP,
        isValidTier && row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && Quota,
        row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && System_Configuration,
        row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && Organization_Configuration,
        row.hasOwnProperty("sn") && _.isEmpty(row['sn']) && Delete_Site,
    ].filter(action => action);

    const [selectedAction, setSelectedAction] = useState(deviceActionsList[0]);
    const [ou, setOu] = useState<any>({});
    const [modifyConfigData, setModifyConfigData] = useState<any>();

    const [selectedManageConfigType, setSelectedManageConfigType] = useState<any>(Traffic_Policy);
    const [selectedManageConfigHubIPType, setSelectedManageConfigHubIPType] = useState<any>(Hub_IP_Configure);
    const dispatch = useDispatch();

    const [selectedDeviceLatestEosVersion, setSelectedDeviceLatestEosVersion] = useState<any>("");

    useEffect(() => {
        if (row && row["tierValue"]) {
            let isValid = handleValidateTier(row)
            setIsValidTier(isValid)
        }
        if(!_.isEmpty(row.deviceId)){
            getDeviceLatestEOSversion(row.deviceId)
        }
    }, [row])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.deviceLatestEosVersion)) {
            if (!_.isEmpty(authReducer.deviceLatestEosVersion?.data)) {
                let eosVersion = authReducer.deviceLatestEosVersion?.data?.app_version;
                if (!_.isEmpty(eosVersion)) {
                    eosVersion=eosVersion.split("~")[0]
                    eosVersion = eosVersion.toLowerCase().startsWith("v") ? eosVersion : "v" + eosVersion
                    setSelectedDeviceLatestEosVersion(eosVersion)
                }
            }
            dispatch({ type: DEVICE_LATEST_EOS_VERSION, payload: {} })
        }
    }, [authReducer?.deviceLatestEosVersion])

    useEffect(() => {
        if (!isValidTier) {
            setSelectedAction(deviceActionsList[0])
        }
    }, [isValidTier])

    useEffect(() => {
        if (selectedAction == Traffic_Policy || selectedAction == Firewall || selectedAction == WAN_Profile || selectedAction == Hub_IP || selectedAction == Quota) {
            setSelectedManageConfigType(selectedAction)
        } else {
            setSelectedManageConfigType("")
        }

        setModifyConfigData({
            selectedDevice: {
                id: row["deviceId"],
                name: row["deviceName"],
                productType: row["productType"],
                current_EOS_version: row["current_EOS_version"],
                konnectStatus : row["konnect_status"]
            },
            selectedSite: row["k4Id"],
            selectedSiteName: row["name"],
            currentEOSversion: row["current_EOS_version"],
            // latestEOSversion: row["latestEOSavailable"],
        })

        if(selectedAction == Delete_Site) {
            setConfirmDialog({
                isOpen: true,
                title: CONFIRM_DIALOG_TITLE,
                subTitle: `Are you sure you want to delete the site '${row["name"]}' ?`,
                onConfirm: () => {
                  deleteSite(row['k4Id']);
                },
            })
        }
    }, [selectedAction])

    const handleChangeActionType = (actionType) => {
        if (actionType == Traffic_Policy || actionType == Firewall || actionType == WAN_Profile || actionType == Hub_IP || actionType == Quota) {
            setSelectedManageConfigType(actionType)
        } else {
            setSelectedManageConfigType("")
        }
        setSelectedAction(actionType);

        if(selectedAction != actionType) {
            setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
            setDeRegistrationResons([]);
            setSelectedReason('');
            setSelectedReasonInput('');
            setSpecificErrorCode('');
        }
    }

    const handleConfirmClick = () => {
        if(selectedAction == Organization_Configuration) {
            const payload = { moveToDP: true, validated:true, deRegReason: selectedReasonInput ? selectedReasonInput : selectedReason != 'Other' ? selectedReason : null};
            moveDeviceToWarehouse(row['deviceId'], payload);
        }
        confirmDialog.onConfirm();
    }

    const changeGroup = () => {
        inventoryChangeGroup(row["k4Id"], ou?.id);
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedAction('');
        setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
        setModifyConfigData({});
        setRow({});
        setDeRegistrationResons([]);
        setSelectedReason('');
        setSelectedReasonInput('');
        setSpecificErrorCode('');
        dispatch({type: INVENTORY_ASSIGN_DEVICE_TO_WAREHOUSE, payload: {}})
    }

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                id={getDrawerOpen ? 'popup-fixed-position-200px' : 'popup-fixed-position-77px'}
                className='config-deploy-inventory-popup menuScroll inventory-warehouse-register-pop-up inventory-edit-actions-container popup-fixed-position'
            >
                <div>
                    <DialogTitle className='create-default-config-title edit-actions-title'>
                            <Grid className="device-configuration-popup">
                                <span className="configurations">Configurations</span>
                                <span className="selected-site-device device-configuration-popup-devicename"  title={`${row['name']} ${row['deviceName'] ? `- ${row['deviceName']}` : ''}`}>{`${row['name']} ${row['deviceName'] ? `- ${row['deviceName']}` : ''}`}</span>
                            </Grid>
                            <Grid><img src={close} title="Close" onClick={handleClose} /></Grid>
                    </DialogTitle>
                    <DialogContent className="edit-actions-content warehouse-edit-actions-content">
                        <Grid container spacing={1}>
                            <Grid className="menuScroll device-border-right inventory-deployed-device-list device-config-pop-config-list-menu" item>
                                <Grid className="device-data-body actions-device-data-body">
                                    <List>
                                        {deviceActionsList && deviceActionsList.map((identifier, index) => {
                                            return <ListItemButton className={identifier == selectedAction ? "status-lists selectedAction " : "status-lists"} key={`device-${index}`} selected={identifier === selectedAction}
                                                onClick={() => handleChangeActionType(identifier)}>
                                                <Tooltip title={identifier.isParent ? 'Parent' : null}>
                                                    <Typography className={identifier === selectedAction ? "font-status-pop-up selectedAction" : "font-status-pop-up NotselectedAction"}>{identifier}</Typography>
                                                </Tooltip>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid className="menuScroll device-config-pop-config-list-menu-content">
                                <Grid className="device-data-body edit-actions-data-body">
                                    <Stack className="sub-tab-parent" direction="column" spacing={1}>
                                        {confirmDialog.isOpen && <div className="custom_confirm_dialog_sub_title edit-actions-subTitle">
                                            <span className="edit_confirm_dialog_sub_title_text"> {confirmDialog.subTitle} </span><br /><br />
                                            <>
                                                {selectedAction == Organization_Configuration && !(specificErrodCode == 10011 || specificErrodCode == 10035 || specificErrodCode == 10015 || specificErrodCode == 10055 || specificErrodCode == 10056) && <>
                                                    <Button onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} className="move-to-warehouse-confirm-btn">No</Button>
                                                    <Button disabled={setInventoryAssignDeviceToWarehouse} onClick={() => moveDeviceToWarehouse(row.deviceId, { moveToDP: true })} className="move-to-warehouse-confirm-btn">Yes</Button>
                                                </>}
                                                {specificErrodCode == '' && !_.isEmpty(inventoryAssignDeviceToWarehouse) && <span>Something went wrong, Please try after sometime.</span>}
                                            </>
                                            {selectedAction == Organization_Configuration && !_.isEmpty(deRegistrationReasons) && <Grid container className="device-organization-config">
                                                <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from">
                                                    <span className="content-left">Reason</span>
                                                    <Grid className="content-right">
                                                        <Autocomplete
                                                            disableClearable={true}
                                                            options={deRegistrationReasons}
                                                            sx={{ width: 300 }}
                                                            onChange={(e, newValue) => handleReason(e, newValue, 'select')}
                                                            value={selectedReason}
                                                            renderInput={(params) => <TextField {...params} placeholder="Select Reason" variant="outlined" size="small" />}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {selectedReason == 'Other' && <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from">
                                                    <span className="content-left"></span>
                                                    <Grid className="content-right">
                                                        <TextField
                                                            className="user-entered-config-name de-red-reason-text"
                                                            placeholder="Enter other reason"
                                                            sx={{ width: 300 }}
                                                            value={selectedReasonInput}
                                                            onChange={(e) => handleReason(e, e.target.value, 'text')}
                                                        />
                                                    </Grid>
                                                </Grid>}
                                                {selectedReasonInput.length > 50 && <span id="errorspan-inventory">Maximum 50 characters allowed</span>}
                                            </Grid>}
                                            <div className="edit-actions-confirm-btns width-76-percent">
                                                <Divider className="confirm-dialogue-new-divider" />
                                                <div className="confirm-dialogue-new-btns">
                                                    <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                    {
                                                        selectedAction === Organization_Configuration ? (
                                                            (selectedReason && selectedReason !== 'Other' && !setInventoryAssignDeviceToWarehouse) || (selectedReason === 'Other' && selectedReasonInput && selectedReasonInput.length <= 50 && !setInventoryAssignDeviceToWarehouse) ? (
                                                                <Button className="confirm-dialogue-proceed" onClick={() => handleConfirmClick()}>
                                                                    Proceed
                                                                </Button>
                                                            ) : (
                                                                <Button className="confirm-dialogue-proceed-notAllow">
                                                                    Proceed
                                                                </Button>
                                                            )
                                                        ) : (
                                                            <Button className="confirm-dialogue-proceed" onClick={() => handleConfirmClick()}>
                                                                Proceed
                                                            </Button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>}
                                        {!_.isEmpty(selectedManageConfigType) && !confirmDialog.isOpen &&
                                            <ManageConfig
                                                selectedDevice={modifyConfigData?.selectedDevice}
                                                selectedSite={modifyConfigData?.selectedSite}
                                                selectedSiteName={modifyConfigData?.selectedSiteName}
                                                selectedRow={modifyConfigData ? modifyConfigData : {}}
                                                onClose={handleClose}
                                                setConfirmDialog={setConfirmDialog}
                                                selectedManageConfigType={selectedManageConfigType}
                                                selectedManageConfigHubIPType={selectedManageConfigHubIPType}
                                                row={row}
                                            />
                                        }
                                        {
                                            selectedAction == System_Configuration && !confirmDialog.isOpen &&
                                            <SystemConfig selectedDeviceInfo={row} onClose={handleClose} isValidTier={isValidTier} selectedDeviceLatestEosVersion={selectedDeviceLatestEosVersion}/>
                                        }
                                        {
                                            selectedAction == Organization_Configuration && !confirmDialog.isOpen &&
                                            <OrganizationConfig selectedDeviceInfo={row} onClose={handleClose} setConfirmDialogMessage={setConfirmDialog} />
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    getDrawerOpen: state.authReducer?.getDrawerOpen,
    inventoryAssignDeviceToWarehouse: state.authReducer?.inventoryAssignDeviceToWarehouse,
    setInventoryAssignDeviceToWarehouse: state.authReducer.setInventoryAssignDeviceToWarehouse,
});

export default withRouter(
    connect(mapStateToProps, {
        deleteSite,
        moveDeviceToWarehouse, getDeviceLatestEOSversion
    })(EditActionsPopup)
);