export interface Site {
    id: string;
    name: string;
}

export interface Device {
    id: string;
    site_id: string;
    name: string;
    interfaces: DeviceInterface[];
    geoConfigs?: GeoConfig[];
}

export interface DeviceInterface {
    alias: string;
    wan_type: string;
}

export interface GeoConfig {
    id?: string;
    dp_name?: string;
    rule_name:string | "";
    target_type?: string;
    site_id?: string;
    site_name?: string;
    device_id?: string;
    mcc?: number;
    mnc?: number;
    device_name?: string;
    actions?: Action[];
    notification_methods?: string[],
    users_to_notify?: User[];
    disabled?: boolean;
    created_at?: string;
    entry_threshold?: number;
    exit_threshold?: number;
    eachRow?:any;
    summary?:string;
}

export interface GeoConfigHistory {
    id?: string;
    site_id?: string;
    site_name?: string;
    device_id?: string;
    mcc: number;
    mnc: number;
    device_name?: string;
    action?: string;
    status: string;
    failure_reason?: string;
}

export interface StarlinkGeoConfig {
    service_line_numbers: string[];
    enabled: boolean;
}

export interface User {
    name: string;
    email: string;
}

export interface DevicesAction {
    device_id: string;
    site_id: string;
    group_id?: string;
    wan_profile?: WanProfile;
    traffic_policy?: TrafficPolicy;
    device_name?:string;
    mcc?:string;
    status?:string;
    last_action_at?:string;
    site_name?:string;
    group_name?:string;
}
export interface Action {
    id: string;
    geo_codes: string[];
    action_type: ActionType;
    configuration_type: ConfigurationType;
    devices?: DevicesAction[];
    valid?:boolean;
}

export type ActionType = 'assignWanProfile' | 'assignTrafficPolicy' | 'disableInterfaces' | 'enableInterfaces' | 'sendNotification' | '';
export type ConfigurationType = 'default' | 'custom' ;

export interface AccessNetwork {
    id: string;
    name: string;
    wan_profile?: string;
    vlan_aggregate?: string;
    common?: number;
    device_id?: string;
}

export const Actions = {
    ActionAssignWanProfile: 'assignWanProfile',
    ActionAssignTrafficPolicy: 'assignTrafficPolicy',
    ActionDisableInterface: 'disableInterfaces',
    ActionEnableInterfaces: 'enableInterfaces',
    ActionNotification: 'sendNotification',
    ActionUndefined: ''
} as const;

export interface WanProfile {
    id: string;
    name?: string;
    access_networks?: AccessNetwork[];
    device_id?:string;
    common?:number;
}

export interface TrafficPolicy {
    id: string;
    name?: string;
    access_networks?: AccessNetwork[];
    device_id?:string;
    common?:number;
}

export interface StarlinkServiceLine {
    service_line_number?: string;
    nickname?: string;
}

export type GeoConfigSortBy = 'site_name' | 'device_name' | 'mcc' | 'mnc' | 'created_at' | 'action' | 'status' | 'failure_reason'
export type SortOrder = 'asc' | 'desc'

export const NotificationMethods = {
    'email': 'Email',
    'microsoft_teams': 'Microsoft Teams',
}

export interface SelectedDeviceType {
    device_id: string;
    site_id: string;
    group_id?: string;
}