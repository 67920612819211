import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Dialog, DialogContent, IconButton, DialogTitle, Divider, Grid, List, ListItemButton, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, Autocomplete, TextField, FormControl, ListItem, ListItemText, DialogActions, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import close from "../../../asset/image/close.svg";
import _ from "lodash";
import ChangeSubOrg from '../ChangeSubOrgDialog/ChangeSubOrgPerServiceLine';
import { checkLoggedInUserAuthorizedToViewPage, convertDateTimeIntoTimezone, getDecodeURI } from "../../../utils/util";
import PauseResumeServiceLine from './PauseResume';
import { getAvailableProductsForAccount, assignCustomPlan } from "../slice";
import MobilePriority from "./MobilePriority";
import { STARLINK_ADMIN_SL_CHNAGE_SUB_ORG, STARLINK_ADMIN_SL_DETAILS, STARLINK_ADMIN_SL_TOP_UP, SERVICE_PLAN, STARLINK_ADMIN_DEVICES, STARLINK_ADMIN_SL_MOBILE_PRIORITY, STARLINK_ADMIN_SL_PAUSE_SERVICE_LINE, STARLINK_ADMIN_SL_RESUME_SERVICE_LINE } from "../../../utils/actionConstants";
import { Info } from "@material-ui/icons";
import moment from "moment";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS, DEFAULT_DATE_FORMAT } from "../../../utils/constants";
import ServiceLineDetails from "./ServiceLineDetails";
import ServiceLineDevices from "./ServiceLineDevices";
import ServiceLineTopUp from "./ServiceLineTopUp";
import { SERVICE_FUNCTION_TO_FEATURE } from '../../../config';
var Loader = require("react-loader");

const EditActionsPopup = (props) => {
    const { loading, getAvailableProductsForAccount, open, setOpen, confirmDialog, setConfirmDialog, row, setRow, handleEdit, serviceLine, 
        onSave, starlinkService, starlinkFunction, pauseData, setPauseData, endNow, handleChangeEndNow, rejectReason, setRejectReason, product,
         setProduct, handleChangeProduct, availableProducts, handlePauseResume, optInData, setOptInData,
        mobilePriority, setMobilePriority, handleOptIn, availableCustomPlans, assignCustomPlan, authReducer, location, handleChangeServiceLine
    } = props;
   const STARLINK_ADMIN_SF = "starlink-admin";
    const STARLINK_FULL_SF = "starlink-full";

    const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
    const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];
    const params = getDecodeURI(location?.search)
    const [selectedOu, setSelectedOu] = useState(params?.ouId)

    const deviceActionsList = [
        checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) ? STARLINK_ADMIN_SL_DETAILS : null,
        checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) ? STARLINK_ADMIN_SL_CHNAGE_SUB_ORG: null,
        checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) ? STARLINK_ADMIN_SL_MOBILE_PRIORITY : null,
        checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) ? row.subscriptionActiveStatus === "active" ? STARLINK_ADMIN_SL_PAUSE_SERVICE_LINE : STARLINK_ADMIN_SL_RESUME_SERVICE_LINE : null,
        checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) ? SERVICE_PLAN : null,
        checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) ? row?.isCustomPlan && STARLINK_ADMIN_SL_TOP_UP : null,
        STARLINK_ADMIN_DEVICES
    ].filter(action => action);

    const [selectedPlan, setSelectedPlan] = useState<any>(null)
    const [migrationConfirm, setMigrationConfirm] = useState(false)
    const [formattedSubscriptionDetails, setFormattedSubscriptionDetails] = useState<any>([]);
    const [selectedAccount, setSelectedAccount] = useState<any>(null)
    const [selectedTerminal, setSelectedTerminal] = useState<any>(null)
    const [updatedName, setUpdatedName] = useState<any>(null)
    const [selectedPlanSize, setSelectedPlanSize] =  useState<any>(null)
    const [downgradePopup, setDowngradePopup] = useState(false)
    const [allowDowngradeNow, setAllowDowngradeNow] = useState(false);

    useEffect(() => {
        let formattedPlans;
        let customPlans = []
        if(availableCustomPlans && availableCustomPlans?.custom_plans && availableCustomPlans?.custom_plans?.length > 0) {
            customPlans = availableCustomPlans?.custom_plans?.map((subscription, index) => ({
                ...subscription,
                label: `${subscription?.plan_name} `,
                name: `${subscription?.plan_name} `,
                data: subscription?.details,
                isCustomPlan: true,
                hasDivider: availableCustomPlans?.custom_plans?.length - 1 === index ? true : false
            }));
        }
        let subsPlan = [];
        if(availableCustomPlans && availableCustomPlans?.available_products && availableCustomPlans?.available_products?.length > 0) {
            subsPlan =  availableCustomPlans?.available_products?.map((subscription, index) => ({
                ...subscription,
                label: `${subscription?.name} • US $${subscription?.price} • ${subscription?.iso_currency_code}`,
                name: `${subscription?.name}`,
                data: subscription?.plan_details,
                isCustomPlan: false,
                // hasDivider: customPlans.length
            }));
        }
        formattedPlans = [{label:"Custom Plans", name:"Custom Plans", data: []}, ...customPlans, {label:"Standard Plans", name:"Standard Plans", data: []}, ...subsPlan];
        setFormattedSubscriptionDetails(formattedPlans)
        setSelectedPlan(formattedPlans?.find((item) => {
            return item?.name?.trim() === row?.servicePlan?.trim()
        }
        ));
        let plan = formattedPlans?.find((item) => {  return item?.name?.trim() === row?.servicePlan?.trim() })
        setSelectedPlanSize({planSize:plan?.plan_size, planUnit:plan?.unit, });

    }, [row, availableCustomPlans])

    useEffect(() => {
        if (selectedPlan) {
            setSelectedAccount(selectedPlan?.data && selectedPlan?.data?.length > 0 && selectedPlan?.data[0]?.account_number)
            setSelectedTerminal(row?.userTerminals && row?.userTerminals)
        }
    }, [selectedPlan])

    const handleChangeUsageType = (value) => {
        setSelectedPlan(value)
    }

    const assignPlan = async () => {
        let params = {
            serviceLineNumber: row?.serviceLineNumber,
            customPlanId: selectedPlan?.details && selectedPlan?.details?.length > 0 ? selectedPlan?.details[0]?.custom_plan_id : "",
            accountNumber: selectedAccount,
            userTerminalId:  selectedTerminal.split(','),
            organizationId: selectedOu,
            productReferenceId:  selectedPlan?.plan_details && selectedPlan?.plan_details?.length > 0 ? selectedPlan?.plan_details[0]?.product_reference_id : "",
            allowDowngradeNow: allowDowngradeNow,
        }

        await assignCustomPlan(params)
        handleClose()
    }

    const handleAssignPlan = async () => {

        if (selectedPlan && selectedPlan?.data && selectedPlan?.data?.length > 0) {
            let planAccount = selectedPlan?.data[0]?.account_number
            let slAccount = row?.accountNumber

            if(planAccount === slAccount) {
                assignPlan()
            }else {
                setMigrationConfirm(true)
            }
            setDowngradePopup(false)
         }

    }

    const getIdNameForList = (action) => {
        if (action == STARLINK_ADMIN_SL_CHNAGE_SUB_ORG) {
            if (checkLoggedInUserAuthorizedToViewPage(starlinkService, starlinkFunction)) {
                return 'actionType-enabled';
            }
        } else if (action == STARLINK_ADMIN_SL_DETAILS) {
            if (checkLoggedInUserAuthorizedToViewPage(starlinkService, starlinkFunction)) {
                return 'actionType-enabled';
            }
        }
        else if (action == STARLINK_ADMIN_SL_TOP_UP) {
            if (checkLoggedInUserAuthorizedToViewPage(starlinkService, starlinkFunction)) {
                return 'actionType-enabled';
            }
        }
         else if (action == STARLINK_ADMIN_DEVICES) {
                return 'actionType-enabled';
        } 
         else if (action == STARLINK_ADMIN_SL_MOBILE_PRIORITY) {
            if (checkLoggedInUserAuthorizedToViewPage(starlinkService, starlinkFunction)) {
                if (row?.accountType !== "credential" || row?.serviceLineStatus) {
                    return 'actionType-enabled';
                }
            }
        } else if (action == STARLINK_ADMIN_SL_PAUSE_SERVICE_LINE || action == STARLINK_ADMIN_SL_RESUME_SERVICE_LINE) {
            if (checkLoggedInUserAuthorizedToViewPage(starlinkService, starlinkFunction)) {
                if (row?.accountType !== "credential") {
                    return 'actionType-enabled';
                }
            }
        }
        else if (action == "SERVICE PLAN") {
            if (checkLoggedInUserAuthorizedToViewPage(starlinkService, starlinkFunction)) {
                return 'actionType-enabled';
            }
        }
        return 'actionType-disabled';
    }

    const getDefaultActionType = (list) => {
        const actionTypes = list.map(action => {
            if (getIdNameForList(action) == 'actionType-enabled') {
                return action;
            } else {
                return '';
            }
        })
        return actionTypes?.filter(action => action != '')?.length > 0 ? actionTypes?.filter(action => action != '')?.[0] : deviceActionsList[0];
    }

    const [selectedAction, setSelectedAction] = useState(getDefaultActionType(deviceActionsList));

    const updateActionValues = (action) => {
        switch (action) {
            case STARLINK_ADMIN_SL_CHNAGE_SUB_ORG: {
                handleEdit({
                    serviceLineNumber: row?.serviceLineNumber,
                    nickname: row?.serviceLineName
                });
                break;
            }
            case STARLINK_ADMIN_SL_DETAILS: {
                handleEdit({
                    serviceLineNumber: row?.serviceLineNumber,
                    nickname: row?.serviceLineName
                });
                break;
            }
            case STARLINK_ADMIN_DEVICES: {
                // handleEdit({
                //     serviceLineNumber: row.serviceLineNumber,
                //     nickname: row.serviceLineName
                // });
                break;
            }
            case STARLINK_ADMIN_SL_PAUSE_SERVICE_LINE: {
                setPauseData({ serviceLineNumber: row.serviceLineNumber, serviceLineName: row.serviceLineName, accountDpId: row.accountDpId, accountNumber: row.accountNumber, action: "Pause", endNow: false });
                break;
            }
            case STARLINK_ADMIN_SL_RESUME_SERVICE_LINE: {
                setPauseData({ serviceLineNumber: row.serviceLineNumber, serviceLineName: row.serviceLineName, accountDpId: row.accountDpId, accountNumber: row.accountNumber, action: "Resume", endNow: false });
                setProduct(row.productReferenceId.toString());
                getAvailableProductsForAccount({ accountNumber: row.accountNumber })
                break;
            }
            case STARLINK_ADMIN_SL_MOBILE_PRIORITY: {
                setOptInData({
                    serviceLineNumber: row.serviceLineNumber,
                    serviceLineName: row.serviceLineName,
                    accountDpId: row.accountDpId,
                    accountNumber: row.accountNumber,
                    action: row?.optedIn ? "optOut" : "optIn"
                });
                setMobilePriority(row?.optedIn ? true : false);
                break;
            }
            default: setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false })
        }
    }

    useEffect(() => {
        updateActionValues(selectedAction);
    }, [selectedAction])

    const handleChangeActionType = (actionType) => {
        setSelectedAction(actionType);
        selectedAction != actionType && setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
    }

    const handleConfirmClick = () => {
        confirmDialog.onConfirm();
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedAction('');
        setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
        setRow({});
        setPauseData({});
        setProduct('');
        setOptInData({});
        setUpdatedName(null)
    }

    const changeSLName = (name) =>{
        setUpdatedName(name)
        handleChangeServiceLine()
    }

    const checkDowngradablePlan = () =>{
        if(selectedPlan?.unit === selectedPlanSize?.planUnit){
           return selectedPlan?.plan_size < selectedPlanSize?.planSize 
        } else {
            let convertedPlan = selectedPlan?.plan_size ? selectedPlan?.plan_size : 0, convertedSelectedPlan = selectedPlanSize?.planSize ? selectedPlanSize?.planSize : 0;
            if(selectedPlan?.unit === "tb"){
                convertedPlan = selectedPlan?.plan_size*1000
            }

            if(selectedPlanSize?.planUnit === "tb"){
                convertedSelectedPlan = selectedPlanSize?.planSize*1000
            }
            return convertedPlan < convertedSelectedPlan
        }
    }

    const handleChangeImmediateDowngrade = (event) => {
        setAllowDowngradeNow(event.target.value === "Yes" ? true : false)
    };

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                className='config-deploy-starlink-popup menuScroll inventory-warehouse-register-pop-up inventory-edit-actions-container'
            >
                <div>
                    <DialogTitle className='create-default-config-title edit-actions-title'>
                        <div>
                            <span>Operations</span>
                            <span className="selected-site-device starlink-admin-operations-popup-service-line-name">{`Service Line: ${updatedName ? updatedName : row['serviceLineName'] } `}</span>
                        </div>
                        <div>
                            {loading ? (
                                <div className="commonLoader_NewSummary wan-profile-interfaces-loader editActions-loader">
                                    <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} />
                                </div>
                            ) : null}
                            <img src={close} title="Close" onClick={handleClose} />
                        </div>
                    </DialogTitle>
                    <DialogContent className="edit-actions-content">
                        <Grid container spacing={1}>
                            <Grid xs={4} className="menuScroll device-border-right deployed-device-list" item>
                                <Grid className="device-data-body actions-device-data-body">
                                    <List>
                                        {deviceActionsList && deviceActionsList.map((identifier, index) => {
                                            return <ListItemButton id={getIdNameForList(identifier)} className='status-lists'
                                                style={identifier === selectedAction ? { background: 'rgba(244, 245, 250, 1)' } : { background: '#fff' }}
                                                key={`device-${index}`}
                                                selected={identifier === selectedAction}
                                                onClick={() => getIdNameForList(identifier) == 'actionType-enabled' && handleChangeActionType(identifier)}>
                                                <Typography className={identifier === selectedAction ? "font-status-pop-up selectedAction" : "font-status-pop-up NotselectedAction"}>{identifier}</Typography>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} className="menuScroll starlinkAdmin-edit-actions-cont" style={{ minHeight: "250px" }}>
                                <Grid className="device-data-body edit-actions-data-body">
                                    <Stack className="sub-tab-parent" direction="column" spacing={1}>
                                        {confirmDialog.isOpen && <div className="custom_confirm_dialog_sub_title edit-actions-subTitle">
                                            {confirmDialog.subTitle}
                                            <div className="edit-actions-confirm-btns">
                                                <Divider className="confirm-dialogue-new-divider" />
                                                <div className="confirm-dialogue-new-btns">
                                                    <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                    <Button className="confirm-dialogue-proceed" onClick={() => handleConfirmClick()}> Proceed </Button>
                                                </div>
                                            </div>
                                        </div>}
                                        {selectedAction == STARLINK_ADMIN_SL_CHNAGE_SUB_ORG && getIdNameForList(selectedAction) == 'actionType-enabled' && <ChangeSubOrg
                                            serviceLine={serviceLine}
                                            open={open} onSave={onSave} onClose={handleClose}
                                            currentDpName={row['dpName']}
                                        />}
                                        {selectedAction == STARLINK_ADMIN_SL_DETAILS && getIdNameForList(selectedAction) == 'actionType-enabled' && 
                                                <ServiceLineDetails serviceLineToUpdate={row} changeSLName={changeSLName}  />}

                                        {selectedAction == STARLINK_ADMIN_DEVICES && getIdNameForList(selectedAction) == 'actionType-enabled' && 
                                                <ServiceLineDevices serviceLineToUpdate={row} changeSLName={changeSLName} userTerminalData={availableCustomPlans}  />}

                                        {selectedAction == STARLINK_ADMIN_SL_MOBILE_PRIORITY && 
                                                <MobilePriority setMobilePriority={setMobilePriority} mobilePriority={mobilePriority} optInData={optInData} handleOptIn={handleOptIn} row={row} />}

                                        {selectedAction == STARLINK_ADMIN_SL_TOP_UP && getIdNameForList(selectedAction) == 'actionType-enabled' && row?.isCustomPlan &&
                                                <ServiceLineTopUp serviceLineToUpdate={row} changeSLName={changeSLName}  />}

                                        {
                                            selectedAction == "SERVICE PLAN" && <>
                                                <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'column', }}>
                                               
                                                    <Grid item xs={10} container alignItems="center" spacing={1} >
                                                        <Grid item xs={2}>
                                                            <Typography variant="subtitle1">Plan:</Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Autocomplete
                                                                fullWidth
                                                                disableClearable
                                                                options={formattedSubscriptionDetails }
                                                                value={selectedPlan}
                                                                onChange={(e, newValue: any) => {
                                                                    if (newValue?.name === "Custom Plans" || newValue?.name === "Standard Plans") {
                                                                        e.preventDefault(); 
                                                                        return; 
                                                                      }
                                                                    handleChangeUsageType(newValue || '');
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField {...params}size="small" />
                                                                )}
                                                                getOptionLabel={(option) => option.label}
                                                                renderOption={(props, option) => (
                                                                    <React.Fragment key={option.label}>
                                                                      {option.name === "Custom Plans" || option.name === "Standard Plans" ? (
                                                                        <li
                                                                          {...props}
                                                                          style={{
                                                                            // fontWeight: 'bolder',
                                                                            pointerEvents: 'none',
                                                                            backgroundColor: '#f5f5f5', 
                                                                            padding: '8px 16px'
                                                                          }}
                                                                        >
                                                                          {option.label}
                                                                        </li>
                                                                      ) : (
                                                                        <li {...props}>{option.label}</li>
                                                                      )}
                                                                      {option.hasDivider && <Divider />}
                                                                    </React.Fragment>
                                                                  )}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={10} container alignItems="center" spacing={1}>
                                                        <Grid item xs={2}>
                                                            <Typography variant="subtitle1">Account:</Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            {selectedPlan && selectedPlan?.data && selectedPlan?.data?.length > 1 ? 
                                                            <Select
                                                                size="small"
                                                                fullWidth
                                                                label="Account Number"
                                                                id="account-select"
                                                                value={selectedAccount || ""}
                                                                onChange={(e) => {
                                                                    setSelectedAccount(e.target.value);
                                                                }}
                                                            >
                                                                {selectedPlan?.data?.map((plan) => (
                                                                    <MenuItem key={plan.account_number} value={plan.account_number}>
                                                                        {plan.account_number}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            :  <Typography variant="subtitle1">{selectedAccount}</Typography> }
                                                        </Grid>
                                                    </Grid>
                                                    
                                                                                                   <Grid item xs={10} container alignItems="center" spacing={1}>
                                                        <Grid item xs={2}>
                                                            <Typography variant="subtitle1">Terminal:</Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                        <Typography variant="subtitle1">{row?.userTerminals}</Typography>
                                                            
                                                        </Grid>
                                                    </Grid>

                                                {
                                                    checkDowngradablePlan() ? 
                                                
                                                    <Grid item xs={10} container alignItems="center"  >
                                                        <Grid item xs={5}>
                                                            <Typography variant="subtitle1">Downgrade:</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                        <FormControl>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={allowDowngradeNow === true ? "Yes" : "No"}
                                                                label=""
                                                                onChange={handleChangeImmediateDowngrade}
                                                                size="small"
                                                            >
                                                                <MenuItem value="Yes" >Now</MenuItem>
                                                                <MenuItem value="No">Billing Date</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                            
                                                        </Grid>
                                                    </Grid>
                                                     : null }
                                                </Grid>


                                                {selectedPlan && getIdNameForList(selectedAction) == 'actionType-enabled' &&
                                                    <>
                                                        <TableContainer component={Paper} className="starlink--table_container" >
                                                            <Table sx={{ minWidth: 300, marginTop: "10px" }} size="small" aria-label="starlink--service_accounts">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell> <span className="font-wt-900">PLAN SIZE</span> </TableCell>
                                                                        <TableCell> <span className="font-wt-900">PLAN START</span> </TableCell>
                                                                        <TableCell> <span className="font-wt-900">PLAN DURATION</span> </TableCell>
                                                                        <TableCell> <span className="font-wt-900">PLAN DESCRIPTION</span> </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell>  {selectedPlan?.plan_size} {selectedPlan?.unit?.toUpperCase()}  <Tooltip title="Unsused Data will not be carried forward for subsequent billing cycles">
                                                                        <IconButton size='small'>
                                                                            <Info fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {convertDateTimeIntoTimezone(selectedPlan?.billing_date, authReducer.userTimezone, DEFAULT_DATE_FORMAT)}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {selectedPlan?.duration?.toUpperCase()}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Tooltip title={selectedPlan && selectedPlan?.plan_info ? selectedPlan?.plan_info : ""}>
                                                                            <span>
                                                                                {selectedPlan?.plan_info?.length > 10 ? `${selectedPlan?.plan_info?.slice(0, 10)}...` : selectedPlan?.plan_info}
                                                                                </span>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        {/* <Typography fontSize={"0.8rem"} mt={1} ml={2}>Note: </Typography> */}
                                                       
                                                    </>}

                                                <div className="edit-actions-confirm-btns starlink-admin-edit-actions-confirm-btns">
                                                    {/* <Divider className="confirm-dialogue-new-divider" /> */}
                                                    <div className="confirm-dialogue-new-btns">
                                                        <Button className="confirm-dialogue-cancel" onClick={() => { handleClose() }}> Cancel </Button>
                                                        <Button size="small" variant="contained" disabled={row?.servicePlan?.trim() ==  selectedPlan?.name?.trim()}
                                                         onClick={() => { 
                                                             if( row?.customPlanId !== null && selectedPlan?.isCustomPlan && !allowDowngradeNow && checkDowngradablePlan() ){
                                                                setDowngradePopup(true) 
                                                            }else{
                                                                handleAssignPlan() 
                                                            }
                                                        }}
                                                        >{selectedPlan?.data[0].account_number ===  row?.accountNumber ? "Assign Plan" : "Assign Plan"}</Button>
                                                    </div>
                                                </div>

                                            </>
                                        }
                                        {(selectedAction == STARLINK_ADMIN_SL_PAUSE_SERVICE_LINE || selectedAction == STARLINK_ADMIN_SL_RESUME_SERVICE_LINE) && getIdNameForList(selectedAction) == 'actionType-enabled' &&
                                            <PauseResumeServiceLine
                                                pauseData={pauseData} rejectReason={rejectReason} setRejectReason={setRejectReason}
                                                endNow={endNow} handleChangeEndNow={handleChangeEndNow} product={product} handleChangeProduct={handleChangeProduct} availableProducts={availableProducts}
                                                handlePauseResume={handlePauseResume} handleClose={handleClose}
                                            />
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>

            <Dialog PaperProps={{
                sx: {
                    width: '500px',
                    maxWidth: '500px'
                }
            }} open={migrationConfirm} onClose={() => { setMigrationConfirm(false) }}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <Typography> Following Terminals will be moved from {row?.accountNumber} to {selectedPlan?.data[0]?.account_number}: </Typography>

                    {row?.userTerminals ? 
                    <ListItem key={row?.userTerminal}>
                        <ListItemText primary={row?.userTerminals} secondary={`Starlink Id: ${row?.userTerminalIds ? row?.userTerminalIds : "-" }`} />
                    </ListItem> : <></> }
                    
                    <ListItem key={row?.userTerminal}>
                        <ListItemText primary={"Plan"} secondary={` ${row?.servicePlan || row?.servicePlan || ""}`} />
                    </ListItem>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setMigrationConfirm(false)} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => { assignPlan(); setMigrationConfirm(false) }}
                        // disabled={isLoading}
                        variant="contained"
                        color="primary"
                    >
                        {'Migrate & Assign Plan'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog PaperProps={{
                sx: {
                    width: '500px',
                    maxWidth: '500px'
                }
            }} open={downgradePopup} onClose={() => { setDowngradePopup(false) }}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <Typography> This Plan update will take effect from next billing cycle. </Typography>

                </DialogContent>
                <DialogActions>
                <Button  onClick={() => {  setDowngradePopup(false);  setSelectedPlan(formattedSubscriptionDetails?.find((item) => {
            return item?.name?.trim() === row?.servicePlan?.trim()
        }
        ));}} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => { handleAssignPlan() }}
                        variant="contained"
                        color="primary"
                    >
                        PROCEED
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.starlinkAdmin.subcribingServiceLine,
    authReducer: state.authReducer
});

export default withRouter(
    connect(mapStateToProps, { getAvailableProductsForAccount, assignCustomPlan })(EditActionsPopup)
);