import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { Box, Icon, IconButton, InputBase, MenuItem, Paper, Select, SelectChangeEvent, Toolbar } from "@mui/material";
import useId from "@mui/material/utils/useId";
import { useMemo } from "react";

import PAGEDOWN from "../../asset/image/pagination_down.svg";

import './starlinkPagination.css';

export type RowPerPageHandler = (event: SelectChangeEvent<number>, child: React.ReactNode) => void;

export type PageChangeHandler = (event: React.SyntheticEvent<HTMLButtonElement> | null, page: number) => void;

interface Props {
    /**
    * The total number of rows.
    *
    * To enable server side pagination for an unknown number of items, provide -1.
    */
    count: number;
    /**
    * Customize the rows per page label.
    *
    * For localization purposes, you can use the provided [translations](/material-ui/guides/localization/).
    * @default 'Rows per page:'
    */
    labelRowsPerPage?: React.ReactNode;
    /**
    * Callback fired when the page is changed.
    *
    * @param {React.SyntheticEvent<HTMLElement> | null} event The event source of the callback.
    * @param {number} page The page selected.
    */
    onPageChange: PageChangeHandler;
    /**
    * Callback fired when the number of rows per page is changed.
    *
    * @param {React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>} event The event source of the callback.
    */
    onRowsPerPageChange?: RowPerPageHandler;
    /**
    * The zero-based index of the current page.
    */
    page: number;
    /**
    * The number of rows per page.
    *
    * Set -1 to display all the rows.
    */
    rowsPerPage: number;
    /**
    * Customizes the options of the rows per page select field. If less than two options are
    * available, no select field will be displayed.
    * Use -1 for the value with a custom label to show all the rows.
    * @default [10, 25, 50, 100]
    */
    rowsPerPageOptions?: Array<number | { value: number; label: string }>;
    /**
    * If `true`, show the first-page button.
    * @default false
    */
    showFirstButton?: boolean;
    /**
    * If `true`, show the last-page button.
    * @default false
    */
    showLastButton?: boolean;
}


export interface PaginationActionsProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Override or extend the styles applied to the component.
     */
    classes?: {};
    count: number;
    /**
     * Accepts a function which returns a string value that provides a user-friendly name for the current page.
     * This is important for screen reader users.
     *
     * For localization purposes, you can use the provided [translations](/material-ui/guides/localization/).
     * @param {string} type The link or button type to format ('first' | 'last' | 'next' | 'previous').
     * @returns {string}
     */
    getItemAriaLabel: (type: 'first' | 'last' | 'next' | 'previous') => string;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    page: number;
    rowsPerPage: number;
    showFirstButton: boolean;
    showLastButton: boolean;
}

export function TablePaginationActions(props: PaginationActionsProps) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 0.5 }}>
            <IconButton className="page-arrow"
                onClick={handleBackButtonClick}
                disabled={page <= 1}
                aria-label="previous page"
            ><KeyboardArrowLeft fontSize="small"/>
            </IconButton>
            <IconButton className="page-arrow margin-rt"
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage)}
                aria-label="next page"
            >
                <KeyboardArrowRight fontSize="small"/>
            </IconButton>
        </Box>
    );
}

export const Pagination = (props: Props) => {
    const {
        count = 0,
        onPageChange,
        onRowsPerPageChange,
        page,
        rowsPerPage,
        rowsPerPageOptions = [10, 25, 50, 100],
        showFirstButton = false,
        showLastButton = false,
        ...other
    } = props;

    const selectId = useId();
    const pageId = useId();
    const labelId = useId();

    function getAriaLabel(type) {
        return `Go to ${type} page`;
    }

    const getLabelDisplayedRowsTo = () => {
        if (count === -1) {
            return page * rowsPerPage;
        }
        return rowsPerPage === -1 ? count : Math.min(count, page * rowsPerPage);
    };

    const labelDisplayedRows = ({ from, to, count }) => {
        return `Items from`
    }

    const totalPages = useMemo(() => rowsPerPage <= 0 || !count ? 1 : Math.ceil(count / rowsPerPage), [rowsPerPage, count]);

    const labelDisplayPages = () => {
        return ` of ${totalPages} pages`
    }

    const handlePageChange = (event: SelectChangeEvent<number>) => {
        const value = event.target.value;

        if (typeof value == 'string') {
            onPageChange(null, parseInt(value, 10));
        } else {
            onPageChange(null, value);
        }
    }

    const renderIcon = () => {
        return <img alt="dropdown" className="page-drop" src={PAGEDOWN} />
    }

    const handleDisplayDetails = ({ from, to, count }) => {
        return `${from}-${to} of ${count} items`
    }

    return <div
        className='starlinkpagination-root'
        {...other}
    >
        <Toolbar className='starlinkpagination-toolbar' component={Box}>
            {rowsPerPageOptions.length > 1 && (
                <Select className="records-select"
                    variant="outlined"
                    input={<InputBase className="row-dropdown" />}
                    value={rowsPerPage}
                    IconComponent={renderIcon}
                    onChange={onRowsPerPageChange}
                    id={selectId}
                    labelId={labelId}
                >
                    {rowsPerPageOptions.map((rowsPerPageOption) => (
                        typeof rowsPerPageOption === 'number' ?
                            <MenuItem className="records-select-item"
                                key={rowsPerPageOption}
                                value={rowsPerPageOption}
                            >
                                {rowsPerPageOption}
                            </MenuItem> :
                            <MenuItem className="records-select-item"
                                key={rowsPerPageOption.label}
                                value={rowsPerPageOption.value}
                            >
                                {rowsPerPageOption.label}
                            </MenuItem>
                    ))}
                </Select>
            )}
            {rowsPerPageOptions.length > 1 && (
                <p className='starlinkpagination-selectLabel records-select' id={labelId}>
                    {labelDisplayedRows({
                        from: count === 0 ? 0 : (page - 1) * rowsPerPage + 1,
                        to: getLabelDisplayedRowsTo(),
                        count: count === -1 ? -1 : count
                    })}
                </p>
            )}
            {/* {count > 1 && ( */}
                <Select className="records-select"
                    variant="outlined"
                    input={<InputBase className="page-dropdown"/>}
                    IconComponent={renderIcon}
                    value={page}
                    onChange={handlePageChange}
                    id={pageId}
                    labelId={pageId}
                >
                    {Array.from(Array(totalPages).keys()).map((page) => (
                        <MenuItem className="records-select-item"
                            key={`page-item-${page}`}
                            value={page + 1}
                        >
                            {page + 1}
                        </MenuItem>
                    ))}
                </Select>
            {/* )} */}
            <p className='starlinkpagination-displayPages'>
                {labelDisplayPages()}
            </p>
            <TablePaginationActions
                style={{margin:"0px"}}
                className='starlinkpagination-actions'
                count={count}
                onPageChange={onPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                showFirstButton={showFirstButton}
                showLastButton={showLastButton}
                getItemAriaLabel={getAriaLabel}
            />
            <div className='starlinkpagination-spacer' />
            {rowsPerPageOptions.length > 1 && (
                <p className='starlinkpagination-selectLabel records-select' id={labelId}>
                    {handleDisplayDetails({
                        from: count === 0 ? 0 : (page - 1) * rowsPerPage + 1,
                        to: getLabelDisplayedRowsTo(),
                        count: count === -1 ? -1 : count
                    })}
                </p>
            )}
        </Toolbar>
    </div>
}