import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import { maxHeight } from '@mui/system';
import { useEffect, useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface Options {
    defaultSelection: string[];
    handleSelection: Function;
    name: string;
    values: string[];
    sx?: any;
    options?: any;
}

function getStyles(name: string, option: readonly string[], theme: Theme) {
    return {
        fontWeight:
            option.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function MultipleSelectChip(props: Options): JSX.Element {
    const theme = useTheme();
    const {defaultSelection, handleSelection, options} = props;
    const[selectedOption, setSelectedOption] = useState<string[]>(defaultSelection);

    useEffect(() => {
        if(defaultSelection.join(',') != selectedOption.join(',')){
            handleSelection(selectedOption);
        }
    }, [selectedOption]);

    useEffect(() => {
        setSelectedOption(defaultSelection);
    }, [defaultSelection]);

    const handleChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setSelectedOption(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleDelete = (e: React.MouseEvent, value: string) => {
        e.preventDefault();
        setSelectedOption(selectedOption.filter((op: any) => op !== value));
    };

    return (
        <FormControl sx={props.sx} size="small">
            <InputLabel id="multiple-chip-label">{props.name}</InputLabel>
            <Select
                labelId="multiple-chip-label"
                id="multiple-chip"
                multiple
                value={selectedOption}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label={props.name} />}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                        },
                    },
                    disableEnforceFocus: true
                }}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxHeight: '24px', overflowY: 'auto' }}>
                        {selected.map((value: any) => (
                            <Chip 
                                key={value}
                                label={options?.label?.format ? options.label.format(value) : value}
                                clickable
                                deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                                onDelete={(e) => handleDelete(e, value)}
                                size="small"
                            />
                        ))}
                    </Box>
                )}
            >
                {props.values.map((name: any) => {
                    const isDisabled = selectedOption.length === 4 && !selectedOption.includes(name); // Disable option if 4 are selected
                    return (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, selectedOption, theme)}
                            disabled={isDisabled}
                        >
                            {options?.label?.format ? options.label.format(name) : name}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    );
}

export default MultipleSelectChip;