import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Autocomplete, Button, DialogActions, Divider, Grid, TextField, Tooltip } from "@mui/material";
import "./SystemConfig.css";
import { useEffect, useState } from "react";
import _ from "lodash";
import { CONFIRM_DIALOG_TITLE, SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import ConfirmDialog from "../ConfirmDialog";
import { backupConfigNow, applyGoldenConfig, backupConfigData, getLicenseTypes, upgradeDevicetoLatestEOSversion, rebootDevice, upgradeInventoryLicense } from "../../actions/Users/authenticateInventory";
import SelectTag from "../InventoryWarehouseTable/SelectTag";
import { checkLoggedInUserAuthorizedToViewPage, convertDateTimeIntoTimezone } from "../../utils/util";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoIcon from '@mui/icons-material/Info';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import { compare } from "compare-versions";
import { T0_TIER } from ".";

export const SystemConfig = (props) => {
    const { authReducer, selectedDeviceInfo, isValidTier, upgradeDevicetoLatestEOSversion, backupConfigNow, onClose, applyGoldenConfig, rebootDevice, backupConfigData, getLicenseTypes, upgradeInventoryLicense, selectedDeviceLatestEosVersion } = props;

    const isAuthorizedToBeta = checkLoggedInUserAuthorizedToViewPage(SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"], SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"]);

    const [deviceName, setDeviceName] = useState(selectedDeviceInfo.deviceName)
    const [deviceId, setDeviceId] = useState(selectedDeviceInfo.deviceId)
    const [siteId, setSiteId] = useState(selectedDeviceInfo.k4Id)
    const [productType, setProductType] = useState(selectedDeviceInfo.productType)
    const [deviceKonnectStatus, setDeviceKonnectStatus] = useState(selectedDeviceInfo.konnect_status)
    const KONNECT_STATUS_CONNECTED = "STATUS_CONNECTED"
    const [currentEOSversion, setCurrentEOSversion] = useState(!_.isEmpty(selectedDeviceInfo.current_EOS_version) ? selectedDeviceInfo.current_EOS_version.toLowerCase().startsWith("v") ? selectedDeviceInfo.current_EOS_version : "v" + selectedDeviceInfo.current_EOS_version : "")
    // const [latestEOSversion, setLatestEOSversion] = useState(selectedDeviceLatestEosVersion)
    const [isLatestEosAvailable, setIsLatestEosAvailable] = useState(!_.isEmpty(selectedDeviceInfo.current_EOS_version) && !_.isEmpty(selectedDeviceLatestEosVersion) ? compare(selectedDeviceInfo.current_EOS_version, selectedDeviceLatestEosVersion, "<") : false)
    const [isTermEnabled, setIsTermEnabled] = useState(isValidTier)

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });
    const [configName, setConfigName] = useState("")

    const [configSelectedValues, setConfigSelectedValue] = useState<any>({});
    const [configOptions, setConfigOptions] = useState<any>([]);
    const [idData, setIdData] = useState<any>({ Site: { label: '', id: '' }, Device: { label: '', id: '' }, Configuration: { label: '', id: '' } })
    const [selectedConfigDetails, setSelectedConfigDetails] = useState<any>({});


    const [licenseOptions, setLicenseOptions] = useState<any>([]);
    const [termOptions, setTermOptions] = useState<any>([]);
    const [selectedLicense, setSelectedLicense] = useState<any>(null);
    const [selectedTerm, setSelectedTerm] = useState<any>(null);

    useEffect(() => {
        if (selectedDeviceInfo?.tierAlias && selectedDeviceInfo?.tierValue && selectedDeviceInfo?.termDisplay && selectedDeviceInfo?.termValue) {
            setSelectedLicense({ label: selectedDeviceInfo['tierAlias'], value: selectedDeviceInfo['tierValue'] })
            setSelectedTerm({ label: selectedDeviceInfo['termDisplay'], value: selectedDeviceInfo['termValue'] })
        }
        getLicenseTypes(selectedDeviceInfo['dpId'], selectedDeviceInfo['deviceId'])
    }, [selectedDeviceInfo])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getVesselsListing)) {
            let data = authReducer.getVesselsListing.hasOwnProperty("locations") && Array.isArray(authReducer.getVesselsListing.locations) ? authReducer.getVesselsListing.locations : [];
            let _k4Ids: any = []
            const vessels = data?.map((vs) => {
                _k4Ids.push(vs.id);
                return { label: vs.name, id: vs.id }
            })
            let sitesIds = _k4Ids.join(',')
            let status = "completed"
            sitesIds && backupConfigData(sitesIds, -1, -1, '', 'create_time', 'desc', '', productType, '', true, status);
        }
    }, [authReducer.getVesselsListing])

    useEffect(() => {
        if (!_.isEmpty(authReducer.backupConfigData)) {
            const data = authReducer?.backupConfigData?.result;
            setConfigOptions(data?.map((ele) => {
                return { label: ele.config_name, id: ele.id, details: ele }
            }));
            authReducer.backupConfigData = {};
        }
    }, [authReducer.backupConfigData])


    useEffect(() => {
        if (!_.isEmpty(authReducer?.getLicenseTypes) && authReducer?.getLicenseTypes?.data) {
            if (selectedLicense?.value?.includes(T0_TIER)) {
                setIsTermEnabled(false)
            } else {
                setIsTermEnabled(true)
            }
            let licenseData = authReducer?.getLicenseTypes.data;
            if (licenseData && Array.isArray(licenseData) && licenseData.length > 0) {
                let types: any[] = [];
                let termTypes: any = {};

                licenseData = _.orderBy(licenseData, ['tierOrder'], ['asc']);

                licenseData.forEach(tierType => {
                    let type = {
                        "label": tierType?.tierAlias,
                        "value": tierType?.tierType
                    }

                    if (tierType?.term && Array.isArray(tierType.term) && tierType.term.length > 0) {
                        let termOptions: any[] = []

                        tierType.term = _.orderBy(tierType.term, ['termOrder'], ['asc']);
                        tierType.term.forEach(durationtype => {
                            let option = {
                                "label": durationtype?.termDisplay,
                                "value": durationtype?.termValue
                            }
                            termOptions.push(option)
                        });
                        termTypes[tierType?.tierType] = termOptions
                    }

                    types.push(type)
                });
                setTermOptions(termTypes?.[selectedLicense?.['value']])
                setSelectedTerm(termTypes?.[selectedLicense?.['value']]?.[0])
                setLicenseOptions(types)
            }
        }
    }, [authReducer.getLicenseTypes, selectedLicense])

    const handleUpgrade = () => {
        upgradeDevicetoLatestEOSversion(siteId, deviceId)
        onClose()
    }

    const takeABackupNow = () => {
        backupConfigNow(siteId, deviceId, deviceName, configName)
        onClose()
    }

    const handleChangeConfigFrom = (e, newValue, key) => {
        if (deviceKonnectStatus == KONNECT_STATUS_CONNECTED) {
            let previousValue = configSelectedValues;
            let selectedValue = { ...idData, [`${key}`]: newValue };
            if (!newValue) {
                previousValue = { ...previousValue, Configuration: '' };
                selectedValue = { ...idData, Configuration: { label: '', id: '' } };
            } else {
                previousValue = { ...previousValue, Configuration: e.target.innerText.trim() };
                selectedValue = { ...idData, Configuration: newValue };
            }

            setIdData(selectedValue);
            setConfigSelectedValue({ ...previousValue });
            if (!_.isEmpty(newValue) && !_.isEmpty(newValue.details)) {
                setSelectedConfigDetails(newValue.details)
            } else {
                setSelectedConfigDetails({})
            }
        }
    }

    const handleApplyConfig = () => {
        const payload = {
            site_id: siteId,
            device_id: deviceId,
            config_id: idData?.Configuration?.id
        }

        applyGoldenConfig(payload);
        onClose();
    }

    const handleUpgradeLicense = () => {
        const payload = {
            did: deviceId,
            licenseFrom: selectedDeviceInfo?.tierValue,
            licenseTo: selectedLicense?.value,
            termFrom: selectedDeviceInfo.termValue,
            termTo: selectedTerm?.value
        }
        upgradeInventoryLicense(payload);
    }


    return (
        <Grid className="Form">
            <Grid container className="device-system-config">
                {isValidTier ? (
                    <>
                        <Grid container>
                            <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Device</Grid>
                            <Grid xs={5} sm={5} md={5} lg={5} className="content-right" title={deviceName}>{deviceName}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Current EOS Version</Grid>
                            <Grid xs={5} sm={5} md={5} lg={5} className="content-right">
                                {currentEOSversion}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Latest EOS Version</Grid>
                            <Grid xs={5} sm={5} md={5} lg={5} className="content-right action">{selectedDeviceLatestEosVersion}</Grid>
                            <Grid xs={3} sm={3} md={3} lg={3} className="content-right action">
                                <Button className={isLatestEosAvailable && deviceKonnectStatus == KONNECT_STATUS_CONNECTED ? "action-button" : "action-button disabled"}
                                    onClick={() => {
                                        if (isLatestEosAvailable && deviceKonnectStatus == KONNECT_STATUS_CONNECTED) {
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: CONFIRM_DIALOG_TITLE,
                                                subTitle: "Are you sure to upgrade the device " + deviceName + " to latest " + selectedDeviceLatestEosVersion + " ?",
                                                onConfirm: () => {
                                                    handleUpgrade()
                                                },
                                            })
                                        }
                                    }}
                                >Upgrade</Button>
                            </Grid>
                        </Grid>
                    </>
                ): null}
                {
                    isValidTier && !_.isEmpty(deviceId) && deviceId.substring(0, 2) !== "AM" && selectedDeviceInfo["is_current_EOS_version_above_v6.5.3"]
                        ? <Grid container>
                            <Grid item xs={4} sm={4} md={4} lg={4} className="content-left">Save Config</Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5} className="content-right action">
                                <Grid>
                                    <TextField
                                        className="user-entered-config-name"
                                        placeholder="Enter the name"
                                        value={configName}
                                        onChange={(event) => { setConfigName(event.target.value) }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} className="content-right action">
                                <Button className={deviceKonnectStatus == KONNECT_STATUS_CONNECTED ? "action-button" : "action-button disabled"} onClick={() => {
                                    if (!_.isEmpty(configName) && deviceKonnectStatus == KONNECT_STATUS_CONNECTED) {
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: CONFIRM_DIALOG_TITLE,
                                            subTitle: "Are you sure to download the configuration for " + deviceName + " ?",
                                            onConfirm: () => {
                                                takeABackupNow()
                                            },
                                        })
                                    }
                                }}>Download
                                </Button>
                            </Grid>
                        </Grid>
                        : null
                }
                {
                    isValidTier && !_.isEmpty(deviceId) && deviceId.substring(0, 2) !== "AM" && selectedDeviceInfo["is_current_EOS_version_above_v6.5.3"]
                        ?
                        <Grid container>
                            <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Select Configuration</Grid>
                            <Grid xs={5} sm={5} md={5} lg={5} className="content-right action">
                                <Grid className="select-config">
                                    <SelectTag options={configOptions} handleChange={(e, newValue) => handleChangeConfigFrom(e, newValue, 'Configuration')} value={idData.Configuration} />
                                </Grid>
                            </Grid>
                            <Grid xs={3} sm={3} md={3} lg={3} className="content-right action"><Button className={deviceKonnectStatus == KONNECT_STATUS_CONNECTED ? "action-button" : "action-button disabled"}
                                onClick={() => {
                                    if (deviceKonnectStatus == KONNECT_STATUS_CONNECTED) {
                                        if (_.isEmpty(selectedConfigDetails) || _.isEmpty(selectedConfigDetails.software_version) || _.isEmpty(currentEOSversion)) {
                                            return
                                        }

                                        if (!compare(selectedConfigDetails.software_version, currentEOSversion, '>')) {
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: CONFIRM_DIALOG_TITLE,
                                                subTitle: "Configuration change needs server reboot and device will go offline for around 10-15 mins.\n\nAre you sure to apply the configuration changes on the device " + deviceName + " ?",
                                                onConfirm: () => {
                                                    handleApplyConfig()
                                                },
                                            })
                                        }
                                    }
                                }}
                            >Upload</Button></Grid>
                        </Grid>
                        : null
                }
                {
                    isValidTier && !_.isEmpty(selectedConfigDetails) && !_.isEmpty(selectedConfigDetails.software_version) && !_.isEmpty(currentEOSversion) && compare(selectedConfigDetails.software_version, currentEOSversion, '>')
                    ?
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            {/* if config EOS is higher than current EOS - dont allow apply */}
                            <Grid className="config-upload-note">Selected config EOS version is higher than the target device current EOS version</Grid>
                        </Grid>
                    : null
                }
                {
                    isValidTier && !_.isEmpty(selectedConfigDetails)
                    ?
                    [
                        <Grid container>
                            <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Selected Configuration details</Grid>
                            {/* <Grid className="content-right"></Grid> */}
                        </Grid>,
                        <Grid container>
                            <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Device</Grid>
                            <Grid className="content-right">{selectedConfigDetails.device_name}</Grid>
                        </Grid>,
                        <Grid container>
                            <Grid xs={4} sm={4} md={4} lg={4} className="content-left">EOS version</Grid>
                            <Grid className="content-right">{selectedConfigDetails.software_version}</Grid>
                        </Grid>,
                        <Grid container>
                            <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Creation Date</Grid>
                            <Grid className="content-right">{convertDateTimeIntoTimezone(selectedConfigDetails.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</Grid>
                        </Grid>,
                    ]
                    : null
                }
                {
                    !_.isEmpty(deviceId) && deviceId.substring(0, 2) !== "AM" && selectedDeviceInfo["is_current_EOS_version_above_v6.5.2"]
                        ? <Grid container>
                            <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Konnect Status</Grid>
                            <Grid xs={5} sm={5} md={5} lg={5} className="content-right action">
                                <Grid className="device-konnect-status">
                                    {
                                        deviceKonnectStatus == KONNECT_STATUS_CONNECTED
                                            ? [<span className="dot online" />, <span>Online</span>]
                                            : deviceKonnectStatus == "-"
                                                ? [<span className="dot unknown" />, <span>Unknown</span>]
                                                : [<span className="dot offline" />, <span>Offline</span>]
                                    }

                                </Grid>
                            </Grid>
                            {isValidTier ? (
                                <Grid xs={3} sm={3} md={3} lg={3} className="content-right action">
                                    <Button className={deviceKonnectStatus == KONNECT_STATUS_CONNECTED ? "action-button" : "action-button disabled"}
                                        onClick={() => {
                                            if (deviceKonnectStatus == KONNECT_STATUS_CONNECTED) {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: CONFIRM_DIALOG_TITLE,
                                                    subTitle: "Are you sure to Reboot the device " + deviceName + " ?",
                                                    onConfirm: () => {
                                                        rebootDevice(siteId, deviceId);
                                                        onClose()
                                                    },
                                                })
                                            }
                                        }}
                                    >Reboot</Button>
                                </Grid>
                            ) : null}
                        </Grid>
                        : null
                }
                {
                    !_.isEmpty(deviceId) && deviceId.substring(0, 2) !== "AM" && !_.isEmpty(selectedLicense) && !_.isEmpty(selectedTerm)
                        ?
                        [
                            <Grid container >
                                <Grid xs={12} sm={12} md={12} lg={12} className="content-left">
                                    Change License
                                    <div className="change-license-info-icon">
                                        <Tooltip arrow placement="right" title='Change License can be used to move from the Current License Tier / Term to a Different license Tier / Term (Subject to business rules specified under MSA). Either Or both of the License Tier, Term can be updated during the Change License.'><InfoIcon className="help-icon-actions" /></Tooltip>
                                    </div>
                                </Grid>
                            </Grid>,
                            <Grid container >
                                <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Select License</Grid>
                                <Grid xs={5} sm={5} md={5} lg={5} className="content-right">
                                    <Grid className="select-licence">
                                        <Autocomplete
                                            freeSolo
                                            disableClearable
                                            forcePopupIcon={true}
                                            popupIcon={<ArrowDropDownIcon />}
                                            ListboxProps={{ className: 'warehouse-apply-config-props' }}
                                            className="audit-filter-inputs-usage config-textField"
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            size='small'
                                            options={licenseOptions}
                                            value={selectedLicense}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(e, newValue) => { setSelectedLicense(newValue) }}
                                            renderInput={({ inputProps, ...params }) => (
                                                <TextField {...params} placeholder="Select"
                                                    inputProps={{ ...inputProps, readOnly: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>,
                            <Grid container>
                                {isTermEnabled ? (
                                    <>
                                        <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Select Term</Grid>
                                        <Grid xs={5} sm={5} md={5} lg={5} className="content-right action">
                                            <Grid className="select-licence-term">
                                                <Autocomplete
                                                    freeSolo
                                                    disableClearable
                                                    forcePopupIcon={true}
                                                    popupIcon={<ArrowDropDownIcon />}
                                                    ListboxProps={{ className: 'warehouse-apply-config-props' }}
                                                    className="audit-filter-inputs-usage config-textField"
                                                    selectOnFocus
                                                    clearOnBlur
                                                    handleHomeEndKeys
                                                    size='small'
                                                    options={termOptions}
                                                    value={selectedTerm}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={(e, newValue) => { setSelectedTerm(newValue) }}
                                                    renderInput={({ inputProps, ...params }) => (
                                                        <TextField {...params} placeholder="Select"
                                                            inputProps={{ ...inputProps, readOnly: true }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : <Grid xs={9} sm={9} md={9} lg={9}/>}
                                <Grid xs={3} sm={3} md={3} lg={3} className="content-right action">
                                    <Button className={deviceKonnectStatus == KONNECT_STATUS_CONNECTED ? "action-button" : "action-button disabled"}
                                        onClick={() => {
                                            if (deviceKonnectStatus == KONNECT_STATUS_CONNECTED) {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: CONFIRM_DIALOG_TITLE,
                                                    subTitle: "Are you sure to change the device licence ?",
                                                    onConfirm: () => {
                                                        handleUpgradeLicense()
                                                    },
                                                })
                                            }
                                        }}
                                    >Apply</Button>
                                </Grid>
                            </Grid>,
                        ]
                        : null
                }
            </Grid>
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </Grid>
    )
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        upgradeDevicetoLatestEOSversion, backupConfigNow, applyGoldenConfig, rebootDevice, backupConfigData, getLicenseTypes, upgradeInventoryLicense
    })(SystemConfig)
);