import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { AddCircle } from "@material-ui/icons";
import { Autocomplete, Button, Grid, TextField, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RemoveCircle } from '@mui/icons-material';
import { getSeverityClassList, getThreatNameList } from "../../../actions/Users/authenticateThreatDashboard";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import _ from "lodash";


const ThreatKontrolActions = (props) => {
    const {authReducer, thretaKontrolActions, setThretaKontrolActions} = props;
    const addthretaKontrolAction = () => {
        const newAction = {
            id: thretaKontrolActions.length,
            severity: [],
            class: [],
            classOptions: [],
            threatName: [],
            threatNameOptions: [],
            meas: '',
            count: 0,
            action: ''
        };
        setThretaKontrolActions([...thretaKontrolActions, newAction]);
    };
    const updateThreatKontrolActions = (id, updatedAction) => {
        setThretaKontrolActions(
            thretaKontrolActions.map((action, index) =>
                index === id ? updatedAction : action
            )
        );
    };
    const removeThreatKontrolAction = (id: string) => {
        const updatedActions = thretaKontrolActions.filter((_, index) => index !== id);
        setThretaKontrolActions(updatedActions);

        setThretaKontrolActions((prevActions) =>
            prevActions.map((action, index) => ({ ...action, id: index }))
        );
    };
    return (
        <div>
            <div>
                {thretaKontrolActions.map((action, index) => (
                    <ThreatKontrolAction
                        key={index}
                        id={index}
                        data={action}
                        authReducer={authReducer}
                        getSeverityClassList={getSeverityClassList}
                        getThreatNameList={getThreatNameList}
                        onUpdate={updateThreatKontrolActions}
                        onRemove={removeThreatKontrolAction}
                    />
                ))}
            </div>
            <Button variant="outlined" size="small" startIcon={<AddCircle id="addActionIcon"/>} id="threatKontrolAddAction" onClick={addthretaKontrolAction} >
                    Add Action
            </Button>
        </div>
    )
}

const ThreatKontrolAction = (props) => {
    const { authReducer, getSeverityClassList, getThreatNameList, id, data, onUpdate, onRemove } = props;
    const handleChange = (field) => (event, value) => {
        onUpdate(id, { ...data, [field]: value || '' });
    };

    const handleSeverityChange = (event, value) => {
        onUpdate(id, { 
            ...data, 
            severity: value || "", 
            class: [], 
            threatName: []
        });
    };
    const isPresentinClass = (threatname, classes) => {
        if(classes.includes(threatname.class)){
            return true;
        }
        else return false;
    }
    const handleClassChange = (event, value) => {
        const remainingThreatNames = data.threatName.filter((threatname, index) => {
            return isPresentinClass(threatname, value)
        })
        onUpdate(id, { 
            ...data, 
            class: value || [], 
            threatName: remainingThreatNames
        });
    };

    const optionsSeverity = ["Low", "Medium", "High", "Critical"];
    const optionsMeas = ["15 Mins", "1 Hour", "6 Hours", "24 Hours" ];
    const optionsCount = [10, 20, 50, 100];
    const optionsAction = ["Notification", "Suppression"];
    const [classOptions, setClassOptions] = useState<any>([]);
    const [threatNameOptions, setThreatNameOptions] = useState<any>([]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSeverityClassList(data.severity || ""));
    }, [data.severity]);
    useEffect(() => {
        if (data.class && data.class.length > 0) {
            const clses= data.class.map(item => `'${item}'`).join(',');
            dispatch(getThreatNameList(clses));
        }
        else {
            dispatch(getThreatNameList("''"));
        }
    }, [data.class]);

    useEffect(() => {
        const classList = authReducer?.threatSeverityClassList?.data?.class?.data[0];
        if (classList && data.severity.length > 0) {
            const parsedClassOptions = classList.split(",");
            setClassOptions(parsedClassOptions);
            onUpdate(id, { ...data, classOptions: parsedClassOptions });
        }
        else {
            onUpdate(id, { ...data, classOptions: [] });
        }
    }, [authReducer.threatSeverityClassList, data.severity]);
    useEffect(() => {
        const nameList = authReducer?.threatNamesList?.data;
        if (nameList && Array.isArray(nameList) && data.class.length > 0) {
            const parsedThreatNameOptions = nameList.map(item => item);
            setThreatNameOptions(parsedThreatNameOptions);
            onUpdate(id, { ...data, threatNameOptions: parsedThreatNameOptions });
        }
        else {
            onUpdate(id, { ...data, threatNameOptions: [] });
        }
    }, [authReducer.threatNamesList, data.class]);
    return (
        <div >
          <Grid style={{alignItems:"center"}}>  
            <Grid item xs={12} md={2} className="threatAction">
                <Autocomplete
                    id="severity"
                    size="small"
                    options={optionsSeverity}
                    value={data.severity || null}
                    onChange={handleSeverityChange}
                    renderInput={(params) => <TextField {...params} label="Severity *" title="Severity" variant="outlined" className="severitySelector"/>}
                />
                <Autocomplete
                    id="class"
                    size="small"
                    multiple
                    options={data.classOptions}
                    value={data.class}
                    onChange={handleClassChange}
                    renderInput={(params) => <TextField {...params} label="Class *" title="Class" variant="outlined" className="classSelector" 
                    sx={{
                        '& .MuiInputBase-root': {
                            minHeight: 40 + data.class.length * 30, 
                        },
                    }}/>}
                />
                <Autocomplete
                    id="threatName"
                    size="small"
                    multiple
                    options={data.threatNameOptions}
                    getOptionLabel={(option) => option.threat_name}
                    value={data.threatName}
                    onChange={handleChange('threatName')}
                    renderInput={(params) => <TextField {...params} label="ThreatName *" title="ThreatName" variant="outlined" className="threatNameSelector"
                    sx={{
                        '& .MuiInputBase-root': {
                            minHeight: 40 + data.threatName.length * 30, 
                        },
                    }}/>}
                />
                <Autocomplete
                    id="action"
                    size="small"
                    options={optionsAction}
                    value={data.action || null}
                    onChange={handleChange('action')}
                    renderInput={(params) => <TextField {...params} label="Action *" title="Action" variant="outlined" className="actionSelector"/>}
                />
                <Autocomplete
                    id="meas"
                    size="small"
                    options={optionsMeas}
                    value={data.meas || null}
                    onChange={handleChange('meas')}
                    disabled={data.action == "Suppression"}
                    renderInput={(params) => <TextField {...params} label="Measurement *" title="Measurement period" variant="outlined" className="measSelector"/>}
                />
                <Autocomplete
                    id="count"
                    size="small"
                    options={optionsCount}
                    value={data.count || null}
                    freeSolo 
                    onChange={(event, newValue) => {
                        handleChange('count')(event, newValue); 
                    }}
                    onInputChange={(event, newInputValue) => {
                        handleChange('count')(event, newInputValue);
                    }}
                    disabled={data.action == "Suppression"}
                    renderInput={(params) => <TextField {...params} label="Count *" title="Count" placeholder="type number" variant="outlined" className="countSelector"/>}
                />
                <RemoveCircle className="threatActionremoveIcon" onClick={() => onRemove(id)}/> 
            </Grid>
            <br></br>
          </Grid>  
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    options: state?.authReducer?.threatSeverityClassList
});

export default withRouter(
    connect(mapStateToProps, { getSeverityClassList, getThreatNameList })(ThreatKontrolActions)
)
