import moment from "moment";
import DownloadCSV from "../../components/DownloadCSV";
import { api } from "../../server/request";
import { RESOURCE_FIELD_VESSELNAME } from "../../utils/constants";
import { readableBytes } from "../../utils/util";
import { ERROR_GET_VESSEL_SUMMARY_TABLE } from "../error_types";
import { handleResponse, handleError, handleResponseObj } from "../responseUtil";
import {
  SET_LOADING, GET_VESSEL_ALERTS, GET_VESSEL_EDGE_TOPOLOGY_PRODUCTS,
  GET_PRODUCT_EDGE_TOPOLOGY_DEVICES, SET_VESSEL_ALERTS_LOADING, SET_LINK_STATUS_PER_DEVICE_LOADING, GET_LINK_STATUS_PER_DEVICE, SET_VESSEL_SUMMARY_LOADING, GET_VESSEL_SUMMARY_TABLE, GET_WIDGEY_SUMMARY_DASHBOARD, GET_WIDGET_LINK_STATUS_SUMMARY_DASHBOARD, GET_VESSEL_SUMMARY_TABLE_COUNT, SET_VESSEL_DASHBOARD_LOADING, CLEAR_SITE_DASHBOARD, GET_TOP_WIDGET_AVAILABILITY, SET_VESSEL_TABLE_COUNT_LOADING, SET_VESSEL_WAN_LINK_STATUS_LOADING,
  SET_DASHBOARD_PER_DEVICE_WAN_PROFILES, GET_DASHBOARD_PER_DEVICE_WAN_PROFILES, SET_DASHBOARD_ADVANCED_BONDING_LOADING, GET_DASHBOARD_ADVANCED_BONDING, SET_DASHBOARD_LAN_STATUS_LOADING, GET_DASHBOARD_LAN_STATUS, SET_DASHBOARD_CONNECTED_HUB_LOADING, GET_DASHBOARD_CONNECTED_HUB,
  SET_SYSTEM_HEALTH_DEVICES_LOADING, GET_SYSTEM_HEALTH_DEVICES, SET_SYSTEM_HEALTH_METRICS_LINE_CHART_CPU_LOADING, GET_SYSTEM_HEALTH_METRICS_LINE_CHART_CPU, SET_SYSTEM_HEALTH_METRICS_LINE_CHART_MEMORY_LOADING, GET_SYSTEM_HEALTH_METRICS_LINE_CHART_MEMORY, SET_SYSTEM_HEALTH_METRICS_LINE_CHART_CONTRACK_LOADING, GET_SYSTEM_HEALTH_METRICS_LINE_CHART_CONTRACK,
  SET_SYSTEM_HEALTH_METRICS_LINE_CHART_LO_RX_DROPS_LOADING, GET_SYSTEM_HEALTH_METRICS_LINE_CHART_LO_RX_DROPS, SET_SYSTEM_HEALTH_METRICS_LINE_CHART_SWAP_LOADING, GET_SYSTEM_HEALTH_METRICS_LINE_CHART_SWAP, SET_SYSTEM_HEALTH_METRICS_LINE_CHART_TEMPERATURE_LOADING, GET_SYSTEM_HEALTH_METRICS_LINE_CHART_TEMPERATURE, SET_SYSTEM_HEALTH_DISK_STORAGE_LINE_CHART_LOADING, GET_SYSTEM_HEALTH_DISK_STORAGE_LINE_CHART
} from "../types";

export const getVesselAlerts = () => (dispatch) => {
  dispatch({ type: SET_VESSEL_ALERTS_LOADING, payload: true });
  // @TODO: need to add &components= and &statuses=
  api
    .getRequest(`/alerts/webhook/v0/alerts?alertName=movement`)
    .then((res) => handleResponse(res, dispatch, GET_VESSEL_ALERTS))
    .catch((err) => handleError(err, dispatch));
};

export const getVesselEdgeTopologyProducts = (dpId, k4Id) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let URL = `/edge/distributionPartners/${dpId}/locations/${k4Id}`;
  api
    .getRequest(URL)
    .then((res) => handleResponse(res, dispatch, GET_VESSEL_EDGE_TOPOLOGY_PRODUCTS))
    .catch((err) => handleError(err, dispatch));
};
export const getProductEdgeTopologyDevices = (dpId, k4Id, productId) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let URL = "/edge/distributionPartners/" + dpId + "/locations/" + k4Id + "/products/" + productId + "/devices";
  api
    .getRequest(URL)
    .then((res) => handleResponse(res, dispatch, GET_PRODUCT_EDGE_TOPOLOGY_DEVICES))
    .catch((err) => handleError(err, dispatch));
};

export const getIntervalInFormat = (interval) => {
  switch (interval) {
    case "15m": return "15 MINUTE";
    case "1h": return "1 HOUR";
    case "2h": return "2 HOUR";
    case "4h": return "4 HOUR";
    case "6h": return "6 HOUR";
    case "12h": return "12 HOUR";
    case "1d": return "1 DAY";
    case "7d": return "7 DAY";
    case "30d": return "30 DAY";
    default: return "15 MINUTE";
  }
}

export const getIntervalInNewFormat = (interval) => {
  switch (interval) {
    case "15m": return "MINUTE,15";
    case "1h": return "HOUR,1";
    case "2h": return "HOUR,2";
    case "6h": return "HOUR,6";
    case "12h": return "HOUR,12";
    case "1d": return "DAY,1";
    case "7d": return "DAY,7";
    case "30d": return "DAY,30";
    default: return "MINUTE,15";
  }
}

export const getAvilWindow = (interval) => {
  switch (interval) {
    case "5m": return 1;
    case "10m": return 2;
    case "15m": return 3;
    case "30m": return 6;
    case "1h": return 12;
    case "2h": return 24;
    case "3h": return 36;
    case "6h": return 72;
    case "12h": return 144;
    case "1d": return 288;
    case "7d": return 2016;
    case "30d": return 8640;
    default: return 3;
  }
}

export const formatInterval = (interval) => {
  switch (interval) {
    case "15m": return "15m";
    case "1h": return "1h";
    case "2h": return "2h";
    case "6h": return "6h";
    case "12h": return "12h";
    case "1d": return "24h";
    case "7d": return "168h";
    case "30d": return "720h";
    default: return "15m";
  }
}

export const getLinkStatusForDashBoard = (deviceId,interval,k4Id) => (dispatch) => {
  let isClickHouse = true;
  dispatch({ type: SET_LINK_STATUS_PER_DEVICE_LOADING, payload: true });
  const data : any = {
    query: 'LINK_STATUS_DASHBOARD_WITH_SPEED_TEST_DATA_AND_QoE',
    named: true,
    parameters: {
      "deviceIdDetails":deviceId,
      "intervalNum": isClickHouse ? getIntervalInNewFormat(interval) : getIntervalInFormat(interval),
      "k4Id": k4Id,
      "avail_window": getAvilWindow(interval)
    },
    format: 'csv'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
  .postRequestOut(`${apiUrl}`, data)
  .then((res) => {
    res.data["passedDevice"] = deviceId;
    handleResponse(res, dispatch, GET_LINK_STATUS_PER_DEVICE)
  })
  .catch((err) => handleError(err, dispatch));
};

export const getIntervalCheck = (minutes) => {
  if (minutes <= 1440) {
    return 5;
  } else {
    return 60;
  }
}

export const vesselSummaryTableData = (vessels: [JSON], searchText: [String], page: number, limit: number, sort, order, interval: string, ouName: string, signal: AbortSignal, filter:string="", startDate, endDate) => (dispatch) => {
  dispatch({ type: SET_VESSEL_SUMMARY_LOADING, payload: true });
  let isClickHouse = true
  const vesselMap: any = [];
  vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
  })
  let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
  
  const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);

  const data: any = {
    query: 'SPORTS_SITE_SUMMARY',
    named: true,
    parameters: {
      "dp": "",
      "locationID": (k4idhere && k4idhere !== '') ? `${k4idhere}` : "",
      "sortColumn": sort,
      "sortOrder": order,
      "pageOffset": page ? page : 0,
      "pageSize": limit,
      "search": searchText ? searchText : "",
      "interval": getIntervalInFormat(interval),
      "avail_window": getAvilWindow(interval),
      "apps_interval": interval === '15mins' ? getIntervalInFormat("1h") : getIntervalInFormat(interval),
      "filter": filter
    },
    format: 'csv'
  }
  const getQueryName = (sortColumn) => {
      switch (sortColumn) {
        case "clients": return 'SPORTS_SITE_SUMMARY_CLIENTS';
        case "totalUsage": return "SPORTS_SITE_SUMMARY_TUSAGE";
        case "availibility": return "SPORTS_SITE_SUMMARY_AVAILIBILITY";
        case "max_quota": return "SPORTS_SITE_SUMMARY_QUOTA";
        default: return "SPORTS_SITE_SUMMARY_SYNC";
    }
  }

  let QueryName = getQueryName(sort)
  const dataCh: any = {
    query: QueryName,
    named: true,
    parameters: {
      "dp": "",
      "locationID": (k4idhere && k4idhere !== '') ? `${k4idhere}` : "",
      "sortColumn": sort,
      "sortOrder": order,
      "pageOffset": page ? page : 0,
      "pageSize": limit,
      "search": searchText ? searchText : "",
      "interval": getIntervalInNewFormat(interval),
      "table": (interval === "15mins" ||interval === "1h" || interval === "2h" || interval === "6h" || interval === "12h" || interval === "1d" ) ? "5m" : '1h',
      "apps_interval": interval === '15mins' ? getIntervalInNewFormat("1h") : getIntervalInNewFormat(interval),
      "filter": filter,
      "avail_window": avail_window
    },
    format: 'csv'
  }
  let _vessels: any[] = [];
  if (vessels && Array.isArray(vessels)) {
    for (let i = 0; i < vessels.length; i++) {
      const item = vessels[i];
      _vessels.push({
        k4Id: item["id"],
        vesselName: item[RESOURCE_FIELD_VESSELNAME],
      });
    }
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
    .postRequestOut(`${apiUrl}`, isClickHouse ? dataCh : data, signal)
    .then((res) => {
      dispatch({ type: SET_VESSEL_SUMMARY_LOADING, payload: false });
      handleResponse(res, dispatch, GET_VESSEL_SUMMARY_TABLE)
    })
    .catch((err) => handleError(err, dispatch));
}

export const vesselSummaryDashboard = (vessels: [JSON], searchText: [String], interval: string, ouName: string, signal: AbortSignal, filter:string="", startDate :moment.Moment, endDate: moment.Moment) => (dispatch) => {
  dispatch({ type: SET_VESSEL_DASHBOARD_LOADING, payload: true });
  let isClickHouse = true
  const vesselMap: any = [];
  vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
  })

  let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
  let now = moment(moment.now()).utc();
  const data: any = {
    query: 'WAN_ALL_LINKS_USAGE',
    named: true,
    parameters: {
      "dp": "",
      "k4Id": (k4idhere && k4idhere !== '') ? `${k4idhere}` : "",
      "search": searchText ? searchText : "",
      "endTime": endDate,
      "startTime": startDate,
      "filter": filter
    },
    format: 'csv'
  }

  const dataCh: any  = {
    query: 'WAN_ALL_LINKS_USAGE',
    named: true,
    parameters: {
      "dp": "",
      "k4Id": (k4idhere && k4idhere !== '') ? `${k4idhere}` : "",
      "search": searchText ? searchText : "",
      "startTime": startDate,
      "endTime": endDate,
      "table": (interval === "15mins" ||interval === "1h" || interval === "2h" || interval === "6h" || interval === "12h" || interval === "1d" ) ? "5m" : '1h',
      "interval": getIntervalInNewFormat(interval),
      "filter": filter
    },
    format: 'csv'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
   .postRequestOut(`${apiUrl}`, isClickHouse ? dataCh : data, signal)
    .then((res) => {
      dispatch({ type: SET_VESSEL_DASHBOARD_LOADING, payload: false });
      handleResponse(res, dispatch, GET_WIDGEY_SUMMARY_DASHBOARD)
    })
    .catch((err) => handleError(err, dispatch));
}

export const vesselLinkStatusSummaryDashboard = (vessels: [JSON], searchText: [String], interval: string, ouName: string, signal: AbortSignal, filter:string="") => (dispatch) => {
  dispatch({ type: SET_VESSEL_WAN_LINK_STATUS_LOADING, payload: true });
  let isClickHouse = true
  const vesselMap: any = [];
  vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
  })

  let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
  // let now = moment(moment.now()).utc();
  const data: any = {
    query: 'SPORTS_WAN_ALL_LINK_STATUS',
    named: true,
    parameters: {
      "dp": "",
      "k4Id": (k4idhere && k4idhere !== '') ? `${k4idhere}` : "",
      "search": searchText ? searchText : "",
      // "endTime": now.toISOString(),
      // "startTime": now.subtract(moment.duration("PT" + interval.toUpperCase())).toISOString(),
      "filter": filter
    },
    format: 'csv'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
    .postRequestOut(`${apiUrl}`, data, signal)
    .then((res) => {
      dispatch({ type: SET_VESSEL_WAN_LINK_STATUS_LOADING, payload: false });
      handleResponse(res, dispatch, GET_WIDGET_LINK_STATUS_SUMMARY_DASHBOARD)
    })
    .catch((err) => handleError(err, dispatch));
}


export const vesselSummaryTableDataCount = (vessels: [JSON], searchText: [String], page: number, limit: number, sort, order, interval: string, ouName: string, signal: AbortSignal, filter: string = "") => (dispatch) => {
  dispatch({ type: SET_VESSEL_TABLE_COUNT_LOADING, payload: true });
  const vesselMap: any = [];
  vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
  })
  getVesselSummaryTableCount(vesselMap, searchText, filter, signal)
  .then((res) => {
    dispatch({ type: SET_VESSEL_TABLE_COUNT_LOADING, payload: false });
    handleResponse(res, dispatch, GET_VESSEL_SUMMARY_TABLE_COUNT);
  })
  .catch((err) => handleError(err, dispatch));;
}

export const clearSiteDashboard = () => (dispatch) => {
  dispatch({ type: CLEAR_SITE_DASHBOARD});
}

export const getTopWidgetAvailability = (vessels: any, search, interval,filter, startDate, endDate) => (dispatch) => {
  let isClickHouse = true
  const vesselMap: any = [];
  vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
  })
  let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
  
  const getDivisionValue = getIntervalCheck(Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3));
  const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / getDivisionValue);

  dispatch({ type: SET_LOADING, payload: true });
  const data: any = {
    query: 'SPORTS_WAN_ALL_LINK_AVAILABILITY',
    named: true,
    parameters: {
      dp: "",
      k4Id: k4idhere,
      search: search ? search : "",
      interval: getIntervalInFormat(interval),
      avail_window: getAvilWindow(interval),
      filter: filter
    },
    format: 'csv'
  }

  const dataCh: any = {
    query: 'SPORTS_WAN_ALL_LINK_AVAILABILITY',
    named: true,
    parameters: {
      dp: "",
      k4Id: k4idhere,
      search: search ? search : "",
      interval: getIntervalInNewFormat(interval),
      "table": (interval === "15mins" ||interval === "1h" || interval === "2h" || interval === "6h" || interval === "12h" || interval === "1d" ) ? "5m" : '1h',
      filter: filter,
      avail_window: avail_window
    },
    format: 'csv'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
    .postRequestOut(`${apiUrl}`, isClickHouse ? dataCh : data)
    .then((res) => {
      handleResponse(res, dispatch, GET_TOP_WIDGET_AVAILABILITY)
    }) 
    .catch((err) => handleError(err, dispatch));
};

export function getVesselSummaryTableCount(vesselIds: string[], searchText: [String], filter: string, signal?: AbortSignal) {
  const k4idhere = vesselIds.length > 0 ? `${"'" + vesselIds.join("','") + "'"}` : null;
  let isClickHouse = true
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const data: any = {
    query: 'SPORTS_SITE_SUMMARY_COUNT',
    named: true,
    parameters: {
      "dp": "",
      "locationID": (k4idhere && k4idhere !== '') ? `${k4idhere}` : "",
      "search": searchText ? searchText : "",
      "filter": filter
    }
  };
  return api
   .postRequestOut(`${apiUrl}`, data, signal)
}

export const getWanProfilesPerDevice = (k4Id, deviceId) => (dispatch) => {
  let isClickHouse = true;
  dispatch({ type: SET_DASHBOARD_PER_DEVICE_WAN_PROFILES, payload: true });
  const wanProfiles : any = {
    query: 'GET_PER_DEVICE_WAN_PROFILES',
    named: true,
    parameters: {
      location_id: k4Id,
      device_id: deviceId,
      hidden: '0'
    },
    format: 'csv',
    groupBy: 'wan_profile_name'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
  .postRequestOut(`${apiUrl}`, wanProfiles)
  .then((res) => { handleResponse(res, dispatch, GET_DASHBOARD_PER_DEVICE_WAN_PROFILES)})
  .catch((err) => handleError(err, dispatch));
};

export const getDashboardAdvancedBonding = (k4Id, deviceId) => (dispatch) => {
  let isClickHouse = true;
  dispatch({ type: SET_DASHBOARD_ADVANCED_BONDING_LOADING, payload: true });
  const advancedBonding : any = {
    query: 'GET_ADVANCED_BONDING',
    named: true,
    parameters: {
      location_id: k4Id,
      device_id: deviceId
    },
    format: 'csv'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
  .postRequestOut(`${apiUrl}`, advancedBonding)
  .then((res) => { handleResponse(res, dispatch, GET_DASHBOARD_ADVANCED_BONDING)})
  .catch((err) => handleError(err, dispatch));
};

export const getDashboardLANStatus = (k4Id, deviceId) => (dispatch) => {
  let isClickHouse = true;
  dispatch({ type: SET_DASHBOARD_LAN_STATUS_LOADING, payload: true });
  const LANStatus : any = {
    query: 'GET_PER_DEVICE_LAN_STATUS',
    named: true,
    parameters: {
      location_id: k4Id,
      device_id: deviceId
    },
    format: 'csv'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
  .postRequestOut(`${apiUrl}`, LANStatus)
  .then((res) => { handleResponse(res, dispatch, GET_DASHBOARD_LAN_STATUS)})
  .catch((err) => handleError(err, dispatch));
};

export const getDashboardConnectedHub = (k4Id, deviceId) => (dispatch) => {
  let isClickHouse = true;
  dispatch({ type: SET_DASHBOARD_CONNECTED_HUB_LOADING, payload: true });
  const connectedHub : any = {
    query: 'GET_PER_DEVICE_HUB_IPPORT',
    named: true,
    parameters: {
      location_id: k4Id,
      device_id: deviceId
    },
    format: 'csv'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
  .postRequestOut(`${apiUrl}`, connectedHub)
  .then((res) => { handleResponse(res, dispatch, GET_DASHBOARD_CONNECTED_HUB)})
  .catch((err) => handleError(err, dispatch));
};

export const getSystemHealthDevices = (k4Id) => (dispatch) => {
  let isClickHouse = true;
  dispatch({ type: SET_SYSTEM_HEALTH_DEVICES_LOADING, payload: true });
  const devices: any = {
    query: 'SYSTEM_HEALTH_GET_DEVICES',
    named: true,
    parameters: {
      locationId: k4Id
    },
    format: 'csv'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
    .postRequestOut(`${apiUrl}`, devices)
    .then((res) => { handleResponse(res, dispatch, GET_SYSTEM_HEALTH_DEVICES) })
    .catch((err) => handleError(err, dispatch));
};

export const getSystemHealthCpuChart = (k4Id, startDate, endDate, intervalNum, interval, deviceId) => (dispatch) => {
  let isClickHouse = true;
  dispatch({ type: SET_SYSTEM_HEALTH_METRICS_LINE_CHART_CPU_LOADING, payload: true });
  const cpuData: any = {
    query: 'SYSTEM_HEALTH_METRICS_REVERSE_LINE_CHART',
    named: true,
    series: "value",
    tsColumn: "tb",
    groupBy: "forcharting",
    parameters: {
      "locationId": k4Id,
      "device_id": deviceId,
      "startTime": startDate,
      "endTime": endDate,
      "intervalNum": intervalNum,
      "interval": interval,
      "metric": "cpu",
      "aggregation_type": "AVG"
    },
    format: 'chart'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
    .postRequestOut(`${apiUrl}`, cpuData)
    .then((res) => { handleResponse(res, dispatch, GET_SYSTEM_HEALTH_METRICS_LINE_CHART_CPU) })
    .catch((err) => handleError(err, dispatch));
};

export const getSystemHealthMemoryChart = (k4Id, startDate, endDate, intervalNum, interval, deviceId) => (dispatch) => {
  let isClickHouse = true;
  dispatch({ type: SET_SYSTEM_HEALTH_METRICS_LINE_CHART_MEMORY_LOADING, payload: true });
  const memoryData: any = {
    query: 'SYSTEM_HEALTH_METRICS_REVERSE_LINE_CHART',
    named: true,
    series: "value",
    tsColumn: "tb",
    groupBy: "forcharting",
    parameters: {
      "locationId": k4Id,
      "device_id": deviceId,
      "startTime": startDate,
      "endTime": endDate,
      "intervalNum": intervalNum,
      "interval": interval,
      "metric": "memory",
      "aggregation_type": "AVG"
    },
    format: 'chart'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
    .postRequestOut(`${apiUrl}`, memoryData)
    .then((res) => { handleResponse(res, dispatch, GET_SYSTEM_HEALTH_METRICS_LINE_CHART_MEMORY) })
    .catch((err) => handleError(err, dispatch));
};

export const getSystemHealthContrackChart = (k4Id, startDate, endDate, intervalNum, interval, deviceId) => (dispatch) => {
  let isClickHouse = true;
  dispatch({ type: SET_SYSTEM_HEALTH_METRICS_LINE_CHART_CONTRACK_LOADING, payload: true });
  const contrackData: any = {
    query: 'SYSTEM_HEALTH_METRICS_LINE_CHART',
    named: true,
    series: "value",
    tsColumn: "tb",
    groupBy: "forcharting",
    parameters: {
      "locationId": k4Id,
      "device_id": deviceId,
      "startTime": startDate,
      "endTime": endDate,
      "intervalNum": intervalNum,
      "interval": interval,
      "metric": "conntrack",
      "aggregation_type": "AVG"
    },
    format: 'chart'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
    .postRequestOut(`${apiUrl}`, contrackData)
    .then((res) => { handleResponse(res, dispatch, GET_SYSTEM_HEALTH_METRICS_LINE_CHART_CONTRACK) })
    .catch((err) => handleError(err, dispatch));
};

export const getSystemHealthRxDropsChart = (k4Id, startDate, endDate, intervalNum, interval, deviceId) => (dispatch) => {
  let isClickHouse = true;
  dispatch({ type: SET_SYSTEM_HEALTH_METRICS_LINE_CHART_LO_RX_DROPS_LOADING, payload: true });
  const rx_drops_data: any = {
    query: 'SYSTEM_HEALTH_METRICS_LINE_CHART',
    named: true,
    series: "value",
    tsColumn: "tb",
    groupBy: "forcharting",
    parameters: {
      "locationId": k4Id,
      "device_id": deviceId,
      "startTime": startDate,
      "endTime": endDate,
      "intervalNum": intervalNum,
      "interval": interval,
      "metric": "lo_rx_drops",
      "aggregation_type": "AVG"
    },
    format: 'chart'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
    .postRequestOut(`${apiUrl}`, rx_drops_data)
    .then((res) => { handleResponse(res, dispatch, GET_SYSTEM_HEALTH_METRICS_LINE_CHART_LO_RX_DROPS) })
    .catch((err) => handleError(err, dispatch));
};

export const getSystemHealthSwapChart = (k4Id, startDate, endDate, intervalNum, interval, deviceId) => (dispatch) => {
  let isClickHouse = true;
  dispatch({ type: SET_SYSTEM_HEALTH_METRICS_LINE_CHART_SWAP_LOADING, payload: true });
  const swapData: any = {
    query: 'SYSTEM_HEALTH_METRICS_REVERSE_LINE_CHART',
    named: true,
    series: "value",
    tsColumn: "tb",
    groupBy: "forcharting",
    parameters: {
      "locationId": k4Id,
      "device_id": deviceId,
      "startTime": startDate,
      "endTime": endDate,
      "intervalNum": intervalNum,
      "interval": interval,
      "metric": "swap",
      "aggregation_type": "AVG"
    },
    format: 'chart'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
    .postRequestOut(`${apiUrl}`, swapData)
    .then((res) => { handleResponse(res, dispatch, GET_SYSTEM_HEALTH_METRICS_LINE_CHART_SWAP) })
    .catch((err) => handleError(err, dispatch));
};

export const getSystemHealthTemperatureChart = (k4Id, startDate, endDate, intervalNum, interval, deviceId) => (dispatch) => {
  let isClickHouse = true;
  dispatch({ type: SET_SYSTEM_HEALTH_METRICS_LINE_CHART_TEMPERATURE_LOADING, payload: true });
  const temperatureData: any = {
    query: 'SYSTEM_HEALTH_METRICS_LINE_CHART',
    named: true,
    series: "value",
    tsColumn: "tb",
    groupBy: "forcharting",
    parameters: {
      "locationId": k4Id,
      "device_id": deviceId,
      "startTime": startDate,
      "endTime": endDate,
      "intervalNum": intervalNum,
      "interval": interval,
      "metric": "temperature",
      "aggregation_type": "AVG"
    },
    format: 'chart'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
    .postRequestOut(`${apiUrl}`, temperatureData)
    .then((res) => { handleResponse(res, dispatch, GET_SYSTEM_HEALTH_METRICS_LINE_CHART_TEMPERATURE) })
    .catch((err) => handleError(err, dispatch));
};

export const getSystemHealthDiskStorageChart = (k4Id, startDate, endDate, intervalNum, interval, deviceId) => (dispatch) => {
  let isClickHouse = true;
  dispatch({ type: SET_SYSTEM_HEALTH_DISK_STORAGE_LINE_CHART_LOADING, payload: true });
  const diskStorage: any = {
    query: 'SYSTEM_HEALTH_DISK_STORAGE_LINE_CHART',
    named: true,
    series: "media_root_ro,media_root_rw,media_k4_app_b_ro,media_k4_app_b_rw,data,var_log,etc_k4data,boot,boot_efi",
    tsColumn: "tb",
    groupBy: "device_id",
    parameters: {
      "locationId": k4Id,
      "device_id": deviceId,
      "startTime": startDate,
      "endTime": endDate,
      "intervalNum": intervalNum,
      "interval": interval,
      "aggregation_type": "AVG"
    },
    format: 'chart'
  }
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  api
    .postRequestOut(`${apiUrl}`, diskStorage)
    .then((res) => { handleResponse(res, dispatch, GET_SYSTEM_HEALTH_DISK_STORAGE_LINE_CHART) })
    .catch((err) => handleError(err, dispatch));
};
