import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { getCaptiveDashEventsTable } from "../../../actions/Users/authenticateCaptiveDahshboard";
import _ from "lodash";
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, Tooltip, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../../../components/Pagination";
import { getDecodeURI, getEncodedURI, convertDateTimeIntoTimezone } from "../../../utils/util";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../../../components/DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import { JsonDataTooltip } from "../../../components/JSONTooltip/JsonTooltip";

const EventsTable = (props) => {
    const { vessels, userTimezone, getCaptiveDashEventsTable, newSummaryStartDate, newSummaryEndDate, getCDEventsTable, setCaptiveDashEventsTableLoading, csvDataDownloded, csvDownloadId, downloadAsCSV, location, history, values, setValues, applyFilterClick } = props;

    const _q = getDecodeURI(location?.search);
    const DEFAULT_PAGE_SIZE = 15;
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const ENTER_KEY_CODE = "Enter";
    const [tableData, setTableData] = useState<any>([]);
    const [count, setCount] = useState(0);
    const EVENT_TIME = 'eventTime';
    const ORG_NAME = 'organizationName';
    const SITE = 'sitename';
    const DESCRIPTION = 'description';
    const EVENT_TYPE = 'eventType';
    const USERNAME = 'userName';
    const IP = 'ipAddress';
    const MAC = 'macAddress';
    const ACTION = 'action';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : 'eventTime');
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const dispatch = useDispatch();
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const { selectedUserNames, selectedIps, selectedMacs, selectedEventActions, selectedEventTypes } = values;
    const { setSelectedUserNames, setSelectedIps, setSelectedMacs, setSelectedEventActions, setSelectedEventTypes } = setValues;

    useEffect(() => {
        if (vessels?.length > 0) {
            getCaptiveDashEventsTable(vessels, newSummaryStartDate, newSummaryEndDate, 1, limit, sort, sortOrder, searchValue, selectedUserNames, selectedIps, selectedMacs, selectedEventActions, selectedEventTypes)
        } else {
            setTableData([]);
            setCount(0);
        }
    }, [vessels, newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        if(!_.isEmpty(getCDEventsTable)) {
            const data = getCDEventsTable.hasOwnProperty('data') ? getCDEventsTable.data : [];
            setCount(data?.[0]?.['totalCount']);
            setTableData(data);
        }
    }, [getCDEventsTable])

    const convertToCsv = (data: any[][]) => {
        const [_columns, ...rows] = data;
        const csvRows: any[] = [];
        rows.forEach(row => {
            const osDetails = row[9] && JSON.parse(row[9]);
            const osColumn = Object.entries(osDetails)
            .map(([key, value]) => `${key}: '${value}'`)
            .join('\n');
            const formattedOsColumn = `"${osColumn.replace(/"/g, '""')}"`;
            csvRows.push([`${convertDateTimeIntoTimezone(row[1], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}`, row[12], row[13], formattedOsColumn, row[2], row[7], row[6], row[4], row[5], row[3]])
        })
        return [['Event Time', 'Organization', 'Site', 'OS', 'Event Type', 'Description', 'Username', 'IP Address', 'MAC Address', 'Action'], ...csvRows]
    }

    useEffect(() => {
        if (csvDataDownloded && csvDataDownloded.length > 0) {
            if (csvDownloadId === 'captive-dashboard-events-table') {
                const [columns, _countRow, ...rows] = csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows]), {
                    formatters : {
                        0: 'ESC-COMMA',
                        1: 'ESC-COMMA',
                        2: 'ESC-COMMA',
                        4: 'ESC-COMMA',
                        5: 'ESC-COMMA',
                    }
                });
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [csvDataDownloded])

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        const Page = 1;
        setPage(1);
        doNavigate(params);
        getCaptiveDashEventsTable(vessels, newSummaryStartDate, newSummaryEndDate, Page, limit, sort, sortOrder, value, selectedUserNames, selectedIps, selectedMacs, selectedEventActions, selectedEventTypes)
    };

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 1;
        setPage(1);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        getCaptiveDashEventsTable(vessels, newSummaryStartDate, newSummaryEndDate, Page, limit, field, order, searchValue, selectedUserNames, selectedIps, selectedMacs, selectedEventActions, selectedEventTypes)
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const handleChangePage = (e, value) => {
        setPage(value);
        let params: any = getDecodeURI(location?.search);
        value == 1 ? delete params.page : params.page = value;
        doNavigate(params);
        getCaptiveDashEventsTable(vessels, newSummaryStartDate, newSummaryEndDate, value, limit, sort, sortOrder, searchValue, selectedUserNames, selectedIps, selectedMacs, selectedEventActions, selectedEventTypes)
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        Limit == 15 ? delete params.limit : params.limit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        getCaptiveDashEventsTable(vessels, newSummaryStartDate, newSummaryEndDate, Page, Limit, sort, sortOrder, searchValue, selectedUserNames, selectedIps, selectedMacs, selectedEventActions, selectedEventTypes)
    }

    const handleDownloadReport = () => {
        let isClickHouse = true;
        const startDate = newSummaryStartDate;
        const endDate = newSummaryEndDate;
        const params: CsvParameters = {
            type: isClickHouse? 'QUERY_CH' : "QUERY",
            id : "captive-dashboard-events-table",
            queryName: 'CAPTIVE_DASHBOARD_EVENTS_TABLE_WITH_FILTERS',
            payload: {
                "k4Id": vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
                "startDate": startDate,
                "endDate": endDate,
                "pageOffset": 0,
                "pageSize": count,
                "sortColumn": sort,
                "sortOrder": sortOrder,
                "search": searchValue,
                userName_filter: selectedUserNames?.length > 0 ? `AND userName IN (${selectedUserNames?.map(user => `'${user}'`).join(',')})` : '',
                ip_filter: selectedIps?.length > 0 ? `AND ipAddress IN (${selectedIps?.map(ip => `'${ip}'`).join(',')})` : '',
                mac_filter: selectedMacs?.length > 0 ? `AND macAddress IN (${selectedMacs?.map(mac => `'${mac}'`).join(',')})` : '',
                action_filter: selectedEventActions?.length > 0 ? `AND action IN (${selectedEventActions?.map(action => `'${action}'`).join(',')})` : '',
                eventType_filter: selectedEventTypes?.length > 0 ? `AND eventType IN (${selectedEventTypes?.map(type => `'${type}'`).join(',')})` : ''
              }
        }
        downloadAsCSV(params);
    }

    const rowsOption = [10, 15, 25, 50, 100]

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    const handleSiteClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        params.k4Ids = `${row.k4Id}:${row.sitename}`;
        delete params.page;
        delete params.limit;
        delete params.sort;
        delete params.sortOrder;
        delete params.search;
        doNavigate(params);
    };

    const handleOrgClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        params.ouName = `${row.organizationName}`;
        params.ouId = `${row.dpId}`;
        delete params.k4Ids;
        delete params.page;
        delete params.limit;
        delete params.sort;
        delete params.sortOrder;
        delete params.search;
        doNavigate(params);
    };

    const handleUserNameClick = (row) => {
        setSelectedUserNames([row.userName])
        applyFilterClick([], [], [], [], '', null, null, null, null, false, [row.userName], selectedIps, selectedMacs, selectedEventActions, selectedEventTypes);
    }

    const handleIpClick = (row) => {
        setSelectedIps([row.ipAddress])
        applyFilterClick([], [], [], [], '', null, null, null, null, false, selectedUserNames, [row.ipAddress], selectedMacs, selectedEventActions, selectedEventTypes);
    };
    
    const handleMacClick = (row) => {
        setSelectedMacs([row.macAddress])
        applyFilterClick([], [], [], [], '', null, null, null, null, false, selectedUserNames, selectedIps, [row.macAddress], selectedEventActions, selectedEventTypes);
    };

    const handleActionClick = (row) => {
        setSelectedEventActions([row.action])
        applyFilterClick([], [], [], [], '', null, null, null, null, false, selectedUserNames, selectedIps, selectedMacs, [row.action], selectedEventTypes);
    }
    
    const handleEventTypeClick = (row) => {
        setSelectedEventTypes([row.eventType])
        applyFilterClick([], [], [], [], '', null, null, null, null, false, selectedUserNames, selectedIps, selectedMacs, selectedEventActions, [row.eventType]);
    }

    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container topbarMarginPaddingReset alignTopbarItemsCenter">
                    <Grid item xs={5} sm={5} md={5} lg={5} className="site-details-header usage-site-details-header">Events</Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7} className="usage-table-search-download-div">
                        <Grid className="usage-search-border-color">
                            <Box className="searchbarBox">
                                {<TextField
                                    id="outlined-basic"                                
                                    variant="outlined"
                                    placeholder="Type something"
                                    autoComplete="off"
                                    size="small"
                                    value={searchValue}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel1" }}
                                    InputProps={{
                                        className: "searchbarInputs",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchValue && <SearchIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchValue && <CloseIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        {count > 0 && <Grid>
                            <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner tablePaddingReset">
                    <Paper className="pagination-position config-pagination new-usage remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={count ? Number(count) : 0}
                            rowsPerPage={limit ? limit : 0}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer" component={Paper} style={{ maxHeight: 'calc(100vh - 335px)', overflow: 'auto' }}>
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, EVENT_TIME)}>EVENT TIME</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === EVENT_TIME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, EVENT_TIME, ASC_ORDER)} />
                                            <img src={sort === EVENT_TIME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, EVENT_TIME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ORG_NAME)}>ORGANIZATION</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === ORG_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, ORG_NAME, ASC_ORDER)} />
                                            <img src={sort === ORG_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, ORG_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SITE)}>SITE</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SITE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITE, ASC_ORDER)} />
                                            <img src={sort === SITE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, SITE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell os-width-7per">
                                        <span style={{ whiteSpace: "nowrap" }}>
                                            <span className="font-wt-900 topAppsFonts" >OS</span>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left events-width-0">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, EVENT_TYPE)}>EVENT TYPE</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === EVENT_TYPE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, EVENT_TYPE, ASC_ORDER)} />
                                            <img src={sort === EVENT_TYPE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, EVENT_TYPE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, DESCRIPTION)}>DESCRIPTION</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === DESCRIPTION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DESCRIPTION, ASC_ORDER)} />
                                            <img src={sort === DESCRIPTION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, DESCRIPTION, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, USERNAME)}>USERNAME</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === USERNAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, USERNAME, ASC_ORDER)} />
                                            <img src={sort === USERNAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, USERNAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left events-width-0">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, IP)}>IP ADDRESS</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === IP && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, IP, ASC_ORDER)} />
                                            <img src={sort === IP && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, IP, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left events-width-0">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, MAC)}>MAC ADDRESS</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === MAC && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, MAC, ASC_ORDER)} />
                                            <img src={sort === MAC && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, MAC, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable login-left-align login-tableCell new-summary-left events-width-0">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ACTION)}>ACTION</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === ACTION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, ACTION, ASC_ORDER)} />
                                            <img src={sort === ACTION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, ACTION, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {count > 0 && tableData && 1 != tableData?.length && !setCaptiveDashEventsTableLoading ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">
                                    {
                                        tableData && tableData?.map((item, i) => (
                                            i > 0 && <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{convertDateTimeIntoTimezone(item?.eventTime, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                                <Tooltip title={item?.organizationName}>
                                                    <TableCell className='login-left-align inter-font Table-Cell-Opacity elipseThreat selectedClick' onClick={() => handleOrgClick(item)}>{item?.organizationName}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={item?.sitename}>
                                                    <TableCell className='login-left-align inter-font Table-Cell-Opacity elipseThreat selectedClick' onClick={() => handleSiteClick(item)}>{item?.sitename}</TableCell>
                                                </Tooltip>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                                    {(() => {
                                                        const deviceInformation = item?.['deviceInformation'] ? JSON.parse(item['deviceInformation']) : {};
                                                        return (
                                                            <div className="device-type-metadata">
                                                                <span className="inter-font device-type-padding-left-0">{deviceInformation.os}</span>
                                                                {deviceInformation?.os && <JsonDataTooltip data={deviceInformation} />}
                                                            </div>
                                                        );
                                                    })()}
                                                </TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity selectedClick" onClick={() => handleEventTypeClick(item)}>{item?.eventType}</TableCell>
                                                <Tooltip title={item?.description}>
                                                    <TableCell className='login-left-align inter-font Table-Cell-Opacity elipseThreat'>{item?.description}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={item?.userName}>
                                                    <TableCell className='login-left-align inter-font Table-Cell-Opacity elipseThreat selectedClick' onClick={() => handleUserNameClick(item)}>{item?.userName}</TableCell>
                                                </Tooltip>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity selectedClick' onClick={() => handleIpClick(item)}>{item?.ipAddress}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity selectedClick' onClick={() => handleMacClick(item)}>{item?.macAddress}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity selectedClick whiteSpace-nowrap" onClick={() => handleActionClick(item)}>{item?.action}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
  userTimezone: state.authReducer.userTimezone,
  newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
  newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
  getCDEventsTable: state?.authReducer?.getCDEventsTable,
  setCaptiveDashEventsTableLoading: state?.authReducer?.setCaptiveDashEventsTableLoading,
  csvDataDownloded: state?.authReducer?.csvDataDownloded,
  csvDownloadId: state?.authReducer?.csvDownloadId
});

export default withRouter(
  connect(mapStateToProps, { getCaptiveDashEventsTable, downloadAsCSV })(EventsTable)
);