import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';

interface Options {
    defaultSelection: string[];
    handleSelection: (selected: string[]) => void;
    name: string;
    values: string[];
    sx?: any;
}

const MultipleAutocomplete = (props: Options): JSX.Element => {
    const { defaultSelection, handleSelection, values, name, sx } = props;

    const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultSelection);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setSelectedOptions(defaultSelection);
    }, [defaultSelection]);

    const handleChange = (event: any, newValue: string[]) => {
        setSelectedOptions(newValue);
        handleSelection(newValue);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (event: React.SyntheticEvent, reason: string) => {
        if (reason !== 'selectOption') {
            setOpen(false);
        }
    };

    return (
        <div>
            <Autocomplete
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                ListboxProps={{ className: "list-box-props" }}
                disableClearable={true}
                multiple
                id="multiple-autocomplete"
                options={values}
                value={selectedOptions}
                onChange={handleChange}
                renderTags={(tagValue, getTagProps) =>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                            maxHeight: '80px',
                            overflowY: 'auto',
                        }}
                    >
                        {tagValue.map((option, index) => (
                            <Chip
                                // key={option}
                                label={option}
                                {...getTagProps({ index })}
                                deleteIcon={<CancelIcon />}
                                size="small"
                            />
                        ))}
                    </Box>
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={name}
                        placeholder="Search"
                        size="small"
                    />
                )}
                sx={{
                    ...sx,
                }}
                filterSelectedOptions
            />
        </div>
    );
};

export default MultipleAutocomplete;
