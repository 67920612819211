import { SyntheticEvent, useEffect, useState, useMemo } from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { Dialog, FormControl, Grid, TextField, Button, Typography, Checkbox, Autocomplete, AutocompleteChangeReason, AutocompleteChangeDetails, Chip, FormHelperText, IconButton, Tooltip, DialogContent } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { AccessNetwork, Action, Device, GeoConfig, User, WanProfile, SelectedDeviceType } from '../types';
import AlertingConditions from '../GeoActions';
import { clearDevicePolicyData, getAccessNetworks, getTrafficPolicies, getWanProfiles, getServiceLineForEdge } from '../../QuotaManagement/slice';
import './index.css';
import { nanoid } from 'nanoid';
import { extractEmails } from '../../../utils/util';
import teamsWebhookHelpDoc from "../../../asset/user_guide/TeamsWebhookCreateRemove.pdf";
import close from "../../../asset/image/close.png";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {getGroupList, getDeviceList} from "./../../../actions/Users/authenticateInventory"
import {getTrafficPolicyDataFromDeviceList, getWanProfileDataFromDeviceList, getAccessNetworkDataFromDeviceList} from "./../slice"
import { DevicesAction } from '../types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface GeoConfigConfigDialogProps {
    authReducer: any;
    heading: string;
    users: User[];
    open: boolean;
    geoConfig: GeoConfig | null;
    onSave: (dpId: string, geoConfig: GeoConfig, edit: boolean) => void;
    onCancel: () => void;
    getGroupList: (string)=> void;
    getDeviceList: (string) => void;
    getTrafficPolicyDataFromDeviceList: (string) => void;
    getWanProfileDataFromDeviceList: (string) => void;
    getAccessNetworkDataFromDeviceList: (string) => void;
}

function GeoConfigDialog(props: GeoConfigConfigDialogProps) {
    const { authReducer, heading, open, users, geoConfig, onSave, onCancel, getGroupList, getDeviceList, getTrafficPolicyDataFromDeviceList, getWanProfileDataFromDeviceList, getAccessNetworkDataFromDeviceList} = props;

    const [usersToNotify, setUsersToNotify] = useState<User[]>(geoConfig != null && geoConfig?.users_to_notify ? geoConfig.users_to_notify : []);
    const [notificationMethods, setNotificationMethods] = useState<string[]>(geoConfig != null && geoConfig.notification_methods ? geoConfig.notification_methods : []);
    const [defaultActions, setDefaultActions] = useState<Action[]>(geoConfig != null && geoConfig?.actions ? geoConfig.actions.filter(ac => ac.configuration_type === "default") : [])
    const [actions, setActions] = useState<Action[]>(geoConfig != null && geoConfig?.actions ? geoConfig.actions.filter(ac => ac.configuration_type === "custom") : [])
    const [ruleName, setRuleName] = useState<string>(geoConfig != null && geoConfig?.rule_name ? geoConfig?.rule_name : "")
    const [edit, setEdit] = useState<boolean>(false)
    const [targetType, setTargetType] = useState({label: ""});
    const [selectedSites, setSelectedSites] = useState<any[]>([]);
    const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
    const [selectedManagedGroups, setSelectedManagedGroups] = useState<any[]>([]);
    const [groupList, setGroupList] = useState<any[]>([]);
    const [siteList, setSiteList] = useState<any[]>([]);
    const [deviceList, setDeviceList] = useState<any[]>([]);
    const [siteFilterValue, setSiteFilterValue] = useState("");
    const [deviceFilterValue, setDeviceFilterValue] = useState("");
    const [managedGroupFilterValue, setManagedGroupFilterValue] = useState("");
    const [entryThreshold, setEntryThreshold] = useState<number>(5);
    const [exitThreshold, setExitThreshold] = useState<number>(5);
    const [selectedDeviceIdsList, setSelectedDeviceIdsList] = useState<SelectedDeviceType[]>([])
    const [isValid, setIsValid] = useState<boolean>(true)

    useEffect(() => {
        if (geoConfig?.actions) {
            let defaultActions = geoConfig.actions.filter(ac => ac.configuration_type === "default")
            defaultActions = defaultActions.map(defaultAction => {
                return {
                    ...defaultAction,
                    id: nanoid()
                }
            })
            setDefaultActions([...defaultActions])

            let actions = geoConfig.actions.filter(ac => ac.configuration_type === "custom")
            actions = actions.map(action => {
                return {
                    ...action,
                    id: nanoid()
                }
            })
            setActions([...actions])
        }
    }, [geoConfig?.actions])

    useEffect(()=>{
        let rule: string = geoConfig?.rule_name || ""
        setRuleName(rule)
    }, [geoConfig?.rule_name])

    useEffect(()=>{
        if(ruleName.length > 0 && (actions.length > 0 ? actions.filter(a => a.valid === false).length === 0 : true)){
            setIsValid(true)
        }else{
            setIsValid(false)
        }
    }, [actions, ruleName])

    useEffect(()=>{
        let targetType: string = geoConfig?.target_type || ""
        if(targetType == "devices"){
            setTargetType({label: "Device"})

            let actions = geoConfig?.actions || []
            actions = actions.filter(action => action.configuration_type == "custom")
            let selectedDevicesArr : any[] = []
            const devices:DevicesAction[] = actions.map(action => action.devices).filter((devices): devices is DevicesAction[] => devices !== undefined).flat();

            for (const device of devices) {
                const { device_id: deviceId, device_name: deviceName } = device;

                const isDeviceAlreadySelected = selectedDevicesArr.some(selectedDevice => selectedDevice.name === deviceName);

                if (!isDeviceAlreadySelected) {
                    selectedDevicesArr.push({
                        name: deviceName,
                        id: deviceId,
                    });
                }
            }

            setSelectedDevices([...selectedDevicesArr])
        } else if(targetType == "sites"){
            setTargetType({label: "Site"})

            let actions = geoConfig?.actions || []
            actions = actions.filter(action => action.configuration_type == "custom")
            let selectedSitesArr : any[] = []
            const devices:DevicesAction[] = actions.map(action => action.devices).filter((devices): devices is DevicesAction[] => devices !== undefined).flat();
            for (const device of devices) {
                const { site_id: siteId, site_name: siteName } = device;

                const isSiteAlreadySelected = selectedSitesArr.some(selectedSite => selectedSite.name === siteName);

                if (!isSiteAlreadySelected) {
                    selectedSitesArr.push({
                        name:siteName,
                        id: siteId,
                    })
                }
            }

            setSelectedSites([...selectedSitesArr])

        } else if(targetType == "groups"){
            setTargetType({label: "Managed Group"})

            let actions = geoConfig?.actions || []
            actions = actions.filter(action => action.configuration_type == "custom")
            let selectedGroupsArr : any[] = []
            const devices:DevicesAction[] = actions.map(action => action.devices).filter((devices): devices is DevicesAction[] => devices !== undefined).flat();
            for (const device of devices) {
                const { group_id: groupId, group_name: groupName } = device;

                const isGroupAlreadySelected = selectedGroupsArr.some(selectedGroup => selectedGroup.name === groupName);

                if (!isGroupAlreadySelected) {
                    selectedGroupsArr.push({
                        name:groupName,
                        id: groupId,
                    })
                }
            }
            setSelectedManagedGroups([...selectedGroupsArr])
        }
    }, [geoConfig?.target_type])

    useEffect(()=>{
        let entryThreshold: number = geoConfig?.entry_threshold || 5
        setEntryThreshold(entryThreshold)
    }, [geoConfig?.entry_threshold])

    useEffect(()=>{
        let exitThreshold: number = geoConfig?.exit_threshold || 5
        setExitThreshold(exitThreshold)
    }, [geoConfig?.exit_threshold])

    useEffect(()=>{
        if(heading.includes("Edit")){
            setEdit(true)
        } else {
            setEdit(false)
        }
    }, [heading])

    useEffect(()=>{
        if (!_.isEmpty(authReducer.getVesselsListing)) {

            let dpIds = getParentAndSubDpIds();
            let _dpIds = dpIds.map(x => "'" + x + "'").toString();
            const payloads = {
                "query": "GET_INVENTORY_GROUPS",
                "named": true,
                "format": "json",
                "parameters": {
                    "dp": _dpIds,
                    "search": "",
                    "page_start": 0,
                    "page_size": 100,
                    "sort_by": "update_time",
                    "sort_order": "desc"
                }
            };

            getGroupList(payloads);

            let ouName = authReducer.selectedOu?.name;
            getDeviceList(ouName);
        }
    }, [authReducer.getVesselsListing])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getInventoryGroupList)) {
            const data = authReducer.getInventoryGroupList?.data;
            setGroupList(data?.map((ele) => {
                return {
                    name: ele.name,
                    id: ele.id
                };
            }))
        }

    }, [authReducer.getInventoryGroupList]);
    
    useEffect(() => {
        if (!_.isEmpty(authReducer.getDeviceList)) {
            const data = authReducer.getDeviceList?.data;

            const siteListOptions: { name: string, id: number }[] = [];
            const deviceListOptions: { name: string, id: number }[] = [];
            data.forEach((ele) => {
                siteListOptions.push({
                    name: ele.location_name,
                    id: ele.location_id
                });
                ele?.devices.forEach((eleDevice) => {
                    deviceListOptions.push({
                        name: eleDevice.name,
                        id: eleDevice.id
                    });
                });
            })
            setSiteList(siteListOptions);
            setDeviceList(deviceListOptions);
        }

    }, [authReducer.getDeviceList]);

    useEffect(()=>{
        if(!_.isEmpty(selectedDevices)){

            let deviceIdList: SelectedDeviceType[] = []

            const data = authReducer.getDeviceList?.data;

            selectedDevices.forEach(selectedDevice => {
                data.forEach(item => {
                    const devices = item?.devices.map(device => device.id);
            
                    if (devices.includes(selectedDevice.id)) {
                        const deviceObj = {
                            device_id: selectedDevice.id,
                            site_id: item?.location_id,
                        };
            
                        deviceIdList.push(deviceObj);
                    }
                });
            });

            setSelectedDeviceIdsList(deviceIdList)
        }
    }, [selectedDevices])

    useEffect(()=>{
        if(!_.isEmpty(selectedManagedGroups)){
            let deviceIdList: SelectedDeviceType[] = []
            let deviceList = authReducer.getDeviceList?.data;
            const inventoryGroups = authReducer.getInventoryGroupList?.data;

            inventoryGroups.forEach(group => {
                const groupId = group?.id;
                const devices = group?.devices.split('+').map(d => d.split("|")[0]); 
                
                devices.forEach(deviceId => {
                    
                    const matchingDevice = deviceList.find(device => 
                        device.devices.some(d => d.id === deviceId)
                    );
                    
                    if (matchingDevice) {
                        const selectedDeviceObj: SelectedDeviceType = {
                            device_id: deviceId,
                            group_id: groupId,
                            site_id: matchingDevice.location_id
                        };
                        deviceIdList.push(selectedDeviceObj);
                    }
                });
            });

            setSelectedDeviceIdsList(deviceIdList)
        }
    }, [selectedManagedGroups])

    useEffect(()=>{
        if(!_.isEmpty(selectedSites)){
            let deviceIdList: SelectedDeviceType[] = []

            const deviceList = authReducer.getDeviceList?.data;

            deviceList.forEach(device => {
                selectedSites
                    .filter(site => device.location_id === site?.id)
                    .forEach(() => {
                        const devices = device?.devices.map(d => ({
                            device_id: d.id,
                            site_id: device.location_id,
                        }));
            
                        deviceIdList.push(...devices);
                    });
            });
            
            setSelectedDeviceIdsList(deviceIdList)
        }
    }, [selectedSites])


    useEffect(()=>{
        if(!_.isEmpty(selectedDeviceIdsList)){
            let payload = { 
                query: "GET_TRAFFIC_POLICIES_MULTIDEVICE", 
                "named": true,
                "format": "json", 
                "parameters": { 
                    "device_id": selectedDeviceIdsList.map(x => "'" + x.device_id + "'").toString()
                } 
            }
            getTrafficPolicyDataFromDeviceList(payload)

            payload = { 
                query: "GET_WAN_PROFILES_MULTIDEVICE", 
                "named": true,
                "format": "json", 
                "parameters": { 
                    "device_id": selectedDeviceIdsList.map(x => "'" + x.device_id + "'").toString()
                } 
            }
            getWanProfileDataFromDeviceList(payload)

            payload = { 
                query: "GET_ACCESS_NETWORKS_MULTIDEVICE", 
                "named": true,
                "format": "json", 
                "parameters": { 
                    "device_id": selectedDeviceIdsList.map(x => "'" + x.device_id + "'").toString()
                } 
            }
            getAccessNetworkDataFromDeviceList(payload)
        }
    }, [selectedDeviceIdsList])

    const getParentAndSubDpIds = () => {
        const _dpIds: any = [];
        if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name) {
            const parentDpId = authReducer?.selectedOu?.id
            const dpIds = authReducer?.parentDpAndSubDpList
            if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name && !_.isEmpty(authReducer.parentDpAndSubDpList)) {
            const parentDpId = authReducer?.selectedOu?.id
            const dpIds = authReducer?.parentDpAndSubDpList
            if (dpIds.hasOwnProperty(parentDpId)) {
                _dpIds.push(parentDpId)
                getChildren(_dpIds, dpIds[parentDpId])
            }
        }
            _dpIds.push(parentDpId)
        }
        return _dpIds
    }

    const getChildren = (dpIds, dps) => {
        for (let i = 0; i < dps?.length; i++) {
            let id = dps[i]?.id;
            dpIds.push(id);
            if (dps[i].hasOwnProperty('_childern') && dps[i]['_childern']?.length > 0) {
                getChildren(dpIds, dps[i]['_childern'])
            }
        }
    }

    const handleClose = (value: boolean) => {
        setRuleName("")
        setTargetType({label:""})
        setSelectedSites([])
        setSelectedDevices([])
        setSelectedManagedGroups([])
        setActions([])
        setDefaultActions([])
        onCancel();
    };

    const handleSave = () => {
        let combinedActions: any = []
        combinedActions = [...defaultActions, ...actions]

        let dpId = authReducer.selectedOu.id
        if(!edit){

            let geoConfigPayload: GeoConfig = {
                rule_name: ruleName,
                target_type: (targetType.label == "Site") ? "sites" :  (targetType.label == "Device") ? "devices" : "groups",
                actions: combinedActions,
                notification_methods: notificationMethods,
                users_to_notify: usersToNotify,
                entry_threshold: entryThreshold,
                exit_threshold: exitThreshold,
            }
            
            onSave(dpId, geoConfigPayload, edit);
        } else {
            let geoConfigPayload: GeoConfig = {
                rule_name: ruleName,
                target_type: (targetType.label == "Site") ? "sites" :  (targetType.label == "Device") ? "devices" : "groups",
                actions: combinedActions,
                notification_methods: notificationMethods,
                users_to_notify: usersToNotify,
                id: geoConfig?.id,
                entry_threshold: entryThreshold,
                exit_threshold: exitThreshold,
            }
            
            onSave(dpId, geoConfigPayload, edit);
        }
        handleClose(false)
    };

    const handleChangeNotificationMethods = (event: React.ChangeEvent<HTMLInputElement>) => {
        let _value = [...notificationMethods];
        if (event.target.checked) {
            _value.push(event.target.name);
        } else {
            _value = _value.filter(nm => nm !== event.target.name);
        }
        setNotificationMethods(_value);
    };

    const handleChangeUsersToNotify = (event: SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {
        if (reason === 'selectOption' || reason === 'removeOption') {
            let _value = value as User[];
            setUsersToNotify(_value);
        } else if (reason === 'createOption') {
            let _users: User[] = [];
            let _value = value as (string | User)[];
            _value.forEach((v: string | User) => {
                if (typeof v === 'string') {
                    let _values: string[] = extractEmails(v)
                    _values.forEach(_v => {
                        let _user = users.find(u => u.email === _v);
                        if (_user) {
                            _users.push(_user);
                        } else {
                            _users.push({ name: _v, email: _v });
                        }
                    })
                } else {
                    _users.push(v);
                }
            });
            setUsersToNotify(_users);
        } else if (reason === 'clear') {
            setUsersToNotify([]);
        }

    };

    const handleChangeActions = (actions: Action[]) => {
        setActions(actions)
    }

    const handleChangeDefaultActions = (actions: Action[]) => {
        setDefaultActions(actions)
    }

    const handleTargetTypeChange = (event, value) => {
        setTargetType(value);
        setSelectedSites([]);
        setSelectedDevices([]);
        setSelectedManagedGroups([]);
    }

    useEffect(() => {
        if (open) {
            setNotificationMethods(geoConfig != null && geoConfig.notification_methods ? geoConfig.notification_methods : []);
            setUsersToNotify(geoConfig != null && geoConfig?.users_to_notify ? geoConfig.users_to_notify : []);
        }
    }, [open, geoConfig])

    const onChangeRuleName = (e:any)=>{
        setRuleName(e?.target?.value)
    }

    const handleEntryThresholdValueChange = (e: any)=>{
        setEntryThreshold(parseInt(e?.target?.value))
    }

    const handleExitThresholdValueChange = (e: any)=>{
        setExitThreshold(parseInt(e?.target?.value))
    }

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="lg" fullWidth>
            <div className="geoConfig_management--base--margin_1x geoConfig_management--base--font_1x">
                <div>
                    {heading}
                </div>
                <div className='margin-left-auto'>  <img
                    src={close}
                    alt='close'
                    onClick={() => { handleClose(false) }}
                    className='close-btn-ht-wd'/> 
                </div>
            </div>
            <DialogContent className='menuScroll' style={{padding:"0px"}}>
                <div className="geoConfig_management--base--margin_1x">
                    <FormControl fullWidth error={ ruleName?.length == 0}>
                        <Typography variant="body2" color="text.secondary">
                            Rule Name
                        </Typography>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    className='rule-name-text-field'
                                    style={{ width: "100%", height:"10px"}}
                                    variant="outlined"
                                    placeholder="Name"
                                    value={ruleName}
                                    onChange={onChangeRuleName}
                                    disabled={edit}
                                />
                            </Grid>
                        </Grid>
                        {
                            ruleName?.length == 0 ? <FormHelperText className='rule_name_error_text'> Rule name is required</FormHelperText> : null
                        }
                    </FormControl>
                </div>
                <div className="geoConfig_management--base--margin_1x">
                    <div className="geoConfig_management--base--margin_top_right-bottom_1x" style={{marginTop:"2rem"}}>
                        <Grid container xs={12} sm={12} md={12} lg={12}>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                <Typography variant="body2" color="text.secondary">
                                    Target Type
                                </Typography>
                                <Autocomplete
                                    options={[{ label: "Site" }, { label: "Device" }, { label: "Managed Group" }]}
                                    getOptionLabel={(option) => option.label}
                                    sx={{ width: 300 }}
                                    onChange={handleTargetTypeChange}
                                    value={targetType}
                                    renderInput={(params) => <TextField {...params} placeholder="Select Target Type" variant="outlined" size="small" />}
                                    isOptionEqualToValue = {(option, value) => option.label === value.label}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                {
                                    targetType?.label === "Site" ? (
                                        <>
                                            <Typography variant="body2" color="text.secondary">
                                                Select Sites
                                            </Typography>
                                            <Autocomplete
                                                multiple
                                                id="site-select-box"
                                                options={[{ name: "Select All", id: null }, ...siteList]}
                                                value={selectedSites}
                                                limitTags={3}
                                                style={{ width: 300, margin: '0px' }}
                                                disableCloseOnSelect
                                                inputValue={siteFilterValue}    
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(prop, option, { selected }) => (
                                                        <li {...prop}>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option.name}
                                                        </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} onChange={(e) => setSiteFilterValue(e.target.value)} placeholder="Select Sites" variant="outlined" size="small" />
                                                )}
                                                onChange={(e, value) => {
                                                    if (value.some((option) => option.name === "Select All")) {
                                                        if (selectedSites.length === siteList.length) {
                                                            setSelectedSites([]);
                                                        } else {
                                                            setSelectedSites(siteList);
                                                        }
                                                    } else {
                                                        setSelectedSites(value);
                                                    }
                                                }}
                                                onClose={() => {setSiteFilterValue("")}}
                                                isOptionEqualToValue = {(option, value) => option.name === value.name}
                                            />
                                        </>
                                    ) 
                                    : null
                                }
                                    
                                {
                                    targetType?.label === "Device" ? (
                                        <>
                                            <Typography variant="body2" color="text.secondary">
                                                Select Devices
                                            </Typography>
                                            <Autocomplete
                                                multiple
                                                id="device-select-box"
                                                options={[{name: "Select All", id: null},...deviceList]}
                                                value={selectedDevices}
                                                limitTags={3}
                                                style={{ width: 300, margin: '0px' }}
                                                disableCloseOnSelect
                                                inputValue={deviceFilterValue}
                                                getOptionLabel={(option) => option.name}                              
                                                renderOption={(prop, option, { selected }) => (
                                                        <li {...prop} key={option.id}>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option.name}
                                                            </li>
                                                    )}
                                                renderInput={(params) => (
                                                    <TextField {...params}  onChange={(e) => setDeviceFilterValue(e.target.value)} placeholder="Select Devices" variant="outlined" size="small" />
                                                )}
                                                onChange={(e, value) => {
                                                    if (value.some((option) => option.name === "Select All")) {
                                                        if (selectedDevices.length === deviceList.length) {
                                                            setSelectedDevices([]); // Deselect all
                                                        } else {
                                                            setSelectedDevices(deviceList); // Select all
                                                        }
                                                    } else {
                                                        setSelectedDevices(value);
                                                    }
                                                }}
                                                onClose={()=>{setDeviceFilterValue("")}}
                                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                            />
                                        </>
                                    ) 
                                    : null
                                }
                    
                                {
                                    targetType?.label === "Managed Group" ? (
                                        <>
                                                <Typography variant="body2" color="text.secondary">
                                                        Select Managed Groups
                                                </Typography>
                                                <Autocomplete
                                                    multiple
                                                    id="managedgroup-select-box"
                                                    options={[{ name: "Select All", id: null }, ...groupList]}
                                                    value={selectedManagedGroups}
                                                    limitTags={3}
                                                    style={{ width: 300, margin: '0px' }}
                                                    disableCloseOnSelect
                                                    inputValue={managedGroupFilterValue}
                                                    getOptionLabel={(option) => option.name}
                                                    renderOption={(prop, option, { selected }) => (
                                                        <li {...prop}>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option.name}
                                                            </li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField {...params} onChange={(e) => setManagedGroupFilterValue(e.target.value)} placeholder="Select Group" size="small" />
                                                    )}
                                                    onChange={(e, value) => {
                                                        if (value.some((option) => option.name === "Select All")) {
                                                            if (selectedManagedGroups.length === groupList.length) {
                                                                setSelectedManagedGroups([]);
                                                            } else {
                                                                setSelectedManagedGroups(groupList);
                                                            }
                                                        } else {
                                                            setSelectedManagedGroups(value);
                                                        }
                                                    }}
                                                    onClose={() => setManagedGroupFilterValue("")}
                                                />
                                        </>
                                    ) 
                                    : null
                                }
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="geoConfig_management--base--margin_1x">
                    <Typography className="geoConfig_management--base--pb_1x" variant="body2" color="text.secondary">
                        Geofence Configuration
                    </Typography>
                    <Grid container>
                        <Grid item xs={12} md={4} style={{paddingRight:"10px", paddingTop: "10px"}}>
                            <FormControl fullWidth>
                                <TextField
                                    type="number" 
                                    size="small"
                                    label="Entry Threshold (In Minutes)"
                                    variant="outlined"
                                    value={entryThreshold}
                                    onChange={handleEntryThresholdValueChange}
                                    placeholder="e.g: 0"
                                    InputProps={{ inputProps: { min: 0, step:5}}}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} style={{paddingTop: "10px"}}>
                            <FormControl fullWidth>
                                <TextField
                                    type="number" 
                                    size="small"
                                    label="Exit Threshold (In Minutes)"
                                    variant="outlined"
                                    value={exitThreshold}
                                    onChange={handleExitThresholdValueChange}
                                    placeholder="e.g: 0"
                                    InputProps={{ inputProps: { min: 0, step:5}}}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div className="geoConfig_management--base--margin_1x">
                    <Typography className='geoConfig_management--base--pb_1x' variant="body2" color="text.secondary">
                        Rule Action(s)
                    </Typography>
                    <AlertingConditions value={actions} onChange={handleChangeActions} isDefaultAction={false} selectedDevices={selectedDeviceIdsList}/>
                </div>
                <div className="geoConfig_management--base--margin_1x">
                    <Typography className='geoConfig_management--base--pb_1x' variant="body2" color="text.secondary">
                        Default Action (On Exit)
                    </Typography>
                    <AlertingConditions value={defaultActions} onChange={handleChangeDefaultActions} isDefaultAction={true} selectedDevices={selectedDeviceIdsList}/>
                </div>
                <div className="geoConfig_management--base--margin_1x">
                    <Typography className='geoConfig_management--base--pb_1x' variant="body2" color="text.secondary">
                        Notification
                    </Typography>
                    <Grid spacing={2}>
                        <Grid container xs={12}>
                            <Grid item xs={12} md={2} >
                                <Checkbox size='small' name={'email'} checked={notificationMethods.includes("email")} onChange={handleChangeNotificationMethods} />
                                <Typography variant="body2" color="text.secondary" display={'inline'}>
                                    Email
                                </Typography>
                            </Grid>
                            {
                                notificationMethods.includes("email") && <Grid item xs={12} md={10}>
                                    <FormControl fullWidth error={usersToNotify.length === 0}>
                                        <Autocomplete
                                            id="select-users"
                                            size='small'
                                            multiple={true}
                                            className={notificationMethods.includes("email") ? "" : "disbale-user-selection"}
                                            freeSolo={true}
                                            value={usersToNotify}
                                            onChange={handleChangeUsersToNotify}
                                            options={users}
                                            getOptionLabel={(option) => {
                                                let _option = option as User;
                                                return _option.name !== "" ? _option.name : _option.email
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Send to (via Email)" variant='outlined' error={usersToNotify.length === 0} />}
                                            renderOption={(props, option: any) => <li {...props} key={nanoid()}>{option.name !== "" ? option.name : option.email}</li>}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option: any, index) => (
                                                    <Chip size='small' label={option.name} {...getTagProps({ index })} />
                                                ))
                                            }
                                            placeholder='Send to (via Email)'
                                        />
                                        {
                                            usersToNotify.length === 0 ? <FormHelperText>Please select at least one user/or email</FormHelperText> : null
                                        }
                                    </FormControl>
                                </Grid>
                            }
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item xs={12} md={2} >
                                <Checkbox size='small' name={'microsoft_teams'} checked={notificationMethods.includes("microsoft_teams")} onChange={handleChangeNotificationMethods} />
                                <Typography variant="body2" color="text.secondary" display={'inline'}>
                                    Microsoft Teams
                                </Typography>
                                <IconButton size='small' onClick={() => window.open(teamsWebhookHelpDoc)}>
                                    <Tooltip title="Help"><HelpOutlineIcon /></Tooltip>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <div className='geoConfig_management--base--flex geoConfig_management--base--margin_1x geoConfig_management--base--flex--justify--end'>
                <Button variant="outlined" className='geoConfig_management--button--cancel' onClick={() => { handleClose(false) }}>Cancel</Button>
                <Button variant="contained" className='geoConfig_management--button--save' disabled={!isValid} onClick={handleSave}>Save</Button>
            </div>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
});

export default connect(mapStateToProps, { getGroupList, getDeviceList, getTrafficPolicyDataFromDeviceList, getWanProfileDataFromDeviceList, getAccessNetworkDataFromDeviceList})(GeoConfigDialog);