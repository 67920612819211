import moment from "moment-timezone";
export const FONT_FAMILY = "Roboto";
export const SNACK_BAR_TIMEOUT = 4000;

export const LOGGED_IN_EMAIL = "loggedInEmail";
export const LOGGED_IN_USER = 'loggedInUser';
export const LOGGED_IN_UPDATE_EMAIL = 'loggedInUpdateEmail';
export const REFRESH_TOKEN = 'Refresh Token';
export const PERMISSIONS = 'permissions';
export const USER_OU = 'user_ou';
export const CONST_ALL_VESSELS = 'All Sites';
export const CONST_ALL_WAN_TYPES = "Wan Types";
export const CONST_ALL_PERIODS = "Intervals";
export const CONST_SELECT_VESSEL = 'Select Site';
export const SESSION_TOKEN = "SESSION_TOKEN";
export const CAPTIVE_ACCESS_ENABLED = "captiveAccessEnabled";
export const MAINTENANCE = "MAINTENANCE";
export const USER_PREFERENCES = "UserPreferences";
export const DEFAULT_PAGE_SIZE = 15;
export const PER_PAGE_ITEMS = [
  { label: "10" },
  { label: "15" },
  { label: "20" },
  { label: "25" },
  { label: "30" },
  { label: "50" },
  { label: "75" },
  { label: "100" },
  // { label: "125" },
  // { label: "150" },
  // { label: "175" },
  // { label: "200" },
  // { label: "225" },
  // { label: "250" },
  // { label: "275" },
  // { label: "300" }
];

export const NEW_PER_PAGE_ITEMS = [
  { label: "10", value: 10 },
  { label: "15", value: 15 },
  // { label: "20", value: 20 },
  { label: "25", value: 25 },
  // { label: "30", value: 30 },
  { label: "50", value: 50 },
  // { label: "75", value: 75 },
  { label: "100", value: 100 }
];

export const INTERVALS = [
  { label: "15m", value: "15mins" },
  { label: "1h", value: "1h" },
  { label: "2h", value: "2h" },
  { label: "6h", value: "6h" },
  { label: "12h", value: "12h" },
  { label: "1d", value: "1d" },
  { label: "7d", value: "7d" },
  { label: "30d", value: "30d" },
];

export const getLastThreeMonths = () => {
  const now = moment();
  const previousMonths: any = [];

  for (let i = 1; i <= 3; i++) {
    const currentMonth = now.clone().subtract(i, 'months');

    previousMonths.push({ label: currentMonth.format('MMMM'),  
      value: currentMonth.format('YYYY-MM'), 
      startDate: currentMonth.startOf('month').format('YYYY-MM-DD 00:00:00'), 
      endDate: currentMonth.endOf('month').format('YYYY-MM-DD 23:59:59'), 
       duration: "30", timeVal: "days"
   
    });
  }

  return previousMonths;
};

export const getLastThreeMonth = () => {
  const months = moment.months(); 
  const now = moment();
  const previousMonths:any = [];

  for (let i = 1; i <= 3; i++) {
    const month = now.clone().subtract(i, 'months');
    previousMonths.push( month.format('YYYY-MM') );
  }

  return previousMonths;
};


export const NEWINTERVALS:any = [
  { label: "Last 15 min", value: "15mins", duration: "15", timeVal: "minutes" },
  { label: "Last 1 Hr", value: "1h", duration: "60", timeVal: "minutes" },
  { label: "Last 6 Hr", value: "6h", duration: "360", timeVal: "minutes" },
  { label: "Last 12 Hr", value: "12h", duration: "720", timeVal: "minutes" },
  { label: "Last 1 Day", value: "1d", duration: "1", timeVal: "days" },
  { label: "Last 7 Days", value: "7d", duration: "7", timeVal: "days" },
  { label: "Last 30 Days", value: "30d", duration: "30", timeVal: "days" },
  { label: "MTD", value: "MTD" },
];

export const NEWINTERVALS_WITH_PLANS:any = [
  { label: "Last 15 min", value: "15mins", duration: "15", timeVal: "minutes" },
  { label: "Last 1 Hr", value: "1h", duration: "60", timeVal: "minutes" },
  { label: "Last 6 Hr", value: "6h", duration: "360", timeVal: "minutes" },
  { label: "Last 12 Hr", value: "12h", duration: "720", timeVal: "minutes" },
  { label: "Last 1 Day", value: "1d", duration: "1", timeVal: "days" },
  { label: "Last 7 Days", value: "7d", duration: "7", timeVal: "days" },
  { label: "Last 30 Days", value: "30d", duration: "30", timeVal: "days" },
  { label: "MTD", value: "MTD" },
  { label: "Plans", value: "plans" },
];


export const INTERVAL_LAST_THREE_MONTHS:any = [
  { label: "Last 15 min", value: "15mins", duration: "15", timeVal: "minutes" },
  { label: "Last 1 Hr", value: "1h", duration: "60", timeVal: "minutes" },
  { label: "Last 6 Hr", value: "6h", duration: "360", timeVal: "minutes" },
  { label: "Last 12 Hr", value: "12h", duration: "720", timeVal: "minutes" },
  { label: "Last 1 Day", value: "1d", duration: "1", timeVal: "days" },
  { label: "Last 7 Days", value: "7d", duration: "7", timeVal: "days" },
  { label: "Last 30 Days", value: "30d", duration: "30", timeVal: "days" },
  { label: "MTD", value: "MTD" },
  ...getLastThreeMonths(), 
];


export const WAN_ARRAY = [
  {label:"All",value:""},
  {label:"CELLULAR",value:"lte"},
  {label:"VSAT",value:"vsat"},
  {label:"STARLINK",value:"starlink"},
  {label:"LBAND",value:"lband"},
  {label:"ETHERNET",value:"wired"},
  {label:"WIFI",value:"wifi"},
  {label:'BOND',value:'bond'}
];

export const THREAT_SEVERITY_ARRAY = [
  {label:"All",value:""},
  {label:"CRITICAL",value:"critical"},
  {label:"HIGH",value:"high"},
  {label:"MEDIUM",value:"medium"},
  {label:"LOW",value:"low"},
];

export const BC_DASHBOARD_VESSELS = [
  { title: 'K4Mobility'},
  { title: 'Dashboard'},
  { title: 'Sites' }
];

export const BC_DASHBOARD_VESSEL_SUMMARY = [
  { title: 'K4Mobility' },
  { title: 'Dashboard', link: '/fleet-dashboard/sites' },
  { title: 'Sites', link: '/fleet-dashboard/sites' },
  { title: '' },
  { title: 'Topology' },
];
export const BC_DASHBOARD_ALERTS = [
  { title: 'K4Mobility', link: '/fleet-dashboard/sites' },
  { title: 'Alerts' }
];
export const BC_CREATE_ALERT = [
  { title: 'K4Mobility' },
  { title: 'Dashboard', link: '/fleet-dashboard/sites' },
  { title: 'Sites', link: '/fleet-dashboard/sites' },
  { title: '' },
  { title: 'Alerts' },
  { title: 'Create Alert' },
];

export const BC_DASHBOARD_LOCATIONS = [
  { title: 'K4Mobility', link: '/fleet-dashboard/vessels' },
  { title: 'Dashboard', link: '/fleet-dashboard/vessels' },
  { title: 'Locations' }
];
export const BC_ACCOUNT_MANAGEMENT = [
  { title: 'K4Mobility' },
  { title: 'Accounts' }
];
export const BC_ACCOUNT_MANAGEMENT_RESOURCES = [
  { title: 'K4Mobility' },
  { title: "Resources" }
];

export const BC_TICKETS = [
  { title: 'K4Mobility' },
  { title: 'Tickets'}
];

export const BC_TICKETS_VIEW = [
  { title: 'K4Mobility' },
  { title: 'Tickets', goBack: true },
];

export const BC_MAPS = [
  { title: 'K4Mobility' },
  { title: 'Map' }
];

export const BC_SUBSCRIPTION_MANAGEMENT = [
  { title: 'K4Mobility' },
  { title: 'Subscriptions' }
];

export const BC_SIM_REPORTS = [
  { title: 'K4Mobility' },
  { title: 'REPORTS' }
];

export const BC_SOFTWARE_LISTS = [
  { title: 'K4Mobility'},
  { title: 'Software'}
];
export const BC_SOFTWARE_BUILDS = [
  { title: 'K4Mobility'},
  { title: 'Software', link: "/softwareList"},
  { title: 'Edge Builds'}
];
export const BC_SOFTWARE_VERSIONS = [
  { title: 'K4Mobility' },
  { title: 'Software'},
  { title: 'DEPLOYED EOS'}
];
export const BC_SOFTWARE_KONNECT_APP = [
  { title: 'K4Mobility' },
  { title: 'Software'},
  { title: 'Konnect App'}
];
export const BC_SOFTWARE_VERSION_DEVICES = [
  { title: 'K4Mobility' },
  { title: 'Software', goBack: true},
  { title: 'Loading..'},
  { title: 'Devices'},
];
export const BC_EDGE_ADMIN_FIRMWARE = [
  { title: 'K4Mobility'},
  { title: 'Dashboard', link: '/fleet-dashboard/sites' },
  { title: 'Sites', link: '/fleet-dashboard/sites' },
  { title: ''},
  { title: 'Topology', goBack: true },
  { title: 'Portal' },
  
];

export const BC_EDGE_ADMIN_EDGE_PORTAL = [
  { title: 'K4Mobility' },
  { title: "Portal" }
];

export const BC_CHANGE_PASSWORD = [
  { title: 'K4Mobility' },
  { title: 'Setting' },
  { title: 'Change Password' }
];

export const UPDATE_USER_EMAIL = [
  { title: 'K4Mobility' },
  { title: 'Setting' },
  { title: 'Account', link: '/account-management/accounts'},
  { title: 'Profile' }
];

export const SEVERITY_LIST = ["minor", "major", "critical"];
export const STATUS_LIST = ["Alerting", "Normal", "Pending"];
export const NAMES_LIST = ["LTE", "VSAT", "Internet Presence"]

// IAM user policy constants
export const IAM_USER_POLICY_ALL_RESOURCES_KEY = "all_resources";
export const IAM_USER_POLICY_ID_KEY = "id"
export const IAM_USER_POLICY_TYPE_KEY = "type";
export const IAM_USER_POLICY_VESSEL_NAME_KEY = "vessel-name";
export const IAM_USER_POLICY_VESSEL = "Vessel";
export const IAM_USER_POLICY_ALL_RESOURCES_VALUE = "*";

// service management page: DEACTIVATION Date
export const SRVC_MGMT_DEACTIVATION_YEAR = 2040;
export const SRVC_MGMT_ALTERNATIVE_TEXT_FOR_DEACTIVATION_YEAR = "Ongoing";


export const PRODUCT_TYPES = [
  { id: 'EO', name: 'EdgeOne' },
  { id: 'OB', name: 'OneBox' },
  { id: 'OD', name: 'OneDome' },
  { id: 'ES', name: 'EdgeServer' },
  { id: 'ES v2', name: 'EdgeServer v2' },
  { id: 'EB', name: 'EdgeBox' },
  { id: 'ZB', name: 'ZeroBox' }
]

export const LOGIN_HISTORY_INTERVALS = [
  { label: "1d", value: "1d" },
  { label: "7d", value: "7d" },
  { label: "15d", value: "15d" },
  { label: "30d", value: "30d" },
];

export const BC_REPORTS = [
  { title: 'K4Mobility', link: '/fleet-dashboard/vessels'},
  { title: 'Reports' },
];

export const BC_INVENTORY_LISTS = [
  { title: 'K4Mobility'},
  { title: 'Inventory'}
];
export const BC_INVENTORY_DEPLOYED = [
  { title: 'K4Mobility'},
  { title: 'Inventory', link: "/inventory"},
  { title: 'Deployed'}
];
export const BC_INVENTORY_WAREHOUSE = [
  { title: 'K4Mobility' },
  { title: 'Inventory'},
  { title: 'Warehouse'}
]
export const BC_INVENTORY_CONFIG = [
  { title: 'K4Mobility'},
  { title: 'Inventory', link: "/inventory"},
  { title: 'Config'}
];

export const BC_DAILY_USAGE = [
  { title: 'K4Mobility', link: '/fleet-dashboard/vessels'},
  { title: 'Reports',  link:'/reports/cellular' },
  { title: 'Data Usage', link:'/reports/dataUsage' },
  { title: 'Daily Data Usage',  },
];

export const BC_AUDIT_LOGS = [
  { title: 'K4Mobility'},
  { title: 'Audit Logs', link: "/auditLogs"}
];
export const BC_KNOWLEDGE_CENTER = [
  { title: 'K4Mobility'},
  { title: 'Knowledge Center', link: "/knowledge-center"},
  { title: ''}
];
export const CHARTS_METRICS = {
  PRE: "Peak Rate Estimate",
  RTT: "Latency & Jitter",
  SINR: "SINR",
  RSSI: "RSSI",
  VSAT_SNR: "VSAT SNR",
  VSAT_TXPOWER: "VSAT TX Power",
  VSAT_POWER: "VSAT Power",
  VSAT_SYMBOL_RATE: "VSAT Symbol Rate",
  LINK_STATUS: "Link Uptime",
  THROUGHPUT: "Throughput",
  CLIENTS: 'Clients',
  USAGE: 'Usage',
  SYSTEM_UPTIME: 'Site Availability',
  LAN_STATUS: 'LAN Status',
  FLOWS: 'MPK Flows',
  SPEED: 'MPK Speed',
  LATENCY: 'MPK Latency',
  STATUS: 'MPK Status',
  SPEEDTEST: 'Speedtest',
  LINK_PROBE_NATIVE: 'Native Probe Pass',
  LINK_PROBE_VTP: 'MPK Probe Pass',
  QOE_STREAMING: 'QOE Streaming',
  QOE_RTC: 'QOE RTC'
}
export const METRIC_BY_WAN_TYPE = {
  "lband": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE, CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.SPEEDTEST],
  "lte": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE,  CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.SINR, CHARTS_METRICS.RSSI, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.SPEEDTEST],
  "starlink": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE,  CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.SPEEDTEST],
  "vsat": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE,  CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.VSAT_SNR, CHARTS_METRICS.VSAT_TXPOWER, CHARTS_METRICS.VSAT_POWER, CHARTS_METRICS.VSAT_SYMBOL_RATE, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.SPEEDTEST],
  "wifi": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE,  CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.SPEEDTEST],
  "wired": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE,  CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.SPEEDTEST]
}

export const EVENT_FILTERS = {
  SOURCES: {
    "edge": "Edge",
    "cloud": "Cloud",
  },
  _SOURCES: {
    "Edge": "edge",
    "Cloud": "cloud",
  },
  MODULES: {
    "SUB_SYSTEM_SYSTEM": "System",
    "SUB_SYSTEM_WAN_MANAGEMENT": "WAN Management",
    "SUB_SYSTEM_CONFIG": "Config",
    "EdgeSync": "Edge Sync",
    "HA": "High Availability",
    "Config": "Config Management"
  },
  _MODULES: {
    "System": "SUB_SYSTEM_SYSTEM",
    "WAN Management": "SUB_SYSTEM_WAN_MANAGEMENT",
    "Config": "SUB_SYSTEM_CONFIG",
    "Edge Sync": "EdgeSync",
    "High Availability": "HA",
    "Config Management": "Config"
  },
  LEVELS: {
    "SEVERITY_INFO": "Severity Info",
    "SEVERITY_CRITICAL": "Critical",
    "LEVEL_INFO": "Level Info",
    "SEVERITY_WARNING": "Warning",
    "INFO": "Info",
    "ERROR": "Error"
  },
  _LEVELS: {
    "Severity Info": "SEVERITY_INFO",
    "Critical": "SEVERITY_CRITICAL",
    "Level Info": "LEVEL_INFO",
    "Warning": "SEVERITY_WARNING",
    "Info": "INFO",
    "Error": "Error"
  },
  LEVEL_COLOR: {
    "SEVERITY_INFO": "blue",
    "SEVERITY_CRITICAL": "red",
    "LEVEL_INFO": "blue",
    "INFO": "blue",
    "ERROR": "red",
    "SEVERITY_WARNING": "#FFC260"
  }
}