import React, { useEffect, useState } from 'react';
import { Tooltip, IconButton, Dialog, DialogContent, DialogTitle, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getSnortRuleDetails } from '../../../actions/Users/authenticateThreatDashboard';
import close from "../../../asset/image/close.svg";

interface JsonDataTooltipProps {
    className?: string;
    data: any;
    [key: string]: any;
}

function isValidJson(str: string): boolean {
    try {
        JSON.parse(str);
        return true;
    } catch {
        return false;
    }
}

function getRuleUrl(ruleValue: string): string {
    const parts = ruleValue.split(':');
    if (parts.length >= 3) {
        const firstId = parts[0];
        let secondId = parts[1];
        return `${firstId}:${secondId}`;
    }
    return '';
}

function renderJsonWithClickables(data: Record<string, any>, handleRuleClick: (ruleValue: string) => void): React.ReactNode {
    const keys = Object.keys(data);
    return (
        <>
            {'{'}
            <br />
            {keys.map((key, index) => {
                const isLast = index === keys.length - 1;
                if (key === 'rule') {
                    return (
                        <div key={key} style={{ marginLeft: '1em' }}>
                            "{key}":{' '}
                            <span
                                style={{ color: 'blue', cursor: 'pointer' }}
                                onClick={() => handleRuleClick(data[key])}
                            >
                                "{data[key]}"
                            </span>
                            {isLast ? '' : ','}
                        </div>
                    );
                }
                return (
                    <div key={key} style={{ marginLeft: '1em' }}>
                        "{key}": {JSON.stringify(data[key])}
                        {isLast ? '' : ','}
                    </div>
                );
            })}
            {'}'}
        </>
    );
}

const CustomisedJsonDataTooltip = ({ className, data, ...props }: JsonDataTooltipProps) => {
    const { getSnortRules, setSnortRulesDetailsLoading, getSnortRuleDetails } = props;
    const [rulePopup, setRulePopup] = useState(false);
    const [tableData, setTableData] = useState<any>([]);
    const [ruleId, setRuleId] = useState('');

    const handleRuleClick = (ruleValue: string) => {
        setRuleId(ruleValue);
        setRulePopup(true);
        getSnortRuleDetails(getRuleUrl(ruleValue));
    };

    useEffect(() => {
        if (!_.isEmpty(getSnortRules)) {
            const data = getSnortRules.hasOwnProperty('data') ? getSnortRules.data : [];
            setTableData(data)
        }
    }, [getSnortRules])

    const handleClose = () => {
        setRulePopup(false);
        setRuleId('');
        setTableData([]);
    };

    const renderData = () => {
        if (_.isEmpty(data)) return '';
        if (typeof data === 'object' && !Array.isArray(data)) {
            return (
                <pre className="scrollTooltip">
                    {renderJsonWithClickables(data, handleRuleClick)}
                </pre>
            );
        }
        if (typeof data === 'string') {
            if (isValidJson(data)) {
                const parsed = JSON.parse(data);
                return (
                    <pre className="scrollTooltip">
                        {renderJsonWithClickables(parsed, handleRuleClick)}
                    </pre>
                );
            }
            return <pre className="scrollTooltip">{data}</pre>;
        }
        return '';
    };

    return (
        <>
            <Tooltip
                classes={{ tooltip: 'jsonTooltip' }}
                title={renderData()}
                {...props}
                placement="left-start"
                arrow
            >
                <IconButton>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={!!rulePopup} onClose={handleClose} maxWidth="md" fullWidth className='rule-detaills-table'>
                <DialogTitle className='create-default-config-title'>
                    <span>{`Rule Documentation (${ruleId})`}</span>
                    <img src={close} title="Close" onClick={handleClose} />
                </DialogTitle>
                <DialogContent>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">Rule Category</TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">Alert Message</TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">Rule Explanation</TableCell>
                                </TableRow>
                            </TableHead>
                            {0 != tableData.length && !setSnortRulesDetailsLoading ? (
                                <TableBody className="tableBody usage-tableBody">
                                    {
                                        tableData.map((row, i) =>
                                            <TableRow key={i} className="loginHistory-tableRow">
                                                <Tooltip title={<span className="font-size-12px">{row.rule_category}</span>}>
                                                    <TableCell className="login-left-align">{row.rule_category}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={<span className="font-size-12px">{row.alert_message}</span>}>
                                                    <TableCell className="login-left-align">
                                                        {row.alert_message}
                                                    </TableCell>
                                                </Tooltip>
                                                <Tooltip title={<span className="font-size-12px">{row.rule_explanation}</span>}>
                                                    <TableCell className="login-left-align">{row.rule_explanation}</TableCell>
                                                </Tooltip>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={3} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => ({
    getSnortRules: state.authReducer.getSnortRules,
    setSnortRulesDetailsLoading: state.authReducer.setSnortRulesDetailsLoading
});

export default withRouter(
    connect(mapStateToProps, { getSnortRuleDetails })(CustomisedJsonDataTooltip)
)