import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Chip, Stack, Tooltip } from "@mui/material"
import { convertDateTimeIntoTimezone, getDecodeURI } from "../../../utils/util";
import moment from "moment";

const ChipFilters = (props) => {
    const { userTimezone, applyFilterClick, values, setValues, location } = props;
    const { selectedSubscribers, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, selectedUserNames, selectedIps, selectedMacs, selectedEventActions, selectedEventTypes, planAutoRenewal } = values;
    const { setSelectedSubscribers, setSelectedPlans, setSelectedUserNames, setSelectedIps, setSelectedMacs, setSelectedEventActions, setSelectedEventTypes, setSelectedPlanDurations, setSelectedPlanQuota, setSelectedPlanStatus, setSelectedPlanStartFrom, setSelectedPlanStartTo, setSelectedPlanEndFrom, setSelectedPlanEndTo, setPlanAutoRenewal } = setValues;
    const _q = getDecodeURI(location?.search);

    const handleDelete = (key, value) => {
        switch (key) {
            case 'subscriber': {
                const resultArray = _q.subscribers
                    .split(",")
                    .filter(item => item.trim() !== value.trim());
                setSelectedSubscribers(resultArray);
                applyFilterClick(resultArray, selectedPlans)
                break;
            }
            case 'plan': {
                const resultArray = _q.selectedPlans
                    .split(",")
                    .filter(item => item.trim() !== value.trim());
                setSelectedPlans(resultArray);
                applyFilterClick(selectedSubscribers, resultArray, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal)
                break;
            }
            case 'duration': {
                const resultArray = _q.durations
                    .split(",")
                    .filter(item => item.trim() !== value.trim());
                setSelectedPlanDurations(resultArray);
                applyFilterClick([], selectedPlans, resultArray, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal)
                break;
            }
            case 'quota': {
                const resultArray = _q.quotas
                    .split(",")
                    .filter(item => item.trim() !== value.trim());
                setSelectedPlanQuota(resultArray);
                applyFilterClick([], selectedPlans, selectedPlanDurations, resultArray, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal)
                break;
            }
            case 'status': {
                setSelectedPlanStatus('');
                applyFilterClick([], selectedPlans, selectedPlanDurations, selectedPlanQuota, '', selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal)
                break;
            }
            case 'planStart': {
                setSelectedPlanStartFrom(null);
                setSelectedPlanStartTo(null);
                applyFilterClick([], selectedPlans, selectedPlanDurations, selectedPlanQuota, '', null, null, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal)
                break;
            }
            case 'planEnd': {
                setSelectedPlanEndFrom(null);
                setSelectedPlanEndTo(null);
                applyFilterClick([], selectedPlans, selectedPlanDurations, selectedPlanQuota, '', selectedPlanStartFrom, selectedPlanStartTo, null, null, planAutoRenewal)
                break;
            }
            case 'autoRenewal': {
                setPlanAutoRenewal(false);
                applyFilterClick([], selectedPlans, selectedPlanDurations, selectedPlanQuota, '', selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, false)
                break;
            }
            case 'userName': {
                const resultArray = _q.subscribers
                    .split(",")
                    .filter(item => item.trim() !== value.trim());
                setSelectedUserNames(resultArray);
                applyFilterClick([], [], [], [], '', null, null, null, null, false, resultArray, selectedIps, selectedMacs, selectedEventActions, selectedEventTypes)
                break;
            }
            case 'ip': {
                const resultArray = _q.ipAddress
                    .split(",")
                    .filter(item => item.trim() !== value.trim());
                setSelectedIps(resultArray);
                applyFilterClick([], [], [], [], '', null, null, null, null, false, selectedUserNames, resultArray, selectedMacs, selectedEventActions, selectedEventTypes)
                break;
            }
            case 'mac': {
                const resultArray = _q.macAddress
                    .split(",")
                    .filter(item => item.trim() !== value.trim());
                setSelectedMacs(resultArray);
                applyFilterClick([], [], [], [], '', null, null, null, null, false, selectedUserNames, selectedIps, resultArray, selectedEventActions, selectedEventTypes)
                break;
            }
            case 'action': {
                const resultArray = _q.actions
                    .split(",")
                    .filter(item => item.trim() !== value.trim());
                setSelectedEventActions(resultArray);
                applyFilterClick([], [], [], [], '', null, null, null, null, false, selectedUserNames, selectedIps, selectedMacs, resultArray, selectedEventTypes)
                break;
            }
            case 'type': {
                const resultArray = _q.types
                    .split(",")
                    .filter(item => item.trim() !== value.trim());
                setSelectedEventTypes(resultArray);
                applyFilterClick([], [], [], [], '', null, null, null, null, false, selectedUserNames, selectedIps, selectedMacs, selectedEventActions, resultArray)
                break;
            }
        }
    }

    const getConvertDateTimeIntoTimezone = (date) => {
        return convertDateTimeIntoTimezone(moment(parseInt(date)).toISOString(), userTimezone, 'MM/DD/YYYY HH:mm');
    }

    return (
        <Stack direction="row" spacing={1} alignItems='center' className="captive-dashboard-chips-container">
            {(_q.tab == 'sites' || _q.tab == 'subscribers') && _q.hasOwnProperty('subscribers') && _q.subscribers && _q.subscribers.split(',')?.map(sb => (
                <Tooltip title={`Subscriber : ${sb}`}><Chip label={sb} onDelete={() => handleDelete('subscriber', sb)} /></Tooltip>
            ))}
            {(_q.tab == 'subscribers' || _q.tab == 'plans') && _q.hasOwnProperty('selectedPlans') && _q.selectedPlans && _q.selectedPlans.split(',')?.map(plan => (
                <Tooltip title={`Plan : ${plan}`}><Chip label={plan} onDelete={() => handleDelete('plan', plan)} /></Tooltip>
            ))}
            {_q.tab == 'events' && _q.hasOwnProperty('subscribers') && _q.subscribers && _q.subscribers.split(',')?.map(userName => (
                <Tooltip title={`UserName : ${userName}`}><Chip label={userName} onDelete={() => handleDelete('userName', userName)} /></Tooltip>
            ))}
            {_q.hasOwnProperty('ipAddress') && _q.ipAddress && _q.ipAddress.split(',')?.map(ip => (
                <Tooltip title={`IP : ${ip}`}><Chip label={ip} onDelete={() => handleDelete('ip', ip)} /></Tooltip>
            ))}
            {_q.hasOwnProperty('macAddress') && _q.macAddress && _q.macAddress.split(',')?.map(mac => (
                <Tooltip title={`MAC : ${mac}`}><Chip label={mac} onDelete={() => handleDelete('mac', mac)} /></Tooltip>
            ))}
            {_q.hasOwnProperty('actions') && _q.actions && _q.actions.split(',')?.map(action => (
                <Tooltip title={`Action : ${action}`}><Chip label={action} onDelete={() => handleDelete('action', action)} /></Tooltip>
            ))}
            {_q.hasOwnProperty('types') && _q.types && _q.types.split(',')?.map(type => (
                <Tooltip title={`Event Type : ${type}`}><Chip label={type} onDelete={() => handleDelete('type', type)} /></Tooltip>
            ))}
            {_q.hasOwnProperty('durations') && _q.durations && _q.durations.split(',')?.map(duration => (
                <Tooltip title={`Duration : ${duration} Days`}><Chip label={`${duration} Days`} onDelete={() => handleDelete('duration', duration)} /></Tooltip>
            ))}
            {_q.hasOwnProperty('quotas') && _q.quotas && _q.quotas.split(',')?.map(quota => (
                <Tooltip title={`Quota : ${quota} GB`}><Chip label={`${quota} GB`} onDelete={() => handleDelete('quota', quota)} /></Tooltip>
            ))}
            {_q.hasOwnProperty('status') && _q.status && <Tooltip title={`Status : ${_q.status == 'active' ? 'Active' : 'Expired'}`}><Chip label={_q.status == 'active' ? 'Active' : 'Expired'} onDelete={() => handleDelete('status', _q.status)} /></Tooltip>}
            {_q.hasOwnProperty('planStartFrom') && _q.hasOwnProperty('planStartTo') ? <Tooltip title={`Plan Start : ${getConvertDateTimeIntoTimezone(_q.planStartFrom)} - ${getConvertDateTimeIntoTimezone(_q.planStartTo)}`}><Chip label={`${getConvertDateTimeIntoTimezone(_q.planStartFrom)} - ${getConvertDateTimeIntoTimezone(_q.planStartTo)}`} onDelete={() => handleDelete('planStart', '')} /></Tooltip> :
                _q.hasOwnProperty('planStartFrom') && !_q.hasOwnProperty('planStartTo') ? <Tooltip title={`Plan Start : ${getConvertDateTimeIntoTimezone(_q.planStartFrom)} - ${getConvertDateTimeIntoTimezone(_q.endDate)}`}><Chip label={`${getConvertDateTimeIntoTimezone(_q.planStartFrom)} - ${getConvertDateTimeIntoTimezone(_q.endDate)}`} onDelete={() => handleDelete('planStart', '')} /></Tooltip> : null}
            {_q.hasOwnProperty('planEndFrom') && _q.hasOwnProperty('planEndTo') ? <Tooltip title={`Plan End : ${getConvertDateTimeIntoTimezone(_q.planEndFrom)} - ${getConvertDateTimeIntoTimezone(_q.planEndTo)}`}><Chip label={`${getConvertDateTimeIntoTimezone(_q.planEndFrom)} - ${getConvertDateTimeIntoTimezone(_q.planEndTo)}`} onDelete={() => handleDelete('planEnd', '')} /></Tooltip> :
                _q.hasOwnProperty('planEndFrom') && !_q.hasOwnProperty('planEndTo') ? <Tooltip title={`Plan End : ${getConvertDateTimeIntoTimezone(_q.planEndFrom)} - ${getConvertDateTimeIntoTimezone(_q.endDate)}`}><Chip label={`${getConvertDateTimeIntoTimezone(_q.planEndFrom)} - ${getConvertDateTimeIntoTimezone(_q.endDate)}`} onDelete={() => handleDelete('planEnd', '')} /></Tooltip> : null}
            {_q.hasOwnProperty('autoRenewal') && _q.autoRenewal && <Tooltip title={`Auto Renewal`}><Chip label={'Auto Renewal'} onDelete={() => handleDelete('autoRenewal', _q.autoRenewal)} /></Tooltip>}
        </Stack>
    )
}

const mapStateToProps = (state) => ({
    userTimezone: state.authReducer.userTimezone,
});

export default withRouter(
    connect(mapStateToProps, {})(ChipFilters)
);