import { Fragment, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment, { Moment } from "moment-timezone";
import React from 'react';
import QuestionMarkOutlinedIcon from '@mui/icons-material/Help';
import { Alert,Box, Button, Collapse, Grid, IconButton, InputAdornment, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import { setSelectedAccount } from "../../UserScreen/Starlink/slice";
import { Pagination } from "../Pagination";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import './StarlinkDashboard.css';
import { getDecodeURI, getEncodedURI } from '../../utils/util';

import { getServiceLines, getStarlinkCloudWarnings, clearComponentData } from "./slice";
import _ from "lodash";
import SortArrows, { SortOrder } from "../SortArrows";
import { alertMap } from "../../utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import CallSplitIcon from '@mui/icons-material/CallSplit';

import Download from '../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";
import { STARLINK_DATAUSAGE_CATEGORIES, STARLINK_DATAUSAGE_CATEGORIES_DATA } from "../../UserScreen/Starlink/types";
import { nanoid } from "nanoid";
import { YYYYMMDD_HHmmss } from "../../utils/constants";
const mapToCsvRow = (row: any) => {
    const values: any[] = Object.values(row);
    const userTerminals: any[] = row.userTerminals != '' && JSON.parse(row.userTerminals) || [];
    const usageSplit: any[] = row.usageSplit != '' && JSON.parse(row.usageSplit) || [];
    return [values[9], values[5], values[3], `${values[22]}, ${values[23]}`.replaceAll(',', ';'), values[11], usageSplit[0]?.usagePriorityGB, usageSplit[0]?.usageOptInPriorityGB, usageSplit[0]?.usageStandardGB, usageSplit[0]?.usageNonBillableGB,
     userTerminals.map(value => value.kitSerialNumber).join(';'),
       userTerminals.map(value => {
            if (value.activeAlerts) {
                try {
                    const alerts = JSON.parse(value.activeAlerts);
                    return alerts.length;
                } catch (error) {
                    console.error("Error parsing activeAlerts:", error);
                    return 0; 
                }
            } else {
                return 0; 
            }
        }).join(';'),
        values[6].replaceAll(',', '; ')
        ]
}

const CsvHeaders = ['Service Line', 'Organization', 'Sub Organization','Location', 'Usage (GB)', 'usagePriority (GB)', 'usageOptInPriority (GB)', 'usageStandard (GB)', 'usageNonBillable (GB)', 'User Terminal', 'Starlink/Edge Alerts', 'Site'];


interface StarlinkDashboardProps {
    authReducer: any;
    errorReducer: any;
    history: any;
    location: any;
    dpIds: string[];
    warnings: string[];
    serviceLines: any[];
    totalServiceLines: number;
    totalUsageGB: number;
    totalOverageGB: number;
    totalOverage: number;
    totalUserTerminals: number;
    totalOnlineTerminals: number;
    totalActiveAlerts: number;
    totalAlertingTerminals: number;
    getServiceLines: (parameters: any) => void;
    getStarlinkCloudWarnings: (parameters: any) => void;
    downloadAsCSV: (parameters: any) => void;
    newSummaryStartDate: Moment;
    newSummaryEndDate: Moment;
    newSummaryServiceLine: any;
    clearComponentData: any;
    selectedServiceAccount: any;
}

export type DashboardSortBy = 'nickname' | 'accountDpName' | 'dpName' | 'usageGB' | 'kitSerialNumber' | 'activeAlerts' | 'k4SiteName' | 'online';

function StarlinkDashboard(props: StarlinkDashboardProps) {

    const { authReducer, errorReducer, history, location, dpIds, warnings, serviceLines, totalServiceLines, totalUsageGB, totalOverageGB, totalOverage, totalUserTerminals, totalOnlineTerminals, totalActiveAlerts, totalAlertingTerminals, getServiceLines, selectedServiceAccount,  getStarlinkCloudWarnings, newSummaryStartDate, newSummaryEndDate, newSummaryServiceLine, downloadAsCSV, clearComponentData } = props;
    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [searchText, setSearchText] = useState<string>(queryParams.get('search') || '');
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        sortBy: DashboardSortBy;
        sortOrder: SortOrder;
        search: string;
        startDate: moment.Moment;
        endDate: moment.Moment;
    }>({
        page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : 10,
        sortBy: queryParams.get('sortBy') as DashboardSortBy || 'usageGB',
        sortOrder: queryParams.get('sortOrder') as SortOrder || 'desc',
        search: queryParams.get('search') || '',
        startDate: newSummaryStartDate,
        endDate: newSummaryEndDate
    });
    const [showWarning, setShowWarning] = useState<boolean>(warnings?.length > 0);
    const [updatedServiceLines, setUpdatedServiceLines] = useState<any>([]);

    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
    }

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const handleChangeSorting = (sortBy: DashboardSortBy, sortOrder: SortOrder) => {
        setTableParams({
            ...tableParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    useEffect(() => {
        if (tableParams.startDate.format('YYYY-MM-DD') == newSummaryStartDate.format('YYYY-MM-DD') && tableParams.endDate.isSame(newSummaryEndDate)) {
            return;
        }
        setTableParams({
            ...tableParams,
            startDate: newSummaryStartDate,
            endDate: newSummaryEndDate,
            page: 1
        });
    }, [newSummaryEndDate, newSummaryStartDate])


    const getOnlineStatusColor = (online, accountType) => {
        if (accountType === 'individual') {
            return '#efefef'
        }
        if (online === null) {
            return '#474748'
        }
        return online ? '#6cce76' : '#ed524a';
    }

    const titleCase = (s) => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

    const updateQueryParams = () => {
        queryParams.set('page', tableParams.page.toString())
        queryParams.set('size', tableParams.size.toString())
        queryParams.set('sortBy', tableParams.sortBy)
        queryParams.set('sortOrder', tableParams.sortOrder)
        queryParams.set('search', tableParams.search)
        // queryParams.set('startDate', tableParams.startDate.valueOf().toString())
        // queryParams.set('endDate', tableParams.endDate.valueOf().toString())
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    useEffect(() => {
        return () => {
        }
    }, [])

    useEffect(() => {
        updateQueryParams();
        let _parameters = {
            accounts: selectedServiceAccount,
            dpIds,
            startDate: tableParams.startDate.format('YYYY-MM-DD HH:mm:ss'),
            endDate: tableParams.endDate.format('YYYY-MM-DD HH:mm:ss'),
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page,
            limit: tableParams.size,
            source: getSource(),
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl != '') : [],
            usageSource:getUsageSource(),
        }
        clearComponentData()
        getServiceLines(_parameters); 
    }, [ tableParams, newSummaryServiceLine]);
     
    
      

    const getUsageSource = () =>{
        const endDate = moment(tableParams.endDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(tableParams.startDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = moment.duration(endDate.diff(startDate)).asHours();
        if ( hoursDifference <= 24) {
                    return 'aggregation_table';
                } else if (hoursDifference > 24 ) {
                    return 'direct_source';
                } else {
                    return ''
                }
    }

    const getSource = () =>{
        const endDate = moment(tableParams.endDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(tableParams.startDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = moment.duration(endDate.diff(startDate)).asHours();

        if (hoursDifference < 6) {
            return '_agg_1h';
        } else if (hoursDifference >= 6 && hoursDifference < 24) {
            return '_agg_6h';
        } else if (hoursDifference >= 24 && hoursDifference < 168) {
            return '_agg_1day';
        }else if (hoursDifference >= 168 ) {
            return '_agg_1week';
        } else {
            return ''
        }
    }

    useEffect(() => {
        getStarlinkCloudWarnings({ dpIds });
    }, [dpIds]);

    useEffect(() => {
        if (warnings?.length > 0) {
            setShowWarning(true);
        }
    }, [warnings]);

    const dataBucketFormat = (buckets: any[]) => {
        return buckets?.length > 0 ? `"${buckets?.map(bucket => {
            return Object.keys(bucket).map(key => {
                if (bucket[key] > 0) {
                return `${STARLINK_DATAUSAGE_CATEGORIES_DATA[key]} - ${bucket[key]} GB`;
                }
                return null;
            }).filter(Boolean).join(', ');
        }).join(', ')}"` : '';
    };

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'starlink-dashboard') {
                const [_columns, status] = authReducer.csvDataDownloded;
                if (status && status.length > 0) {
                    const rows = JSON.parse(status[0]);
                    if (rows.length) {

                        DownloadCSV([CsvHeaders, ...rows.map(row => mapToCsvRow(row))], {
                            formatters: {
                                0: 'ESC-COMMA',
                                1: 'ESC-COMMA',
                                2: 'ESC-COMMA',
                                3: 'ESC-COMMA',
                                // 5: dataBucketFormat,
                                10: 'ESC-COMMA',
                                11: 'ESC-COMMA',
                            }
                        }
                        );
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded]) 

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_CH',
            queryName: 'GET_SERVICE_LINES_SPORT',
            id: "starlink-dashboard",
            payload: {
                dpIds: dpIds.map(dpId => `'${dpId}'`).join(','),
                startDate: tableParams.startDate.format(YYYYMMDD_HHmmss),
                endDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
                searchText: tableParams.search,
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                source: getSource(),
                usageSource:getUsageSource(),
                limit: totalServiceLines,
                offset: 0,
                serviceLines: Array.isArray(newSummaryServiceLine) && newSummaryServiceLine?.filter(sl=>sl != '').map(sl => `'${sl}'`).join(',')
            }
        }
        downloadAsCSV(params);
    }

    useEffect(() => {
        const resources = JSON.parse(localStorage.getItem('all_resources') || '[]');
        const starlinkOnlyUser = JSON.parse(localStorage.getItem('loggedInUser') || '[]');
        let arr = serviceLines?.map((serviceLine) => {
            if (resources.filter((resource) => serviceLine.k4SiteName == resource.name)?.length > 0 && !starlinkOnlyUser?.data?.user?.starlinkOnlyUser) {
                serviceLine.isFound = true;
            } else {
                serviceLine.isFound = false;
            }
            return serviceLine
        })
        setUpdatedServiceLines(arr);
    }, [serviceLines])

    const handleSiteClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        params.k4Ids = `${row.location_id}:${row.site}`;
        delete params.page;
        history.push({ pathname: '/fleet-dashboard/sites', search: `?${getEncodedURI(params)}` });
    }

    const handleServcieLineClick = (row) => {
        let kits;
        if (row.userTerminals !== '' && JSON.parse(row.userTerminals)) {
            kits = JSON.parse(row.userTerminals)?.map((userTerminal: any) => {
                return userTerminal.kitSerialNumber
            })
        }
        let params: any = getDecodeURI(location?.search);
        params.kitSerialNumber = kits.map(kit => `'${kit}'`).join(',');
        delete params.page;
        history.push({ pathname: '/starlink/analytics', search: `?${getEncodedURI(params)}` });
    }

    return (
        <Grid>
            {/* CARDS */}
            <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Card title="Service Lines" leftTitle="Total" leftContent={totalServiceLines} rightTitle="Overage" rightContent={totalOverage} progress={(totalServiceLines - totalOverage) / totalServiceLines * 100} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Card title="User Terminals" leftTitle="Total" leftContent={totalUserTerminals}
                            rightTitle="Offline" rightContent={totalUserTerminals - totalOnlineTerminals} progress={totalOnlineTerminals / totalUserTerminals * 100} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Card title="Usage" leftTitle="Total" leftContent={`${totalUsageGB} GB`} rightTitle="Overage (MTD)" rightContent={`${totalOverageGB} GB`} progress={(totalUsageGB - totalOverageGB) / totalUsageGB * 100} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Card title="Alerts" leftTitle="Total Alerts" leftContent={totalActiveAlerts} rightTitle="Alerting Terminals" rightContent={totalAlertingTerminals} progress={(totalUserTerminals - totalAlertingTerminals) / totalUserTerminals * 100} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                <Collapse in={showWarning}>
                    <Alert
                        severity="warning"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowWarning(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {
                            warnings?.map((warning: string) => <Typography component="div" className="starlink--base--font_09x">
                                {warning}
                            </Typography>)
                        }
                    </Alert>
                </Collapse>
            </Grid>

            {/* SEARCH AND PERIOD FILTERS */}
            <Grid display={"flex"}>
            <Grid>

                     <TextField
                        id="search"
                        fullWidth
                        variant="outlined"
                        placeholder="Type something"
                        autoComplete="off"
                        classes={{ root: "input-box-service" }}
                        size="small"
                        value={searchText}
                        onChange={handleOnSearchChange}
                        onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                        InputLabelProps={{ className: "serachLabel" }}
                        InputProps={{
                            className: "serachBar",
                            startAdornment: (
                                <InputAdornment position="start">
                                    {!searchText && <SearchIcon
                                        className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                    />}
                                    {searchText && <CloseIcon
                                        className="cursorPointer input-search-icons"
                                        onClick={handleOnSearchClear}
                                    />}
                                </InputAdornment>
                            ),
                        }}
                    />
            </Grid>
            <Grid container display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <Grid   ml={1}>
               
                </Grid>
                <Grid   ml={1}>
                    <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                        <Typography component={'div'} className="starlink--base--font_08x starlink--base--padding_05x">
                            Web Service Accounts do not include online/offline, Drop Rate, Latency, Throughput, Obstruction or Alert data, this only comes from Starlink Enterprise Service Account API
                        </Typography>
                    }>
                        <QuestionMarkOutlinedIcon className="marg-left-auto" />
                    </Tooltip>
                </Grid>
                <Grid   ml={1}>
                    {totalServiceLines > 0 && <Button variant="contained" size="small" className='starlink--button_contained--primary' onClick={handleDownloadReport} disabled={false} startIcon={<img className="downloadImg" src={Download} alt="" />}>Download</Button>}

                </Grid>
            </Grid>
            </Grid>
          
            <Paper elevation={0} classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_05x' }}>
                <Pagination count={totalServiceLines} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />
                <TableContainer component={Paper} className="starlink--table_container">
                    <Table sx={{ minWidth: 650, maxWidth: '100%' }} size="small" aria-label="starlink--dashboard">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{minWidth:"150px"}}>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="nickname" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">SERVICE LINE</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell >
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="nickname" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">STATUS</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="accountDpName" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">ORGANIZATION</span>
                                    </SortArrows>
                                </TableCell>
                                  <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="dpName" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">SERVICE PLAN</span>
                                    </SortArrows>
                                </TableCell> 
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="usageGB" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">USAGE</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                        <span className="font-wt-900">LOCATION</span>
                                </TableCell>                               
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="online" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">USER TERMINALS</span>
                                    </SortArrows>
                                </TableCell>
                              
                                <TableCell>
                                <div className="starlink-d-flex"> 
                                    <Tooltip
                                        classes={{ tooltip: 'starlink--tooltip' }}
                                        title={
                                            <div className="menuScroll">
                                                <div className="starlinkAlertsTableDiv">
                                                    <Table size="small" className="starlinkAlertsTableWidth" >
                                                        <TableBody >
                                                            {Object.entries(alertMap).map(([key, value]) => (
                                                                <TableRow key={key}>
                                                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{key}</TableCell>
                                                                    <TableCell>{value}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <span className="font-wt-900">ALERTS   </span>
                                    </Tooltip>

                                   
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="activeAlerts" onChange={handleChangeSorting}>
                                </SortArrows>
                                </div>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="k4SiteName" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">SITE</span>
                                    </SortArrows>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                updatedServiceLines?.length > 0 ? updatedServiceLines.map((serviceLine: any) => {
                                    return <TableRow
                                        key={nanoid()}
                                    >
                                        <TableCell>
                                            <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                                <Grid>
                                                    <Table size="small">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>Account Number</TableCell>
                                                                <TableCell>{serviceLine.accountNumber}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>Service Line Number</TableCell>
                                                                <TableCell>{serviceLine.serviceLineNumber}</TableCell>
                                                            </TableRow>
                                                      <TableRow>
                                                      <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>
                                                              Custom Plan
                                                          </TableCell>
                                                          <TableCell>
                                                              {serviceLine?.is_custom_plan === "true" ? 'Yes' : 'No'}
                                                          </TableCell>
                                                          </TableRow>
                                                            {
                                                                serviceLine.userTerminals !== '' &&   JSON.parse(serviceLine.userTerminals)?.map((userTerminal: any) => {
                                                                    return (
                                                                        userTerminal.interface_display_name !== null ? 
                                                                        <TableRow>
                                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>Edge Interface</TableCell>
                                                                        <TableCell>{userTerminal.interface_display_name !== null ? `${userTerminal.interface_display_name}` : ""}  ( {userTerminal.kitSerialNumber !== null ? `${userTerminal.kitSerialNumber}` : "" } )</TableCell> 
                                                                    </TableRow>
                                                                    : null
                                                                    )
                                                                })
                                                            } 
                                                           
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            }>
                                                <Box className="serviceLineLink" onClick={()=>{handleServcieLineClick(serviceLine)  }}>{serviceLine.nickname} </Box>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell >
                                        <div className="starlink-d-flex">
                                          
                                            <div className="starlink-ml-4">
                                                {serviceLine?.is_service_line_paused === "false" ? (
                                                    <Tooltip   title="Service Line Status: Active"  arrow >
                                                        <div> <CircleIcon id={ "greenDotSymbol" } /> </div>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip   title="Service Line Status: Inactive" arrow  >
                                                        <div> <CircleIcon id={ "greyDotSymbol" } /></div>
                                                    </Tooltip>
                                                )}
                                            </div>
                                            <div>
                                                {serviceLine?.opted_in ? (
                                                    <Tooltip
                                                        title="Mobile Priority: Enabled"
                                                        arrow
                                                    >
                                                        <div>
                                                            <CircleIcon id={ "greenDotSymbol" } />
                                                            </div>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Mobile Priority: Disabled" arrow > 
                                                     <div> <CircleIcon id={"greyDotSymbol"} />   </div> 
                                                     </Tooltip>
                                                )}
                                            </div>
                                        </div>

                                        </TableCell>
                                        <TableCell >{serviceLine.accountDpName}  {serviceLine.dpName ? ` \\ ${serviceLine.dpName} ` : ""}</TableCell>
                                        <TableCell > {serviceLine?.servicePlan}</TableCell>
                                        <TableCell>
                                            {serviceLine?.usageSplit != '' && JSON.parse(serviceLine?.usageGB) ? <Grid display={'flex'} alignItems={'center'}>
                                                {JSON.parse(serviceLine?.usageSplit)?.length === 1 ? <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                                    <Grid>
                                                        <Table size="small">
                                                            <TableBody>

                                                                <TableRow>
                                                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Priority GB'}</TableCell>
                                                                    <TableCell>{JSON.parse(serviceLine?.usageSplit) && JSON.parse(serviceLine?.usageSplit).length > 0 ? JSON.parse(serviceLine?.usageSplit)[0]?.usagePriorityGB : '0'}  GB</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Opt In Priority GB'}</TableCell>
                                                                    <TableCell>{JSON.parse(serviceLine?.usageSplit) && JSON.parse(serviceLine?.usageSplit).length > 0 ? JSON.parse(serviceLine?.usageSplit)[0]?.usageOptInPriorityGB : '0'}  GB</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Standard GB'}</TableCell>
                                                                    <TableCell>{JSON.parse(serviceLine?.usageSplit) && JSON.parse(serviceLine?.usageSplit).length > 0 ? JSON.parse(serviceLine?.usageSplit)[0]?.usageStandardGB : '0'}  GB</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Non Billable GB'}</TableCell>
                                                                    <TableCell>{JSON.parse(serviceLine?.usageSplit) && JSON.parse(serviceLine?.usageSplit).length > 0 ? JSON.parse(serviceLine?.usageSplit)[0]?.usageNonBillableGB : '0'}  GB</TableCell>
                                                                </TableRow>
                                                                
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                }>
                                                    <Box>{`${serviceLine?.usageGB } GB`}</Box>
                                                </Tooltip> : `${serviceLine?.usageGB } GB`}
                                                {serviceLine?.usageSplit != '' && JSON.parse(serviceLine?.usageSplit)?.length > 1 ? <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                                    <Grid>
                                                        <Table size="small">
                                                            <TableBody>
                                                                {
                                                                    JSON.parse(serviceLine?.usageSplit)?.map((split: any) => <TableRow key={`tooltip-${split?.usageGB}`}>
                                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{STARLINK_DATAUSAGE_CATEGORIES[split.dataBucket] ? STARLINK_DATAUSAGE_CATEGORIES[split.dataBucket] : 'Unknown Bucket'}</TableCell>
                                                                        <TableCell>{split?.usageGB} GB</TableCell>
                                                                    </TableRow>)
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                }>
                                                    <IconButton>
                                                        <CallSplitIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip> : null}
                                            </Grid> : "0 GB"}
                                        </TableCell>
                                        <Tooltip title={<span className="starlinkGeo-font">{`${serviceLine?.currentGeoLocation}, ${serviceLine?.currentGeoCountry}`}</span>}>
                                        <TableCell>
                                            {serviceLine?.currentGeoLocation}, {serviceLine?.currentGeoCountry && serviceLine?.currentGeoCountry.length > 10 ? `${serviceLine?.currentGeoCountry.substring(0, 10)}...` : serviceLine?.currentGeoCountry}
                                        </TableCell> 
                                        </Tooltip>
                                        <TableCell >
                                             {
                                              serviceLine.userTerminals !== '' &&  JSON.parse(serviceLine.userTerminals)?.map((userTerminal: any) => {
                                                    return <Grid classes={{ root: 'starlink--base--flex--align--center' }}>
                                                        {userTerminal.kitSerialNumber ? <Fragment>
                                                            <CircleIcon id={JSON.parse(userTerminal.online) ? "greenDotSymbol" : "redDotSymbol" } />
                                                            {userTerminal.kitSerialNumber}
                                                        </Fragment> : null}
                                                    </Grid>
                                                })
                                            } 
                                        </TableCell>
                                        <TableCell >
                                            <Grid display={'flex'} direction={'row'} alignItems={'center'}>
                                                <Grid>
                                                    {
                                                        serviceLine.userTerminals !== '' &&    JSON.parse(serviceLine?.userTerminals)?.length > 0 ?  JSON.parse(serviceLine.userTerminals)?.map((userTerminal: any) => {
                                                            return <Box>
                                                                <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                                                    <Grid>
                                                                        <Table size="small">
                                                                            <TableBody>
                                                                                {
                                                                                  userTerminal.activeAlerts !== "" &&  JSON.parse(userTerminal.activeAlerts)?.map((alert: any) => {
                                                                                        return <TableRow>
                                                                                            <TableCell>
                                                                                                {alertMap[alert] ? titleCase(alertMap[alert]) : titleCase(`alert_${alert}`)}
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    })

                                                                                }
                                                                            </TableBody>
                                                                        </Table>
                                                                    </Grid>}>
                                                                    <Box display={"flex"} alignItems={"center"}>{userTerminal.activeAlerts !== "" && JSON.parse(userTerminal.activeAlerts) !== null ?
                                                                        <> <WarningAmberIcon fontSize="small" className="alertIconColor" />
                                                                            {JSON.parse(userTerminal?.activeAlerts)?.length}
                                                                        </>
                                                                        : <><WarningAmberIcon fontSize="small" className="alertIconColor"  /> 0</>}</Box>
                                                                </Tooltip></Box>
                                                        }) : 0
                                                    } 
                                                </Grid>
                                               
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <span style={serviceLine?.isFound ? {color: '#4c3aff', cursor: 'pointer'} : {color:'#45464E'}} onClick={() => serviceLine.isFound && handleSiteClick({location_id: serviceLine.k4SiteId, site: serviceLine.k4SiteName})}>{serviceLine?.k4SiteName}</span>
                                        </TableCell>
                                    </TableRow>
                                }) : <TableRow>
                                    <TableCell colSpan={10} align='center'>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>
    );
}

interface CardProps {
    title: string;
    leftTitle: string;
    leftContent: any;
    rightTitle: string;
    rightContent: any;
    // leftTooltip: React.ReactNode;
    // rightTooltip: React.ReactNode;
    progress: number;
}

function Card(props: CardProps) {
    const { title, leftTitle, leftContent, rightTitle, rightContent, progress } = props;
    return (
        <Paper classes={{ root: 'starlink--base--flex--column starlink--base--flex--justify--space_between starlink--base--padding_05x' }}>
            <Typography component="div" className="starlink--dashboard--card_title">
                {title}
            </Typography>
            <Grid classes={{ root: 'starlink--base--flex--row starlink--base--flex--justify--space_around starlink--base--mtb_07x' }}>
                <Grid>
                    <Typography component="div" classes={{ root: 'starlink--base--font_08x starlink--base--text_center' }}>
                        {leftTitle}
                    </Typography>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x starlink--base--text_center' }}>
                        {leftContent}
                    </Typography>
                </Grid>
                <Grid>
                    <Typography component="div" classes={{ root: 'starlink--base--font_08x starlink--base--text_center' }}>
                        {rightTitle}
                    </Typography>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x starlink--base--text_center' }}>
                        {rightContent}
                    </Typography>
                </Grid>

            </Grid>
            <Box className="starlink--dashboard--card_progress">
                <LinearProgress variant="determinate" value={progress} />
            </Box>
        </Paper>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    warnings: state.starlinkDashboard.warnings,
    serviceLines: state.starlinkDashboard.serviceLines,
    totalServiceLines: state.starlinkDashboard.totalServiceLines,
    totalUsageGB: state.starlinkDashboard.totalUsageGB,
    totalOverageGB: state.starlinkDashboard.totalOverageGB,
    totalOverage: state.starlinkDashboard.totalOverage,
    totalUserTerminals: state.starlinkDashboard.totalUserTerminals,
    totalOnlineTerminals: state.starlinkDashboard.totalOnlineTerminals,
    totalAlertingTerminals: state.starlinkDashboard.totalAlertingTerminals,
    totalActiveAlerts: state.starlinkDashboard.totalActiveAlerts,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
    selectedServiceAccount: state?.authReducer?.selectedServiceAccount,

});

export default withRouter(
    connect(mapStateToProps, {
        getServiceLines,
        getStarlinkCloudWarnings,
        downloadAsCSV,
        clearComponentData,
        setSelectedAccount
    })(StarlinkDashboard)
);