import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import _ from "lodash";
import close from "../../../asset/image/close.svg";
import { getUniqueActionList, getUniqueClassList, getUniqueDeviceList, getUniqueIpList, getUniqueNameList } from "../../../actions/Users/authenticateThreatDashboard";
import { SET_THREAT_FILTERS } from "../../../actions/types";
import { getEncodedURI, getDecodeURI } from "../../../utils/util";

const ThreatFilters = (props) => {
    const { authReducer, setFilterPopup, newSummaryStartDate, newSummaryEndDate, getUniqueDeviceList, getUniqueClassList, getUniqueIpList, threatUniqueDeviceList, threatUniqueIpList, threatUniqueClassList,threatFilters, history, location,threatUniqueThreatNameList, getUniqueNameList, threatUniqueActionList, getUniqueActionList} = props;
    const [deviceNames, setDeviceNames] = useState<any[]>([]);
    const [ipList, setIpList] = useState<any[]>([]);
    const [classList, setClassList] = useState<any[]>([]);
    const [nameList , setNameList] =useState<any[]>([]);
    const [actionList , setActionList] =useState<any[]>([]);
    const [severityList, setSeverityList] = useState<any[]>([{id: 501, severityType:"ALL"}, {id: 501, severityType:"LOW"}, {id: 501, severityType:"MEDIUM"}, {id: 501, severityType:"HIGH"}, {id: 501, severityType:"CRITICAL"}]);
    const dispatch = useDispatch();
    // Hardcoded default values for IP, Device, and Class
    const [selectedIp, setSelectedIp] = useState<any>({ id: 1, ip_address: threatFilters?.ip ? threatFilters?.ip : '' });
    const [selectedDevice, setSelectedDevice] = useState<any>({ id: 101, Device_Name: threatFilters?.deviceName ? threatFilters?.deviceName : '' });
    const [selectedClass, setSelectedClass] = useState<any>({ id: 201, class: threatFilters?.threatClass ? threatFilters?.threatClass : '' });
    const [selectedName, setSelectedName] = useState<any>({ id: 301, name: threatFilters?.threatClass ? threatFilters?.threatClass : '' });
    const [selectedAction, setSelectedAction] = useState<any>({ id: 401, name: threatFilters?.action ? threatFilters?.action : '' });
    const [selectedSeverity, setSelectedSeverity] = useState<any>({id: 501, severityType: threatFilters?.severityType ? threatFilters?.severityType : ''});

    // Load vessels list and fetch unique data
    useEffect(() => {
        if(threatFilters) {
            setSelectedIp({id: 1, ip_address: threatFilters?.ip ? threatFilters?.ip : ''})
            setSelectedDevice({ id: 101, Device_Name: threatFilters?.deviceName ? threatFilters?.deviceName : '' })
            setSelectedClass({ id: 201, class: threatFilters?.threatClass ? threatFilters?.threatClass : '' })
            setSelectedName({id: 301, msg: threatFilters?.threatName ? threatFilters?.threatName : ''  })
            setSelectedAction({ id: 401,  action : threatFilters?.action  ? threatFilters?.action : ''})
            setSelectedSeverity({id: 501, severityType: threatFilters?.severityType ? threatFilters?.severityType : ''})
        }
    },[threatFilters])

    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing)) return;
        let vessels = authReducer?.filteredVessels?.length > 0 ? authReducer.filteredVessels : authReducer?.getVesselsListing?.locations;
        getUniqueDeviceList(vessels, newSummaryStartDate, newSummaryEndDate);
        getUniqueClassList(vessels, newSummaryStartDate, newSummaryEndDate);
        getUniqueIpList(vessels, newSummaryStartDate, newSummaryEndDate);
        getUniqueNameList(vessels, newSummaryStartDate, newSummaryEndDate)
        getUniqueActionList(vessels, newSummaryStartDate, newSummaryEndDate)
    }, [authReducer?.getVesselsListing, authReducer?.filteredVessels, newSummaryStartDate, newSummaryEndDate]);

    // Set device names
    useEffect(() => {
        if (!_.isEmpty(threatUniqueDeviceList)) {
            const data = threatUniqueDeviceList.data;
            const deviceNamesList = data?.rows.map((row, i) => {
                const mappedRow = {};
                data?.columns.forEach((col, index) => {
                    mappedRow[col] = row[index];
                });
                return mappedRow;
            });
            setDeviceNames(deviceNamesList);
        }
    }, [threatUniqueDeviceList]);

    // Set IP list
    useEffect(() => {
        if (!_.isEmpty(threatUniqueIpList)) {
            const data = threatUniqueIpList.data;
            const ipListDetails = data?.rows.map((row, i) => {
                const mappedRow = {};
                data?.columns.forEach((col, index) => {
                    mappedRow[col] = row[index];
                });
                return mappedRow;
            });
            setIpList(ipListDetails);
        }
    }, [threatUniqueIpList]);

    // Set class list
    useEffect(() => {
        if (!_.isEmpty(threatUniqueClassList)) {
            const data = threatUniqueClassList.data;
            const classListDetails = data?.rows.map((row, i) => {
                const mappedRow = {};
                data?.columns.forEach((col, index) => {
                    mappedRow[col] = row[index];
                });
                return mappedRow;
            });
    
            // Always add "virus" and "malware" to the top of the list
            classListDetails.unshift({ class: "malware" });
            classListDetails.unshift({ class: "virus" });
            setClassList(classListDetails);
        }
    }, [threatUniqueClassList]);

    useEffect(() => {
        if (!_.isEmpty(threatUniqueThreatNameList)) {
            const data = threatUniqueThreatNameList.data;
            const nameListDetails = data?.rows.map((row, i) => {
                const mappedRow = {};
                data?.columns.forEach((col, index) => {
                    mappedRow[col] = row[index];
                });
                return mappedRow;
            });
            setNameList(nameListDetails);
        }
    }, [threatUniqueThreatNameList]);


    useEffect(() => {
        if (!_.isEmpty(threatUniqueActionList)) {
            const data = threatUniqueActionList.data;
            const actionListDetails = data?.rows.map((row, i) => {
                const mappedRow = {};
                data?.columns.forEach((col, index) => {
                    mappedRow[col] = row[index];
                });
                return mappedRow;
            });
            setActionList(actionListDetails);
        }
    }, [threatUniqueActionList]);

    // Handle device change
    const handleInputDeviceChange = (e, newValue) => {
        setSelectedDevice(newValue);
    };

    // Handle class change
    const handleInputClassChange = (e, newValue) => {
        setSelectedClass(newValue);
    };

    // Handle IP change
    const handleInputIpChange = (e, newValue) => {
        setSelectedIp(newValue);
    };

    const handleInputNameChange = (e,newValue) => {
        setSelectedName(newValue)
    }

    const handleInputActionChange = (e, newValue) => {
        setSelectedAction(newValue)
    }

    const handleSeverityChange = (e, value) => {
        setSelectedSeverity(value);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
      }
      

    const handleApply = () => {
        const defaultAuthValues = {
            ip: selectedIp  && selectedIp?.ip_address ? selectedIp?.ip_address : '',
            threatName: selectedName && selectedName?.msg ? selectedName?.msg : "",
            threatClass: selectedClass && selectedClass?.class ? selectedClass?.class : '',
            deviceName: selectedDevice && selectedDevice?.Device_Name ? selectedDevice?.Device_Name  : '',
            action: selectedAction && selectedAction?.action ? selectedAction?.action : '',
            severityType: selectedSeverity && selectedSeverity?.severityType ? selectedSeverity?.severityType : ''
        };

        let params: any = getDecodeURI(location?.search);
        params.threatName = defaultAuthValues?.threatName;
        params.deviceName = defaultAuthValues?.deviceName;
        params.deviceName = defaultAuthValues?.ip;
        params.threatClass = defaultAuthValues?.threatClass;
        params.action = defaultAuthValues?.action;
        params.severityType = defaultAuthValues?.severityType;
        doNavigate(params);
        dispatch({ type: SET_THREAT_FILTERS, payload: { ...defaultAuthValues } });
        setFilterPopup(false)
    }  

    const handleClearFilter = () => {
        setSelectedIp({id: 1, ip_address: ''})
        setSelectedDevice({ id: 101, Device_Name: '' })
        setSelectedClass({ id: 201, class: '' })
        setSelectedName({id: 301, msg: ''  })
        setSelectedAction({ id: 401,  action : ''})
        setSelectedSeverity({id: 501, severityType: ''})
        const defaultAuthValues = {
            ip: '',
            threatName: "",
            threatClass: '',
            deviceName: '',
            action: '',
            severityType: ''
        };
        let params: any = getDecodeURI(location?.search);
        params.threatName = defaultAuthValues?.threatName;
        params.deviceName = defaultAuthValues?.deviceName;
        params.deviceName = defaultAuthValues?.ip;
        params.threatClass = defaultAuthValues?.threatClass;
        params.action = defaultAuthValues?.action;
        params.severityType = defaultAuthValues?.severityType;
        doNavigate(params);
        dispatch({ type: SET_THREAT_FILTERS, payload: { ...defaultAuthValues } });
        setFilterPopup(false)
    }

    return (
        <div className="Form">
            <Grid className="am-table">
                <Grid className="dash-filter-pop">
                    <Grid className="login-history-filter-inputs">
                        <Grid className="filter-clearFilter login-filter-clearFilter">
                            <div className="Filters_Title">Filters</div>
                            <img src={close} onClick={() => setFilterPopup(false)} className="dialog_closeIcon-filters" alt="Close" />
                        </Grid>
                        <Grid className="accountActivityOptionsResponse">
                            {/* Device Names */}
                            <Grid className="rc--filter_section login-rc--filter_section">
                                <div className="optionHeadingPadding12">Device Names</div>
                                <Autocomplete
                                    disableClearable={selectedDevice ? false : true}
                                    ListboxProps={{ style: { maxHeight: 200, fontSize: '13px' } }}
                                    options={deviceNames}
                                    size="small"
                                    value={selectedDevice}
                                    onChange={handleInputDeviceChange}
                                    getOptionLabel={(option) => option?.Device_Name || ""}
                                    renderInput={(params) => <TextField {...params} placeholder="Device Name" />}
                                />
                            </Grid>
                            {/* User IP */}
                            <Grid className="rc--filter_section login-rc--filter_section">
                                <div className="optionHeadingPadding12">IP</div>
                                <Autocomplete
                                    disableClearable={selectedIp ? false : true}
                                    ListboxProps={{ style: { maxHeight: 200, fontSize: '13px' } }}
                                    options={ipList}
                                    size="small"
                                    value={selectedIp}
                                    onChange={handleInputIpChange}
                                    getOptionLabel={(option) => option?.ip_address || ""}
                                    renderInput={(params) => <TextField {...params} placeholder="User IP" />}
                                />
                            </Grid>
                            {/* Class */}
                            <Grid className="rc--filter_section login-rc--filter_section">
                                <div className="optionHeadingPadding12">Class</div>
                                <Autocomplete
                                    disableClearable={selectedClass ? false : true}
                                    ListboxProps={{ style: { maxHeight: 200, fontSize: '13px' } }}
                                    options={classList}
                                    size="small"
                                    value={selectedClass}
                                    onChange={handleInputClassChange}
                                    getOptionLabel={(option) => option?.class || ""}
                                    renderInput={(params) => <TextField {...params} placeholder="Class" />}
                                />
                            </Grid>

                            <Grid className="rc--filter_section login-rc--filter_section">
                                <div className="optionHeadingPadding12">Threat Name</div>
                                <Autocomplete
                                    disableClearable={selectedName ? false : true}
                                    ListboxProps={{ style: { maxHeight: 200, fontSize: '13px' } }}
                                    options={nameList}
                                    size="small"
                                    value={selectedName}
                                    onChange={handleInputNameChange}
                                    getOptionLabel={(option) => option?.msg || ""}
                                    renderInput={(params) => <TextField {...params} placeholder="Threat Name" />}
                                />
                            </Grid>
                            <Grid className="rc--filter_section login-rc--filter_section">
                                <div className="optionHeadingPadding12">Action</div>
                                <Autocomplete
                                    disableClearable={selectedName ? false : true}
                                    ListboxProps={{ style: { maxHeight: 200, fontSize: '13px' } }}
                                    options={actionList}
                                    size="small"
                                    value={selectedAction}
                                    onChange={handleInputActionChange}
                                    getOptionLabel={(option) => option?.action || ""}
                                    renderInput={(params) => <TextField {...params} placeholder="Action Name" />}
                                />
                            </Grid>
                            <Grid className="rc--filter_section login-rc--filter_section">
                                <div className="optionHeadingPadding12">Severity</div>
                                <Autocomplete
                                    disableClearable={selectedName ? false : true}
                                    ListboxProps={{ style: { maxHeight: 200, fontSize: '13px' } }}
                                    options={severityList}
                                    size="small"
                                    value={selectedSeverity}
                                    onChange={handleSeverityChange}
                                    getOptionLabel={(option) => option.severityType}
                                    renderInput={(params) => <TextField {...params} placeholder="Severity" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="clearApplyFilters">
                        <Button onClick={() => handleClearFilter()} className="clearFiltersButton">Clear Filter</Button>
                        <Button className="usage-applyFilterBtn" onClick={() => handleApply()}>Apply Filter</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    threatUniqueDeviceList: state?.authReducer?.threatUniqueDeviceList,
    threatUniqueIpList: state?.authReducer?.threatUniqueIpList,
    threatUniqueClassList: state?.authReducer?.threatUniqueClassList,
    threatFilters: state?.authReducer?.threatFilters,
    threatUniqueThreatNameList: state?.authReducer?.threatUniqueThreatNameList,
    threatUniqueActionList: state?.authReducer?.threatUniqueActionList
});

export default withRouter(connect(mapStateToProps, { getUniqueDeviceList, getUniqueClassList, getUniqueIpList,getUniqueNameList, getUniqueActionList })(ThreatFilters));
