import { api, displayToastErrorForExternalApi } from "../../server/request";
import { handleResponse, handleError, handleResponseObj } from "../responseUtil";
import { KAPTURE_BASE_URL, KAPTUREV2_URL_INV, KAPTUREV2_URL, API_URL } from '../../config';
import {
  SET_LOADING,
  SET_VESSEL_PLANS_LOADING,
  GET_VESSEL_PLANS,
  GET_USER_POLICY,
  GET_USER_DETAILS,
  UPDATE_USER_PROFILE,
  GET_VESSELS_LISTING,
  GET_USER_LOGIN_HISTORY,
  SET_VESSEL_LISTING_LOADING,
  UPDATE_NAME,
  VESSEL_ADDRESS_MMSI,
  GET_VESSEL_INFO,
  UPDATE_MMSI_AND_STREETADDRESS,
  GET_PARENT_DEVICE_OF_A_SITE,
  UPDATE_PARENT_DEVICE_OF_A_SITE,
  GET_MAINTENANCE_INFO,
  UPDATE_USER_PREFERENCES,
  GET_USER_PREFERENCES,
  USER_PREFERENCES_LOADING,
  SET_PRIVATE_VESSEL_LIST_LOADING,
  SET_PRIVATE_VESSEL_LIST
} from "../types";
import {ERROR_GET_VESSELS_LISTING, ERROR_UPDATING_USER_DETAILS} from "../error_types";
import { IAM_USER_POLICY_ALL_RESOURCES_KEY } from "../../constants/Constants";
import { toast } from "react-toastify";

export const getVesselPlans = (payload, page, limit) => (dispatch) => {
  dispatch({ type: SET_VESSEL_PLANS_LOADING, payload: true });
  api
  .postRequestForExternalApi(`${KAPTUREV2_URL}/vessel/getKaptureActiveVessels?exactMatch=true&page=${page}&limit=${limit}`, payload)
  .then((res) => handleResponse(res, dispatch, GET_VESSEL_PLANS, "", SET_VESSEL_PLANS_LOADING))
    .catch((err) => handleError(err, dispatch))
    .finally(() => dispatch({type : SET_VESSEL_PLANS_LOADING, payload: false}));
};

export const getUserPolicies = () => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest('/iam/policy/getUserPolicies')
    .then((res) => handleResponse(res, dispatch, GET_USER_POLICY))
    .catch((err) => handleError(err, dispatch));
};

export const getMaintenanceInfo = (dpId) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  const data: any = {
    "query": "GET_SPORT_MAINTENANCE_INFO",
    "named": true,
    "parameters": {
      "dpId": dpId,
    },
    format: 'csv'
  }

  api
    .postRequestOut(`/store/ch/query`, data)
    .then((res) =>
      handleResponse(res, dispatch, GET_MAINTENANCE_INFO))
    .catch((err) => handleError(err, dispatch));
};

export const getVesselsListing = (name, search, serviceName: any, serviceFunction: any, isT0Visible: boolean = false) => (dispatch) => {
  dispatch({ type: SET_VESSEL_LISTING_LOADING, payload: true });
  api
    .getRequestWithHeader(`/edge/distributionPartners/dpName/${name}/locations?search=${search}&sort=name&sortOrder=asc&isT0Visible=${isT0Visible}`, {})
    .then((res) => handleResponse(res, dispatch, GET_VESSELS_LISTING, ERROR_GET_VESSELS_LISTING, SET_VESSEL_LISTING_LOADING))
    .catch((err) => handleError(err, dispatch, ERROR_GET_VESSELS_LISTING));
};

export const getVesselsListingForPrivate = (name, search, serviceName: any, serviceFunction: any) => (dispatch) => {
  dispatch({ type: SET_PRIVATE_VESSEL_LIST_LOADING, payload: true });
  api
    .getRequestWithHeader(`/edge/distributionPartners/dpName/${name}/locations?search=${search}&sort=name&sortOrder=asc&private=true&isT0Visible=true`, {})
    .then((res) => handleResponse(res, dispatch, SET_PRIVATE_VESSEL_LIST))
    .catch((err) => handleError(err, dispatch, SET_PRIVATE_VESSEL_LIST_LOADING));
};

export const getResourceList = (name, search, serviceName: any, serviceFunction: any, callback?: VoidFunction) => (dispatch) => {
  dispatch({ type: SET_VESSEL_LISTING_LOADING, payload: true });
  api
    .getRequestWithHeader(`/edge/distributionPartners/dpName/${name}/locations?search=${search}&sort=name&sortOrder=asc}`, {})
    .then((res) => {
      console.log("RESOURCES",res);
      localStorage.setItem(IAM_USER_POLICY_ALL_RESOURCES_KEY, JSON.stringify(res?.data?.locations));
      handleResponse(res, dispatch, GET_VESSELS_LISTING, ERROR_GET_VESSELS_LISTING, SET_VESSEL_LISTING_LOADING)
      callback?.();
    }) 
    .catch((err) => handleError(err, dispatch, ERROR_GET_VESSELS_LISTING));
};

export const getUserDetails = (userName, ouName) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/iam/user/details?userName=${userName}&ouName=${encodeURIComponent(ouName)}`)
    .then((res) => handleResponse(res, dispatch, GET_USER_DETAILS))
    .catch((err) => handleError(err, dispatch));
};

export const updateUserProfile = (payload) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .putRequest("/iam/user/details/v2", payload)
    .then((res) => handleResponse(res, dispatch, UPDATE_USER_PROFILE, ERROR_UPDATING_USER_DETAILS))
    .catch((err) => handleError(err, dispatch));
};

export const updateUserPreferences = (payload) => (dispatch) => {
  dispatch({ type: USER_PREFERENCES_LOADING, payload: true });
  api
    .putRequest("/iam/user/updatePreferences", payload)
    .then((res) => {
      if (res.status == 200) {
        toast.success("Preferences updated successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        })
      }
      handleResponse(res, dispatch, UPDATE_USER_PREFERENCES)
    })
    .catch((err) => handleError(err, dispatch));
}

export const getUserPreferences = (userName) => (dispatch) => {
  // dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/iam/user/${userName}/preferences`)
    .then((res) => handleResponse(res, dispatch, GET_USER_PREFERENCES))
    .catch((err) => handleError(err, dispatch));
}

export const getUserLoginHistoryDetails = (userName:string, duration:any, limit: any, page: any, sort: any, sortOrder: any, search: any, filterBy: any) =>(dispatch) =>{
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/iam/loginHistory/getUserLoginHistory?userName=${userName}&duration=${duration}&limit=${limit}&page=${page}&sort=${sort}&sortOrder=${sortOrder}&search=${search}&filterBy=${filterBy}`)
    .then((res) => handleResponse(res, dispatch, GET_USER_LOGIN_HISTORY))
    .catch((err) => handleError(err, dispatch));
}

const handleupdateNames = (res: any, dispatch: any) => {
  if (res && !res.status && res.message) {
    displayToastErrorForExternalApi(res.message);
  }
  handleResponse(res, dispatch, UPDATE_NAME);
}

export const updateNames = (name: string, type: string, payload) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequestForTicketExternalApi(type == 'Site' ? `${KAPTUREV2_URL}/location/update?k4Id=${name}` :
      `${KAPTUREV2_URL_INV}inventoryConfig/updateDeviceAlias?deviceId=${name}`
      , payload)
    .then((res) => handleupdateNames(res, dispatch))
    .catch((err) => handleError(err, dispatch));
};

export const vesselAddressMMSI = (k4Id) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  const data : any = {
      query: 'VESSEL_ADDRESS_MMSI',
      named: true,
      parameters: {
        k4Id : k4Id
      },
      format: 'csv'
  }
  api
  .postRequestOut(`/store/ch/query`, data)
  .then((res) => 
  handleResponse(res, dispatch, VESSEL_ADDRESS_MMSI))
  .catch((err) => handleError(err, dispatch));
  };

  export const getVesselInfo = (k4Id:'') => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
      .getRequest(`/geolocation/getTopologyInfo/${k4Id}`)
      .then((res) => handleResponse(res, dispatch, GET_VESSEL_INFO))
      .catch((err) => handleError(err, dispatch));
  }
  
  const handleupdateAddress = (res: any, dispatch: any) => {
    if(res.status == 200){
      toast.success("Updated Successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    else if(res && !res.status && res.message) {
      displayToastErrorForExternalApi(res.message);
    };
    handleResponse(res, dispatch, UPDATE_MMSI_AND_STREETADDRESS);
  }
  
  export const updateMMSI_StreetAddress = (payload) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
      .postRequestForExternalApi(`${API_URL}/geolocation/updateTopologyInfo`, payload)
      .then((res) => handleupdateAddress(res, dispatch))
      .catch((err) => handleError(err, dispatch));
  };

  export const getParentDeviceOfSite = (siteId: string) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
      .getRequestForExternalApi(`${API_URL}/client/v1/sites/${siteId}`)
      .then((res) => handleResponseObj(res, dispatch, GET_PARENT_DEVICE_OF_A_SITE))
      .catch((err) => handleError(err, dispatch));
  };

  export const updateParentDeviceOfSite = (siteId: string, deviceId: string) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let payload = {
      "parent_device": deviceId
    }
    api
      .putRequestForExternalApi(`${API_URL}/client/v1/sites/${siteId}`, payload)
      .then((res) => handleUpdateParentDeviceOfSite(res, dispatch))
      .catch((err) => handleError(err, dispatch));
  };

  const handleUpdateParentDeviceOfSite = (res: any, dispatch: any) => {
    if(res && res.status == 200 && res?.data && res.data?.success && res.data.success){
      toast.success("Parent device updated Successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else{
      let message =  res && res?.data && res.data?.errors ? res.data.errors : JSON.stringify(res.data);
      displayToastErrorForExternalApi(message);
    };
    handleResponse(res, dispatch, UPDATE_PARENT_DEVICE_OF_A_SITE);
  }
