import React, { useEffect, useState } from 'react';
import { Grid, Box, Paper, TextField, Chip, IconButton, Table, Typography, Button, Stack, TableContainer, TableHead, TableRow, TableCell, TableBody, Tooltip, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, ListItemText, ListItem } from '@mui/material';
import { Edit as EditIcon, Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from 'lodash';
import { addTerminalToServiceLine, removeTerminalFromServiceLine, rebootTerminal, ACTION_TYPES } from '../slice';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { SERVICE_FUNCTION_TO_FEATURE } from '../../../config';
import { checkLoggedInUserAuthorizedToViewPage } from '../../../utils/util';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const ServiceLineDevices = (props) => {
    const { serviceLineToUpdate, starlinkAdmin, changeSLName, userTerminalData, rebootUserTerminal, addTerminalToServiceLine, removeTerminalFromServiceLine, addUserTerminalToServiceLine, removeUserTerminalFromServiceLine, rebootTerminal } = props;
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [filteredTerminalArray, setFilteredTerminalArray] = useState<any>([])
    const [selectedTerminals, setSelectedTerminals] = useState<any>(null);
    const [confirmDialog, setConfirmDialog] = useState(false)
    const [terminalToDelete, setTerminalToDelete] = useState<any>(null)
    const [userTerminals, setUserTerminals] = useState<any>([])
    const [addTerminalDialog, setAddTerminalDialog] = useState(false)
    const STARLINK_ADMIN_SF = "starlink-admin";
    const STARLINK_FULL_SF = "starlink-full";

    const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
    const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];

    useEffect(() => {
        if (userTerminalData?.user_terminals) {
            let filteredTerminals = userTerminalData?.user_terminals?.map((item: any) => ({
                dishSerialNumber: item?.dish_serial_number,
                kitSerialNumber: item?.kit_serial_number,
                userTerminal: item?.user_terminal_id,
                accountNumber: item?.account_number
            }));
            setFilteredTerminalArray(filteredTerminals)
        }
    }, [userTerminalData])

    useEffect(() => {
        if (serviceLineToUpdate && serviceLineToUpdate?.userTerminalDetails) {
            setUserTerminals(serviceLineToUpdate?.userTerminalDetails)
        }
    }, serviceLineToUpdate)


    const handleAddUserTerminal = () => {
        let params = {
            organizationId: serviceLineToUpdate?.accountDpId,
            accountNumber: serviceLineToUpdate?.accountNumber,
            serviceLineNumber: serviceLineToUpdate?.serviceLineNumber,
            userTerminalId: selectedTerminals?.userTerminal
        }
        addTerminalToServiceLine(params)
    }

    useEffect(() => {
        if (!_.isEmpty(addUserTerminalToServiceLine)) {
            toast.success(`User Terminal added successfully.`, {
                position: toast.POSITION.BOTTOM_LEFT,
            })
            let newTerminal = userTerminals
            newTerminal.push({
                kitSerialNumber: selectedTerminals?.kitSerialNumber,
                userTerminalId: selectedTerminals?.userTerminal
            })
            setUserTerminals(newTerminal)
            setSelectedTerminals(null)
            dispatch({ type: ACTION_TYPES.ADD_USER_TERMINAL_TO_SERVICE_LINE, payload: {} })

        }

    }, [addUserTerminalToServiceLine])

    const handleRemoveServiceLine = (ut) => {
        let params = {
            organizationId: serviceLineToUpdate?.accountDpId,
            accountNumber: serviceLineToUpdate?.accountNumber,
            serviceLineNumber: serviceLineToUpdate?.serviceLineNumber,
            userTerminalId: ut?.userTerminalId
        }
        removeTerminalFromServiceLine(params)
    }

    useEffect(() => {
        if (!_.isEmpty(removeUserTerminalFromServiceLine)) {
            toast.success(`User Terminal removed successfully.`, {
                position: toast.POSITION.BOTTOM_LEFT,
            })
            let updatedTerminalArray = userTerminals?.filter((userTerminal) => {
                return userTerminal.kitSerialNumber !== terminalToDelete?.kitSerialNumber
            })

            setUserTerminals(updatedTerminalArray)
            changeSLName(serviceLineToUpdate?.serviceLineName)
            setSelectedTerminals(null)
            setTerminalToDelete(null)
            dispatch({ type: ACTION_TYPES.REMOVE_USER_TERMINAL_FROM_SERVICE_LINE, payload: {} })
        }

    }, [removeUserTerminalFromServiceLine])

    useEffect(() => {
        setLoading(
            starlinkAdmin.addingUTtoServiceLine ||
            starlinkAdmin.removeUTfromServiceLine ||
            starlinkAdmin.rebootingUserTerminal 
        )
    }, [ starlinkAdmin.addingUTtoServiceLine, starlinkAdmin.removeUTfromServiceLine,  starlinkAdmin.rebootingUserTerminal  ])

    const handleRebootTerminal = (ut, slData) =>{

        let params = {
            organizationId: slData?.accountDpId,
            accountNumber: slData?.accountNumber,
            userTerminalId:  ut?.userTerminalId
        }
        rebootTerminal(params)
    }

    useEffect(() => {
        if (!_.isEmpty(rebootUserTerminal)) {
            toast.success(`User Terminal reeboted successfully.`, {
                position: toast.POSITION.BOTTOM_LEFT,
            })
            
            dispatch({ type: ACTION_TYPES.REBOOT_USER_TERMINAL, payload: {} })
        }

    }, [rebootUserTerminal])

    return (
        <div className="serviceLineDetails-container">
            {userTerminals && userTerminals.length > 0 ?
                <>
                    <Typography component="div" className="starlink--base--font_1x">Associated Terminals</Typography>
                    <TableContainer component={Paper} className="starlink--table_container" >
                        <Table className='starlink-sl-devices-table' size="small" aria-label="starlink--service_accounts">
                            <TableHead>
                                <TableRow>
                                    <TableCell> <span className="font-wt-900">SNo.</span> </TableCell>
                                    <TableCell> <span className="font-wt-900">STARLINK</span> </TableCell>
                                    <TableCell> <span className="font-wt-900">ACTIONS</span> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userTerminals?.map((ut, index) => {
                                    return (<TableRow>
                                        <TableCell> {index + 1} </TableCell>
                                        <TableCell> {ut?.kitSerialNumber} </TableCell>
                                        <TableCell>
                                            {
                                                checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) ?
                                                <Tooltip title={"Remove Starlink"}>
                                                <IconButton size='small' onClick={(e) => { setConfirmDialog(true); setTerminalToDelete(ut) }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                </Tooltip> : null
                                            }

                                                <Tooltip title={"Reboot Terminal"}>
                                                <IconButton size='small' onClick={(e) => { handleRebootTerminal(ut, serviceLineToUpdate) }}>
                                                    <RestartAltIcon />
                                                </IconButton>
                                                </Tooltip>
                                           

                                        </TableCell>
                                    </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </> :
                <>
                    <Typography component="div" className="starlink--base--font_1x">No Associated Terminals</Typography>
                </>
            }

            {checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)
                ? 
                <Grid className='starlink-sl-devices-grid'>
                <Typography component="div" className="starlink--base--font_1x">Available Terminals</Typography>
                <Autocomplete
                    disableClearable
                    fullWidth
                    options={filteredTerminalArray}
                    getOptionLabel={(option) => `${option?.userTerminal || ''} - ${option?.kitSerialNumber || ''} - ${option?.dishSerialNumber || ''}`}
                    value={selectedTerminals}
                    onChange={(event, newValue) => {
                        setSelectedTerminals(newValue)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                        />
                    )}
                />

                <Button
                    size="small"
                    className='starlink-sl-devices-button'
                    disabled={selectedTerminals === null}
                    variant="outlined"
                    onClick={() => { selectedTerminals?.accountNumber === serviceLineToUpdate?.accountNumber ?  handleAddUserTerminal() : setAddTerminalDialog(true) }}
                >
                   {starlinkAdmin.addingUTtoServiceLine ? <CircularProgress size={24} /> : "ADD STARLINK"}  
                </Button>
            </Grid>
            : null
            }

            

            <Dialog className="serviceLineDevices-dialog-width" open={addTerminalDialog} onClose={() => { setAddTerminalDialog(false) }}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                <Typography>
                        {`Following Terminals will be moved from `}
                        <Typography component="span" fontWeight="bold">
                        {selectedTerminals?.accountNumber}
                        </Typography>
                        {` to `}
                        <Typography component="span" fontWeight="bold">
                        {serviceLineToUpdate?.accountNumber}
                        </Typography>
                    </Typography>

                    {selectedTerminals?.kitSerialNumber ? 
                    <ListItem key={selectedTerminals?.kitSerialNumber}>
                        <ListItemText primary={selectedTerminals?.kitSerialNumber} secondary={`Starlink Id: ${selectedTerminals?.userTerminal ? selectedTerminals?.userTerminal : "-" }`} />
                    </ListItem> : <></> }
                  
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddTerminalDialog(false)} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                             handleAddUserTerminal() ;  
                            setAddTerminalDialog(false) }}
                        variant="outlined"
                        color="primary"
                    >
                        {'Migrate & Add Starlink'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog  className="serviceLineDevices-dialog-width" open={confirmDialog} onClose={() => { setConfirmDialog(false) }}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <Typography>
                        {`Are you sure you want to remove KIT `}
                        <Typography component="span" fontWeight="bold">
                            {terminalToDelete?.kitSerialNumber}
                        </Typography>
                        {` from service line ${serviceLineToUpdate?.serviceLineName}?`}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialog(false)} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                             handleRemoveServiceLine(terminalToDelete); 
                             setConfirmDialog(false) }}
                        variant="outlined"
                        color="primary"
                    >
                          {starlinkAdmin.removeUTfromServiceLine ? <CircularProgress size={24} /> : "Yes"}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

const mapStateToProps = (state) => ({
    starlinkAdmin: state.starlinkAdmin,
    loading: state.starlinkAdmin.subcribingServiceLine,
    addUserTerminalToServiceLine: state.starlinkAdmin.addUserTerminalToServiceLine,
    removeUserTerminalFromServiceLine: state.starlinkAdmin.removeUserTerminalFromServiceLine,
    authReducer: state.authReducer,
    rebootUserTerminal: state.starlinkAdmin.rebootUserTerminal
});

export default withRouter(
    connect(mapStateToProps, { removeTerminalFromServiceLine, addTerminalToServiceLine, rebootTerminal })(ServiceLineDevices)
);


