import React, { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { MenuItem, Menu, Typography, Tooltip } from "@mui/material";
import SideMenu from "../SideMenu";
import { Grid } from "@mui/material";
import { logoutUser } from "../../actions/Users/authenticateLogin";
import _, { set } from "lodash";
import { useHistory } from "react-router-dom";
import HeaderLogo from '../../asset/image/headerLogo.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "./Header.css";
import SubHeaderSummary from '../../components/SubHeaderSummary';
import {
  LOGGED_IN_EMAIL, LOGGED_IN_USER, REFRESH_TOKEN,
  LOGGED_IN_UPDATE_EMAIL, PERMISSIONS, USER_OU, IAM_USER_POLICY_ALL_RESOURCES_KEY, SESSION_TOKEN, CAPTIVE_ACCESS_ENABLED, MAINTENANCE, USER_PREFERENCES
} from '../../constants/Constants';
import { removeDataFromLocalStorage, getDataFromLocalStorage } from "../../utils/util";
import ReleaseNote from "../ReleaseNote";
import Maintainence from "../Maintainence";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {REDIRECT_URL} from './../../config';
import { updateSelectedVessels, updatFilteredVessels } from "../../actions/Users/authenticate";
import ToggleSwitch from "./ToggleSwitch";
import NewSideMenu from "../NewSideMenu";
import Preferences from "./Preferences";
import UTCClock from "../Clock";

const Header = (props) => {
  
  const { errorReducer, authReducer, logoutUser, updateSelectedVessels, updatFilteredVessels, handleToggle, isViewSUm, isDashBoard } = props;

  let history = useHistory();
  const [userName, setUserName] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [seletedVessels, setSeletedVessels] = useState([]);
  const [isImageError, setImageError] = useState(false);
  const open = Boolean(anchorEl);

  const [isPreferencesPopUpOpen, setIsPreferencesPopUpOpen] = useState(false)

  useEffect(() => {
    const _loggedInUser: any = getDataFromLocalStorage("loggedInUser");
    if (_loggedInUser) {
      const loggedInUser = JSON.parse(_loggedInUser);
      if (loggedInUser?.data?.user) {
        setUserName(`${loggedInUser?.data?.user?.name?.givenName} ${loggedInUser?.data?.user?.name?.familyName}`)
      }
    }
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    let loggedInUser = getDataFromLocalStorage(LOGGED_IN_USER);
    let logoutPayload = {
      idToken: JSON.parse("" + loggedInUser).data.id_token,
      redirectUrl: REDIRECT_URL,
      loginId: JSON.parse("" + loggedInUser).data.login_id,
      username: getDataFromLocalStorage(LOGGED_IN_EMAIL),
      session_token: getDataFromLocalStorage(SESSION_TOKEN),
      applicationName: "Sport"
    }

    logoutUser(logoutPayload);
    removeDataFromLocalStorage(SESSION_TOKEN);
    removeDataFromLocalStorage(LOGGED_IN_EMAIL);
    removeDataFromLocalStorage(LOGGED_IN_UPDATE_EMAIL);
    removeDataFromLocalStorage(LOGGED_IN_USER);
    removeDataFromLocalStorage(REFRESH_TOKEN);
    removeDataFromLocalStorage(PERMISSIONS);
    removeDataFromLocalStorage(USER_OU);
    removeDataFromLocalStorage(IAM_USER_POLICY_ALL_RESOURCES_KEY);
    removeDataFromLocalStorage(CAPTIVE_ACCESS_ENABLED)
    removeDataFromLocalStorage(MAINTENANCE)
    removeDataFromLocalStorage(USER_PREFERENCES)

    updateSelectedVessels({isAll: true, vessels:[]});
    updatFilteredVessels([]);

    // This will clear everything of this app
    // localStorage.clear();
    history.push('/login');
  }

  const setValue = (selectecVesselList) => {
    setSeletedVessels(selectecVesselList);
  };

  const handleChangePassword = () => {
    history.push('/changePassword');
    handleClose()
  }

  const handleProfile = () => {
    history.push({ pathname: "/viewUser", state: { demo: getDataFromLocalStorage(LOGGED_IN_EMAIL) } });
    handleClose()
  }

  const handlePreferences = () => {
    handleClose()
    setIsPreferencesPopUpOpen(true)
  }

  const handleImgError = (host) => {
    if(host) {
      setImageError(true);
    }
  }

  const [isSvgError, setIsSvgError] = useState(false);
  const [isPngError, setIsPngError] = useState(false);

  const handleSvgError = () => {
    setIsSvgError(true);
  };

  const handlePngError = () => {
    setIsPngError(true);
  };

  return (
    <Box >
      <AppBar className="headerContainer menuScroll" position="fixed" >
        <Toolbar className="toolBarContainer"  >
          <Typography component="div">
          {authReducer.hostName && (
        <img
          onError={(event) => {
            if (!isSvgError) {
              handleSvgError();
            } else if (!isPngError) {
              handlePngError();
            }
          }}
          src={!isSvgError ? `https://k4storage.blob.core.windows.net/cdn/sportAssets/${authReducer.hostName}/headerLogo.svg` : (!isPngError ? `https://k4storage.blob.core.windows.net/cdn/sportAssets/${authReducer.hostName}/headerLogo.png` : HeaderLogo)}
          alt="K4 Mobility Logo"
          className="headerLogo"
        />
      )}
          </Typography>

          <div className="profileContainer menuScroll">
            {/* {isViewSUm && <SubHeaderSummary subHeaderSelectedDropdown={setValue} />} */}
             {/* {isDashBoard && <ToggleSwitch handleToggle={handleToggle}/>} */}
             <UTCClock />
             <Tooltip title={userName}>
              <Typography className="profileUserName" title={userName} onClick={handleMenu}
                style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", maxWidth: '112px'}}>{userName}</Typography>
             </Tooltip>
            <KeyboardArrowDownIcon onClick={handleMenu} className="logoutIcon" />
            <ReleaseNote className="releaseNoteIcon"/>
            <Maintainence className="releaseNoteIcon" />
          </div>
          <Menu id="menu-appbar" anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            className="menuList user-settings"
            style={{ top: "2px"}}
          >
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handlePreferences}>Preferences</MenuItem>
            <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      { isPreferencesPopUpOpen ? < Preferences isPopUpOpen={isPreferencesPopUpOpen} setIsPopUpOpen={setIsPreferencesPopUpOpen} /> : null }
    </Box>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer
})

export default withRouter(
  connect(mapStateToProps, {
    logoutUser, 
    updateSelectedVessels,
    updatFilteredVessels 
  })(Header)
);