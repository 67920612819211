import React, { useState, useEffect, Fragment, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";

import { Grid, Paper } from "@mui/material";
import Loader from "react-loader";

import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { SIDE_MENU_ITEM_GEO_MANAGEMENT } from "../../utils/constants";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, strToK4List, updateTimePeriod } from "../../utils/util";
import { fetchUserListAPI, updatePermission, updateSideMenu } from "../../actions/Users/authenticate";
import { Site, User } from "./types";
import { getDevices } from "../QuotaManagement/slice"
import { createGeoConfig, clearData } from "./slice";

import NotAuthorized from "../../UserScreen/NotAuthorized";
import "./index.css";
import GeoConfiguration from "./GeoConfiguration";
import AccountManagement from '../../asset/user_guide/Quota Kontrol_User_Guide.pdf';
import HelpIcon from '@mui/icons-material/Help';
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import InfoIcon from '@mui/icons-material/Info';

const USAGE_GEO_CONFIG = "view-vessel-summary";
const usageGeoConfigService = SERVICE_FUNCTION_TO_FEATURE[USAGE_GEO_CONFIG]["service"];
const usageGeoConfigFunction = SERVICE_FUNCTION_TO_FEATURE[USAGE_GEO_CONFIG]["function"];

const GeoManagement = (props: any): JSX.Element => {
    const { authReducer, history, location,match, errorReducer, getVesselsListing, filteredVessels, usersList, devices, geoConfigs, fetchUserListAPI, getDevices, updatePermission, updateSideMenu, clearData, creating, updating, deleting, gettingDevices, gettingGeoConfigs, gettingCurrentUsage } = props;
    const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
    const not_authorized_page_title = "GeoConfig Manager";
    const not_authorized_page_message = "You are not authorised to view geoConfig manager.";

    useEffect(() => {
        if ((authReducer && !authReducer.sideMenu) || (SIDE_MENU_ITEM_GEO_MANAGEMENT !== authReducer.sideMenu.menuItem)) {
            updateSideMenu({ menuItem: SIDE_MENU_ITEM_GEO_MANAGEMENT });
        }
        return () => {
            clearData();
        }
    }, [])

    const [sites, setSites] = useState<Site[]>([]);

    useEffect(() => {
        if (_.isEmpty(getVesselsListing)) return;
        let locations: any[] = [];
        if (_.isArray(filteredVessels) && filteredVessels.length > 0) {
            locations = filteredVessels;
        } else {
            const { k4Ids = '' } = getDecodeURI(location?.search);
            const k4IdsFromUrl = strToK4List(k4Ids);
            if (_.isArray(k4IdsFromUrl) && k4IdsFromUrl.length) {
                locations = k4IdsFromUrl;
            } else if (_.isArray(getVesselsListing.locations)) {
                locations = getVesselsListing.locations;
            }
        }
        const data = locations.map(site => ({
            id: site.id,
            name: site['vessel-name'],
        }))

        if (_.isEqual(data, sites)) {
            return;
        } else {
            setSites(data);
        }
    }, [getVesselsListing, filteredVessels]);
    
    useEffect(() => {
        if (sites && sites.length > 0) {
            let _sites = sites.map((site: Site) => site.id);
            getDevices({ sites: _sites })
        }
    }, [sites]);

    useEffect(() => {
        let authorized = checkLoggedInUserAuthorizedToViewPage(usageGeoConfigService, usageGeoConfigFunction);
        if (authorized && !authReducer.permission) {
            const info = {
                permission: {
                    service: usageGeoConfigService,
                    serviceFunction: usageGeoConfigFunction,
                },
                isMultiVessel: false,
                isSingleVessel: false,
                showOu: true,
                ou: authReducer.selectedOu ? authReducer.selectedOu : ''
            };
            updatePermission(info);
            setIsAuthorizedToViewPage(true);

            if (authReducer?.selectedOu?.name) {
                fetchUserListAPI(authReducer.selectedOu.name, 1, 1000000, '');
            }
        }
    }, [authReducer.selectedOu])

    const users = useMemo(() => {
        return authReducer?.usersList?.data?.records?.length > 0 ? _.orderBy(authReducer?.usersList?.data?.records?.map((u: any) => {
            let _u: User = {
                name: u?.name?.givenName + " " + u?.name?.familyName,
                email: u.userName
            };
            return _u;
        }).filter(
            (u: User) => u.name.trim() !== "" && u.email.trim() !== ""
        ), 'name', 'asc') : [];
    }, [usersList]);

    const handleSiteClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        row && row.k4Id ? params.k4Ids = `${row.k4Id}:${row.x}` : row && row.location_id ? params.k4Ids = `${row.location_id}:${row.site}`:  delete params.k4Ids;
        delete params.page;
        delete params.limit;
        delete params.sort;
        delete params.sortOrder;
        delete params.search;
        doNavigate(params);
      }

      const doNavigate = (pathname, params = {}) => {
        history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
      }

      const helpAction = () =>{
        window.open(AccountManagement)
      }

    return (
        <Fragment>
           <CommonFilters loading={authReducer.loading || creating || updating || deleting || gettingDevices || gettingGeoConfigs || authReducer?.userPreferencesLoading} helpIcon={false} helpAction={ helpAction} handleSiteClick={handleSiteClick} showWantType={false} showSummaryPeriod={false} userGuide={[true, 'quota-kontrol-user-guide']}></CommonFilters>
            <Paper elevation={0} square={true}>
                {
                    isAuthorizedToViewPage ?
                            <Grid item xs={12}>
                                <GeoConfiguration sites={sites} devices={devices} users={users} />
                            </Grid>
                        : <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
                }
            </Paper>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    getVesselsListing: state.authReducer?.getVesselsListing,
    filteredVessels: state.authReducer?.filteredVessels,
    usersList: state.authReducer.usersList,
    devices: state.quotaManager.devices,
    geoConfigs: state.geoConfigManager.geoConfigs,
    creating: state.geoConfigManager.creating,
    updating: state.geoConfigManager.updating,
    deleting: state.geoConfigManager.deleting,
    gettingDevices: state.quotaManager.gettingDevices,
    gettingGeoConfigs: state.geoConfigManager.gettingGeoConfigs,
    gettingCurrentUsage: state.geoConfigManager.gettingCurrentUsage,
});

export default withRouter(connect(mapStateToProps, {
    clearData,
    fetchUserListAPI,
    getDevices,
    createGeoConfig,
    updatePermission,
    updateSideMenu
})(GeoManagement));