import { Button, Grid, Tooltip } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { connect, useDispatch } from 'react-redux';
import { getUsageTableData } from "../../../actions/Users/authenticateUsageReports";
import { withRouter } from "react-router-dom";
import { getDecodeURI, readableBytesAsGB, getEncodedURI } from "../../../utils/util";
import { Pagination } from "../../Pagination/index";
import _ from "lodash";
import { TableBody, TableRow, TableCell, TableContainer, Table, TableHead, Paper } from "@mui/material";
import { UsageContext } from "../../../UserScreen/UserData/UsageDataContext";
import Download from '../../../asset/image/DownloadUsage.svg';
import RiskScrorePopup from "../RiskScorePopup";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import ImageWithFallback from "../../ImageWithFallback";

const TopUsageTable = (props) => {
    const { UsageTableData, getVesselsListing, handleChangePageUser, handleChangeRowsPerPageUser, page, limit, rowsOption, topUsersCount, setTopUsersCount, location, history, getUsageTableData } = props;
    const _q = getDecodeURI(location?.search);
    const { selectFilter, handleUsernameChange, setPage, setPageStart, setUserNameTrack, handleVlanFilterChange, vlanFilt, handleDeviceFilterChange, deviceFilt, handleTopUsageOnCsvDownload, checkUser, vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, sortUser, sortOrderUser, setSortUser, setSortOrderUser } = useContext(UsageContext);
    const [tableData, setTableData] = useState<any>([]);
    const [riskScrorePopup, setRiskScorePopup] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const TOTAL = 'total';
    const UPLOAD = 'upload';
    const DOWNLOAD = 'download';
    const THREAD = 'thread';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";

    useEffect(() => {
        if (getVesselsListing?.locations && getVesselsListing?.locations?.length == 0) {
            setTableData([]);
            setTopUsersCount(0)
            return
        }
        if (!_.isEmpty(UsageTableData)) {
            const data = UsageTableData?.hasOwnProperty('data') ? UsageTableData?.data : {};

            if (data) {
                let cols: any = [];
                let result: any = [];
                for (var key in data) {
                    cols.push(key);
                }

                data[cols[0]]?.data?.forEach((el, i) => {
                    let rowObj: any = {};
                    cols.forEach((ele) => {
                        rowObj[ele] = data[ele].data[i]
                    })
                    result.push(rowObj);
                })
                result && result?.length > 0 && result.shift();
                setTableData(result);
            }
        }
    }, [UsageTableData, getVesselsListing])

    useEffect(() => {
        if (!_.isEmpty(UsageTableData)) {
            const data = UsageTableData?.hasOwnProperty('data') ? UsageTableData?.data : 0;
            setTopUsersCount(data?.totalCount?.data[0])
        }
    }, [UsageTableData])

    const userNameClick = (e, user, userCheck) => {
        let filters: any = {};
        filters.filterUserName = user;
        filters.vlanFilter = vlanFilt;
        filters.deviceFilter = deviceFilt;
        filters.userCheck = userCheck;
        handleUsernameChange(null, user);
        setPage(1);
        setPageStart(1);
        selectFilter(filters);
        setUserNameTrack(true);
    }

    const siteNameClick = (e) => {
        let filters: any = {};
        filters.filterSite = e.target.innerText;
        filters.vlanFilter = vlanFilt;
        filters.deviceFilter = deviceFilt;
        filters.userCheck = checkUser;
        setPage(1);
        setPageStart(1);
        selectFilter(filters);
    }

    const vlanFilterClick = (row) => {
        let filters: any = {};
        const vlanName = row.vlanName;
        filters.vlanFilter = vlanName;
        filters.filterSite = row.site;
        filters.deviceFilter = deviceFilt;
        filters.userCheck = checkUser;
        handleVlanFilterChange(null, vlanName);
        setPage(1);
        setPageStart(1);
        selectFilter(filters);
    }

    const deviceFilterClick = (row) => {
        let filters: any = {};
        const device = row.deviceAliasName;
        filters.deviceFilter = device;
        handleDeviceFilterChange(null, device);
        setPage(1);
        setPageStart(1);
        selectFilter(filters);
    }

    const handleDownloadReport = () => {
        handleTopUsageOnCsvDownload(topUsersCount);
    }

    const handleClickThreat = (row) => {
       setSelectedRow(row);
       setRiskScorePopup(true);
    }

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sortUser = field;
        params.sortOrderUser = order;
        const Page = 1;
        setPage(1);
        setSortUser(field);
        setSortOrderUser(order);
        getUsageTableData(vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, Page, limit, vlanFilt, deviceFilt, checkUser, field, order);
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sortUser === field) {
            order = ASC_ORDER === sortOrderUser ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    function GetTopAppsImage (props)  {
        const { app } = props;
        if(app) {
          let appName = app.includes(' ') ? app.split(' ')[0]: app.includes('-') ? app.split('-')[0] : ""; 
          return <ImageWithFallback src={`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${app.trim()}`} fallbackSrcs={[`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${appName.trim()}`,"https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png"]} app={app} />
        } else {
          return <img className="logo-activity paddingLogo" src={`https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png`} />
        }
    
      }

    const tooltipContent = (row) => {
        const interfaceKeys = ["CELLULAR", "VSAT", "STARLINK", "LBAND", "ETHERNET", "WIFI", "BOND"];
        return <div>
            {interfaceKeys.map((key) => (
                <div key={key}>
                    <b>{key} :</b> {row[key] ? readableBytesAsGB(row[key]) : 0}
                </div>
            ))}
        </div>
    };

    return (<Grid item xs={12} sm={4} md={12} lg={12} className="Usage-TableComponent">
                <Grid item xs={12} sm={12} md={12} lg={12} className="topSites-download">
                    <Grid item xs={6} sm={6} md={6} lg={6} className="Reports-overView-topUsers align-text-center">Top Users</Grid>
                    {topUsersCount > 0 && <Grid item xs={6} sm={6} md={6} lg={6}>
                        <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                    </Grid>}
                </Grid>

                <Grid container spacing={0.5} className="TopUsersList-Reports users-activity-table">
                    <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">
                        <Grid className="Reports-graph_table">
                            {/* <Grid className="exportBtnClick">
                            <Button className="exportBtnClick-btn">Export</Button>
                            <img src={Download} alt="download" />
                        </Grid> */}
                        </Grid>
                        <Paper className="pagination-position config-pagination activity-usage">
                            <Pagination
                                rowsPerPageOptions={rowsOption}
                                count={topUsersCount?topUsersCount:0}
                                rowsPerPage={limit}
                                page={page}
                                onPageChange={handleChangePageUser}
                                onRowsPerPageChange={handleChangeRowsPerPageUser}
                            />
                        </Paper>
                        <Grid>
                            <TableContainer className="usageReports-FleetDashboard">
                                <Table aria-label="simple table">
                                    <TableHead className="usageReports-tableHead">
                                        <TableRow className="UsageReports-tableRow login-tableRow">
                                            <TableCell className="usage-fleetDataTable userName-fleetDataTable font-wt-900">Device Alias</TableCell>
                                            <TableCell align="left" className="usage-fleetDataTable ip_port-fleetDataTable font-wt-900">IP Address</TableCell>
                                            <TableCell className="usage-fleetDataTable ip_port-fleetDataTable font-wt-900">Mac Address</TableCell>
                                            <TableCell className="usage-fleetDataTable user-fleetDataTable font-wt-900">Site</TableCell>
                                            <TableCell className="usage-fleetDataTable alias-fleetDataTable font-wt-900 user-device">Device</TableCell>
                                            <TableCell className="usage-fleetDataTable vlan-fleetDataTable font-wt-900">VLAN</TableCell>
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                                    <span className="login-history-dp-name font-10px" onClick={(event) => switchSorting(event, TOTAL)}>Total(GB)</span>
                                                    <Grid className="sortUser-icon-margin">
                                                        <img src={sortUser === TOTAL && sortOrderUser === ASC_ORDER ? ascSortingSelected : AscSort}
                                                            className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, TOTAL, ASC_ORDER)} />
                                                        <img src={sortUser === TOTAL && sortOrderUser === DESC_ORDER ? descSortingSelected : DescSort}
                                                            className="sortingImages" onClick={(event) => setSorting(event, TOTAL, DESC_ORDER)} />
                                                    </Grid>
                                                </span>
                                            </TableCell>
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                                    <span className="login-history-dp-name font-10px" onClick={(event) => switchSorting(event, UPLOAD)}>Upload(GB)</span>
                                                    <Grid className="sortUser-icon-margin">
                                                        <img src={sortUser === UPLOAD && sortOrderUser === ASC_ORDER ? ascSortingSelected : AscSort}
                                                            className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, UPLOAD, ASC_ORDER)} />
                                                        <img src={sortUser === UPLOAD && sortOrderUser === DESC_ORDER ? descSortingSelected : DescSort}
                                                            className="sortingImages" onClick={(event) => setSorting(event, UPLOAD, DESC_ORDER)} />
                                                    </Grid>
                                                </span>
                                            </TableCell>
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                                    <span className="login-history-dp-name font-10px" onClick={(event) => switchSorting(event, DOWNLOAD)}>Download(GB)</span>
                                                    <Grid className="sortUser-icon-margin">
                                                        <img src={sortUser === DOWNLOAD && sortOrderUser === ASC_ORDER ? ascSortingSelected : AscSort}
                                                            className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DOWNLOAD, ASC_ORDER)} />
                                                        <img src={sortUser === DOWNLOAD && sortOrderUser === DESC_ORDER ? descSortingSelected : DescSort}
                                                            className="sortingImages" onClick={(event) => setSorting(event, DOWNLOAD, DESC_ORDER)} />
                                                    </Grid>
                                                </span>
                                            </TableCell>
                                            <TableCell className="usage-fleetDataTable topApp-sitestable topApp-sitestable-user font-wt-900">Top Apps</TableCell>
                                            <Tooltip title={'Top 5% Risk Score'}>
                                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                                        <span className="login-history-dp-name font-10px" onClick={(event) => switchSorting(event, THREAD)}>Top Risk</span>
                                                        <Grid className="sortUser-icon-margin">
                                                            <img src={sortUser === THREAD && sortOrderUser === ASC_ORDER ? ascSortingSelected : AscSort}
                                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, THREAD, ASC_ORDER)} />
                                                            <img src={sortUser === THREAD && sortOrderUser === DESC_ORDER ? descSortingSelected : DescSort}
                                                                className="sortingImages" onClick={(event) => setSorting(event, THREAD, DESC_ORDER)} />
                                                        </Grid>
                                                    </span>
                                                </TableCell>
                                            </Tooltip>
                                        </TableRow>
                                    </TableHead>
                                    {0 != tableData.length ? (
                                        <TableBody className="tableBody usage-tableBody">
                                            {
                                                tableData && tableData.map((row, i) =>
                                                    <TableRow key={i} className="usageData-tabelRow">
                                                        { row.hostname !== 'NA' ? 
                                                            <TableCell className="usagedata-tableCell selected-site" onClick={(e)=>userNameClick(e, row.hostname, 'hostname')}>{row.captiveFlag ? `${row.hostname}*` : row.hostname}</TableCell>
                                                            :
                                                            <TableCell className="usagedata-tableCell">{row.hostname}</TableCell>
                                                        }
                                                        <TableCell className="usagedata-tableCell selected-site" onClick={(e)=>userNameClick(e, row.ipAddress, 'ipAddress')}>{row.ipAddress}</TableCell>
                                                        <TableCell className="usagedata-tableCell selected-site" onClick={(e)=>userNameClick(e, row.macAddress, 'macAddress')}>{row.macAddress}</TableCell>
                                                        <TableCell className="usagedata-tableCell selected-site" onClick={(e)=>siteNameClick(e)}>{row.site}</TableCell>
                                                        <TableCell className="usagedata-tableCell">{row.deviceAliasName}</TableCell>
                                                        <TableCell className="usagedata-tableCell selected-site" onClick={() =>vlanFilterClick(row)}>{row.vlanName}</TableCell>
                                                        <TableCell className="usagedata-tableCell">
                                                            <Tooltip title={tooltipContent(row)} arrow placement="right">
                                                                <span>{readableBytesAsGB(row.total, false)}</span>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell className="usagedata-tableCell">{readableBytesAsGB(row.upload, false)}</TableCell>
                                                        <TableCell className="usagedata-tableCell">{readableBytesAsGB(row.download, false)}</TableCell>
                                                        <TableCell className="usageData-tabelCell">
                                                            <Grid className="tableSites-topIcons">
                                                                { tableData[i]?.topApps?.split(',')?.map((app) => (
                                                                    <Tooltip title={app}>
                                                                        <GetTopAppsImage app={app} />
                                                                    </Tooltip>
                                                                ))}
                                                            </Grid>
                                                        </TableCell>
                                                        <Tooltip title={row.thread && 'Click to get the Risk type'}>
                                                            <TableCell className={row.thread ? "usagedata-tableCell risk-score" : 'usagedata-tableCell'} onClick={() => row.thread && handleClickThreat(row)}>
                                                                {row.thread}
                                                            </TableCell>
                                                        </Tooltip>
                                                    </TableRow>
                                                )
                                            }
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={9} className="noDataAvailableCss" align="center">No data available</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
                { riskScrorePopup ? <RiskScrorePopup selectedRow={selectedRow} open={riskScrorePopup} setOpen={setRiskScorePopup} /> : null }
    </Grid>
    )
}

const mapStateToProps = (state) => ({
    UsageTableData: state.authReducer?.UsageTableData,
    getVesselsListing: state?.authReducer?.getVesselsListing
});

export default withRouter(
    connect(mapStateToProps, {
        getUsageTableData
    })(TopUsageTable)
);