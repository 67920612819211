import { useEffect, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, updateTimePeriod } from "../../utils/util";
import { SIDE_MENU_ITEM_CAPTIVE } from "../../utils/constants";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters"
import NotAuthorized from "../NotAuthorized"
import { updatePermission } from "../../actions/Users/authenticate";
import _ from "lodash";
import TabsComponent from "../../components/NewTabs";
import CaptiveTopWidget from './captiveComponent/topWidget';
import CaptiveCharts from './captiveComponent';
import Table from './captiveComponent/Table';
import SubscriberTable from './captiveComponent/SubscriberTable';
import PlansCharts from './captivePlans/LineChart';
import PlansTable from './captivePlans/PlansTable';
import ChipFilters from './captiveComponent/ChipFilters';
import FilterPopup from './captiveComponent/FilterPopup';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Button, Grid, Menu } from "@mui/material";
import { getCaptiveDashTopSitesUsageBar, getCaptiveDashTopSitesSubscribersBar, getCaptiveDashTopSitesSessionsBar, getCaptiveDashTopSubscribersUsageBar, getCaptiveDashTopSubscribersSessionsBar, getCaptiveDashUsageLineChart, getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashSitesTable,
  getCaptiveDashSubscribersSessionsTable, getCaptiveDashTopPlansUsersPie, getCaptiveDashPlansPercentageUsageCountBar, getCaptiveDashPlanTable, getCaptiveDashEventsTable, getCaptiveDashSitesSubscribersSessionsTopWidget, getCaptiveDashPlansTopWidget, getCaptiveDashUsageTopWidget, getCaptiveDashPlansUsageLineCharts } from "../../actions/Users/authenticateCaptiveDahshboard";
import EventsTable from "./captiveComponent/EventsTable";
import moment from "moment";

const CaptiveDashboard = (props) => {
  const { authReducer, newSummaryStartDate, newSummaryEndDate, updatePermission, history, location, getCaptiveDashTopSitesUsageBar, getCaptiveDashTopSitesSubscribersBar, getCaptiveDashTopSitesSessionsBar, getCaptiveDashTopSubscribersUsageBar, getCaptiveDashTopSubscribersSessionsBar, getCaptiveDashUsageLineChart,
    getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashSitesTable, getCaptiveDashSubscribersSessionsTable, getCaptiveDashTopPlansUsersPie, getCaptiveDashPlansPercentageUsageCountBar, getCaptiveDashPlanTable, getCaptiveDashEventsTable, getCaptiveDashSitesSubscribersSessionsTopWidget, getCaptiveDashPlansTopWidget, getCaptiveDashUsageTopWidget, getCaptiveDashPlansUsageLineCharts } = props;

  const _q = getDecodeURI(location?.search);
  const [selectedTab, setSelectedTab] = useState(_q.hasOwnProperty('tab') ? _q.tab : "sites");
  const [loading, setLoading] = useState();
  const [vessels, setVessels] = useState<any>();
  const [topcardSelected, setTopCardSelected] = useState(_q.hasOwnProperty('topCard') ? _q.topCard : '')
  const dispatch = useDispatch();
  const [filterPopup, setFilterPopup] = useState(false);
  const [anchorElRef, setAnchorElRef] = useState<HTMLElement>();
  const getInterval = (minutes) => {
    if (minutes <= 720) {
        return "5 minutes";
    } else if (minutes > 720 && minutes <= 1440) {
        return "15 minutes";
    } else if (minutes > 1440 && minutes <= 10080) {
        return "1 hour";
    } else {
        return "12 hour";
    }
}
  const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
  const VIEW_VESSEL_SUMMARY = "view-vessel-summary";
  const not_authorized_page_title = "Dashboard";
  const not_authorized_page_message = "You are not authorised to view site dashboard.";

  const viewVesselSummaryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
  const viewVesselSummaryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];

  const [selectedSubscribers, setSelectedSubscribers] = useState<any>(_q.hasOwnProperty('subscribers') ? _q.subscribers.split(',') : []);
  const [selectedPlans, setSelectedPlans] = useState<any>(_q.hasOwnProperty('selectedPlans') ? _q.selectedPlans.split(',') : []);
  const [selectedUserNames, setSelectedUserNames] = useState(_q.hasOwnProperty('subscribers') ? _q.subscribers.split(',') : []);
  const [selectedIps, setSelectedIps] = useState(_q.hasOwnProperty('ipAddress') ? _q.ipAddress.split(',') : []);
  const [selectedMacs, setSelectedMacs] = useState(_q.hasOwnProperty('macAddress') ? _q.macAddress.split(',') : []);
  const [selectedEventActions, setSelectedEventActions] = useState(_q.hasOwnProperty('actions') ? _q.actions.split(',') : []);
  const [selectedEventTypes, setSelectedEventTypes] = useState(_q.hasOwnProperty('types') ? _q.types.split(',') : []);
  const [selectedPlanDurations, setSelectedPlanDurations] = useState(_q.hasOwnProperty('durations') ? _q.durations.split(',') : []);
  const [selectedPlanQuota, setSelectedPlanQuota] = useState(_q.hasOwnProperty('quotas') ? _q.quotas.split(',') : []);
  const [selectedPlanStatus, setSelectedPlanStatus] = useState(_q.hasOwnProperty('status') ? _q.status : '');
  const [selectedPlanStartFrom, setSelectedPlanStartFrom] = useState<any>(_q.hasOwnProperty('planStartFrom') ? moment.utc(parseInt(_q.planStartFrom)) : null);
  const [selectedPlanStartTo, setSelectedPlanStartTo] = useState<any>(_q.hasOwnProperty('planStartTo') ? moment.utc(parseInt(_q.planStartTo)) : null);
  const [selectedPlanEndFrom, setSelectedPlanEndFrom] = useState<any>(_q.hasOwnProperty('planEndFrom') ? moment.utc(parseInt(_q.planEndFrom)) : null);
  const [selectedPlanEndTo, setSelectedPlanEndTo] = useState<any>(_q.hasOwnProperty('planEndTo') ? moment.utc(parseInt(_q.planEndTo)) : null);
  const [planAutoRenewal, setPlanAutoRenewal] = useState(_q.hasOwnProperty('autoRenewal') ? true : false);

  const values = { selectedSubscribers, selectedPlans, selectedUserNames, selectedIps, selectedMacs, selectedEventActions, selectedEventTypes, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal };
  const setValues = { setSelectedSubscribers, setSelectedPlans, setSelectedUserNames, setSelectedIps, setSelectedMacs, setSelectedEventActions, setSelectedEventTypes, setSelectedPlanDurations, setSelectedPlanQuota, setSelectedPlanStatus, setSelectedPlanStartFrom, setSelectedPlanStartTo, setSelectedPlanEndFrom, setSelectedPlanEndTo, setPlanAutoRenewal };

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_CAPTIVE !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_CAPTIVE };
  }

  useEffect(() => {
    let authorized = checkLoggedInUserAuthorizedToViewPage(viewVesselSummaryService, viewVesselSummaryFunction);
    setIsAuthorizedToViewPage(authorized);
    const info = {
      permission: {
        service: viewVesselSummaryService,
        serviceFunction: viewVesselSummaryFunction,
      },
      isMultiVessel: false,
      isSingleVessel: false,
      showOu: false,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };

    if (authorized) {
      info.isMultiVessel = true;
      info.showOu = true;
    }

    updatePermission(info);

    const params = getDecodeURI(location?.search);
    setSelectedTab(!params.tab ? 'sites' : params.tab)
    setTopCardSelected(!params.topCard ? 'TOTAL USAGE' : params.topCard)
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    params.startDate = startDate;
    params.endDate = endDate;
    !params.tab ? params.tab = 'sites' : params.tab = params.tab;
    !params.topCard ? params.topCard = 'TOTAL USAGE' : params.topCard = params.topCard;
    history.push({ pathname: location.pathname, search: getEncodedURI(params) });
  }, []);

  useEffect(() => {
    setLoading(authReducer.setCaptiveDashSitesSubscribersSesTopWidgetLoading || authReducer.setCaptiveDashPlansTopWidgetLoading || authReducer.setCaptiveDashUsageTopWidgetLoading ||
      authReducer.setCaptiveDashSitesUsageBarLoading || authReducer.setCaptiveDashSubscribersLineChartLoading ||
      authReducer.setCaptiveDashSessionsLineChartLoading || authReducer.setCaptiveDashUsageLineChartLoading || authReducer.setCaptiveDashSitesTableLoading ||
      authReducer.setCaptiveDashTopSitesSubscribersBarLoading || authReducer.setCaptiveDashTopSitesSessionsBarLoading ||
      authReducer.setCaptiveDashSubscribersSessionsTableLoading || authReducer.setCaptiveDashTopPlansUsersPieLoading ||
      authReducer.setCaptiveDashEventsSubscribersPlanUsageTableLoading ||
      authReducer.setCaptiveDashTopSubscribersUsageBarLoading || authReducer.setCaptiveDashTopSubscribersSessionsBarLoading || authReducer.setCaptiveDashPlansPercentageUsageCountBarLoading ||
      authReducer.setCaptiveDashPlansUsageLineChartLoading || authReducer.setCaptiveDashSubscribersListLoading || authReducer.setCaptiveDashPlansListLoading || authReducer.setCaptiveDashUserSessionsTableLoading || authReducer.setCaptiveDashEventsTableLoading || authReducer.setCaptiveDashIpMacListLoading)
  }, [authReducer.setCaptiveDashSitesSubscribersSesTopWidgetLoading, authReducer.setCaptiveDashPlansTopWidgetLoading, authReducer.setCaptiveDashUsageTopWidgetLoading,
  authReducer.setCaptiveDashSitesUsageBarLoading, authReducer.setCaptiveDashSubscribersLineChartLoading, authReducer.setCaptiveDashSessionsLineChartLoading,
  authReducer.setCaptiveDashUsageLineChartLoading, authReducer.setCaptiveDashSitesTableLoading, authReducer.setCaptiveDashTopSitesSubscribersBarLoading, authReducer.setCaptiveDashTopSitesSessionsBarLoading,
  authReducer.setCaptiveDashSubscribersSessionsTableLoading, authReducer.setCaptiveDashTopPlansUsersPieLoading,
  authReducer.setCaptiveDashEventsSubscribersPlanUsageTableLoading, authReducer.setCaptiveDashTopSubscribersUsageBarLoading,
  authReducer.setCaptiveDashTopSubscribersSessionsBarLoading, authReducer.setCaptiveDashPlansPercentageUsageCountBarLoading, authReducer.setCaptiveDashPlansUsageLineChartLoading,
  authReducer.setCaptiveDashSubscribersListLoading, authReducer.setCaptiveDashPlansListLoading, authReducer.setCaptiveDashUserSessionsTableLoading, authReducer.setCaptiveDashEventsTableLoading, authReducer.setCaptiveDashIpMacListLoading])

  const tabs = [
    { tabName: 'Sites', tabValue: 'sites', pathName: '/captive' },
    { tabName: 'Subscribers', tabValue: 'subscribers', pathName: '/captive' },
    { tabName: 'Plans', tabValue: 'plans', pathName: '/captive' },
    { tabName: 'Events', tabValue: 'events', pathName: '/captive' },
    // { tabName: 'Admin', tabValue: 'admin', pathName: '/captive' }
  ]

  const doNavigateTab = (pathname: any, tabName: any) => {
    const currentParams = getDecodeURI(location?.search);
    _q.subscribers ? setSelectedSubscribers(_q.subscribers?.split(',')) : setSelectedSubscribers([]);
    _q.subscribers ? setSelectedUserNames(_q.subscribers?.split(',')) : setSelectedUserNames([]);
    _q.selectedPlans ? setSelectedPlans(_q.selectedPlans?.split(',')) : setSelectedPlans([]);
    setSelectedTab(tabName);
    setTopCardSelected('TOTAL USAGE');
    clearFilters();
    currentParams.tab = tabName;
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    currentParams.startDate = startDate;
    currentParams.endDate = endDate;
    delete currentParams.page;
    delete currentParams.limit;
    delete currentParams.sort;
    delete currentParams.sortOrder;
    currentParams.topCard = 'TOTAL USAGE';
    delete currentParams.durations;
    delete currentParams.quotas;
    delete currentParams.status;
    delete currentParams.planStartFrom;
    delete currentParams.planStartTo;
    delete currentParams.planEndFrom;
    delete currentParams.planEndTo;
    delete currentParams.autoRenewal;
    delete currentParams.ipAddress;
    delete currentParams.macAddress;
    delete currentParams.actions;
    delete currentParams.types;
    history.push({ pathname, search: getEncodedURI(currentParams) });
  }

  useEffect(() => {
    if (_.isEmpty(authReducer?.getVesselsListing))
      return;
    let vessels = [];
    const filteredVessels = authReducer?.filteredVessels;
    if (filteredVessels && filteredVessels.length > 0) {
      vessels = filteredVessels?.filter((el) => el?.id !== "");
    } else {
      vessels = authReducer?.getVesselsListing?.locations;
    }
    setVessels(vessels);
  }, [authReducer?.getVesselsListing, authReducer.filteredVessels])

  const handleClickFilterPopup = () => {
    setFilterPopup(true);
  }

  const filterPopClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElRef(e.currentTarget)
    handleClickFilterPopup();
  }

  const applyFilterClick = (subscribers, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal, userNames, ips, macs, actions, types) => {
    let params: any = getDecodeURI(location.search);
    delete params.page;
    if(_q.tab == 'sites' || _q.tab == 'subscribers'){
      !_.isEmpty(subscribers) ? params.subscribers = subscribers?.join(',') : delete params.subscribers;
    } 
    !_.isEmpty(plans) ? params.selectedPlans = plans?.join(',') : delete params.selectedPlans;
    if(_q.tab == 'events') {
      !_.isEmpty(userNames) ? params.subscribers = userNames?.join(',') : delete params.subscribers;
    } 
    !_.isEmpty(ips) ? params.ipAddress = ips?.join(',') : delete params.ipAddress;
    !_.isEmpty(macs) ? params.macAddress = macs?.join(',') : delete params.macAddress;
    !_.isEmpty(actions) ? params.actions = actions?.join(',') : delete params.actions;
    !_.isEmpty(types) ? params.types = types?.join(',') : delete params.types;
    !_.isEmpty(durations) ? params.durations = durations?.join(',') : delete params.durations;
    !_.isEmpty(quotas) ? params.quotas = quotas?.join(',') : delete params.quotas;
    status ? params.status = status : delete params.status;
    startFrom != null ? params.planStartFrom = startFrom.valueOf() : delete params.planStartFrom;
    startTo != null ? params.planStartTo = startTo.valueOf() : delete params.planStartTo;
    endFrom != null ? params.planEndFrom = endFrom.valueOf() : delete params.planEndFrom;
    endTo != null ? params.planEndTo = endTo.valueOf() : delete params.planEndTo;
    autoRenewal ? params.autoRenewal = true : delete params.autoRenewal;

    history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    const _interval = getInterval(
      Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)
    )
    const sortColumn = _q.hasOwnProperty('sort') ? _q.sort : 'subscriberCount';
    const sortOrder = _q.hasOwnProperty('sortOrder') ? _q.sortOrder : 'Desc';
    const tabToChartMap = {
      'sites': {
        'SUBSCRIBERS': () => getCaptiveDashTopSitesSubscribersBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, 1, subscribers),
        'SESSIONS': () => getCaptiveDashTopSitesSessionsBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, 1, subscribers),
        'TOTAL USAGE': () => getCaptiveDashTopSitesUsageBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, 1, subscribers),
      },
      'subscribers': {
        'TOTAL USAGE': () => getCaptiveDashTopSubscribersUsageBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, 1, subscribers, plans),
        'SESSIONS': () => getCaptiveDashTopSubscribersSessionsBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, 1, subscribers, plans)
      },
      'plans': {
        'TOTAL USAGE': () => getCaptiveDashTopPlansUsersPie(vessels, newSummaryStartDate, newSummaryEndDate, 10, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal)
      }
    };
    tabToChartMap[_q.tab]?.[_q.topCard]?.();

    const tabToLineChartMap = {
      'sites': {
        'SUBSCRIBERS': () => getCaptiveDashSubscribersLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, subscribers, plans),
        'SESSIONS': () => getCaptiveDashSessionsLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, subscribers, plans),
        'TOTAL USAGE': () => getCaptiveDashUsageLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, subscribers, plans),
      },
      'subscribers': {
        'SESSIONS': () => getCaptiveDashSessionsLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, subscribers, plans),
        'TOTAL USAGE': () => getCaptiveDashUsageLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, subscribers, plans),
      },
      'plans': {
        'TOTAL USAGE': _q.planChartView === 'column' ? 
        () => getCaptiveDashPlansPercentageUsageCountBar(vessels, newSummaryStartDate, newSummaryEndDate, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal) :
        () => getCaptiveDashPlansUsageLineCharts(vessels, newSummaryStartDate, newSummaryEndDate, _interval, 10, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal)
      },
    };
    tabToLineChartMap[_q.tab]?.[_q.topCard]?.();

    const _searchValues = _q.hasOwnProperty('search') ? _q.search : '';
    const tabToTableMap = {
      'sites': () => getCaptiveDashSitesTable(vessels, newSummaryStartDate, newSummaryEndDate, 1, 15, sortColumn, sortOrder, _searchValues, subscribers),
      'subscribers': () => getCaptiveDashSubscribersSessionsTable(vessels, newSummaryStartDate, newSummaryEndDate, 1, 15, 'totalSessions', sortOrder, _searchValues, subscribers, plans),
      'plans': () => getCaptiveDashPlanTable(vessels, newSummaryStartDate, newSummaryEndDate, 1, 15, 'subscribersCount', 'desc', _searchValues, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal),
      'events': () => getCaptiveDashEventsTable(vessels, newSummaryStartDate, newSummaryEndDate, 1, 15, 'eventTime', 'desc', _searchValues, userNames, ips, macs, actions, types)
    };
    tabToTableMap[_q.tab]?.();

    if(selectedTab != 'events') {
      getCaptiveDashSitesSubscribersSessionsTopWidget(vessels, newSummaryStartDate, newSummaryEndDate, subscribers, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal);
      getCaptiveDashPlansTopWidget(vessels, newSummaryStartDate, newSummaryEndDate, subscribers, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal);
      getCaptiveDashUsageTopWidget(vessels, newSummaryStartDate, newSummaryEndDate, subscribers, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal);
    }
  }

  const clearFilters = () => {
    setSelectedPlanDurations([]);
    setSelectedPlanQuota([]);
    setSelectedPlanStatus('');
    setSelectedPlanStartFrom(null);
    setSelectedPlanStartTo(null);
    setSelectedPlanEndFrom(null);
    setSelectedPlanEndTo(null);
    setPlanAutoRenewal(false);
    setSelectedIps([]);
    setSelectedMacs([]);
    setSelectedEventActions([]);
    setSelectedEventTypes([]);
  }

  return (
    <div className="Form">
      <CommonFilters loading={loading} showWantType={false}></CommonFilters>
      <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={selectedTab} />
      <Grid key="dashboard" container style={{ backgroundColor: "#ffffff" }}>
        <Grid key="fleet" item className="threat-dashboard-NewSummary captive-dashboard-NewSummary">
          {
            isAuthorizedToViewPage ? [
              <Grid>
                {<Box className='activityReports-Filter'>
                  <Fragment>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="filter-dateRange-container captive-dashboard-filters-container">
                      <Button variant="outlined" startIcon={<TuneIcon id="filterIcon" />} onClick={filterPopClick} id="filterButton">
                        Filter
                      </Button>
                      <ChipFilters applyFilterClick={applyFilterClick} values={values} setValues={setValues} />
                    </Grid>
                    <Menu disableEnforceFocus
                      onClose={() => setFilterPopup(false)}
                      open={filterPopup}
                      className="rc--filter_menu_container_reports captive-reports-filters-popup-conatiner"
                      anchorEl={anchorElRef}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Grid className="usageFilterComponent">
                        <FilterPopup vessels={vessels} filterPopup={filterPopup} setFilterPopup={setFilterPopup} values={values} setValues={setValues} applyFilterClick={applyFilterClick} clearFilters={clearFilters} />
                      </Grid>
                    </Menu>
                  </Fragment>
                </Box>}
                <Grid key="SubHeader" className="SubHeader_NewSummary padding-right-17" id={selectedTab != 'events' ? 'captive-dashboard-contents' : ''}>
                  {selectedTab !== 'events' && <CaptiveTopWidget vessels={vessels} topcardSelected={topcardSelected} setTopCardSelected={setTopCardSelected} selectedTab={selectedTab} values={values} />}
                  {selectedTab == 'sites' || selectedTab == 'subscribers' ? <CaptiveCharts vessels={vessels} topcardSelected={topcardSelected} selectedTab={selectedTab} values={values} setValues={setValues} applyFilterClick={applyFilterClick} /> : selectedTab == 'plans' ? <PlansCharts vessels={vessels} values={values} setValues={setValues} applyFilterClick={applyFilterClick} /> : null}
                  {selectedTab == 'subscribers' ? <SubscriberTable vessels={vessels} values={values} setValues={setValues} applyFilterClick={applyFilterClick} /> : selectedTab == 'sites' ? <Table vessels={vessels} values={values} /> : selectedTab == 'events' ? <EventsTable vessels={vessels} values={values} setValues={setValues} applyFilterClick={applyFilterClick} /> : <PlansTable vessels={vessels} values={values} setValues={setValues} applyFilterClick={applyFilterClick} />}
                </Grid>
              </Grid>
            ] :
              <Grid key="noitems" item container style={{ position: "fixed", paddingBottom: "16px", backgroundColor: "#ffffff", zIndex: 10, paddingTop: "80px" }}>
                <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
              </Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  newSummaryStartDate: state.authReducer.newSummaryStartDate,
  newSummaryEndDate: state.authReducer.newSummaryEndDate,
});

export default withRouter(
  connect(mapStateToProps, { updatePermission, getCaptiveDashTopSitesUsageBar, getCaptiveDashTopSitesSubscribersBar, getCaptiveDashTopSitesSessionsBar, getCaptiveDashTopSubscribersUsageBar, getCaptiveDashTopSubscribersSessionsBar, getCaptiveDashUsageLineChart,getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashSitesTable,
    getCaptiveDashSubscribersSessionsTable, getCaptiveDashTopPlansUsersPie, getCaptiveDashPlansPercentageUsageCountBar, getCaptiveDashPlanTable, getCaptiveDashEventsTable, getCaptiveDashSitesSubscribersSessionsTopWidget, getCaptiveDashPlansTopWidget, getCaptiveDashUsageTopWidget, getCaptiveDashPlansUsageLineCharts })(CaptiveDashboard)
);