import React, { useState, useEffect, Fragment, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import { SIDE_MENU_ITEM_GEO_MANAGEMENT } from "../../utils/constants";
import { getDecodeURI, getEncodedURI, strToK4List, updateTimePeriod } from "../../utils/util";
import { updateSideMenu } from "../../actions/Users/authenticate";
import { getDevices } from "../QuotaManagement/slice"
import "./index.css";
import TabsComponent from "../../components/NewTabs";
import QuotaManagement from "./../QuotaManagement"
import GeoManagement from "../GeoManagement";
import GeoLogs from "./../GeoManagement/GeoLogs";

const KontrolManagement = (props: any): JSX.Element => {
    const { authReducer, history, location, match} = props;

    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState(match?.params?.tabs ? match.params.tabs : "quota_configuration");

    const tabs = [
        { tabName: 'Quota', tabValue: 'quota_configuration', pathName: '/kontrol/quota_configuration' },
        { tabName: 'Geo', tabValue: 'geo_configuration', pathName: '/kontrol/geo_configuration' },
        { tabName: 'Logs', tabValue: 'logs', pathName: '/kontrol/logs' }
    ]

    if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_GEO_MANAGEMENT !== authReducer.sideMenu.menuItem)) {
        authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_GEO_MANAGEMENT };
    }


    const doNavigateTab = (pathname, tabName) => {
        const currentParams = getDecodeURI(location?.search);
        const params:any = {};
        if (currentParams.ouName) {
            params['ouName'] = currentParams.ouName;
        }
        if (currentParams.ouId) {
            params['ouId'] = currentParams.ouId;
        }
        if (currentParams.k4Ids) {
            params['k4Ids'] = currentParams.k4Ids;
        }
        if(currentParams.sideNav){
            params['sideNav'] = currentParams.sideNav
        }
        if(currentParams.interval) {
            params['interval'] = currentParams.interval
        }
        const { startDate, endDate } = updateTimePeriod(dispatch, location);
        params.startDate = startDate;
        params.endDate = endDate;
        params.tab = tabName;
        history.push({ pathname, search: getEncodedURI(params) });
        setActiveTab(tabName);
    }

    return (
        <Fragment>
            <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={activeTab} />
            {
                activeTab == 'geo_configuration' ?  
                    <GeoManagement></GeoManagement>  : 
                activeTab == "quota_configuration" ? 
                    <QuotaManagement></QuotaManagement> : 
                    <GeoLogs {...props}/>}
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    getVesselsListing: state.authReducer?.getVesselsListing,
    filteredVessels: state.authReducer?.filteredVessels,
    usersList: state.authReducer.usersList,
    devices: state.quotaManager.devices,
});

export default withRouter(connect(mapStateToProps, {
    getDevices,
    updateSideMenu
})(KontrolManagement));