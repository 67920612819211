import React, { useEffect, useMemo, useRef, useState } from 'react';
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Box, Button, Grid, InputAdornment, Paper, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { Device, GeoConfig, GeoConfigSortBy, Site, SortOrder, User, GeoConfigHistory } from '../types';
import { EditableGeoConfigRow } from '../EditableGeoConfigRow';
import { createGeoConfig, deleteGeoConfig, getGeoConfigs, getGeoConfigsParameters, updateGeoConfig, toggleGeoConfig, updateGeoConfigParameters, ACTION_TYPES } from '../slice';
import { DeleteGeoConfigConfirmationDialog } from '../DeleteGeoConfigConfirmDialog';
import { Pagination } from '../../../components/Pagination';
import GeoConfigDialog from '../GeoConfigDialog';
import './index.css';
import { getDecodeURI, getEncodedURI, checkLoggedInUserAuthorizedToViewPage } from '../../../utils/util';
import SortArrows from '../../../components/SortArrows';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { fetchUserListAPI, updatePermission } from "../../../actions/Users/authenticate";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../../config";

export interface DashboardProps {
    sites: Site[];
    devices: Device[];
    users: User[];
    geoConfigs: GeoConfig[];
    totalGeoConfigs: number;
    gettingGeoConfigs: boolean;
    authReducer?: any;
    getGeoConfigs: (parameters: getGeoConfigsParameters) => any;
    deleteGeoConfig: (parameters: any) => any;
    createGeoConfig: (parameters: any) => any;
    updateGeoConfig: (parameters: updateGeoConfigParameters) => any;
    toggleGeoConfig: (parameters: any) => any;
    isReadOnlyUser: boolean;
    updatePermission:any;
}

const USAGE_GEO_CONFIG = "view-vessel-summary";
const usageGeoConfigService = SERVICE_FUNCTION_TO_FEATURE[USAGE_GEO_CONFIG]["service"];
const usageGeoConfigFunction = SERVICE_FUNCTION_TO_FEATURE[USAGE_GEO_CONFIG]["function"];


function GeoConfiguration(props: DashboardProps) {
    const rowsOption = [10, 15, 25, 50, 100];

    const { sites, devices, users, geoConfigs, totalGeoConfigs, getGeoConfigs, createGeoConfig, updateGeoConfig, deleteGeoConfig, toggleGeoConfig, authReducer, isReadOnlyUser, updatePermission } = props;

    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch();
    const searchTimeoutRef = useRef<NodeJS.Timeout>();

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [toDeleteGeoConfig, setToDeleteGeoConfig] = React.useState<GeoConfig | null>(null);
    const [toUpdateGeoConfig, setToUpdateGeoConfig] = React.useState<GeoConfig | null>(null);
    const [openGeoConfigConfigDialog, setOpenGeoConfigConfigDialog] = React.useState<boolean>(false);
    const [geoConfigDialogHeading, setGeoConfigDialogHeading] = React.useState<string>("Add Geofence Rule(MCC)");

    const [searchText, setSearchText] = React.useState<string>(queryParams.get('search') || '');
    const [paginationParams, setPaginationParams] = React.useState<{
        page: number;
        size: number;
        sortBy: GeoConfigSortBy;
        sortOrder: SortOrder;
        search: string;
    }>({
        page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : 10,
        sortBy: queryParams.get('sortBy') as GeoConfigSortBy || 'created_at',
        sortOrder: queryParams.get('sortOrder') as SortOrder || 'desc',
        search: queryParams.get('search') || ''
    });
    
    useEffect(() => {
        updateQueryParams();
        refreshGeoConfigs();
    }, [paginationParams, sites]);

    useEffect(() => {
        let authorized = checkLoggedInUserAuthorizedToViewPage(usageGeoConfigService, usageGeoConfigFunction);
        if (authorized && !authReducer.permission) {
            const info = {
                permission: {
                    service: usageGeoConfigService,
                    serviceFunction: usageGeoConfigFunction,
                },
                isMultiVessel: false,
                isSingleVessel: false,
                showOu: true,
                ou: authReducer.selectedOu ? authReducer.selectedOu : ''
            };
            updatePermission(info);

            if (authReducer?.selectedOu?.name) {
                fetchUserListAPI(authReducer.selectedOu.name, 1, 1000000, '');
            }
        }
    }, [authReducer.selectedOu])

    const refreshGeoConfigs = (_searchText: string | undefined = undefined) => {
        if (_.isEmpty(sites)) {
            dispatch({
                type: ACTION_TYPES.GET_GEO_CONFIGS, payload: {
                    data: {
                        rows: [
                            [[], 0]
                        ]
                    }
                }
            })
            return;
        }
        setTimeout(() => {
            getGeoConfigs({ dpId: authReducer.selectedOu.id, searchText: paginationParams.search, sortBy: paginationParams.sortBy, sortOrder: paginationParams.sortOrder, limit: paginationParams.size, offset: (paginationParams.page - 1) * paginationParams.size })
        }, 1000);
    }

    const handleCreateGeoConfig = async (dpId: string, geoConfig: GeoConfig) => {
        await createGeoConfig({
            dpId: dpId,
            geoConfig: geoConfig
        });
        refreshGeoConfigs();
    }

    const handleUpdateGeoConfig = async (dpId: string, geoConfig: GeoConfig) => {
        await updateGeoConfig({
            dpId: dpId,
            geoConfig: geoConfig
        });
        refreshGeoConfigs();
    }

    const handleSaveGeoConfigConfig = (dpId: string, geoConfig: GeoConfig, edit: boolean) => {
        if(edit) {
            handleUpdateGeoConfig(dpId, geoConfig)
        } else {
            handleCreateGeoConfig(dpId, geoConfig);
        }
            setToUpdateGeoConfig(null);
        setOpenGeoConfigConfigDialog(false);
    }

    const handleEditGeoConfig = async (geoConfig: GeoConfig) => {
        setGeoConfigDialogHeading("Edit Geofence Rule(MCC)")
        setToUpdateGeoConfig(geoConfig);
    }

    const handleDeleteGeoConfig = (geoConfig: GeoConfig) => {
        setToDeleteGeoConfig(geoConfig);
    }

    const handleDeleteConfirm = async () => {
        if (toDeleteGeoConfig) {
            let _toDeleteGeoConfig = toDeleteGeoConfig
            setToDeleteGeoConfig(null);
            await deleteGeoConfig({
                dpId: authReducer.selectedOu.id,
                geoConfig: _toDeleteGeoConfig
            });
            refreshGeoConfigs();
        }
        setToDeleteGeoConfig(null);
    }

    const handleDeleteCancel = () => {
        setToDeleteGeoConfig(null);
    }

    const handleCancelGeoConfigConfig = () => {
        setToUpdateGeoConfig(null);
        setOpenGeoConfigConfigDialog(false);
    }

    const handleToggleDisable = async (geoConfig: GeoConfig) => {
        let res = await toggleGeoConfig({
            geoConfig: {
                ...geoConfig,
                disabled: !geoConfig.disabled
            }
        });
        refreshGeoConfigs()
    }

    const handleSearch = (value: string) => {
        setPaginationParams({
            ...paginationParams,
            search: value,
            page: 1
        });
    }

    const handleChangeSorting = (sortBy: GeoConfigSortBy, sortOrder: SortOrder) => {
        setPaginationParams({
            ...paginationParams,
            sortBy: sortBy,
            sortOrder: sortOrder
        });
    }

    const handleChangePage = (event: React.SyntheticEvent<HTMLButtonElement> | null, _page: number) => {
        setPaginationParams({
            ...paginationParams,
            page: _page
        });
    };

    const handleChangeSize = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
        setPaginationParams({
            ...paginationParams,
            size: event.target.value as number,
            page: 1
        });
    };

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const updateQueryParams = () => {
        queryParams.set('page', paginationParams.page.toString())
        queryParams.set('size', paginationParams.size.toString())
        queryParams.set('sortBy', paginationParams.sortBy)
        queryParams.set('sortOrder', paginationParams.sortOrder)
        queryParams.set('search', paginationParams.search)
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    return (
        <div>
            <Grid classes={{ root: 'geoConfig_management--base--flex  geoConfig_management--base--padding_05x geoConfig-container geoConfig_management--base--flex--justify--end' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} classes={{ root: 'geoConfig_management--base--flex geoConfig_management--base--flex--justify--end' }} className='geoconfig-flex-end-div'>
                    <Box
                        sx={{
                            "& .MuiTextField-root": {
                                width: "100%",
                                maxWidth: "500px",
                            },
                        }}
                    >
                        <TextField
                            id="search"
                            variant="outlined"
                            placeholder="Type something"
                            autoComplete="off"
                            size="small"
                            value={searchText}
                            onChange={handleOnSearchChange}
                            onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                            InputLabelProps={{ className: "serachLabel" }}
                            InputProps={{
                                className: "serachBar",
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {!searchText && <SearchIcon
                                            className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                        />}
                                        {searchText && <CloseIcon
                                            className="cursorPointer input-search-icons"
                                            onClick={handleOnSearchClear}
                                        />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    {!isReadOnlyUser && <Button variant="contained" className='geoConfig_management--button--save' onClick={() => { setOpenGeoConfigConfigDialog(true); setGeoConfigDialogHeading("Add Geofence Rule(MCC)") }}>
                        Create Rule
                    </Button>}
                    <GeoConfigDialog heading={geoConfigDialogHeading} open={toUpdateGeoConfig != null || openGeoConfigConfigDialog} users={users} geoConfig={toUpdateGeoConfig} onSave={handleSaveGeoConfigConfig} onCancel={handleCancelGeoConfigConfig} />
                </Grid>
            </Grid>
            <Paper elevation={0} className="geoConfig_management--dashboard">
                <Grid classes={{ root: 'geoConfig_management--base--padding_1x' }}>
                    <Pagination count={totalGeoConfigs} rowsPerPageOptions={rowsOption} onPageChange={handleChangePage} page={paginationParams.page} rowsPerPage={paginationParams.size} onRowsPerPageChange={handleChangeSize} />
                    <TableContainer component={Paper} className="geoConfig_management--table_container" style={{ maxHeight: 'calc(100vh - 270px)', overflow: 'auto' }}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="geoConfig dashboard">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="background-header-cell arrow-column" sx={{width:"5px"}}/>
                                    <TableCell align='left'>
                                            RULE NAME
                                    </TableCell>
                                    <TableCell align='left'>
                                        ORGANIZATION
                                    </TableCell>
                                    <TableCell align='left'>
                                            TYPE
                                    </TableCell>
                                    <TableCell align='left'>
                                            RULE CONFIGURATION
                                    </TableCell>
                                    <TableCell align='left'>
                                            SUMMARY
                                    </TableCell>
                                    {!isReadOnlyUser && <TableCell align='left' classes={{ root: 'geoConfig_management--dashboard--actions' }}>
                                        <Grid classes={{ root: 'geoConfig_management--base--flex--align--center' }}>
                                            ACTIONS
                                        </Grid>
                                    </TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (geoConfigs && geoConfigs?.length > 0) ?
                                        geoConfigs?.map((geoConfig: GeoConfig, i) => (
                                            <EditableGeoConfigRow index={i} key={geoConfig.id} geoConfig={geoConfig} devices={devices} onEdit={handleEditGeoConfig} onDelete={handleDeleteGeoConfig} onToggleDisable={handleToggleDisable} isReadOnlyUser={isReadOnlyUser} timezone={authReducer.userTimezone} />
                                        ))
                                        :
                                        <TableRow>
                                            <TableCell colSpan={8} align='center'>
                                                No data available
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer >
                </Grid>
                <DeleteGeoConfigConfirmationDialog open={toDeleteGeoConfig != null} onConfirm={handleDeleteConfirm} onCancel={handleDeleteCancel} ruleName={toDeleteGeoConfig?.rule_name || ""}/>
            </Paper >
        </div>

    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    geoConfigs: state.geoConfigManager.geoConfigs,
    totalGeoConfigs: state.geoConfigManager.totalGeoConfigs,
    gettingGeoConfigs: state.geoConfigManager.gettingGeoConfigs,
    gettingCurrentUsage: state.geoConfigManager.gettingCurrentUsage,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser
});

export default withRouter(connect(mapStateToProps, {
    getGeoConfigs,
    createGeoConfig,
    updateGeoConfig,
    deleteGeoConfig,
    toggleGeoConfig,
    updatePermission,
})(GeoConfiguration));