import { useState, useEffect, useRef} from "react";
import { Grid, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import {Pagination} from "../../Pagination";
import { getDefaultConfigListing, deleteDefaultConfig } from "../../../actions/Users/authenticateInventory";
import { getOrganisationUnitList, parentDpAndSubDpList } from "../../../actions/Users/authenticate";
import _ from "lodash";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI, populateForParent } from "../../../utils/util";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import EditDefaultConfig from "./EditDefaultConfig";
import "./defaultConfig.css";
import MenuItemComp from "./MenuItemComp";
import { updateUserOuHasSubOus } from "../../../actions/Users/authenticate";
import Download from '../../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../../actions/types";

const convertToCsv = (data: any[][], userTimezone: string) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[3], row[2], convertDateTimeIntoTimezone(row[5], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), row[4] ? 'Active' : 'Not Active'])
    })
    let creationDate= 'Creation Date (' + userTimezone + ')'
    return [['Config Name', 'Organization', creationDate, 'Status'], ...csvRows]
}

const DefaultConfig = (props) => {
    const { authReducer, getDefaultConfigListing, getOrganisationUnitList, parentDpAndSubDpList, deleteDefaultConfig, updateUserOuHasSubOus, location, history, downloadAsCSV, isReadOnlyUser } = props;
    let _q = getDecodeURI(location?.search);
    // dont consider URL filter keys, fetch data using default filters
    // filterAppliedOn - will be available if filter is applied on manual backup config table
    if(_q.hasOwnProperty("filterAppliedOn")){
        _q={}
    }
    const DEFAULT_PAGE_SIZE = 15;
    const [createConfigPopup, setCreateConfigPopup] = useState(false);
    const [editPopup, setEditPopup] = useState(false);
    const [listDpIds, setListDpIds] = useState([]);
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const CONFIG_NAME = 'name';
    const DP_NAME = "dp_name";
    const CREATION_TIME = 'create_time';
    const STATUS = 'active';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : STATUS);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const ENTER_KEY_CODE = "Enter";
    const [searchValue, setSearchValue] = useState<any>(_q.hasOwnProperty("search") ? _q.search : "");
    const [configId, setConfigId] = useState<any>({});
    const [k4Ids, setK4ids] = useState('');

    const [configTableData, setConfigTableData]: any = useState([]);
    const [configTablePaginationInfo, setConfigTablePaginationInfo]: any = useState();
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();

    useEffect(() => {
       getOrganisationUnitList()
    }, [])

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'defualt-config') {
                DownloadCSV(convertToCsv(authReducer.csvDataDownloded, authReducer.userTimezone)
                    , {
                        formatters: {
                            0: 'ESC-COMMA',
                            1: 'ESC-COMMA',
                            2: 'ESC-COMMA'
                        }
                    }
                );
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getDefaultConfigListing)) {
            if (authReducer.getDefaultConfigListing.hasOwnProperty("result") && Array.isArray(authReducer.getDefaultConfigListing.result)) {
                setConfigTableData(authReducer.getDefaultConfigListing.result)
                setConfigTablePaginationInfo(authReducer.getDefaultConfigListing?.result_info)
            } else {
                setConfigTableData([])
                setConfigTablePaginationInfo({})
            }
            authReducer.getDefaultConfigListing = {}
        }
    }, [authReducer.getDefaultConfigListing])

    const getChildren = (dpIds, dps) => {
        for(let i=0; i<dps?.length; i++) {
            let id = dps[i]?.id;
            dpIds.push(id);
            if(dps[i].hasOwnProperty('_childern') && dps[i]['_childern']?.length > 0) {
                getChildren(dpIds, dps[i]['_childern'])
            }
        }
    }

    useEffect(() => {
        updateUserOuHasSubOus(authReducer)
        if(!_.isEmpty(authReducer.getOrganisationUnitList)) {
            if (authReducer.getOrganisationUnitList.dps && authReducer.getOrganisationUnitList.dps.length > 0) {
                getDpList(authReducer.getOrganisationUnitList.dps)
            }
        }
    }, [authReducer.getOrganisationUnitList])

    const getDpList = (dpList) => {
        const tempArr:any = {};
        for(let i = 0; i < dpList.length; i++){
            const _organisations = [];
            populateForParent(dpList[i], null, _organisations);
            getDPTree(_organisations, tempArr);
        }
        parentDpAndSubDpList(tempArr);
    }
    
    const getDPTree = (ous: any[], arr) => {
        if (ous && Array.isArray(ous)) {
            for (let i = 0; i<ous?.length; i++) {
                const item = ous[i];
                if( arr.hasOwnProperty(item.id) ) {
                    const temp:any = arr[item.id];
                    arr[item.id] = {...temp, ...item._childern};
                } else{
                    arr[item.id] = item._childern; 
                }
                getDPTree(item._childern, arr);
            }
        }
    }

    useEffect(() => {
        getDefaultConfigTableData()
    },[authReducer.selectedOu,authReducer.parentDpAndSubDpList])

    const getDefaultConfigTableData = () => {
        if(!_.isEmpty(authReducer.selectedOu)) {
            let selectedOu= typeof authReducer.selectedOu != "object" ? JSON.parse(authReducer.selectedOu) : authReducer.selectedOu
            const parentDpId = selectedOu?.id;
            const dpIds = authReducer?.parentDpAndSubDpList;
            if(dpIds.hasOwnProperty(parentDpId)) {
                const _dpIds:any = [];
                _dpIds.push(parentDpId);
                getChildren(_dpIds, dpIds[parentDpId]);
                const DpIds = _dpIds.filter((ele) => ele.trim() != "");
                setListDpIds(DpIds);
                getDefaultConfigListing(DpIds, page, limit, sort, sortOrder, searchValue)
            }
        }
    }

     const handleChangePage = (e, value) => {
        let params: any = getDecodeURI(location?.search);
        params.page = value;
        setPage(value);
        doNavigate(params);
        getDefaultConfigListing(listDpIds, value, limit, sort, sortOrder, searchValue);
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        params.page = 1;
        params.limit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        getDefaultConfigListing(listDpIds, Page, Limit, sort, sortOrder, searchValue)
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleSetSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            let params: any = getDecodeURI(location?.search);
            params.page = 1;
            params.sort = field;
            params.sortOrder = order;
            const Page = 1;
            setPage(1);
            setSort(field);
            setSortOrder(order);
            doNavigate(params);
            getDefaultConfigListing(listDpIds, Page, limit, field, order, searchValue)
        }
      };

    const handleSwitchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        let params: any = getDecodeURI(location?.search);
        params.page = 1;
        params.sort = field;
        params.sortOrder = order;
        const Page = 1;
        setPage(1);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        getDefaultConfigListing(listDpIds, Page, limit, field, order, searchValue)
    };

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
            let params: any = getDecodeURI(location?.search);
            value ? params.search = value : delete params.search ;
            delete params.page;
            const Page = 1;
            setPage(1);
            doNavigate(params);
            getDefaultConfigListing(listDpIds, Page, limit, sort, sortOrder, value) 
    };

    const autoRefreshConfig = () => {
        setTimeout(() => {
            getDefaultConfigListing(listDpIds, page, limit, sort, sortOrder, searchValue)
        }, 500)
    }

    useEffect(() => {
       if(!_.isEmpty(authReducer.editDefaultConfig)) {
        const res = authReducer.editDefaultConfig;
            if(res && res.success == true) {
                autoRefreshConfig();
                setEditPopup(false);
            }
       }

       if(!_.isEmpty(authReducer.createDefaultConfig)) {
        const res = authReducer.createDefaultConfig;
            if(res && res.success == true) {
                autoRefreshConfig();
                setCreateConfigPopup(false);
            }
       }

       if(!_.isEmpty(authReducer.deleteDefaultConfig)) {
        const res = authReducer.deleteDefaultConfig;
            if(res && res.success == true) {
                autoRefreshConfig();
            }
       }
    }, [authReducer.editDefaultConfig, authReducer.createDefaultConfig, authReducer.deleteDefaultConfig])

    const rowsOption = [10, 15, 25, 50, 100]

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }
    
    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    const handleDownloadReport = () => {
        // getDefaultConfigListing(listDpIds, page, limit, sort, sortOrder, searchValue)
        const params: CsvParameters = {
            type: "POST_API_SITE_CONTROLLER",
            id: 'defualt-config',
            endpoint: `/v1/dps/default_configurations`,
            payload: {
                dp_id: listDpIds,
                search: searchValue,
                sort_by: sort,
                sort_order: sortOrder,
                page: 0,
                size: configTablePaginationInfo?.total_count,
                show_product_config: false,
                show_dp_name: true
            }
        }
        downloadAsCSV(params);
    }

    useEffect(() => {
        if (!_.isEmpty(authReducer.getVesselsListing)) {
            const sites = authReducer?.getVesselsListing?.hasOwnProperty("locations") ? authReducer?.getVesselsListing?.locations : [];
            if (sites?.length == 0) {
                setK4ids("");
                return;
            }
            const k4Ids = sites && sites.length > 0 ? `${sites.map((item: any) => item.id).join(",")}` : '';
            setK4ids(k4Ids);
        }
    }, [authReducer.getVesselsListing])

  return(
        <div className="backup-config-container default-config-component">
            <Grid container spacing={0.5} className="inventoryCMContainer alertsFilterContainer">
                <Grid item xs={12} sm={12} md={12} lg={12} className="default-config-search-btn">
                    <Grid item xs={12} sm={12} md={12} lg={12} className="inventoryCMHeading">Default Configuration</Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="alertsFilterContainerBox alignTopbarItemsCenter">
                    <Grid item xs={6} sm={6} md={6} lg={6} className="search-create-button">
                        <Box className="searchbarBox">
                            {<TextField
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="Type something"
                                autoComplete="off"
                                size="small"
                                value={searchValue}
                                onChange={handleOnSearchChange}
                                onKeyDown={(event) => searchBoxKeyDown(event)}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "searchbarInputs",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchValue && <SearchIcon
                                                className="searchbarIconsPosition"
                                                onClick={handleOnSearchClick}
                                            />}
                                            {searchValue && <CloseIcon
                                                className="searchbarIconsPosition"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />}
                        </Box>
                        {authReducer.selectedOu.name !== 'K4Mobility' && k4Ids?.length > 0 && <Button className="create-default-config-btn" onClick={() => { setCreateConfigPopup(true) }}>Create Default Configuration</Button>}
                    </Grid>
                  {configTablePaginationInfo?.total_count > 0 && <Grid item xs={6} sm={6} md={6} lg={6} className="sim-anal-csv">
                      <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                  </Grid>}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">
                <Paper className="pagination-position config-pagination">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count= {configTablePaginationInfo?.total_count}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard backup-config-table-container">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow config-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => handleSwitchSorting(event, CONFIG_NAME)}>Config Name</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === CONFIG_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => handleSetSorting(event, CONFIG_NAME, ASC_ORDER)} />
                                                <img src={sort === CONFIG_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => handleSetSorting(event, CONFIG_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    {authReducer.isUserOuHasSubOus == true && <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => handleSwitchSorting(event, DP_NAME)}>Organization</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === DP_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => handleSetSorting(event, DP_NAME, ASC_ORDER)} />
                                                <img src={sort === DP_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => handleSetSorting(event, DP_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>}
                                    <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => handleSwitchSorting(event, CREATION_TIME)}>Creation Date</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === CREATION_TIME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => handleSetSorting(event, CREATION_TIME, ASC_ORDER)} />
                                                <img src={sort === CREATION_TIME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => handleSetSorting(event, CREATION_TIME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => handleSwitchSorting(event, STATUS)}>Status</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === STATUS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => handleSetSorting(event, STATUS, ASC_ORDER)} />
                                                <img src={sort === STATUS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => handleSetSorting(event, STATUS, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                     {!isReadOnlyUser && <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900">Actions</span>
                                        </span>
                                    </TableCell>}
                                </TableRow>
                            </TableHead>
                            {0 != configTableData.length ? (
                            <TableBody className="tableBody usage-tableBody">
                                {
                                    configTableData.map((row, i) =>
                                        <TableRow key={i} className="usageData-tabelRow config-backup-tabe-row">
                                            <TableCell className="usagedata-tableCell config-left-align">{row.name}</TableCell>
                                            { authReducer.isUserOuHasSubOus == true && <TableCell className="usagedata-tableCell config-left-align">{row.dp_name}</TableCell> }
                                            <TableCell className="usagedata-tableCell config-left-align">{convertDateTimeIntoTimezone(row.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                            <TableCell className='usagedata-tableCell config-left-align'>
                                                <div className={ row.active == true ? 'config-active-status' : 'config-inactive-status'}>
                                                    <span>{row.active == true ? 'Active' : 'Not Active'}</span>
                                                </div>
                                            </TableCell>
                                            {!isReadOnlyUser && <TableCell className="usagedata-tableCell config-left-align"><MenuItemComp deleteDefaultConfig={deleteDefaultConfig} row={row} setEditPopup={setEditPopup} setConfigId={setConfigId} /></TableCell>}
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={authReducer.isUserOuHasSubOus ? 5 : 4} className="noDataAvailableCss" align="center">No data available</TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                        </Table>
                    </TableContainer>
                    
                </Grid>
            </Grid>
            {
                createConfigPopup ? <EditDefaultConfig createConfigPopup={createConfigPopup} setCreateConfigPopup={setCreateConfigPopup} ou={authReducer?.selectedOu?.name} /> : null
            }
            {
                editPopup ? <EditDefaultConfig editPopup={editPopup} setEditPopup={setEditPopup} ou={authReducer?.selectedOu?.name} configDetails={configId} /> : null
            }
            
        </div>
  )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser
  });

  export default withRouter(
    connect(mapStateToProps, { 
        getDefaultConfigListing, getOrganisationUnitList, parentDpAndSubDpList, deleteDefaultConfig, updateUserOuHasSubOus, downloadAsCSV
    })(DefaultConfig)
  )