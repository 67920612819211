import { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Autocomplete, Button, DialogActions, Divider, Grid, TextField } from "@mui/material";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from '@mui/material/DialogContent';
import close from "../../asset/image/close.svg";
import _ from "lodash";
import DP_Dropdown from "./DP_Dropdown";
import "./inventoryWarehouse.css";
import SelectTag from "./SelectTag";
import { getConfigProducts, backupConfigData } from '../../actions/Users/authenticateInventory';
import { getVesselEdgeTopologyProducts } from '../../actions/Users/authenticateDashboard';
import { FreeSoloCreateOption } from "./SelectConfig";
import { checkLoggedInUserAuthorizedToViewPage, convertDateTimeIntoTimezone } from "../../utils/util";
import ConfirmDialog from "../ConfirmDialog";
import { CONFIRM_DIALOG_TITLE } from "../../config";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import { GET_DPS_DEFAULT_ACTIVE_CONFIG, GET_LICENSE_TYPES } from "../../actions/types";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const AssignInventory = (props) => {
const {
    authReducer,
    deviceOu,
    isAuthorizedToBeta,
    handleAssignInventory,
    selectedDevice,
    handleCreateAndAssignInventory,
    openAssignDialogue,
    setOpenAssignDialogue,
    setOu,
    getVesselEdgeTopologyProducts,
    backupConfigData,
    idData, setIdData,
    siteValue, setSiteValue, isCreate, setIsCreate,
    isGoldenConfigApplied, setIsGoldenConfigApplied,
    isGoldenConfigOtherApplied, setIsGoldenGonfigOtherApplied,
    getConfigProducts, handleOu, value, setValue,
    selectedLicense, setSelectedLicense, selectedTerm, setSelectedTerm,
    licenseOptions, setLicenseOptions, termOptions, setTermOptions,
    clearLicenceSelection,
    isUpgradeToLatestEosSelected, setIsUpgradeToLatestEosSelected,
    selectedDeviceLatestEosVersion, setConfirmDialog, handleClose
} = props;

const [isGoldenConfigNotApplied, setIsGoldenConfigNotApplied] = useState(true);
const [configSelectedValues, setConfigSelectedValue] = useState<any>({});
const [checkInputValue, setCheckInputValue] = useState(false);
const [readOnly, setReadOnly] = useState(true);
const [devicesOptions, setDeviceOptions] = useState([]);
const [configOptions, setConfigOptions]=useState<any>([]);
const [defaultConfig, setDefaultConfig] = useState<any>([]);
const [vesselNames, setVesselNames] = useState<any[]>([]);

const [licenseTermTypes, setLicenseTermTypes] = useState<any>({});
const [defaultConfigDetails, setDefaultConfigDetails] = useState<any>({})

const dispatch = useDispatch();

useEffect(() => {
    if (openAssignDialogue) {
        setIsGoldenConfigApplied(false);
        setIsGoldenConfigNotApplied(true);
        setIsGoldenGonfigOtherApplied(false);
    }
}, [openAssignDialogue])

useEffect(() => {
    if (!_.isEmpty(selectedLicense)) {
        let termTypes = licenseTermTypes[selectedLicense?.value]
        setTermOptions(termTypes)
        if(termTypes && Array.isArray(termTypes) && termTypes.length > 0){
            setSelectedTerm(termTypes[0])
        }
    }
}, [selectedLicense])

useEffect(() => {
    if (!_.isEmpty(authReducer.getLicenseTypes) && authReducer.getLicenseTypes?.data) {
        let licenseTypes = authReducer.getLicenseTypes.data
        if (licenseTypes && Array.isArray(licenseTypes) && licenseTypes.length > 0) {
            let types : any[] = []
            let termTypes = {}
            let defaultLicense = {}

            licenseTypes = _.orderBy(licenseTypes, ['tierOrder'],['asc']);

            if(!_.isEmpty(licenseTypes) && Array.isArray(licenseTypes) && licenseTypes.length > 0){ 
                if(!_.isEmpty(licenseTypes[0]) && licenseTypes[0]?.tierAlias && licenseTypes[0]?.tierType){
                    defaultLicense = {
                        "label": licenseTypes[0].tierAlias,
                        "value" : licenseTypes[0].tierType
                    }
                }
            }

            licenseTypes.forEach(tierType => {
                let type = {
                    "label": tierType?.tierAlias,
                    "value" : tierType?.tierType
                }

                if(tierType?.term && Array.isArray(tierType.term) && tierType.term.length > 0){
                    let termOptions : any[] = []

                    tierType.term = _.orderBy(tierType.term, ['termOrder'],['asc']);
                    tierType.term.forEach(durationtype => {
                        let option = {
                            "label" : durationtype?.termDisplay,
                            "value" : durationtype?.termValue
                        }
                        termOptions.push(option)
                    });
                    termTypes[tierType?.tierType] = termOptions
                }

                types.push(type)
            });
            setLicenseTermTypes(termTypes)
            setLicenseOptions(types)
            setSelectedLicense(defaultLicense)
        } else {
            clearLicenceSelection()
            setLicenseTermTypes({})
        }
        dispatch({ type: GET_LICENSE_TYPES, payload: {} })
    }
}, [authReducer.getLicenseTypes])

useEffect(() => {
    if (!_.isEmpty(authReducer.getDpsDefaultActiveConfig) && authReducer.getDpsDefaultActiveConfig?.data) {
        let activeConfigs = authReducer.getDpsDefaultActiveConfig.data
        let isDefaultConfigFound= false
        if (activeConfigs && Array.isArray(activeConfigs) && activeConfigs.length > 0) {
            setDefaultConfigDetails(activeConfigs[0])
            isDefaultConfigFound = true
        } else {
            setDefaultConfigDetails({})
        }

        if (!isDefaultConfigFound && isGoldenConfigApplied) {
            setIsGoldenConfigApplied(false);
            setIsGoldenConfigNotApplied(true);
            setIsGoldenGonfigOtherApplied(false);
        }

        dispatch({ type: GET_DPS_DEFAULT_ACTIVE_CONFIG, payload: {} })
    }
}, [authReducer.getDpsDefaultActiveConfig])

const closeDialog = () => {
    setOpenAssignDialogue(false)
    clearLicenceSelection()
    setLicenseTermTypes({})
}

useEffect(() => {
    if (!_.isEmpty(authReducer.getInventoryWarehouseVesselList)) {
        let data = authReducer.getInventoryWarehouseVesselList.hasOwnProperty("locations") && Array.isArray(authReducer.getInventoryWarehouseVesselList.locations) ? authReducer.getInventoryWarehouseVesselList.locations : [];
        let _k4Ids: any = []
        const vessels = data?.map((vs) => {
            _k4Ids.push(vs.id);
            return { label: vs.name, id: vs.id }
        })
        setVesselNames(vessels);
        let sitesIds = _k4Ids.join(',')
        setConfigOptions([])
        let status = "completed"
        sitesIds && backupConfigData(sitesIds, -1, -1, '', 'create_time', 'desc', '', selectedDevice.productType, '', true, status);
        authReducer.getInventoryWarehouseVesselList = {}
    }
}, [authReducer.getInventoryWarehouseVesselList])

useEffect(() => {
    if (!_.isEmpty(authReducer.backupConfigData)) {
        const data = authReducer?.backupConfigData?.result;
        setConfigOptions(data?.map((ele) => {
            return { label: ele.config_name, id: ele.id, details:ele }
        }));
        authReducer.backupConfigData = {};
    }
}, [authReducer.backupConfigData])

const handleGoldenConfigApply = (e) => {
    if (e.target.value == 'Yes') {
        setIsGoldenConfigApplied(true);
        setIsGoldenConfigNotApplied(false);
        setIsGoldenGonfigOtherApplied(false);
        setReadOnly(true);
    } else if (e.target.value == 'Other') {
        setIsGoldenConfigApplied(false);
        setIsGoldenConfigNotApplied(false);
        setIsGoldenGonfigOtherApplied(true);
        setReadOnly(false);
    } else {
        setIsGoldenConfigApplied(false);
        setIsGoldenConfigNotApplied(true);
        setIsGoldenGonfigOtherApplied(false);
    }
}

const handleChangeConfigFrom = (e, newValue, key) => {
    let previousValue = configSelectedValues;
    let selectedValue = { ...idData, [`${key}`]: newValue };
    if (!newValue) {
        previousValue = { ...previousValue, Configuration: '' };
        selectedValue = { ...idData, Configuration: { label: '', id: '' } };
    } else {
        previousValue = { ...previousValue, Configuration: e.target.innerText.trim() };
        selectedValue = { ...idData, Configuration: newValue };
    }

    setIdData(selectedValue);
    setConfigSelectedValue({ ...previousValue });
}

return (
    <div className="Form assignInventory-container warehouseOrgDropdown">
        <DialogContent>
            <Grid container className="deploy-inventory-content register-inventory-content">
                <Grid className="selectedDeviceDetails">
                    <Grid>Select/Create a site from below to assign <span className="selectedProductType">{`(${selectedDevice.deviceId})`}</span></Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy">
                    <span id="content-left-text">Select Organization</span>
                    <DP_Dropdown isSmall={true} isReports={false} setOu={handleOu} deviceOu={deviceOu} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="select-site-check" >
                    <div>
                        <span id="content-left-text">Create / Search Site</span>
                        <FreeSoloCreateOption vesselNames={vesselNames} check={setCheckInputValue} setSiteValue={setSiteValue} setIsCreate={setIsCreate} value={value} setValue={setValue} />
                    </div>
                    <span className={checkInputValue ? "input-text-char-check" : ''}>{checkInputValue ? `Character " ' " is Not allowed` : ''}</span>
                </Grid>
                {
                    licenseOptions && licenseOptions.length > 1
                        ? <Grid item xs={12} sm={12} md={12} lg={12} className="select-site-check" >
                            <div className="margintop-10px">
                                <span id="content-left-text">Select License</span>
                                <Autocomplete
                                    freeSolo
                                    disableClearable
                                    forcePopupIcon={true}
                                    popupIcon={<KeyboardArrowDownIcon />}
                                    ListboxProps={{ className: 'warehouse-apply-config-props' }}
                                    className="audit-filter-inputs-usage config-textField"
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    size='small'
                                    options={licenseOptions}
                                    value={selectedLicense}
                                    onChange={(e, newValue) => { setSelectedLicense(newValue) }}
                                    renderInput={({ inputProps, ...params }) => (
                                        <TextField {...params} placeholder="select"
                                            inputProps={{ ...inputProps, readOnly: true }}
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        : null
                }
                {
                    termOptions && termOptions.length > 0
                        ? <Grid item xs={12} sm={12} md={12} lg={12} className="select-site-check" >
                            <div className="margintop-10px">
                                <span id="content-left-text">Select Term</span>
                                <Autocomplete
                                    freeSolo
                                    disableClearable
                                    forcePopupIcon={true}
                                    popupIcon={<KeyboardArrowDownIcon />}
                                    ListboxProps={{ className: 'warehouse-apply-config-props' }}
                                    className="audit-filter-inputs-usage config-textField"
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    size='small'
                                    options={termOptions}
                                    value={selectedTerm}
                                    onChange={(e, newValue) => { setSelectedTerm(newValue) }}
                                    renderInput={({ inputProps, ...params }) => (
                                        <TextField {...params} placeholder="select"
                                            inputProps={{ ...inputProps, readOnly: true }}
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        : null
                }
                <Grid item xs={12} sm={12} md={12} lg={12} className="name-input-default-config config-apply-config-deploy edit-reg-radio-btn" id='addMarginBottom'>
                    {
                        <Fragment>
                            <span id="content-left-text">Upgrade EOS
                                {
                                    !_.isEmpty(selectedDevice) && selectedDevice?.deviceId
                                    ? <span>({selectedDeviceLatestEosVersion})</span>
                                    : null
                                }
                            </span>
                            <div className="radioButton config-radio-button"> <input value='Yes' type="radio" onChange={() => setIsUpgradeToLatestEosSelected(true)} checked={isUpgradeToLatestEosSelected} /> <span>Yes</span> </div>
                            <div className="radioButton config-radio-button"> <input value='Yes' type="radio" onChange={() => setIsUpgradeToLatestEosSelected(false)} checked={!isUpgradeToLatestEosSelected} /> <span>No</span> </div>
                        </Fragment>
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="name-input-default-config config-apply-config-deploy edit-reg-radio-btn" id={isGoldenConfigNotApplied ? 'addMarginBottom' : 'removeMarginBottom'} >
                    {<Fragment>
                        <span id="content-left-text">Apply Default Config</span>
                        {
                            !_.isEmpty(defaultConfigDetails) ?
                                <div className="radioButton config-radio-button"> <input value='Yes' type="radio" onChange={handleGoldenConfigApply} checked={isGoldenConfigApplied} /> <span>Yes</span> </div>
                                : <div className="radioButton config-radio-button" title="No active default config found"> <input value='Yes' type="radio" checked={false} disabled /> <span>Yes</span> </div>
                        }
                        <div className="radioButton config-radio-button"> <input value='No' type="radio" onChange={handleGoldenConfigApply} checked={isGoldenConfigNotApplied} /> <span>No</span> </div>
                        <div className="radioButton config-radio-button"> <input value='Other' type="radio" onChange={handleGoldenConfigApply} checked={isGoldenConfigOtherApplied} /> <span>Other</span> </div>
                    </Fragment>}
                </Grid>
                {isGoldenConfigOtherApplied ? <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy" >
                    {<Fragment>
                        <span id="content-left-text">Select Configuration</span>
                        <Grid className="configuration-form-build">
                            <div><SelectTag options={configOptions} handleChange={(e, newValue) => handleChangeConfigFrom(e, newValue, 'Configuration')} value={idData.Configuration} /> </div>
                        </Grid>
                    </Fragment>}
                </Grid> : null}
                {isGoldenConfigOtherApplied ? <Grid item xs={12} sm={12} md={12} lg={12} className="selected-config-details" >
                    {!_.isEmpty(idData.Configuration) && !_.isEmpty(idData.Configuration.details) ? <Fragment>
                        <div className="new-selected-config-details">
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target margin-top-10px-reg" >
                                <span id="content-left-text">Site</span>
                                <Grid id="content-right-text">
                                    <div title={idData.Configuration.details.site_name}>{idData.Configuration.details.site_name}</div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target margin-top-10px-reg" >
                                <span id="content-left-text">Device</span>
                                <Grid id="content-right-text">
                                    <div title={idData.Configuration.details.device_name}>{idData.Configuration.details.device_name}</div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target margin-top-10px-reg" >
                                <span id="content-left-text">EOS Version</span>
                                <Grid id="content-right-text">
                                    <div title={idData.Configuration.details.software_version}>{idData.Configuration.details.software_version}</div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target margin-top-10px-reg" >
                                <span id="content-left-text">Creation Date</span>
                                <Grid id="content-right-text">
                                    <div title={convertDateTimeIntoTimezone(idData.Configuration.details.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}>{convertDateTimeIntoTimezone(idData.Configuration.details.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</div>
                                </Grid>
                            </Grid>
                        </div>
                    </Fragment> : null}
                </Grid> : null}
                {
                    isGoldenConfigOtherApplied && !_.isEmpty(defaultConfigDetails)
                        ?
                        <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy selected-config-details" >
                            <span id="content-left-text">Default Configuration details</span>
                            <Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target register-remove-margin-top" >
                                    <span id="content-left-text">Config Name</span>
                                    <Grid id="content-right-text">
                                        <div title={defaultConfigDetails?.config_name}>{defaultConfigDetails?.config_name}</div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target register-remove-margin-top" >
                                    <span id="content-left-text">Site</span>
                                    <Grid id="content-right-text">
                                        <div title={defaultConfigDetails?.site_name}>{defaultConfigDetails?.site_name}</div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target register-remove-margin-top" >
                                    <span id="content-left-text">Device</span>
                                    <Grid id="content-right-text">
                                        <div title={defaultConfigDetails?.device_name}>{defaultConfigDetails?.device_name}</div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target register-remove-margin-top" >
                                    <span id="content-left-text">EOS Version</span>
                                    <Grid id="content-right-text">
                                        <div title={defaultConfigDetails?.software_version}>{defaultConfigDetails?.software_version}</div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target register-remove-margin-top" >
                                    <span id="content-left-text">Creation Date</span>
                                    <Grid id="content-right-text">
                                        <div title={convertDateTimeIntoTimezone(defaultConfigDetails?.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}>{convertDateTimeIntoTimezone(defaultConfigDetails?.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        : null
                }
            </Grid>
        </DialogContent>
        <DialogActions>
            <div className="register-edit-actions-confirm-btns">
                <Divider className="confirm-dialogue-new-divider" />
                <div className="confirm-dialogue-new-btns">
                    <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                    <Button className={(siteValue && isGoldenConfigApplied) || (siteValue && isGoldenConfigOtherApplied && configSelectedValues.Configuration) || (siteValue && isGoldenConfigNotApplied) ? 'confirm-dialogue-proceed' : 'confirm-dialogue-proceed-notAllow'}
                        disabled={(siteValue && isGoldenConfigApplied) || (siteValue && isGoldenConfigOtherApplied && configSelectedValues.Configuration) || (siteValue && isGoldenConfigNotApplied) ? false : true}
                        onClick={() => {
                            setConfirmDialog({
                                isOpen: true,
                                title: CONFIRM_DIALOG_TITLE,
                                subTitle: `Are you sure to register ${selectedDevice.productType} (${selectedDevice.deviceId}) on site ${siteValue.label} ?`,
                                onConfirm: () => {
                                    isCreate ? handleCreateAndAssignInventory() : handleAssignInventory()
                                },
                            })
                    }}>Register</Button>
                </div>
            </div>
        </DialogActions>
    </div>
  )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});
export default withRouter(
    connect(mapStateToProps, { getConfigProducts, getVesselEdgeTopologyProducts, backupConfigData })(AssignInventory)
);