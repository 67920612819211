// Card Details for the summary page
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Grid, LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Line from '../../asset/image/DividerLine.svg';
import Box from '@mui/material/Box';
import "./CardDetails.css";
import "../../UserScreen/ThreatDashboard/ThreatDashboard.css"
import { connect, useDispatch } from 'react-redux';
import { getThreatTopWidget, getThreatTopWidgetTotal } from '../../actions/Users/authenticateThreatDashboard'
import { withRouter } from "react-router-dom";
import { readableBytes, getDecodeURI, getEncodedURI, readableBytesAsGB } from '../../utils/util';
import { displayToastError } from "../../server/request";
import _ from "lodash";
import { GET_TOP_WIDGET_DATA_NEW_SUMM, GET_TOP_WIDGET_WANLINK_STATUS_NEW_SUMM, UPDATE_NEW_SUMMARY_WAN_TYPE, GET_NEW_SUMMARY_UPTIME_ALL_LINK_AVAILABLITY, UPDATE_NEW_SEVERITY_TYPE } from "../../actions/types";
import { useHistory, useLocation } from 'react-router-dom';
import { NEWINTERVALS, THREAT_SEVERITY_ARRAY } from "../../constants/Constants";
import { makeStyles } from "@material-ui/core/styles";
import { SET_THREAT_FILTERS } from "../../actions/types";

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: '#D6DAFA',
  },
  barColorPrimary: {
    backgroundColor: '#2F4B82',
  }
});

const CardContents = (cardHeading, rightTopData, allowed, blocked, dropped, data, threatFilters, borderColor, barCount) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const doNavigate = (pathname, params = {}) => {
    history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
  }
  const handleCardClick = () => {
    dispatch({ type: UPDATE_NEW_SEVERITY_TYPE, payload: data })
    let params = getDecodeURI(location?.search);
    params.severityType = data?.label;
    delete params.page;
    doNavigate(location.url, params);
    dispatch({ type: SET_THREAT_FILTERS, payload: { ...threatFilters, severityType: data?.label } });
  }

  return (
    <Grid item xs={12} sm={6} md={2} lg={2.4} className="card-container">
    <Card className="card-widget" 
     id={'addBoxShadow'}
         onClick={() => handleCardClick(cardHeading)}> 
      <Box className="cardHeader">
        <Typography className="cardTitle">{cardHeading}</Typography>
      </Box>
      <Box className="cardContents">
          <Box className="cardActive">
            <div title={"count"} className="content">{(rightTopData) > 0 ? (rightTopData) : '-' }</div>
          </Box>
      </Box>
      <Box sx={{ margin: '0.25rem', marginLeft: '0.5rem', marginRight: '0.5rem' }}>
        {
          !_.isNaN(1) && !_.isNaN(1) ? <>
             <LinearProgress
        sx={{
          "--LinearProgress-radius": "10px", 
          width: '240px',
          height: '5px',
          backgroundColor: '#ddd', // Background color of the progress track
          '& .MuiLinearProgress-bar': {
            backgroundColor: borderColor, // Custom color for the progress bar itself
          },
        }}
        variant="determinate"
        value={barCount} // Progress at 70%
      />

            <div className="advance-dashboard-card-css">
              {<div>{"Class "}<span style={{ fontWeight: '600' }}>{allowed}</span></div>}
              <div >Affected Sites <span style={{ fontWeight: '600' }}>{blocked}</span></div>

            </div>
          </> : null
        }
      </Box>
    </Card>
  </Grid>
    // <Grid className="card-box-parent" item xs={tabValue === 1 ? 2.2 : 2.4}>
    //   <Card className={`card-widget_NewSummary flex_grow new-sum-cards-box-shadow`}
    //     id={'addBoxShadow'}
    //     onClick={() => handleCardClick(cardHeading)}>
    //     <Box className="cardHeader cardHeaderTile_NewSummary ">
    //       {/* {!(!_.isEmpty(params) && params.hasOwnProperty("k4Ids")) && <img src={null} className="cardHeaderImage_NewSummary" alt="Card Image" /> } */}
    //       <Typography className="cardTitle_NewSummary">{cardHeading}</Typography>
    //       <Typography className="cardTitle_NewSummary siteNameInThreatCard">{rightTopData}</Typography>
    //     </Box>
    //     {/* <Box className="cardContents_NewSummary cardNew"> */}
    //     <Box className={`cardLeftContent_NewSummary`}
    //       display="flex"
    //       justifyContent="space-between" >
    //       <Typography className="content_NewSummary">
    //         <span className="siteNameThreat">Class:</span>
    //         <span className="cardOnlineStatatusData_ns">{allowed}</span>
    //       </Typography>
    //       <Typography className="content_NewSummary">
    //         <span className="siteNameThreat">{"Affected Sites:"}</span>
    //         <span className="cardOnlineStatatusData_ns">{blocked}</span>
    //       </Typography>
    //       {/* <Typography className="content_NewSummary">
    //                   <span className="siteNameThreat">Dropped:</span>
    //                   <span className="cardOnlineStatatusData_ns">{dropped}</span>
    //                   </Typography> */}
    //     </Box>


    //   </Card>
    // </Grid>
  );
}


const SummaryCardDetails = (props) => {
  const { search, authReducer, errorReducer, vesselList, selectedOu, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType, tabValue, alertStatus, alertTopWidget, getThreatTopWidget, threatTopWidgetData, topwidgetTotal, getThreatTopWidgetTotal ,threatFilters, suppress} = props;
  const [cardetails, setCardDetails] = useState({})
  const [topWidgetData, setTopWidgetData] = useState({})
  const [topWidgetDataTotal, setTopWidgetDataTotal] = useState({})
  const [interval, setInterval] = useState(NEWINTERVALS && NEWINTERVALS[0]?.label)
  const [uptime, setUptime] = useState(0);
  const [vessels, setVessels] = useState([]);
  const [filter, setFilter] = useState('');

  const dispatch = useDispatch()
  const location = useLocation();

  useEffect(() => {
    let _q = getDecodeURI(location?.search);

    if (_q?.interval) {
      let selectedInterval
      NEWINTERVALS?.map(interval => {
        if (interval.value === _q?.interval) {
          selectedInterval = interval;
        }
      });
    }

  }, []);

  useEffect(() => {
    let tableData = threatTopWidgetData?.data?.rows.map((item) => {
      return threatTopWidgetData?.data?.columns.reduce((vsd, columnitem, i) => {
        vsd[columnitem] = item[i]
        return vsd;
      }, {})
    })
    if (tableData && tableData.length > 0) {
      setTopWidgetData(tableData)
    } else {
      setTopWidgetData({})
    }
  }, [threatTopWidgetData])

  useEffect(() => {
    let tableData = topwidgetTotal?.data?.rows.map((item) => {
      return topwidgetTotal?.data?.columns.reduce((vsd, columnitem, i) => {
        vsd[columnitem] = item[i]
        return vsd;
      }, {})
    })

    if (tableData && tableData.length > 0) {
      setTopWidgetDataTotal(tableData)
    } else {
      setTopWidgetDataTotal({})
    }
  }, [topwidgetTotal])

  useEffect(() => {
    // create new requestAborter
    let _requestAbortController = new AbortController();

    if (interval && Object.keys(interval).length > 0 && !_.isEmpty(vesselList)) {
      let vessels = [];
      let params = getDecodeURI(location?.search);
      let filter = "";
      if (!_.isEmpty(params) && params.hasOwnProperty("filterApplied")) {
        let statues = params?.internetStatus;
        if (statues) {
          statues = statues.split(",")
          statues = "'" + statues.join("','") + "'";
          filter = "AND status IN(" + statues + ")"
        }
      }
      setFilter(filter);
      if (!_.isEmpty(authReducer?.selectedVessels?.vessels)) {
        vessels = authReducer?.selectedVessels?.vessels;

      } else if (!_.isEmpty(vesselList)) {
        vessels = vesselList?.locations;
        if (_.isEmpty(vessels)) {
          dispatch({ type: GET_TOP_WIDGET_WANLINK_STATUS_NEW_SUMM, payload: {} })
          dispatch({ type: GET_TOP_WIDGET_DATA_NEW_SUMM, payload: {} })
          // setAlertsTopWidgetData([]);
          return;
        }

      }
      setVessels(vessels)
    }

    return () => {
      _requestAbortController.abort()
    }

  }, [authReducer.filteredVessels, search, vesselList]);

  useEffect(() => {
    let _requestAbortController = new AbortController();
    if (vessels.length > 0) {
      getThreatTopWidget(vessels, newSummaryStartDate, newSummaryEndDate, threatFilters?.severityType, alertStatus,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass, threatFilters.action, suppress)
    }
  }, [vessels, newSummaryStartDate, newSummaryEndDate, tabValue,threatFilters, suppress])

  useEffect(() => {
    if (vessels.length > 0) {
      getThreatTopWidgetTotal(vessels, newSummaryStartDate, newSummaryEndDate, threatFilters?.severityType, alertStatus,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass, threatFilters.action, suppress)
    }
  }, [vessels, newSummaryStartDate, newSummaryEndDate, tabValue,threatFilters, suppress])

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorGetVesselSummaryDashboard)) {
      displayToastError(errorReducer.errorGetVesselSummaryDashboard.description)
      errorReducer.errorGetVesselSummaryDashboard = {};
    }
  }, [errorReducer.errorGetVesselSummaryDashboard]);


  const GetCardDetails = ({ newSummarySeverityType }) => {
    return (
      <Grid container spacing={tabValue === 1 ? 1 : 2}>
        {tabValue === 1 && newSummarySeverityType?.label === "All" &&
          <Grid item xs={2} className="new_summary_grid_item card-box-parent" >
            <Card className="card-widget_NewSummary new_summary_uptime flex_grow new-sum-cards-box-shadow" id={'addBoxShadow'}>
              <div>
                <div style={{ display: "flex" }}>
                  <Typography className="new_summary_uptime_value">{uptime}</Typography>
                  <Typography className="new_summary_uptime_percent">{"%"}</Typography>
                </div>
                <Typography className="new_summary_uptime_typo">{"Availability"}</Typography>
              </div>
            </Card>
          </Grid>
        }
        
        {CardContents(`THREAT SUMMARY`, topWidgetDataTotal[0]?.total_hits, topWidgetDataTotal[0]?.threat_type_count, topWidgetDataTotal[0]?.total_sites_effected, topWidgetData[0]?.dropped_count, THREAT_SEVERITY_ARRAY[0], threatFilters, "#2F4B82", (1 * 100))}
        {((threatFilters?.severityType? false : true) || threatFilters.severityType === "Critical" || threatFilters.severityType === "CRITICAL" || threatFilters.severityType === "ALL" || threatFilters.severityType === "All" || threatFilters.severityType === "" ) && CardContents(`CRITICAL`, topWidgetData[0]?.total_count, topWidgetData[0]?.class_count, topWidgetData[0]?.location_count, topWidgetData[0]?.dropped_count, THREAT_SEVERITY_ARRAY[1], threatFilters, "#d62728",((topWidgetData[0]?.total_count / topWidgetDataTotal[0]?.total_hits) * 100))}
        {((threatFilters?.severityType? false : true) || threatFilters.severityType === "High" || threatFilters.severityType === "HIGH" || threatFilters.severityType === "ALL" || threatFilters.severityType === "All" || threatFilters.severityType === "" ) && CardContents(`HIGH`, topWidgetData[1]?.total_count, topWidgetData[1]?.class_count, topWidgetData[1]?.location_count, topWidgetData[1]?.dropped_count, THREAT_SEVERITY_ARRAY[2], threatFilters, "#ff7f0e",((topWidgetData[1]?.total_count / topWidgetDataTotal[0]?.total_hits) * 100))}
        {((threatFilters?.severityType? false : true) || threatFilters.severityType === "Medium" || threatFilters.severityType === "MEDIUM" || threatFilters.severityType === "ALL" || threatFilters.severityType === "All" || threatFilters.severityType === "") && CardContents(`MEDIUM`, topWidgetData[2]?.total_count, topWidgetData[2]?.class_count, topWidgetData[2]?.location_count, topWidgetData[2]?.dropped_count, THREAT_SEVERITY_ARRAY[3], threatFilters, "#ffbf00",((topWidgetData[2]?.total_count / topWidgetDataTotal[0]?.total_hits) * 100))}
        {((threatFilters?.severityType? false : true) || threatFilters.severityType === "Low" || threatFilters.severityType === "LOW" || threatFilters.severityType === "ALL" || threatFilters.severityType === "All" || threatFilters.severityType === "") && CardContents(`LOW`, topWidgetData[3]?.total_count, topWidgetData[3]?.class_count, topWidgetData[3]?.location_count, topWidgetData[3]?.dropped_count, THREAT_SEVERITY_ARRAY[4], threatFilters, "#1f77b4",((topWidgetData[3]?.total_count / topWidgetDataTotal[0]?.total_hits) * 100))}
      </Grid>
    )
  }

  return (
    <Grid className="cards_NewSummary" container item>
      <GetCardDetails newSummarySeverityType={newSummarySeverityType} threatFilters={threatFilters}/>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state?.authReducer,
  threatTopWidgetData: state?.authReducer?.threatTopWidgetData,
  setTopWidgetData: state?.authReducer?.setTopWidgetData,
  errorReducer: state?.errorReducer,
  newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
  newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
  newSummarySeverityType: state?.authReducer?.newSummarySeverityType,
  alertTopWidget: state?.authReducer?.alertTopWidget,
  topwidgetTotal: state?.authReducer?.topwidgetTotal,
  threatFilters: state?.authReducer?.threatFilters
});


export default withRouter(
  connect(mapStateToProps, {
    getThreatTopWidget,
    getThreatTopWidgetTotal

  })(SummaryCardDetails)
);
