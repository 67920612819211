import { Autocomplete, Button, Dialog, DialogTitle, Divider, Grid, TextField, Typography } from '@mui/material';
import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import close from "../../../asset/image/close.png";
import ThreatKontrolActions from "./ThreatKontrolActions";
import {deleteThreatKontrol} from "../../../actions/Users/authenticateThreatDashboard";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import axios from 'axios';

interface ThreatKontrolDelete {
    deleteOpen: boolean,
    setDeleteOpen: any,
    selectedRow: any
}

const ThreatKontrolDelete = (props) => {
    const {authReducer, deleteThreatKontrol, deleteOpen, setDeleteOpen, selectedRow} = props;
    const handleConfirm = () => {
        deleteThreatKontrol(selectedRow.site_id, selectedRow.device_id, selectedRow.rule_id);
        setDeleteOpen(false);
    }
    const handleClose = () => {
        setDeleteOpen(false);
    }
    return (
        <Dialog className="menuScroll threatKontrolDeletebox" onClose={handleClose} open={deleteOpen} maxWidth="lg" fullWidth>
            <DialogTitle className='inter-font-parent confirm-pad' style={{background:"#f5f5f5"}}>
                <div className="inter-font">
                    Confirm?
                </div>
            </DialogTitle>
            <div className="threatKontrolMargin">Delete threat rule?</div>
            <div className="threatDeleteButtonDiv threatKontrolMargin">
                <Button id="threatKontrolBackButton" onClick={() => setDeleteOpen(false)}>
                    BACK
                </Button>
                <Button id="threatKontrolProceedButton" onClick={handleConfirm}>
                    PROCEED
                </Button>
            </div>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
})

export default withRouter(
    connect(mapStateToProps, { deleteThreatKontrol })(ThreatKontrolDelete)
);