import { nanoid } from "nanoid";
import { Fragment, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Button, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Checkbox, FormHelperText} from "@mui/material";
import { RemoveCircle } from '@mui/icons-material';
import { Action, Actions, AccessNetwork, WanProfile, TrafficPolicy, ActionType, SelectedDeviceType, DevicesAction } from "../types";
import { AddCircle } from "@material-ui/icons";
import _ from "lodash";
import "./index.css"

interface GeoConfigActionsProps {
    accessNetworks?: AccessNetwork[];
    wanProfiles?: WanProfile[];
    trafficPolicies?: TrafficPolicy[];
    value: Action[];
    readOnly?: boolean;
    onChange?: (actions: Action[]) => void;
    isDefaultAction?: boolean;
    selectedDevices: SelectedDeviceType[];
}

function GeoConfigActions(props: GeoConfigActionsProps) {

    const { accessNetworks, wanProfiles, trafficPolicies, value, readOnly, onChange, isDefaultAction, selectedDevices} = props;
    const [geoConfigActions, setGeoConfigActions] = useState<Action[]>([])

    useEffect(()=>{
        if(!_.isEmpty(value)){
            let geoActions: Action[] = value
            geoActions.forEach((geoAction, i) => {
               if(geoAction.action_type == 'assignTrafficPolicy'){
                    let devices = geoAction.devices || [];
                    devices = devices.map(device => ({
                        ...device,
                        traffic_policy: {
                            id: device.traffic_policy?.id || "",
                            name: device.traffic_policy?.name,
                            access_networks: device.traffic_policy?.access_networks,
                        }
                    }));

                    geoActions[i].devices = devices
                    geoActions[i].id = geoAction.id
                    geoActions[i].geo_codes = geoAction.geo_codes
                    geoActions[i].configuration_type = geoAction.configuration_type
                    geoActions[i].action_type = geoAction.action_type

                } else if(geoAction.action_type == 'assignWanProfile'){
                    let devices = geoAction.devices || [];
                    devices = devices.map(device => ({
                        ...device,
                        wan_profile: {
                            id: device.wan_profile?.id || "",
                            name: device.wan_profile?.name,
                            access_networks: device.wan_profile?.access_networks,
                        }
                    }));

                    geoActions[i].devices = devices
                    geoActions[i].id = geoAction.id
                    geoActions[i].geo_codes = geoAction.geo_codes
                    geoActions[i].configuration_type = geoAction.configuration_type
                    geoActions[i].action_type = geoAction.action_type 
                }
           })
           setGeoConfigActions([...geoActions])    
        }
    }, [value])

    const addGeoConfigAction = () => {
        geoConfigActions.push({
            id: nanoid(),
            geo_codes: isDefaultAction ? ["000"] : [] ,
            action_type: Actions.ActionUndefined,
            configuration_type: isDefaultAction ? "default" : "custom",
            devices:[],
        })
        let _geoConfigActions = [...geoConfigActions]
        if (onChange) onChange(_geoConfigActions)
    }

    const deleteGeoConfigAction = (id: string) => () => {
        geoConfigActions.splice(geoConfigActions.findIndex(condition => condition.id === id), 1)
        let _geoConfigActions = [...geoConfigActions]
        if (onChange) onChange(_geoConfigActions)
    }

    const changeGeoConfigAction = (id: string) => (condition: Action) => {
        let _geoConfigActions = [...geoConfigActions]
        _geoConfigActions[_geoConfigActions.findIndex(condition => condition.id === id)] = condition
        if (onChange) onChange(_geoConfigActions)
    }

    return (
        <Fragment>
            {geoConfigActions.map(condition => <GeoConfigAction condition={condition} key={condition.id} accessNetworks={accessNetworks} wanProfiles={wanProfiles} trafficPolicies={trafficPolicies}
                onChange={changeGeoConfigAction(condition.id)} removeComp={
                    <IconButton aria-label="remove condition" onClick={deleteGeoConfigAction(condition.id)} disabled={readOnly}>
                        <RemoveCircle />
                    </IconButton>
                } readOnly={readOnly} selectedDevices={selectedDevices}/>)}
            {
                !readOnly && <Button variant="outlined" size="small" startIcon={<AddCircle />} className="geoConfig_management--button_outlined--primary" onClick={addGeoConfigAction} >
                    ADD ACTION
                </Button>
            }
        </Fragment>
    )
}

interface GeoConfigActionProps {
    condition: Action;
    removeComp: JSX.Element;
    accessNetworks?: AccessNetwork[];
    wanProfiles?: WanProfile[];
    trafficPolicies?: TrafficPolicy[];
    readOnly?: boolean;
    onChange?: (action: Action) => void;
    selectedDevices: SelectedDeviceType[];
}

function GeoConfigAction(props: GeoConfigActionProps) {
    const { condition, removeComp, accessNetworks, wanProfiles, trafficPolicies, readOnly, onChange, selectedDevices } = props;
    const [geoCodes, setGeoCodes] = useState<string>(condition?.geo_codes?.join(","))
    const [action, setAction] = useState<ActionType>(condition.action_type)
    const [accessNetworkNames, setAccessNetworkNames] = useState<string[]>([])
    const [wanProfileName, setWanProfileName] = useState<string>("")
    const [trafficPolicyName, setTrafficPolicyName] = useState<string>("")
    const [devicesAction, SetDevicesAction] = useState<DevicesAction[]>([])
    const [trafficPolicyCommonList, setTrafficPolicyCommonList] = useState<any>([])
    const [wanProfileCommonList, setWanProfileCommonList] = useState<any>([])
    const [accessNetworksCommonList, setAccessNetworksCommonList] = useState<any>([])

    const handleGeoCodeValidation = (code: string): boolean => {
        // const geoCodePattern = /^\d{3}(?:\/\d{2})?(,\d{3}(?:\/\d{2})?)*$/
        const geoCodePattern = /^\d{3}(?:\/\d{2}|\/\d{3})?(, ?\d{3}(?:\/\d{2}|\/\d{3})?)*$/
        return geoCodePattern.test(code)
    }

    const validGeoCodes = useMemo(() => {
        return handleGeoCodeValidation(geoCodes)
    }, [geoCodes])
    
    const invalidWanProfile = useMemo(() => {
        if (action === Actions.ActionAssignWanProfile) {
            return !wanProfiles?.find(profile => profile.name === wanProfileName)
        }
        return false
    }, [action, wanProfileName, wanProfiles])

    const invalidTrafficPolicy = useMemo(() => {
        if (action === Actions.ActionAssignTrafficPolicy) {
            return !trafficPolicies?.find(profile => profile.name === trafficPolicyName)
        }
        return false
    }, [action, trafficPolicyName, wanProfiles])

    const invalidAccessNetworks = useMemo(() => {
        if (action === Actions.ActionAssignWanProfile || action === Actions.ActionAssignTrafficPolicy) {
            return accessNetworkNames.filter(name => !accessNetworks?.find(network => network.name === name))
        }
        return []
    }, [action, accessNetworkNames, accessNetworks])

    useEffect(() => {
        if(condition.action_type =='assignWanProfile'){
            let devices = condition?.devices || []
            if(devices.length > 0){
                let wanProfile = devices[0].wan_profile;
                let wanProfileName = devices[0].wan_profile?.name || "";

                let accessNetworks:any = wanProfile?.access_networks || [];
                let accessNetworkNames :string[] = accessNetworks.filter(network => network?.name !== undefined).map(network => network.name);
                if(accessNetworkNames.length > 0){
                    setAccessNetworkNames([...accessNetworkNames])
                }
                setWanProfileName(wanProfileName)
            }
        } else if(condition.action_type =='assignTrafficPolicy'){
            let devices = condition?.devices || []
            if(devices.length > 0){
                let trafficPolicy = devices[0].traffic_policy
                let tpProfileName = trafficPolicy?.name || ""
                
                let accessNetworks:any = trafficPolicy?.access_networks || [];
                let accessNetworkNames :string[] = accessNetworks.filter(network => network?.name !== undefined).map(network => network.name);
                if(accessNetworkNames.length > 0){
                    setAccessNetworkNames([...accessNetworkNames])
                }
                setTrafficPolicyName(tpProfileName)
            }
        }
    }, [])

    useEffect(() => {
        onChange && onChange({
            ...condition,
            geo_codes: (geoCodes && geoCodes.length >0 ? geoCodes.split(","):[]),
            action_type: action,
            devices: devicesAction,
            id: condition.id,
            valid:
                validGeoCodes && geoCodes.length > 0 &&
                action !== Actions.ActionUndefined &&
                !invalidWanProfile &&
                !invalidTrafficPolicy &&
                invalidAccessNetworks.length === 0 &&
                (
                    (
                        action === Actions.ActionAssignWanProfile &&
                        wanProfileName !== "" && accessNetworkNames.length !== 0
                    ) ||
                    (
                        action === Actions.ActionAssignTrafficPolicy &&
                        trafficPolicyName !== "" && accessNetworkNames.length !== 0
                    ) ||
                    (
                        action === Actions.ActionNotification
                    )
                )
        })
    }, [geoCodes, action, devicesAction, accessNetworkNames, wanProfileName, trafficPolicyName, invalidWanProfile, invalidTrafficPolicy, invalidAccessNetworks])

    useEffect(()=>{
        if(!_.isEmpty(trafficPolicies)){
            let commonList :any = []
            var trafficPolicyData = trafficPolicies || []

            trafficPolicyData.forEach(item => {
                if (!commonList.find(e => e.name === item.name)) {
                    commonList.push({
                        name: item.name,
                        common: item.common,
                    });
                }
            });

            commonList.sort((a, b)=> b.common - a.common)

            setTrafficPolicyCommonList([...commonList])
        } else {
            setTrafficPolicyCommonList([])
        }
    }, [trafficPolicies])

    useEffect(()=>{
        if(!_.isEmpty(wanProfiles)){
            let commonList :any = []
            var wanProfileData = wanProfiles || []

            wanProfileData.forEach(item => {
                if (!commonList.find(e => e.name === item.name)) {
                    commonList.push({
                        name: item.name,
                        common: item.common,
                    });
                }
            });

            commonList.sort((a, b)=> b.common - a.common)

            setWanProfileCommonList([...commonList])
        } else {
            setWanProfileCommonList([])
        }
    }, [wanProfiles])

    useEffect(()=>{
        if(!_.isEmpty(accessNetworks)){
            let commonList :any = []
            var accessNetworksData = accessNetworks || []

            accessNetworksData.forEach(item => {
                if (!commonList.find(e => e.name === item.name)) {
                    commonList.push({
                        name: item.name,
                        common: item.common,
                    });
                }
            });

            commonList.sort((a, b)=> b.common - a.common)

            setAccessNetworksCommonList([...commonList])
        } else {
            setAccessNetworksCommonList([])
        }
    }, [accessNetworks])

    useEffect(()=>{
        let deviceActionData:DevicesAction[] = []
        switch(action){
            case 'assignTrafficPolicy':{

                let accessNetworkList = accessNetworks || [];

                selectedDevices.forEach((device, i) => {
                    deviceActionData.push({
                        site_id: device.site_id,
                        device_id: device.device_id,
                        group_id: device.group_id,
                    });

                    const arrAccessNetworks = accessNetworkList.filter(network => 
                        accessNetworkNames.includes(network.name) && device.device_id === network.device_id
                    ).map(network => ({
                        id: network.id,
                        name: network.name
                    }));

                    const trafficPolicyData = trafficPolicies || [];
                    const matchingTrafficPolicy = trafficPolicyData.find(policy => 
                        policy.name === trafficPolicyName && policy.device_id === device.device_id
                    );

                    if (matchingTrafficPolicy) {
                        deviceActionData[i].traffic_policy = {
                            id: matchingTrafficPolicy.id,
                            name: trafficPolicyName,
                        };
                    }

                    deviceActionData[i].traffic_policy = {
                        id: deviceActionData[i].traffic_policy?.id || "",
                        name: deviceActionData[i].traffic_policy?.name || "",
                        access_networks: arrAccessNetworks,
                    };
                });

                break;
            }
            case 'assignWanProfile':{
                let accessNetworkList = accessNetworks || [];

                selectedDevices.forEach((device, i) => {
                    deviceActionData.push({
                        site_id: device.site_id,
                        device_id: device.device_id,
                        group_id: device.group_id,
                    });

                    const arrAccessNetworks = accessNetworkList
                        .filter(network => 
                            accessNetworkNames.includes(network.name) && device.device_id === network.device_id
                        )
                        .map(network => ({
                            id: network.id,
                            name: network.name
                        }));

                    const wanProfileData = wanProfiles || [];
                    const matchingWanProfile = wanProfileData.find(profile => 
                        profile.name === wanProfileName && profile.device_id === device.device_id
                    );

                    if (matchingWanProfile) {
                        deviceActionData[i].wan_profile = {
                            id: matchingWanProfile.id,
                            name: wanProfileName,
                        };
                    }

                    deviceActionData[i].wan_profile = {
                        id: deviceActionData[i].wan_profile?.id || "",
                        name: deviceActionData[i].wan_profile?.name || "",
                        access_networks: arrAccessNetworks,
                    };
                });
                break;
            }
        }
        SetDevicesAction([...deviceActionData])
    }, [accessNetworkNames, trafficPolicyName, wanProfileName])

    const handleGeoCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _value = event?.target?.value
        setGeoCodes(_value)
    }

    const handleChangeActionOnExhaust = (event: SelectChangeEvent) => {
        const _action = event.target.value as ActionType
        setAction(_action)
    }

    const handleChangeWanProfileName = (event: SelectChangeEvent) => {
        setWanProfileName(event.target.value as string)
    }

    const handleChangeTrafficPolicyName = (event: SelectChangeEvent) => {
        setTrafficPolicyName(event.target.value as string)
    }

    const handleChangeAccessNetworkNames = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        let _value = typeof value === 'string' ? value.split(',') : value
        _value = _value.filter(name => accessNetworks?.find(network => network.name === name))
        setAccessNetworkNames(_value);
    }


    return (
        <Grid>
            <Grid container spacing={0.5}>
                <Grid item xs={12} md={10}>
                    <Grid container spacing={0.5} style={{paddingTop:"5px", paddingBottom:"5px"}}>
                            {
                                condition?.configuration_type != "default" ?
                                    <Grid item xs={3} md={3}>
                                        <FormControl fullWidth error={!validGeoCodes || geoCodes?.length == 0}>
                                            <TextField
                                                size="small"
                                                label="MCC"
                                                variant="outlined"
                                                value={geoCodes}
                                                onChange={handleGeoCodeChange}
                                                placeholder="e.g: 312,234,310"
                                                InputProps={{
                                                    readOnly: readOnly,
                                                }}
                                            />
                                            {
                                                !validGeoCodes ? geoCodes?.length == 0 ? <FormHelperText> Geo code is required</FormHelperText> : <FormHelperText>Geo code format invalid</FormHelperText> : null
                                            }
                                        </FormControl>
                                    </Grid>
                                : <></>
                            }
                        <Grid item xs={3} md={3}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="action-on-exhaust-label">Action</InputLabel>
                                <Select
                                    value={action}
                                    onChange={handleChangeActionOnExhaust}
                                    labelId="action-on-exhaust-label"
                                    label="Action"
                                    readOnly={readOnly}
                                >
                                    <MenuItem value={Actions.ActionAssignTrafficPolicy}>Assign Traffic Policy</MenuItem>
                                    <MenuItem value={Actions.ActionAssignWanProfile}>Assign WAN Profile</MenuItem>
                                    <MenuItem value={Actions.ActionNotification}>Send Notification</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            {
                                (action === Actions.ActionAssignWanProfile || action === Actions.ActionAssignTrafficPolicy) ? 
                                    <>
                                        {
                                            action === Actions.ActionAssignWanProfile && 
                                                <FormControl fullWidth size="small" error={invalidWanProfile}>
                                                    <InputLabel id="wan-profile-label">WAN Profile</InputLabel>
                                                    <Select
                                                        value={wanProfileName}
                                                        onChange={handleChangeWanProfileName}
                                                        labelId="wan-profile-label"
                                                        label="WAN Profile"
                                                        readOnly={readOnly}
                                                    >
                                                        {wanProfileCommonList && wanProfileCommonList.map(profile => <MenuItem disabled={profile.common == 0 ? true : false } key={profile.id} value={profile.name}>{profile.name}</MenuItem>)}
                                                    </Select>
                                                    {
                                                        invalidWanProfile ? wanProfileName === "" ? <FormHelperText> WAN profile is required</FormHelperText> : <FormHelperText>Selected wan profile does not exist, please reselect the wan profile</FormHelperText> : null
                                                    }
                                                </FormControl>
                                        }
                                        {
                                            action === Actions.ActionAssignTrafficPolicy && 
                                                <FormControl fullWidth size="small" error={invalidTrafficPolicy}>
                                                    <InputLabel id="traffic-policy-label">Traffic Policy</InputLabel>
                                                    <Select
                                                        value={trafficPolicyName}
                                                        onChange={handleChangeTrafficPolicyName}
                                                        labelId="traffic-policy-label"
                                                        label="Traffic Policy"
                                                        readOnly={readOnly}
                                                    >
                                                        {trafficPolicyCommonList && trafficPolicyCommonList.map(policy => <MenuItem disabled={policy.common == 0 ? true : false } key={policy.id} value={policy.name}>{policy.name}</MenuItem>)}
                                                    </Select>
                                                    {
                                                        invalidTrafficPolicy ? trafficPolicyName === "" ? <FormHelperText> Traffic policy is required</FormHelperText> : <FormHelperText>Selected traffic policy does not exist, please reselect the traffic policy</FormHelperText> : null
                                                    }
                                                </FormControl>
                                        }
                                    </>
                                    :<></>
                            }
                        </Grid>
                        <Grid item xs={3} md={3}>
                            {
                                (action === Actions.ActionAssignWanProfile || action === Actions.ActionAssignTrafficPolicy) && 
                                    <FormControl fullWidth size="small" error={invalidAccessNetworks?.length > 0 || accessNetworkNames?.length === 0}>
                                        <InputLabel id="access-network-label">Access Networks</InputLabel>
                                        <Select
                                            multiple
                                            value={accessNetworkNames}
                                            onChange={handleChangeAccessNetworkNames}
                                            labelId="access-network-label"
                                            label="Access Networks"
                                            renderValue={(selected) => {
                                                let _anName = new Map(accessNetworksCommonList?.map(an => [an.name, an.name]))
                                                return (selected as string[]).map(name => _anName.get(name)).join(', ')
                                            }}
                                            readOnly={readOnly}
                                        >
                                            {accessNetworksCommonList && accessNetworksCommonList.map(network => <MenuItem disabled={network.common == 0 ? true : false } key={network.id} value={network.name}>
                                                <Checkbox size='small' checked={false || accessNetworkNames.indexOf(network.name) > -1} />
                                                <ListItemText primary={network.name} />
                                            </MenuItem>)}
                                        </Select>
                                        {
                                            invalidAccessNetworks?.length > 0 ? <FormHelperText>Selected access networks do not exist, please reselect the access networks</FormHelperText> : accessNetworkNames.length === 0 ? <FormHelperText>Access networks are required</FormHelperText> : null
                                        }
                                    </FormControl>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={1} display={'flex'} gap={0.25} alignItems={'center'} className="configuration-remove-icon">
                    {removeComp}
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    accessNetworks: state.geoConfigManager.accessNetworks,
    wanProfiles: state.geoConfigManager.wanProfiles,
    trafficPolicies: state.geoConfigManager.trafficPolicies
});

export default connect(mapStateToProps, {})(GeoConfigActions)