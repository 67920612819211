import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Box, Button, Chip, Grid, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material"
import CreateGroup  from "./CreateGroup"
import { useEffect, useRef, useState } from "react"
import { UploadConfiguration } from "./UploadConfiguration"
import _ from "lodash"
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"
import Paper from "@mui/material/Paper"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined"
import RotateRightOutlinedIcon from "@mui/icons-material/RotateRightOutlined"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../utils/util"
import { DEFAULT_PAGE_SIZE, NEW_PER_PAGE_ITEMS } from "../../constants/Constants"
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg"
import descSortingSelected from "../../asset/image/descSortingSelected.svg"
import AscSort from "../../asset/image/AscSort.svg"
import DescSort from "../../asset/image/DescSort.svg"
import { useLocation, useHistory } from "react-router-dom"
import { Pagination } from "../Pagination"
import { populateForParent } from "../../utils/util"
import ConfirmDialog from "../ConfirmDialog"
import "./index.css"
import { CONFIRM_DIALOG_TITLE } from "../../config"
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants"
import { getDeviceList, createGroup, getGroupList, getGroupListCount, deleteGroup, groupDevicesEosUpgrade } from "../../actions/Users/authenticateInventory";
import { parentDpAndSubDpList } from "../../actions/Users/authenticate";

import GroupDeviceList from "./GroupDeviceList"
import EditGroup from "./EditGroup"
import { CREATE_INVENTORY_GROUP, DELETE_INVENTORY_GROUP, GET_INVENTORY_GROUP_LIST, GET_INVENTORY_GROUP_LIST_COUNT, UPDATE_INVENTORY_GROUP } from "../../actions/types";

const GroupActions = (props) => {
    const { authReducer, getDeviceList, createGroup, getGroupList, getGroupListCount, parentDpAndSubDpList, deleteGroup, groupDevicesEosUpgrade, isReadOnlyUser } = props

    const location = useLocation()
    const history = useHistory()

    const ENTER_KEY_CODE = "Enter"
    const searchTimeoutRef = useRef<NodeJS.Timeout>()

    // confirm dailog
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });

    const [createGroupPopup, setCreateGroupPopup] = useState(false)
    let [groupDeviceListPopup, setGroupDeviceListPopup] = useState<boolean>(false)
    const [editGroupPopup, setEditGroupPopup] = useState(false)
    const [uploadConfigurationPopup, setUploadConfigurationPopup] = useState(false)
    const [selectedRow, setSelectedRow] = useState("")
    const [groupList, setGroupList] = useState<any[]>([])

    // sort
    const GROUP_NAME = "name"
    const ORGANIZATION = "dp_name"
    const CREATED_ON = "create_time"
    const UPDATED_ON = "update_time"
    const ASC_ORDER = "asc"
    const DESC_ORDER = "desc"

    let _q = getDecodeURI(location?.search)

    // pagination
    const [fromItem, setFromItem] = useState<number>(0)
    const [toItem, setToItem] = useState<number>(0)
    const [total, setTotal] = useState(0)
    const [pages, setPages] = useState(0)
    const [page, setPage] = useState<any>(_q.hasOwnProperty("page") ? parseInt(_q.page) : 0)
    const [limit, setLimit] = useState<any>(_q.hasOwnProperty("limit") ? _q.limit : DEFAULT_PAGE_SIZE)

    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") && !_.isEmpty(_q.sort) ? _q.sort : UPDATED_ON)
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER)

    let [searchValue, setSearchValue] = useState<any>(_q.hasOwnProperty("search") ? _q.search : "")
    const dispatch = useDispatch();

    let payload = {
        "query": "GET_INVENTORY_GROUPS",
        "named": true,
        "parameters": {
            "dp": "",
            "search": searchValue,
            "page_start": page,
            "page_size": limit,
            "sort_by": sort,
            "sort_order": sortOrder
        },
        "format": "csv"
    }


    const getDPTree = (ous: any[], arr) => {
        if (ous && Array.isArray(ous)) {
            for (let i = 0; i < ous?.length; i++) {
                const item = ous[i];
                if (arr.hasOwnProperty(item.id)) {
                    const temp: any = arr[item.id];
                    arr[item.id] = { ...temp, ...item._childern };
                } else {
                    arr[item.id] = item._childern;
                }
                getDPTree(item._childern, arr);
            }
        }
    }

    const getDpList = (dpList) => {
        const tempArr: any = {};
        for (let i = 0; i < dpList.length; i++) {
            const _organisations = [];
            populateForParent(dpList[i], null, _organisations);
            getDPTree(_organisations, tempArr);
        }
        parentDpAndSubDpList(tempArr);
    }

    useEffect(() => {
        if (!_.isEmpty(authReducer.getOrganisationUnitList)) {
            if (authReducer.getOrganisationUnitList.dps && authReducer.getOrganisationUnitList.dps.length > 0) {
                getDpList(authReducer.getOrganisationUnitList.dps)
            }
        }
    }, [authReducer.getOrganisationUnitList])


    const getParentAndSubDpIds = () => {
        const _dpIds: any = [];
        if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name && !_.isEmpty(authReducer.parentDpAndSubDpList)) {
            const parentDpId = authReducer?.selectedOu?.id
            const dpIds = authReducer?.parentDpAndSubDpList
            if (dpIds.hasOwnProperty(parentDpId)) {
                _dpIds.push(parentDpId)
                getChildren(_dpIds, dpIds[parentDpId])
            }
        }
        return _dpIds
    }

    const getChildren = (dpIds, dps) => {
        for (let i = 0; i < dps?.length; i++) {
            let id = dps[i]?.id;
            dpIds.push(id);
            if (dps[i].hasOwnProperty('_childern') && dps[i]['_childern']?.length > 0) {
                getChildren(dpIds, dps[i]['_childern'])
            }
        }
    }

    const updatePageInfo = (page: number, limit: number, itemCount: number, pages: number, total: number) => {
        setPage(page);
        setFromItem(itemCount > 0 ? ((page) * limit) + 1 : 0);
        setToItem(((page) * limit) + itemCount);
        setTotal(total);
        setPages(pages);
    }

    const handlePerPageChange = (e: any) => {
        let _perPage = parseInt(e.target.value);
        if (Number.isInteger(_perPage)) {
            let params: any = getDecodeURI(location?.search);
            params.page = 0;
            params.limit = _perPage;
            setPage(0);
            setLimit(_perPage);
            doNavigate(location.url, params);

            let dpIds = getParentAndSubDpIds();
            payload["parameters"]["dp"] = dpIds.map(x => "'" + x + "'").toString()
            payload["parameters"]["page_start"] = 0
            payload["parameters"]["page_size"] = _perPage
            getGroupList(payload)
        }
    }

    const handlePagination = (e, value) => {
        let params: any = getDecodeURI(location?.search);
        params.page = value - 1;
        setPage(value - 1)
        doNavigate(location.pathname, params);

        let dpIds = getParentAndSubDpIds();
        payload["parameters"]["dp"] = dpIds.map(x => "'" + x + "'").toString()
        payload["parameters"]["page_start"] = (value - 1) * limit

        getGroupList(payload)
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue)
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        setPage(0);

        doNavigate(location.url, params);

        let dpIds = getParentAndSubDpIds();
        payload["parameters"]["dp"] = dpIds.map(x => "'" + x + "'").toString()
        payload["parameters"]["search"] = value
        getGroupList(payload)

        payload["query"] = "GET_INVENTORY_GROUPS_COUNT";
        getGroupListCount(payload)
    }

    const handleOnSearchClear = () => {
        setSearchValue('')
        doSearch("")
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value)
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current)
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined
            doSearch(e.target.value)
        }, 1000)
    }

    const handleOnSearchClick = () => {
        doSearch(searchValue)
    }

    useEffect(() => {
        if (!_.isEmpty(authReducer) && authReducer.hasOwnProperty("selectedOu") && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id && !_.isEmpty(authReducer.parentDpAndSubDpList)) {
            let dpIds = getParentAndSubDpIds();
            payload["parameters"]["dp"] = dpIds.map(x => "'" + x + "'").toString()
            getGroupList(payload)

            payload["query"] = "GET_INVENTORY_GROUPS_COUNT";
            getGroupListCount(payload)
        }
    }, [authReducer.selectedOu, authReducer.parentDpAndSubDpList])

    useEffect(() => {
        if (!_.isEmpty(authReducer.createInventoryGroup) || !_.isEmpty(authReducer.updateInventoryGroup) || !_.isEmpty(authReducer.deleteInventoryGroup)) {
            let params: any = getDecodeURI(location?.search);
            delete params.search;
            params.page = 0;
            params.sort = UPDATED_ON
            params.sortOrder = DESC_ORDER

            doNavigate(params.pathname, params)

            setPage(0);
            setSort(UPDATED_ON)
            setSortOrder(DESC_ORDER)

            let dpIds = getParentAndSubDpIds()
            let _dpIds = dpIds.map(x => "'" + x + "'").toString()
            let payload = {
                "query": "GET_INVENTORY_GROUPS",
                "named": true,
                "parameters": {
                    "dp": _dpIds,
                    "search": "",
                    "page_start": 0,
                    "page_size": limit,
                    "sort_by": UPDATED_ON,
                    "sort_order": DESC_ORDER
                },
                "format": "csv"
            }
            getGroupList(payload)

            payload["query"] = "GET_INVENTORY_GROUPS_COUNT";
            getGroupListCount(payload)

            if(!_.isEmpty(authReducer.createInventoryGroup)){
                dispatch({ type: CREATE_INVENTORY_GROUP, payload: {}})
            }

            if(!_.isEmpty(authReducer.updateInventoryGroup)){
                dispatch({ type: UPDATE_INVENTORY_GROUP, payload: {}})
            }

            if(!_.isEmpty(authReducer.deleteInventoryGroup)){
                dispatch({ type: DELETE_INVENTORY_GROUP, payload: {}})
            }
        }
    }, [authReducer.createInventoryGroup, authReducer.updateInventoryGroup, authReducer.deleteInventoryGroup])

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            let params: any = getDecodeURI(location?.search)
            params.page = 0
            params.limit = limit
            params.sort = field
            params.sortOrder = order

            setPage(0)
            setSort(field)
            setSortOrder(order)

            payload["parameters"]["page_start"] = 0
            payload["parameters"]["sort_by"] = field
            payload["parameters"]["sort_order"] = order

            doNavigate(location.pathname, params)
            let dpIds = getParentAndSubDpIds();
            payload["parameters"]["dp"] = dpIds.map(x => "'" + x + "'").toString()
            getGroupList(payload)
        }
    }

    const doNavigate = (pathname, params = {}) => {
        history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` })
    }

    useEffect(() => {
        if (!_.isEmpty(authReducer.getInventoryGroupList)) {
            const data = authReducer.getInventoryGroupList.hasOwnProperty("data") ? authReducer.getInventoryGroupList.data : {}
            if (data.hasOwnProperty("rows") && Array.isArray(data["rows"])) {
                const rows: any[] = data["rows"]
                let list: any[] = []
                for (let i = 0; i < rows.length; i++) {
                    let row = {};
                    row["groupId"] = rows[i][0]
                    row["groupName"] = rows[i][1]
                    row["user"] = rows[i][2]
                    row["dpId"] = rows[i][3]
                    row["isAllSelected"] = rows[i][4]
                    row["deviceIdentifier"] = rows[i][5]
                    row["createTime"] = rows[i][6]
                    row["updateTime"] = rows[i][7]
                    row["dpName"] = rows[i][9]

                    let devices: any[] = []
                    let _devices = rows[i][10]
                    if (!_.isEmpty(_devices)) {
                        _devices = _devices.split("+")
                        for (let j = 0; j < _devices.length; j++) {
                            let device = _devices[j]
                            if (!_.isEmpty(device) && device.includes("|")) {
                                devices.push(
                                    {
                                        "id": device.split("|")[0],
                                        "name": device.split("|")[1]
                                    }
                                )
                            }
                        }
                    }
                    row["devices"] = devices

                    list.push(row)
                }
                setGroupList(list)
            } else {
                setGroupList([])
            }
            dispatch({ type: GET_INVENTORY_GROUP_LIST, payload: {}})
        }
    }, [authReducer.getInventoryGroupList])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getInventoryGroupListCount)) {
            const data = authReducer.getInventoryGroupListCount.hasOwnProperty("data") ? authReducer.getInventoryGroupListCount.data : {}

            if (!_.isEmpty(data) && data.hasOwnProperty("rows") && Array.isArray(data.rows)) {
                const totalCount: number = parseInt(data.rows[0][0])
                let length = groupList.length
                const pages = Math.ceil(totalCount / limit)
                updatePageInfo(page, limit, length, pages, totalCount)
            } else {
                updatePageInfo(page, limit, 0, 1, 0)
            }
            dispatch({ type: GET_INVENTORY_GROUP_LIST_COUNT, payload: {}})
        }
    }, [authReducer.getInventoryGroupListCount])

    return (
        <div className="inventory-group-actions-tab-content">
           {!isReadOnlyUser && <div className="group-buttons">
                <Button variant="outlined" onClick={(e) => setCreateGroupPopup(true)}>Create Group</Button>
            </div>}
            <div className="Form">
                <Grid className="am-table">
                    <Grid className="">
                        <Grid className="topbarWidth alignTopbarItemsCenter">
                            <Grid item xs={6} sm={6} md={6} lg={6} className="searchbarMaxWidth1">
                                <Box className="searchbarBox">
                                    {<TextField
                                        variant="outlined"
                                        placeholder="Type something"
                                        autoComplete="off"
                                        size="small"
                                        value={searchValue}
                                        onChange={handleOnSearchChange}
                                        onKeyDown={(event) => searchBoxKeyDown(event)}
                                        InputLabelProps={{ className: "serachLabel" }}
                                        InputProps={{
                                            autoComplete: 'none',
                                            className: "searchbarInputs",
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {!searchValue && <SearchIcon
                                                        className="searchbarIconsPosition"
                                                        onClick={handleOnSearchClick}
                                                    />}
                                                    {searchValue && <CloseIcon
                                                        className="searchbarIconsPosition"
                                                        onClick={handleOnSearchClear}
                                                    />}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                                </Box>
                            </Grid>
                            {/* <Grid item xs={6} sm={6} md={6} lg={6} className="sim-anal-csv">
                            <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid> */}
                        </Grid>
                        {
                            <div className="menuScroll">
                                <Box>
                                    <Pagination
                                        rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                                        count={total ? total : 0}
                                        rowsPerPage={limit}
                                        page={page + 1}
                                        onPageChange={handlePagination}
                                        onRowsPerPageChange={handlePerPageChange}
                                    />
                                </Box>
                                <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer deployed-container white-bg" component={Paper}>
                                    <Table aria-label="simple sticky table" className="inventory-group-table" stickyHeader>
                                        <TableHead className="usageReports-tableHead">
                                            <TableRow className="UsageReports-tableRow login-tableRow">
                                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                                    <span className="align-items-center">
                                                        <span className="login-history-dp-name font-wt-900" onClick={(event) => setSorting(event, GROUP_NAME, ASC_ORDER)}>Name</span>
                                                        <Grid className="sort-icon-margin">
                                                            <img src={sort === GROUP_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, GROUP_NAME, ASC_ORDER)} />
                                                            <img src={sort === GROUP_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                                className="sortingImages" onClick={(event) => setSorting(event, GROUP_NAME, DESC_ORDER)} />
                                                        </Grid>
                                                    </span>
                                                </TableCell>
                                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                                    <span className="align-items-center">
                                                        <span className="login-history-dp-name font-wt-900" onClick={(event) => setSorting(event, ORGANIZATION, ASC_ORDER)}>Organization</span>
                                                        <Grid className="sort-icon-margin">
                                                            <img src={sort === ORGANIZATION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, ORGANIZATION, ASC_ORDER)} />
                                                            <img src={sort === ORGANIZATION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                                className="sortingImages" onClick={(event) => setSorting(event, ORGANIZATION, DESC_ORDER)} />
                                                        </Grid>
                                                    </span>
                                                </TableCell>
                                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                                    <span className="align-items-center">
                                                        <span className="login-history-dp-name font-wt-900" onClick={(event) => setSorting(event, CREATED_ON, ASC_ORDER)}>Created On</span>
                                                        <Grid className="sort-icon-margin">
                                                            <img src={sort === CREATED_ON && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CREATED_ON, ASC_ORDER)} />
                                                            <img src={sort === CREATED_ON && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                                className="sortingImages" onClick={(event) => setSorting(event, CREATED_ON, DESC_ORDER)} />
                                                        </Grid>
                                                    </span>
                                                </TableCell>
                                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                                    <span className="align-items-center">
                                                        <span className="login-history-dp-name font-wt-900" onClick={(event) => setSorting(event, UPDATED_ON, ASC_ORDER)}>Updated On</span>
                                                        <Grid className="sort-icon-margin">
                                                            <img src={sort === UPDATED_ON && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, UPDATED_ON, ASC_ORDER)} />
                                                            <img src={sort === UPDATED_ON && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                                className="sortingImages" onClick={(event) => setSorting(event, UPDATED_ON, DESC_ORDER)} />
                                                        </Grid>
                                                    </span>
                                                </TableCell>
                                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                                    <span className="align-items-center">
                                                        <span className="login-history-dp-name font-wt-900">Devices</span>
                                                    </span>
                                                </TableCell>
                                                {!isReadOnlyUser && <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                                    <span className="align-items-center">
                                                        <span className="login-history-dp-name font-wt-900">Actions</span>
                                                    </span>
                                                </TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        {groupList && 0 != groupList.length ? (
                                            <TableBody className="tableBody usage-tableBody">
                                                {
                                                    groupList && groupList.map((row, i) =>
                                                        <TableRow key={i} className="loginHistory-tableRow new-deployed-usage-tr">
                                                            <TableCell className="login-left-align inter-font text-center CF-cell" title={row["groupName"]}>
                                                                {row["groupName"]}
                                                            </TableCell>
                                                            <TableCell className="login-left-align inter-font text-center CF-cell" title={row["dpName"]}>
                                                                {row["dpName"]}
                                                            </TableCell>
                                                            <TableCell className="login-left-align inter-font text-center CF-cell" title={row["createTime"]}>
                                                                {convertDateTimeIntoTimezone(row["createTime"], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                                            </TableCell>
                                                            <TableCell className="login-left-align inter-font text-center CF-cell" title={row["updateTime"]}>
                                                                {convertDateTimeIntoTimezone(["updateTime"], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                                            </TableCell>
                                                            <TableCell className="login-left-align inter-font text-center CF-cell">
                                                                <span>
                                                                    <div className="inventory-group-table-devices">
                                                                        {
                                                                            row.devices.map((device, idx) => (
                                                                                idx==0?
                                                                                    <Tooltip key={device.id} title={<span style={{ fontSize: "12px" }}>{device.name}</span>}>
                                                                                        <Chip key={device.id} label={device.name} variant="outlined" />
                                                                                    </Tooltip>
                                                                                :null
                                                                            ))
                                                                        }
                                                                        {
                                                                            row.devices.length > 1 ? (
                                                                                <Chip className="inventory-group-table-more-devices" key="pmore" label={"+" + (row.devices.length - 1)} variant="outlined" title={"More Devices"}
                                                                                    onClick={() => {
                                                                                        setGroupDeviceListPopup(true);
                                                                                        setSelectedRow(row);
                                                                                    }}
                                                                                />
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                </span>
                                                            </TableCell>
                                                            {!isReadOnlyUser && <TableCell className="login-left-align inter-font text-center CF-cell">
                                                                <span className="inventoryDeployedAction backup-config-now-icon" title="Edit Group"
                                                                    onClick={() => {
                                                                        setSelectedRow(row)
                                                                        setEditGroupPopup(true)
                                                                    }}
                                                                >
                                                                    <EditOutlinedIcon />
                                                                </span>
                                                                {/* <span className="inventoryDeployedAction upload-config-icon" title="Upload Configuration" onClick={() => { setUploadConfigurationPopup(true); setSelectedRow(row) }}><BackupOutlinedIcon /></span> */}
                                                                <span className="inventoryDeployedAction backup-config-now-icon" title="EOS Upgrade"
                                                                    onClick={() => {
                                                                        let latestVersion = "latest EOS verssion"

                                                                        setSelectedRow(row)
                                                                        setConfirmDialog({
                                                                            isOpen: true,
                                                                            title: CONFIRM_DIALOG_TITLE,
                                                                            subTitle: "Are you sure to upgrade the devices of a group " + row["groupName"] + " to "+latestVersion+" ?",
                                                                            onConfirm: () => {
                                                                                groupDevicesEosUpgrade(row["dpId"], row["groupId"])
                                                                            }
                                                                        })
                                                                    }}>
                                                                    <RotateRightOutlinedIcon />
                                                                </span>
                                                                <span className="inventoryDeployedAction backup-config-now-icon" title="Delete Group" onClick={() => {
                                                                    setSelectedRow(row)
                                                                    setConfirmDialog({
                                                                        isOpen: true,
                                                                        title: CONFIRM_DIALOG_TITLE,
                                                                        subTitle: "Are you sure to delete group " + row["groupName"] + "?",
                                                                        onConfirm: () => {
                                                                            deleteGroup(row["dpId"], row["groupId"])
                                                                        }
                                                                    })
                                                                }}
                                                                ><DeleteOutlinedIcon /></span>
                                                            </TableCell>}
                                                        </TableRow>
                                                    )
                                                }
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </div>
                        }
                    </Grid>
                </Grid>
            </div>
            {createGroupPopup ? <CreateGroup createGroupPopup={createGroupPopup} setCreateGroupPopup={setCreateGroupPopup} /> : null}
            {editGroupPopup ? <EditGroup selectedRow={selectedRow} editGroupPopup={editGroupPopup} setEditGroupPopup={setEditGroupPopup} /> : null}
            {uploadConfigurationPopup ? <UploadConfiguration authReducer={authReducer} uploadConfigurationPopup={uploadConfigurationPopup} setUploadConfigurationPopup={setUploadConfigurationPopup} selectedRow={selectedRow} /> : null}
            {confirmDialog.isOpen ? <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} /> : null}
            {groupDeviceListPopup ? <GroupDeviceList selectedRow={selectedRow} groupDeviceListPopup={groupDeviceListPopup} setGroupDeviceListPopup={setGroupDeviceListPopup} /> : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser
});

export default withRouter(
    connect(mapStateToProps, {
        getDeviceList, createGroup, getGroupList, getGroupListCount, parentDpAndSubDpList, deleteGroup, groupDevicesEosUpgrade
    })(GroupActions)
);