import { inventoryDeviceHistory, getDeviceConfigActionLogs, getDeviceEosHistoryEvents } from '../../actions/Users/authenticateInventory';
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState, Suspense } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, TableHead, Tooltip } from '@mui/material';
import close from "../../asset/image/close.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import './inventoryDeployed.css';
import _ from 'lodash';
import moment from 'moment';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from '../../utils/constants';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import JsonTooltip from '../JSONTooltip/JsonTooltip';
import { convertDateTimeIntoTimezone } from "../../utils/util";
import { GET_DEVICE_CONFIG_ACTION_LOGS, GET_DEVICE_EOS_HISTORY_EVENTS } from '../../actions/types';
import React from 'react';

const DeviceHistoryPopup = (props) => {
    const { authReducer, inventoryDeviceHistory, deviceHistoryLoading, deviceHistoryData, open, setOpen, deviceIdSelected, inventoryId, getDeviceConfigActionLogs, deviceConfigActionLogsLoading, deviceEosHistoryData, getDeviceEosHistoryEvents, deviceEosHistoryEventsLoading, deviceEosHistoryEventsData } = props;

    const [deviceHistory, setDeviceHistory] = useState<any>([]);

    const dispatch = useDispatch();

    useEffect(() => {
        inventoryDeviceHistory(inventoryId);
        getDeviceConfigActionLogs(deviceIdSelected)
        getDeviceEosHistoryEvents(deviceIdSelected)
    }, [])

    useEffect(() => {
        if (!_.isEmpty(deviceHistoryData) && deviceHistoryData.status == 1) {
            const data = deviceHistoryData.hasOwnProperty('data') ? deviceHistoryData.data : [];
            setDeviceHistory(data);
        }
    }, [deviceHistoryData])

    const InventoryHistoryTab = "Inventory"
    const SystemHistoryTab = "System"

    const [selectedTab, setSelectedTab] = useState(InventoryHistoryTab);
    const [deviceConfigActionLogs, setDeviceEosHistory] = useState<any>([]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
      setSelectedTab(newValue);
    };

    const getCreatedTime = (row:any) => {
        if (!_.isEmpty(row.status)){
            let status = row.status
            if (status.includes("scheduled")){
                return ""
            }
        }
        return convertDateTimeIntoTimezone(row.created_at, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)
    }

    const getUpdatedTime = (row:any) => {
        if (!_.isEmpty(row.status)){
            let status = row.status
            if (status.includes("scheduled") || (!status.includes("failed") && status.includes("inprogress"))){
                return ""
            }
        }
        return convertDateTimeIntoTimezone(row.updated_at, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)
    }

    useEffect(() => {
        if (!_.isEmpty(deviceEosHistoryData)) {
            if (deviceEosHistoryData?.data?.rows){
                if(deviceEosHistoryData.data.rows.length>0){
                    let data = deviceEosHistoryData?.data?.rows[0][0]
                    data?.map((row) => {
                        let status = ""
                        if(!_.isEmpty(row.status)){
                            status = row.status
                            status = status.length > 1 ? status.charAt(0).toUpperCase() + status.slice(1) : status.charAt(0)
                        }
                        if (status.toLowerCase() == "upgraded") {
                            status = "Success"
                        }
                        if (_.isEmpty(row.action)) {
                            row.action = "Config Restore"
                        }
                        row["creation_time"] = row.created_at

                        row.created_at = getCreatedTime(row)
                        row.updated_at = getUpdatedTime(row)
                        row.status = status

                        row["eos_change"] = ""
                        if (!_.isEmpty(row.eos_version_before_upgrade) && !_.isEmpty(row.eos_version)) {
                            row["eos_change"] = "v" + row.eos_version_before_upgrade.split("~")[0] + " -> v" + row.eos_version.split("~")[0]
                        } else if (!_.isEmpty(row.eos_version)) {
                            row["eos_change"] = "v" + row.eos_version.split("~")[0]
                        }

                        if (!_.isEmpty(row.status_history)) {
                            row.status_history = JSON.stringify(row.status_history)
                        }
                    })
                    setDeviceEosHistory(deviceConfigActionLogs.concat(data))
                }
            }
            dispatch({ type: GET_DEVICE_CONFIG_ACTION_LOGS, payload: {}})
        }
    }, [deviceEosHistoryData])

    useEffect(() => {
        if (!_.isEmpty(deviceEosHistoryEventsData)) {
            if (deviceEosHistoryEventsData?.data?.rows){
                if(deviceEosHistoryEventsData.data.rows.length>0){
                    let events : Object[]= []
                    let rows = deviceEosHistoryEventsData.data.rows
                    rows.forEach((row, index) => {
                        let event = {}
                        event["creation_time"] = row[0]
                        event["created_at"] = convertDateTimeIntoTimezone(row[0], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)

                        let status = row[5]
                        event["status"] = status

                        event["action_initiated_by"] = row[10]

                        let eosVersion = ""
                        if (status.includes("build")) {
                            status=status.split("build")[1]
                        }

                        if (status.includes("is triggered")) {
                            status=status.split("is triggered")[0]
                        }

                        eosVersion = status

                        if (!_.isEmpty(eosVersion)) {
                            eosVersion=eosVersion.replace("DCP","")
                            eosVersion=eosVersion.split("_")[0]
                            event["eos_change"]= "v"+eosVersion.trim()
                        }

                        events.push(event)
                    });

                    setDeviceEosHistory(deviceConfigActionLogs.concat(events))
                }
            }
            dispatch({ type: GET_DEVICE_EOS_HISTORY_EVENTS, payload: {}})
        }
    }, [deviceEosHistoryEventsData])

    const InventoryHistoryTabContent = React.memo(() =>
        <Grid className='tab-content'>
            <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                <Table aria-label="simple sticky table" stickyHeader>
                    <TableHead className="usageReports-tableHead">
                        <TableRow className="UsageReports-tableRow login-tableRow">
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left history-device-change">Change</TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">Created By</TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    {0 != deviceHistory.length && !deviceHistoryLoading ? (
                        <TableBody className="tableBody usage-tableBody">
                            {
                                deviceHistory && deviceHistory.map((row, i) =>
                                    <TableRow key={i} className="loginHistory-tableRow">
                                        <Tooltip title={row.change}>
                                            <TableCell className="login-left-align short-text-username">{row.change}</TableCell>
                                        </Tooltip>
                                        <Tooltip title={row.ipAddress}>
                                            <TableCell className="login-left-align short-text-username">{row.fullName ? row.fullName : row.createdBy}</TableCell>
                                        </Tooltip>
                                        <TableCell className='login-left-align'>{convertDateTimeIntoTimezone(row.createdAt, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </Grid>
    );

    const SystemHistoryTabContent = React.memo(() =>
        <Grid className='tab-content'>
            <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                <Table aria-label="simple sticky table" stickyHeader>
                    <TableHead className="usageReports-tableHead">
                        <TableRow className="UsageReports-tableRow login-tableRow">
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">Version</TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">Start time</TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">Completed time</TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">ACTION</TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">Initiated by</TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    {0 != deviceConfigActionLogs.length && !deviceConfigActionLogsLoading && !deviceEosHistoryEventsLoading ? (
                        <TableBody className="tableBody usage-tableBody">
                            {
                                _.sortBy(deviceConfigActionLogs, 'creation_time').reverse().map((row, i) =>
                                    <TableRow key={i} className="loginHistory-tableRow">
                                        <Tooltip disableHoverListener={_.isEmpty(row.eos_change)} title={<span className="font-size-12px">{row.eos_change}</span>}>
                                            <TableCell className="login-left-align short-text-username">
                                                {row.eos_change}
                                            </TableCell>
                                        </Tooltip>
                                        <Tooltip disableHoverListener={_.isEmpty(row.created_at)} title={<span className="font-size-12px">{row.created_at}</span>}>
                                            <TableCell className="login-left-align short-text-username">{row.created_at}</TableCell>
                                        </Tooltip>
                                        <Tooltip disableHoverListener={_.isEmpty(row.updated_at)} title={<span className="font-size-12px">{row.updated_at}</span>}>
                                            <TableCell className="login-left-align short-text-username">{row.updated_at}</TableCell>
                                        </Tooltip>
                                        <Tooltip disableHoverListener={_.isEmpty(row.action)} title={<span className="font-size-12px">{row.action}</span>}>
                                            <TableCell className="login-left-align short-text-username">{row.action}</TableCell>
                                        </Tooltip>
                                        <Tooltip disableHoverListener={_.isEmpty(row.action_initiated_by)} title={<span className="font-size-12px">{row.action_initiated_by}</span>}>
                                            <TableCell className="login-left-align short-text-username">{row.action_initiated_by}</TableCell>
                                        </Tooltip>
                                        <TableCell className="login-left-align status">
                                            <Tooltip disableHoverListener={_.isEmpty(row?.reason) && _.isEmpty(row?.status)} title={!_.isEmpty(row?.reason) ? row.reason : row.status}><div className='text'>{row?.status}</div></Tooltip>
                                            {
                                                _.isEmpty(row.status_history) || "null" == row.status_history ? null : <JsonTooltip className="prettyToolTip" json={row.status_history} />
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={5} className="noDataAvailableCss">No data available</TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </Grid>
    );

    return (
        <div className="Form menuScroll">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                sx={{ borderRadius: '8px', width: '1000px' }}
                className='config-deploy-inventory-popup menuScroll device-history-popup'
            >
                <div>
                    <DialogTitle className='create-default-config-title'>
                        <span>{`History (${deviceIdSelected})`}</span>
                        <img src={close} title="Close" onClick={() => setOpen(false)} />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className="deploy-inventory-content device-history-content warehouse-device-history-content">
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={selectedTab}>
                                    <Box sx={{ borderBottom: 'none' }}>
                                        <TabList onChange={handleTabChange} TabIndicatorProps={{ style: { display: 'none' } }} className='tabsHeader'>
                                            <Tab label={InventoryHistoryTab} value={InventoryHistoryTab} />
                                            <Tab label={SystemHistoryTab} value={SystemHistoryTab} />
                                        </TabList>
                                    </Box>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        {selectedTab === InventoryHistoryTab && <InventoryHistoryTabContent />}
                                        {selectedTab === SystemHistoryTab && <SystemHistoryTabContent />}
                                    </Suspense>
                                </TabContext>
                            </Box>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}
const mapStateToProps = (state) => ({
    authReducer: state?.authReducer,
    deviceHistoryData: state.authReducer?.deviceHistory,
    deviceHistoryLoading: state.authReducer?.setDeviceHistoryLoading,
    deviceEosHistoryData: state.authReducer?.deviceConfigActionLogs,
    deviceConfigActionLogsLoading: state.authReducer?.deviceConfigActionLogsLoading,
    deviceEosHistoryEventsData: state.authReducer?.deviceEosHistoryEvents,
    deviceEosHistoryEventsLoading: state.authReducer?.deviceEosHistoryEventsLoading,
});

export default withRouter(
    connect(mapStateToProps, { inventoryDeviceHistory, getDeviceConfigActionLogs, getDeviceEosHistoryEvents })(DeviceHistoryPopup)
);