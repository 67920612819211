import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useState, useEffect, useRef, Fragment} from "react";
import { getGeoConfigLogs, getGeoConfigLogsCount } from "../slice";
import _ from "lodash";
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, Tooltip, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../../../components/Pagination";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, convertDateTimeIntoTimezone } from "../../../utils/util";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../../../components/DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import './index.css';
import { SERVICE_FUNCTION_TO_FEATURE } from "../../../config";
import { updatePermission } from "../../../actions/Users/authenticate";
import CommonFilters from "../../../components/BreadCrumbs/CommonFilters";

const USAGE_GEO_CONFIG = "view-vessel-summary";
const usageGeoConfigService = SERVICE_FUNCTION_TO_FEATURE[USAGE_GEO_CONFIG]["service"];
const usageGeoConfigFunction = SERVICE_FUNCTION_TO_FEATURE[USAGE_GEO_CONFIG]["function"];


const GeoLogs = (props) => {
    const { authReducer, selectedOu, userTimezone, getGeoConfigLogs, getGeoConfigLogsCount, newSummaryStartDate, newSummaryEndDate, geoConfigLogs, geoConfigLogsCount, csvDataDownloded, csvDownloadId, downloadAsCSV, location, history, gettingGeoConfigsCount, updatePermission} = props;

    const _q = getDecodeURI(location?.search);
    const DEFAULT_PAGE_SIZE = 15;
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const ENTER_KEY_CODE = "Enter";
    const [tableData, setTableData] = useState<any>([]);
    const [count, setCount] = useState(0);
    const ORGANIZATION = 'organiztion';
    const RULE_NAME = 'rule_name';
    const DEVICE_NAME = 'device_name';
    const MCC = 'mcc';
    const APPLIED_ACTION = 'applied_action';
    const APPLIED_ACTION_NAME = 'applied_action_name';
    const APPLIED_AT = 'applied_at';
    const STATUS = 'status';
    const DESCRIPTION = 'description';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : APPLIED_AT);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const dispatch = useDispatch();
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const [selectedOrg, setSelectedOrg] = useState<any>({});
    

    useEffect(() => {
        let authorized = checkLoggedInUserAuthorizedToViewPage(usageGeoConfigService, usageGeoConfigFunction);
        if (authorized && !authReducer.permission) {
            const info = {
                permission: {
                    service: usageGeoConfigService,
                    serviceFunction: usageGeoConfigFunction,
                },
                isMultiVessel: false,
                isSingleVessel: false,
                showOu: true,
                ou: authReducer.selectedOu ? authReducer.selectedOu : ''
            };
            updatePermission(info);
        }
    }, [authReducer.selectedOu])

    useEffect(() => {
        if(!_.isEmpty(selectedOu)) {
            const ou = selectedOu;
            setSelectedOrg(ou)
        }
    }, [selectedOu])

    useEffect(() => {
        if (!_.isEmpty(selectedOrg)) {
            getGeoConfigLogs(selectedOrg.id, newSummaryStartDate, newSummaryEndDate, 1, limit, sort, sortOrder, searchValue)
            getGeoConfigLogsCount(selectedOrg.id, newSummaryStartDate, newSummaryEndDate, searchValue)
        } else {
            setTableData([]);
            setCount(0);
        }
    }, [selectedOrg, newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        if(!_.isEmpty(geoConfigLogs)) {
            const data = geoConfigLogs ? geoConfigLogs : [];
            setTableData(data);
        }
        if(!_.isEmpty(geoConfigLogsCount)) {
            const data = geoConfigLogsCount;
            setCount(data?.[0]?.['total']);
        }
    }, [geoConfigLogs, geoConfigLogsCount])

    const convertToCsv = (data: any[][]) => {
        const [_columns, ...rows] = data;
        const csvRows: any[] = [];
        rows.forEach(row => {
            csvRows.push([row[0], row[1], row[2], row[3], row[4], row[5], convertDateTimeIntoTimezone(row[6], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), row[7],  row[8]])
        })
        return [['Organization', 'Rule Name', 'Device Name', 'MCC', 'Action Applied', 'Action Applied Name' ,'Applied At', 'Status', 'Description'], ...csvRows]
    }

    useEffect(() => {
        if (csvDataDownloded && csvDataDownloded.length > 0) {
            if (csvDownloadId === 'geo-logs-table') {
                const [columns, _countRow, ...rows] = csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows]), {
                    formatters : {
                        6: 'ESC-COMMA'
                    }
                });
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [csvDataDownloded])

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        const Page = 1;
        setPage(1);
        doNavigate(params);
        getGeoConfigLogs(selectedOrg.id, newSummaryStartDate, newSummaryEndDate, Page, limit, sort, sortOrder, value)
        getGeoConfigLogsCount(selectedOrg.id, newSummaryStartDate, newSummaryEndDate, value)
    };

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 1;
        setPage(1);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        getGeoConfigLogs(selectedOrg.id, newSummaryStartDate, newSummaryEndDate, Page, limit, field, order, searchValue)
        getGeoConfigLogsCount(selectedOrg.id, newSummaryStartDate, newSummaryEndDate, searchValue)
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const handleChangePage = (e, value) => {
        setPage(value);
        let params: any = getDecodeURI(location?.search);
        value == 1 ? delete params.page : params.page = value;
        doNavigate(params);
        getGeoConfigLogs(selectedOrg.id, newSummaryStartDate, newSummaryEndDate, value, limit, sort, sortOrder, searchValue)
        getGeoConfigLogsCount(selectedOrg.id, newSummaryStartDate, newSummaryEndDate, searchValue)
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        Limit == 15 ? delete params.limit : params.limit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        getGeoConfigLogs(selectedOrg.id, newSummaryStartDate, newSummaryEndDate, Page, Limit, sort, sortOrder, searchValue)
        getGeoConfigLogsCount(selectedOrg.id, newSummaryStartDate, newSummaryEndDate, searchValue)
    }

    const handleDownloadReport = () => {
        let isClickHouse = true;
        const startDate = newSummaryStartDate;
        const endDate = newSummaryEndDate;
        const params: CsvParameters = {
            type: isClickHouse ? 'QUERY_PG' : "QUERY",
            id : "geo-logs-table",
            queryName: 'GET_GEO_LOG_TABLE',
            payload: {
                "dp_id": selectedOrg?.id,
                "start_time": startDate,
                "end_time": endDate,
                "search": searchValue,
                "pageoffset": 0,
                "pagesize": count,
                "sortColumn": sort,
                "sortOrder": sortOrder,
              }
        }
        downloadAsCSV(params);
    }

    const rowsOption = [10, 15, 25, 50, 100]

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    return (
        <Fragment>
            <CommonFilters loading={gettingGeoConfigsCount} helpIcon={false} showWantType={false} showSummaryPeriod={true}></CommonFilters>
            <div className="config-container geo-config-logs-container">
                <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                    <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container topbarMarginPaddingReset alignTopbarItemsCenter">
                        <Grid item xs={4} sm={4} md={4} lg={4} className="site-details-header usage-site-details-header">
                            <Grid className="usage-search-border-color">
                                <Box className="searchbarBox">
                                    {<TextField
                                        id="outlined-basic"                                
                                        variant="outlined"
                                        placeholder="Type something"
                                        size="small"
                                        value={searchValue}
                                        onChange={handleOnSearchChange}
                                        onKeyDown={(event) => searchBoxKeyDown(event)}
                                        InputLabelProps={{ className: "serachLabel1" }}
                                        InputProps={{
                                            className: "searchbarInputs",
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {!searchValue && <SearchIcon
                                                        className="searchbarIconsPosition"
                                                        onClick={handleOnSearchClick}
                                                    />}
                                                    {searchValue && <CloseIcon
                                                        className="searchbarIconsPosition"
                                                        onClick={handleOnSearchClear}
                                                    />}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className="usage-table-search-download-div">
                            {count > 0 && <Grid>
                                <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                            </Grid>}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner tablePaddingReset">
                        <Paper className="pagination-position config-pagination new-usage remove-shadow">
                            <Pagination
                                rowsPerPageOptions={rowsOption}
                                count={count ? Number(count) : 0}
                                rowsPerPage={limit ? limit : 0}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                        <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer" component={Paper}>
                            <Table aria-label="simple sticky table" stickyHeader>
                                <TableHead className="usageReports-tableHead">
                                    <TableRow className="UsageReports-tableRow login-tableRow">
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ORGANIZATION)}>ORGANIZATION</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === ORGANIZATION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, ORGANIZATION, ASC_ORDER)} />
                                                    <img src={sort === ORGANIZATION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                        className="sortingImages" onClick={(event) => setSorting(event, ORGANIZATION, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, RULE_NAME)}>RULE NAME</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === RULE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, RULE_NAME, ASC_ORDER)} />
                                                    <img src={sort === RULE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                        className="sortingImages" onClick={(event) => setSorting(event, RULE_NAME, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-usage-sub-th">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, DEVICE_NAME)}>DEVICE NAME</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === DEVICE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DEVICE_NAME, ASC_ORDER)} />
                                                    <img src={sort === DEVICE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                        className="sortingImages" onClick={(event) => setSorting(event, DEVICE_NAME, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-usage-sub-th">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, MCC)}>MCC</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === MCC && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, MCC, ASC_ORDER)} />
                                                <img src={sort === MCC && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, MCC, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-usage-sub-th">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, APPLIED_ACTION)}>APPLIED ACTION</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === APPLIED_ACTION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, APPLIED_ACTION, ASC_ORDER)} />
                                                <img src={sort === APPLIED_ACTION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, APPLIED_ACTION, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-usage-sub-th">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, APPLIED_ACTION_NAME)}>APPLIED ACTION VALUE</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === APPLIED_ACTION_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, APPLIED_ACTION_NAME, ASC_ORDER)} />
                                                <img src={sort === APPLIED_ACTION_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, APPLIED_ACTION_NAME, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-topups-sub-th">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, APPLIED_AT)}>APPLIED AT</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === APPLIED_AT && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, APPLIED_AT, ASC_ORDER)} />
                                                <img src={sort === APPLIED_AT && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, APPLIED_AT, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-usage-sub-th">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, STATUS)}>STATUS</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === STATUS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, STATUS, ASC_ORDER)} />
                                                <img src={sort === STATUS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, STATUS, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-loggen-in">
                                            <span>
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, DESCRIPTION)}>DESCRIPTION</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === DESCRIPTION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DESCRIPTION, ASC_ORDER)} />
                                                <img src={sort === DESCRIPTION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, DESCRIPTION, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {count > 0 && tableData && 0 != tableData?.length ? (
                                    <TableBody className="tableBody usage-tableBody Table-Body-Opacity">
                                        {
                                            tableData && tableData?.map((item, i) => (
                                                <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                                    <Tooltip title={item.subscriber}>
                                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.organiztion}</TableCell>
                                                    </Tooltip>
                                                    <TableCell className='login-left-align inter-font Table-Cell-Opacity elipseThreat'>{item?.rule_name}</TableCell>
                                                    <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.device_name}</TableCell>
                                                    <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.mcc}</TableCell>
                                                    <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.applied_action}</TableCell>
                                                    <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.applied_action_name}</TableCell>
                                                    <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{convertDateTimeIntoTimezone(item?.applied_at, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                                    <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.status}</TableCell>
                                                    <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.description}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,  
  selectedOu: state.authReducer.selectedOu,
  geoConfigLogs: state.geoConfigManager.geoConfigLogs,
  geoConfigLogsCount: state.geoConfigManager.geoConfigLogsCount,
  userTimezone: state.authReducer.userTimezone,
  newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
  newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
  csvDataDownloded: state?.authReducer?.csvDataDownloded,
  csvDownloadId: state?.authReducer?.csvDownloadId,
  gettingGeoConfigsCount: state.geoConfigManager.gettingGeoConfigsCount
});

export default withRouter(
  connect(mapStateToProps, { getGeoConfigLogs, getGeoConfigLogsCount, downloadAsCSV, updatePermission })(GeoLogs)
);