import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import JSONPretty from "react-json-pretty";
import './JsonTooltip.css';
import _ from 'lodash';
import React from 'react';

interface JsonTooltipProps {
    json: string;
    className?: string;
}

interface JsonDataTooltipProps {
    data: any;
    className?: string;
}

const JsonTooltip = ({ className, json, ...props }: JsonTooltipProps) => (
    <Tooltip classes={{tooltip: 'jsonTooltip'}}  title={_.isEmpty(json)?"":<pre className='scrollTooltip'>{JSON.stringify(JSON.parse(json), null, 2)}</pre>} {...props} placement="left-start" arrow>
        <IconButton>
            <InfoIcon />
        </IconButton>
    </Tooltip>
);

const isValidJson = (str: string) => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
};

export const JsonDataTooltip = ({ className, data, ...props }: JsonDataTooltipProps) => (
    <Tooltip classes={{tooltip: 'jsonTooltip'}}  title={!_.isEmpty(data) && typeof data === "object" && !Array.isArray(data) 
        ? <pre className='scrollTooltip'>{JSON.stringify(data, null, 2)}</pre>
        : (!_.isEmpty(data) && typeof data === "string" 
         ? isValidJson(data)
            ? <pre className='scrollTooltip'>{JSON.stringify(JSON.parse(data), null, 2)}</pre>
             : <pre className='scrollTooltip'>{data}</pre>
              :"")} {...props} placement="left-start" arrow>
        <IconButton>
            <InfoIcon />
        </IconButton>
    </Tooltip>
);


export default JsonTooltip;