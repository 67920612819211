import { Autocomplete, Button, Dialog, Divider, Grid, TextField, Typography } from '@mui/material';
import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import close from "../../../asset/image/close.png";
import ThreatKontrolActions from "./ThreatKontrolActions";
import {createThreatKontrol} from "../../../actions/Users/authenticateThreatDashboard";
import {getGroupList, getDeviceList } from "../../../actions/Users/authenticateInventory";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import InfoIcon from '@mui/icons-material/Info';
import { JsonDataTooltip } from "../../../components/JSONTooltip/JsonTooltip";
import {  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Checkbox, ListItemText, AutocompleteChangeReason, AutocompleteChangeDetails, Chip, FormHelperText, IconButton, Tooltip, FormControlLabel, ListSubheader } from '@mui/material';

interface ThreatKontrolDialogProps {
    open: boolean,
    setOpen: any,
    row: any
}

interface ThretaKontrolAction {
    id: number;
    severity: string;
    class: string[];
    classOptions: string[];
    threatName: any[];
    threatNameOptions: string[]; 
    meas: string;
    count: number;
    action: string;
}

interface User {
    name: string;
    email: string;
}

const ThreatKontrolDialog = (props) => {
    const {authReducer, createThreatKontrol, getDeviceList, getGroupList, users, open, setOpen, row} = props;
    const Heading = "Set Threat Kontrol";
    const targetTypes = ["Device", "Site", "Managed Group"];
    const [targetType, setTargetType] = useState<string>("");
    const [email, setEmail] = useState<any[]>([]);
    const [groupList, setGroupList] = useState<any[]>([]);
    const [siteList, setSiteList] = useState<any[]>([]);
    const [deviceList, setDeviceList] = useState<any[]>([]);
    const [targets, setTargets] = useState<any[]>([]);
    const [emailSelected, setEmailSelected] = useState<boolean>(false);
    const [teamsSelected, setTeamsSelected] = useState<boolean>(false);
    const [measure, setMeasure] = useState<string>("");
    const [threatKontrolName, setThreatKontrolName] = useState<string>('');
    const [thretaKontrolActions, setThretaKontrolActions] = useState<ThretaKontrolAction[]>([]) 
    const [inputValue, setInputValue] = useState("");
    const [isAllSupress, setIsAllSupress] = useState<boolean>(true);

    const getParentAndSubDpIds = () => {
        const _dpIds: any = [];
        if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name) {
            const parentDpId = authReducer?.selectedOu?.id
            const dpIds = authReducer?.parentDpAndSubDpList
            if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name && !_.isEmpty(authReducer.parentDpAndSubDpList)) {
            const parentDpId = authReducer?.selectedOu?.id
            const dpIds = authReducer?.parentDpAndSubDpList
            if (dpIds.hasOwnProperty(parentDpId)) {
                _dpIds.push(parentDpId)
                getChildren(_dpIds, dpIds[parentDpId])
            }
        }
            _dpIds.push(parentDpId)
        }
        return _dpIds
    }

    const getChildren = (dpIds, dps) => {
        for (let i = 0; i < dps?.length; i++) {
            let id = dps[i]?.id;
            dpIds.push(id);
            if (dps[i].hasOwnProperty('_childern') && dps[i]['_childern']?.length > 0) {
                getChildren(dpIds, dps[i]['_childern'])
            }
        }
    }
    useEffect(() => {
        if (row && Object.keys(row).length > 0) {
            setThretaKontrolActions((prevActions) => [
                ...prevActions,
                {
                id: thretaKontrolActions.length,
                severity: row.severity || "",
                class: row.type ? [row.type] : [],
                classOptions: [],
                threatName: [{class: row.type ? row.type : "", threat_name: row.threat_name ? row.threat_name: ""}],
                threatNameOptions: [],
                meas: '',
                count: parseInt(row.total_hits) || 0,
                action: "",
                }
            ]);
        }
    }, []);

    useEffect(() => {

        if (!_.isEmpty(authReducer.getVesselsListing)) {
            let dpIds = getParentAndSubDpIds();
            let _dpIds = dpIds.map(x => "'" + x + "'").toString();
            const payloads = {
                "query": "GET_INVENTORY_GROUPS",
                "named": true,
                "format": "json",
                "parameters": {
                    "dp": _dpIds,
                    "search": "",
                    "page_start": 0,
                    "page_size": 100,
                    "sort_by": "update_time",
                    "sort_order": "desc"
                }
            };

            getGroupList(payloads);

            let ouName = authReducer.selectedOu?.name;
            getDeviceList(ouName);
        }
    }, [authReducer.getVesselsListing])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getInventoryGroupList)) {
            const data = authReducer.getInventoryGroupList?.data;
            setGroupList(data?.map((ele) => {
                return {
                    name: ele.name,
                    id: ele.id
                };
            }))
        }

    }, [authReducer.getInventoryGroupList]);

    useEffect(() => {
        if (!_.isEmpty(authReducer.getDeviceList)) {
            const data = authReducer.getDeviceList?.data;

            const siteListOptions: { name: string, id: number }[] = [];
            const deviceListOptions: { name: string, id: number }[] = [];
            data.forEach((ele) => {
                siteListOptions.push({
                    name: ele.location_name,
                    id: ele.location_id
                });
                ele?.devices.forEach((eleDevice) => {
                        deviceListOptions.push({
                            name: eleDevice.name,
                            id: eleDevice.id
                        });
                });
            })
            setSiteList(siteListOptions);
            setDeviceList(deviceListOptions);
        }

    }, [authReducer.getDeviceList]);

    useEffect(() => {
        const allSuppressed = thretaKontrolActions.every((threatAction) => threatAction.action === "Suppression");
        setIsAllSupress(allSuppressed);
    },[thretaKontrolActions])

    const handleTargetTypeChange = (event, value) => {
        setTargetType(value);
        setTargets([]);
    }
    const handleTargetsChange = (event, value) => {
        setTargets(value);
    }
    const handleMeasureChange = (event, meas) => {
        setMeasure(meas);
    }
    const handleThreatKontrolNameChange = (event, newInputValue) => {
        setThreatKontrolName(newInputValue);
    }
    const handleClose = () => {
        handleCancel();
        setOpen(false);
    }
    const isValidEmail = (emailString: string): boolean => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailString.trim());
    };
    
    const handleInputEmailChange = (event, newInputValue) => {
        if (newInputValue.endsWith(",")) {
            const emailString = newInputValue.slice(0, -1).trim();
            if (isValidEmail(emailString)) {
                const newEmail = { name: emailString, email: emailString };
                if (!email.some(item => item.email === newEmail.email)) {
                    setEmail(prev => [...prev, newEmail]);
                }
            }
            else{
                alert("Enter Valid Email");
            }
            setInputValue("")
        } else {
            setInputValue(newInputValue);
        }
    }
    const handleEmailChnage = (event, value) => {
        setEmail(value);
    }
    const handleEmailChange = (event) => {
        setEmailSelected(event.target.checked);
    };

    const handleTeamsChange = (event) => {
        setTeamsSelected(event.target.checked);
    };
    const handleCancel = () => {
        setThreatKontrolName("");
        setTargetType("");
        setTargets([]);
        setThretaKontrolActions([]);
        setEmail([]);
        setEmailSelected(false);
        setTeamsSelected(false);
        setOpen(false);
    }

    const areAllFieldsFilled = () => {
        return thretaKontrolActions.length && thretaKontrolActions.every(action =>
            action.severity &&
            action.class.length > 0 &&
            action.threatName.length > 0 &&
            (action.action == "Suppression" || (action.meas &&
            action.count !== null)) &&
            action.action && 
            threatKontrolName &&
            (isAllSupress || teamsSelected || (emailSelected && email.length > 0))
        );
    };

    const handleSave = async () => {
        const notificationMethods: string[] = [];
        if (emailSelected) notificationMethods.push("email");
        if (teamsSelected) notificationMethods.push("microsoft_teams");
        if (!targetType || targets.length === 0 || thretaKontrolActions.length === 0) {
            alert('Please fill in all required fields before saving.');
            return;
        }
        try {
            
          const payload = {
            ...(targetType === "Managed Group" && { groupIds: targets.map(target => target.id) }),
            ...(targetType === "Site" && { siteIds: targets.map(target => target.id) }),
            ...(targetType === "Device" && { deviceIds: targets.map(target => target.id) }),
            control_name: threatKontrolName,
            threat_actions: thretaKontrolActions.map(action => ({
                threat_names_classes: action.threatName,
                severity: action.severity,
                ...(action.action !== "Suppression" ? { measurement_period: action.meas } : {}),
                ...(action.action !== "Suppression" ? { count: Number(action.count) } : {}),
                action: action.action
            })),
            notification_methods: notificationMethods,  
            users_to_notify: email 
        };
          createThreatKontrol(payload)
          handleCancel();
          setOpen(false);
        } catch (error) {
            alert('Failed to save data. Please try again.');
        }
    };

    return (
        <Dialog className="menuScroll threatKontrolDialogbox" onClose={handleClose} open={open} maxWidth="lg" fullWidth>
            <div className="displayFlex threatKontrolMargin">
                <div className="threatKontrolHeading">{Heading}</div>
                <div className='threatKontrolClose'>  <img className="threatKontrolCloseIcon"
                    src={close}
                    alt='close'
                    onClick ={handleClose}>
                    </img>
                </div>
            </div>
            <Divider className="threatKontrolDivider"/>
            <div className="threatKontrolNameMargin threatkontrolSelectorGap">
                <Autocomplete
                    id="threatKontrolName"
                    size="small"
                    fullWidth
                    options={[]}
                    value={threatKontrolName}
                    freeSolo
                    onInputChange={handleThreatKontrolNameChange}
                    renderInput={(params) => <TextField {...params} label="ThreatKontrolName *" title="ThreatKontrolName" variant="outlined" />}
                />
            </div>
            <div className="threatKontrolMargin">
                <div className="threatSourceInfo threatKontrolSubheading">
                    <Typography>Threat Source</Typography>
                    <JsonDataTooltip data={"runs every 5 minutes"}/>
                </div>
                <div className="threatkontrolSelectorGap">
                <Autocomplete
                    id="targettype"
                    disableCloseOnSelect
                    options={targetTypes}
                    value={targetType}
                    onChange={handleTargetTypeChange}
                    size="small"
                    renderInput={(params) => <TextField {...params} label="Target Type *" variant="outlined" style={{width:"200px"}}/>}
                />
                <Autocomplete
                    id="user_terminals"
                    multiple
                    disableCloseOnSelect
                    fullWidth
                    options={
                        targetType === "Site" ? siteList :
                        targetType === "Device" ? deviceList :
                        targetType === "Managed Group" ? groupList : []
                    }
                    onChange={handleTargetsChange}
                    value={targets}
                    size="small"
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Target *" variant="outlined"/>}
                />
                </div>
                <br></br>
                <ThreatKontrolActions thretaKontrolActions={thretaKontrolActions} setThretaKontrolActions={setThretaKontrolActions}/>
            </div>
            <hr className="threadKontrolhr"></hr>
            <div className="threatKontrolMargin threatEmailSelect">
                <Typography className="threatKontrolSubheading">Notification *</Typography>
                <div className="threatkontrolRuleCheckbox">
                    <Checkbox size='small' name={'email'} checked={emailSelected} onChange={handleEmailChange} className="threatKontrolCheckbox" disabled={isAllSupress}/>
                    <Typography variant="body2" className="threatKontrolCheckbocName">
                        Email
                    </Typography>
                </div>
                <div className="threatkontrolEmailGap"></div>
                <Autocomplete
                    id="select-device"
                    size='small'
                    multiple
                    fullWidth
                    options={users}
                    freeSolo
                    getOptionLabel={(option) => option.name}
                    onInputChange={handleInputEmailChange}
                    onChange={handleEmailChnage}
                    inputValue={inputValue}
                    value={email}
                    disabled={isAllSupress}
                    renderInput={(params) => <TextField {...params} label="Enter Email Id*   (end with , if typed)" variant='outlined' />}
                />
                <div className="threatkontrolEmailGap"></div>
                <div className="threatkontrolRuleCheckbox">
                    <Checkbox size='small' name={'email'} checked={teamsSelected} onChange={handleTeamsChange} disabled={isAllSupress}/>
                    <Typography variant="body2" className="threatKontrolCheckbocName">
                        Microsoft Teams
                    </Typography>
                </div>
            </div>
            <Divider className="threatKontrolDivider"/>
            <div className="displayFlex threatKontrolMargin">
                <Button id="threatKontrolCancelButton" onClick ={handleCancel}>
                    Cancel
                </Button>
                <Button id= {areAllFieldsFilled() ? "threatKontrolSaveButton" : "threatKontrolgreySaveButton"} onClick={handleSave} disabled={!areAllFieldsFilled()}>
                    Save
                </Button>
            </div>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
})
export default withRouter(
    connect(mapStateToProps, { getGroupList, getDeviceList, createThreatKontrol })(ThreatKontrolDialog)
);