import React from 'react';
import { Chip, Stack, Tooltip } from '@mui/material';

const ThreatChips = (props) => {
    const { threatInfo, onDelete } = props;
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {/* Dynamically create chips for each key-value pair in the threatInfo object */}
            {Object.entries(threatInfo).map(([key, value], index) => {
                // Check if the value is not empty, not null, and not undefined
                if (value !== '' && value !== null && value !== undefined) {
                    return (
                        <Tooltip key={index} title={key} arrow>
                            <Chip
                                label={`${value}`} // Dynamic key-value label
                                onDelete={() => onDelete(key)} // Call the parent's delete handler
                            />
                        </Tooltip>
                    );
                }
                // If the value is empty, null, or undefined, don't render the chip
                return null;
            })}
        </Stack>
    );
};

export default ThreatChips;
