import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Grid, ButtonGroup, Button } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment-timezone';
import _ from "lodash";
import { getUserLoginHistoryDetails } from "../../actions/Users/authenticateService";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "./../../utils/constants";
import { PER_PAGE_ITEMS } from "./../../constants/Constants";
import { LOGIN_HISTORY_INTERVALS } from "../../constants/Constants";
import ascSorting from "../../asset/image/ascSorting.svg";
import descSorting from "../../asset/image/descSorting.svg";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";

export const UserLoginHistory = ({
    username,
    authReducer,
    errorReducer,
    getUserLoginHistoryDetails
}) => {

    const [userLoginHistoryData, setUserLoginHistoryData] = useState([]);
    const [interval, setIntervalValue] = useState({
        value: '1d'
    });
    const [perPageLimit, setPerPageLimit] = useState(10);
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [fromItem, setFromItem] = useState(0);
    const [toItem, setToItem] = useState(0);
    const [sortOrder, setSortOrder] = useState("desc");
    const [sort, setSort] = useState("requestAt");
    const [searchValue, setSearchValue] = useState("");
    const [filterByValue, setFilterByValue] = useState("");
    const [search, setSearch] = useState("");
    const [filterBy, setFilterBy] = useState("");
    const [showSearchIcon, setShowSearchIcon] = useState(true);

    const FILTER_BY_LIST = [
        { label: "Application", value: "resource" }
    ];

    useEffect(() => {
        setUserLoginHistoryData([]);
        getUserLoginHistoryDetails(username, interval.value, perPageLimit, activePage, sort, sortOrder, searchValue, filterByValue);
    }, [interval, perPageLimit, activePage, sort, sortOrder, searchValue, filterByValue])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getUserLoginHistory)) {
            const data: any = authReducer.getUserLoginHistory.data.rows;
            setTotalPages(authReducer.getUserLoginHistory.data.pages);
            setTotalRows(authReducer.getUserLoginHistory.data.totalRows);
            setUserLoginHistoryData(data);

            let totalRows = authReducer.getUserLoginHistory.data.totalRows;
            let fromIndex: any = totalRows > 0 ? (activePage - 1) * perPageLimit + 1 : 0;
            let toIndex: any = (fromIndex + perPageLimit - 1) > totalRows ? totalRows : (fromIndex + perPageLimit - 1);
            setFromItem(fromIndex);
            setToItem(toIndex);
        }
    }, [authReducer.getUserLoginHistory])

    const handleIntervalChange = (e: Event, row: any) => {
        const value: any = row.value;
        setActivePage(1);
        setIntervalValue({
            value: value
        });
    }

    const handlePerPageChange = (e: any) => {
        const limit: any = parseInt(e?.target?.textContent);
        setActivePage(1);
        setPerPageLimit(limit);
    }

    const handlePagination = (e: any, value: any) => {
        if (value != activePage) {
            setActivePage(value);
        }
    }

    const switchSorting = (e: any, value: any) => {
        setSort(sort);
    }

    const setUserLoginHistorySorting = (e: any, sort: any, order: any) => {
        setSort(sort);
        setSortOrder(order);
    }

    const handleFilterSearchBy = (e: any, value: any) => {
        setFilterBy(value);
    }

    const doSearch = () => {
        if (!_.isEmpty(filterBy) && !_.isEmpty(search)) {
            setActivePage(1);
            setSearchValue(search);
            setFilterByValue(filterBy);
            setShowSearchIcon(false);
        }
    }

    const clearSearch = () => {
        setActivePage(1);
        setSearch("");
        setFilterBy("");
        setSearchValue("");
        setFilterByValue("");
        setShowSearchIcon(true);
    }

    return (
        <div>
            <Grid container>
                <Grid item container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={3} sm={3} md={3} lg={3} className="filterAlertCss" style={{ marginTop: "8px" }}>
                        <Autocomplete
                            id="status_list" className="fliterAlertDropdown filterAction filterBy"
                            options={FILTER_BY_LIST}
                            onChange={(e, newValue: any) => { handleFilterSearchBy(e, newValue?.value) }}
                            // defaultValue={defaultFilterSearchValue}
                            renderInput={(params) => (
                                <TextField {...params} label="Filter By" InputLabelProps={{ className: "filterStateName" }} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                        <Box>
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Type something"
                                autoComplete="off"
                                classes={{root: "input-box-usr-lgn-hist"}}
                                size="small"
                                value={search}
                                onChange={(event) => {
                                    setSearch(event.target.value);
                                }}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {showSearchIcon ? <SearchIcon
                                                className="cursorPointer input-search-icons" onClick={doSearch}
                                            />
                                                :
                                                <CloseIcon
                                                    className="cursorPointer input-search-icons" onClick={clearSearch}
                                                >
                                                </CloseIcon>

                                            }
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    >
                        <Grid>
                            <Grid className="vesselPageDetails">Per Page</Grid>
                            {<Grid className="vesselPagination"> {`${fromItem} to ${toItem} out of ${totalRows} Records`}</Grid>}
                        </Grid>
                        <Grid style={{ marginLeft: "10px" }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={PER_PAGE_ITEMS}
                                className="props.classes.paper"
                                sx={{ width: 65 }}
                                disableClearable
                                onChange={(e: any) => {
                                    handlePerPageChange(e);
                                }}
                                value={{ label: `${perPageLimit}` }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        InputLabelProps={{ className: "autoCompleteBox" }}
                                    />
                                )}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ fontSize: "12px" }}>{children}</Paper>
                                )}
                            />
                        </Grid>
                        <Grid
                            container
                            key="pagination-list"
                            style={{
                                marginLeft: "5px",
                                alignItems: "center",
                                width: "auto",
                            }}
                        >
                            <Stack>
                                <Pagination
                                    className="paginationContainer"
                                    count={totalPages}
                                    page={activePage}
                                    size="small"
                                    onChange={handlePagination}
                                    variant="outlined"
                                    shape="rounded"
                                    siblingCount={0}
                                    defaultPage={1}
                                    boundaryCount={0}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                        <Grid style={{
                            display: "flex",
                            alignItems: "right",
                            justifyContent: "flex-end",
                            paddingRight: "24px",
                            marginTop: "10px"
                        }}>
                            <ButtonGroup key="interval_group" className="interval_group" variant="outlined" aria-label="Interval group">
                                {
                                    LOGIN_HISTORY_INTERVALS.map((row, i) => (
                                        <Button key={`interval_${i}`} variant={(interval && Object(interval).value === row.value) ? "contained" : "outlined"}
                                            aria-label="Interval"
                                            onClick={(e: any) => { handleIntervalChange(e, row); }}>{row.label}</Button>
                                    ))
                                }
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{
                    paddingRight: "24px",
                    paddingBottom: "10px"
                }}>
                    <TableContainer className="FleetDashboard">
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow className="tableHeaderFleetVesselData">
                                    <TableCell className="fleetDataTable account-column" align="left">
                                        <span className="align-items-center">
                                            <span style={{ cursor: 'pointer' }} onClick={(event) => switchSorting(event, "requestAt")}>REQUEST AT</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === "requestAt" && sortOrder === "asc" ? ascSortingSelected : ascSorting}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setUserLoginHistorySorting(event, "requestAt", "asc")} />
                                                <img src={sort === "requestAt" && sortOrder === "desc" ? descSortingSelected : descSorting}
                                                    className="sortingImages" onClick={(event) => setUserLoginHistorySorting(event, "requestAt", "desc")} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable account-column" align="left">IP ADDRESS</TableCell>
                                    <TableCell className="fleetDataTable account-column" align="left">APPLICATION</TableCell>
                                    <TableCell className="fleetDataTable account-column" align="left">LOGIN AT</TableCell>
                                    <TableCell className="fleetDataTable account-column" align="left">LOGGED IN</TableCell>
                                    <TableCell className="fleetDataTable account-column" align="left">LOGOUT AT</TableCell>
                                    <TableCell className="fleetDataTable account-column" align="left">DESCRIPTION</TableCell>
                                </TableRow>
                            </TableHead>
                            {0 != userLoginHistoryData.length ? (
                                <TableBody>
                                    {
                                        userLoginHistoryData.map((row, i) => (
                                            <TableRow key={`history_${i}`} className={i % 2 ? "fleetRowRank fleetRowRank-odd" : "fleetRowRank fleetRowRank-even"}>
                                                <TableCell>{Object(row).requestAt ? moment(new Date(Object(row).requestAt)).utc().format(MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : '-'}</TableCell>
                                                <TableCell>{Object(row).ipAddress}</TableCell>
                                                <TableCell>{Object(row).resource}</TableCell>
                                                <TableCell>{Object(row).loginAt ? moment(new Date(Object(row).loginAt)).utc().format(MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : '-'}</TableCell>
                                                <TableCell>{Object(row).isLoggedIn ? 'Success' : 'Failed'}</TableCell>
                                                <TableCell>{Object(row).logoutAt ? moment(new Date(Object(row).logoutAt)).utc().format(MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : "-"}</TableCell>
                                                <TableCell>{Object(row).description}</TableCell>
                                            </TableRow>
                                        )
                                        )
                                    }
                                </TableBody>
                            ) :
                                (
                                    <TableBody>
                                        <TableRow className="fleetRowRank fleetRowRank-odd">
                                            <TableCell colSpan={7} align="center" className="fleetRowDataDetails"> No data available </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )
                            }
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
};

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, { getUserLoginHistoryDetails })(UserLoginHistory)
);

