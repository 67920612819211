import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Stack, TextField, Tooltip } from "@mui/material";
import close from "../../asset/image/close.svg";
import DP_Dropdown from "../../components/InventoryWarehouseTable/DP_Dropdown";
import SelectTag from "../../components/InventoryWarehouseTable/SelectTag";
import { getDecodeURI, getEncodedURI, getTimezoneCity, strToK4List } from "../../utils/util";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Moment } from "moment-timezone";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import _ from "lodash";
import { getAllUsernames, createDownloadService, getAllVlans, getAllApplications } from '../../actions/Users/authenticateUsageReports';
import { fetchResources } from '../../actions/Users/authenticate';
import { toast } from "react-toastify";
import { UsageContext } from "./UsageDataContext";
import { UPDATE_NEW_SUMMARY_END_DATE, UPDATE_NEW_SUMMARY_START_DATE } from "../../actions/types";
import HelpIcon from '@mui/icons-material/Help';

const FiltersPopup = (props) => {
    const { authReducer, generateFilter, setGenerateFilter, getAllUsernames, fetchResources, createDownloadService, getAllVlans, getAllApplications, topUsersCount, topSitesCount, location, history } = props;
    const { setTrackLength, selectTab, checkUser } = useContext(UsageContext);
    let _q = getDecodeURI(location?.search);
    const [startDate, setStartdate] = useState<any>(_q.hasOwnProperty("startDate") ? moment(new Date(parseInt(_q.startDate))) : moment(Date.now()).subtract(1, 'hour'));
    const [endDate, setEnddate] = useState<any>(_q.hasOwnProperty("endDate") ? moment(new Date(parseInt(_q.endDate))) : moment(Date.now()));
    const [sites, setSites] = useState<any>(_q.hasOwnProperty('k4Ids') && _q.k4Ids != '' ? [...strToK4List(_q.k4Ids)?.map((ele) => { return { id: ele["id"], name: ele['vessel-name'] } })] : []);
    const [userNamesList, setUserNamesList] = useState<any>([]);
    const [sitesList, setSitesList] = useState<any>([]);
    const WAN_ARRAY = [
        { label: "All", value: "" },
        { label: "CELLULAR", value: "lte" },
        { label: "VSAT", value: "vsat" },
        { label: "STARLINK", value: "starlink" },
        { label: "LBAND", value: "lband" },
        { label: "ETHERNET", value: "wired" },
        { label: "WIFI", value: "wifi" },
        { label: 'BOND', value: 'bond' }
    ];
    const [selectedFilter, setSelectedFilter] = useState<any>(
        {
            userName : _q.hasOwnProperty('userName') && _q.userCheck == 'hostname' ? _q.userName : '',
            userIp : _q.hasOwnProperty('userName') && _q.userCheck == 'ipAddress' ? _q.userName : '',
            userMac : _q.hasOwnProperty('userName') && _q.userCheck == 'macAddress' ? _q.userName : '',
            category : _q.hasOwnProperty('category') ? _q.category : '',
            appFilt : _q.hasOwnProperty('appFilt') ? _q.appFilt : '',
            vlan : _q.hasOwnProperty('vlan') ? _q.vlan : '',
            wanType : _q.hasOwnProperty('wanType') ? _q.wanType : '',
        }
    );
    const [selectedOu, setSelectedOu] = useState<any>({});
    const [vessels, setVessls] = useState([]);
    
    const [serviceName, setServiceName] = useState("")
    const [serviceFunction, setServiceFunction] = useState("");
    const [enableDPI, setEnableDPI] = useState(false);
    const [vlanList, setVlanList] = useState([]);
    const [appList, setAppList] = useState([]);
    const categoryList = [
        {label: 'Advertiser'},
        {label: 'Arts and Entertainment'},
        {label: 'Business'},
        {label: 'Career and Education'},
        {label: 'CDN'},
        {label: 'Cybersecurity'},
        {label: 'Device/IoT'},
        {label: 'File Sharing'},
        {label: 'Financial'},
        {label: 'Games'},
        {label: 'Government'},
        {label: 'Hosting'},
        {label: 'ISP/Telco'},
        {label: 'Mail'},
        {label: 'Malware'},
        {label: 'Messaging'},
        {label: 'News'},
        {label: 'OS/Software Updates'},
        {label: 'Portal'},
        {label: 'Recreation'},
        {label: 'Reference'},
        {label: 'Remote Desktop'},
        {label: 'Shopping'},
        {label: 'Social Media'},
        {label: 'Sports'},
        {label: 'Streaming Media'},
        {label: 'Technology'},
        {label: 'VoIP/Conferencing'},
        {label: 'VPN and Proxy'}
    ]
    const dispatch = useDispatch();
    const [userCheckFilter, setUserCheckFilter] = useState(checkUser);
    const [format, setFormat] = useState('xlsx');

    useEffect(()=> {
        const service = JSON.parse(localStorage.getItem('permissions') || '');
        service?.map((list)=>{
          if(list.service == 'iam'){
            setServiceName(list.service)
            setServiceFunction(list?.servicefunctions[0]?.function)
          }
        })
    },[])
    
    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing))
            return;
        let vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            vessels = filteredVessels;
        } else {
            vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessls(vessels);
    },[authReducer?.getVesselsListing, authReducer?.filteredVessels])

    useEffect(() => {
        if(vessels.length > 0) {
            getAllUsernames('', vessels, startDate, endDate);
            getAllVlans(vessels, startDate, endDate);
            getAllApplications(vessels, startDate, endDate);
        }
    }, [vessels])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAllUsernames)) {
            const data = authReducer?.getAllUsernames?.hasOwnProperty('data') ? authReducer?.getAllUsernames?.data: {};
            if (data) {
                const rows: any[] = data?.rows;
                const columns: string[] = data?.columns;
                const usernames: any[] = [];
                for (let i = 0; i < rows?.length; i++) {
                    const row = {};
                    const currentRow = rows[i];
                    columns.forEach((column, index) => {
                        row[column] = currentRow[index];
                    })
                    usernames.push(row);
                }
                const users = usernames.filter(value => value.userName && value.userName != " ").map(value => {return {label: value?.userName, userFilter: value?.userFilter}});
                users?.length > 0 ? setUserNamesList(Array.from(new Set(users))) : setUserNamesList([]);
            }
            authReducer.getAllUsernames = {};
        }

        if(!_.isEmpty(authReducer.getAllVlans)) {
            const vlans = authReducer.getAllVlans.hasOwnProperty('data') ? authReducer.getAllVlans.data : {};
            const vlanList = vlans?.rows?.map((item) => {
                return vlans?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            vlanList?.length > 0  ? setVlanList(Array.from(new Set(vlanList.filter(vlan => vlan.vlanName).map(value => { return {label: value.vlanName}})))) : setVlanList([]);
            authReducer.getAllVlans = {};
        }

        if(!_.isEmpty(authReducer.getAllApplications)) {
            const apps = authReducer.getAllApplications.hasOwnProperty('data') ? authReducer.getAllApplications.data : {};
            const appList = apps?.rows?.map((item) => {
                return apps?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            appList?.length > 0 ? setAppList(Array.from(new Set(appList.map(value => { return {label: value.appList}})))) : setAppList([]);
            authReducer.getAllApplications = {};
        }
    }, [authReducer?.getAllUsernames, authReducer.getAllVlans, authReducer.getAllApplications])

    const hanldeChangeSelect = (e, newValue, key) => {
        if(newValue && newValue.userFilter == 'hostname') {
            setSelectedFilter({ ...selectedFilter, userName: newValue.label, userIp: '', userMac: '' })
        } else if(newValue && newValue.userFilter == 'ipAddress') {
            setSelectedFilter({ ...selectedFilter, userIp: newValue.label, userName: '', userMac: '' })
        } else if(newValue && newValue.userFilter == 'macAddress') {
            setSelectedFilter({ ...selectedFilter, userMac: newValue.label, userName: '', userIp: '' })
        } else if(newValue) {
            setSelectedFilter({ ...selectedFilter, [key]: newValue.label })
        } else {
            setSelectedFilter({...selectedFilter, [key]: '', userIp: '', userMac: ''})
        }
        setUserCheckFilter(newValue?.userFilter)
    }

    const handleStartDatePicker = (date: Moment | null) => {
        if(date){
            setStartdate(date);
        }
    }

    const handleEndDatePicker = (date: Moment | null) => {
        if(date) {
            setEnddate(date);
        }
    }

    const handleOu = (ou: any) => {
        setSelectedOu(ou);
        if(authReducer?.selectedOu?.name != ou.name) {
            setSites([{ name:"All Sites", type:"Site" }])
        }
        fetchResources(ou.name, 0, 500, serviceName, serviceFunction);
    }

    useEffect(() => {
        if (!_.isEmpty(authReducer?.fetchResources)) {
            let resourceRecords = authReducer.fetchResources?.locations;
            if (resourceRecords && Array.isArray(resourceRecords)) {
                let resourceSort = resourceRecords.map((index, i) => index.name);
                resourceSort.sort();
                let sortedResourceNames: any[] = resourceRecords.sort((a: any, b: any) => {
                  return ('' + a.name).localeCompare(b.name);
                })
                let externalAllVesselObj = {name: "All Sites", description: "", type: "Site", is_all: true}
                sortedResourceNames?.unshift(externalAllVesselObj);
                let defaultAllVessel =  "All Sites"
                let all_sites = sortedResourceNames.find(item => item.name === defaultAllVessel)
                sortedResourceNames = sortedResourceNames.filter(item => item.name !== defaultAllVessel)
                if(all_sites){
                    sortedResourceNames.unshift(all_sites);
                    !_q.hasOwnProperty('k4Ids') && setSites([all_sites]);
                }
                setSitesList(sortedResourceNames);
            } else {
                setSitesList([]);
            }
            authReducer.fetchResources = {};
        }
    }, [authReducer.fetchResources]);

    const handleChangeSite = (e, newValue) => {
        setSites([...newValue]);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleClose = () => {
        setGenerateFilter(false);
        if(_q.hasOwnProperty('filterClick')) {
            let params: any = getDecodeURI(location?.search);
            delete params.filterClick;
            doNavigate(params);
        }
        else if(_q.hasOwnProperty('startDate')) {
            const start = moment(Date.now()).utc().subtract(1, 'hour');
            const end = moment(Date.now()).utc();
            dispatch({ type: UPDATE_NEW_SUMMARY_END_DATE, payload: end})
            dispatch({ type: UPDATE_NEW_SUMMARY_START_DATE, payload: start})
            let params: any = getDecodeURI(location.search);
            delete params.startDate;
            delete params.endDate;
            params.interval = '1h';
            doNavigate(params);
            setTrackLength(false);
        }
    }

    const getInterval = (minutes) => {
        if (minutes > 10080) {
          return true;
        } 
        return false;
    }

    const getIntervalBin = (minutes) => {
        if (minutes <= 1440) {
          return "15m";
        } else if (minutes > 1440 && minutes <= 10080) {
          return "1h";
        } else {
          return "4h";
        }
    }

    const handleClickGenerate = () => {
        let dpId = selectedOu?.id
        if (dpId === undefined) {
            toast.error('Please select an Organization', {
                position: toast.POSITION.BOTTOM_LEFT,
            });
            return;
        }

        const payload = {
            "filters": {
                "site_ids": sites.length > 0 && sites[0].name !== 'All Sites' ? sites?.map((site:any) => site.id) : sitesList?.filter((site) => site.name !== 'All Sites')?.map((site:any) => site.id),
                "start_time": startDate.toISOString(),
                "end_time": endDate.toISOString(),
                "detailed_report": enableDPI,
                "application": selectedFilter.appFilt,
                "category": selectedFilter.category,
                "user_host_name": selectedFilter.userName,
                "user_ip": selectedFilter.userIp,
                "user_mac": selectedFilter.userMac,
                "vlan": selectedFilter.vlan,
                "wan_type": WAN_ARRAY.find((wan) => wan.label === selectedFilter.wanType)?.value || ''
            },
            "format": format
        }
        createDownloadService(dpId, payload);
        handleClose();
        toast.success('Please check the progress of your report request in the "Generated Reports" tab.', {
            position: toast.POSITION.BOTTOM_LEFT,
        });
    }

    const handleChangeCheckbox = (e) => {
        setEnableDPI(e.target.checked);
    }

    return (
        <div className="Form menuScroll">
            <Dialog 
                disableEnforceFocus
                open={generateFilter}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                sx={{borderRadius: '8px'}}
                className='config-deploy-inventory-popup activity-filters-popup-dialogue menuScroll'
            >
                <div>
                    <DialogTitle className='create-default-config-title'>
                        <span>Report</span>
                        <img src={close} title="Close" onClick={handleClose} />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className="deploy-inventory-content">
                            <Grid className="selectedDeviceDetails">
                                <Grid className="generate-report-title">
                                    <span>Generate Report</span> <br />
                                    <span>Apply Filters below to generate your Report</span>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy">
                                <span>Select Date Range</span>
                                <Stack direction='row' spacing={1}>
                                    <div className="siteCharts__filters-date-time-picker">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DateTimePicker
                                                ampm={false}
                                                timezone={getTimezoneCity(authReducer.userTimezone)}
                                                label='Start Date'
                                                value={startDate}
                                                onChange={handleStartDatePicker}
                                                maxDateTime={endDate}
                                                className="audit-filter-inputs date-range-activity"
                                                viewRenderers={{
                                                    hours: renderTimeViewClock,
                                                    minutes: renderTimeViewClock,
                                                    seconds: renderTimeViewClock,
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="siteCharts__filters-date-time-picker">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DateTimePicker
                                                ampm={false}
                                                timezone={getTimezoneCity(authReducer.userTimezone)}
                                                label='End Date'
                                                value={endDate}
                                                onChange={handleEndDatePicker}
                                                minDateTime={startDate}
                                                className="audit-filter-inputs date-range-activity"
                                                maxDateTime={moment()}
                                                viewRenderers={{
                                                    hours: renderTimeViewClock,
                                                    minutes: renderTimeViewClock,
                                                    seconds: renderTimeViewClock,
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy">
                                <span>Select Organization</span>
                                <DP_Dropdown isReports= {true} setOu={handleOu} className={'dp-dropdown-activity-filters'} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="select-site-check" >
                                <div>
                                    <span>Select Site</span>
                                    <div className="list-filtered-filter">
                                        <Grid>
                                            <Autocomplete className="select-site-input"
                                                fullWidth
                                                size="small"
                                                multiple 
                                                limitTags={1}
                                                ListboxProps={{className: 'warehouse-apply-config-props'}}
                                                id="multiple-limit-tags"
                                                options={sitesList}
                                                getOptionLabel={(option: any) => {
                                                    return option.name;
                                                }}
                                                value={[...sites]}
                                                getOptionDisabled={(option: any) => {
                                                    let vesselOption = "All Sites";
                                                    if (sites.findIndex(item => item?.name === vesselOption) !== -1) {
                                                        return true;
                                                    }
                                                    if (option.name === vesselOption && sites[0]?.name && sites[0]?.name !== vesselOption) {
                                                        return true;
                                                    }
                                                    if(sites.find((ele)=> ele.name==option.name)) {
                                                        return true
                                                    }
                                                    return false;
                                                }}
                                                onChange={(e, newValue) => { handleChangeSite(e, newValue) }}
                                                renderInput={(params) => (<TextField {...params} required placeholder="select" style={{fontSize:'11px'}} />)}
                                            />
                                        </Grid>
                                        <span>List is Filtered based on Organization</span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Select MAC/IP</span>
                                <SelectTag options={userNamesList} handleChange={(e, newValue) => hanldeChangeSelect(e, newValue, newValue.userFilter)} value={selectedFilter?.userName ? selectedFilter.userName : selectedFilter?.userIp ? selectedFilter.userIp : selectedFilter?.userMac ? selectedFilter.userMac : ''} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Application Category</span>
                                <SelectTag options={categoryList} handleChange={(e, newValue) => hanldeChangeSelect(e, newValue, 'category')} value={selectedFilter?.category} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Select Application</span>
                                <SelectTag options={appList} handleChange={(e, newValue) => hanldeChangeSelect(e, newValue, 'appFilt')} value={selectedFilter?.appFilt} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Select VLAN</span>
                                <SelectTag options={vlanList} handleChange={(e, newValue) => hanldeChangeSelect(e, newValue, 'vlan')} value={selectedFilter?.vlan} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Select WAN Type</span>
                                <SelectTag options={WAN_ARRAY} handleChange={(e, newValue) => hanldeChangeSelect(e, newValue, 'wanType')} value={selectedFilter?.wanType} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Format</span>
                                <SelectTag options={['xlsx', 'csv']} handleChange={(e, newValue) => setFormat(newValue)} value={format} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy DPI-checkbox suggested-filters">
                            <Tooltip title="Note: Category and MAC/IP filters not applicable for Flow Report" placement="top">
                                <span style={{ display: 'inline-flex', alignItems: 'center', gap: '4px' }}>
                                    Detailed Flow Report <HelpIcon />
                                </span>
                            </Tooltip>
                                <FormControlLabel control={<Checkbox checked={enableDPI} onChange={handleChangeCheckbox} />} label="" />
                            </Grid>
                            <DialogActions className="config-create-actions">
                                <Button className="config-cancel-btn" onClick={handleClose}>Cancel</Button>
                                <Button className="config-create-btn" onClick = {handleClickGenerate}
                                style={{background: sitesList?.length > 1 ? "#264C86" : "#E8F0FD", color: sitesList?.length > 1 ? "#fff" : "#264C86"}}
                                disabled={sitesList?.length > 1 ? false : true}
                                >Generate</Button>
                            </DialogActions>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
  });
  
  export default withRouter(
    connect(mapStateToProps, { getAllUsernames, fetchResources, createDownloadService, getAllVlans, getAllApplications })( FiltersPopup )
  );