import React, { useEffect, useState } from 'react';
import { Autocomplete, Divider, Button, Dialog, Grid, TextField, IconButton, Tooltip, Typography, DialogTitle, DialogContent, DialogActions, Chip, Paper, Box, Select, MenuItem, FormControl, InputLabel, Checkbox, ListItem, ListItemText } from "@mui/material";

import { Edit as EditIcon, Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { updateServiceLineDetails, ACTION_TYPES, getAllSitesWithDevices, getSitesForTerminal, addSiteToServiceLineApi } from '../slice';
import { toast } from "react-toastify";
import _ from 'lodash';
import { getDecodeURI, strToK4List } from '../../../utils/util';

const ServiceLineDetails = (props) => {
    const { serviceLineToUpdate, updateServiceLineDetails, changeSLName, updateSLDetails, getVesselsListing, filteredVessels, location, 
        getAllSitesWithDevices, siteForTerminal, sitesWithDevices, getSitesForTerminal, starlinkAdmin, addSiteToServiceLineApi, addSiteToServiceLine } = props
    const dispatch = useDispatch();
    const [isEditingServiceLine, setIsEditingServiceLine] = useState(false);
    const [isEditingHomeAddress, setIsEditingHomeAddress] = useState(false);
    const [serviceLineName, setServiceLineName] = useState('');
    const [originalServiceLineName, setOriginalServiceLineName] = useState('');
    const [homeAddress, setHomeAddress] = useState('');
    const [originalHomeAddress, setOriginalHomeAddress] = useState('');
    const [emails, setEmails] = useState<any>([]);
    const [originalEmails, setOriginalEmails] = useState([]);
    const [emailInput, setEmailInput] = useState('');
    const [error, setError] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedSite, setSelectedSite] = useState("");
    const [selectedDevice, setSelectedDevice] = useState("");
    const [sites, setSites] = useState<any>([]);
    const [loader, setLoader] = useState(false)
    const [showCheck, setShowCheck] = useState(true)

    const [addressData, setAddressData] = useState({
        administrativeAreaCode: ``,
        regionCode: '',
        formattedAddress: ``,
        addressLines: [],
        latitude: '',
        longitude: ''
    });

    const formFields = [
        {
            label: "Formatted Address",
            placeholder: "ex: 1 Rocket Road",
            value: addressData.formattedAddress,
            onChange: (e) => handleAddressChange('formattedAddress', e.target.value),
            xs: 12,
        },
        {
            label: "Address Lines",
            placeholder: "Address lines",
            value: addressData.addressLines,
            onChange: (e) => handleAddressChange('addressLines', [e.target.value]),
            xs: 12,
        },
        {
            label: "Region Code",
            placeholder: "Region ex: US",
            value: addressData.regionCode,
            onChange: (e) => handleAddressChange('regionCode', e.target.value),
            xs: 6,
        },
        {
            label: "Administrative Area Code",
            placeholder: "State ex: CA",
            value: addressData.administrativeAreaCode,
            onChange: (e) => handleAddressChange('administrativeAreaCode', e.target.value),
            xs: 6,
        },
        {
            label: "Latitude",
            placeholder: "Latitude",
            value: addressData.latitude,
            onChange: (e) => handleAddressChange('latitude', e.target.value),
            xs: 6,
        },
        {
            label: "Longitude",
            placeholder: "Longitude",
            value: addressData.longitude,
            onChange: (e) => handleAddressChange('longitude', e.target.value),
            xs: 6,
        },
    ];

    const handleAddressChange = (field, value) => {
        setAddressData({
            ...addressData,
            [field]: value,
        });
    };

    useEffect(() => {
        setServiceLineName(serviceLineToUpdate.serviceLineName);
        setOriginalServiceLineName(serviceLineToUpdate.serviceLineName);
        setEmails(serviceLineToUpdate.emailIds || []);
        setOriginalEmails(serviceLineToUpdate.emailIds || []);
        setHomeAddress(serviceLineToUpdate.address?.formattedAddress);
        setOriginalHomeAddress(serviceLineToUpdate.address?.formattedAddress);

        if(serviceLineToUpdate?.userTerminalDetails && serviceLineToUpdate?.userTerminalDetails?.length > 0){
            getSitesForTerminal({terminalId: "ut" + serviceLineToUpdate?.userTerminalDetails[0]?.userTerminalId})
         }
     
    }, [serviceLineToUpdate]);

    useEffect(()=>{
        if(_.isEmpty(siteForTerminal)) return;
        setSelectedSite(siteForTerminal && siteForTerminal?.length > 0 ? siteForTerminal[0].site_id : "")
    },[siteForTerminal])

    const handleEditToggle = (type) => {
        if (type === 'serviceLine') {
            setIsEditingServiceLine(!isEditingServiceLine);
        } else if (type === 'homeAddress') {

            setAddressData({
                formattedAddress: serviceLineToUpdate.address?.formattedAddress || '',
                addressLines: serviceLineToUpdate.address?.addressLines || [],
                regionCode: serviceLineToUpdate.address?.regionCode || '',
                administrativeAreaCode: serviceLineToUpdate.address?.administrativeAreaCode || '',
                latitude: serviceLineToUpdate.address?.latitude || '',
                longitude: serviceLineToUpdate.address?.longitude || ''
            });
            setDialogOpen(true);
        }
    };

    const handleCancelEdit = (type) => {
        if (type === 'serviceLine') {
            setServiceLineName(originalServiceLineName);
            setIsEditingServiceLine(false);
        } else if (type === 'homeAddress') {
            setHomeAddress(originalHomeAddress);
            setIsEditingHomeAddress(false);
        }
    };

    const handleDeleteEmail = (emailToDelete) => {
        setEmails((prev) => prev.filter((email: any) => email?.email !== emailToDelete.email));
    };

    const handleAddEmail = (e) => {
        if (e.key === 'Enter' && emailInput) {
            setEmails([...emails, { name: emailInput.split('@')[0].replace('.', ' ').replace(/\b\w/g, char => char.toUpperCase()), email: emailInput }]);
            // setEmails([...emails, { email: emailInput, name: '' }]);
            setEmailInput('');
        }
    };

    const handleSave = async () => {
        const updatedAddress = {
            address_reference_id: serviceLineToUpdate.address?.addressReferenceId,
            formatted_address: addressData.formattedAddress,
            address_lines: addressData.addressLines,
            region_code: addressData.regionCode,
            administrative_area_code: addressData.administrativeAreaCode,
            latitude: addressData.latitude,
            longitude: addressData.longitude
        };

        let updatePayload: any = {

        };

        if (serviceLineName !== originalServiceLineName) {
            updatePayload.serviceLineName = serviceLineName;
        }
        if (dialogOpen) {
            updatePayload.address = updatedAddress;
        }
        if (JSON.stringify(emails) !== JSON.stringify(originalEmails)) {
            updatePayload.emailIds = emails;
        }

        updatePayload.organizationId = serviceLineToUpdate.accountDpId;
        updatePayload.serviceLineNumber = serviceLineToUpdate.serviceLineNumber;
        updatePayload.accountNumber = serviceLineToUpdate.accountNumber;

        try {
            updateServiceLineDetails(updatePayload)

        } catch (e) {
            setIsEditingHomeAddress(false);
            setDialogOpen(false)
        }
    };

    useEffect(() => {
        if (!_.isEmpty(updateSLDetails)) {
            setOriginalServiceLineName(serviceLineName);
            setOriginalHomeAddress(homeAddress);
            setOriginalEmails(emails);
            setIsEditingServiceLine(false);
            setIsEditingHomeAddress(false);
            setDialogOpen(false)
            changeSLName(serviceLineName)
            dispatch({ type: ACTION_TYPES.UPDATE_SERVICE_LINE_DETAILS, payload: {} })
            toast.success(`Service Line ${serviceLineName} updated successfully.`, {
                position: toast.POSITION.BOTTOM_LEFT,
            })
        }
        else {
            setIsEditingHomeAddress(false);
            setDialogOpen(false)
        }
    }, [updateSLDetails])

        useEffect(() => {
            setLoader( starlinkAdmin.addingSiteToServiceLine)
        }, [  starlinkAdmin.addingSiteToServiceLine ])


     useEffect(() => {
        if (_.isEmpty(getVesselsListing)) return;
        let locations: any[] = [];
        if (_.isArray(filteredVessels) && filteredVessels.length > 0) {
            locations = filteredVessels;
        } else {
            const { k4Ids = '' } = getDecodeURI(location?.search);
            const k4IdsFromUrl = strToK4List(k4Ids);
            if (_.isArray(k4IdsFromUrl) && k4IdsFromUrl.length) {
                locations = k4IdsFromUrl;
            } else if (_.isArray(getVesselsListing.locations)) {
                locations = getVesselsListing.locations;
            }
        }
        const data = locations.map(site => ({
            id: site.id,
            name: site['vessel-name'],
        }))

        if (_.isEqual(data, sites)) {
            return;
        } else {
            setSites(data);
        }
    }, [getVesselsListing, filteredVessels]);
   
    useEffect(()=>{
        if (_.isEmpty(sites)) return;
        getAllSitesWithDevices({sites:sites})

    },[sites])
  
    const uniqueSites = Array.from(
        new Map(
          (sitesWithDevices || []).map((item) => [
            item.site_id,
            { site_id: item.site_id, site_name: item.site_name },
          ])
        ).values()
      );
      
      const devicesForSelectedSite = (sitesWithDevices || []).filter(
        (item) => item.site_id === selectedSite
      );
  
    const handleSiteChange = (val) => {
        setShowCheck(true)  
      setSelectedSite(val);
      setSelectedDevice(""); 
    };
  
    const handleDeviceChange = (event) => {
      setSelectedDevice(event.target.value);
    };

    const handleSiteUpdate = async() =>{
        let params = {
            siteId: selectedSite,
            deviceId: devicesForSelectedSite[0].device_id,
            serviceLineNumber: serviceLineToUpdate.serviceLineNumber,
            userTerminalId: serviceLineToUpdate?.userTerminalDetails && serviceLineToUpdate?.userTerminalDetails.length > 0 ? "ut"+serviceLineToUpdate?.userTerminalDetails[0]?.userTerminalId : ""
        }
        await addSiteToServiceLineApi(params)
    }

     useEffect(() => {
            if (!_.isEmpty(addSiteToServiceLine)) {
                toast.success(`Site Updated Successfully .`, {
                    position: toast.POSITION.BOTTOM_LEFT,
                })
                setShowCheck(false)
                
                dispatch({ type: ACTION_TYPES.ADD_SITE_TO_SERVICE_LINE, payload: {} })
    
            }
    
        }, [addSiteToServiceLine])


return (
        <div className="serviceLineDetails-container">
            <Box className="starlink-serviceline-details-container">
                <Box className="starlink-serviceline-details-row">
                    <Box className="starlink-serviceline-details-heading">Account Number:</Box>
                    <Box className="starlink-serviceline-details-value">{serviceLineToUpdate.accountNumber}</Box>
                </Box>

                <Box className="starlink-serviceline-details-row">
                    <Box className="starlink-serviceline-details-heading">Service Line Number:</Box>
                    <Box className="starlink-serviceline-details-value">{serviceLineToUpdate.serviceLineNumber}</Box>
                </Box>

                <Box className="starlink-serviceline-details-row">
                    <Box className="starlink-serviceline-details-heading">Service Line Name:</Box>
                    {isEditingServiceLine ? (
                        <>
                            <TextField
                                size="small"
                                value={serviceLineName}
                                onChange={(e) => setServiceLineName(e.target.value)}
                                className="starlink-serviceline-details-value"
                            />
                            <Tooltip title="Save">
                                <span>
                                    <IconButton
                                        //   color="primary"
                                        onClick={handleSave}
                                        className="starlink-serviceline-details-save-button"
                                        disabled={serviceLineName === originalServiceLineName}
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Cancel">
                                <IconButton onClick={() => handleCancelEdit('serviceLine')} className="starlink-serviceline-details-cancel-button">
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <Box className="starlink-serviceline-details-value">{serviceLineName}</Box>
                            <IconButton onClick={() => handleEditToggle('serviceLine')} className="starlink-serviceline-details-edit-button padding-left-right">
                                <EditIcon />
                            </IconButton>
                        </>
                    )}
                </Box>

                <Box className="starlink-serviceline-details-row">
                    <Box className="starlink-serviceline-details-heading">Home Address:</Box>
                    {isEditingHomeAddress ? (
                        <>
                            <TextField
                                size="small"
                                value={homeAddress}
                                onChange={(e) => setHomeAddress(e.target.value)}
                                className="starlink-serviceline-details-value"
                            />
                            <Tooltip title="Save">
                                <span>
                                    <IconButton
                                        onClick={handleSave}
                                        className="starlink-serviceline-details-save-button"
                                        disabled={homeAddress === originalHomeAddress}
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Cancel">
                                <IconButton onClick={() => handleCancelEdit('homeAddress')} className="starlink-serviceline-details-cancel-button ">
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <Box className="starlink-serviceline-details-value">{homeAddress}</Box>
                            <IconButton onClick={() => handleEditToggle('homeAddress')} className="starlink-serviceline-details-edit-button padding-left-right">
                                <EditIcon />
                            </IconButton>
                        </>
                    )}
                </Box>

                <Box className="starlink-serviceline-details-row">
                    <Box className="starlink-serviceline-details-heading">Kit Serial:</Box>
                    <Box className="starlink-serviceline-details-value">{serviceLineToUpdate.userTerminals}</Box>
                </Box>

                <Box className="starlink-serviceline-details-row">
                    <Box className="starlink-serviceline-details-heading">Site: </Box>
                    <Box className="starlink-serviceline-details-value">

                    <FormControl>
                    <Autocomplete
                        options={uniqueSites}
                        getOptionLabel={(option:any) => option.site_name}
                        value={uniqueSites.find((site:any) => site.site_id === selectedSite) || null}
                        onChange={(event, newValue:any) => {
                            handleSiteChange(newValue ? newValue.site_id : null);
                        }}
                        renderInput={(params) => (
                            <TextField {...params}  variant="outlined" size="small" />
                        )}
                        sx={{ minWidth: 200 }} 
    />
                                
                            </FormControl>
                            {siteForTerminal && siteForTerminal?.length > 0  && selectedSite !==  siteForTerminal[0].site_id && showCheck ?
                             <>
                             <Tooltip title="Save">
                                <span>
                                    <IconButton 
                                        onClick={handleSiteUpdate}
                                        className="starlink-serviceline-details-save-button"
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Cancel">
                                <IconButton onClick={() => setSelectedSite(siteForTerminal && siteForTerminal?.length > 0 && siteForTerminal[0].site_id)} className="starlink-serviceline-details-cancel-button ">
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                            </> : <></>}
                    </Box>
                </Box>

                <Box className="starlink-serviceline-details-row">
                    <Box className="starlink-serviceline-details-heading">Device: </Box>
                    <Box className="starlink-serviceline-details-value">
                        {selectedSite && devicesForSelectedSite.length === 1 ? (
                            <Typography variant="body1">
                             {devicesForSelectedSite[0].device_id}
                            </Typography>
                        ) : (
                            <FormControl fullWidth margin="normal">
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label=""
                                    value={selectedDevice}
                                    onChange={handleDeviceChange}
                                    size="small"
                                    style={{minWidth:"200px"}}
                                >
                                {devicesForSelectedSite.map((item) => (
                                <MenuItem key={item.device_id} value={item.device_id}>
                                    {item.device_id}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        )}
                    </Box>
                </Box>

                {serviceLineToUpdate?.isCustomPlan ?
                    <>
                        <Box className="serviceLineDetails-divider-margin">
                            <Divider />
                        </Box>
                        <Box className="starlink-serviceline-details-row">
                            <Box className="starlink-serviceline-details-heading">Current Plan:</Box>
                            <Box className="starlink-serviceline-details-value">{serviceLineToUpdate?.servicePlan}</Box>
                        </Box>
                    </> :
                    <>
                        <Box className="starlink-serviceline-details-row">
                            <Box className="starlink-serviceline-details-heading">Current Plan:</Box>
                            <Box className="starlink-serviceline-details-value">{serviceLineToUpdate?.servicePlan}</Box>
                        </Box>

                        <Box className="serviceLineDetails-divider-margin">
                            <Divider />
                        </Box>
                    </>}


            </Box>

            <Typography fontWeight={"550"} component="div" >Email Ids:</Typography>
            <Grid item xs={12}>
                <Box>
                    <Paper
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            padding: '8px',
                            borderRadius: '10px',
                            border: '1px solid #c2d3eb',
                            boxShadow: 'none',
                        }}
                    >
                        {emails.map((email, index) => (
                            <Chip
                                key={index}
                                label={email?.email}
                                onDelete={() => handleDeleteEmail(email)}
                                sx={{ margin: '4px' }}
                            />
                        ))}
                        <TextField
                            size="small"
                            fullWidth
                            variant="standard"
                            error={!!error}
                            placeholder="Press Enter to add email"
                            helperText={error}
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                            onKeyDown={handleAddEmail}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    padding: '0 4px',
                                },
                            }}
                            sx={{
                                width: 'auto',
                                flexGrow: 1,
                                minWidth: '120px',
                                '& .MuiInputBase-root': {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                },
                                '& .MuiInputBase-input': {
                                    padding: '8px 4px',
                                    flex: 1,
                                },
                            }}
                        />
                    </Paper>
                </Box>
                <Typography variant="caption" color="textSecondary" sx={{ marginTop: '4px', marginLeft: '8px' }}>
                    Quota Kontrol Notifications shall be sent to this email.
                </Typography>

                {/* Show Save Button Only When Emails Change */}
                {JSON.stringify(emails) !== JSON.stringify(originalEmails) && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                        <Button variant="contained" onClick={handleSave}> Save </Button>
                    </Box>
                )}
            </Grid>


            <Dialog
                PaperProps={{
                    sx: {
                        width: '500px',
                        maxWidth: '500px',
                    },
                }}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <DialogTitle>Enter Address</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {formFields.map((field, index) => (
                            <Grid item xs={field.xs} key={index}>
                                <Typography variant="subtitle1">{field.label}</Typography>
                                <TextField
                                    fullWidth
                                    placeholder={field.placeholder}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        disabled={
                            addressData.formattedAddress === '' ||
                            addressData.administrativeAreaCode === '' ||
                            addressData.regionCode === '' ||
                            addressData.latitude === '' ||
                            addressData.longitude === ''
                        }
                        variant="contained"
                        color="primary"
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>


    );
};

const mapStateToProps = (state) => ({
    loading: state.starlinkAdmin.subcribingServiceLine,
    starlinkAdmin: state.starlinkAdmin,
    updateSLDetails: state.starlinkAdmin.updateServiceLineDetail,
    authReducer: state.authReducer,
    getVesselsListing: state.authReducer?.getVesselsListing,
    filteredVessels: state.authReducer?.filteredVessels,
    usersList: state.authReducer.usersList,
    sitesWithDevices: state.starlinkAdmin.sitesWithDevices,
    siteForTerminal: state.starlinkAdmin.siteForTerminal,
    addSiteToServiceLine: state.starlinkAdmin.addSiteToServiceLine
});

export default withRouter(
    connect(mapStateToProps, { 
        updateServiceLineDetails,
         getAllSitesWithDevices,
         getSitesForTerminal,
         addSiteToServiceLineApi
        })(ServiceLineDetails)
);

