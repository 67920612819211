import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { getCaptiveDashSitesTable } from "../../../actions/Users/authenticateCaptiveDahshboard";
import _ from "lodash";
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, Tooltip, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../../../components/Pagination";
import { getDecodeURI, getEncodedURI, readableBytesAsGB } from "../../../utils/util";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../../../components/DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import CircleIcon from '@mui/icons-material/Circle';
import ConfirmDialog from "../../../components/AcoordionPopUp";
import { INTERVALS } from "../../../constants/Constants";

const convertToCsv = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[0], row[1], row[2] ? row[2] : 'InActive', row[5], row[6], row[7], row[8], readableBytesAsGB(row[9])])
    })
    return [['Site Name', 'Organization', 'Internet Status', 'Alerts Count', 'Subscribers Count', 'Sessions Count', 'Avg Plan', 'Usage'], ...csvRows]
}

const CaptiveSitesTable = (props) => {
    const { getCaptiveDashSitesTable, vessels, newSummaryStartDate, newSummaryEndDate, getCDSitesTable, setCaptiveDashSitesTableLoading, csvDataDownloded, csvDownloadId, downloadAsCSV, location, history, values } = props;

    const _q = getDecodeURI(location?.search);
    const DEFAULT_PAGE_SIZE = 10;
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const ENTER_KEY_CODE = "Enter";
    const [tableData, setTableData] = useState<any>([]);
    const [count, setCount] = useState(0);
    const SITE_NAME = 'siteName';
    const ORG_NAME = 'organizationName';
    const INTERNET_STATUS = 'internetStatus';
    const ALERTS_COUNT = 'alertCount';
    const SUBSCRIBERS_COUNT = 'subscriberCount';
    const SESSIONS_COUNT = 'sessionCount';
    const QUOTA_USAGE = 'quotaUsage';
    const AVG_PLAN_USAGE = 'avgUniquePlans';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : SUBSCRIBERS_COUNT);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const dispatch = useDispatch();
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const [interval, setInterval] = useState(_q.hasOwnProperty('interval') ? {label: _q.interval == '15mins' ? '15m' : _q.interval, value: _q.interval} : INTERVALS[1]);
    const [wanProfilePopup, setWanProfilePopup] = useState(false);
    const [row, setRow] = useState<any>();
    const { selectedSubscribers } = values;    

    useEffect(() => {
        if (vessels?.length >= 1) {
            getCaptiveDashSitesTable(vessels, newSummaryStartDate, newSummaryEndDate, 1, limit, sort, sortOrder, searchValue, selectedSubscribers)
        } else {
            setTableData([]);
            setCount(0);
        }
    }, [vessels, newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        if(!_.isEmpty(getCDSitesTable)) {
            const data = getCDSitesTable.hasOwnProperty('data') ? getCDSitesTable.data : [];
            setCount(data?.[0]?.['totalCount']);
            setTableData(data);
        }
    }, [getCDSitesTable])

    useEffect(() => {
        if (csvDataDownloded && csvDataDownloded.length > 0) {
            if (csvDownloadId === 'captive-dashboard-sites-table') {
                const [columns, _countRow, ...rows] = csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows]), {
                    formatters : {
                        0: 'ESC-COMMA',
                        1: 'ESC-COMMA',
                    }
                });
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [csvDataDownloded])

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        const Page = 1;
        setPage(1);
        doNavigate(params);
        vessels && getCaptiveDashSitesTable(vessels, newSummaryStartDate, newSummaryEndDate, Page, limit, sort, sortOrder, value, selectedSubscribers)
    };

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 1;
        setPage(1);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        vessels && getCaptiveDashSitesTable(vessels, newSummaryStartDate, newSummaryEndDate, Page, limit, field, order, searchValue, selectedSubscribers)
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const handleChangePage = (e, value) => {
        setPage(value);
        let params: any = getDecodeURI(location?.search);
        value == 1 ? delete params.page : params.page = value;
        doNavigate(params);
        vessels && getCaptiveDashSitesTable(vessels, newSummaryStartDate, newSummaryEndDate, value, limit, sort, sortOrder, searchValue, selectedSubscribers)
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        Limit == 10 ? delete params.limit : params.limit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        vessels && getCaptiveDashSitesTable(vessels, newSummaryStartDate, newSummaryEndDate, Page, Limit, sort, sortOrder, searchValue, selectedSubscribers)
    }

    const handleDownloadReport = () => {
        let isClickHouse = true;
        const startDate = newSummaryStartDate;
        const endDate = newSummaryEndDate;
        const params: CsvParameters = {
            type: isClickHouse? 'QUERY_CH' : "QUERY",
            id : "captive-dashboard-sites-table",
            queryName: 'CAPTIVE_DASHBOARD_SITES_TABLE',
            payload: {
                "k4Id": vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
                "startDate": startDate,
                "endDate": endDate,
                "search": searchValue,
                "pageOffset": 0,
                "pageSize": count,
                "sortColumn": sort,
                "sortOrder": sortOrder,
                "sub_filter": selectedSubscribers?.length > 0 ? `AND username in (${selectedSubscribers?.map(sb => `'${sb}'`).join(',')})` : ''
              }
        }
        downloadAsCSV(params);
    }

    const rowsOption = [10, 15, 25, 50, 100]

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    const handleSiteClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        params.k4Ids = `${row.k4Id}:${row.siteName}`;
        delete params.page;
        delete params.limit;
        delete params.sort;
        delete params.sortOrder;
        delete params.search;
        doNavigate(params);
    };

    const handleOrgClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        params.ouName = `${row.organizationName}`;
        params.ouId = `${row.dpId}`;
        delete params.k4Ids;
        delete params.page;
        delete params.limit;
        delete params.sort;
        delete params.sortOrder;
        delete params.search;
        doNavigate(params);
    };

    const handleOpenPopup = (row) => {
        setRow(row);
        setWanProfilePopup(true);
    }

    const handleClosePopUp = () => {
        setWanProfilePopup(false);
    }

    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container topbarMarginPaddingReset alignTopbarItemsCenter">
                    <Grid item xs={4} sm={4} md={4} lg={4} className="site-details-header usage-site-details-header">Site CAN Report</Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} className="usage-table-search-download-div">
                        <Grid className="usage-search-border-color">
                            <Box className="searchbarBox">
                                {<TextField
                                    id="outlined-basic"                                
                                    variant="outlined"
                                    placeholder="Type something"
                                    autoComplete="off"
                                    size="small"
                                    value={searchValue}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel1" }}
                                    InputProps={{
                                        className: "searchbarInputs",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchValue && <SearchIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchValue && <CloseIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        {count > 0 && <Grid>
                            <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner tablePaddingReset">
                    <Paper className="pagination-position config-pagination new-usage remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={count ? Number(count) : 0}
                            rowsPerPage={limit ? limit : 0}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SITE_NAME)}>SITE</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SITE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITE_NAME, ASC_ORDER)} />
                                                <img src={sort === SITE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SITE_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ORG_NAME)}>Organization name</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === ORG_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, ORG_NAME, ASC_ORDER)} />
                                                <img src={sort === ORG_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, ORG_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, INTERNET_STATUS)}>Internet Status</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === INTERNET_STATUS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, INTERNET_STATUS, ASC_ORDER)} />
                                            <img src={sort === INTERNET_STATUS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, INTERNET_STATUS, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ALERTS_COUNT)}>Alerts</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === ALERTS_COUNT && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, ALERTS_COUNT, ASC_ORDER)} />
                                            <img src={sort === ALERTS_COUNT && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, ALERTS_COUNT, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SUBSCRIBERS_COUNT)}>Subscribers</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SUBSCRIBERS_COUNT && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SUBSCRIBERS_COUNT, ASC_ORDER)} />
                                            <img src={sort === SUBSCRIBERS_COUNT && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, SUBSCRIBERS_COUNT, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SESSIONS_COUNT)}>Sessions</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SESSIONS_COUNT && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SESSIONS_COUNT, ASC_ORDER)} />
                                            <img src={sort === SESSIONS_COUNT && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, SESSIONS_COUNT, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, AVG_PLAN_USAGE)}>Avg Plan</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === AVG_PLAN_USAGE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, AVG_PLAN_USAGE, ASC_ORDER)} />
                                            <img src={sort === AVG_PLAN_USAGE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, AVG_PLAN_USAGE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, QUOTA_USAGE)}>Usage</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === QUOTA_USAGE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, QUOTA_USAGE, ASC_ORDER)} />
                                            <img src={sort === QUOTA_USAGE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, QUOTA_USAGE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {count > 0 && tableData && 1 != tableData?.length && !setCaptiveDashSitesTableLoading ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">
                                    {
                                        tableData && tableData?.map((item, i) => (
                                            i > 0 && <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                                <Tooltip title={item.siteName}>
                                                    <TableCell className="login-left-align inter-font selectedClick Table-Cell-Opacity elipseThreat" onClick={() => handleSiteClick(item)}>{item?.siteName}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={item.organizationName}>
                                                    <TableCell className="login-left-align inter-font selectedClick Table-Cell-Opacity elipseThreat" onClick={() => handleOrgClick(item)}>{item?.organizationName}</TableCell>
                                                </Tooltip>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity selectedClick">
                                                    <div className="hub-row-internetStatus">
                                                        <CircleIcon id={item.internetStatus == 'Online' ? "greenDotSymbol" : item.internetStatus == 'Offline' ? "redDotSymbol" : "greyDotSymbol"} />
                                                        <span onClick={() => handleOpenPopup(item)}>{item.internetStatus ? item.internetStatus : 'InActive'}</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.alertCount}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.subscriberCount}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.sessionCount}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.avgUniquePlans}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{readableBytesAsGB(item?.quotaUsage)}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {wanProfilePopup && <ConfirmDialog confirmDialog={{isOpen: wanProfilePopup}} row={row} handleClosePopUp={handleClosePopUp} interval={interval} />}
        </div>
    )
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
  newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
  getCDSitesTable: state?.authReducer?.getCDSitesTable,
  setCaptiveDashSitesTableLoading: state?.authReducer?.setCaptiveDashSitesTableLoading,
  csvDataDownloded: state?.authReducer?.csvDataDownloded,
  csvDownloadId: state?.authReducer?.csvDownloadId
});

export default withRouter(
  connect(mapStateToProps, { getCaptiveDashSitesTable, downloadAsCSV })(CaptiveSitesTable)
);