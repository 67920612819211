import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { updateHubFilteredVessels, updatePermission } from "../../../actions/Users/authenticate";
import { useEffect, useState } from "react";
import { HUB_RESOURCE_FIELD_VESSELNAME, SIDE_MENU_ITEM_HUB } from "../../../utils/constants";
import { getDecodeURI, getEncodedURI, strToK4HubList, strToK4IdList, updateTimePeriod, checkLoggedInUserAuthorizedToViewPage } from "../../../utils/util";
import { getHubDataSitesList } from "../../../actions/Users/authenticateHub";
import { CONST_ALL_VESSELS } from "../../../constants/Constants";
import _ from "lodash";
import TopologyAgentTable from "./TopologyAgentTable";
import ServiceTable from "./ServiceTable";
import CommonFilters from "../../../components/BreadCrumbs/CommonFilters";
import TabsComponent from "../../../components/NewTabs";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../../config";
import { GET_HUB_DASHBOARD_HUB_DATA, GET_HUB_DASHBOARD_CLIENT_WISE, GET_CARRIER_HUB_SDWAN_STATUS, GET_CARRIER_HUB_SDWAN_LATENCY, GET_CARRIER_HUB_SDWAN_DATA_RATES, GET_CARRIER_HUB_SDWAN_USAGE } from "../../../actions/types";
import AgentSDWAN from './SDWAN';

const TopologyHubDetails = (props) => {
    const { match, authReducer, setTopologyHubTableLoading, setHubDashboardCarrierServiceTableLoading, updatePermission, getHubDataSitesList, location,  history, updateHubFilteredVessels,
        setCarrierHubSDWAnStatusLoading, setCarrierHubSDWAnLatencyLoading, setCarrierHubSDWANDataRatesLoading, setCarrierHubSDWANUsageLoading, setCarrierHubAgentDropdownLoading, setCarrierHubWanDropdownLoading, setCarrierHubSDWANHubsLoading, setCarrierHubListDropdownLoading
     } = props;
    let _q: any = getDecodeURI(location.search);
    const [loading, setLoading] = useState('');

    const [hubVesselNames, setHubVesselNames] = useState<any[]>([]);
    const [hubSelectedVessels, setHubSelectedVessels] = useState<any[]>([]);
    const [hubSelectedVesselNames, setHubSelectedVesselNames] = useState<string[]>([]);
    const [hubSelectedVesselsDisplayName, setHubSelectedVesselsDisplayName] = useState<any>(CONST_ALL_VESSELS);
    const [isAllSelectedHub, setIsAllSelectedHub] = useState(false);
    const [siteSearchValue, setSiteSearchValue] = useState("");
    const [hubFilterK4Ids, setHubFilterK4Ids] = useState<any>([]);
    const [hubVesselList, setHubVesselList] = useState([]);
    const [activeTab, setActiveTab] = useState(match?.params?.tab ? match.params.tab.includes("?") ? match.params.tab.split("?")[0] : match.params.tab : "topology");
    const dispatch = useDispatch();

    if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_HUB !== authReducer.sideMenu.menuItem)) {
        authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_HUB };
    }
    
    const VIEW_VESSEL_SUMMARY = "view-vessel-summary";
    const viewVesselSummaryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
    const viewVesselSummaryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];

    useEffect(() => {
        let authorized = checkLoggedInUserAuthorizedToViewPage(viewVesselSummaryService, viewVesselSummaryFunction);
        if (authorized && !authReducer.permission) {
            const info = {
                permission: {
                    service: viewVesselSummaryService,
                    serviceFunction: viewVesselSummaryFunction,
                },
                isMultiVessel: false,
                isSingleVessel: false,
                showOu: false,
                ou: authReducer.selectedOu ? authReducer.selectedOu : '',
                hubSites: false
            };
            updatePermission(info);
        }
    }, [])

    useEffect(() => {
        setLoading(setTopologyHubTableLoading || setHubDashboardCarrierServiceTableLoading || setCarrierHubSDWAnStatusLoading || setCarrierHubSDWAnLatencyLoading ||
            setCarrierHubSDWANDataRatesLoading || setCarrierHubAgentDropdownLoading || setCarrierHubWanDropdownLoading || setCarrierHubSDWANUsageLoading ||
            setCarrierHubSDWANHubsLoading || setCarrierHubListDropdownLoading)
    }, [setTopologyHubTableLoading, setHubDashboardCarrierServiceTableLoading, setCarrierHubSDWAnStatusLoading, setCarrierHubSDWAnLatencyLoading,
        setCarrierHubSDWANDataRatesLoading, setCarrierHubAgentDropdownLoading, setCarrierHubWanDropdownLoading, setCarrierHubSDWANUsageLoading,
        setCarrierHubSDWANHubsLoading, setCarrierHubListDropdownLoading])

    useEffect(() => {
        if (_q.hasOwnProperty("hubK4Ids") && '' != _q.hubK4Ids.trim()) {
            let hubVessels = strToK4IdList(_q.hubK4Ids);
            if (hubVessels && hubVessels.length > 0) {
                hubVessels = hubVessels.filter(item => item !== '');
            }
            let hubVesselObjs = strToK4HubList(_q.hubK4Ids);
            if (hubVessels.length != hubFilterK4Ids.length || hubVessels.join() !== hubFilterK4Ids.join())
                updateHubFilteredVessels(hubVesselObjs);
            setHubFilterK4Ids(hubVessels);
        } else {
            if (hubFilterK4Ids.length > 0) {
                setHubFilterK4Ids([]);
                updateHubFilteredVessels([]);
            }
        }
        if (!_q.hasOwnProperty('hubK4Ids')) {
            updateHubFilteredVessels([]);
        }
    }, [location.search])

    useEffect(() => {
        let selVessels: any[] = hubVesselNames;
        let isAll = isAllSelectedHub;
        if (hubVesselNames.length > 0) {
            if (hubFilterK4Ids && hubFilterK4Ids.length > 0) {
                setIsAllSelectedHub(false);
                isAll = false;
                selVessels = hubVesselNames.filter(item => hubFilterK4Ids.indexOf(item.k4Id) >= 0);
                let selVesselNames: any[] = selVessels.map(item => item[HUB_RESOURCE_FIELD_VESSELNAME] ? item[HUB_RESOURCE_FIELD_VESSELNAME] : "");
                setHubSelectedVessels(selVessels);
                setHubSelectedVesselNames(selVesselNames);
                updateHubDisplayName(selVesselNames);
            } else {
                isAll = true;
                setHubSelectedVesselsDisplayName(CONST_ALL_VESSELS);
                checkAllCheckBoxHub(hubVesselNames);
            }
        } else {
            isAll = true;
            setHubSelectedVesselsDisplayName("");
        }
    }, [hubVesselNames, hubFilterK4Ids]);

    const updateHubDisplayName = (selVesselNames) => {
        if (0 === selVesselNames.length) {
            setHubSelectedVesselsDisplayName("");
        } else if (1 === selVesselNames.length) {
            setHubSelectedVesselsDisplayName(selVesselNames[0])
        } else {
            setHubSelectedVesselsDisplayName(`${selVesselNames[0]} +${selVesselNames.length - 1}`);
        }
    }

    const checkAllCheckBoxHub = (_vessels) => {
        setIsAllSelectedHub(true);
        setHubSelectedVesselNames(_vessels.map(item => item[HUB_RESOURCE_FIELD_VESSELNAME] ? item[HUB_RESOURCE_FIELD_VESSELNAME] : ""));
        setHubSelectedVessels(_vessels.filter(item => item[HUB_RESOURCE_FIELD_VESSELNAME] && item[HUB_RESOURCE_FIELD_VESSELNAME] !== CONST_ALL_VESSELS));
    }

    const handleChangeVesselHub = (e, vessel) => {
        let name = vessel[HUB_RESOURCE_FIELD_VESSELNAME];
        let isChecked = !hubSelectedVesselNames.includes(name);
        let selectedVessel: any = [];
        let selectedVesselName: any = [];
        let isAllSelect = isAllSelectedHub
        if (isChecked) {
            if (name == CONST_ALL_VESSELS) {
                checkAllCheckBoxHub(hubVesselNames);
            } else {
                if (isAllSelectedHub) {
                    selectedVesselName = [name]
                    selectedVessel = [vessel]
                    isAllSelect = false
                    setIsAllSelectedHub(isAllSelect);
                    setHubSelectedVesselNames(selectedVesselName);
                    setHubSelectedVessels(selectedVessel);
                } else {
                    selectedVesselName = [...hubSelectedVesselNames, name]
                    selectedVessel = [...hubSelectedVessels, vessel]
                    setHubSelectedVesselNames(selectedVesselName);
                    setHubSelectedVessels(selectedVessel);
                }
            }
        } else {
            if (name === CONST_ALL_VESSELS) {
                selectedVesselName = []
                selectedVessel = []
                setHubSelectedVesselNames(selectedVesselName);
                setHubSelectedVessels(selectedVessel);
            } else {
                if (isAllSelectedHub) {
                    isAllSelect = false
                    setIsAllSelectedHub(isAllSelect);
                    selectedVesselName = [name]
                    selectedVessel = [vessel]
                    setHubSelectedVesselNames(selectedVesselName);
                    setHubSelectedVessels(selectedVessel);
                } else {
                    selectedVesselName = hubSelectedVesselNames.filter(item => item !== name)
                    selectedVessel = hubSelectedVessels.filter(item => item[HUB_RESOURCE_FIELD_VESSELNAME] !== name)
                    setHubSelectedVesselNames(selectedVesselName);
                    setHubSelectedVessels(selectedVessel);
                }
            }
        }

        let params = getDecodeURI(location?.search);
        if (isAllSelect) {
            setHubSelectedVesselsDisplayName(CONST_ALL_VESSELS);
        } else {
            updateHubDisplayName(selectedVesselName.filter(item => item !== name));
        }

        let filterK4Ids: any[] = [];
        if (!isAllSelect) {
            filterK4Ids = selectedVessel.map(item => `${item.k4Id}:${item['site_name']}`);
        }
        let navigate = true;
        if (filterK4Ids.length > 0) {
            params.hubK4Ids = filterK4Ids.toString();
        } else if (params.hasOwnProperty("hubK4Ids")) {
            delete params.hubK4Ids;
        } else {
            navigate = false;
        }
        delete params.page;

        if (navigate) {
            history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });
        }

    }

    useEffect(() => {
        if (!_.isEmpty(authReducer.getHubVesselListing) && !_.isEmpty([authReducer?.selectedOu])) {
            let _vessels = [];
            let anotherVessels: any = authReducer.getHubVesselListing.hasOwnProperty('data') && authReducer.getHubVesselListing.data;
            if (anotherVessels) {
                anotherVessels = anotherVessels.map((item) => ({
                    k4Id: item.k4Id,
                    'site_name': item.site_name,
                }))
                _vessels = anotherVessels;
            }
            _vessels = _.sortBy(_vessels, ['site_name']);
            const ous = !_.isEmpty([authReducer?.selectedOu]) && [authReducer?.selectedOu].length > 0 ? [authReducer?.selectedOu].map(org => {
                return org?._childern?.length > 0 ? org._childern.map(dp => {
                    return {
                        'site_name': dp.name,
                    }
                }) : []
            }).flat() : [];
            _vessels = _.differenceBy(_vessels, ous, 'site_name')
            setHubVesselList(_vessels);
        }
    }, [authReducer.getHubVesselListing, authReducer.selectedOu])

    useEffect(() => {
        setHubVesselNames([{ "site_name": CONST_ALL_VESSELS, k4Id: '' }, ...hubVesselList]);
    }, [hubVesselList])

    useEffect(() => {
        if (siteSearchValue !== "" && !_.isEmpty(hubVesselList)) {
            let _vesselList = hubVesselList.filter((vessel: any) => !_.isEmpty(vessel["site_name"]) && vessel["site_name"].toLowerCase().includes(siteSearchValue.toLowerCase()));
            setHubVesselNames(!_.isEmpty(_vesselList) ? [{ "site_name": CONST_ALL_VESSELS, k4Id: '' }, ..._vesselList] : []);
        } else if (siteSearchValue === "" && !_.isEmpty(hubVesselList)) {
            setHubVesselNames([{ "site_name": CONST_ALL_VESSELS, k4Id: '' }, ...hubVesselList]);
        }
    }, [siteSearchValue])

    useEffect(() => {
        if (authReducer?.selectedOu?.id) {
            getHubDataSitesList(authReducer?.selectedOu?.id);
        }
    }, [authReducer.selectedOu])

    const doNavigateTab = (pathname, tabName) => {
        const currentParams = getDecodeURI(location?.search);
        const params:any = {};
        if (currentParams.ouName) {
          params['ouName'] = currentParams.ouName;
        }
        if (currentParams.ouId) {
          params['ouId'] = currentParams.ouId;
        }
        if (currentParams.k4Ids) {
          params['k4Ids'] = currentParams.k4Ids;
        }
        if(currentParams.sideNav){
          params['sideNav'] = currentParams.sideNav
        }
        if(currentParams.interval) {
          params['interval'] = currentParams.interval
        }
        if(currentParams.siteId) {
          params['siteId'] = currentParams.siteId
        }
        if(currentParams.agentId) {
          params['agentId'] = currentParams.agentId
        }
        const { startDate, endDate } = updateTimePeriod(dispatch, location);
        params.startDate = startDate;
        params.endDate = endDate;
        history.push({ pathname, search: getEncodedURI(params) });
        setActiveTab(tabName);
      }

    const tabs = [
        { tabName: 'Topology', tabValue: 'topology', pathName: '/fleet-dashboard/hub/topology' }
    ]

    const gotoHubDashboard = () => {
        let params = getDecodeURI(location?.search);
        delete params.siteId;
        delete params.agentId;
        delete params.hub_name;
        delete params.wanId;
        delete params.pageStart;
        history.push({ pathname: '/hub', search: `?${getEncodedURI(params)}` });
        dispatch({type: GET_HUB_DASHBOARD_HUB_DATA, payload: {}});
        dispatch({type: GET_HUB_DASHBOARD_CLIENT_WISE, payload: {}});
        dispatch({ type: GET_CARRIER_HUB_SDWAN_STATUS, payload: {} });
        dispatch({ type: GET_CARRIER_HUB_SDWAN_LATENCY, payload: {} });
        dispatch({ type: GET_CARRIER_HUB_SDWAN_DATA_RATES, payload: {} });
        dispatch({ type: GET_CARRIER_HUB_SDWAN_USAGE, payload: {} });
    }

    return (
        <div>
            <CommonFilters loading={loading} showWantType={false} showSummaryPeriod={true}></CommonFilters>
            <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={activeTab} backButtonNeeded={true} goBack={gotoHubDashboard} />
            {activeTab == 'topology' ? <div className='hub-topology-parent-container'>
                <AgentSDWAN />
                <TopologyAgentTable />
                <ServiceTable />
            </div> : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    setTopologyHubTableLoading: state.authReducer.setTopologyHubTableLoading,
    setHubDashboardCarrierServiceTableLoading: state.authReducer.setHubDashboardCarrierServiceTableLoading,
    setCarrierHubSDWAnStatusLoading: state.authReducer.setCarrierHubSDWAnStatusLoading,
    setCarrierHubSDWAnLatencyLoading: state.authReducer.setCarrierHubSDWAnLatencyLoading,
    setCarrierHubSDWANDataRatesLoading: state.authReducer.setCarrierHubSDWANDataRatesLoading,
    setCarrierHubSDWANUsageLoading: state.authReducer.setCarrierHubSDWANUsageLoading,
    setCarrierHubSDWANHubsLoading: state.authReducer.setCarrierHubSDWANHubsLoading,
    setCarrierHubAgentDropdownLoading: state.authReducer.setCarrierHubAgentDropdownLoading,
    setCarrierHubWanDropdownLoading: state.authReducer.setCarrierHubWanDropdownLoading,
    setCarrierHubListDropdownLoading: state.authReducer.setCarrierHubListDropdownLoading,
});

export default withRouter(
    connect(mapStateToProps, {updatePermission, getHubDataSitesList, updateHubFilteredVessels})(TopologyHubDetails)
)