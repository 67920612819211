import { Button, Grid, Tooltip } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { connect, useDispatch } from 'react-redux';
import { getTopSitesTableData } from "../../../actions/Users/authenticateUsageReports";
import { withRouter } from "react-router-dom";
import { getDecodeURI, readableBytes, readableBytesAsGB, getEncodedURI } from "../../../utils/util";
import { Pagination } from "../../Pagination/index";
import _ from "lodash";
import { TableBody, TableRow, TableCell, TableContainer, Table, TableHead, Paper } from "@mui/material";
import { UsageContext } from "../../../UserScreen/UserData/UsageDataContext";
import Download from '../../../asset/image/DownloadUsage.svg';
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import ImageWithFallback from "../../ImageWithFallback";

const TopSitesTable = (props) => {
    const { TopSitesTableData, getVesselsListing, handleChangePageSite, handleChangeRowsPerPageSite, pageStart , pageLimit, rowsOption, topSitesCount, setTopSitesCount, location, getTopSitesTableData, history } = props;
    const _q = getDecodeURI(location?.search);
    const { selectFilter, setPage, setPageStart, vlanFilt, handleTopSiteOnCsvDownload, checkUser, vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, deviceFilt, sortSite, sortOrderSite, setSortSite, setSortOrderSite} = useContext(UsageContext);
    const [topSitesData, setTopSitesData] = useState<any>([]);
    const TOTAL = 'total';
    const UPLOAD = 'upload';
    const DOWNLOAD = 'download';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";

    useEffect(() => {
        if (getVesselsListing?.locations && getVesselsListing?.locations?.length == 0) {
            setTopSitesData([]);
            setTopSitesCount(0)
            return
        }
        if (!_.isEmpty(TopSitesTableData)) {
            const data = TopSitesTableData?.hasOwnProperty('data') ? TopSitesTableData?.data : {} ;       
            if(data) { 
                let cols :any = [];
                let result :any = [];
                for(var key in data){
                    cols.push(key);
                }
                data[cols[0]]?.data?.forEach((el, i)=>{
                    let rowObj :any = {};
                    cols.forEach((ele)=>{
                        rowObj[ele] = data[ele].data[i]
                    })
                    result.push(rowObj);
                })
                result && result.length > 1 &&  result.shift();
                setTopSitesData(result);
            }
        }
    }, [TopSitesTableData, getVesselsListing])

    useEffect(() => {
        if (!_.isEmpty(TopSitesTableData)) {
            const data = TopSitesTableData?.hasOwnProperty('data') ? TopSitesTableData?.data : 0 ;       
            setTopSitesCount(data?.totLoc?.data[0])
        }
    },[TopSitesTableData])

    const siteNameClick = (e) => {
        let filters: any = {};
        filters.filterSite = e.target.innerText;
        filters.vlanFilter = vlanFilt;
        filters.userCheck = checkUser;
        setPage(1);
        setPageStart(1);
        selectFilter(filters);
    }

    const handleDownloadReport = () => {
        handleTopSiteOnCsvDownload(topSitesCount);
    }

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.pageStart;
        params.sortSite = field;
        params.sortOrderSite = order;
        const Page = 1;
        setPageStart(1);
        setSortSite(field);
        setSortOrderSite(order);
        getTopSitesTableData(vessels, newSummaryStartDate, newSummaryEndDate, userName, category, appFilt, Page, pageLimit, vlanFilt, deviceFilt, checkUser, field, order)
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sortSite === field) {
            order = ASC_ORDER === sortOrderSite ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    function GetTopAppsImage (props)  {
        const { app } = props;
        if(app) {
          let appName = app.includes(' ') ? app.split(' ')[0]: app.includes('-') ? app.split('-')[0] : ""; 
          return <ImageWithFallback src={`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${app.trim()}`} fallbackSrcs={[`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${appName.trim()}`,"https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png"]} app={app} />
        } else {
          return <img className="logo-activity paddingLogo" src={`https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png`} />
        }
    
      }

    const tooltipContent = (row) => {
        const interfaceKeys = ["CELLULAR", "VSAT", "STARLINK", "LBAND", "ETHERNET", "WIFI", "BOND"];
        return <div>
            {interfaceKeys.map((key) => (
                <div key={key}>
                    <b>{key} :</b> {row[key] ? readableBytesAsGB(row[key]) : 0}
                </div>
            ))}
        </div>
    };

    return (
    <Grid item xs={12} sm={4} md={12} lg={12} className="Usage-TableComponent">
        <Grid item xs={12} sm={12} md={12} lg={12} className="topSites-download">
            <Grid item xs={6} sm={6} md={6} lg={6} className="Reports-overView-topUsers align-text-center">Top Sites</Grid>
                {topSitesCount > 0 && <Grid item xs={6} sm={6} md={6} lg={6}>
                    <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                </Grid>}
        </Grid>
       
       <Grid container spacing={0.5} className="TopUsersList-Reports">
            <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">
                <Grid className="Reports-graph_table">
                    {/* <Grid className="exportBtnClick">
                    <Button className="exportBtnClick-btn">Export</Button>
                    <img src={Download} alt="download" />
                </Grid> */}
                </Grid>
                <Paper className="pagination-position config-pagination activity-usage">
                    <Pagination
                        rowsPerPageOptions={rowsOption}
                        count={topSitesCount}
                        rowsPerPage={pageLimit}
                        page={pageStart}
                        onPageChange={handleChangePageSite}
                        onRowsPerPageChange={handleChangeRowsPerPageSite}
                    />
                </Paper>
                <Grid>
                    <TableContainer className="usageReports-FleetDashboard">
                        <Table aria-label="simple table">
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="usage-fleetDataTable topApp-icons-sitestable font-wt-900 width-13-per">Site Name</TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-10px" onClick={(event) => switchSorting(event, TOTAL)}>Total(GB)</span>
                                            <Grid className="sortSite-icon-margin">
                                                <img src={sortSite === TOTAL && sortOrderSite === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, TOTAL, ASC_ORDER)} />
                                                <img src={sortSite === TOTAL && sortOrderSite === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, TOTAL, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-10px" onClick={(event) => switchSorting(event, UPLOAD)}>Upload Size(GB)</span>
                                            <Grid className="sortSite-icon-margin">
                                                <img src={sortSite === UPLOAD && sortOrderSite === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, UPLOAD, ASC_ORDER)} />
                                                <img src={sortSite === UPLOAD && sortOrderSite === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, UPLOAD, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-10px" onClick={(event) => switchSorting(event, DOWNLOAD)}>Download Size(GB)</span>
                                            <Grid className="sortSite-icon-margin">
                                                <img src={sortSite === DOWNLOAD && sortOrderSite === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DOWNLOAD, ASC_ORDER)} />
                                                <img src={sortSite === DOWNLOAD && sortOrderSite === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, DOWNLOAD, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="usage-fleetDataTable topApp-icons-sitestable font-wt-900">Top Apps</TableCell>
                                </TableRow>
                            </TableHead>
                            {0 != topSitesData.length ? (
                                <TableBody className="tableBody usage-tableBody">
                                    {
                                        topSitesData && topSitesData.map((row, i) =>
                                            <TableRow key={i} className="usageData-tabelRow">
                                                <TableCell className="usagedata-tableCell selected-site" onClick={(e) => siteNameClick(e)}>{row.site}</TableCell>
                                                <TableCell className="usagedata-tableCell">
                                                    <Tooltip title={tooltipContent(row)} arrow placement="right">
                                                        <span>{readableBytesAsGB(row.total, false)}</span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell className="usagedata-tableCell">{readableBytesAsGB(row.upload, false)}</TableCell>
                                                <TableCell className="usagedata-tableCell">{readableBytesAsGB(row.download, false)}</TableCell>
                                                <TableCell className="usageData-tabelCell">
                                                    <Grid className="tableSites-topIcons">
                                                        { topSitesData[i]?.topApps?.split(',')?.map((app) => (
                                                            <Tooltip title={app}> 
                                                                <GetTopAppsImage app={app} />
                                                            </Tooltip>
                                                        ))}
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)
}

const mapStateToProps = (state) => ({
    TopSitesTableData: state.authReducer?.TopSitesTableData,
    getVesselsListing: state?.authReducer?.getVesselsListing
});

export default withRouter(
    connect(mapStateToProps, {
        getTopSitesTableData
    })(TopSitesTable)
);