import { Autocomplete, Button, Dialog, Divider, Grid, TextField, Typography } from '@mui/material';
import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import close from "../../../asset/image/close.png";
import ThreatKontrolActions from "./ThreatKontrolActions";
import {updateThreatKontrol} from "../../../actions/Users/authenticateThreatDashboard";
import { getSeverityClassList, getThreatNameList } from "../../../actions/Users/authenticateThreatDashboard";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { JsonDataTooltip } from "../../../components/JSONTooltip/JsonTooltip";
import {  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Checkbox, ListItemText, AutocompleteChangeReason, AutocompleteChangeDetails, Chip, FormHelperText, IconButton, Tooltip, FormControlLabel, ListSubheader } from '@mui/material';

interface EditThreatKontrol {
    editOpen: boolean,
    setEditOpen: any,
    selectedRow: any
}

const EditThreatKontrol = (props) => {
    const {authReducer, options, getSeverityClassList, getThreatNameList, updateThreatKontrol,users,  editOpen, setEditOpen, selectedRow} = props;
    const Heading = "Set Threat Kontrol";
    const targetTypes = ["Device", "Site", "Managed Group"];
    const [targetType, setTargetType] = useState<string>("");
    const [email, setEmail] = useState<any[]>(JSON.parse(selectedRow.users_to_notify));
    const [targets, setTargets] = useState<any[]>([]);
    const [emailSelected, setEmailSelected] = useState<boolean>(JSON.parse(selectedRow.notification_methods).includes("email"));
    const [teamsSelected, setTeamsSelected] = useState<boolean>(JSON.parse(selectedRow.notification_methods).includes("microsoft_teams"));
    const [inputValue, setInputValue] = useState("");

    const handleTargetTypeChange = (event, value) => {
        setTargetType(value);
        setTargets([]);
    }
    const handleTargetsChange = (event, value) => {
        setTargets(value);
    }
    const handleClose = () => {
        setEditOpen(false);
    }
    const isValidEmail = (emailString: string): boolean => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailString.trim());
    };
    const handleInputEmailChange = (event, newInputValue) => {
        if (newInputValue.endsWith(",")) {
            const emailString = newInputValue.slice(0, -1).trim();
            if (isValidEmail(emailString)) {
                const newEmail = { name: emailString, email: emailString };
                if (!email.some(item => item.email === newEmail.email)) {
                    setEmail(prev => [...prev, newEmail]);
                }
            }
            else{
                alert("Enter Valid Email");
            }
            setInputValue("")
        } else {
            setInputValue(newInputValue);
        }
    }
    const handleEmailChnage = (event, value) => {
        setEmail(value);
    }
    const handleEmailChange = (event) => {
        setEmailSelected(event.target.checked);
    };

    const handleTeamsChange = (event) => {
        setTeamsSelected(event.target.checked);
    };
    const handleCancel = () => {
        setThreatKontrolName("");
        setSeveity("");
        setSelectedClass([]);
        setSeletedThreatName([]);
        setCount(0);
        setMeasure("");
        setAction("");
        setEmail([]);
        setEmailSelected(false);
        setTeamsSelected(false);
        setEditOpen(false);
    }
    
    const dispatch = useDispatch();
    const optionsSeverity = ["Low", "Medium", "High", "Critical"];
    const optionsMeas = ["15 Mins", "1 Hour", "6 Hours", "24 Hours" ];
    const optionsCount = [10, 20, 50, 100];
    const [classOptions, setClassOptions] = useState<string[]>([]);
    const [threatNameOptions, setThreatNamesOptions] = useState<any[]>([]);
    const [threatKontrolName, setThreatKontrolName] = useState<string>(selectedRow.control_name ? selectedRow.control_name : "");
    const [severity, setSeveity] = useState<string>(selectedRow.severity);
    const [selectedClass, setSelectedClass] = useState<string[]>([]);
    const [seletedThreatName, setSeletedThreatName] = useState<any[]>(typeof selectedRow.threat_names_and_classes === 'string' ? JSON.parse(selectedRow.threat_names_and_classes) : []);
    const [count, setCount] = useState<any>(selectedRow.count);
    const [measure, setMeasure] = useState<string>(selectedRow.measurement_period);
    const [action, setAction] = useState<string>(selectedRow.action);
    useEffect(() => {
        getSeverityClassList(severity);
    }, [severity]);

    useEffect(() => {
        const classList = authReducer?.threatSeverityClassList?.data?.class?.data[0];
        if (classList) {
            const parsedClassOptions = classList.split(",");
            setClassOptions(parsedClassOptions);
        }
        else {
            setClassOptions([]);
        }
    }, [authReducer.threatSeverityClassList]);

    useEffect(() => {
        const extractUniqueClasses = () => {
            try {
                const parsedData = JSON.parse(selectedRow.threat_names_and_classes);
                const uniqueClassSet = new Set<string>(
                    parsedData.map((item: { class: string }) => item.class)
                );
                setSelectedClass(Array.from(uniqueClassSet));
            } catch (error) {
                console.error("Error parsing data or extracting unique classes:", error);
            }
        };
        extractUniqueClasses();
    }, []);

    useEffect(() => {
        getThreatNameList(selectedClass.map(item => `'${item}'`).join(','));
    }, [selectedClass]);

    useEffect(() => {
        const nameList = authReducer?.threatNamesList?.data;
        if (nameList && Array.isArray(nameList)) {
            const parsedThreatNameOptions = nameList.map(item => item);
            setThreatNamesOptions(parsedThreatNameOptions);
        }
        else {
            setThreatNamesOptions([]);
        }
    }, [authReducer.threatNamesList]);

    const handleSeverityChange = (event, value) => {
        setSeveity(value);
        setSelectedClass([]);
        setSeletedThreatName([]);
    }
    const isPresentinClass = (threatname, classes) => {
        if(classes.includes(threatname.class)){
            return true;
        }
        else return false;
    }
    const handleClassChange = (event, cls) => {
        setSelectedClass(cls);
        const remainingThreatNames = seletedThreatName.filter((threatname, index) => {
            return isPresentinClass(threatname, cls)
        })
        setSeletedThreatName(remainingThreatNames);
    }

    const handleThreatKontrolNameChange = (event, newInputValue) => {
        setThreatKontrolName(newInputValue);
    }
    const handleThreatNameChange = (event, thrtName) => {
        setSeletedThreatName(thrtName)
    }
    const handleCountChange = (event, value) => {
        setCount(value || event.target.value);
    }
    const handleMeasureChange = (event, meas) => {
        setMeasure(meas);
    }
    const handleSave = async () => {
        const notificationMethods: string[] = [];
        if (emailSelected) notificationMethods.push("email");
        if (teamsSelected) notificationMethods.push("microsoft_teams");
        try {
          const payload = {
                control_name: threatKontrolName,
                threat_names_classes: seletedThreatName,
                severity: severity,
                measurement_period: measure,
                count: parseInt(count),
                is_disabled: selectedRow.is_disabled ? true : false,
                action: action,
                notification_methods: notificationMethods,
                users_to_notify: email 
        };
          updateThreatKontrol(selectedRow.site_id, selectedRow.device_id, selectedRow.rule_id, payload);
          setEditOpen(false);
        } catch (error) {
            alert('Failed to save data. Please try again.');
        }
    };
    return (
        <Dialog className="menuScroll threatKontrolDialogbox" onClose={handleClose} open={true} maxWidth="lg" fullWidth>
            <div className="displayFlex threatKontrolMargin">
                <div className="threatKontrolHeading">{Heading}</div>
                <div className='threatKontrolClose'>  <img className="threatKontrolCloseIcon"
                    src={close}
                    alt='close'
                    onClick ={handleClose}>
                    </img>
                </div>
            </div>
            <Divider className="threatKontrolDivider"/>
            <div className="threatKontrolNameMargin threatkontrolSelectorGap">
                <Autocomplete
                    id="threatKontrolName"
                    size="small"
                    options={[]}
                    value={threatKontrolName}
                    freeSolo
                    fullWidth
                    onInputChange={handleThreatKontrolNameChange}
                    renderInput={(params) => <TextField {...params} label="ThreatKontrolName" title="ThreatKontrolName" variant="outlined" />}
                />
            </div>
            <div className="threatKontrolMargin">
                <div className="threatSourceInfo threatKontrolSubheading">
                    <Typography>Threat Source</Typography>
                    <JsonDataTooltip data={"runs every 5 minutes"}/>
                </div>
                <div className="threatkontrolSelectorGap">
                <Autocomplete
                    id="Site"
                    disableCloseOnSelect
                    options={[]}
                    value={"Device"}
                    size="small"
                    renderInput={(params) => <TextField {...params} label="Site" variant="outlined" style={{width:"200px"}}/>}
                />
                <Autocomplete
                    id="Device"
                    multiple
                    disableCloseOnSelect
                    fullWidth
                    options={[]}
                    value={[selectedRow.device_name]}
                    size="small"
                    renderInput={(params) => <TextField {...params} label="Device" variant="outlined" />}
                />
                </div>
                <br></br>
                <div >
                    <Grid>  
                        <Grid item xs={12} md={2} className="threatAction">
                            <Autocomplete
                                id="severity"
                                size="small"
                                options={optionsSeverity}
                                value={severity}
                                onChange={handleSeverityChange}
                                renderInput={(params) => <TextField {...params} label="Severity" title="Severity" variant="outlined" className="editseveritySelector"/>}
                            />
                            <Autocomplete
                                id="class"
                                size="small"
                                multiple
                                options={classOptions}
                                value={selectedClass}
                                onChange={handleClassChange}
                                renderInput={(params) => <TextField {...params} label="Class" title="Class" variant="outlined" className="editclassSelector"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        minHeight: 40 + selectedClass.length * 30, 
                                    },
                                }}/>}
                            />
                            <Autocomplete
                                id="threatName"
                                size="small"
                                multiple
                                options={threatNameOptions}
                                getOptionLabel={(option) => option.threat_name}
                                value={seletedThreatName}
                                onChange={handleThreatNameChange}
                                isOptionEqualToValue={(option, value) => option.threat_name === value.threat_name}
                                renderInput={(params) => <TextField {...params} label="ThreatName" title="ThreatName" variant="outlined" className="editthreatNameSelector"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        minHeight: 40 + seletedThreatName.length * 30, 
                                    },
                                }}/>}
                            />
                            <Autocomplete
                                id="action"
                                size="small"
                                options={[]}
                                value={action}
                                renderInput={(params) => <TextField {...params} label="Action" title="Action" variant="outlined" className="editactionSelector"/>}
                            />
                            <Autocomplete
                                id="meas"
                                size="small"
                                options={optionsMeas}
                                value={measure}
                                onChange={handleMeasureChange}
                                disabled={action == "Suppression"}
                                renderInput={(params) => <TextField {...params} label="Measurement" title="Measurement period" variant="outlined" className="editmeasSelector"/>}
                            />
                            <Autocomplete
                                id="count"
                                size="small"
                                options={optionsCount}
                                freeSolo 
                                onChange={handleCountChange}
                                onInputChange={handleCountChange}
                                value={count}
                                disabled={action == "Suppression"}
                                renderInput={(params) => <TextField {...params} label="Count" title="Count" placeholder="type number" variant="outlined" className="editcountSelector"/>}
                            />
                        </Grid>
                        <br></br>
                    </Grid>  
                </div>
            </div>
            <hr className="threadKontrolhr"></hr>
            <div className="threatKontrolMargin threatEmailSelect">
                <Typography className="threatKontrolSubheading">Notification</Typography>
                <div className="threatkontrolRuleCheckbox">
                    <Checkbox size='small' name={'email'} checked={emailSelected} onChange={handleEmailChange} className="threatKontrolCheckbox" disabled={action == "Suppression"}/>
                    <Typography variant="body2" className="threatKontrolCheckbocName">
                        Email
                    </Typography>
                </div>
                <div className="threatkontrolEmailGap"></div>
                <Autocomplete
                    id="select-device"
                    size='small'
                    multiple
                    fullWidth
                    freeSolo
                    options={users}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEmailChnage}
                    onInputChange={handleInputEmailChange}
                    inputValue={inputValue}
                    value={email}
                    disabled={action == "Suppression"}
                    renderInput={(params) => <TextField {...params} label="Enter Email Id* (end with , if typed)" variant='outlined' />}
                />
                <div className="threatkontrolEmailGap"></div>
                <div className="threatkontrolRuleCheckbox">
                    <Checkbox size='small' name={'email'} checked={teamsSelected} onChange={handleTeamsChange} disabled={action == "Suppression"}/>
                    <Typography variant="body2" className="threatKontrolCheckbocName">
                        Microsoft Teams
                    </Typography>
                </div>
            </div>
            <Divider className="threatKontrolDivider"/>
            <div className="displayFlex threatKontrolMargin">
                <Button id="threatKontrolCancelButton" onClick ={handleCancel}>
                    Cancel
                </Button>
                <Button id="threatKontrolSaveButton" onClick={handleSave}>
                    Update
                </Button>
            </div>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    options: state?.authReducer?.threatSeverityClassList
})

export default withRouter(
    connect(mapStateToProps, { getSeverityClassList, getThreatNameList, updateThreatKontrol })(EditThreatKontrol)
);