import React,{ useState, useEffect, useRef } from "react";
import { Button, Grid, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {getInventoryWarehouseTableData, getInventoryWarehouseVesselList, assignInventoryToVessel, createVessel, applyGoldenConfig, warehouseChangeGroup, getLicenseTypes, postDeviceResgistrationActions, getDpsDefaultActiveConfig } from "../../actions/Users/authenticateInventory";
import "../InventoryDeployedTable/inventoryDeployed.css"
import _ from "lodash";
import { KAPTUREV2_URL_INV, SERVICE_FUNCTION_TO_FEATURE } from '../../config';
import { checkLoggedInUserAuthorizedToViewPage, convertDateTimeIntoTimezone, getDataFromLocalStorage } from "../../utils/util";
import { displayToastError } from "../../server/request";
import SuccessPopup from "./SuccessPopup";
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import { getDecodeURI, getEncodedURI, checkSelectedOuIsInvitedOu, getInvitedOuList } from "../../utils/util";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DEFAULT_PAGE_SIZE, NEW_PER_PAGE_ITEMS } from "../../constants/Constants";
import "./inventoryWarehouse.css"
import { updateUserOuHasSubOus } from "../../actions/Users/authenticate";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";
import { DEFAULT_DATE_FORMAT, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import { Pagination } from "../Pagination";
import Download from '../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";
import { ASSIGN_INVENTORY_TO_VESSEL, CREATE_AND_ASSIGN_INVENTORY, GET_CSV_DATA_TO_DOWNLOAD } from "../../actions/types";
import { CONFIRM_DIALOG_TITLE } from "../../config";
import SettingsIcon from '@mui/icons-material/Settings';
import EditActionsPopup from "./EditActionsPopup";
import DeviceHistoryPopup from "../InventoryDeployedTable/DeviceHistoryPopup";
const convertToCsv = (data: any[][], userTimezone: string) => {
  const [_columns, ...rows] = data;
  const csvRows: any[] = [];
  rows.forEach(row => {
    csvRows.push([row[7], row[2], row[14], row[10], convertDateTimeIntoTimezone(row[13], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), row[11], row[12]])
  })
  let shipmentDate = 'Shipment Date (' + userTimezone + ')'
  return [['Organization', 'DID', 'EOS Version','Proposal ID', shipmentDate, 'Slip ID', 'Tracking ID'], ...csvRows]
}

const InventoryWarehouseTable = (props) => {
  const { authReducer, errorReducer, getInventoryWarehouseTableData, getInventoryWarehouseVesselList, assignInventoryToVessel, createVessel, history, location, updateUserOuHasSubOus, applyGoldenConfig, downloadAsCSV, warehouseChangeGroup, isReadOnlyUser, getLicenseTypes, postDeviceResgistrationActions, getDpsDefaultActiveConfig } = props;
    
  let _q = getDecodeURI(location?.search);
  const [openAssignDialogue, setOpenAssignDialogue] = useState<any>(false);
  let   [inventoryWarehouseTableData, setInventoryWarehouseTableData] = useState<any[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<any>({})
  let   [selectedOu, setSelectedOu ] = useState<any>({});
  const [contactName, setContactName] = React.useState("");
  const [contactEmail, setContactEmail] = React.useState("");
  const [openSuccesPopup, setOpenSuccessPopup] = useState(false);
  const [ou, setOu] = useState<any>({});
  const ENTER_KEY_CODE = "Enter";
  const [inputSearchValue, setInputSearchValue] = useState<any>(_q.hasOwnProperty("search") ? _q.search : "")
  const [fromItem, setFromItem] = useState<number>(0);
  const [toItem, setToItem] = useState<number>(0);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = useState<any>(_q.hasOwnProperty("page") ? parseInt(_q.page) : 1)
  const [pages, setPages] = React.useState(0);
  const [limit, setLimit] = useState<any>(_q.hasOwnProperty("limit") ? _q.limit : DEFAULT_PAGE_SIZE);
  // sorting
  const DP_NAME= "dpName"
  const DEVICE_ID= "deviceId"
  const EOS_VERSION= "eos_version"
  const PROPOSAL_ID= "proposalId"
  const SHIPMENT_DATE= "shipmentDate"
  const SLIP_ID= "slipId"
  const ASC_ORDER = "asc";
  const DESC_ORDER = "desc";
  const [sortBy, setSortBy] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : SHIPMENT_DATE);
  const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);

  const [idData, setIdData] = useState<any>({ Site: { label: '', id: '' }, Device: { label: '', id: '' }, Configuration: { label: '', id: '' } })
  const [siteValue, setSiteValue] = useState<any>({});
  const [isCreate, setIsCreate] = useState(false);
  const [isGoldenConfigApplied, setIsGoldenConfigApplied] = useState(false);
  const [isUpgradeToLatestEosSelected, setIsUpgradeToLatestEosSelected] = useState(false);
  const [isGoldenConfigOtherApplied, setIsGoldenGonfigOtherApplied] = useState(true);
  const [createdSiteId, setCreatedSiteId] = useState('');
  const [isAuthorizedToBeta, setIsAuthorizedToBeta] = useState<Boolean>(false);

  const BETA_FEATURE = "beta-feature";
  const betaFeatureService = SERVICE_FUNCTION_TO_FEATURE[BETA_FEATURE]["service"];
  const betaFeatureFunction = SERVICE_FUNCTION_TO_FEATURE[BETA_FEATURE]["function"];
  const [value, setValue] = useState<any>(null);

  const VIEW_INVENTORY = "view-inventory";
  const viewWarehouseService = SERVICE_FUNCTION_TO_FEATURE[VIEW_INVENTORY]['service'];
  const viewwarehouseFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_INVENTORY]['function'];
  const searchTimeoutRef = useRef<NodeJS.Timeout>();
  const dispatch = useDispatch();

  // move to sub organisation
  const [invitedOus, setInvitedOus] = useState<any[]>([])
  const [isSelectedOuIsUserOu, setIsSelectedOuIsUserOu] = useState(false)
  const [changeGroupPopup, setChangeGroupPopup] = useState(false);
  const [actionSelectedRow, setActionSelectedRow] = useState<any>({})

  // Register pop up - license, duration
  const [selectedLicense, setSelectedLicense] = useState<any>(null)
  const [selectedTerm, setSelectedTerm] = useState<any>(null)
  const [licenseOptions, setLicenseOptions] = useState<any[]>([])
  const [termOptions, setTermOptions] = useState<any[]>([])

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: {},
  });
  const [registerObj, setRegisterObj] = useState({});
  const [editActionsPopup, setEditActionsPopup] = useState(false);
  const [deviceHistoryPopup, setDeviceHistoryPopup] = useState(false);

  const clearLicenceSelection = () => {
    setSelectedLicense(null)
    setSelectedTerm(null)
    setLicenseOptions([])
    setTermOptions([])
  }

  const changeGroup = () => {
    warehouseChangeGroup(ou.id,actionSelectedRow["deviceId"])
    setEditActionsPopup(false);
  }

  useEffect(() => {
    if(!_.isEmpty(authReducer.inventoryWarehouseChangeGroupData)){
      setChangeGroupPopup(false)
      doSearch("")
    }
  }, [authReducer.inventoryWarehouseChangeGroupData]);

  useEffect(() => {
    const _loggedInUser: any = getDataFromLocalStorage("loggedInUser");
    if (_loggedInUser) {
      const loggedInUser = JSON.parse(_loggedInUser);
      if (loggedInUser?.data?.user) {
        setContactName(`${loggedInUser?.data?.user?.name?.givenName} ${loggedInUser?.data?.user?.name?.familyName}`)
      }
    }
  }, []);

  useEffect(() => {
    updateUserOuHasSubOus(authReducer)
    let invitedOuList = getInvitedOuList(authReducer.getOrganisationUnitList)
    setInvitedOus(invitedOuList)
  }, [authReducer.getOrganisationUnitList]);

  useEffect(() => {
    if(invitedOus.length>0){
      let isSelectedOuIsInvitedOu = checkSelectedOuIsInvitedOu(authReducer.selectedOu, invitedOus)
      setIsSelectedOuIsUserOu(!isSelectedOuIsInvitedOu)
    }
  }, [invitedOus])

  useEffect(() => {
    const _loggedInUser: any = getDataFromLocalStorage("loggedInUser");
    if (_loggedInUser) {
      const loggedInUser = JSON.parse(_loggedInUser);
      if (loggedInUser?.data?.user) {
        setContactEmail(`${loggedInUser?.data?.user.userName}`)
      }
      setIsAuthorizedToBeta(checkLoggedInUserAuthorizedToViewPage(betaFeatureService, betaFeatureFunction));
    }
  }, []);

  useEffect(() => {
    if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
      if (authReducer.csvDownloadId === 'inventory-warehouse') {
        DownloadCSV(convertToCsv(authReducer.csvDataDownloded, authReducer.userTimezone)
          , {
              formatters: {
                  0: 'ESC-COMMA',
                  4: 'ESC-COMMA'
              }
          }
      );
        return () => {
          dispatch(clearCsvDownload())
        }
      }
    }
  }, [authReducer.csvDataDownloded])
  
  const searchBoxKeyDown = (event: any) => {
    if (event && event.key === ENTER_KEY_CODE) {
        doSearch(inputSearchValue);
    }
  }
  
  const doSearch = (value: string) => {
      let params: any = getDecodeURI(location?.search);
      value ? params.search = value : delete params.search;
      delete params.page;
      setPage(1);
      doNavigate(location.url, params);
      getInventoryWarehouseTableData(selectedOu.id , value, 1, limit, sortBy, sortOrder);
  };
  
  const doNavigate = (pathname, params={}) => {
    history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
  }

  const setSorting = (event: any, field: any, order: any,) => {
    if (event && event.currentTarget) {
        let params: any = getDecodeURI(location?.search);
        params.page = 1;
        params.sort = field;
        params.sortOrder = order;

        setPage(1);
        setSortBy(field);
        setSortOrder(order);

        doNavigate(location.url, params);
        getInventoryWarehouseTableData(selectedOu.id , inputSearchValue, 1, limit, field, order);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id
        && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name) {
        setSelectedOu(authReducer.selectedOu);
        setPage(_q.hasOwnProperty("page") ? parseInt(_q.page) : 1)
        if(authReducer.selectedOu?.name){
          let isSelectedOuIsInvitedOu = checkSelectedOuIsInvitedOu(authReducer.selectedOu, invitedOus)
          setIsSelectedOuIsUserOu(!isSelectedOuIsInvitedOu)
        }
    }
  }, [authReducer.selectedOu]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorFetchOuResources)) {
      displayToastError(errorReducer.errorFetchOuResources.description)
      errorReducer.errorFetchOuResources = {};
    }
  }, [errorReducer.errorFetchOuResources]);

  useEffect(()=>{
    if(selectedOu.id){
      getInventoryWarehouseTableData(selectedOu.id , inputSearchValue, page, limit, sortBy, sortOrder);
    }
  },[selectedOu])

  useEffect(() => {
    if (!_.isEmpty(authReducer.getInventoryWarehouseTableData)) {
        let data = authReducer.getInventoryWarehouseTableData.hasOwnProperty("data") && Array.isArray(authReducer.getInventoryWarehouseTableData.data.filteredRecords) ? authReducer.getInventoryWarehouseTableData.data.filteredRecords : [];
        setInventoryWarehouseTableData(data);
        const totalCount: number = authReducer.getInventoryWarehouseTableData.data.hasOwnProperty("count") ? parseInt(authReducer.getInventoryWarehouseTableData.data.count) : 0;
        const pages = Math.ceil(totalCount / limit);
        updatePageInfo(page, limit, data?.length, pages, totalCount);
        authReducer.getInventoryWarehouseTableData = {};
    }
}, [authReducer.getInventoryWarehouseTableData]);

useEffect(() => {
  if (!_.isEmpty(authReducer.assignInventoryToVessel)) {
    if(authReducer.assignInventoryToVessel.status == 1 && authReducer.assignInventoryToVessel.error_code == 10043) {
      const payload = {...registerObj, validated: true};
      setConfirmDialog({
        isOpen: true,
        title: CONFIRM_DIALOG_TITLE,
        subTitle: authReducer.assignInventoryToVessel?.message,
        onConfirm: () => {
          assignInventoryToVessel(selectedDevice.deviceId, payload)
          setEditActionsPopup(false);
        },
      })
    }
    else if(authReducer.assignInventoryToVessel.hasOwnProperty("status") && authReducer.assignInventoryToVessel.status==1){
      setOpenAssignDialogue(false)
      setOpenSuccessPopup(true)
      setEditActionsPopup(false);

      let _page = 1

      let params: any = getDecodeURI(location?.search)
      params.page = _page
      doNavigate(location.url, params)

      setPage(_page)

      getInventoryWarehouseTableData(selectedOu.id , inputSearchValue, _page, limit, sortBy, sortOrder)

      let siteId = isCreate ? createdSiteId : siteValue?.id
      let deviceId= selectedDevice?.deviceId

      const payload: any = {
        upgradeToLatestEOS: false,
        applyConfig : false,
      }

      if (isUpgradeToLatestEosSelected) {
        payload.upgradeToLatestEOS= true
      }

      if (isGoldenConfigApplied || (isGoldenConfigOtherApplied && idData?.Configuration?.id)) {
        payload.applyConfig = true
        if (isGoldenConfigOtherApplied && idData?.Configuration?.id) {
          payload.configId = idData?.Configuration?.id
        }
      }
      // post registration actions
      // postDeviceResgistrationActions(siteId,deviceId,payload)
    } else {
      setEditActionsPopup(false);
    }
    dispatch({ type: ASSIGN_INVENTORY_TO_VESSEL, payload: {}})
  }
}, [authReducer.assignInventoryToVessel]);

useEffect(() => {
  if (!_.isEmpty(authReducer.createVessel)) {
    if(authReducer.createVessel.hasOwnProperty("status") && authReducer.createVessel.status==1){
      let payload = {
        "loc": authReducer.createVessel.hasOwnProperty("data") && authReducer.createVessel.data.name ,
        "k4Id": authReducer.createVessel.hasOwnProperty("data") && authReducer.createVessel.data.k4Id,
    }
    payload["setTierTerm"] = {
      "tier": selectedLicense?.value,
      "tierAlias" : selectedLicense?.label,
      "termValue": selectedTerm?.value,
      "dpIdd": ou.id
    }
    setRegisterObj(payload);
    setCreatedSiteId(authReducer?.createVessel?.data?.k4Id)
    assignInventoryToVessel(selectedDevice.deviceId , payload)     
    }
    dispatch({ type: CREATE_AND_ASSIGN_INVENTORY, payload: {}})
  }
}, [authReducer.createVessel]);


const handleAssignInventory = () => {
  if(siteValue?.id) {
    let payload = {
      "loc": siteValue ? siteValue.label : null,
      "k4Id": siteValue ? siteValue.id : null,
    }
    payload["setTierTerm"] = {
      "tier": selectedLicense?.value,
      "tierAlias" : selectedLicense?.label,
      "termValue": selectedTerm?.value,
      "dpIdd": ou.id
    }
    setRegisterObj(payload);
    assignInventoryToVessel(selectedDevice.deviceId , payload)
  }
}

const handleCreateAndAssignInventory = () => {
  if(siteValue?.label) {
    let payload = {
      "vesselName": siteValue?.label,
      "contactName": contactName,
      "contactEmail":contactEmail,
      "k4DpId": ou.id,
      "source": "SPORT"
    }
    createVessel(contactEmail, payload)
  }
}

const handleButtonFunction = (row)=> {
  setActionSelectedRow(row)
  setOpenAssignDialogue(true);
  setSelectedDevice(row);
  setSiteValue({});
  setIsGoldenGonfigOtherApplied(false);
  setIdData({ Site: { label: '', id: '' }, Device: { label: '', id: '' }, Configuration: { label: '', id: '' } });
}

const handleCloseSuccesPopup = () => {
  setOpenSuccessPopup(false);
}

const handlePerPageChange = (e: any) => {
  let _perPage = parseInt(e.target.value);
  if (Number.isInteger(_perPage)) {
      let params: any = getDecodeURI(location?.search);
      params.page = 1;
      params.limit = _perPage;
      setPage(1);
      setLimit(_perPage);
      doNavigate(location.url, params);
      getInventoryWarehouseTableData(selectedOu.id, inputSearchValue, 1, _perPage, sortBy, sortOrder)
  }
};

const handlePagination = (e, value) => {
  let params: any = getDecodeURI(location?.search);
  params.page = value;
  setPage(value)
  doNavigate(location.url, params);
  getInventoryWarehouseTableData(selectedOu.id, inputSearchValue, value, limit, sortBy, sortOrder)
};

const updatePageInfo = (page: number, limit: number, itemCount: number, pages: number, total: number) => {
  setPage(page);
  setFromItem(itemCount > 0 ? ((page-1) * limit) + 1 : 0);
  setToItem(((page-1) * limit) + itemCount);
  setTotal(total);
  setPages(pages);
};

const handleOu = (ou: any) => {
  setValue(null);
  setOu(ou)
  getInventoryWarehouseVesselList(ou.name, '', viewWarehouseService, viewwarehouseFunction);
  clearLicenceSelection()
  getLicenseTypes(ou.id, actionSelectedRow["deviceId"])
  getDpsDefaultActiveConfig(ou.id,selectedDevice.deviceId)
  setIdData({ Site: { label: '', id: '' }, Device: { label: '', id: '' }, Configuration: { label: '', id: '' } });
}

  const handleOnSearchClick = () => {
    doSearch(inputSearchValue);
  }
  const handleOnSearchClear = () => {
    setInputSearchValue('');
    doSearch('');
  }

  const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputSearchValue(e.target.value);
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(() => {
      searchTimeoutRef.current = undefined;
      doSearch(e.target.value);
    }, 1000)
  }

  const handleDownloadReport = () => {
    const params: CsvParameters = {
      type: 'GET_API_EXTERNAL',
      id: 'inventory-warehouse',
      endpoint: `${KAPTUREV2_URL_INV}dp/inventoryList?k4DpId=${selectedOu.id}&searchValue=${inputSearchValue}&pageNumber=${1}&pageSize=${total}&sortKey=${sortBy}&sortOrder=${sortOrder}`,
    }
    downloadAsCSV(params);
  }

  const handleOpenEditPopup = (row) => {
    setEditActionsPopup(true);
    setActionSelectedRow(row);
    handleButtonFunction(row);
    setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
  }

  const handleShowHistory = (row) => {
    setActionSelectedRow(row);
    setDeviceHistoryPopup(true);
  }

  return (
    <div className="Form">
      <Grid className="am-table inventory-am-table">
        <Grid className="topbarWidth alignTopbarItemsCenter">
          <Grid item xs={6} sm={6} md={6} lg={6} className="searchbarMaxWidth1">
            <Box className="searchbarBox">
              {<TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Type something"
                  autoComplete="off"
                  size="small"
                  value={inputSearchValue}
                  onChange={handleOnSearchChange}
                  onKeyDown={(event) => searchBoxKeyDown(event)}
                  InputLabelProps={{ className: "serachLabel" }}
                  InputProps={{
                      className: "searchbarInputs",
                      startAdornment: (
                          <InputAdornment position="start">
                            {
                              !inputSearchValue && <SearchIcon
                                className="searchbarIconsPosition"
                                onClick={handleOnSearchClick}
                              />
                            }
                            {
                              inputSearchValue && <CloseIcon
                                className="searchbarIconsPosition"
                                onClick={handleOnSearchClear}
                              />
                            }
                          </InputAdornment>
                      ),
                  }}
              />}
            </Box>
          </Grid>
          {total > 0 && <Grid item xs={6} sm={6} md={6} lg={6} className="dwn-margin-auto">
            <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
          </Grid>}
        </Grid>
        <Grid className="menuScroll">
          <Box>
            <Pagination
              rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
              count={total ? total : 0}
              rowsPerPage={limit}
              page={page}
              onPageChange={handlePagination}
              onRowsPerPageChange={handlePerPageChange}
            />
          </Box>
          <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer deployed-container ">
            <Table aria-label="simple sticky table" stickyHeader>
              <TableHead className="usageReports-tableHead">
                <TableRow className="UsageReports-tableRow login-tableRow">
                  <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                    <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                      <span className="login-history-dp-name font-wt-900">Organization</span>
                      <Grid className="sort-icon-margin">
                        <img src={sortBy === DP_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                          className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DP_NAME, ASC_ORDER)} />
                        <img src={sortBy === DP_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                          className="sortingImages" onClick={(event) => setSorting(event, DP_NAME, DESC_ORDER)} />
                      </Grid>
                    </span>
                  </TableCell>
                  <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                    <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                      <span className="login-history-dp-name font-wt-900">DID</span>
                      <Grid className="sort-icon-margin">
                        <img src={sortBy === DEVICE_ID && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                          className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DEVICE_ID, ASC_ORDER)} />
                        <img src={sortBy === DEVICE_ID && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                          className="sortingImages" onClick={(event) => setSorting(event, DEVICE_ID, DESC_ORDER)} />
                      </Grid>
                    </span>
                  </TableCell>
                  <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                    <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                      <span className="login-history-dp-name font-wt-900">EOS Version</span>
                      <Grid className="sort-icon-margin">
                        <img src={sortBy === EOS_VERSION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                          className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, EOS_VERSION, ASC_ORDER)} />
                        <img src={sortBy === EOS_VERSION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                          className="sortingImages" onClick={(event) => setSorting(event, EOS_VERSION, DESC_ORDER)} />
                      </Grid>
                    </span>
                  </TableCell>
                  <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                    <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                      <span className="login-history-dp-name font-wt-900">Proposal Id</span>
                      <Grid className="sort-icon-margin">
                        <img src={sortBy === PROPOSAL_ID && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                          className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, PROPOSAL_ID, ASC_ORDER)} />
                        <img src={sortBy === PROPOSAL_ID && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                          className="sortingImages" onClick={(event) => setSorting(event, PROPOSAL_ID, DESC_ORDER)} />
                      </Grid>
                    </span>
                  </TableCell>
                  <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                    <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                      <span className="login-history-dp-name font-wt-900">Shipment Date</span>
                      <Grid className="sort-icon-margin">
                        <img src={sortBy === SHIPMENT_DATE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                          className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SHIPMENT_DATE, ASC_ORDER)} />
                        <img src={sortBy === SHIPMENT_DATE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                          className="sortingImages" onClick={(event) => setSorting(event, SHIPMENT_DATE, DESC_ORDER)} />
                      </Grid>
                    </span>
                  </TableCell>
                  <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                    <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                      <span className="login-history-dp-name font-wt-900">Slip Id</span>
                      <Grid className="sort-icon-margin">
                        <img src={sortBy === SLIP_ID && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                          className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SLIP_ID, ASC_ORDER)} />
                        <img src={sortBy === SLIP_ID && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                          className="sortingImages" onClick={(event) => setSorting(event, SLIP_ID, DESC_ORDER)} />
                      </Grid>
                    </span>
                  </TableCell>
                  <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                    <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon">
                      <span className="login-history-dp-name font-wt-900" >Tracking Id</span>
                    </span>
                  </TableCell>
                {!isReadOnlyUser && <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                    <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon">
                      <span className="login-history-dp-name font-wt-900">Operations</span>
                    </span>
                  </TableCell>}
                </TableRow>
              </TableHead>
              {inventoryWarehouseTableData && 0 != inventoryWarehouseTableData.length ? (
                <TableBody className="tableBody usage-tableBody">
                  {
                    inventoryWarehouseTableData && inventoryWarehouseTableData.map((row, i) =>
                      <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr">
                        <TableCell className="login-left-align inter-font selectedClick text-center">
                          <span className="elementDataType">{row?.dpName}</span>
                        </TableCell>
                        <TableCell className="login-left-align inter-font text-center">
                          <span className="elementDataType" id='selectedClick-column' onClick={() => handleShowHistory(row)}>{row.deviceId}</span>
                        </TableCell>
                        <TableCell className="login-left-align inter-font text-center">
                          <span className="elementDataType">{_.isEmpty(row.eos_version) || "-" == row.eos_version ? "" : "v"+row.eos_version.split("~")[0]}</span>
                        </TableCell>
                        <TableCell className="login-left-align inter-font text-center">
                          <span className="elementDataType">{"-" != row.proposalId ? row.proposalId : ""}</span>
                        </TableCell>
                        <Tooltip disableHoverListener={_.isEmpty(row.shipmentDate) || "-" == row.shipmentDate} title={<span className="font-size-12px">{convertDateTimeIntoTimezone(row['shipmentDate'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</span>}>
                          <TableCell className="login-left-align inter-font text-center">
                            <span className="elementDataType">{convertDateTimeIntoTimezone(row['shipmentDate'], authReducer.userTimezone, DEFAULT_DATE_FORMAT)}</span>
                          </TableCell>
                        </Tooltip>
                        <TableCell className='login-left-align inter-font text-center'>
                          <span className="elementDataType">{"-" != row.slipId ? row.slipId : ""}</span>
                        </TableCell>
                        <TableCell className="login-left-align inter-font text-center">
                          <span className="elementDataType">{"-" != row.tackingId ? row.tackingId : ""}</span>
                        </TableCell>
                        {!isReadOnlyUser && <TableCell className="login-left-align inter-font text-center">
                          { row.deviceId.substring(0, 2) !== "AM" ? 
                          <span onClick={() => handleOpenEditPopup(row)}><SettingsIcon className="inventory-edit-icon" /></span>
                          : <span><SettingsIcon className="greySettings"/></span> }
                        </TableCell>}
                      </TableRow>
                    )
                  }
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      { deviceHistoryPopup ? <DeviceHistoryPopup open={deviceHistoryPopup} setOpen={setDeviceHistoryPopup} deviceIdSelected={actionSelectedRow.deviceId} inventoryId={actionSelectedRow.id} /> : null}
      {openSuccesPopup ? <SuccessPopup selectedDevice={selectedDevice} siteValue={siteValue} handleCloseSuccesPopup={handleCloseSuccesPopup}/> : null}            
      { editActionsPopup ? <EditActionsPopup
          confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} changeGroup={changeGroup}
          actionSelectedRow={actionSelectedRow} open={editActionsPopup} setOpen={setEditActionsPopup}
          handleOu={handleOu} value={value} setValue={setValue}
          selectedDevice={selectedDevice} 
          handleAssignInventory={handleAssignInventory} 
          handleCreateAndAssignInventory={handleCreateAndAssignInventory} 
          openAssignDialogue={openAssignDialogue} setOpenAssignDialogue={setOpenAssignDialogue} setOu={setOu}
          idData={idData} setIdData={setIdData} siteValue={siteValue} setSiteValue={setSiteValue} 
          isCreate={isCreate} setIsCreate={setIsCreate}
          selectedLicense={selectedLicense} setSelectedLicense ={setSelectedLicense}
          selectedTerm={selectedTerm} setSelectedTerm={setSelectedTerm}
          licenseOptions={licenseOptions} setLicenseOptions={setLicenseOptions}
          termOptions={termOptions} setTermOptions={setTermOptions}
          clearLicenceSelection={clearLicenceSelection}
          isUpgradeToLatestEosSelected={isUpgradeToLatestEosSelected} setIsUpgradeToLatestEosSelected={setIsUpgradeToLatestEosSelected}
          isGoldenConfigApplied = {isGoldenConfigApplied} setIsGoldenConfigApplied = {setIsGoldenConfigApplied}
          isGoldenConfigOtherApplied={isGoldenConfigOtherApplied} setIsGoldenGonfigOtherApplied={setIsGoldenGonfigOtherApplied}
          setRegisterLoading={authReducer.setAssignInventoryToVessel}
       /> : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser,
  });
  
  export default withRouter(
    connect(mapStateToProps, { 
      getInventoryWarehouseTableData, getInventoryWarehouseVesselList, assignInventoryToVessel, createVessel, updateUserOuHasSubOus, applyGoldenConfig, downloadAsCSV, warehouseChangeGroup, getLicenseTypes, postDeviceResgistrationActions, getDpsDefaultActiveConfig })( InventoryWarehouseTable )
  );