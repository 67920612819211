import { Fragment, useEffect, useMemo, useState } from "react";
import { IconButton, Paper, TableCell, TableRow, Tooltip, Collapse, TableHead, Table, TableContainer, TableBody } from "@mui/material";
import { Edit, Delete, Lens } from "@material-ui/icons";
import { convertDateTimeIntoTimezone, readablePeriod } from "../../../utils/util";
import { Device, GeoConfig } from "../types";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import {JsonDataTooltip} from "./../../../components/JSONTooltip/JsonTooltip"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InfoIcon from '@mui/icons-material/Info';

interface EditableGeoConfigRowProps {
    geoConfig: GeoConfig;
    onEdit: (geoConfig: GeoConfig) => void;
    onDelete: (geoConfig: GeoConfig) => void;
    onToggleDisable: (geoConfig: GeoConfig) => void;
    isReadOnlyUser: boolean;
    devices: any;
    timezone: string;
    index: number;
}

export function EditableGeoConfigRow(props: EditableGeoConfigRowProps) {
    const { index, geoConfig, devices, onEdit, onDelete, onToggleDisable, isReadOnlyUser, timezone } = props;
    const [selectedDevice, setSelectedDevice] = useState<Device | undefined>(geoConfig != null ? devices.find(d => d.id === geoConfig.device_id) : undefined);
    const [open, setOpen] = useState(false);
    let actions: any = geoConfig?.actions

    const handleEdit = () => {
        onEdit(geoConfig);
    }

    const handleDelete = () => {
        onDelete(geoConfig);
    }

    useEffect(() => {
        if (index == 0) {
            setOpen(!open)
        }
    }, [])

    useEffect(() => {
        if (devices?.length > 0) {
            setSelectedDevice(geoConfig != null ? devices.find(d => d.id === geoConfig.device_id) : undefined);
        }
    }, [devices])

    return (
        <>
            <TableRow key={geoConfig.id} className="row-parent">
                <TableCell className={open ? "accordian-expand no-padding" : 'accordian-expand remove-border-btm'} id={index != 0 ? 'hub-add-padding-top' : ''}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon className="accordian-arrow" /> : <KeyboardArrowRightIcon className="accordian-arrow" />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {geoConfig.rule_name}
                </TableCell>
                <TableCell>
                    {geoConfig.dp_name}
                </TableCell>
                <TableCell>
                    {"Geo"}
                </TableCell>
                <TableCell>
                    <Tooltip title={
                                        <Paper elevation={3}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            MCC Value
                                                        </TableCell>
                                                        <TableCell>
                                                            ACTION
                                                        </TableCell>
                                                        <TableCell>
                                                            ACTION VALUE
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        geoConfig.actions?.map((geoConfigRow, i)=>{
                                                            return (
                                                                <TableRow>
                                                                    <TableCell>{geoConfigRow.geo_codes && geoConfigRow.geo_codes.length > 0 ? geoConfigRow.geo_codes[0]: ""}</TableCell>
                                                                    <TableCell>{geoConfigRow.action_type == "assignWanProfile" ? "ASSIGN WAN POFILE": geoConfigRow.action_type == "assignTrafficPolicy"? "ASSIGN TRAFFIC POLICY": geoConfigRow.action_type == "sendNotification"?"SEND NOTIFICATION":""}</TableCell>
                                                                    <TableCell>{geoConfigRow.action_type == "assignWanProfile" ? 
                                                                            (geoConfigRow.devices && geoConfigRow.devices.length > 0 ? geoConfigRow.devices[0].wan_profile?.name : "") 
                                                                            : geoConfigRow.action_type == "assignTrafficPolicy"? 
                                                                            (geoConfigRow.devices && geoConfigRow.devices.length > 0 ? geoConfigRow.devices[0].traffic_policy?.name : "") 
                                                                            : geoConfigRow.action_type == "sendNotification" ? "":""}</TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </Paper>
                    } classes={{ tooltip: "quota_management--usage_tooltip" }} placement="top">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    {geoConfig.summary}
                </TableCell>
                {!isReadOnlyUser && <TableCell>
                    <Paper elevation={0} className="geoConfig_management--device_summary--actions">
                        <Fragment>
                            <Tooltip title="Edit">
                                <IconButton size='small' onClick={handleEdit}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton size='small' onClick={handleDelete}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Fragment>
                    </Paper>
                </TableCell>}
            </TableRow>
            {
                open ? 
                <TableRow className="hub-empty-table-row">
                    <TableCell>
                    </TableCell>
                    <TableCell colSpan={6}>
                        <TableContainer>
                            <Table sx={{ minWidth: 550 }} size="small" aria-label="starlink--service_accounts">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>DEVICE NAME</TableCell>
                                        <TableCell>CURRENT MCC</TableCell>
                                        <TableCell>LAST ACTION AT</TableCell>
                                        <TableCell>STATUS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        geoConfig?.actions && geoConfig?.actions.length > 0 ? geoConfig?.actions[0].devices?.map((eachRow, i) => (
                                                <TableRow>
                                                    <TableCell>
                                                        {eachRow?.device_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {eachRow?.mcc}
                                                    </TableCell>
                                                    <TableCell>
                                                    {convertDateTimeIntoTimezone(eachRow?.last_action_at, timezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {eachRow?.status}
                                                    </TableCell>
                                                </TableRow>
                                        )):<></>
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableCell>
                </TableRow>
            : <></>                  
            }
        </>
    )
}