import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';

import { Box, Button, FormControl, Grid, InputAdornment, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Tooltip } from '@mui/material';
import { FiberManualRecord, ArrowDownward, ArrowUpward, Search, Clear } from '@mui/icons-material';

import { Device, Quota, QuotaSortBy, Site, SortOrder, User } from '../types';

import { EditableQuotaRow } from '../EditableQuotaRow';
import { createQuota, deleteQuota, getQuotas, getQuotasParameters, updateQuota, toggleQuota, updateQuotaParameters, ACTION_TYPES } from '../slice';
import { toast } from 'react-toastify';
import { DeleteQuotaConfirmationDialog } from '../DeleteQuotaConfirmDialog';

import QuotaConfigDialog from '../QuotaConfigDialog';
import { Pagination } from '../../../components/Pagination';
import './index.css';
import moment from 'moment-timezone';
import { checkLoggedInUserAuthorizedToViewPage, convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI, readablePeriod } from '../../../utils/util';
import SortArrows from '../../../components/SortArrows';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';

import Download from '../../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../../actions/types";
import DownloadCSV, { clearCsvDownload, escapeJsonString } from '../../../components/DownloadCSV'
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from '../../../utils/constants';
import { QuotaCopy } from '../QuotaCopy';
import { getGroupList, getDeviceList, copyQuotaConfig } from "../../../actions/Users/authenticateInventory";
import { SERVICE_FUNCTION_TO_FEATURE } from '../../../config';
import InfoIcon from '@mui/icons-material/Info';


const convertToCsv = (data: any[][], userTimezone: string) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        let dataSource = ''
        if (row[29]) {
            dataSource = "WAN Interfaces: " +  row?.[29]?.join?.(';')
        } else if (row[1]) {
            dataSource = "Access Networks: " +  row?.[1]?.join?.(';')
        } else if (row?.[22]?.service_line_numbers) {
            dataSource = "Starlink Service Lines: " +  row?.[22]?.service_line_numbers?.join?.(';')
        }
        csvRows.push([row[20], row[9], dataSource, convertDateTimeIntoTimezone(row[5], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), readablePeriod(row[16], row[18]), row[6], row[24]])
    })
    let quotaStart = 'Quota Start ('+ userTimezone +')'
    return [['Site', 'Device', 'Data Source', quotaStart, 'Quota Period', 'Usage (GB)', 'Status'], ...csvRows]
}

export interface DashboardProps {
    sites: Site[];
    devices: Device[];
    users: User[];
    quotas: Quota[];
    totalQuotas: number;
    gettingQuotas: boolean;
    gettingCurrentUsage: boolean;
    authReducer?: any;
    getQuotas: (parameters: getQuotasParameters) => any;
    deleteQuota: (parameters: any) => any;
    createQuota: (parameters: any) => any;
    updateQuota: (parameters: updateQuotaParameters) => any;
    toggleQuota: (parameters: any) => any;
    downloadAsCSV: (params: CsvParameters) => void;
    isReadOnlyUser: boolean;
    getGroupList: (parameters: any) => any;
    getDeviceList: (parameters: any) => any;
    copyQuotaConfig: (parameters: any) => any;
}

function Dashboard(props: DashboardProps) { 
    const { sites, devices, users, quotas, totalQuotas, getQuotas, createQuota, updateQuota, deleteQuota, toggleQuota, gettingQuotas, gettingCurrentUsage, authReducer, downloadAsCSV, isReadOnlyUser, getGroupList, getDeviceList, copyQuotaConfig } = props;

    const isAuthorizedToBeta= checkLoggedInUserAuthorizedToViewPage(SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"], SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"]);

    const history = useHistory();
    const location = useLocation()
    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [toDeleteQuota, setToDeleteQuota] = React.useState<Quota | null>(null);
    const [toUpdateQuota, setToUpdateQuota] = React.useState<Quota | null>(null);
    const [openQuotaConfigDialog, setOpenQuotaConfigDialog] = React.useState<boolean>(false);
    const [quotaDialogHeading, setQuotaDialogHeading] = React.useState<string>("Add Quota");

    const [searchText, setSearchText] = React.useState<string>(queryParams.get('search') || '');
    const [paginationParams, setPaginationParams] = React.useState<{
        page: number;
        size: number;
        sortBy: QuotaSortBy;
        sortOrder: SortOrder;
        search: string;
    }>({
        page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : 10,
        sortBy: queryParams.get('sortBy') as QuotaSortBy || 'used_percent',
        sortOrder: queryParams.get('sortOrder') as SortOrder || 'desc',
        search: queryParams.get('search') || ''
    });

    const searchTimeoutRef = useRef<NodeJS.Timeout>();

    const dispatch = useDispatch();

    const [openQuotaCopyPopUp, setOpenQuotaCopyPopUp] = useState<boolean>(false)
    const [clickedQuotaRow, setClickedQuotaRow] = useState<any>()


    const refreshQuotas = (_searchText: string | undefined = undefined) => {
        if (_.isEmpty(sites)) {
            // empty the table
            dispatch({
                type: ACTION_TYPES.GET_QUOTAS, payload: {
                    data: {
                        rows: [
                            [[], 0]
                        ]
                    }
                }
            })
            return;
        }
        setTimeout(() => {
            getQuotas({ sites: sites.map(site => site.id), search: paginationParams.search, sort: paginationParams.sortBy, order: paginationParams.sortOrder, limit: paginationParams.size, offset: (paginationParams.page - 1) * paginationParams.size })
        }, 1000);
    }

    const handleCreateQuota = async (site: string, quota: Quota) => {
        let res = await createQuota({
            site: site,
            quota: quota
        });
        if (res?.success) {
            refreshQuotas();
        } else {
            toast.error("Failed to create quota.", {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    }

    const handleUpdateQuota = async (siteId: string, deviceId: string, quotaId: string, quota: Quota) => {
        let res = await updateQuota({
            site_id: siteId,
            device_id: deviceId,
            id: quotaId,
            quota: quota
        });
        if (res?.success) {
            refreshQuotas();
        } else {
            toast.error("Failed to update quota.", {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    }

    const handleSaveQuotaConfig = (siteId: string, deviceId: string, quota: Quota, newVal: boolean) => {
        if (newVal) {
            handleCreateQuota(siteId, quota);
        } else {
            let _quotaId = quota.id;
            if (_quotaId) {
                delete quota.id;
                handleUpdateQuota(siteId, deviceId, _quotaId, quota);
            }
        }
        setToUpdateQuota(null);
        setOpenQuotaConfigDialog(false);
    }

    const handleEditQuota = async (quota: Quota) => {
        setQuotaDialogHeading("Edit Quota")
        setToUpdateQuota(quota);
    }

    const handleDeleteQuota = (quota: Quota) => {
        setToDeleteQuota(quota);
    }

    const handleDeleteConfirm = async () => {
        if (toDeleteQuota) {
            let _toDeleteQuota = toDeleteQuota
            setToDeleteQuota(null);
            let res = await deleteQuota({
                quota: _toDeleteQuota
            });
            if (res?.success) {
                refreshQuotas();
            } else {
                toast.error("Failed to delete quota", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        }
        setToDeleteQuota(null);
    }

    const handleDeleteCancel = () => {
        setToDeleteQuota(null);
    }

    const handleCancelQuotaConfig = () => {
        setToUpdateQuota(null);
        setOpenQuotaConfigDialog(false);
    }

    const handleCopyQuota = async (quota: Quota) => {
        if (isAuthorizedToBeta) {
            setOpenQuotaCopyPopUp(true)
            setClickedQuotaRow(quota)
            return
        }

        let _quota = { ...quota };
        delete _quota.id;
        let res = await createQuota({
            quota: _quota
        });
        if (res?.success) {
            refreshQuotas();
        } else {
            toast.error("Failed to copy quota", {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }

    const handleToggleDisable = async (quota: Quota) => {
        let res = await toggleQuota({
            quota: {
                ...quota,
                disabled: !quota.disabled
            }
        });
        if (!res?.success) {
            toast.error(`Failed to ${!quota.disabled ? 'enable' : 'disable'} quota`, {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }

    const handleSearch = (value: string) => {
        setPaginationParams({
            ...paginationParams,
            search: value,
            page: 1
        });
    }

    const handleChangeSorting = (sortBy: QuotaSortBy, sortOrder: SortOrder) => {
        setPaginationParams({
            ...paginationParams,
            sortBy: sortBy,
            sortOrder: sortOrder
        });
    }

    const handleChangePage = (event: React.SyntheticEvent<HTMLButtonElement> | null, _page: number) => {
        setPaginationParams({
            ...paginationParams,
            page: _page
        });
    };

    const handleChangeSize = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
        setPaginationParams({
            ...paginationParams,
            size: event.target.value as number,
            page: 1
        });
    };

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const updateQueryParams = () => {
        queryParams.set('page', paginationParams.page.toString())
        queryParams.set('size', paginationParams.size.toString())
        queryParams.set('sortBy', paginationParams.sortBy)
        queryParams.set('sortOrder', paginationParams.sortOrder)
        queryParams.set('search', paginationParams.search)
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    const deviceMap = useMemo(() => {
        const map: { [key: string]: Device } = {};
        devices.forEach(device => {
            map[device.id] = device;
        });
        return map;
    }, [devices]);

    useEffect(() => {
        updateQueryParams();
        refreshQuotas();
    }, [paginationParams, sites]);

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'quota-management') {
                const [_columns, status] = authReducer.csvDataDownloded;
                if (status && status.length > 0) {
                    const rows = status[0];
                    if (rows.length) {
                        DownloadCSV(convertToCsv([Object.keys(rows[0]), ...rows.map(row => Object.values(row))], authReducer.userTimezone), {
                            formatters: {
                                0: 'ESC-COMMA',
                                1: 'ESC-COMMA',
                                3: 'ESC-COMMA',
                                5: 'GB'
                            }
                        });
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const rowsOption = [10, 15, 25, 50, 100];

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_PG',
            id: 'quota-management',
            payload: { sites: sites.map(site => `'${site.id}'`).join(','), 
            search: paginationParams.search, 
            sort: paginationParams.sortBy, 
            order: paginationParams.sortOrder, 
            limit: totalQuotas, 
            offset: 0 
        },
            queryName: 'GET_QUOTAS',
        }
        downloadAsCSV(params);
    }

    return (
        <Paper elevation={0} className="quota_management--dashboard ">
            <Grid classes={{ root: 'quota_management--base--flex quota-container topbarWidth alignTopbarItemsCenter' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} classes={{ root: 'quota_management--base--flex quota-search-addQuota-div' }}>
                    <Grid item xs={6} sm={6} md={6} lg={6} className="searchbarMaxWidth1">
                        <Box
                          className="searchbarBox"  
                        >
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Type something"
                                autoComplete="off"
                                size="small"
                                value={searchText}
                                onChange={handleOnSearchChange}
                                onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "searchbarInputs",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchText && <SearchIcon
                                                className="searchbarIconsPosition" onClick={handleOnSearchClick}
                                            />}
                                            {searchText && <CloseIcon
                                                className="searchbarIconsPosition"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            /> 
                        </Box>
                    </Grid>
                    {!isReadOnlyUser && <Button variant="contained" className='quota_management--button--save' onClick={() => { setOpenQuotaConfigDialog(true); setQuotaDialogHeading("Add Quota") }}>
                        Add Quota
                    </Button>
                    }
                    <div>
                        <Tooltip classes={{ tooltip: 'quota_management--tooltip' }} title={
                            <Grid classes={{ root: 'quota_mangement--base--padding_1x' }}>
                                <div className='quota_management--base--font_07x'>
                                    <div>
                                        Note : Quotas are monitored every 15 minutes.
                                        WAN usage is measured on the Edge system for each WAN interface. The Edge usage may differ from Carrier usage (e.g. Starlink or T Mobile) due additional radio network and operational overheads from the carrier.
                                    </div>
                                    <div className='quota_management--base--mt_1x'>
                                        Starlink provided usage is on average 8% larger than Edge Starlink WAN usage.
                                    </div>
                                </div>
                            </Grid>
                        }>
                            <InfoIcon className="helpIcon-quota" />
                        </Tooltip>
                    </div>
                    <QuotaConfigDialog heading={quotaDialogHeading} open={toUpdateQuota != null || openQuotaConfigDialog} devices={devices} users={users} quota={toUpdateQuota} onSave={handleSaveQuotaConfig} onCancel={handleCancelQuotaConfig} />
                </Grid>
                <div className='quota_management--base--font_07x quota_management--base--flex--align--center'>
                    <FiberManualRecord fontSize='small' classes={{ root: 'quota_management--dashboard--usage_metric_color--remaining' }} id="usageLightblueDotSymbol"/> Remaining &nbsp;&nbsp;
                    <FiberManualRecord fontSize='small' classes={{ root: 'quota_management--dashboard--usage_metric_color--normal' }} id="usageBlueDotSymbol"/> Normal &nbsp;&nbsp;
                    <FiberManualRecord fontSize='small' classes={{ root: 'quota_management--dashboard--usage_metric_color--alerting' }} id="usageYellowDotSymbol"/> Over Quota Threshold &nbsp;&nbsp;
                    <FiberManualRecord fontSize='small' classes={{ root: 'quota_management--dashboard--usage_metric_color--exceeded' }} id="usageRedDotSymbol"/> Over Max Quota &nbsp;&nbsp;

                </div>
                {totalQuotas > 0 && <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>}



            </Grid>
            <Grid>
                <Pagination count={totalQuotas} rowsPerPageOptions={rowsOption} onPageChange={handleChangePage} page={paginationParams.page} rowsPerPage={paginationParams.size} onRowsPerPageChange={handleChangeSize} />
                <TableContainer component={Paper} className="quota_management--table_container">
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="quota dashboard">
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>
                                    <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field={'site_name'} onChange={handleChangeSorting}>
                                        SITE
                                    </SortArrows>
                                </TableCell>
                                <TableCell align='left'>
                                    <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field={'device_name'} onChange={handleChangeSorting}>
                                        DEVICE
                                    </SortArrows>
                                </TableCell>
                                <TableCell align='left'>
                                    <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field={'wan_links'} onChange={handleChangeSorting}>
                                        DATA SOURCE
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field={'current_quota_start_time'} onChange={handleChangeSorting}>
                                        QUOTA START
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field={'period_display'} onChange={handleChangeSorting}>
                                        QUOTA PERIOD
                                    </SortArrows>
                                </TableCell>
                                <TableCell classes={{ root: 'quota_management--dashboard--usage' }}>
                                    <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field={'used_percent'} onChange={handleChangeSorting}>
                                        USAGE
                                    </SortArrows>
                                </TableCell>
                                <TableCell align='left'>
                                    <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field={'status'} onChange={handleChangeSorting}>
                                        STATUS
                                    </SortArrows>
                                </TableCell>
                               {!isReadOnlyUser &&  <TableCell align='left' classes={{ root: 'quota_management--dashboard--actions' }}>
                                    <Grid classes={{ root: 'quota_management--base--flex--align--center' }}>
                                        ACTIONS
                                    </Grid>
                                </TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (sites.length !== 0 && quotas?.length > 0) ?
                                    quotas?.map((quota: Quota) => (
                                        <EditableQuotaRow userTimezone={authReducer.userTimezone}key={quota.id} device={deviceMap[quota.device_id]} quota={quota} devices={devices} gettingCurrentUsage={gettingCurrentUsage} onEdit={handleEditQuota} onDelete={handleDeleteQuota} onCopy={handleCopyQuota} onToggleDisable={handleToggleDisable} isReadOnlyUser={isReadOnlyUser}/>
                                    ))
                                    :
                                    <TableRow>
                                        <TableCell colSpan={8} align='center'>
                                            No data available
                                        </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer >
            </Grid>

            {openQuotaCopyPopUp?<QuotaCopy authReducer={authReducer} clickedQuotaRow={clickedQuotaRow} openQuotaCopyPopUp={openQuotaCopyPopUp} setOpenQuotaCopyPopUp={setOpenQuotaCopyPopUp} getDeviceList={getDeviceList} getGroupList={getGroupList} copyQuotaConfig={copyQuotaConfig} />: null}
            <DeleteQuotaConfirmationDialog open={toDeleteQuota != null} onConfirm={handleDeleteConfirm} onCancel={handleDeleteCancel} />

        </Paper >
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    quotas: state.quotaManager.quotas,
    totalQuotas: state.quotaManager.totalQuotas,
    gettingQuotas: state.quotaManager.gettingQuotas,
    gettingCurrentUsage: state.quotaManager.gettingCurrentUsage,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser
});

export default withRouter(connect(mapStateToProps, {
    // clearData,
    getQuotas,
    createQuota,
    updateQuota,
    deleteQuota,
    toggleQuota,
    downloadAsCSV,
    getGroupList,
    getDeviceList,
    copyQuotaConfig,
})(Dashboard));